import React, { Component } from 'react'
import styled from 'styled-components'
import comprehensivePlan from '../assets/images/comprehensivePlan.png'
import circlepage1 from '../assets/images/landingpage3/circlepage1.png'
import dotspage1 from '../assets/images/landingpage3/dotspage1.png'

const Wrapper = styled.div`
  width: 100%;
  padding-top: 12rem;
  background: rgba(238, 238, 238, 1);
  position: relative;
  // height: 770px;

  // img:second-of-type {
  //   position: absolute;
  //   z-index: 10;
  //   right: 5%;
  //   top: 5%;
  //   width: 40;
  // }

  // img:nth-of-type(2) {
  //   position: absolute;
  //   z-index: 10;
  //   left: 0;
  //   top: 38%;
  // }

  @media (min-width: 1024px) {
    // padding-top: 7.6rem;
    // padding-top: 3.4rem;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 1680px) {
    padding: 0;
  }
`

const Header = styled.div`
  // width: 33rem;
  padding-left: 3.6rem;
  // margin-bottom: 1.2rem;

  h1 {
    width: 29rem;
    margin-bottom: 1.2rem;
  }

  @media (min-width: 1024px) {
    padding-left: 0;
    width: 50rem;
    h1 {
      width: 50rem;
      margin-bottom: 2.4rem;
    }
  }

  @media (min-width: 1680px) {
    width: 50rem;
    h1 {
      width: 50rem;
      margin-bottom: 4.8rem;
    }
  }
`

// const Image = styled.div`
//   // align-self: flex-end;
//   background: url(${comprehensivePlan});
//   background-size: contain;
//   background-repeat: no-repeat;
//   width: 30rem;
//   height: 455.8px;
//   // padding-left: 24px;
//   // padding-bottom: 24px;
//   // margin-bottom: 36px;
//   // margin: 0 auto;
//   // padding-bottom: 36px;
//   // position: absolute;
//   left: 50%;
//   bottom: -50px;
//   transform: translateX(25%);
//   // transform: translateY(10%);

//   @media (min-width: 1024px) {
//     position: relative;
//     margin: 0;
//     width: 40rem;
//     height: 60.96rem;
//     left: auto;
//     bottom: auto;
//     transform: none;
//   }
//   @media (min-width: 1680px) {
//     background: url(${comprehensivePlan});
//     background-size: cover;
//     width: 50rem;
//     height: 75.7rem;
//   }
// `

const H1 = styled.h1`
  font-family: Proxima Nova;
  font-size: 3.4rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    font-size: 4.4rem;
    // line-height: 1.3;
  }

  @media (min-width: 1680px) {
    font-size: 6.2rem;
    line-height: 1.3;
  }
`
const Image = styled.img`
  display: flex;
  height: 453px;
  width: 227.8px;

  @media (min-width: 1680px){
    width: 267px;
    height: 513px;
    // margin-top: 66px;
    // padding-top: 24px;
    // padding-bottom: 44px;
  }
  
`
const Idiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 48px;

    @media (min-width: 1024px) {
    position: relative;
    margin: 0;
    width: 40rem;
    height: 60.96rem;
    left: auto;
    bottom: auto;
    transform: none;
    padding-top: 100px;
  }
  @media (min-width: 1680px) {
    // background: url(${comprehensivePlan});
    // background-size: cover;
    // width: 50rem;
    // height: 75.7rem;
    margin-top: 66px;
  }
`

const H2 = styled.h2`
  font-size: 2.4rem;
  font-family: Avenir;
  font-weight: 400;
  letter-spacing: 0.01px;
  color: #4a4a4a;
  padding-bottom: 36px;

  @media (min-width: 1023px) {
    font-size: 3.4rem;
    // font-weight: 400;
    letter-spacing: normal;
    // line-height: 1.56;
  }

  @media (min-width: 1679px) {
    font-size: 3.4rem;
    font-weight: normal;
    letter-spacing: 0.02px;
    line-height: 1.59;
  }
`
const Logo1 = styled.div`
  width: 6rem;
  height: 10rem;
  background: url(${dotspage1});
  background-repeat: no-repeat;
  position: absolute;
  top: 16%;
  right: 0;
`

const Logo2 = styled.div`
  width: 6rem;
  height: 10rem;
  background: url(${circlepage1});
  background-repeat: no-repeat;
  position: absolute;
  top: 38%;
  left: 0;
`

export default class Page31 extends Component {
  render = () => {
    return (
      <Wrapper>
        <Header>
          <H1>Reimagining Health Insurance</H1>
          <H2>Simple • Smart • Easy</H2>
        </Header>
        {/* <Image /> */}
        <Idiv>
        {/* <Image src={comprehensivePlan}/> */}
        <Image alt ="health insurance" src = "http://images.getvital.in/homelanding/comprehensivePlan.png" />
        </Idiv>
        <Logo1 />
        <Logo2 />
        {/* <img src={dotspage1} alt="dotspage1.png" />
        <img src={circlepage1} alt="circlepage1.png" /> */}
       </Wrapper>
    )
  }
}
