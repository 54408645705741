import React from 'react'

const all_nb_banks_name = [
  {
    bank_name: 'ICICI Bank',
    title: 'ICICI',
    image: require('../../../assets/images/bank/icici.svg'),
    recurring: true
  },

  {
    bank_name: 'Kotak Mahindra Bank',
    title: 'KOTAK',
    image: require('../../../assets/images/bank/kotak.svg'),
    recurring: true
  },
  {
    bank_name: 'Axis Bank',
    title: 'AXIS',
    image: require('../../../assets/images/bank/axis.svg'),
    recurring: true
  },
  {
    bank_name: 'HDFC Bank',
    title: 'HDFC',
    image: require('../../../assets/images/bank/hdfc.svg'),
    recurring: true
  },
  {
    bank_name: 'State Bank of India',
    title: 'SBI',
    image: require('../../../assets/images/bank/sbi.png'),
    recurring: true
  },
  //5-10
  {
    bank_name: 'Bank of Maharashtra',
    title: 'MAHB',
    image: require('../../../assets/images/bank/icici.svg'),
    recurring: true
  },

  {
    bank_name: 'Deutsche Bank AG',
    title: 'DEUT',
    image: require('../../../assets/images/bank/kotak.svg'),
    recurring: true
  },
  {
    bank_name: 'Equitas Small Finance Bank',
    title: 'ESFB',
    image: require('../../../assets/images/bank/axis.svg'),
    recurring: true
  },
  {
    bank_name: 'IDFC First Bank',
    title: 'IDFB',
    image: require('../../../assets/images/bank/usfb.svg'),
    recurring: true
  },
  {
    bank_name: 'IndusInd Bank',
    title: 'INDB',
    image: require('../../../assets/images/bank/usfb.svg'),
    recurring: true
  },
  //11-15

  {
    bank_name: 'Ujjivan Small Finance Bank LTD.',
    title: 'USFB',
    image: require('../../../assets/images/bank/icici.svg'),
    recurring: true
  },

  {
    bank_name: 'United Bank of India',
    title: 'UTBI',
    image: require('../../../assets/images/bank/kotak.svg'),
    recurring: true
  },
  {
    bank_name: 'Yes Bank',
    title: 'YESB',
    image: require('../../../assets/images/bank/axis.svg'),
    recurring: true
  },
  {
    bank_name: 'Andhra Bank',
    title: 'ANDB',
    image: require('../../../assets/images/bank/usfb.svg'),
    recurring: true
  },
  {
    bank_name: 'Bank of Baroda',
    title: 'BARB_R',
    image: require('../../../assets/images/bank/usfb.svg'),
    recurring: true
  },
  //16-20

  {
    bank_name: 'Central Bank of India',
    title: 'CBIN',
    image: require('../../../assets/images/bank/icici.svg'),
    recurring: true
  },

  {
    bank_name: 'City Union Bank',
    title: 'CIUB',
    image: require('../../../assets/images/bank/kotak.svg'),
    recurring: true
  },
  {
    bank_name: 'Federal Bank',
    title: 'FDRL',
    image: require('../../../assets/images/bank/axis.svg'),
    recurring: true
  },
  {
    bank_name: 'IDBI Bank',
    title: 'IBKL',
    image: require('../../../assets/images/bank/usfb.svg'),
    recurring: true
  },
  {
    bank_name: 'Indian Overseas Bank',
    title: 'IOBA',
    image: require('../../../assets/images/bank/usfb.svg'),
    recurring: true
  },
  //21-15
  {
    bank_name: 'Karnataka Bank LTD',
    title: 'KARB',
    image: require('../../../assets/images/bank/icici.svg'),
    recurring: true
  },

  {
    bank_name: 'Punjab National Bank',
    title: 'PUNB_R',
    image: require('../../../assets/images/bank/kotak.svg'),
    recurring: true
  },
  {
    bank_name: 'Paytm Payments Bank',
    title: 'PYTM',
    image: require('../../../assets/images/bank/axis.svg'),
    recurring: true
  },
  {
    bank_name: 'RBL Bank',
    title: 'RATN',
    image: require('../../../assets/images/bank/usfb.svg'),
    recurring: true
  },
  {
    bank_name: 'Standard Charted Bank',
    title: 'SCBL',
    image: require('../../../assets/images/bank/usfb.svg'),
    recurring: true
  },
  //26-29
  {
    bank_name: 'Tamilnadu Mercantile Bank',
    title: 'TMBL',
    image: require('../../../assets/images/bank/icici.svg'),
    recurring: true
  },

  {
    bank_name: 'AU SMALL FINANCE BANK LTD',
    title: 'AUBL',
    image: require('../../../assets/images/bank/kotak.svg'),
    recurring: true
  },
  {
    bank_name: 'South Indian Bank',
    title: 'SIBL',
    image: require('../../../assets/images/bank/axis.svg'),
    recurring: true
  },
  {
    bank_name: 'Abhyudaya Co Op Bank',
    title: 'ABHY',
    recurring: true
  },
  {
    bank_name: 'Aman Sahakari Bank Ltd.',
    title: 'AMAX',
    recurring: true
  },
  {
    bank_name: 'Ambarnath Jai Hind Coop Bank Ltd.',
    title: 'AJSX',
    recurring: true
  },
  {
    bank_name: 'Alavi Co op Bank Ltd.',
    title: 'ALAX',
    recurring: true
  },
  {
    bank_name: 'Bandhan Bank',
    title: 'BDBL',
    recurring: true
  },
  {
    bank_name: 'Bharat Co-op bank',
    title: 'BCBM',
    recurring: true
  },
  {
    bank_name: 'Canara Bank',
    title: 'CNRB',
    recurring: true
  },
  {
    bank_name: 'Citi Bank',
    title: 'CITI',
    recurring: true
  },
  {
    bank_name: 'Cosmos Co-op Bank',
    title: 'COSB',
    recurring: true
  },
  {
    bank_name: 'Dhanalaxmi Bank',
    title: 'DLXB',
    recurring: true
  },
  {
    bank_name: 'DBS Bank India Ltd.',
    title: 'DBSS',
    recurring: true
  },
  {
    bank_name: 'DCB Bank',
    title: 'DCBL',
    recurring: true
  },
  {
    bank_name: 'Durgapur Steel Peoples Co op Bank Ltd.',
    title: 'DSPX',
    recurring: true
  },
  {
    bank_name: 'GP Parsik Sahakari Bank Ltd. Kalwa Thane',
    title: 'PJSB',
    recurring: true
  },
  {
    bank_name: 'The Gujarat State Co op Bank Ltd.',
    title: 'GSCB',
    recurring: true
  },
  {
    bank_name: 'Hongkong and Shanghai Banking Corporation (HSBC)',
    title: 'HSBC',
    recurring: true
  },
  {
    bank_name: 'Indian Bank',
    title: 'IDIB',
    recurring: true
  },
  {
    bank_name: 'Jana Small Finance Bank',
    title: 'JSFB',
    recurring: true
  },
  {
    bank_name: 'The Jalgaon Peoples Co-op Bank Ltd.',
    title: 'JPCB',
    recurring: true
  },
  {
    bank_name: 'J & K Grameen Bank',
    title: 'XJKG',
    recurring: true
  },
  {
    bank_name: 'Jio Payments Bank',
    title: 'JIOP',
    recurring: true
  },
  {
    bank_name: 'Karur Vysya Bank',
    title: 'KVBL',
    recurring: true
  },
  {
    bank_name: 'Manorama Co Op Bank Ltd. Solapur',
    title: 'MSOX',
    recurring: true
  },
  {
    bank_name: 'Model Co-op. Bank Ltd.',
    title: 'MDEX',
    recurring: true
  },
  {
    bank_name: 'New India Co-op Bank Ltd.',
    title: 'NICB',
    recurring: true
  },
  {
    bank_name: 'Oriental Bank of Commerce',
    title: 'ORBC',
    recurring: true
  },
  {
    bank_name: 'Osmanabad Janata Sahakari Bank Ltd.',
    title: 'OSMX',
    recurring: true
  },
  {
    bank_name: 'Palus Sahakari Bank Ltd.',
    title: 'PLUX',
    recurring: true
  },
  {
    bank_name: 'Paschim Banga Gramin Bank',
    title: 'PASX',
    recurring: true
  },
  {
    bank_name: 'Punjab & Sind Bank',
    title: 'PSIB',
    recurring: true
  },
  {
    bank_name: 'Rendal Sahakari Bank Ltd. Rendal',
    title: 'REBX',
    recurring: true
  },
  {
    bank_name: 'Saraswat Cooperative Bank Ltd.',
    title: 'SRCB',
    recurring: true
  },
  {
    bank_name: 'Shree Kadi Nagarik Sahakari Bank Ltd.',
    title: 'SCBL',
    recurring: true
  },
  {
    bank_name: 'SVC Cooperative Bank Ltd.',
    title: 'SVCB',
    recurring: true
  },
  {
    bank_name: 'Syndicate Bank',
    title: 'SYNB',
    recurring: true
  },
  {
    bank_name: 'The Ajara Urban Co op Bank Ltd.',
    title: 'AUCX',
    recurring: true
  },
  {
    bank_name: 'The Burdwan Central Co op Bank Ltd.',
    title: 'BURX',
    recurring: true
  },
  {
    bank_name: 'The Income Tax Dept Coop Bank Ltd.',
    title: 'ITDX',
    recurring: true
  },
  {
    bank_name: 'Tumkur Grain Merchants Coop Bank Ltd.',
    title: 'TGMB',
    recurring: true
  },
  {
    bank_name: 'UCO Bank',
    title: 'UCBA',
    recurring: true
  },
  {
    bank_name: 'Varachha Cooperative Bank',
    title: 'VARA',
    recurring: true
  },
  {
    bank_name: 'Union Bank of India',
    title: 'UBIN',
    recurring: true
  },
  {
    bank_name: 'THE UNION CO-OP BANK LTD',
    title: 'TUNX',
    recurring: true
  },
  {
    bank_name: 'The Adarsh Co-op Urban Bank Ltd',
    title: 'ACUX',
    recurring: true
  },
  {
    bank_name: 'The Ahmednagar District Central Co-op Bank Ltd',
    title: 'AHMX',
    recurring: true
  },
  {
    bank_name: 'The Andhra Pradesh State Co-op Bank Ltd',
    title: 'APBL',
    recurring: true
  },
  {
    bank_name: 'The Baroda City Co-op Bank Pvt Ltd',
    title: 'BARX',
    recurring: true
  },
  {
    bank_name: 'The Pochampally Co-op Urban Bank Ltd',
    title: 'PCUX',
    recurring: true
  },
  {
    bank_name: 'Rajadhani Co-op Urban Bank Limited',
    title: 'RCUX',
    recurring: true
  },
  {
    bank_name: 'Saraspur Nagarik Co Op Bank Ltd',
    title: 'SNAX',
    recurring: true
  },
  {
    bank_name: 'Shrikrishna Co-op Bank Ltd Umrer',
    title: 'SKCX',
    recurring: true
  },
  {
    bank_name: 'The Sircilla Co-op Urban Bank Ltd',
    title: 'SIRX',
    recurring: true
  },
  {
    bank_name: 'The Surat District Co-op Bank',
    title: 'SDCB',
    recurring: true
  },
  {
    bank_name: 'The Sutex Co-op Bank Ltd',
    title: 'SUTB',
    recurring: true
  },
]

all_nb_banks_name.sort((a,b) => {
  return a.bank_name.localeCompare(b.bank_name)
})

all_nb_banks_name.push({
  bank_name: 'Others',
  title: 'Others',
  image: require('../../../assets/images/bank/others.svg')
})



export default all_nb_banks_name
