import React from "react";
import styled from "styled-components";
import {Ptag} from '../fonts'
import crossPic from '../../assets/images/popupcrossbutton.png'
import { formatAmount } from "../../utils/utils";


const PriceBreakPopUp = ({amounts = [], closePop, showPopup}) => {

    const onClickCard = (event) => {
        event.stopPropagation()
    }

    const Sum = amounts.reduce((sum, item) => sum + item['value'], 0)
    return(
        <Root onClick={closePop} showModal={showPopup} >
            <ContentRoot onClick={onClickCard} >
                <Header>
                    <Ptag fontSize='16px' fontWeight='600' letterSpacing='0.8px' >
                        {'Subscription Break-up'}
                    </Ptag>
                    <img onClick={closePop} src={crossPic} height='20px' width='20px' />
                </Header>
                
                <AmountsBox>
                    {
                        amounts.map( (item) => 
                            <>
                                {
                                    !!item['value'] &&
                                    <Row key={item['text']} >
                                        <Ptag textTransform='capitalize' fontSize='14px' letterSpacing='0.7px'>
                                            {item['text']}
                                        </Ptag>
                                        <Ptag fontSize='14px' fontWeight='600' letterSpacing='0.4px' >
                                            {`₹${formatAmount(item['value'] || 0)}`}
                                        </Ptag>
                                    </Row>
                                }
                            </>
                            
                        )
                    }
                </AmountsBox>
                <Row>
                    <Ptag textTransform='capitalize' fontSize='14px' letterSpacing='0.7px'>
                        {'Subscription Fee'}
                    </Ptag>
                    <Ptag fontSize='14px' fontWeight='600' letterSpacing='0.4px' >
                        {`₹${formatAmount(Sum || 0)}`}
                    </Ptag>
                </Row>

            </ContentRoot>
        </Root>
    )
}

export default PriceBreakPopUp

const Root = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    overflow: auto;
    justify-content: flex-end;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    transform: ${props => (props.showModal === true ? 'translateY(0) scaleY(100%)' :
     'translateY(102%) scaleY(0)')};
    transition: all 0.6s;
`

const ContentRoot = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 15px 15px 0 0;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
    padding: 24px;
    background-color: #fff;
    min-height: 310px;
    max-height: 310px;
    max-width: 340px;
    min-width: 340px;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`

const AmountsBox = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #4a4a4a;
    padding: 30px 0 18px;
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
`