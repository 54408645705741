import React, { Component } from 'react'
import styled from 'styled-components'
import Credit1 from '../assets/svgs/credit1.png'
import Credit2 from '../assets/svgs/credit2.png'
import Credit3 from '../assets/svgs/credit3.png'
import Credit4 from '../assets/svgs/credit4.png'
import halfCircleLightGreen4 from '../assets/svgs/flyingLogoWebsite/halfCircleLightGreen4.svg'



const Wrapper = styled.div`
    width: 100%;
    padding: 3.6rem 0 1rem 0;
    position: relative;

    @media (min-width: 1024px) {
      padding-top: 120px;
    }
`
const Header = styled.div`
    width: 32rem;
    padding-left: 2.4rem;
    margin-bottom: 3rem;

    @media (min-width: 1024px) {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
    
      @media (min-width: 1680px) {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
      }
`
const H2 = styled.h2`
    width: 308px;
    // height: 103px;
    font-family: Proxima Nova;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: 0.25px;
    color: #4a4a4a;
    // padding-right: 36px;

    @media (min-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 20rem 0 20rem 0;
        font-size: 34px;
        font-weight: 600;
        width: 649px;
        text-align: center;
      }
    
      @media (min-width: 1680px) {
        // padding: 29rem 0 29rem 0;
      }
    
`
const OuterDiv = styled.div`
    display: flex;
    flex-direction: row;
    padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '35px')};
    align-items: center;

    @media (min-width: 1024px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
`
const ImageDv = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin-left: 36px;
`
const Image = styled.img`
    align-items: center;
    justify-content: center;
    height: 60px;
    width: 60px;
`
const H3 = styled.h3`
    font-family: Avenir;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0px;
    text-align: left;
    color: #4a4a4a;
    margin-left: 16px;

    @media (min-width: 1024px) {
      font-size: 20px;
      font-weight: 600;
      margin-top: 32px;
      text-align: center;
      width: 170px;
      margin-left: 32px;
    }
`
const IDiv = styled.div`
    @media (min-width: 1024px) {
        display: none;
    }
`
const Imagedots = styled.div`
  width: 3rem;
  height: 10rem;
  background-image: url(${halfCircleLightGreen4});
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 10%;

  @media (min-width: 1024px) {
    background-image: url(${halfCircleLightGreen4});
    height: 4.44rem;
    width: 4.45rem;
    top: 12%;
  }
`
const ParentDiv = styled.div`
  width: 100%;
  position: relative;
  background-color: #f6f6f6;

  @media (min-width: 1024px) {
  }
`
const SubHeading = styled.div`
  width: 100%;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    font-size: 34px;
    // width: 95rem;
    padding-bottom: 120px;
    padding-top: 36px;

  }

  @media (min-width: 1680px) {
    display: flex;
    align-items: center;
    font-size: 48px;
  }
`



export default class Page41 extends Component {
  render = () => {
    return (
      <ParentDiv>
      <Wrapper>
        <Header>
          <H2>Pay out of pocket expenses conveniently in monthly instalments</H2>
        </Header>
        <Imagedots />

        <SubHeading>
            <OuterDiv>
                <ImageDv>
                    <Image src={Credit1}/>
                </ImageDv>
                <H3>
                    No processing fee 
                </H3>
            </OuterDiv>

            <OuterDiv>
                <ImageDv>
                    <Image src={Credit2}/>
                </ImageDv>
                <H3>
                No physical documentation required 
                </H3>
            </OuterDiv>

            <OuterDiv>
                <ImageDv>
                    <Image src={Credit3}/>
                </ImageDv>
                <H3>
                NIL foreclosure charges 
                </H3>
            </OuterDiv>
            
            <OuterDiv paddingBottom="36px">
                <ImageDv>
                    <Image src={Credit4}/>
                </ImageDv>
                <H3>
                Flexible repayment tenure 
                </H3>
            </OuterDiv>

            </SubHeading>

            </Wrapper>
            </ParentDiv>
    )
  }
}
