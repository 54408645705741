export const emptyPersonalState = {
    spouse: false,
    children: false,
    parents: false
}

export const filledPersonalState = {
    spouse: true,
    children: true,
    parents: true
}

export const actions = {
    update: "UPDATE",
    add: "ADD",
    delete: "DELETE"
}

export const emptyFields = {
    first_name: "",
    last_name: "",
    dob: "",
    relationship: "",
}

export const spouseOptions = {
    father: "FATHER",
    mother: "MOTHER"
}