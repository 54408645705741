import React, { Component } from "react";
import styled from "styled-components";
import {Ptag} from '../fonts'


export default function UnelligibleMembersPopup({showPopup = false, members, onClickAcceptMembers, onRejectPolicy, showAcceptWithout, ...props}){

    return(
        <Home show={showPopup}>
            <Body>
                <Ptag textAlign='center' style={{'padding': '0 12px', 'marginBottom': '24px'}} fontSize='16px' letterHeight='1.5' letterSpacing='0.25px'>
                    {'OOPS'}
                </Ptag>

                <Ptag style={{'padding': '0 12px', 'marginBottom': '12px'}} textAlign='center' fontSize='16px' letterHeight='1.5' letterSpacing='0.25px'>
                    {'Unfortunately, we can’t add these members in the plan as they have existing health conditions:'}
                </Ptag>
                <Names>
                {
                    members.map((member, index) =>
                        <Ptag style={{'padding': '0 12px'}} textTransform='capitalize' textAlign='left' fontSize='16px' letterHeight='1.5' letterSpacing='0.25px'>
                            {`${index + 1}. ${member}`}
                        </Ptag>
                    )
                }
                </Names>
                
                
                {showAcceptWithout && <Button onClick={onClickAcceptMembers}>
                    {'Accept without these members'}
                    </Button>
                }
                <Button onClick={onRejectPolicy}>
                    {'Cancel for everyone'}
                </Button>
            </Body>
        </Home>
    )
}


const Home = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background: #00000025;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    transform: ${props =>
    props.show
        ? "translateY(0) scaleY(100%)"
        : "translateY(102%) scaleY(0)"};
    transition: all 0.6s;
    min-height: 100vh;
`;


const Body = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.19);
    margin: auto 15px;
    padding-top: 34px;
`

const Button = styled.div`
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding: 18px 0;
    font-family: Avenir;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    text-align: center;
    color: #4a4a4a;
`

const Names = styled.div`
    dsiplay: flex;
    flex-direction: column;
    margin: 0 auto 24px auto; 
`


