import React, { Component } from 'react'
import styled from 'styled-components'


const MaterialInput = ({type = 'text', error, Readonly, placeholder, disabled, ...restProps}) => {
    let additionalProps = {}
    if (type === 'number'){
        additionalProps = {
          pattern: "[0-9]*",
          inputmode: "numeric"
        }
    }
    return (
        <>
            <InputFiled
                {...restProps}
                {...additionalProps}
                type={type}
                readOnly={!!Readonly}
                border={disabled && '2px solid grey'}
                color={disabled ? '#4a4a4a' : '#009999'}
                placeholder={placeholder}
            />
            <Placeholder>
                {placeholder}
            </Placeholder>
            {error && <Error>{error}</Error>}
        </>
        
    )
}


export default MaterialInput

const InputFiled = styled.input`
    width: 100%;
    -webkit-appearance: none;
    font-size: 1.6rem;
    font-family: Avenir;
    font-weight: ${props => (props.fontWt ? props.fontWt : 'normal')};
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: ${props => (props.letterSpacing ? props.letterSpacing : '0.5px')};
    color: ${props => (props.color ? props.color : '#009999')};
    border: 0;
    border-radius: ${props => (props.borderRadius ? props.borderRadius : 0)};;
    border-bottom: ${props => (props.border ? props.border : '2px solid #436ab2')};
    -webkit-text-fill-color: ${props => props.disabled ? 'rgba(0, 0, 0, 0.54)' : ''};
    -webkit-opacity: 1;
    background: white;
    padding-left: 5px;
    padding-bottom: 10px; // 5px;
    // margin-bottom: 5px;
    background: none;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
    transition: background-color 5000s 0s;
    -webkit-text-fill-color: ${props => (props.color ? props.color : '#009999')};
    }
    &::placeholder{
        color: white;
    }
    &:focus {
        outline: none;
        background: none !important;
    }
    &:focus ~ label, &:not(:placeholder-shown) ~ label  {
        top:-20px;
        font-size:14px;
    }
    // &:not(:placeholder-shown) ~ label {
    //     top:-20px;
    //     font-size:12px;
    // };
`

const Error = styled.p`
    color: red;
    font-size: 1.4rem;
    margin-top: 38px;
    z-index: 9;
    position: absolute;
`

const Placeholder = styled.label`
    display: block;
    color:#999999; 
    font-size:18px;
    font-weight:normal;
    position:absolute;
    pointer-events:none;
    left:5px;
    top: 0px;
    transition:0.3s ease all; 
    -moz-transition:0.3s ease all; 
    -webkit-transition:0.3s ease all;
`