import React from 'react'
import styled from 'styled-components'
import { BusyIndicator } from '../../components'

const Overlay = styled.div`
  /* width: 100vw;
  height: 100vh; */
  // background: rgba(0, 0, 0, 0.55);
  /* position:fixed; */
  background: ${props => (props.bgColor ? props.bgColor : '#ffffff')};
  display: flex;
  /* top: 0;
  left: 0; */
  justify-content:center;
  align-items:center;
  z-index: ${props => (props.zindex ? props.zindex : 999)};
`

const Loader = props => (
  <Overlay bgColor={props.bgColor} zindex={props.zindex}>
    <BusyIndicator />
  </Overlay>
)

export default Loader
