import React from 'react'
import styled from 'styled-components'
import cosmetic from '../assets/svgs/cosmetic.svg'
import dental from '../assets/svgs/dental.svg'
import maternity from '../assets/svgs/maternity.svg'
import prosthetics from '../assets/svgs/prosthetics.svg'
import mentalDisorders from '../assets/svgs/mentalDisorders.svg'
import drugTreatment from '../assets/svgs/drugTreatment.svg'

const Home = styled.div`
  display: flex;
  overflow-x: auto;
  & > div {
    display: inline-block;
  }

  & > :last-child {
    div {
      border: none;
    }
  }
  //Chrome, Safari, newer versions of Opera
  ::-webkit-scrollbar {
    display: none;
  }
  //Firefox
  overflow: -moz-scrollbars-none;
  //Internet Explorer +10
  -ms-overflow-style: none;
`

const ImgWrap = styled.div`
  width: 10rem;
  // height: 10rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 1rem 1.2rem 0;
  border-right: 1px solid #e8e8e8;

  & img {
    width: 4.8rem;
    height: 4.8rem;
    margin-bottom: 1rem;
  }
`

const Font12 = styled.p`
  font-family: Avenir;
  font-size: 1.2rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #4a4a4a;
`

const NotCoveredThing = props => {
  return (
    <Home>
      <div>
        <ImgWrap>
          <img src={cosmetic} alt="cosmetic.png" />
          <Font12>Cosmetic Procedures</Font12>
        </ImgWrap>
      </div>
      <div>
        <ImgWrap>
          <img src={dental} alt="dental.png" />
          <Font12>Dental Treatments</Font12>
        </ImgWrap>
      </div>
      <div>
        <ImgWrap>
          <img src={maternity} alt="maternity.png" />
          <Font12>{'Maternity & IVF'} </Font12>
        </ImgWrap>
      </div>
      <div>
        <ImgWrap>
          <img src={prosthetics} alt="prosthetics.png" />
          <Font12>Artficial</Font12>
          <Font12>Limbs</Font12>
        </ImgWrap>
      </div>
      <div>
        <ImgWrap>
          <img src={mentalDisorders} alt="mentalDisorders.png" />
          <Font12>Mental</Font12>
          <Font12>Disorders</Font12>
        </ImgWrap>
      </div>
      <div>
        <ImgWrap>
          <img src={drugTreatment} alt="drugTreatment.png" />
          <Font12>Drug</Font12>
          <Font12>Abuse</Font12>
        </ImgWrap>
      </div>
    </Home>
  )
}

export default NotCoveredThing
