import styled from "styled-components";

export const LoginPageWrapper = styled.section`
  display: flex;
  height: 100vh;
  & > aside {
    background: #e7edff;
    display: flex;
    flex: 1 1 0;
    align-items: flex-end;
    @media (max-width: 768px) {
      display: none;
    }
  }
  & > aside > img {
    width: 90%;
    margin-bottom: 3rem;
  }
`;

export const LoginContentWrapper = styled.section`
  flex: 2 1 0;
  display: grid;
  place-items: center;
  & > div {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    max-width: 700px;
  }
`;

export const PhoneInput = styled.input`
  width: 100%;
  background-color: #edeff4;
  padding: 1.6rem 2rem 1.6rem 7.4rem;
  outline: none;
  font-weight: 600;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  color: ${(props) => props.theme.accentColor};
  letter-spacing: 2px;
`;

export const PhoneInputWrapper = styled.div`
  position: relative;
  margin: 8rem 0rem 12rem 0rem;
  width: 100%;
  & > p {
    color: #4a4a4a;
    position: absolute;
    left: 2rem;
    z-index: 99;
    top: 50%;
    margin-top: 0.1rem;
    transform: translateY(-50%);
    font-size: 16px;
    font-weight: 600;
    pointer-events: none;
    user-select: none;
  }
  & > div {
    width: 1px;
    height: 40%;
    margin-top: 0.1rem;
    background-color: #4a4a4a;
    z-index: 999;
    position: absolute;
    left: 6rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    user-select: none;
  }
`;

export const Error = styled.span`
  color: red;
  font-size: 1.4rem;
  z-index: 9;
  position: absolute;
  bottom: -3rem;
  left: 0;
`;
