export const PolicyDocumentTexts = {
  HeaderText: [
    "This health insurance policy is underwritten and serviced by Care Health Insurance Limited (formerly known as Religare Health Insurance Company Limited). The policy covers all the insured members for hospitalization expenses due to accident or illness up to the sum insured.",
    "It is being distributed by Vital as the Master Policyholder.",
  ],
  WhatsCovered: [
    "If any insured member is hospitalized for more than 24 hours, the policy will cover him or her against the medical expenses incurred on hospitalization. It will also include charges for:",
  ],
  HospitalizationExpenses: [
    "Hospital Room (Up to Single Room)",
    "Nursing",
    "Intensive Care Unit (ICU)",
    "Anaesthesia",
    "Medical Practitioners (Fees)",
    "Oxygen",
    "Blood",
    "Operation theatre",
    "Medicines",
    "Drugs",
    "Surgical Appliances",
    "Nursing",
    "Consumables",
    "Diagnostic Procedures",
    "Covid-19 Treatment",
  ],
  HospitalizationExpensesYearly: [
    "Hospital Room",
    "Nursing",
    "Intensive Care Unit (ICU)",
    "Anaesthesia",
    "Medical Practitioners (Fees)",
    "Oxygen",
    "Blood",
    "Operation theatre",
    "Medicines",
    "Drugs",
    "Surgical Appliances",
    "Nursing",
    "Consumables",
    "Diagnostic Procedures",
    "Covid-19 Treatment",
  ],
  DayCare:
    "Day care treatment means medical treatment, or surgical procedures which can be completed in less than 24 hours in a hospital or daycare center. This excludes all procedures and treatments that can be taken in the outpatient department of the hospital.",
  DayCareProcedures: [
    "Chemotherapy",
    "Radiotherapy",
    "Liver Biopsy",
    "Coronary Angiography",
    "Haemodialysis",
    "Operation of Cataract",
    "Nasal Sinus Aspiration",
  ],
  PreHospitalization:
    "Your policy will cover pre-hospitalization expenses including consultations, investigations and medicines incurred up to 30 days before the date of admission for hospitalization purpose or day care treatment.",
  PostHospitalization:
    "Your policy will be inclusive of post-hospitalization expenses for consultations,investigations and medicines incurred up to 60 days after discharge from hospitalization or day care treatment.",
  Ambulance:
    "Your policy will cover expenses up to Rs.1,000 incurred on transportation of insured person to the hospital for treatment in case of an emergency.",
  Deductible:
    "Deductible is the amount you will pay yourself towards expenses covered in the plan before insurance starts to pay.",
  WhatsNotCovered: [
    "Hospitalization of an insured person caused due to any illegal act or intentional self-injury",
    "Treatment of any illness or injury as a consequence of intentional use of alcohol, tobacco, narcotic or psychotropic substances",
    "Prosthetic and other devices which are self-detachable/ removable without surgery  involving anaesthesia usage",
    "Any treatment taken outside India",
    "Any cosmetic treatment or plastic surgery including circumcision, unless medically necessary",
    "Any preventive care or vaccination, including inoculation and immunizations (except in case of post-bite treatment).",
    "Hospitalization primarily for diagnostic and evaluation purposes, with no course of treatment being followed in future",
    "Psychiatric, mental disorders (including mental health treatments), Parkinson and Alzheimer’s disease",
    "Treatment for sleep apnea",
    "External congenital (present since birth) diseases, defects or anomalies, genetic disorders",
    "Any treatment that does not include hospitalization other than daycare treatment",
    "Sexually transmitted diseases, HIV and AIDS, and diseases arising out of the same",
    "Any expense related to pregnancy directly or indirectly including voluntary termination, miscarriage (except as a  result of an accident or illness), maternity or child birth except Ectopic pregnancy",
    "Any treatment for sterility, infertility or birth control",
    "Any treatment or surgery for change of sex or gender reassignments including any complication arising from these treatments",
    "Any dental or optical care, unless requiring hospitalization due to an accident",
    "All expenses related to donor treatment, including surgery to remove organs from the donor, in case of transplant surgery",
    "Any treatment at a non-registered hospital or day care center or by someone who is not a licensed medical practitioner",
    "Any drugs or treatments not supported by a prescription",
    "Any treatment or part of a treatment that is not medically necessary or which is unproven or non-allopathic or experimental in nature",
    "Treatments or illnesses due to war and war-like occurrence or invasion, acts of foreign enemies, rebellion including those arising out of nuclear, chemical or  biological attack",
    "Expenses incurred for artificial life maintenance, including life support machine, post confirmation of vegetative state by treating medical practitioner where such treatment will not result in recovery or restoration of the previous health under any circumstances",
  ],

  WaitingPeriod:
    "A time period during which the person insured has to wait to claim the benefits covered under the policy.",
  "30DaysWaitingPeriod":
    "You can claim policy benefits only after 30 days from its date of commencement, except for any treatment due to an accident.",
  "24MonthWaitingPeriod":
    "You can claim policy benefits after 24 months from its date of commencement for medical and surgical treatment of illnesses/ diagnosis or surgical procedures mentioned below:",
  "24MonthWaitingPeriodPoints": [
    "Arthritis (if non-infective), Osteoarthritis and Osteoporosis",
    "Gout, Rheumatism and Spinal disorders, Joint replacement surgery",
    "Benign ear, nose and throat (ENT) disorders and surgeries (including but not limited to Adenoidectomy, Mastoidectomy, Tonsillectomy and Tympanoplasty), nasal septum deviation, Sinusitis and related disorders",
    "Benign prostatic hypertrophy",
    "Cataract",
    "Dilatation and Curettage",
    "Fissure/fistula in anus, Hemorrhoids/piles, pilonidal sinus, gastric and duodenal ulcers; surgery of Genito urinary system unless necessitated by malignancy",
    "All types of Hernia, Hydrocele",
    "Hysterectomy for menorrhagia or fibromyoma or prolapse of uterus unless necessitated by malignancy",
    "Internal tumors, skin tumours, cysts, nodules, polyps including breast lumps (each of any kind) unless malignant",
    "Kidney Stone/ Ureteric Stone/ Lithotripsy/ Gall bladder Stone",
    "Myomectomy for fibroids",
    "Varicose veins and varicose ulcers",
  ],
  PreExisting1:
    "Pre-existing disease is any condition, illness or injury for which the insured member has had signs or symptoms, or was diagnosed, or has received medical advice or treatment within 48 months before the policy was issued. All medical expenses incurred for diagnosis and treatment of any pre-existing illness will not be covered until the completion of 48 months of continuous coverage.",

  PreExisting2:
    "All medical expenses incurred for diagnosis and treatment of any pre-existing illness will not be covered until the completion of 48 months of continuous coverage.",
  PolicyCancel:
    "Your policy will be cancelled on grounds of misrepresentation, fraud, non-disclosure of material facts or non-cooperation by any insured person after giving 30 days notice period.",
  FreeLook:
    "You have an option to cancel your policy within 15 days from the date of purchase and receive a full refund of the premium paid till date.",
  PolicyRenewal:
    "Your policy is ordinarily life-long renewable, provided the renewal premium has been received till the due dates.",
  ReimbursementClaim:
    "For raising reimbursement claims, in case you have not used the cashless service at our network hospital or in case you have used the services of a non-network hospital",
  ReimbursementClaim1:
    "Upload the copy of bills and raise your claim request using your Vital App. The request will automatically be raised with Care Health Insurance Limited (formerly known as Religare Health Insurance Company Limited).",
  ReimbursementClaim2:
    "Download the claim form from the link below, and fill the required details",
  ReimbursementClaim2Steps: [
    "All indoor case papers – This document is prepared by the hospital, which maintains the doctor notes, patient details and progress from admission to discharge.",
    "Hospital discharge summary from the hospital, which includes admission date, date of discharge, diagnosis, line of treatment, and advise on discharge.",
    "Original payment receipts of all payments done towards bills, investigation reports, and any procedures done.",
    "Any consultation papers, with whom the patient had consulted.",
  ],
  ReimbursementClaim4Steps: [
    "First Information Report (FIR), final police report (if applicable), post mortem report (if conducted).",
    "A copy of a cancelled cheque and the PAN card copy (in case amount of reimbursement is greater than Rs 1 lac).",
  ],
  SpecifiedConditions: [
    "Arthritis (if non-infective), Osteoarthritis and Osteoporosis",
    "Gout, Rheumatism and Spinal disorders, Joint Replacement Surgery ",
    "Benign Ear, Nose and Throat (ENT) disorders and surgeries (including but not limited to Adenoidectomy, Mastoidectomy, Tonsillectomy and Tympanoplasty), Nasal Septum Deviation, Sinusitis and related disorders",
    "Benign Prostatic Hypertrophy ",
    "Cataract",
    "Dilatation and Curettage ",
    "Fissure/Fistula in anus, Hemorrhoids/Piles, Pilonidal Sinus, Gastric and Duodenal Ulcers; Surgery of Genitourinary System unless necessitated by malignancy",
    "All types of Hernia, Hydrocele",
    "Hysterectomy for Menorrhagia or Fibromyoma or prolapse of uterus unless necessitated by malignancy",
    "Internal tumors, skin tumors, cysts, nodules, polyps including breast lumps (each of any kind) unless malignant ",
    "Kidney Stone / Ureteral Stone / Lithotripsy / Gall Bladder Stone ",
    "Myomectomy for Fibroids",
    "Varicose Veins and Varicose Ulcers",
  ],
  PreExistingDefinition:
    "A pre-existing illness is defined as any condition, illness or Injury for which the \
      Insured Member had signs or symptoms, or was diagnosed, or received Medical Advice or treatment within 48 months\
       prior to the policy being issued.",
  Maternity: [
    "Normal or Cesarean delivery will be covered for the primary insured member or spouse, with a coverage up to Rs 50,000.",
    "All prenatal or postnatal treatment expenses (within +- 30 days of hospitalization) will be covered up to Rs 5000 within the maternity benefit limit.",
    "New Born Baby cover will be provided from day one, up to the maternity cover limit.",
  ],
  Note1: [
    "50% copay for cyberknife treatments",
    "Mental illness covered up to Rs 30,000 in a hospital",
    "AYUSH treatment covered in Govt. Recognized Hospitals",
  ],
  Note2: [
    "LASIK surgery is covered if the correction index is +/- 7.5",
    "50% copay for cyberknife treatments",
    "Mental illness covered up to Rs 30,000 in a hospital",
    "AYUSH treatment covered in Govt. Recognized Hospitals",
  ],
  PreExistingDef:
    "A pre-existing illness is defined as any condition, illness or Injury for which the Insured Member had signs or symptoms, or was diagnosed, or received Medical Advice or treatment within 48 months prior to the policy being issued.",
};
