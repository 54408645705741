import React, { Component, useEffect, useState } from 'react'
import styled from 'styled-components'
import {Ptag} from '../fonts'


const BenefitOfferSection = ({heading, bodyText, showAsPoints=true}) => {
    
    const formatInstruction = (statement) => {
        let urlStartIndex = statement.indexOf('{url}')
        let urlEndIndex = statement.indexOf('{/url}')
        if(urlStartIndex > -1 && urlEndIndex > -1){
            let part1 = statement.slice(0, urlStartIndex) + ' '
            let link = statement.slice(urlStartIndex + 5, urlEndIndex)
            let redirectLink = 'http://' + link
            let part2 = statement.slice(urlEndIndex + 6)
        
            return(
                <InstructionDiv >
                    <InstructionText>
                        {part1}
                        <Link href={redirectLink}  marginRight={part2 === "." ? "0px" : "5px"}>
                            {redirectLink}
                        </Link>
                        {part2}
                    </InstructionText>
                </InstructionDiv>
                
            )
        } else {
            return (
                <InstructionDiv>
                    <InstructionText>
                        {statement}
                    </InstructionText>
                </InstructionDiv>
            )
        }
    }

    return(
        <> 
            {
                bodyText && bodyText.length && 
                <Section>
                    <Ptag letterSpacing='1.14px' fontSize='16px' fontWeight='600' >
                        {heading}
                    </Ptag>
                    {
                        bodyText.map( (text,index) => 
                            <>
                                {
                                    text && 
                                    <SectionPoint key={index}>
                                        {showAsPoints && 
                                            <DotWrapper id={index} >
                                                <Dot/>
                                            </DotWrapper>
                                        }
                                        {formatInstruction(text)}
                                    </SectionPoint>
                                }
                            </>
                            
                        )
                    }
                </Section>
            }
        </>
    )
}

export default BenefitOfferSection


const Section = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgb(216, 216, 216);
    & > :first-child{
        margin-bottom: 10px;
    }
    padding: 24px 0;
`

const Dot = styled.div`
    display: flex;
    background-color: #4a4a4a;
    border-radius: 50%;
    height: 6px;
    width: 6px;
`

const DotWrapper = styled.div`
    display: flex;
    margin-right: 12px;
    margin-top: 11px;
`

const SectionPoint = styled.div`
    display: flex;   
`

const InstructionDiv = styled.div`
    display: flex;
    margin-right: 12px;
`

const InstructionText = styled(Ptag)`
    font-size: 16px;
    line-height: 1.71;
    letter-spacing: 0.5px;
`

const Link = styled.a`
    font-family: Avenir;
    color: blue;
    font-size: 16px;
    line-height: 1.71;
    letter-spacing: 0.5px;
    text-decoration: underline;
    margin-right: ${(props) => (props.marginRight ? props.marginRight : "0px")}
`