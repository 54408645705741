import styled from "styled-components"
import { theme } from "../../../theme"

export const FlexProductList = styled.table`
  width: 100%;
  border: ${(props) => `1px solid ${props.theme.primaryColor}`};
  overflow: hidden;
  border-radius: 10px;
  height: 3rem;
  margin-bottom: 2rem;
  border-collapse: separate;
  border-spacing: 0;
  background: #F9FAFD;
  float: left;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const IndividualList = styled.th`
  cursor: pointer;
  max-width: 50px;
  border-left: ${(props) => `0.3px solid ${props.theme.primaryColor}`};
  border-right: ${(props) => `0.5px solid ${props.theme.primaryColor}`};
  padding: 1.4rem 1rem;
  background: ${(props) => (props.bgColor ? props.bgColor : "transparent")};
`;
