import React from 'react'
import styled from 'styled-components'
import concentricCircle from '../assets/images/concentricCircle2.png'
import creditPage from '../assets/images/creditPage2.png'

const Wrapper = styled.div`
  width: 100%;
  padding-top: 8rem;
  background-color: #eeeee0;
  position: relative;

  @media (min-width: 1024px) {

  }

  @media (min-width: 1680px) {

  }
`

const Logo1 = styled.div`
  width: 155px;
  height: 352px;
  opacity: 0.85;
  background: url(${concentricCircle});
  background-size: cover;
  position: absolute;
  top: 24%;
  left: 0;
`
const H2 = styled.h2`
font-family: Avenir;
  font-size: 16px;
  // width: 32.7rem;
  padding-right: 12px;
  padding-left: 2.8rem;
  padding-top: 2.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.01px;
  // text-align: center;
  color: #4a4a4a;
  // z-index: 100;

  @media (min-width: 1024px) {
    // font-size: 34px;
    margin-left: 48px;
    font-family: Avenir;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: #4a4a4a;
  }
`

const H1 = styled.h1`
font-family: Proxima Nova;
  font-size: 34px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  // line-height: 1.35;
  // letter-spacing: 0.02px;
  // text-align: center;
  color: #4a4a4a;
  padding-left: 2.8rem;
  padding-top: 2.7rem;
  width: 330px;


  @media (min-width: 1024px) {
    // font-size: 6rem;
    width: 100%;
    margin-left: 48px;
    padding-left: 2.8rem;
    margin-top: 140px;
    font-family: Proxima Nova;
    font-size: 44px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 56px;
    letter-spacing: 0.44px;
    color: #4a4a4a;
  }

  @media (min-width: 1680px) {
    font-size: 6.2rem;
    // line-height: 1.3;
    margin-left: 48px;

  }
`
const Header = styled.div`
    width: 100%;
    positon: relative;

    @media (min-width: 1024px) {
      display: flex;
      justify-content: flex-end;
      font-size: 34px;
      padding-top: 34px;
    }
  
    @media (min-width: 1680px) {
      display: flex;
      align-items: center;
      font-size: 48px;
    }
`
const Image = styled.img`
    display: flex;
    width: 375px;
    z-index: 10;
    // object-fit: cover;
    
    @media (min-width: 1024px) {
      display: flex;
      width: 723px;
      height: 753px;
    }

    @media (min-width: 1680px) {
      height: 753px;
      width: 723px;
    }


    
    
`
const IWrapper = styled.div`
      display: flex;
      padding-top: 48px;
      width: 100%;
      align-items: center;
      justify-content: center;

      @media (min-width: 1024px) {
        display: flex;
        width: 723px;
        // height: 753px;
    }
`
const SubHeader = styled.div`
display: flex;
flex-direction: column;

@media (min-width: 1024px) {
    padding-left: 36px;
}

@media (min-width: 1680px) {
    margin-top: -240px;
}
`

export default function Page40() {
  return (
    <Wrapper>
      <Header>
        <SubHeader>
      <H1> A credit line specifically for health expenses </H1>
      <H2>
           Convert elective health and wellness expenses into affordable monthly installments
      </H2>
      </SubHeader>
      <IWrapper>
	  {/* <Image src={creditPage}/> */}
    <Image alt="loan for medical treatment" src = "http://images.getvital.in/homelanding/creditPage2.png" />
    </IWrapper>
    <Logo1 />
    </Header>
    </Wrapper>
  )
}
