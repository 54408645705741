import React, { Component } from 'react'
import styled from 'styled-components'
import check from '../../assets/images/lottie/checkMark.json'
import Lottie from 'react-lottie'
import TagManager from 'react-gtm-module'
import {gtmCode} from '../../utils/utils'
import {withRouter} from 'react-router'
import paymentImg from '../../assets/images/paymentsuccessbusiness.png'
import paymentPro from '../../assets/images/paymentProcessing.png'
import Loader from './overLayLoaderBusiness'
import dashboardBox from './dashboardBox'

 class PaymentSuccessful extends Component {
  // e_category = this.props.location.pathname

  // componentDidMount(){
  //   const tagManagerArgs = {
  //     gtmId: gtmCode(),
  //   }
  //   TagManager.initialize(tagManagerArgs)
  // }

  // onClickButton = () => {
  //   localStorage.removeItem('employees')
  //   localStorage.removeItem('selectedPlan')
  //   localStorage.removeItem('premium')
  //   localStorage.removeItem('via_subscription')
  //   localStorage.removeItem('via_subscription_to_details')
    
  //   window.history.pushState(null, null, '/')
  //   if (localStorage.getItem('subscriptionPayment')){
  //     this.props.history.push('/business/payment')
  //   } else {
  //     this.props.history.push('/business/dashboard')
  //   }
  // }
dashboard=()=>{
  window.history.pushState(null, null, '/')
  this.props.history.push('/business/dashboard')
}
   
  render() {
    // const defaultOptions = {
    //   loop: 0,
    //   autoplay: true,
    //   animationData: check,
    //   rendererSettings: {
    //     preserveAspectRatio: 'xMidYMid slice'
    //   }
    // }
    return (
      <Root>
        <Popup>
          {/* <IconWrapper>
            {/* <Lottie height={60} width={60} /> */}
          {/* </IconWrapper>  */}
             <img src={paymentPro} width="150px" height="150px"></img>
          <Text style={{fontSize:18}}>Payment Successful</Text>
          <LineDiv />
          <SearchBarWrapper>
            <Button style={{border:0}} id="business_paymentsuccess_continue" onClick={()=>this.dashboard()}>
              <Text1>Continue</Text1>
            </Button>
          </SearchBarWrapper>
        </Popup>
      </Root>
    )
  }
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`
const Popup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  vertical-align: middle;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  width: 100%;
  padding-top: 48px;
  margin-right: 20px;
  background-color: #ffffff;
`
const Text = styled.p`
  margin: 32px 0 24px 0;
  text-align: center;
  font-family: Avenir;
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;

`
const TextSmall = styled.p`
  font-family: Avenir;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: 0.21px;
  text-align: center;
  color: #666666;
`
const SearchBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 18px;
  padding-right: 18px;
  margin-bottom: 32px;
  justify-content: center;
  width: 100%;
`
const Button = styled.button`
  display: flex;
  width: 65%;
  text-align: center;
  margin: 12px 0px 0 0px;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  height: 54px;
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  background-color: #436ab2;
`
const Text1 = styled.p`
  font-family: Avenir;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 1px;
`
const IconWrapper = styled.div`
  display: flex;
  height:120px;
  width: 120px;
`
const LineDiv = styled.div`
  display: flex;
  width: 100%;
  height: 1px;
  margin-top: 14px;
  border-radius: 12px;
  /* background-color: rgba(247, 201, 201, 0.37); */
`
export default withRouter(PaymentSuccessful)