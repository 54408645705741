import styled from "styled-components";

export const DefaultCardWrapper = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #f6f6f6;
  border-radius: 10px;
  padding: 22px;
`;

export const CardRow = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
`;

export const IconWrapper = styled.span`
  display: flex;
  align-items: baseline;
  & > img {
    width: 1.4rem;
    margin-right: 1rem;
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`
