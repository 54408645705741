import React, { Fragment, useEffect, useState } from "react";
import { startCase, trim } from "lodash";
import { useHistory } from "react-router-dom";

import { findInValues, formatAmount, toTitleCase } from "../../utils/utils";
import Chip from "../Chip";
import { Ptag } from "../fonts";
import { colors, theme } from "../../theme";
import {
  DownloadButton,
  DownloadLink,
  Dropdown,
  FilterImage,
  FilterWrapper,
  Option,
  SearchInput,
  SearchWrapper,
  StickyCell,
  Table,
  TableWrapper,
  TableWrapperHeader,
  TopHeader,
} from "./styles";
import DownloadIcon from "../../assets/svgs/download_white.svg";
import AddIcon from "../../assets/svgs/add_white.svg";
import FilterIcon from "../../assets/svgs/filter.svg";
import { Icons } from "..";
import http from "../../services/httpService";

const MisTable = ({ products, employeeData }) => {
  const history = useHistory();
  // Data used in table generation
  const [tableData, setTableData] = useState([]);
  // Base table data used to reset filters
  const [baseTableData, setBaseTableData] = useState([]);
  // Filter options showing above table
  const [filterOptions, setFilterOptions] = useState([]);

  // Determining which products to show
  const showGmcColumn = products.includes("GMC") || products.includes("GMC_M");
  const showGpaColumn = products.includes("GPA");
  const showGtlColumn = products.includes("GTL");

  useEffect(() => {
    const tableData = generateTableData(employeeData);
    const stoppedOptions = ["STOPPED", "DELETION INITIATED"];

    const stoppedProds = tableData.filter((pol) => {
      if (stoppedOptions.includes(pol.GMC && pol.GMC.policy_status))
        return true;
      if (stoppedOptions.includes(pol.GMC_M && pol.GMC_M.policy_status))
        return true;
      if (stoppedOptions.includes(pol.GPA && pol.GPA.policy_status))
        return true;
      if (stoppedOptions.includes(pol.GTL && pol.GTL.policy_status))
        return true;
    });
    const stoppedEmployeeIds = stoppedProds.map((pol) => pol.employee_id);
    const remainingProds = tableData.filter(
      (pol) => !stoppedEmployeeIds.includes(pol.employee_id)
    );
    const sortedTableData = [...remainingProds, ...stoppedProds];
    // Updating table and filter whenever employee data changes
    setTableData(sortedTableData);
    setBaseTableData(sortedTableData);
    updateFilters(sortedTableData);
  }, [employeeData]);

  const generateTableHeaderMarkup = (text, sticky, subtitle) => {
    let JSX = (
      <th>
        <TopHeader>
          <Ptag
            fontSize="14px"
            textAlign="center"
            lineHeight={1.3}
            color={theme.primaryColor}
            fontWeight="600"
          >
            {text}
          </Ptag>
          {subtitle && <Ptag marginLeft="27px">{subtitle}</Ptag>}
        </TopHeader>
      </th>
    );
    if (sticky) {
      JSX = (
        <StickyCell>
          <TopHeader>
            <Ptag
              fontSize="14px"
              textAlign="center"
              lineHeight={1.3}
              color={theme.primaryColor}
              fontWeight="600"
            >
              {text}
            </Ptag>
            {subtitle && <Ptag marginLeft="27px">{subtitle}</Ptag>}
          </TopHeader>
        </StickyCell>
      );
    }
    return JSX;
  };

  // generating table data that will be used to display the table
  const generateTableData = (data) => {
    const reducer = (acc, value) => {
      // employees data of product -> [value]
      const empData = data[value].employees_data;
      // temp previous table data
      const newTableData = acc;
      empData.forEach((employee) => {
        // whether employee is already in the new table data or not
        const empIdx = acc.findIndex(
          (prevEmp) => prevEmp.employee_id === employee.employee_id
        );
        // if already present, then only adding the new product's info
        if (empIdx > -1) {
          newTableData[empIdx][value] = {
            policy_number: employee.policy_number,
            policy_status: employee.policy_status,
            employee_uhid: employee.employee_uhid,
            end_date: employee.end_date,
            start_date: employee.start_date,
            sum_insured: employee.sum_insured,
            refund: employee.refund,
          };
          // if not present, storing all the common and first product's info
        } else {
          const obj = {
            name: employee.name,
            gender: employee.gender,
            dependents: employee.dependents,
            dob: employee.dob,
            email: employee.email,
            employee_id: employee.employee_id,
            enrollment_id: employee.enrollment_id,
            phone: employee.phone,
            no_of_dependents: employee.no_of_dependents,
            date_of_addition: employee.date_of_addition,
            date_of_deletion: employee.date_of_deletion,
            [value]: {
              policy_number: employee.policy_number,
              policy_status: employee.policy_status,
              employee_uhid: employee.employee_uhid,
              end_date: employee.end_date,
              start_date: employee.start_date,
              sum_insured: employee.sum_insured,
              refund: employee.refund,
            },
          };
          newTableData.push(obj);
        }
      });
      return newTableData;
    };
    return Object.keys(data).reduce(reducer, []);
  };

  const getProductStatusChip = (productName, productStatus) => {
    switch (productStatus) {
      case "ACTIVATION PENDING":
        return <Chip background="#F7CB59" text={`${productName} Pending`} />;
      case "ACTIVATED":
        return <Chip background="#81C14B" text={`${productName} Activated`} />;
      case "DELETION INITIATED":
        return (
          <Chip
            background="#FF9446"
            text={`${productName} Deletion Initiated`}
          />
        );
      case "STOPPED":
        return <Chip background="#F27576" text={`${productName} Stopped`} />;
      default:
        return null;
    }
  };

  // rendering status chips in the table
  const renderStatusChips = (individualPolicy) => {
    // storing the JSX for chips
    let JSX = [];
    // determining whether product is present for the employee
    const gmcExist = individualPolicy["GMC"];
    const gmcMaternityExist = individualPolicy["GMC_M"];
    const gpaExist = individualPolicy["GPA"];
    const gtlExist = individualPolicy["GTL"];

    const gmcStatus = gmcExist && individualPolicy["GMC"].policy_status;
    const gmcMaternityStatus =
      gmcMaternityExist && individualPolicy["GMC_M"].policy_status;
    const gpaStatus = gpaExist && individualPolicy["GPA"].policy_status;
    const gtlStatus = gtlExist && individualPolicy["GTL"].policy_status;

    if (products.includes("GMC")) {
      JSX.push(getProductStatusChip("GHI", gmcStatus));
    }

    if (products.includes("GMC_M")) {
      JSX.push(getProductStatusChip("GHI", gmcMaternityStatus));
    }

    if (products.includes("GPA")) {
      JSX.push(getProductStatusChip("GPA", gpaStatus));
    }

    if (products.includes("GTL")) {
      JSX.push(getProductStatusChip("GTL", gtlStatus));
    }

    return (
      <section style={{ gap: "0.6rem", display: "flex" }}>
        {JSX.map((el) => el)}
      </section>
    );
  };

  const renderTableCell = (_, text) => {
    return (
      <td>
        <TopHeader>
          <Ptag
            textAlign="center"
            fontSize="14px"
            lineHeight={1.3}
            fontWeight="500"
          >
            {text}
          </Ptag>
        </TopHeader>
      </td>
    );
  };

  const getIndividualStatusNames = (type, status) => {
    if (status === "ACTIVATION PENDING") {
      return `${type} Pending`;
    }
    return `${type} ${startCase(status.toLowerCase())}`;
  };

  const updateFilters = (tableData) => {
    const reducer = (acc, employee) => {
      let newArr = acc;
      if (employee["GMC"] && employee["GMC"].policy_status) {
        newArr = [
          ...new Set(newArr).add(
            getIndividualStatusNames("GHI", employee["GMC"].policy_status)
          ),
        ];
      }
      if (employee["GPA"] && employee["GPA"].policy_status) {
        newArr = [
          ...new Set(newArr).add(
            getIndividualStatusNames("GPA", employee["GPA"].policy_status)
          ),
        ];
      }
      if (employee["GTL"] && employee["GTL"].policy_status) {
        newArr = [
          ...new Set(newArr).add(
            getIndividualStatusNames("GTL", employee["GTL"].policy_status)
          ),
        ];
      }
      return newArr;
    };
    const options = tableData.reduce(reducer, []);
    setFilterOptions(options);
  };

  const searchTable = (e) => {
    e.persist();
    const searchText = trim(e.target.value);
    if (searchText.length > 0) {
      const newArr = findInValues(baseTableData, searchText);
      setTableData(newArr);
      // this.setState({ tableData: newArr, filterText: 'Filter' })
    } else {
      setTableData(baseTableData);
      // this.setState({ tableData: this.state.arrForSearch, filterText: 'Filter' })
    }
  };

  // navigating to e-card preview page with employee id as state
  const navigateToECardPreview = (detail) => {
    history.push({
      pathname: "/business/e-card-preview",
      state: { employeeId: detail.employee_id },
    });
  };

  // downloading excel by firing API
  const downloadExcel = async () => {
    // sending request and resetting the reponse type blob
    const { data } = await http.post(
      "business/download_mis_data/",
      employeeData,
      { responseType: "blob" }
    );

    // creating a link node to download the excel
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(new Blob([data]));
    link.download = "employee_data.xlsx";

    // Append link to the DOM and click it
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);

    // below is the local download code
    // const tableEl = document.getElementById("excel-to-be-downloaded");
    // const wb = window.XLSX.utils.table_to_book(tableEl);

    // /* Export to file (start a download) */
    // window.XLSX.writeFile(wb, "mis_table.xlsx");
  };

  // navigating to employee addition/deletion page
  const navigateToEmployeeAdditionDeletion = () => {
    history.push("/business/addDeleteEmployee");
  };

  // filtering based on the selected dropdown filter
  const dropdownFilterHandler = (event) => {
    const val = event.target.value;
    console.log({val})
    // Setting to base table data when no filter is selected
    if (val === "none") {
      console.log("IN", baseTableData)
      setTableData(baseTableData);
    } else {
      const filteredArray = baseTableData.filter((member) => {
        const gmcForUserExist = member["GMC"];
        const gmcMaternityForUserExist = member["GMC_M"];
        const gpaForUserExist = member["GPA"];
        const gtlForUserExist = member["GTL"];
        const conditions = {
          "ghi activated": () =>
            gmcForUserExist && gmcForUserExist.policy_status === "ACTIVATED",
          "gpa activated": () =>
            gpaForUserExist && gpaForUserExist.policy_status === "ACTIVATED",
          "gtl activated": () =>
            gtlForUserExist && gtlForUserExist.policy_status === "ACTIVATED",
          "ghi pending": () =>
            (products.includes("GMC") && !gmcForUserExist) ||
            member["GMC"].policy_status === "ACTIVATION PENDING",
          "gpa pending": () =>
            (products.includes("GPA") && !gpaForUserExist) ||
            member["GPA"].policy_status === "ACTIVATION PENDING",
          "gtl pending": () =>
            (products.includes("GTL") && !gtlForUserExist) ||
            member["GTL"].policy_status === "ACTIVATION PENDING",
          "ghi deletion initiated": () =>
            products.includes("GMC") &&
            gmcForUserExist &&
            member["GMC"].policy_status === "DELETION INITIATED",
          "gpa deletion initiated": () =>
            products.includes("GPA") &&
            gpaForUserExist &&
            member["GPA"].policy_status === "DELETION INITIATED",
          "gtl deletion initiated": () =>
            products.includes("GTL") &&
            gtlForUserExist &&
            member["GTL"].policy_status === "DELETION INITIATED",
          "ghi stopped": () =>
            products.includes("GMC") &&
            gmcForUserExist &&
            member["GMC"].policy_status === "STOPPED",
          "gpa stopped": () =>
            products.includes("GPA") &&
            gpaForUserExist &&
            member["GPA"].policy_status === "STOPPED",
          "gtl stopped": () =>
            products.includes("GTL") &&
            gtlForUserExist &&
            member["GTL"].policy_status === "STOPPED",
        };

        for (const key in conditions) {
          if (key === val && conditions[key]()) {
            return true;
          }
        }
        return false;
      });

      setTableData(filteredArray);
    }
  };

  const excelTable = () => {
    const numbers = Array.from({ length: 5 }, (_, i) => i + 1);
    const generateDependentsHeaders = () => {
      return numbers.map((_, index) => (
        <Fragment key={index.toString()}>
          <th>Dependent Type</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>DOB</th>
        </Fragment>
      ));
    };
    const generateProductsHeaders = () => {
      let JSX = [];
      if (products.includes("GMC")) {
        JSX.push(
          <>
            <th>UHID</th>
            <th>Sum Insured</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Refund</th>
            <th>Group Policy Number</th>
            <th>Status</th>
          </>
        );
      }
      if (products.includes("GPA")) {
        JSX.push(
          <>
            <th>UHID</th>
            <th>Sum Insured</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Refund</th>
            <th>Group Policy Number</th>
            <th>Status</th>
          </>
        );
      }
      if (products.includes("GTL")) {
        JSX.push(
          <>
            <th>UHID</th>
            <th>Sum Insured</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Refund</th>
            <th>Group Policy Number</th>
            <th>Status</th>
          </>
        );
      }
      return JSX.map((el) => el);
    };
    const generateDependentData = (dependents) => {
      const totalDependents = dependents.length;
      const emptyIterations = Array(7 - totalDependents).fill(0);

      return (
        <>
          {dependents.map((dependent, index) => (
            <Fragment key={index.toString()}>
              <td>{dependent && dependent.relation}</td>
              <td>{dependent && dependent.first_name}</td>
              <td>{dependent && dependent.last_name}</td>
              <td>{dependent && dependent.dob}</td>
            </Fragment>
          ))}
          {emptyIterations.map((_, index) => (
            <Fragment key={index.toString()}>
              <td> </td>
              <td> </td>
              <td> </td>
              <td> </td>
            </Fragment>
          ))}
        </>
      );
    };
    return (
      <table
        style={{ display: "none" }}
        id="excel-to-be-downloaded"
        width="2000px"
      >
        <thead>
          <tr>
            <th colSpan="6"> </th>
            {numbers.map((num, index) => (
              <th key={index.toString()} colSpan="4">
                Dependent {num}
              </th>
            ))}
            {products.includes("GMC") && <th colSpan="7">GHI</th>}
            {products.includes("GPA") && <th colSpan="7">GPA</th>}
            {products.includes("GTL") && <th colSpan="7">GTL</th>}
          </tr>
          <tr>
            <th>Name</th>
            <th>Gender</th>
            <th>Date Of Birth</th>
            <th>Email</th>
            <th>Mobile</th>
            <th>Dependents</th>
            {generateDependentsHeaders()}
            {generateProductsHeaders()}
          </tr>
        </thead>
        <tbody>
          {baseTableData.map((employee) => (
            <tr>
              <td>{employee.name}</td>
              <td>{employee.gender}</td>
              <td>{employee.dob}</td>
              <td>{employee.email}</td>
              <td>{employee.phone}</td>
              <td>{employee.no_of_dependents}</td>
              {generateDependentData(employee.dependents)}
              {products.includes("GMC") && (
                <>
                  <td>
                    {employee && employee.GMC && employee.GMC.employee_uhid}
                  </td>
                  <td>
                    {employee && employee.GMC && employee.GMC.sum_insured}
                  </td>
                  <td>{employee && employee.GMC && employee.GMC.start_date}</td>
                  <td>{employee && employee.GMC && employee.GMC.end_date}</td>
                  <td>{employee && employee.GMC && employee.GMC.refund}</td>
                  <td>
                    {employee && employee.GMC && employee.GMC.policy_number}
                  </td>
                  <td>
                    {(employee && employee.GMC && employee.GMC.policy_status) ||
                      "ACTIVATION PENDING"}
                  </td>
                </>
              )}
              {products.includes("GPA") && (
                <>
                  <td>
                    {employee && employee.GPA && employee.GPA.employee_uhid}
                  </td>
                  <td>
                    {employee && employee.GPA && employee.GPA.sum_insured}
                  </td>
                  <td>{employee && employee.GPA && employee.GPA.start_date}</td>
                  <td>{employee && employee.GPA && employee.GPA.end_date}</td>
                  <td>{employee && employee.GPA && employee.GPA.refund}</td>
                  <td>
                    {employee && employee.GPA && employee.GPA.policy_number}
                  </td>
                  <td>
                    {(employee & employee.GPA && employee.GPA.policy_status) ||
                      "ACTIVATION PENDING"}
                  </td>
                </>
              )}
              {products.includes("GTL") && (
                <>
                  <td>
                    {employee && employee.GTL && employee.GTL.employee_uhid}
                  </td>
                  <td>
                    {employee && employee.GTL && employee.GTL.sum_insured}
                  </td>
                  <td>{employee && employee.GTL && employee.GTL.start_date}</td>
                  <td>{employee && employee.GTL && employee.GTL.end_date}</td>
                  <td>{employee && employee.GTL && employee.GTL.refund}</td>
                  <td>
                    {employee && employee.GTL && employee.GTL.policy_number}
                  </td>
                  <td>
                    {(employee && employee.GTL && employee.GTL.policy_status) ||
                      "ACTIVATION PENDING"}
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const isActivatedPolicyPresent = (detail) => {
    if (
      products.includes("GMC") &&
      detail["GMC"] &&
      detail["GMC"].policy_status === "ACTIVATED"
    ) {
      return true;
    }
    if (
      products.includes("GMC_M") &&
      detail["GMC_M"] &&
      detail["GMC_M"].policy_status === "ACTIVATED"
    ) {
      return true;
    }
    if (
      products.includes("GPA") &&
      detail["GPA"] &&
      detail["GPA"].policy_status === "ACTIVATED"
    ) {
      return true;
    }
    if (
      products.includes("GTL") &&
      detail["GTL"] &&
      detail["GTL"].policy_status === "ACTIVATED"
    ) {
      return true;
    }
    return false;
  };

  return (
    <section>
      <TableWrapperHeader>
        <Ptag fontSize="24px" fontWeight="700">
          Manage Employees
        </Ptag>
        <div style={{ display: "flex" }}>
          <DownloadButton onClick={downloadExcel}>
            <img
              src={DownloadIcon}
              width="18px"
              height="18px"
              style={{ marginRight: 10 }}
            />
            <Ptag
              fontSize="14px"
              fontWeight="600"
              color="#FFFFFF"
              lineHeight="24px"
              letterSpacing="0.5px"
            >
              Download
            </Ptag>
          </DownloadButton>

          {/* Disabling endorsements for GMCs */}
          {/* Disabling endorsement for GMCs */}
          {/* <DownloadButton onClick={navigateToEmployeeAdditionDeletion}>
            <img
              src={AddIcon}
              width="16px"
              height="16px"
              style={{ marginRight: 10 }}
            />
            <Ptag
              fontSize="14px"
              fontWeight="600"
              color="#FFFFFF"
              lineHeight="24px"
              letterSpacing="0.5px"
            >
              Add/Delete Employee
            </Ptag>
          </DownloadButton> */}
        </div>
      </TableWrapperHeader>
      <SearchWrapper>
        <SearchInput>
          <input
            onChange={(e) => searchTable(e)}
            type="text"
            placeholder="Search Employee"
          />
        </SearchInput>
        <FilterWrapper>
          <FilterImage src={FilterIcon} />
          <Dropdown onChange={dropdownFilterHandler} color="#fff">
            <Option value="" default disabled selected hidden>
              Filter
            </Option>

            <Option value={"none"}>{"None"}</Option>

            {filterOptions &&
              filterOptions.map((item, key) => (
                <Option value={item.toLowerCase()} key={`FilterArray${key}`}>
                  {item}
                </Option>
              ))}
          </Dropdown>
          <div style={{ marginRight: "12px" }}>
            <Icons icon={"chevron_down"} fill="#ffffff" />
          </div>
        </FilterWrapper>
      </SearchWrapper>
      <TableWrapper>
        <Table id="table-to-xls" width="100%" cellSpacing="0" cellPadding="6">
          <thead>
            <tr>
              {generateTableHeaderMarkup("Employee ID", true)}
              {generateTableHeaderMarkup("Name")}
              {generateTableHeaderMarkup("Gender")}
              {generateTableHeaderMarkup("Date of Birth")}
              {generateTableHeaderMarkup("Email")}
              {generateTableHeaderMarkup("Mobile")}
              {generateTableHeaderMarkup("Dependents")}
              {showGmcColumn && <>{generateTableHeaderMarkup("GHI Cover")}</>}
              {showGpaColumn && <>{generateTableHeaderMarkup("GPA Cover")}</>}
              {showGtlColumn && <>{generateTableHeaderMarkup("GTL Cover")}</>}
              {generateTableHeaderMarkup("E-Card")}
              <th>
                <TopHeader>
                  <Ptag
                    fontSize="14px"
                    textAlign="left"
                    lineHeight={1.3}
                    color={colors.primaryColor}
                    fontWeight="600 !important"
                  >
                    Status
                  </Ptag>
                </TopHeader>
              </th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((individualPolicy, index) => (
              <tr
                key={index.toString()}
                style={{
                  background: "#fff",
                }}
              >
                <StickyCell bgColor="#ffffff">
                  <TopHeader>
                    <Ptag
                      textAlign="center"
                      fontSize="14px"
                      lineHeight={1.3}
                      fontWeight="600"
                    >
                      {individualPolicy.enrollment_id || "-"}
                    </Ptag>
                  </TopHeader>
                </StickyCell>
                {renderTableCell(individualPolicy, individualPolicy.name)}
                {renderTableCell(
                  individualPolicy,
                  toTitleCase(individualPolicy.gender)
                )}
                {renderTableCell(individualPolicy, individualPolicy.dob || "-")}
                {renderTableCell(individualPolicy, individualPolicy.email || '-')}
                {renderTableCell(individualPolicy, individualPolicy.phone || '-')}
                {renderTableCell(
                  individualPolicy,
                  individualPolicy.no_of_dependents
                )}
                {showGmcColumn && individualPolicy && individualPolicy["GMC"] && (
                  <>
                    {renderTableCell(
                      individualPolicy,
                      individualPolicy["GMC"] &&
                        individualPolicy["GMC"].sum_insured
                        ? `₹${formatAmount(
                            individualPolicy["GMC"].sum_insured
                          )}`
                        : "-"
                    )}
                  </>
                )}
                {showGmcColumn && individualPolicy && individualPolicy["GMC_M"] && (
                  <>
                    {renderTableCell(
                      individualPolicy,
                      individualPolicy["GMC_M"] &&
                        individualPolicy["GMC_M"].sum_insured
                        ? `₹${formatAmount(
                            individualPolicy["GMC_M"].sum_insured
                          )}`
                        : "-"
                    )}
                  </>
                )}
                {showGpaColumn && (
                  <>
                    {renderTableCell(
                      individualPolicy,
                      individualPolicy["GPA"] &&
                        individualPolicy["GPA"].sum_insured
                        ? `₹${formatAmount(
                            individualPolicy["GPA"].sum_insured
                          )}`
                        : "-"
                    )}
                  </>
                )}
                {showGtlColumn && (
                  <>
                    {renderTableCell(
                      individualPolicy,
                      individualPolicy["GTL"] &&
                        individualPolicy["GTL"].sum_insured
                        ? `₹${formatAmount(
                            individualPolicy["GTL"].sum_insured
                          )}`
                        : "-"
                    )}
                  </>
                )}
                <td>
                  <TopHeader style={{ display: "flex" }}>
                    {isActivatedPolicyPresent(individualPolicy) ? (
                      <DownloadLink
                        onClick={() => navigateToECardPreview(individualPolicy)}
                      >
                        View
                      </DownloadLink>
                    ) : (
                      <Ptag
                        textAlign="center"
                        fontSize="14px"
                        lineHeight={1.3}
                        fontWeight="500"
                      >
                        -
                      </Ptag>
                    )}
                  </TopHeader>
                </td>
                <td>
                  <TopHeader style={{ display: "flex" }}>
                    {renderStatusChips(individualPolicy)}
                  </TopHeader>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
      {excelTable()}
    </section>
  );
};

export default MisTable;
