import React, { Component } from 'react'
import styled from 'styled-components'
import Icons from '../components/icons'

export default class AboutPremium extends Component{
    render () {
        return (
            <Home>
                <Header>
                    <Font24>What is Premium?</Font24>
                    <IconDiv onClick={() => this.props.BackBtn()} >
                    <Icons icon="cross" fill="#ffffff" height="24" width="24" />
                    </IconDiv>
                </Header>

                <Body>
                    <Font14>
                        Premium is the amount you need to pay every month to keep the plan active
                    </Font14>   

                    <Font14 fontWeight="600" marginTop="18px" marginBottom="6px">
                        NOTE :
                    </Font14>

                    <Font14>
                        Premium amount is not accounted in your deductible
                    </Font14>
                </Body>

            </Home>
        )
    }
}


const Header = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: row;
    height: 116px;
    background-color: #6db7b7;
`
const Font24 = styled.p`
    font-family: Proxima Nova;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #ffffff;
    margin: 61px 0px 31px 24px;
`
const Body = styled.div`
    display: flex;
    flex-direction: column;
    margin: 24px;   
`
const Font14 = styled.div`
    display: flex;
    font-family: Avenir;
    font-size: 14px;
    font-weight: ${props => (props.fontWeight ? props.fontWeight : '500')};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.5px;
    color: #666666;
    margin-top: ${props => (props.marginTop ? props.marginTop : 'none')}
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 'none')}
`
const Image = styled.div`
`
const Home = styled.div`
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
`
const IconDiv = styled.div`
    display: flex;
    position: absolute;
    right: 0px;
    margin: 12px 12px 0px 0px;
`
