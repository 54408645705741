import React, { Component } from 'react'
import styled from 'styled-components'
import ImageA from '../assets/svgs/imageA.svg'
import ImageB from '../assets/svgs/imageB.svg'
import ImageZ from '../assets/svgs/imageZ.svg'

export default class Page12 extends Component {
    render = () => {
        return (
            <Wrapper id={this.props.id}>
                <Header>
                <H2>
                    Bringing together 3 key offerings on 1 platform
                </H2>
                <SubHeading>
                    {/* Exclusive offers for regular health and 
                    wellness expenses, personalized<br />
                    low-cost insurance plans for unforeseen 
                    hospitalization events, and EMI financing 
                    for those treatments you always wanted. */}
                </SubHeading>
                <SubHeader>
                <OuterDiv>
                    <ImageDv>
                        <Image alt="Irresistible Offers & Discounts" src={ImageZ}/>
                        </ImageDv>
                        <H3>
                            1. Irresistible Offers & Discounts 
                        </H3>
                </OuterDiv>
                <OuterDiv>
                    <ImageDv>
                        <Image alt="Super Affordable Insurance" src={ImageA}/>
                        </ImageDv>
                        <H3>
                            2.  Smart Health Plan
                        </H3>
                </OuterDiv>
                <OuterDiv>
                    <ImageDv>
                        <Image alt="No cost EMI network" src={ImageB}/>
                        </ImageDv>
                        <H3 paddingBottom="36px">
                            3. No cost EMI network 
                        </H3>
                </OuterDiv>
                </SubHeader>
                </ Header>
            </Wrapper>
        )
    }
}

const Wrapper = styled.div`
    width: 100%;
    positon: relative;
    background-color: #f4f4f4;
    align-items: center;
    justify-content: center;
`
const Header = styled.div`
    width: 100%;
    positon: relative;

    @media (min-width: 1024px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 34px;
      }
    
      @media (min-width: 1680px) {
        display: flex;
        align-items: center;
        font-size: 48px;
      }
`
const SubHeader = styled.div`
      width; 100%;

      @media (min-width: 1024px) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-size: 34px;
        width: 95rem;
        padding-bottom: 140px;
        padding-top: 36px;
      }
    
      @media (min-width: 1680px) {
        display: flex;
        align-items: center;
        font-size: 48px;
      }
`
const H2 = styled.h2`
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: Proxima Nova;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.86px;
    color: #4a4a4a;
    padding: 36px 0px 12px 0px;

    @media (min-width: 1024px) {
        width: 100%;
        font-size: 3.4rem;
        line-height: 1.59;
        letter-spacing: 0.02px;
        padding-top: 170px;
      }
    
      @media (min-width: 1680px) {
        font-size: 6.2rem;
        line-height: 1.61;
        letter-spacing: 0.03px;
      }
`
const SubHeading = styled.div`
    font-family: Avenir;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.5px;
    text-align: center;
    color: #4a4a4a;
    padding: 0 24px 0 24px;

    @media (min-width: 1024px) {
        width: 65rem;
        // margin-top: 2rem;
        font-size: 1.8rem;
        line-height: 1.56;
        letter-spacing: 0px;
      }
    
      @media (min-width: 1680px) {
        margin-top: 4.5rem;
        width: 69.3rem;
        font-size: 3.4rem;
        line-height: 1.59;
        letter-spacing: 0.02px;
      }
`
const OuterDiv = styled.div`
    width: 100%;
    // height: 148.6px;
    @media (min-width: 1024px){
        height: 148.6px;
    }
`
const ImageDv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 37px;
`
const H3 = styled.h3`
    margin-top: 18px;
    padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : 'none')}
    font-family: Avenir;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.57px;
    text-align: center;
    color: #4a4a4a;

    @media (min-width: 1680px) {
        // margin-top: 4.5rem;
        // width: 69.3rem;
        font-size: 2.4rem;
        // line-height: 1.59;
        // letter-spacing: 0.02px;
      }
`
const Image = styled.img`
    height: 72px;
    width: 72px;
`
