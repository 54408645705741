import React from "react";
import BenefitCard from "./BenefitCard";
import { BenefitsWrapper } from "./styles";

const BenefitCardList = ({ benefits }) => {
  return (
    <BenefitsWrapper>
      {benefits.map((benefit, index) => (
        <BenefitCard key={index.toString()} detail={benefit} />
      ))}
    </BenefitsWrapper>
  );
};

export default BenefitCardList;