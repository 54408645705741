export const FreshPolicyDisclaimer = [
    "The plan is on a non-selection basis i.e. the organization cannot choose to exclude any employees or dependents (in case the policy includes coverage for dependents). Excluding a class within a group or employee set from coverage is not permitted.",
    "This quote is valid only for fresh business, any renewal or rollover policies will need specific approvals.",
    "The quote is valid for the given set of data, any change in data will require a revised premium for group health insurance.",
    "A mid-term increase in the cover amount due to a change in the level of the employee (promotion) is allowed, but the extra coverage will not be valid for claims within the same year.",
    "If the organization wants to cover employees’ dependents within the policy, then the same needs to be declared while purchasing the policy. In case we have opted for an employee-only type of policy, we can only include spouses after marriage or children by birth during the policy term. No other dependents can be added in between the policy term.",
    "The organization must inform about new employee additions within a reasonable time but not later than 30 days from the date of joining the organization.", 
    "On exit of employees, deletion of employees should be updated at the earliest. In case there is a failure to remove the employee, any liability incurred on claims filed by such employee (after the exit) will be borne by the organization.",
    "Contractors; temporary, seasonal, substitute, uncompensated employees; volunteers, silent partners, shareholders or investors only; owners, officers, or managing members (who are not active, permanent, full-time employees) cannot be included in the coverage.",
    "Dependent children can only be included for up to 24 years of age.",
    "Individuals cannot be covered as both employee and dependent under the same policy. Furthermore, no member can be covered in multiple policies within the same organization.",
    "We authorise VITAL to share the enrolled member details with its respective partner service providers including and not limited to the insurance company to enable them to provide the respective benefits and services."
]

export const RenewPolicyDisclaimer = [
    "The plan is on a non-selection basis i.e. the organization cannot choose to exclude any employees or dependents (in case the policy includes coverage for dependents). Excluding a class within a group or employee set from coverage is not permitted.",
    "The quote is valid for the given set of data, any change in data will require a revised premium for group health insurance.",
    "A mid-term increase in the cover amount due to a change in the level of the employee (promotion) is allowed, but the extra coverage will not be valid for claims within the same year.",
    "If the organization wants to cover employees’ dependents within the policy, then the same needs to be declared while purchasing the policy. In case we have opted for an employee-only type of policy, we can only include spouses after marriage or children by birth during the policy term. No other dependents can be added in between the policy term.",
    "The organization must inform about new employee additions within a reasonable time but not later than 30 days from the date of joining the organization.",
    "On exit of employees, deletion of employees should be updated at the earliest. In case there is a failure to remove the employee, any liability incurred on claims filed by such employee (after the exit) will be borne by the organization.",
    "Contractors; temporary, seasonal, substitute, uncompensated employees; volunteers, silent partners, shareholders or investors only; owners, officers, or managing members (who are not active, permanent, full-time employees) cannot be included in the coverage.",
    "Dependent children can only be included for up to 24 years of age.",
    "Individuals cannot be covered as both employee and dependent under the same policy. Furthermore, no member can be covered in multiple policies within the same organization.",
    "We authorise VITAL to share the enrolled member details with its respective partner service providers including and not limited to the insurance company to enable them to provide the respective benefits and services."
] 


export const RepaymentDisclaimer = [
    "The plan is on a non-selection basis i.e. the organization cannot choose to exclude any employees or dependents (in case the policy includes coverage for dependents). Excluding a class within a group or employee set from coverage is not permitted.",
    "This quote is valid only for fresh business, any renewal or rollover policies will need specific approvals.",
    "The quote is valid for the given set of data, any change in data will require a revised premium for group health insurance.",
    "A mid-term increase in the cover amount due to a change in the level of the employee (promotion) is allowed, but the extra coverage will not be valid for claims within the same year.",
    "If the organization wants to cover employees’ dependents within the policy, then the same needs to be declared while purchasing the policy. In case we have opted for an employee-only type of policy, we can only include spouses after marriage or children by birth during the policy term. No other dependents can be added in between the policy term.",
    "The organization must inform about new employee additions within a reasonable time but not later than 30 days from the date of joining the organization.", 
    "On exit of employees, deletion of employees should be updated at the earliest. In case there is a failure to remove the employee, any liability incurred on claims filed by such employee (after the exit) will be borne by the organization.",
    "Contractors; temporary, seasonal, substitute, uncompensated employees; volunteers, silent partners, shareholders or investors only; owners, officers, or managing members (who are not active, permanent, full-time employees) cannot be included in the coverage.",
    "Dependent children can only be included for up to 24 years of age.",
    "Individuals cannot be covered as both employee and dependent under the same policy. Furthermore, no member can be covered in multiple policies within the same organization.",
    "We authorise VITAL to share the enrolled member details with its respective partner service providers including and not limited to the insurance company to enable them to provide the respective benefits and services."
]
