import React, { Component } from "react";
import styled from "styled-components";
import Credit5 from "../assets/svgs/credit5.svg";
import religare3x from "../assets/images/religare@3x.png";
import iciciI from "../assets/images/iciciAbout.png";
import dmiAbout from "../assets/images/dmiAbout.png";
import mucihAbout from "../assets/images/mucihAbout.png";

const Wrapper = styled.div`
  width: 100%;
  background: rgba(238, 238, 238, 1);
  padding: 2px 0 3.6rem 0;
`;
const Image = styled.img`
  background: rgba(238, 238, 238, 1);
  margin-right: 0px;
`;

const Header = styled.div`
  width: 32rem;
  padding-left: 2.4rem;
  // margin-bottom: rem;
  @media (min-width: 1024px) {
    display: flex;
    font-size: 34px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

// const Image = styled.img`
//     background: rgba(238, 238, 238, 1);
//     margin-right: 36px;

//     @media (min-width: 1024px) {
//       // width: ${props => (props.width ? props.width : '129px')};
//       // height: ${props => (props.height ? props.height : '32px')};
//       width: 193px;
//       height: 72px;
//       margin-bottom: 60px;
//     }
// `
const Font24 = styled.div`
  font-family: Proxima Nova;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 1px;
  color: #009999;
  padding-top: 36px;

  @media (min-width: 1024px) {
    display: flex;
    font-size: 34px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;
const Iwrapper = styled.div`
  display: flex;
  padding-top: 26px;
  align-items: center;
  justify-content: space-around;
  margin-left: 2.4rem;
  margin-right: 2.4rem;

  @media (min-width: 1024px) {
    margin-right: 4.8rem;
  }

  img:nth-of-type(1) {
    width: 71px;
    height: 71px;

    @media (min-width: 1024px) {
      width: 146px;
      height: 148px;
      margin-right: 48px;
    }
  }
  img:nth-of-type(2) {
    width: 154px;
    height: 32px;

    @media (min-width: 1024px) {
      width: 231px;
      height: 48px;
    }
  }
`;
const Lwrapper = styled.div`
  display: flex;
  padding-top: 26px;
  align-items: center;
  justify-content: space-around;
  margin-left: 24px;
  margin-right: 2.4rem;

  img:nth-of-type(1) {
    width: 96px;
    height: 56px;

    @media (min-width: 1024px) {
      width: 130px;
      height: 80px;
      margin-left: -38px;
    }
  }
  img:nth-of-type(2) {
    width: 155px;
    height: 48px;

    @media (min-width: 1024px) {
      width: 180.8px;
      height: 56px;
      padding-left: 36px;
    }
  }
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
`;

export default class Page44 extends Component {
  render = () => {
    return (
      <React.Fragment>
        <Wrapper>
          <Header>
            <Font24> Our Partners</Font24>
          </Header>
          <MainWrapper>
            <Iwrapper>
              {/* <Image src={religare3x} width='129px' height='32px' /> */}
              {/* <Image src={iciciI} width='129px' height='32px' /> */}
              <Image alt="religare insurance" src={religare3x} />
              <Image alt="icici lombard" src={iciciI} />
            </Iwrapper>
            <Lwrapper>
              {/* <Image src = {mucihAbout} width='155px' height='48px' /> */}
              {/* <Image src = {dmiAbout}  width='95.7px' height='56px'/> */}
              <Image alt="DMI finance" src={dmiAbout} />
              <Image alt="appolo munich" src={mucihAbout} />
            </Lwrapper>
          </MainWrapper>
        </Wrapper>
      </React.Fragment>
    );
  };
}
