import React from "react";
import styled from "styled-components";
import { Ptag } from "../../components/fonts";


import PolicyType from "./constants/PolicyConstants.js";

const InsuranceBenefits = ({adults, policyType, benefits: Benefits}) => {

    
    const getCardBackgroundColor = () => {
        const policy = policyType;
        const background = {
          [PolicyType.COMPREHENSIVE]: "#436ab2",
          [PolicyType.HEART]: "#ff5588",
          [PolicyType.PED_INSULIN]: "#009999",
          [PolicyType.PED_NON_INSULIN]: "rgba(247, 121, 99)",
          [PolicyType.WELLNESS]: '#97b872'
        };
        return background[policy];
      };
    

    return (
      <>
        {Benefits && Benefits.map( item => 
          <BlueWrapper key={item['details'] && item['details']['id']} border={getCardBackgroundColor()}>
            <CardHeader paddingBottom='16px'>
              <CardHeaderTileTitle>
                <CardIcon  src={item['details'] && item['details']['header_icon_url']}/>
                <Ptag fontSize='16px' color='#436ab2' letterSpacing='0.57px' fontWeight='600'>
                  {`${item['details']['heading']}`}
                </Ptag>
              </CardHeaderTileTitle>
              
              <ThirdParty marginBottomText={item['details'] && item['details']['marginBottom']}>
                <Ptag fontSize='8px' letterSpacing='0.5px' textAlign='right' color='rgba(0,0,0,0.6)' textTransform='uppercase'>
                  {item['details'] && item['details']['sideHeading']}
                </Ptag>
                <img src={item['details'] && item['details']['side_icon_url']} height={'28px'} width='auto' />
              </ThirdParty>
            </CardHeader>
            <Ptag style={{'marginTop': '16px'}} color='#666666' letterSpacing='0.5px' letterHeight='1.8' fontSize='14px'>
              {item['details'] && item['details']['description'] && item['details']['description'].replace('${adults}', adults)}
            </Ptag>
            {
              item['tests'] &&
                <TestsWrapper>
                    {
                       item['tests'].map( test => 
                          <Test key={test['id']}>
                            
                            <img src={test['icon_url']}/>
                            <Ptag textTransform='capitalize'  color='#666666' maxwidth="90px" textAlign='center' fontSize='11px' letterSpacing='0.5px' >
                              {test['heading']}
                            </Ptag>
                            <Ptag textAlign='center' fontSize='9px' fontWeight='600' color='#4a4a4a'>
                              {test['subheading']}
                            </Ptag>
                            
                          </Test>
                        )
                    }
                </TestsWrapper> 
             
            }
          </BlueWrapper>

        )}
      </>
      
    )    
  }

export default InsuranceBenefits

const BlueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.border};
  border-radius: 10px;
  padding: 18px 14px;
  margin-bottom: 16px;
`


const CardHeaderTileTitle = styled.div`
  display: flex;
  align-items: center;
  & > img{
    margin-right: 4px;
  }
`

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: -18px -14px 0 -14px;
  padding: ${props => props.paddingBottom ? `18px 14px ${props.paddingBottom} 14px` : '18px 14px 26px 14px'};
  background-color: blue;
  border: 1px solid #dadada;
  border-radius: 10px 10px 0 0;
  background-color: #e0eafd;
`


const CardIcon = styled.img`
  width:auto !important;
  height: 36px;
`


const TestsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -14px;
  ::-webkit-scrollbar {
    display: none;
  }
`

const Test = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 28%;
  max-width: 28%;
  margin-right: 16px;
  margin-top: 24px;
  & > img{
    margin-bottom: 8px;
    object-fit: contain;
    height: 36px;
  };
`


const ThirdParty = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  align-items: center;
  & > p {
    margin-bottom: ${props => props.marginBottomText || '4px'};
  };
  & > img {
    object-fit: contain;
  }
`