import React, { Component } from "react";
import styled from "styled-components";


const Page = ({children, ...rest}) => {

    return(
        <Home {...rest} >
            {children}
        </Home>
    )
}

export default Page

const Home = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 90vh;
`