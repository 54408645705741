import React, { Component } from 'react'
import styled from 'styled-components'
import { Icons, LargeButton } from './'
import { Ptag } from './fonts'
import cross from '../assets/images/cross.png'
import config from "../config.json";
import TagManager from "react-gtm-module";
export default class HealthQuestions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      error:''
    }
  }

  onClickSubmit = (cardId) => {
    const { questionResponses, onClose } = this.props;
    let diseaseNames="";
    Object.keys(questionResponses).forEach(function(key,index) {
      diseaseNames+=key+',';
    });
    const tagManagerArgs1 = {
      gtmId: 'GTM-MDCMM7K',
      dataLayer: {
        event:'gvIn_Add_Continue_button',
        gvIn_Add_health_conditions:diseaseNames
      }
    }
    if (config.env === 'prod'){
      TagManager.initialize(tagManagerArgs1)
    }
    let error = {}
    for (let question of this.props.questions){
      
      if (questionResponses[question['key']] && question['subquestions'].length){
        for (let subq of question['subquestions']){
          if (questionResponses[subq['key']] === undefined){
            let yOffset = window.document.getElementById(question['key'].toString()).offsetTop
            document.getElementById('home').scrollTop = yOffset - 20
            error[subq['key']] = true
          }
        }
      }
    }

    return Object.keys(error).length > 0 ? this.setState({error}) : onClose(cardId) 
    
  }

  sortQuestion = (subquestion1, subquestion2) => subquestion1['id'] - subquestion2['id']

  checkSubQuestionValue = (questionResponses, questionID, subQuestionID) => {
    let questionObj = questionResponses && questionResponses.filter(question => question['question'] === questionID)
    return (
      questionObj && questionObj.length && questionObj[0] && questionObj[0]['subquestion'].indexOf(subQuestionID) > -1
    )
  }

  formatQuestion = (text, i) => {
    let alphabet = ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","t","u","v","w","x","y","z"];
    return `${alphabet[i]}. ${text}`
  }

  render() {
    const { onChange, cardId, questionResponses, hideOthers } = this.props
    let questions = this.props.questions
    if (hideOthers) {
      questions = questions.slice(0, 4)
      questions.push({
        id: 6969,
        subquestions: [],
        question: 'Others',
        gender: 'Male',
        key: 'others6969'
      })
    }
    return (
      <Home id='home' showModal={this.props.openQusn}>
        <Body>
          <img onClick={() => this.onClickSubmit(cardId)} style={{'marginLeft': '94%', opacity: '55%'}} src={cross} width='24px' height='24px'/>
          <Ptag fontWeight="600" letterHeight="58px" fontSize="24px">
            {'Health conditions?'}
          </Ptag>
          {questions && questions.map((obj, index) => (
            <div key={index} id={obj['key']}>
              <Question>
                <QuestionHeading>
                  <Ptag
                    style={{ marginRight: '8px', verticalAlign: 'middle' }}
                    fontWeight="600"
                    letterSpacing="0.5px"
                    fontSize="16px">
                    {`${index + 1}. `}
                  </Ptag>
                  <QuestionRow>
                    <Ptag style={{'marginRight': '8px'}} fontWeight="600" letterSpacing="0.5px" letterHeight="23px" fontSize="16px">
                      {obj['question']}
                    </Ptag>
                    <CheckboxWrapper onClick={() => onChange(cardId, obj['key'])}>
                      <Icons
                        icon={questionResponses && questionResponses[obj['key']] ? 'checkedCircle' : 'uncheckedCircle'}
                      />
                    </CheckboxWrapper>
                  </QuestionRow>
                </QuestionHeading>

                {obj['subquestions'] && obj['subquestions'].length > 0 && questionResponses && questionResponses[obj['key']] &&
                  <SubquestionContainer>
                    <Ptag letterSpacing='0.75px' fontWeight='600' fontSize='12px'>
                      {'Please share these additional details :'}
                    </Ptag>
                    {obj['subquestions'].sort(this.sortQuestion).map((subquestion, i) => (
                      <SubQuestionRow key={i}>
                        <Ptag fontWeight="500" letterSpacing="0.44px" fontSize="14px" letterHeight='1.71'>
                          {this.formatQuestion(subquestion['subquestion'], i)}
                        </Ptag>
                        <YesNoRow>
                          <Button onClick={() => onChange(cardId, subquestion['key'], true)} selected={questionResponses[subquestion['key']] === true}>{'YES'}</Button>
                          <Button onClick={() => onChange(cardId, subquestion['key'], false)} selected={questionResponses[subquestion['key']] === false}>{'NO'}</Button>
                        </YesNoRow>
                          {this.state.error[subquestion['key']] === true && questionResponses[obj['key']] && questionResponses[subquestion['key']] === undefined && 
                            <Error>
                              {'*Required'}
                            </Error>
                          }
                        
                      </SubQuestionRow>
                      ))
                    }
                  </SubquestionContainer>
                }
              </Question>
              <Line />
            </div>
          ))}
          
          
        </Body>
        <ButtonWrapper >
          <LargeButton style={{'height': '52px'}} type={'button'} onClick={() => this.onClickSubmit(cardId)} label="CONTINUE" />
        </ButtonWrapper>
      </Home>
    )
  }
}

const Home = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  flex-direction: column;
  transform: ${props => (props.showModal === true ? 'translateY(0) scaleY(100%)' : 'translateY(102%) scaleY(0)')};
  transition: all 0.6s;
`

const Body = styled.div`
  padding: 36px 24px;
  margin-bottom: 40px;
`

const ButtonWrapper = styled.div`
  position:fixed;
  bottom:0;
  background-color: white;
  width: 100%;
  padding: 12px 24px 24px 24px;
`

const Question = styled.div`
  display: flex;
  flex-direction: column;
  margin: 18px 0;
`

const Line = styled.div`
  border-bottom: 1px solid #4a4a4a;
`

const QuestionHeading = styled.div`
  display: flex;
  flex-direction: row;
`

const QuestionRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const SubQuestionRow = styled.div`
  display:flex;
  flex-direction: column;
  margin: 12px 0 6px 0;
`

const YesNoRow = styled.div`
  display: flex;
  margin: 16px 0 0 0;
`

const SubquestionContainer =styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #f7f7f7;
  padding: 18px 24px;
  margin-top: 20px;
`;


const CheckboxWrapper = styled.div`
  width: 24px;
  height: 24px;
`

const Button = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;
  width: 98px;
  height: 30px;
  margin-right: 16px;
  border-radius: 5px;
  border: 1px solid #436ab2;
  background-color: ${props => props.selected ? '#436ab2' : 'transparent'};
  font-family: Avenir;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  text-align: center;
  color: ${props => props.selected ? '#ffffff' : '#4a4a4a'};
`

const Error = styled.p`
  color: red;
  font-size: 1.4rem;
  margin-top: 0.8rem;
  z-index: 9;
`
