import React, { Component } from 'react'
import auth from '../../services/authService'
import {Navbar} from '../../components/business'
import { OverlayLoader } from '../../components'
export default class Homepage extends Component{

    constructor(props){
        super(props)
    }
    componentDidMount() {
        if(auth.getCurrentUser())
        {
            this.props.history.push('/business/dashboard')
        }
        localStorage.clear()
        sessionStorage.clear()
        localStorage.setItem('businessAccount',true)
        window.location.href = '/business'
    }

    render(){
        return(
            <OverlayLoader/>
        )
    }

}