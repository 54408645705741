import React from 'react'
import styled from 'styled-components'
import vitalLogoHomePage from '../assets/images/vitalLogoHomePage.png'
import facebook from '../assets/images/fb.png'
import twitter from '../assets/images/twitter.png'
import insta from '../assets/images/insta.png'
import linkedIn from '../assets/images/linkedIn.png'
import { withRouter } from 'react-router'
import * as config from '../config.json'

const FooterWrapper = styled.footer`
  //padding-top: 3.6rem;

  @media (min-width: 425px) {
    //padding-top: 6.4rem;
  }

  // > div:first-child {
  //   // padding: 0 2.4rem;
  // }
`

const Header = styled.div`
  border-bottom: 1px solid #7e7b7f;
  padding-bottom: 1.8rem;
  img {
    width: 148px;
	height: 48px;
	margin-bottom: 12px;
  }

  @media (min-width: 425px) {
    margin: 0 5.4rem;
    padding-bottom: 3.6rem;

    img {
		width: 148px;
		height: 48px;
		margin-bottom: 12px;
    }
  }
`

const Body = styled.div`
  padding: 0 2.4rem;
  background-color: #666666;
  section:last-child {
    border: 0;
  }

  @media (min-width: 1024px) {
    display: flex;
    padding: 8px 5.4rem;

    section:first-child {
      // width: 15%;
    }
    section:nth-child(2) {
      // width: 31%;
    }
    section:last-child {
      // width: 34.7%;
    }
  }
`

const Section = styled.section`
  padding: ${props => (props.padding ? props.padding : '1.4rem 0 1.8rem')};
  width: 100%;
  @media (min-width: 425px) {
    border: 0;
  }
  @media (min-width: 1024px) {
    width: ${props => (props.width ? props.width : '50%')};
  }
`
const NewSection = styled.section`
  background-color: #666666;
  display: ${props => (props.desktop ? 'flex' : 'none')};
  padding: ${props => (props.padding ? props.padding : '2.4rem')};
  width: 100%;
  border-bottom: 1px solid #fff;
  @media (min-width: 425px) {
    border: 0;
    display: ${props => (props.desktop ? 'none' : 'flex')};
  }
`

const SocialMediaIcons = styled.div`
  display: ${props => (props.desktop ? 'flex' : 'none')};
  flex-direction: row;
  background-color: #666666;
  @media (min-width: 425px) {
    padding-top: 20px;
    display: ${props => (props.desktop ? 'none' : 'flex')};
    border: 0;
    width: ${props => (props.width ? props.width : '50%')};
  }
`;

const Line = styled.div`
  height: 1px;
  display:flex;
  background-color: rgba(255,255,255,1);
`;

const OurPolicy = styled.div`
  padding: 2rem 0 2rem 2.4rem;
  background-color: #666666;;
  @media (min-width: 1024px) {
    display: flex;
    padding: 1.6rem 2.6%;
    // padding: 0 5.4rem;
    // padding: 3.2rem 5.4rem;
    > div:last-child {
      border: 0;
    }
    div:first-child {
	  width: 35%;
	  padding: 1.4rem 1.6rem 1.4rem 0rem;
    }
    div {
      width: 27%;
      border-right: 1px solid #7e7b7f;
      padding: 1.4rem 5.4rem;
    }
  }

  > div:first-child {
    padding-left: 0;
  }
`

const FooterExtras = styled.div`
  padding: 2rem 0 2rem 2.4rem;
  background-color: #666666;;
  @media (min-width: 1024px) {
    display: flex;
    padding: 1.6rem 2.6%;
    > div:last-child {
      border: 0;
    }
    div:first-child {
	  padding: 1.4rem 5.4rem 1.4rem 0rem;
    }
    div {
      border-right: 1px solid rgba(255,255,255,0.41);
      padding: 1.4rem 5.4rem;
    }
  }

  > div:first-child {
    padding-left: 0;
  }
`


const OurPolicyText = styled.h3`
font-family: Avenir;
font-size: 1.4rem;
font-weight: ${props => (props.fontWeight ? props.fontWeight : '600')};
font-style: normal;
font-stretch: normal;
line-height: ${props => (props.lineHeight ? props.lineHeight : 2)};
letter-spacing: 2px;
color: #ffffff;
text-transform: ${props => (props.textTransform ? props.textTransform : 'uppercase')};
white-space: nowrap;
cursor: ${props => (props.cursor ? props.cursor : 'default')};
span {
  text-transform: none;
}

@media (min-width: 425px) {
  font-size: ${props => (props.fontSize ? props.fontSize : '1.4rem')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '600')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : '36px')};
  letter-spacing: ${props => (props.letterSpacing ? props.letterSpacing : '1px')};
}
`;

const Link = styled.a`
font-family: Avenir;
font-size: 1.2rem;
font-weight: ${props => (props.fontWeight ? props.fontWeight : '600')};
font-style: normal;
font-stretch: normal;
line-height: ${props => (props.lineHeight ? props.lineHeight : 2)};
letter-spacing: 2px;
color: #ffffff;
text-transform: lowercase;
span {
  text-transform: none;
}

@media (min-width: 425px) {
  font-size: ${props => (props.fontSize ? props.fontSize : '1.6rem')};
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '600')};
  line-height: ${props => (props.lineHeight ? props.lineHeight : 2)};
  letter-spacing: ${props => (props.letterSpacing ? props.letterSpacing : '4px')};
}
`;

const Font12 = styled.p`
  font-family: Avenir;
  font-size: 1.2rem;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : '600')};
  font-style: normal;
  font-stretch: normal;
  line-height: ${props => (props.lineHeight ? props.lineHeight : 2)};
  letter-spacing: 2px;
  color: ${props => (props.color ? props.color : '#ffffff')};
  text-transform: ${props => (props.textTransform ? props.textTransform : 'uppercase')};
  span {
    text-transform: none;
  }

  @media (min-width: 425px) {
    font-size: ${props => (props.fontSize ? props.fontSize : '2.4rem')};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : '600')};
    line-height: ${props => (props.lineHeight ? props.lineHeight : 2)};
    letter-spacing: ${props => (props.letterSpacing ? props.letterSpacing : '4px')};
  }
`

const SocialIcon = styled.img`
  height: 24px;
  width: 24px;
  cursor: pointer;
  margin: 2.6% 18px 24px 0%;
  @media (min-width: 425px) {
    height: 36px;
    width: 36px;
  }
`;

const Footer = (props) => {

  const getPrivacyPolicy=() =>{
    window.scrollTo(0,0)
    props.history.push({
      pathname: '/privacypolicydoc',
    })
  }
    const getTermsAndCondition=() =>{
      window.scrollTo(0,0)
      props.history.push({
        pathname: '/termsandconditiondoc',
      })
  }
  const getaboutUs = () => {
    window.scrollTo(0,0)
    props.history.push({
      pathname: "/aboutus"
    })
  }

  const buildTime = config['version']

  return(
  <FooterWrapper>
    <Body>
      {/* <Section padding='1.4rem 2.4rem;'>
        <Font12 fontWeight="700" lineHeight="3" style={{'white-space': 'nowrap'}}>
          QUICK LINKS
        </Font12>
        <Font12 lineHeight="3" style={{'white-space': 'nowrap'}} >VITAL POINTS</Font12>
        <Font12 lineHeight="3" style={{'white-space': 'nowrap'}}>VITAL INSURANCE</Font12>
        <Font12 lineHeight="3" style={{'white-space': 'nowrap'}}>VITAL CREDIT</Font12>
      </Section> */}
      {/* <Section>
        <Font12 fontWeight="600" lineHeight="3">
          home
        </Font12>
        <Font12 lineHeight="3">about us</Font12>
        <Font12 lineHeight="3">blog</Font12>
        <Font12 lineHeight="3">claim</Font12>
        <Font12 lineHeight="3">POLICY CANCELLATION & REFUND </Font12>
        <Font12 lineHeight="3">FAQ</Font12>
      </Section> */}
      <Section width='60%'>
        <Font12 fontWeight="700" lineHeight="3">
          CONTACT OUR CARE MANAGER
        </Font12>
        <Font12 style={{'whiteSpace': 'nowrap'}} fontSize="1.6rem" lineHeight="2.25" letterSpacing="2.67px" fontWeight="normal">
          LIFEFRONT COVER PRIVATE LIMITED
        </Font12>
        <Font12 style={{'whiteSpace': 'nowrap'}} fontSize="1.6rem" lineHeight="2.25" letterSpacing="2.67px" fontWeight="normal">
          Plot No. 9, Okhla Industrial Estate,
        </Font12>
	      	<Font12 style={{'whiteSpace': 'nowrap'}} fontSize="1.6rem" lineHeight="2.25" letterSpacing="2.67px" fontWeight="normal">
          Near Modi Flour Mill, New Delhi – 110020
        </Font12>
      </Section>
	  <Section>
		  <SocialMediaIcons>
	  		<SocialIcon src={facebook} onClick={() => window.open('https://www.facebook.com/getvitalnow')}/>
        <SocialIcon src={twitter} onClick={() => window.open('https://twitter.com/get_vital')}/>
        <SocialIcon src={insta} onClick={() => window.open('https://instagram.com/getvitalofficial')}/>
        <SocialIcon src={linkedIn} onClick={() => window.open('https://www.linkedin.com/company/getvital')}/>
      </SocialMediaIcons>
	  	<Font12 fontSize="1.6rem" fontWeight="600" style={{'whiteSpace': 'nowrap'}}>
          EMAIL : <Link href="mailto:care@getvital.in" target="_top">care@getvital.in</Link>
        </Font12>
        <Font12 style={{'whiteSpace': 'nowrap'}} fontSize="1.6rem" fontWeight="600">PHONE: <Link href="tel:+01141171787">01141171787</Link></Font12>
	  </Section>
    </Body>

	<Line/>
	
    <OurPolicy>
      <div>
        <OurPolicyText>
          Corporate Agent (Composite):
        </OurPolicyText>
        <OurPolicyText fontWeight='normal'>
          Lifefront Cover Private Limited
        </OurPolicyText>
      </div>
      <div>
        <OurPolicyText>
          IRDAI registration No:
        </OurPolicyText>
        <OurPolicyText fontWeight='normal'>
          CA0676
        </OurPolicyText>
      </div>
      <div>
        <OurPolicyText>
          Valid Till:
        </OurPolicyText>
        <OurPolicyText fontWeight='normal'>
          26 Aug 2022
        </OurPolicyText>
      </div>
      <div>
        <OurPolicyText>
          CIN Number :
        </OurPolicyText>
        <OurPolicyText fontWeight="normal">
          U66000DL2019PTC344015
        </OurPolicyText>
      </div>
    </OurPolicy>
    <Line/>
    <NewSection desktop={true} padding='2.4rem 2.4rem 0 2.4rem'>
		  <SocialMediaIcons desktop={true}>
	  		<SocialIcon src={facebook} onClick={() => window.open('https://www.facebook.com/getvitalnow')}/>
        <SocialIcon src={twitter} onClick={() => window.open('https://twitter.com/get_vital')}/>
        <SocialIcon src={insta} onClick={() => window.open('https://instagram.com/getvitalofficial')}/>
        <SocialIcon src={linkedIn} onClick={() => window.open('https://www.linkedin.com/company/getvital')}/>
      </SocialMediaIcons>
	  </NewSection>
    <FooterExtras>
      <div>
        <OurPolicyText cursor='pointer' fontWeight="normal" onClick={getPrivacyPolicy}>
        PRIVACY POLICY
        </OurPolicyText>
      </div>
      <div>
        <OurPolicyText cursor='pointer' fontWeight="normal" onClick={getTermsAndCondition}>
        TERMS & CONDITIONS        
        </OurPolicyText>
      </div>
      <div>
        <OurPolicyText fontWeight="normal">
        SITEMAP        
        </OurPolicyText>
      </div>
      <div>
        <OurPolicyText cursor='pointer' fontWeight="normal" onClick={getaboutUs}>
        ABOUT US        
        </OurPolicyText>
      </div>
      <div>
        <OurPolicyText fontWeight="normal">
        VERSION: {buildTime}
        </OurPolicyText>
      </div>
    </FooterExtras>

  </FooterWrapper>
     );
    }


export default withRouter(Footer)
