import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import {Header} from '../../components/business'
import Icons from "../../components/icons";
import logger from "../../services/logService";
import {OverlayLoader} from '../../components'
import config from '../../config.json'
import axios from 'axios'
import http from '../../services/httpService'
import {maxScreenWidth} from '../../config.json'
import TagManager from 'react-gtm-module'
import {gtmCode} from '../../utils/utils'


export default class UploadPanDetails extends Component {
  state = {
    files: [],
    compressedFiles: [],
    uploading: false,
    enableNext: false,
    navigationIntent: localStorage.getItem("navigatingFrom"),
    navigatingFor: localStorage.getItem("navigatingFor"),
    loadingButtonLabel: "UPLOADING... 0%",
    fileUploadedPercentage: 0,
    loader: false
  };

  componentDidMount(){
    const tagManagerArgs = {
        gtmId: gtmCode(),
    }
    TagManager.initialize(tagManagerArgs)
  }


  onSubmit = (oEvent) => {
    this.setState({loader: true})
    if (this.state.files.length === 0 || this.state.uploading) {
      return;
    }
    try{
        let formData = new FormData()
        formData.append('file_details', this.state.files[0])
        formData.append('file_type', 'pan')
        const id = Number(localStorage.getItem('companyId'))
        axios({
            url: config.apiUrl + '/insurance/upload_document/',
            method: 'POST',
            data: formData,
            headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            Authorization: 'JWT ' + localStorage.getItem('token')
            }
        }).then(({data}) => 

           http.patch(`/business/company/${id}/`,{
          pan_document:data['id']
        }))
        
        this.props.history.push("/business/employeraddress");
    } catch (error) {
      this.setState({ loader: false });
      logger.log(error);
      console.log(error);
    }
  };

  handleOnChange = async (e) => {
    const width = 1200;
    const height = 1600;
    const fileName = e.target.files[0].name;
    const reader = new FileReader();
    const imagesType = ['image/jpg', 'image/png', 'image/jpeg']
    reader.readAsDataURL(e.target.files[0]);
    let that = this;
    this.setState({ uploading: true, loadingButtonLabel: "Compressing File" });
    if (imagesType.indexOf(e.target.files[0]['type']) === -1){
      let files = that.state.compressedFiles;
      files.push(e.target.files[0]);
      this.setState({
        uploading: false,
        loadingButtonLabel: "NEXT",
        files: files})
      return
    }
    reader.onload = (event) => {
      const img = document.createElement("img");
      img.src = event.target.result;
      img.onload = () => {
        const elem = document.createElement("canvas");
        elem.width = width;
        elem.height = height;
        const ctx = elem.getContext("2d");
        // img.width and img.height will contain the original dimensions
        ctx.drawImage(img, 0, 0, width, height);
        ctx.canvas.toBlob(
          async (blob) => {
            const file = new File([blob], fileName, {
              type: "image/jpeg",
              lastModified: Date.now(),
            });
            let filesList = [];
            filesList.push(file);
            await that.setState({ files: filesList });
            let reader = new FileReader();
            reader.onloadend = async () => {
              let files = that.state.compressedFiles;
              files.push(reader.result);
              await that.setState({
                compressedFiles: files,
                uploading: false,
                loadingButtonLabel: "NEXT",
              });
            };
            reader.readAsDataURL(file);
          },
          "image/jpeg",
          1
        );
      };
    };
    reader.onerror = (error) => console.log(error);
  };

  dropFile = async (index) => {
    if (this.state.uploading) return;
    let files = this.state.files;
    files.splice(index, 1);
    await this.setState({ files: files });
  };

  render() {
    return (
      <Root>
        <Header onClickBack={() => this.props.history.push('/business/gst')}  progressPercentage={80}/>

        <BodyWrapper opacity={this.state.uploading ? "0.4" : "1"}>
          <UploadBillText>please upload the front side of your PAN card</UploadBillText>
          <UploaderWrapper opacity={this.state.uploading ? "0.4" : "1"}>
            <Rectangle>
              <Icons icon="cameraNew" />
              <UploadText>Click a picture</UploadText>
              {!this.state.uploading && (
                <input
                  type="file"
                  accept="image/*"
                  capture="camera"
                  name="upload"
                  onChange={(e) => this.handleOnChange(e)}
                />
              )}
            </Rectangle>
            <Rectangle>
              {!this.state.uploading && (
                <input
                  type="file"
                  name="upload"
                  accept=".pdf, .png, .jpg, .jpeg, .docx, .doc"
                  onChange={(e) => this.handleOnChange(e)}
                />
              )}

              <Icons icon="uploadDoc" />
              <UploadText>Choose from device</UploadText>
            </Rectangle>
          </UploaderWrapper>
          <ImagesList>
            {this.state.files.length > 0 &&
              this.state.files.map((value, key) => {
                return (
                  <ImageWrapper key={key}>
                    <CrossWrapper onClick={() => this.dropFile(key)}>
                      <Icons icon="roundCross" />
                    </CrossWrapper>
                    <Image src={window.URL.createObjectURL(value)} />
                  </ImageWrapper>
                );
              })}
          </ImagesList>
        </BodyWrapper>

        <Footer>
          {this.state.uploading ? (
            <OuterLoader>
              <UploadingButtonLabel>
                {this.state.loadingButtonLabel}
              </UploadingButtonLabel>
              <InnerLoader width={this.state.fileUploadedPercentage} />
            </OuterLoader>
          ) : (
            <NextButton
              id="business_uploadpan_next"
              opacity={this.state.files.length > 0 ? "1" : "0.4"}
              onClick={(oEvent) => this.onSubmit(oEvent)}
            >
              <NextButtonLabel>
                {this.state.navigatingFor === "hospitalisationBill"
                  ? "UPLOAD"
                  : "NEXT"}
              </NextButtonLabel>
            </NextButton>
          )}
        </Footer>
        {this.state.loader && <OverlayLoader/>}
      </Root>
    );
  }
}

const Animationa = keyframes`
from {
    width: 0%;
  }

  to {
    width: 100%;
  }
`;
const OuterLoader = styled.div`
  height: 54px;
  background-color: #d8d8d8;
  width: 100%;
  border-radius: 15px;
`;

const InnerLoader = styled.div`
	position: relative;
	top: -19px;  
	border-radius: 15px;
	height: 54px;
	width: ${(props) => (props.width ? props.width + "%" : "0%")};
	background-color : #436ab2;
	//animation: ${Animationa} 2s linear infinite;
	z-index:2
`;

const ImagesList = styled.div`
	padding-bottom: 100px
	display: flex;
	flex-wrap: wrap;
	//Chrome, Safari, newer versions of Opera
	::-webkit-scrollbar {
	  display: none;
	};
	//Firefox
	overflow: -moz-scrollbars-none;
	//Internet Explorer +10
	-ms-overflow-style: none;
`;

const CrossWrapper = styled.div`
  display: flex;
  position: relative;
  left: 95px;
  top: 10px;
  width: 18px;
  height: 18px;
  z-index: 2;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;
const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Footer = styled.div`
  background-color: #ffffff;
  z-index: 2;
  width: 100%;
  max-width: ${maxScreenWidth};
  position: fixed;
  bottom: 0;
  padding: 2.4rem 1.6rem 2.4rem 1.6rem;
`;
const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.4rem 2.4rem 2.4rem 1.6rem;
  opacity: ${(props) => props.opacity};
`;
const Image = styled.img`
  width: 104px;
  height: 143px;
  margin-right: 1rem;
  border: solid 1px #436ab2;
  fill: contain;
`;
const NextButton = styled.div`
	opacity: ${(props) => props.opacity};
	height: 54px;
	display:flex;
	border-radius: 15px;
	background-color: #436ab2;
	justify-content:center;
	align-items :center;
`;
const UploadingButtonLabel = styled.p`
  text-align: center;
  height: 20px;
  font-family: Proxima Nova;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.9);
  z-index: 3;
  position: relative;
  top: 19px;
`;

const NextButtonLabel = styled.p`
  text-align: center;
  height: 20px;
  font-family: Proxima Nova;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #ffffff;
`;
const UploadBillText = styled.p`
  margin-left: 5px;
  object-fit: contain;
  font-family: Proxima Nova;
  font-size: 24px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #4a4a4a;
`;

const UploaderWrapper = styled.div`
	opacity: ${(props) => (props.opacity ? props.opacity : "1")};
	display:flex;
	flex-direction: row;
	padding: 0rem 8px;
	justify-content: space-between;
	margin-top: 3.6rem;
`;

const Rectangle = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  height: 56px;
  border-radius: 10px;
  border: solid 1px #436ab2;
  & > input {
    width: 100%;
    display: none;
    position: relative;
    index: 9;
  }
`;

const UploadText = styled.p`
  font-family: Avenir;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.33px;
  text-align: center;
  color: #4a4a4a;
`;
