import React, { Component } from 'react'
import onClickOutside from 'react-onclickoutside'
import styled from 'styled-components'
import { SmallButton } from './'

const FooterWrapper = styled.div`
  width: 100%;
  background: transparent;
  padding: 0 1.6rem;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 20;
`
const Footer = styled.div`
  width: 100%;
  padding: 2.4rem 1.6rem 4rem;
  border-radius: 10px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;

  position: relative;
  left: 0;
  bottom: 0;

  & button {
    margin-top: 6.5rem;
  }
`

const Font16 = styled.p`
  font-family: Avenir;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.33px;
  color: #4a4a4a;
`
// const VoucherFooter = ({ showFooter, onHandleClick }) => {
class VoucherFooter extends Component {
  handleClickOutside(evt) {
    
    return this.props.onHandleClick(false)
    // this.state.toggleList && this.setState({ showFooter: false })
  }

  render = () => {
    return (
      <FooterWrapper>
        <Footer>
          <Font16>you are about to burn</Font16>
          <Font16 fontWeight="bold">ƥ3000 of your vital.points</Font16>
          <SmallButton label="Confirm" bgColor="#436ab2" color="#fff" />
        </Footer>
      </FooterWrapper>
    )
  }
}

export default VoucherFooter
// export default onClickOutside(VoucherFooter)
