import * as Sentry from '@sentry/browser'
import http from './httpService'
import config from '../config.json'

function init() {
  Sentry.init({
    dsn: 'https://9c5f8a266a0e4b4699b0721b9283a2fb@sentry.io/1498413',
    environment: 'development-test'
    // environment: 'staging'
  })
}

function log(error) {
  Sentry.configureScope(scope => {
    scope.setUser({ email: localStorage.getItem('email'), id: localStorage.getItem('uuid') })
  })
  Sentry.captureException(error)
}

// function postBreadCrumb({ category, message, level = Sentry.Severity.Info, data }) {
function postBreadCrumb(category, message, level = Sentry.Severity.Info, data) {
  Sentry.addBreadcrumb({
    category,
    message,
    level
  })
}

function insuranceLoggin(data) {
  if (config.loggin === true) {
    const formattedData = JSON.parse(JSON.stringify(data))
    formattedData['phone'] = localStorage.getItem('phone')
    formattedData['time'] = new Date()
    http.post('/insurance/log/', {
      data: formattedData
    })
  }
}

function setScope(email, id = null) {
  Sentry.configureScope(scope => {
    scope.setUser({ email, id })
  })
}

export default {
  init,
  log,
  insuranceLoggin,
  setScope,
  postBreadCrumb
}
