import _, { flatMap } from 'lodash'
import Loader from './overLayLoaderBusiness'
import React from "react";
import '../../App.css'
import styled from 'styled-components'
import { Ptag } from "../../components/fonts";
import http from '../../services/httpService'
import { Icons } from '../../components';
import { findInValues, formatAmount, formatBackendDate, formatDateReverse, toTitleCase } from '../../utils/utils'
import { connect } from 'react-redux'
import { CSVLink } from "react-csv";
import downloadcsv from '../../assets/images/downloadcsv.png'
import deleteIco from '../../assets/images/deleteico.png'
import filtericon from '../../assets/images/filtericon.png'
import renewalprice from '../../assets/images/priceUp.png'
import { debounce, trim, upperCase } from 'lodash'
import DeletePopup from './deletePopup';
import { withRouter } from 'react-router'
import { TerrainSharp } from '@material-ui/icons';

class DashboardList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      csvData: [],
      policies: [], showFilterArray: [], showDeletePopUp: false,
      error: { loader: true }, searchText: '',
      myCsvRef: React.createRef(), globalArray: [], subscriptionAmount: '',
      showFilterlist: false, filterText: 'Filter', value: '',arrForSearch: [],
      gmcField: false

    }
  }
  componentDidMount = async () => {
    const policies = await this.getData()
    var name = JSON.parse(localStorage.getItem('user_plan_details')).company_name
    if(localStorage.getItem('flow') === 'GMC' || localStorage.getItem('flow') === 'GMC_M'){
      this.setState({gmcField: true})
    }
    name = name.split(".", 1)
  }
  getData = async () => {
    await this.setState({ loading: true });
    try {
      const { data } = await http.get(`/business/employee_detail/`, {
        params: {
          userplan_id: localStorage.getItem('userplan_id'),
        }
      })
      if(!!data['overallsummary']['discount code']){
        var lineThroughAmount = ((data['overallsummary']['premium'] || 0) + (data['overallsummary']['platform_fee'] || 0) + (data['overallsummary']['tax'] || 0) + (data['overallsummary']['gpa_cost'] || 0)) || 0
         this.props.lineThroughSubscriptionAmount(lineThroughAmount)
      }
      var subscription_amount = ((data['overallsummary']['premium'] || 0) + (data['overallsummary']['platform_fee'] || 0) - (data['overallsummary']['discount'] || 0) + (data['overallsummary']['discounted_tax'] || 0) + (data['overallsummary']['gpa_cost'] || 0))
     
      this.props.changeTotalSubscriptionAmount(subscription_amount)
      this.props.changeTotalLives(data['overallsummary']['no_of_lives'])
      this.props.changeTotalEmp(data['overallsummary']['number_of_employee'])
      this.props.setInstantIssuanceData(data && data['instant_issuance_lot'])
      this.props.wellnessPayableAmount((data['overallsummary']['payable_amount']))
      this.props.wellnessEmployeeData( data && data['wellness_employees_data'] && data['wellness_employees_data'])
      this.props.perEmoloyeeYearlyFeee((data['overallsummary']['per_employee_wellness_fee']))
      this.props.wellnessNoOfEmps((data['overallsummary']['number_of_employees']))
      this.props.totalWellnessFee((data['overallsummary']['total_wellness_fee']))
      await localStorage.setItem("emp_data", JSON.stringify(data))
      const unique = ['None', ...new Set(data['employees_data'].map(item => item.policy_status))]
      this.setState({ policies: data['employees_data'], globalArray: data['employees_data'], showFilterArray: unique, loading: false })
      return data['employees_data']
    }
    catch (error) {
      // alert(error.response.data)
    }
  }

  renderMis = () => {
    const getBgColorRed = (individualPolicy) => {
      const colrdata = (individualPolicy.policy_status === ('DELETION INITIATED' || 'STOPPED'))
      if((individualPolicy.policy_status === 'DELETION INITIATED') || (individualPolicy.policy_status === 'STOPPED')){
          return true
      }
      return false
  }

  const tableStyle = (individualPolicy, index) => {
    const isAnnualGmcFieldsShown = this.state.showAnnualGmcFields;
    const isBgColorRed = getBgColorRed(individualPolicy);
    const isIndexEven = index % 2 === 0;
    const isRenewal = individualPolicy.updated_amount && getRenewal(individualPolicy);
  
    const style = {
      background: isBgColorRed ? 'rgba(242,158,158)' : isIndexEven ? '#ffffff' : '#D0D9E9',
      height: 48,
    };
  
    if (isAnnualGmcFieldsShown) {
      return style;
    } else if (isBgColorRed) {
      return { background: 'rgba(242,158,158)' };
    } else {
      if (isRenewal) {
        style.borderColor = '#436AB2';
        style.borderStyle = 'solid';
        style.borderWidth = '2px';
      }
      return style;
    }
  };

  const getWidth = (individualPolicy) => {
      return  (individualPolicy.updated_amount != individualPolicy.total_amount && (individualPolicy.updated_amount != 0)) ?'600':'500'
   }

   const getRenewal = (individualPolicy) => {
       return (individualPolicy.updated_amount != individualPolicy.total_amount && (individualPolicy.updated_amount != 0)) ? true : false
   }

    return (
      <TableWrapper>
        <Table id="table-to-xls" width="100%" cellSpacing="0" cellPadding="6">
          <thead>
            <tr style={{}} >
              {/* { !this.state.gmcField &&
                <StickyCell style={{ padding: '10px', }}>
                  <input onChange={() => this.selectAllPolicies()} checked={this.areAllPoliciesSelected()} type="checkbox" />
                </StickyCell>
              } */}
              { 
               this.state.gmcField &&
                  <StickyCell >
                    <TopHeader>
                      <Ptag fontSize="18px" textAlign="center" lineHeight={1.3} color={'#4a4a4a'} fontWeight="600 !important">
                        Employee UHID
                      </Ptag>
                    </TopHeader>
                 </StickyCell>
              }
              <StickyCell >
                <TopHeader>
                  <Ptag fontSize="18px" textAlign="center" lineHeight={1.3} color={'#4a4a4a'} fontWeight="600 !important">
                    Name
                  </Ptag>
                </TopHeader>
              </StickyCell>
              <td ><TopHeader><Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#4a4a4a'} fontWeight="600 !important">Gender</Ptag></TopHeader></td>
              <td ><TopHeader><Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#4a4a4a'} fontWeight="600">Date of Birth</Ptag></TopHeader></td>
              <td ><TopHeader><Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#4a4a4a'} fontWeight="600">Email</Ptag></TopHeader></td>
              <td ><TopHeader><Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#4a4a4a'} fontWeight="600">Mobile</Ptag></TopHeader></td>
              <td ><TopHeader><Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#4a4a4a'} fontWeight="600">Dependents</Ptag></TopHeader></td>
              {
                  this.state.gmcField && 
                  <td ><TopHeader><Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#4a4a4a'} fontWeight="600">Start Date</Ptag></TopHeader></td>

              }
              <td ><TopHeader><Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#4a4a4a'} fontWeight="600">Sum Insured</Ptag></TopHeader></td>
              {
                !this.state.gmcField && 
                <td ><TopHeader><Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#4a4a4a'} fontWeight="600">Subscription Amount</Ptag></TopHeader></td>
              }
              {
                !this.state.gmcField && 
                <td ><TopHeader><Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#4a4a4a'} fontWeight="600">Date of Addition</Ptag></TopHeader></td>
              }
              {
                !this.state.gmcField && 
                <td ><TopHeader><Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#4a4a4a'} fontWeight="600">Policy No.</Ptag></TopHeader></td>
              }
              {
                this.state.gmcField && 
                <td >
                  <SubTopHeader>
                    <Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#4a4a4a'} fontWeight="600">Premium</Ptag>
                    <Ptag marginLeft="27px">(Inclusive Tax)</Ptag>
                  </SubTopHeader>
                </td>
              }
              {/* {
                this.state.gmcField && 
                <td >
                  <SubTopHeader>
                    <Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#4a4a4a'} fontWeight="600">Wellness Fee</Ptag>
                    <Ptag marginLeft="10px">(Inclusive Tax)</Ptag>
                  </SubTopHeader>
                </td>
              } */}
               {
                this.state.gmcField && 
                <td ><TopHeader><Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#4a4a4a'} fontWeight="600">End Date</Ptag></TopHeader></td>
              }
              {
                this.state.gmcField && 
                <td ><TopHeader><Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#4a4a4a'} fontWeight="600">Refund</Ptag></TopHeader></td>
              }
              <td ><TopHeader><Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#4a4a4a'} fontWeight="600">Status</Ptag></TopHeader></td>
             
              {
                !this.state.gmcField &&
                <td ><TopHeader><Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#4a4a4a'} fontWeight="600">Date of Deletion</Ptag></TopHeader></td>
              }
              {/* <th ><TopHeader><Ptag fontSize="13px" textAlign="center" lineHeight={1.3} color={'white'} fontWeight="600">Edit</Ptag></TopHeader></th> */}
            </tr>
          </thead>

          <tbody>

            {this.state.policies && this.state.policies.map((individualPolicy, index) => (
              <tr key={index} style={tableStyle(individualPolicy, index)}>  
                {/* { 
                  !this.state.gmcField &&
                  (individualPolicy.policy_status !== 'STOPPED' ?
                    <StickyCell bgColor={individualPolicy.policy_status === 'STOPPED' ? 'rgba(242,158,158)' : !(index % 2) ? '#fff' : '#D0D9E9'} style={{ padding: '10px', textAlign: 'center' }}>
                      <input disabled={individualPolicy.policy_status === 'STOPPED'} onChange={() => this.onSelectPolicyItem(index)} checked={individualPolicy['isChecked']} type="checkbox" />
                    </StickyCell> :
                    <StickyCell></StickyCell>)
                } */}
                { 
                  this.state.gmcField &&
                  <StickyCell bgColor={getBgColorRed(individualPolicy) ? 'rgba(242,158,158)' : (index % 2 === 0) ? '#ffffff' : '#D0D9E9'} >
                    <TopHeader>
                      <Ptag textAlign='center' fontSize="16px" lineHeight={1.3} fontWeight={individualPolicy.updated_amount && getWidth(individualPolicy)}>
                        {individualPolicy.employee_uhid || '_'}
                      </Ptag>
                    </TopHeader>
                  </StickyCell>
                }

                <StickyCell bgColor={getBgColorRed(individualPolicy) ? 'rgba(242,158,158)' : (index % 2 === 0) ? '#ffffff' : '#D0D9E9'} >
                  <TopHeader>
                    <Ptag textAlign='center' fontSize="16px" lineHeight={1.3} fontWeight={individualPolicy.updated_amount && getWidth(individualPolicy)}>
                      {individualPolicy.name}
                    </Ptag>
                  </TopHeader>
                </StickyCell>
                <td ><TopHeader><Ptag textAlign='center' fontSize="14px" lineHeight={1.3} fontWeight={individualPolicy.updated_amount && getWidth(individualPolicy)}>{toTitleCase(individualPolicy.gender)}</Ptag></TopHeader></td>
                <td ><TopHeader><Ptag textAlign='center' fontSize="14px" lineHeight={1.3} fontWeight={individualPolicy.updated_amount && getWidth(individualPolicy)}>{individualPolicy.dob || '-'}</Ptag></TopHeader></td>
                <td ><TopHeader><Ptag textAlign='center' fontSize="14px" lineHeight={1.3} fontWeight={individualPolicy.updated_amount && getWidth(individualPolicy)}>{individualPolicy.email || '-'}</Ptag></TopHeader></td>
                <td ><TopHeader><Ptag textAlign='center' fontSize="14px" lineHeight={1.3} fontWeight={individualPolicy.updated_amount && getWidth(individualPolicy)}>{individualPolicy.phone || '-'}</Ptag></TopHeader></td>
                <td ><TopHeader><Ptag textAlign='center' fontSize="14px" lineHeight={1.3} fontWeight={individualPolicy.updated_amount && getWidth(individualPolicy)}>{individualPolicy.no_of_dependents || 0}</Ptag></TopHeader></td>
                {
                  this.state.gmcField && 
                    <td ><TopHeader><Ptag textAlign='center' fontSize="14px" lineHeight={1.3} fontWeight={individualPolicy.updated_amount && getWidth(individualPolicy)}>{(individualPolicy.start_date) || '-'}</Ptag></TopHeader></td>
                }
                <td ><TopHeader><Ptag textAlign='center' fontSize="14px" lineHeight={1.3} fontWeight={individualPolicy.updated_amount && getWidth(individualPolicy)}>₹{formatAmount(individualPolicy.sum_insured) || 0}</Ptag></TopHeader></td>
                {/* <td ><TopHeader><Ptag textAlign='center' fontSize="14px" lineHeight={1.3}>₹{formatAmount(individualPolicy.total_amount) || 0}</Ptag></TopHeader></td> */}
                { 
                  !this.state.gmcField && 
                  ((individualPolicy.updated_amount && getRenewal(individualPolicy)) ? 
                  <td >
                    <TopHeader>
                      <Ptag textAlign='center' fontSize="14px" lineHeight={1.3}>
                        <AmountTag style ={{fontWeight : '600', fontSize : '18px', lineHeight : '27.24px', letterSpacing : '0.56px'}}>
                        ₹{formatAmount(individualPolicy.updated_amount)}<RenewTag style = {{fontSize : '16px', fontWeight : '400', padding: '3px 0px 0px 4px', color : '#4a4a4a', letterSpacing : '0.88px', fontStretch : 'normal', fontStyle : 'normal'}}> ₹{individualPolicy.total_amount}</RenewTag>
                        </AmountTag>
                      <TextTag style = {{fontWeight : '400', lineHeight : '10px', fontSize : '10px',  minWidth : '180px'}}>
                        <Ptag><img src={renewalprice} width='18px' height='18px' style={{ marginRight: 10 }} /></Ptag>
                          Increased due to change in age band
                      </TextTag>
                      </Ptag>
                    </TopHeader>
                  </td>: 
                  <td ><TopHeader><Ptag textAlign='center' fontSize="14px" lineHeight={1.3} fontWeight={individualPolicy.updated_amount && getWidth(individualPolicy)}>₹{formatAmount(individualPolicy.total_amount) || 0}</Ptag></TopHeader></td>)
                }
                { 
                  !this.state.gmcField && 
                  <td ><TopHeader><Ptag textAlign='center' fontSize="14px" lineHeight={1.3} fontWeight={individualPolicy.updated_amount && getWidth(individualPolicy)}>{individualPolicy.date_of_addition || '-'}</Ptag></TopHeader></td>
                }
                {
                  !this.state.gmcField && 
                  <td ><TopHeader><Ptag textAlign='center' fontSize="14px" lineHeight={1.3} fontWeight={individualPolicy.updated_amount && getWidth(individualPolicy)}>{individualPolicy.policy_number || '-'}</Ptag></TopHeader></td>
                }
                {
                  this.state.gmcField && 
                  <td ><TopHeader><Ptag textAlign='center' fontSize="14px" lineHeight={1.3} fontWeight={individualPolicy.updated_amount && getWidth(individualPolicy)}>₹{formatAmount(individualPolicy.premium) || 0}</Ptag></TopHeader></td>
                }
                {/* {
                  this.state.gmcField && 
                  <td ><TopHeader><Ptag textAlign='center' fontSize="14px" lineHeight={1.3} fontWeight={individualPolicy.updated_amount && getWidth(individualPolicy)}>₹{formatAmount(individualPolicy.wellness_fee) || 0}</Ptag></TopHeader></td>
                } */}
                {
                  this.state.gmcField && 
                  <td ><TopHeader><Ptag textAlign='center' fontSize="14px" lineHeight={1.3} fontWeight={individualPolicy.updated_amount && getWidth(individualPolicy)}>{((individualPolicy.policy_status == 'STOPPED') ? individualPolicy.date_of_deletion: individualPolicy.end_date || '-')}</Ptag></TopHeader></td>
                }
                {
                  this.state.gmcField && 
                  <td ><TopHeader><Ptag textAlign='center' fontSize="14px" lineHeight={1.3} fontWeight={individualPolicy.updated_amount && getWidth(individualPolicy)}>₹{formatAmount(individualPolicy.refund) || 0}</Ptag></TopHeader></td>
                }
                <td ><TopHeader><Ptag textAlign='center' fontSize="14px" lineHeight={1.3} fontWeight={individualPolicy.updated_amount && getWidth(individualPolicy)}>{toTitleCase(individualPolicy.policy_status || '-')}</Ptag></TopHeader></td>
                
                {
                  !this.state.gmcField && 
                  <td ><TopHeader><Ptag textAlign='center' fontSize="14px" lineHeight={1.3} fontWeight={individualPolicy.updated_amount && getWidth(individualPolicy)}>{individualPolicy.date_of_deletion || '-'}</Ptag></TopHeader></td>  
                }       
              </tr>
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    )
  }
  
  goToAddDelete = () =>{
    // const history = useHistory()
    // history.push('/business/addDeleteEmoloyee')
    this.props.history.push({
      pathname: '/business/addDeleteEmployee',
    })
  }

  exportCsv = () => {
    if(this.state.gmcField){
      var csvStr = '"UHID","Name","Gender","Date of Birth","Email","Mobile","Dependents","Sum Insured","Start Date","Premium","End Date","Refund","Status","Dependent1",,,,"Dependent2",,,,"Dependent3",,,,"Dependent4",,,,"Dependent5",,,,\n,,,,,,,,,,,,,"Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB",\n'
      this.state.policies.forEach(item => { 
          csvStr = csvStr+ (item.employee_uhid || '') +','
          csvStr = csvStr+ (item.name || '') +','
          csvStr = csvStr+ (item.gender|| '')+','
          csvStr = csvStr+ (item.dob || '') +','
          csvStr = csvStr+ (item.email|| '')+','
          csvStr = csvStr + (item.phone || '') + ','
          csvStr = csvStr+ (item.no_of_dependents|| '')+','
          csvStr = csvStr+ (item.sum_insured|| '')+','
          csvStr = csvStr+ (item.start_date|| '')+','
          csvStr = csvStr+ (item.premium|| '')+','
          csvStr = csvStr+ (item.end_date|| '')+','
          csvStr = csvStr+ (item.refund|| '')+','
          csvStr = csvStr+ (item.policy_status|| '')+','
          for (let i = 0; i < item.dependents.length; i++) {
              const role = item.dependents[i]
              csvStr = csvStr+ (role.relation || '')+','
              csvStr = csvStr+ (role.first_name || '')+','
              csvStr = csvStr+ (role.last_name || '')+','
              csvStr = csvStr+ (role.dob || '')+','
              
          }
          csvStr = csvStr+'\n'
      });
    }else{var csvStr = '"Name","Gender","Date of Birth","Email","Mobile","Dependents","Sum Insured","Subscription Amount","Date of Addtion","Policy No.","Status","Date of Deletion","Dependent1",,,,"Dependent2",,,,"Dependent3",,,,"Dependent4",,,,"Dependent5",,,,"Dependent6",,,\n,,,,,,,,,,,,"Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB"\n'
    this.state.policies.forEach(item => {
      csvStr = csvStr + (item.name || '') + ','
      csvStr = csvStr + (item.gender || '') + ','
      csvStr = csvStr + (item.dob || '') + ','
      csvStr = csvStr + (item.email || '') + ','
      csvStr = csvStr + (item.phone || '') + ','
      csvStr = csvStr + (item.no_of_dependents || '') + ','
      csvStr = csvStr + (item.sum_insured || '') + ','
      csvStr = csvStr + (item.total_amount || '') + ','
      csvStr = csvStr + (item.date_of_addition || '') + ','
      csvStr = csvStr + (item.policy_number || '') + ','
      csvStr = csvStr + (item.policy_status || '') + ','
      csvStr = csvStr + (item.date_of_deletion || '') + ','
      for (let i = 0; i < item.dependents.length; i++) {
        const role = item.dependents[i]
        csvStr = csvStr + (role.relation || '') + ','
        csvStr = csvStr + (role.first_name || '') + ','
        csvStr = csvStr + (role.last_name || '') + ','
        csvStr = csvStr + (role.dob || '') + ','

      }
      csvStr = csvStr + '\n'
    });}

    // this.setState({ csvArr: data }, () => { console.log(this.state.csvArr) })
    const element = document.createElement("a");
    const fileOutput = csvStr
    const file = new Blob([fileOutput], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
     
    element.download = (localStorage.getItem('cname') && localStorage.getItem('cname').split(/[\s.]+/)[0] + '_employee.csv') || 'Employee_Details.csv';
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    element.parentNode.removeChild(element);
  }

  search = debounce((e) => {
    var searchText = trim(e)
    if (searchText.length > 0) {
      var newArr = findInValues(this.state.policies, searchText)
      this.setState({ policies: newArr })
    }
    else {
      this.setState({ policies: this.state.globalArray, filterText: 'Filter' })
    }
  }, 800)
  filter = (event) => {
    const val = event.target.value
    if (val === 'none') {
        this.setState({ policies: this.state.globalArray, filterText: val })
    }
    else {
        const filteredArray = this.state.globalArray.filter(
            (member) => member.policy_status === upperCase(val)
        )
        this.setState({ policies: filteredArray, filterText: toTitleCase(val) })
    }
}

  handleChange = event => {
    event.persist();
    this.setState({ value: event.target.value });
    this.search(event.target.value)
  }
  onSelectPolicyItem = (index) => {
    const { policies } = this.state
    policies[index]['isChecked'] = !policies[index]['isChecked']
    this.setState({ policies: [...policies] })
  }

  areAllPoliciesSelected = () => {
    const { policies } = this.state
    if (!policies || !policies.length) {
      return false
    }
    return policies.every(policy => policy['policy_status'] === 'STOPPED' || !!policy['isChecked'])
  }


  selectAllPolicies = () => {
    const { policies } = this.state
    const allSelected = this.areAllPoliciesSelected()
    policies.forEach(policy => {
      policy['isChecked'] = policy['policy_status'] === 'STOPPED' ? false : !allSelected
    })
    this.setState({ policies: [...policies] })
  }


  onClickDeletePopUp = () => {
    this.setState({ showDeletePopUp: true })
  }

  onDeleteNoSelected = () => {
    this.setState({ showDeletePopUp: false })
  }

  handleCallback = (childData) => {
    this.setState({ showDeletePopUp: childData })
  }

  handleCallbackAfterDelete = (childData) => {
    setTimeout(() => {
      this.getData()
      this.setState({ showDeletePopUp: childData, loading: false })
    }, "800")
  }

  navigateToAddEmployee = () => {
    this.props.history.push({
      pathname: '/business/uploadExcel',
    })
  }

  render() {
    const csvReport = {
      data: this.state.csvData || [],
      // filename: localStorage.getItem('user_plan_deatils') && `${name}`+'_employee',
    }
    return (
      this.state.loading ? <Loader></Loader> :
        <HomeWrapper>
          
          <BoxHeading>
                <Ptag fontSize="24px" fontWeight="600" color="#4A4A4A" lineHeight="33px" letterSpacing="0.6px">Manage Employees</Ptag>
                <InnerDiv>
                    <Download onClick={this.exportCsv} >
                      <img src={downloadcsv} width='24px' height='24px' style={{ marginRight: 10 }} />
                      <Ptag fontSize="18px" fontWeight="600" color="#FFFFFF" lineHeight="24px" letterSpacing="0.5px">Download</Ptag>
                    </Download>
                    {
                      this.state.gmcField &&
                      <AddDeleteTag onClick={this.goToAddDelete} >
                        <Ptag fontSize="18px" fontWeight="600" color="#FFFFFF" lineHeight="24px" letterSpacing="0.5px">Add/Delete Employee</Ptag>
                      </AddDeleteTag>
                    }
                </InnerDiv>
          </BoxHeading>
          <SearchfilterDiv>
            <InputDiv>
              <input className="input-elevated"
                onChange={this.handleChange}
                value={this.state.value}
                type="text" placeholder="Search Employee" />
            </InputDiv>
            <FilterDiv>

              <Dropdown
                onChange={this.filter}
              >
                <Option value='' default disabled selected hidden>
                  {'STATUS'}
                </Option>
                {this.state.showFilterArray && this.state.showFilterArray.map((item, key) => (
                  <Option value={item.toLowerCase()} key={`FilterArray${key}`} >
                    {toTitleCase(String(item))}
                  </Option>))
                }
              </Dropdown>
              <Icons icon={'chevron_down'} />


            </FilterDiv>
            {/* {
              localStorage.getItem("flow")!=="GMC" && localStorage.getItem("flow")!=="GMC_M" && 
            <DeleteIconDiv onClick={this.onClickDeletePopUp}>
              <img src={deleteIco} width="24px" height="24px" />
            </DeleteIconDiv>
            } */}
          </SearchfilterDiv>
          {this.renderMis()}
          {this.state.showDeletePopUp && <DeletePopup parentCallback={this.handleCallback} closeAfterDel={this.handleCallbackAfterDelete} data={this.state.policies || []}></DeletePopup>}
        </HomeWrapper>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    name: state
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    changeTotalLives: (value) => { dispatch({ type: 'SET_TOTAL_LIVES', payload: value }) },
    changeTotalEmp: (value) => { dispatch({ type: 'SET_TOTAL_EMP', payload: value }) },
    changeTotalSubscriptionAmount: (value) => { dispatch({ type: 'SET_TOTAL_SUBSCRIPTION_AMOUNT', payload: value }) },
    lineThroughSubscriptionAmount: (value) => { dispatch({ type: 'SET_LINE_THROUGH_SUBSCRIPTION_AMOUNT', payload: value }) },
    wellnessPayableAmount: (value) => { dispatch({ type: 'WELLNESS_PAYABLE_AMOUNT', payload: value }) },
    wellnessEmployeeData: (value) => { dispatch({ type: 'WELLNESS_EMPLOYEE_DATA', payload: value }) },
    perEmoloyeeYearlyFeee: (value) => { dispatch({ type: 'PRE_EMP_YEARLY_FEE', payload: value }) },
    wellnessNoOfEmps: (value) => { dispatch({ type: 'NUMBER_OF_EMPLOYEES', payload: value }) },
    totalWellnessFee: (value) => { dispatch({ type: 'TOTAL_WELLNESS_FEE', payload: value }) },

  }

}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DashboardList))

const HomeWrapper = styled.div`
  transition: margin-left .2s ease-in;
  padding: 20px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: rgb(0 0 0 / 15%) 0px 1px 4px 0px;
`

const UploadExcelPopUpDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1200;
`

const Innerpopup = styled.div`
  position: absolute;
  left: 25%;
  right: 25%;
  top: 5%;
  bottom: 5%;
  margin: auto;
`

const SubInnerDiv = styled.div`
    width:100%;
    padding:35px 45px;
    background: #ffffff;
    border-radius: 10px;
`

const InnerDiv = styled.div`
  display: flex;
  flex-direction: row;

`

const FirstRow = styled.div`
    justify-content: space-between;
    display: flex;
`

const SecondSubRow = styled.div`
padding-top:10px;

`
const EmployeeAmountDiv = styled.div`
background:#f3f4f7;
width:100%;
padding:30px 20px;
margin-top: 20px;
border-radius: 10px;
height: 250px;
overflow: auto;
`
const AmountDiv = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
padding: 0px 0px;

`
const Hr = styled.div`
border: 1px solid #cdc9c9;
margin:10px 0px;
`


const AddExcelbtn = styled.div`
padding-top:20px; 
display: flex;
justify-content: space-between;
`

const TopHeader = styled.div`
  min-width:130px;
  display: flex;
  padding: 12px 6px ;
  justify-content: center;
   
`
const SubTopHeader = styled.div`
  min-width:130px;
  display: flex;
  padding: 12px 6px ;
  justify-content: center;
  display: flex;
  flex-direction: column;
   
`
const Option = styled.option`
    color: ${props => props.color ? props.color : 'black'};
`


const Dropdown = styled.select`
    display: flex;
    width: 100%;
    border: none;
    font-family: Avenir;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    color: ${props => props.color ? props.color : 'rgba(0, 0, 0, 0.5)'};
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    &:hover,
    &:focus 
    {
    outline: 0 !important;
    };
`

const Table = styled.table`
    display: block;
    max-width: -moz-fit-content;
    max-width: fit-content;
    border-collapse: collapse;
    thead {
        position: sticky;
        top: 0;
        z-index: 10;
        th{
            background: rgb(208, 217, 233);
            position: sticky;
            top: 0;
            left: 0;
            z-index: 13;
        }
        td{
            background: rgb(208, 217, 233);
            position: sticky;
            top: 0;
            left: 0;
            z-index: 12;
        }
    }
    tbody th {
        
    }
`

const StickyCell = styled.th`
    position: sticky;
    left: 0;
    z-index: 8;
    background: ${props => props.bgColor};
`

const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 44px;
`

const TableWrapper = styled.div`
    max-width: 100%;
    max-height: 100vh;
    overflow-x: scroll;
    float: left;
    ::-webkit-scrollbar {
        display: none;
    }
`
const Download = styled.button`
  width:184px;
  display: flex;
  padding: 16px 30px ;
  flex-direction: row;
  background-color:#436AB2;
  border-radius: 8px;
  cursor: pointer;
  border: none;
  margin-right: 5px;
`

const AddDeleteTag = styled.button`
  /* width:184px; */
  display: flex;
  padding: 16px 30px ;
  flex-direction: row;
  background-color:#436AB2;
  border-radius: 8px;
  cursor: pointer;
  border: none;
`

const BoxHeading = styled.div`
display:flex;
justify-content:space-between;
align-items: center;
padding:18px 0px;
`

const SearchfilterDiv = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
background: #e1e8f5;
width:100%;
padding: 23px;
margin-bottom:54px;
  border-radius:8px;
`

const FilterDiv = styled.div`
margin-right: 10px;
display: flex;
justify-content: space-between;
width: 268px;
height: 42px;
margin: 0 14px;
padding: 10px 10px 10px 10px;
border-radius: 10px;
border: solid 1px #436ab2;
z-index:0;
`


const InputDiv = styled.div`
display:flex;
flex-direction: row;
padding:15px;
border-radius:8px;
`

const DeleteIconDiv = styled.div`
padding: 7px 5px 3px 5px;
border-radius: 10px;
border: solid 1px #436ab2;
`

const RenewTag = styled.div`
  text-decoration: line-through;
  font-family: "Avenir";
  text-align: right;
  line-height: normal;
  margin: 0px;
  text-transform: none;
  opacity: 1;
`

const TextTag = styled.div`
  font-family: 'Avenir';
  font-style: normal;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  text-align: left;
  letter-spacing: 0.555556px;
`

const AmountTag = styled.div`
  display: inline-flex;
  flex-direction: row;

`
