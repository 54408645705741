import React, { useMemo } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";

import { Ptag } from "../fonts";
import ECard from "./ECard";
import { ECardHeader, ECardLink, ECardScroll, Line } from "./styles";
import ECardPdf from "../ECardPdf";
import { FLEX_PRODUCTS } from "../../constants/businessConstant";

const ECardList = ({ abbr, members, flex }) => {
  // getting title based on the abbreviation
  const title = useMemo(() => {
    let title = "";
    if (flex) {
      title = FLEX_PRODUCTS[abbr]
    } else {
      switch (abbr) {
        case "GMC":
        case "GMC_M":
          title = "Group Health Insurance";
          break;
        case "GPA":
          title = "Group Personal Accident";
          break;
        case "GTL":
          title = "Group Term Life";
          break;
      }
    }
    return title;
  }, [abbr]);

  const getProductCommercialAbbr = () => {
    switch (abbr) {
      case "GMC":
      case "GMC_M":
        return "GHI";
      default:
        return abbr;
    }
  };

  return (
    <ECardScroll>
      <ECardHeader>
        <Ptag marginLeft="1rem" fontSize="20px" fontWeight="600">
          {title}
        </Ptag>
        <PDFDownloadLink
          document={<ECardPdf members={members} />}
          fileName={`${getProductCommercialAbbr()}_E_Cards.pdf`}
        >
          <ECardLink>Download E-Cards</ECardLink>
        </PDFDownloadLink>
      </ECardHeader>
      <Line />
      <div
        style={{
          marginTop: "2.2rem",
          marginBottom: "6rem",
          display: "flex",
          overflow: "auto",
          gap: "2.4rem",
          padding: "2rem 1rem",
        }}
      >
        {members.map((member, index) => (
          <ECard key={index.toString()} member={member} />
        ))}
      </div>
    </ECardScroll>
  );
};

export default ECardList;
