import React, { Component } from 'react'
import styled from 'styled-components'
import Icons from './icons'

const RectangleBtn = styled.button`
  width: 100%;
  padding: 9px 1.2rem;

  border: 1px solid #fff;
  border-radius: 1rem;
  background-color: transparent;// ${props => (props.active ? '#436ab2' : '#fff')};

  display: flex;
  justify-content: space-between;
  align-items: center;

  &:active,
  &:focus {
    outline: none;
  }
`

const Font24 = styled.p`
  font-family: Avenir;
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.5px;
  text-align: center;
  color: #ffffff;
`

export default class RectangleButton2 extends Component {
  render() {
    return (
      <RectangleBtn {...this.props}>
        <span onClick={() => this.props.SumInsuredClick('minus')}>
          <Icons icon="circularMinus" />
        </span>
        <Font24 {...this.props}>{this.props.label}</Font24>
        <span onClick={() => this.props.SumInsuredClick('plus')}>
          <Icons icon="circularPlus" />
        </span>
      </RectangleBtn>
    )
  }
}
