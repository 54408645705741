import React, { Component } from 'react'
import styled from 'styled-components'
import {Header} from '../../components/business'
import {Ptag} from '../../components/fonts'
import shield from '../../assets/images/shieldIcon.png'
import Icons from "../../components/icons";

import RightArrowIcon from "../../assets/images/forward.png";
import arrow from '../../assets/images/blueArrow.png'
import info from '../../assets/images/i.png'
import http from '../../services/httpService'
import {OverlayLoader} from '../../components'
import formatAmount from '../../components/memberApp/common/utils'
import vitalcoin from '../../assets/images/vital-coin.png'
import {Deductible, WellnessRewards, BusinessIcon, CoveredThing} from '../../components/business'
import TagManager from 'react-gtm-module'
import {gtmCode} from '../../utils/utils'
import buttonBackground from "../../assets/images/payNowBackground.png";
import cross from "../../assets/images/vital-cross.png";
import $ from 'jquery'
import {maxScreenWidth} from '../../config.json'
import icicil from "../../assets/images/iciciLombard.png";
import InsuranceBenefits from '../../components/business/benefits'

export default class InsurePlan extends Component{


    constructor(props){
        super(props)
        this.state = {
            loading: true,
            members: 0,
            showDeductible: false,
            showWellnessRewards: false,
            plantype: 'Monthly',
            selectedInsurer: 0,
            showInsurersList: false,
            maternityBenefits: false,
            preExistingBenefits: false,
            Insurers: [],
            displayedInsurers: [],
            premiumSelected: 0,
            pointsSelected: true,
            pointsSelectedIndex: 0,
            pointsCategories: [],
            selectedDeductible: 0,
            estimate: false,
            pointsHeading: ''
        }
    }

    componentDidMount = async () => {
        try {
            const tagManagerArgs = {
                gtmId: gtmCode(),
              }
            TagManager.initialize(tagManagerArgs)
            const plan_id = localStorage.getItem('plan_id')

            const {data} = await http.patch(`business/plan/${plan_id}/`,{
                plan_type: this.state.plantype
            })
            //$('#pedtip').focusout(() => this.setState({showPedTip: false}))
            const employeesCovered = data['total_employee_count']
            const membersCovered = data['total_lives_count']
            const Insurers = data['insurers'].filter(item => item['premiums'] && item['premiums'].length)
            const displayedInsurers = Insurers
            const pointsCategories = data['add_ons'] && data['add_ons'][0] && data['add_ons'][0]['subcategories']
            const selectedInsurer = 0 // Since religare is disabled for yearly plans
            const premiumSelected = 0
            const estimate = data['estimate']
            const pointsHeading = data['add_ons'] && data['add_ons'][0] && data['add_ons'][0]['description']
            if (this.state.plantype === 'annual'){
                const enableMaternity = data['enable_maternity']
                const maternitySelected = false
                const enablePED = data['enable_ped']
                const PEDselected = false
                
                await this.setState({employeesCovered, membersCovered, enableMaternity, maternitySelected, 
                    enablePED, Insurers, selectedInsurer, premiumSelected, PEDselected, pointsCategories, estimate,
                    pointsHeading
                })
                this.setInsurers()
            } else {
                //insuranceAmounts  For monthly only
                const insuranceAmounts = {
                    0: [],
                    35000: [],
                    100000: []
                }
                for (let entry of Insurers && Insurers[0] && Insurers[0]['premiums'] && Insurers[0]['premiums'][0] && Insurers[0]['premiums'][0]['Non Maternity']){
                    if(entry['premium'])
                    insuranceAmounts[0].push(entry)
                }
                this.setState({employeesCovered, membersCovered, insuranceAmounts,
                    Insurers, selectedInsurer, premiumSelected, pointsCategories, estimate, pointsHeading,  displayedInsurers})
            }
            
            
        }
        catch(ex){
            console.error(ex)
        }
        this.setState({loading: false})
    }

    onClickPayNow = async (plan) => {
        try{
            this.setState({loading: true})
            const request = {
                plan: localStorage.getItem('plan_id')
            }
            if (this.state.insuranceAmounts){  // Monthly
                const requestObj = Object.assign({}, request, {
                    insurer: this.state.Insurers[this.state.selectedInsurer]['insurer_id'],
                    deductible: this.state.selectedDeductible,
                    sum_insured: this.state.insuranceAmounts[this.state.selectedDeductible][this.state.premiumSelected]['sum_insured'],
                    add_ons: this.state.pointsSelected ? 
                        [{ subcategory: this.state.pointsCategories[this.state.pointsSelectedIndex] && this.state.pointsCategories[this.state.pointsSelectedIndex]['id']}] : undefined,
                })
                
                const {data} = await http.post('/business/userplans/',{
                    ...requestObj
                })
                localStorage.setItem('userplan_id',data['userplan_id'])

            } else { // Yearly
                const requestObj = Object.assign({}, request, {
                    insurer: this.state.displayedInsurers[this.state.selectedInsurer]['insurer_id'],
                    maternity_benefits: this.state.maternitySelected ? true : undefined,
                    ped_waiver: this.state.PEDselected ? true : undefined,
                    sum_insured: this.state.Insurers[this.state.selectedInsurer] &&
                        this.state.Insurers[this.state.selectedInsurer]['premiums'] &&
                        this.state.Insurers[this.state.selectedInsurer]['premiums'][0] && 
                        this.state.Insurers[this.state.selectedInsurer]['premiums'][0][this.state.maternitySelected ? 'Maternity' : 'Non Maternity'] &&
                        this.state.Insurers[this.state.selectedInsurer]['premiums'][0][this.state.maternitySelected ? 'Maternity' : 'Non Maternity'][this.state.premiumSelected]['sum_insured'],
                    add_ons: this.state.pointsSelected ? 
                        [{ subcategory: this.state.pointsCategories[this.state.pointsSelectedIndex] && this.state.pointsCategories[this.state.pointsSelectedIndex]['id']}] : undefined,
                })
                const {data} = await http.post('/business/userplans/',{
                    ...requestObj
                })
                localStorage.setItem('userplan_id',data['userplan_id'])
            }
            window.scrollTo(0,0)
            localStorage.removeItem('navigatingFrom')
            this.props.history.push('/business/enrollmentprocess')
            
        }
        catch(ex){
            this.setState({loading: false})
            console.log(ex)
        }
       
    }

    formatTileText = (text) => {
        if(text === 'members')
            return `${this.state.TileData['members_covered']}`
        if(text === 'months')
            return `${this.state.TileData['duration']} months`
    }

    formatSum = (sum) => {
        return '₹' + parseInt(sum)/100000 + 'Lakhs'
    }

    onClickMarketPlace = () => {
        localStorage.setItem('navigatingFrom', 'plans')
        this.props.history.push('/business/marketplace')
    }

    onClickViewPolicy = () => {
        window.scrollTo({
            top: 0,
            left: 0,
          })
        localStorage.setItem('navigatingFrom','plans')
        this.props.history.push(`/business/carepolicyDoc`)
    }

    onClickViewClaimProcess = () => {
        window.scrollTo(0,0)
        localStorage.setItem('navigatingFrom', 'plans')
        
        this.props.history.push(`/business/claimprocess/${3}`)
    }

    renderAddButton = (selected=false, color = '#436ab2', text, click) => {
        return(
            <AddButton onClick={click} color={color} selected={selected}>
                {selected ? `${text}ED` : text}
                {selected && <BusinessIcon icon='checkMark' fill={color} height='18' width='18'/>}
            </AddButton>
        )
    }

    renderSumInsuredButtons = () => {
        if (this.state.insuranceAmounts){
            const ButtonsList = this.state.insuranceAmounts[this.state.selectedDeductible]
            return(
                <ButtonWrapper>
                    {ButtonsList.map((item, index) => (
                        <SelectButton key={`business_plans_sum_insured_${item['sum_insured']}`} id={`business_plans_sum_insured_${item['sum_insured']}`}  selected={this.state.premiumSelected === index} onClick={() => this.setState({premiumSelected: index})} style={{'marginBottom': '12px'}}>
                            {`${item['sum_insured']/100000} Lakhs`}
                        </SelectButton>
                    ))}
                </ButtonWrapper>
            )
        } else {
            const ButtonsList = this.state.Insurers[this.state.selectedInsurer] &&
            this.state.Insurers[this.state.selectedInsurer]['premiums'] &&
            this.state.Insurers[this.state.selectedInsurer]['premiums'][0] && 
            this.state.Insurers[this.state.selectedInsurer]['premiums'][0][this.state.maternitySelected ? 'Maternity' : 'Non Maternity'] || []

            return(
                <ButtonWrapper>
                    {ButtonsList.map((item, index) => (
                        <SelectButton id={`business_plans_sum_insured_${item['sum_insured']}`} selected={this.state.premiumSelected === index} onClick={() => this.setState({premiumSelected: index})} style={{'marginBottom': '12px'}}>
                            {`${item['sum_insured']/100000} Lakhs`}
                        </SelectButton>
                    ))}
                </ButtonWrapper>
            )
        }
        
    }

    renderPointsButton = () => {

        const PointsButtons = this.state.pointsCategories
        return (
            <ButtonWrapper style={{'marginTop': '16px'}}>
                {
                    PointsButtons.map( (item, index) => 
                        <SelectButton id={`business_plans_points_selected_${item['value']}`} onClick={() => this.setState({pointsSelectedIndex: index})} selected={this.state.pointsSelectedIndex === index} backgroundColor='#f27576'>
                            <img src={vitalcoin} width='16px' height='16px'/>
                            {item['value']}
                        </SelectButton>
                    )
                }
            </ButtonWrapper>
        )
    }

    calculatePremium = (withPoints = false, index) => {
        let amount
        if (this.state.insuranceAmounts){
            amount = this.state.insuranceAmounts[this.state.selectedDeductible] && this.state.insuranceAmounts[this.state.selectedDeductible][this.state.premiumSelected] &&
            this.state.insuranceAmounts[this.state.selectedDeductible][this.state.premiumSelected]['premium']
        } else {
            amount = this.state.displayedInsurers[index] && this.state.displayedInsurers[index]['premiums'] && this.state.Insurers[index]['premiums'][0] 
            && this.state.displayedInsurers[index]['premiums'][0][this.state.maternitySelected ? 'Maternity' : 'Non Maternity'] 
            && this.state.displayedInsurers[index]['premiums'][0][this.state.maternitySelected ? 'Maternity' : 'Non Maternity'][this.state.premiumSelected]
            && this.state.displayedInsurers[index]['premiums'][0][this.state.maternitySelected ? 'Maternity' : 'Non Maternity'][this.state.premiumSelected][this.state.PEDselected ? 'premium_ped' : 'premium']
        }
        const points = withPoints && this.state.pointsSelected ? this.state.pointsCategories[this.state.pointsSelectedIndex] && this.state.pointsCategories[this.state.pointsSelectedIndex]['cost'] * 
        this.state.employeesCovered : 0
            return amount + points 

    }

    setInsurers = () => {
        if (this.state.membersCovered > 25){
            this.setState({ displayedInsurers: this.state.Insurers})
            return
        }
            
        let displayedInsurers = []
        if (this.state.maternitySelected || this.state.PEDselected){
            displayedInsurers = this.state.Insurers.filter( item => item['name'] && item['name'].toLowerCase().indexOf('care') > -1)
        } else {
            displayedInsurers = this.state.Insurers.filter( item => item['name'] && item['name'].toLowerCase().indexOf('bharti') > -1)
        }
        this.setState({ displayedInsurers})

    }

    onClickMaternityBenefits = async () => {
        if(this.state.enableMaternity === true){
            await this.setState({maternitySelected: !this.state.maternitySelected})
            this.setInsurers()
        } else {
            alert(this.state.enableMaternity)
        }
    }

    onClickPEDBenefits = async () => {
        if (this.state.enablePED === true){
            await this.setState({PEDselected: !this.state.PEDselected})
            this.setInsurers()
        } else {
            alert(this.state.enablePED)

        }
    }

    onClickTipCalled = async (tip, id, event) => {
        event.preventDefault()
        let that = this
        await this.setState({[tip]: true})
        const ele = $(`#${id}`)
        ele.focus()
        ele.focusout(function(){
            that.setState({[tip]: false})
        })
    }

    onClickCashlessNetwork = (item) => {
        const page = 'https://www.icicilombard.com/docs/default-source/Policy-Wordings-product-Brochure/english-health-claim-form8c0003ff45fd68ff8a0df0055e0d3bfe.pdf?sfvrsn=39fd6bfe_6'
        window.open(page, '_blank')
    }

    render(){
        return(
            <Root>
                <Header headerText='Build Plan' onClickBack={()=> this.props.history.push('/business/employees')}/>
                <StupidBlueStrip />
                <Body>
                    <Tiles>
                        <Tile>
                            <Ptag fontWeight='600' letterSpacing='0.86px' letterHeight='1.5'>
                                {'Employees Covered'}
                            </Ptag>
                           
                            <Ptag fontSize='16px' fontWeight='500' letterSpacing='0.4px'>
                                {this.state.employeesCovered}
                            </Ptag>
                                
                        </Tile>

                        <Tile>
                            <Ptag fontWeight='600' letterSpacing='0.86px' letterHeight='1.5'>
                                {'Members Covered'}
                            </Ptag>                            
                            <Ptag fontSize='16px' fontWeight='500' letterSpacing='0.4px'>
                                {this.state.membersCovered}
                            </Ptag>

                        </Tile>

                        <Tile>
                            <Ptag fontWeight='600' letterSpacing='0.86px' letterHeight='1.5'>
                                {'Cover Period'}
                            </Ptag>                            
                            <Ptag fontSize='16px' fontWeight='500' letterSpacing='0.4px'>
                                {'12 months'}
                            </Ptag>
                        </Tile>

                    </Tiles>

                    <ThickBorder/>

                    <Cards margin={'0 0 24px 0'} boxShadow={false}>
                    <CardHeader>
                        <CardHeaderTileTitle>
                            <CardIcon src={shield}/>
                            <HeadingWrapper>
                            <Heading>
                                {'Insurance'}
                            </Heading>
                            <Ptag style={{'marginTop': '8px'}} color='#666666' letterSpacing='0.5px' >
                                {'For hospitalization expenses'}
                            </Ptag>
                            </HeadingWrapper>
                        </CardHeaderTileTitle>
                        
                        <ThirdParty>
                            <Ptag fontSize='8px' letterSpacing='0.5px' color='rgba(0,0,0,0.6)' textTransform='uppercase'>
                            {'ISSUED By'}
                            </Ptag>
                            <img src={icicil} width='100px' height='38px'/>
                        </ThirdParty>
                        </CardHeader>
                        
                        <CardComponents style={{'paddingBottom': '24px', borderBottom:'1px solid rgba(74,144,226,0.3)'}}>
                            <Ptag textTransform='capitalize' fontWeight='600' letterHeight='2' letterSpacing='0.86px' >
                                {'coverage '}
                            </Ptag>
                            
                            <CoveredThing
                                features={['covid', this.state.plantype === 'annual' ? 'dayone' : 'hospitalization' ,'daycare' ,'ambulance', 'prehospitalization']}
                                tags={['hospitalization', 'daycare', 'covid']}
                                iconHeight="24px"
                                iconWidth="24px"
                                fontSize="10px"
                                tilePadding="0 1.2rem 0 0"
                                borderRight="none"
                            />
                        </CardComponents>

                        <PolicyDocWrapper>
                            <FlexWrap onClick={this.onClickViewPolicy}>
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <Ptag letterSpacing='1px' textTransform='capitalize'  fontSize='12px' fontWeight="600">
                                    {'View Policy Document'}
                                </Ptag>
                                
                            </div>
                            <Icons icon="chevron_right" fill="#4a4a4a" />
                            </FlexWrap>
                        </PolicyDocWrapper>

                        <PolicyDocWrapper>
                            <FlexWrap onClick={() => this.onClickViewClaimProcess()}>
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                <Ptag letterSpacing='1px' textTransform='capitalize'  fontSize='12px' fontWeight="600">{'Claim Process'}</Ptag>
                                <Ptag fontSize='12px' color="#7d7d7d" letterSpacing="0.5px">
                                    {"8500+ hospitals in cashless network"}
                                </Ptag>
                                </div>
                                <Icons icon="chevron_right" fill="#4a4a4a" />
                            </FlexWrap>
                        </PolicyDocWrapper>


                        {/* <ViewMarketPlace style={{'marginTop':'12px','justifyContent': 'flex-end'}} id='business_plans_view_policy'  >
                            <Ptag fontWeight='600' letterSpacing='0.75px' color='#4a90e2' >
                                {'View Details'}
                            </Ptag>
                            <img style={{'margin': '3px 0 0 6px'}}  src={arrow} width='12px' height='12px'/>
                        </ViewMarketPlace> */}
                        
                        <CardComponents>
                            <Ptag textTransform='capitalize'  fontWeight='600' letterHeight='2' letterSpacing='0.86px' >
                                {'Select Sum Insured '}
                            </Ptag>

                            {this.renderSumInsuredButtons()}

                        </CardComponents>
                        
                        {/* {
                            this.state.plantype === 'annual' &&
                                <BenefitsRow>
                                    
                                    <BenefitRowItem>
                                        
                                        <Ptag fontWeight='600' letterSpacing='1px'>
                                            {'Do you want to include Maternity Benefits?'}
                                            <img style={{'marginLeft' : '4px'}} onClick={(event) => this.onClickTipCalled('showDeliveryTip', 'maternitytip', event)} height='14px' width='14px' src={info}/>
                                        </Ptag>
                                        
                          
                                    </BenefitRowItem>
                                    <Toggle 
                                        active={this.state.maternitySelected}
                                        toggleClick={this.onClickMaternityBenefits}
                                    />
                                </BenefitsRow>
                        } */}

                        {/* {
                            this.state.plantype === 'annual' &&
                            <BenefitsRow>
                                <BenefitRowItem>
                                    <Ptag fontWeight='600' letterSpacing='1px'>
                                        {'Do you want to cover Pre-existing Diseases?'}
                                        <img style={{'marginLeft' :'4px'}} onClick={(event) => this.onClickTipCalled('showPedTip', 'pedtip', event)} height='14px' width='14px' src={info}/>
                                    </Ptag>
                                    
                                    
                                </BenefitRowItem>
                                <Toggle 
                                    active={this.state.PEDselected}
                                    toggleClick={this.onClickPEDBenefits}
                                />
                            </BenefitsRow>

                        } */}
                        {/* {
                            this.state.plantype === 'annual' && this.state.enablePED !== true &&
                            <Ptag style={{'marginTop':'4px'}} letterSpacing='1px' >
                                {this.state.enablePED}
                            </Ptag>
                        } */}

                        {/* {
                            this.state.plantype === 'monthly' && 
                            <CardComponents>
                                <SelectDeductible >
                                    <Ptag textTransform='uppercase' fontWeight='600' letterHeight='2' letterSpacing='0.86px' >
                                        {'Select Deductible'}
                                    </Ptag>
                                    <img onClick={() => this.setState({showDeductible: true})} src={info}/>
                                </SelectDeductible>
                                    <ButtonWrapper disableOverFlow={true}>
                                        <SelectButtonWrapper>
                                            <SelectButton id="business_plans_deductible_0"  onClick={() => this.setState({selectedDeductible: 0})} selected={this.state.selectedDeductible === 0}>
                                                {'₹0'}
                                            </SelectButton>
                                        </SelectButtonWrapper>
                                        <SelectButtonWrapper>
                                            <SelectButton id="business_plans_deductible_35000" onClick={() => this.setState({selectedDeductible: 35000})} selected={this.state.selectedDeductible === 35000}>
                                                {'₹35,000'}
                                            </SelectButton>
                                            <Ptag fontSize='8px' fontWeight='600' letterSpacing='0.87px' color='#009999'>
                                                {'60% Lower Premium'}
                                            </Ptag>
                                        </SelectButtonWrapper>
                                        <SelectButtonWrapper>                                    
                                            <SelectButton id="business_plans_deductible_100000" onClick={() => this.setState({selectedDeductible: 100000})} selected={this.state.selectedDeductible === 100000}>
                                                {'₹1,00,000'}
                                            </SelectButton>
                                            <Ptag fontSize='8px' fontWeight='600' letterSpacing='0.87px' color='#009999'>
                                                {'75% Lower Premium'}
                                            </Ptag>
                                        </SelectButtonWrapper>
                                    </ButtonWrapper>
                            </CardComponents>
                        } */}
                        
                        {/* <Ptag style={{'margin': '24px 0 16px 0'}} fontWeight='600' letterSpacing='1px' color='#4a4a4a'>
                            {'INSURER WITH BEST QUOTES'}
                        </Ptag> */}
                        {/* <InsurerCardWrapper>

                            {
                                this.state.displayedInsurers.map( (item, index) =>
                                    <InsurerCard>
                                
                                        <InsurerCardTop>
                                            <Ptag textTransform='capitalize' fontSize='14px' fontWeight='600' letterSpacing='1.17px'>
                                                {item['name']
                                                }
                                            </Ptag>

                                            <LinksWrapper>
                                                <ViewMarketPlace onClick={() => this.onClickCashlessNetwork(item)}>
                                                    <u style={{'textDecoration':'none'}} >
                                                        {'Cashless  Network'}
                                                    </u>
                                                    <img style={{'marginLeft': '4px'}} src={arrow} width='14px' height='14px'/>
                                                </ViewMarketPlace>
                                                <ViewMarketPlace onClick={() => this.onClickViewClaimProcess(item)}>
                                                    <u style={{'textDecoration':'none'}}>
                                                        {'Claim Process'}
                                                    </u>
                                                    <img style={{'marginLeft': '4px'}} src={arrow} width='14px' height='14px'/>
                                                </ViewMarketPlace>
                                            </LinksWrapper>
                                        </InsurerCardTop>
                                        
                                        <InsurerCardFooter>
                                            <CardFooterBottomText>
                                                <Ptag fontSize='8px' letterHeight='2' letterSpacing='0.67px'>
                                                    {'TOTAL PREMIUM'}
                                                </Ptag>
                                                <Ptag fontSize='18px' fontWeight='600' letterHeight='1.33' letterSpacing='0.9px'>
                                                    {`₹${formatAmount(this.calculatePremium(false, index))}${this.state.estimate ? '*' : ''}`}
                                                </Ptag>
                                            </CardFooterBottomText>
                                            {this.state.Insurers.length > 1 && this.renderAddButton(this.state.selectedInsurer === index, '#436ab2', 'SELECT',() => this.setState({selectedInsurer: index}))}
                                        </InsurerCardFooter>
                                    </InsurerCard>
                        
                                )
                            }

                        
                        </InsurerCardWrapper>
                        {
                            this.state.estimate && 
                            <Ptag style={{'marginTop': '8px'}} fontSize='10px' letterSpacing='0.5px'>
                                {'*Estimated price based on the age assumptions of members.'}
                            </Ptag>
                        } */}
                        

                       
                    </Cards>
                    
                    <ThickBorder />
                    
                    <div style={{'marginTop':'16px'}}>
                        <InsuranceBenefits

                        />
                    </div>
                        
                  
                    <ThickBorder/>
                </Body>

                <PaymentFooter>
                    <FooterLeft>
                        <Tooltip style={{'marginLeft' : '4px'}} height='14px' width='14px' src={info}/>
                        <Ptag color="#fff" letterSpacing="0.4px" letterHeight="1.33">
                            {"Total Due"}
                        </Ptag>
                        <Amount>
                            <Ptag fontSize="20px" color="#fff" letterSpacing="1px" letterHeight="1.2">
                                {`₹ ${formatAmount(this.calculatePremium(true, this.state.selectedInsurer))}`}
                            </Ptag>
                            <Ptag letterSpacing='0.5px' color='#fff'>
                                {'/month'}
                            </Ptag>
                        </Amount>
                        
                    </FooterLeft>
                    <AuthoriseButton id="business_plan_continue" onClick={this.onClickPayNow}>
                        <AuthoriseText>CONTINUE</AuthoriseText>
                        <RightArrow src={RightArrowIcon} />
                    </AuthoriseButton>
                </PaymentFooter>
                
                <Deductible
                    showPopup={this.state.showDeductible}
                    BackBtn={() => this.setState({showDeductible: false})}
                />
                <WellnessRewards showPopup={this.state.showWellnessRewards} onClosePopup={() => this.setState({showWellnessRewards: false})}/>
                <EditNumberPopup showTip={this.state.showDeliveryTip} id='maternitytip' margin='0px 12px 0 190px'>
                    <img onClick={() => this.setState({showDeliveryTip: false})}  style={{'alignSelf': 'flex-end'}} height='14px' width='14px' src={cross}/>
                    
                    <Ptag style={{'margin':  '12px 0'}} fontWeight='600' letterSpacing='0.5px'>
                        {'Maternity Benefits'}
                    </Ptag>
                    <Ptag letterSpacing='0.5px'>
                        {'1. Up to Rs. 50,000 for Normal or Cesarean delivery'}
                    </Ptag>
                    <Ptag letterSpacing='0.5px'>
                        {'2. Up to Rs. 5000 for pre and post natal expenses (+/- 30 days of hospitalization)'}
                    </Ptag>
                    <Ptag letterSpacing='0.5px'>
                        {'3. Newborn babies covered from day one (in case spouse is covered)'}
                    </Ptag>
                </EditNumberPopup>
                <EditNumberPopup id='pedtip' showTip={this.state.showPedTip} >

                    <img onClick={() => this.setState({showPedTip: false})} style={{'alignSelf': 'flex-end'}} height='14px' width='14px' src={cross}/>

                    <Ptag style={{'margin': ' 12px 0'}} fontWeight='600' letterSpacing='0.5px'>
                        {'PED Benefits'}
                    </Ptag>              
                    <Ptag letterSpacing='0.5px'>
                        {'This will cover your employees for any illness, condition or injury which they had been diagnosed with, anytime prior to the policy being issued.'}
                    </Ptag>
                    
                </EditNumberPopup>
                {this.state.loading && <OverlayLoader />}
            </Root>
        )
    }
}


const Root = styled.div`
    display: flex;
    flex-direction: column;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 24px 36px 24px;
    margin-bottom: 38px;
    @media(max-width:425px){
        padding: 24px 16px 36px 16px;
    }
`

const StupidBlueStrip = styled.div`
    display: flex;
    height: 16px;
    background-color: #436ab2;
`;

const Tiles = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
`

const Tile = styled.div`
    display: flex;
    width: 32%;
    padding: 12px;
    flex-direction: column;
    border: solid 1px #4a90e230;
    & > :first-child{
        margin-bottom: 8px;
    };
`;

const Cards = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    box-shadow: ${props => props.boxShadow ? '1px 1px 4px 0 rgba(0, 0, 0, 0.18)' : ''};
    z-index:12;
    margin: ${props => props.margin ? props.margin : '0'};
    box-shadow: 0 1px 6px 0 rgba(0,0,0,0.2);
    border: 1px solid #436ab2;
    border-radius: 10px;
    padding: 24px;
    margin: 16px 0;
`

const CardComponents = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    & > :first-child{
        margin-bottom: 12px;
    }
`

const SelectButton = styled.button`
    display: flex;
    align-items: center;
    justify-content:center;
    min-width: 88px;
    height: 36px;
    border-radius: 5px;
    border: solid 1px ${props => props.backgroundColor ? props.backgroundColor : '#436ab2'};
    color: ${props => props.selected ? '#fff' : '#4a4a4a'};
    background-color: ${props => props.selected ? (props.backgroundColor ? props.backgroundColor : '#436ab2') : '#fff'};
    font-family: Avenir;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    & > img{
        margin-right: 4px;
    };
    cursor: pointer;
`

const AddButton = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    min-width: 114px;
    height: 36px;
    border-radius: 5px;
    border: solid 1px ${props => props.color};
    color: ${props => props.selected ? '#fff' : '#4a4a4a'};
    background-color: ${props => props.selected ? props.color : '#fff'};
    font-family: Avenir;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    & > svg {
        margin-left: 14px 
    };
    cursor: pointer;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  //Chrome, Safari, newer versions of Opera
  ::-webkit-scrollbar {
    display: none;
  }
  //Firefox
  overflow: -moz-scrollbars-none;
  //Internet Explorer +10
  -ms-overflow-style: none;
  & > :not(:last-child){
    margin-right: 20px;
  };
`

const PaymentFooter = styled.div`
  position: fixed;
  width: 100%;
  max-width: ${maxScreenWidth};
  background-image: url(${buttonBackground});
  background-size: cover;
  background-postion: left bottom;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.1rem 1.8rem 1.1rem 2.4rem;
  z-index: 30;
`;

const FooterLeft = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  & > :first-child {
    margin-bottom: 8px;
  }
`;

const AuthoriseButton = styled.div`
  height: 5.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #ffffff;
  cursor: pointer;
`;

const AuthoriseText = styled.p`
  height: 2.2rem;
  font-family: Avenir;
  font-size: 1.6rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.114rem;
  color: #4a4a4a;
  padding: 0 8px 0 12px;
`;

const RightArrow = styled.img`
  margin-left: 0.8rem;
  height: 3.2rem;
  width: 3.2rem;
  margin-right: 1rem;
`;

const ThickBorder = styled.div`
      display: flex;
      height: 12px;
      margin: 0px -24px;
      background-color: #ececec;
`

const EditNumberPopup = styled.button`
  width: 100%;
  max-width: 538px;
  display: flex;
  border-radius: 8px 8px 0 0 ;
  margin: 12px auto 0 auto;
  align-self: center;
  flex-direction: column;
  padding: 0 1.2rem;
  bottom: 0;
  position: fixed;
  background-color: white;
  padding: 24px 24px 48px 24px;
  z-index: 35;
  :focus {
    outline: none;
  };
  transform: ${(props) =>
    props.showTip === true
      ? "translateY(0) scaleY(100%)"
      : "translateY(102%) scaleY(0)"};
  transition: all 0.6s;
`;


const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${ props => props.paddingBottom ? props.paddingBottom : '26px'};
  border-bottom: 1px solid #4a90e230;
`


const CardIcon = styled.img`
  width:auto !important;
  height: 36px;
`

const CardHeaderTileTitle = styled.div`
  display: flex;
  align-items: center;
  & > img{
    margin-right: 8px;
  }
`

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  `

const ThirdParty = styled.div`
display: flex;
flex-direction: column;
margin-right: 10px;
align-items: baseline;
& > p {
  margin-bottom: ${props => props.marginBottomText || '4px'};
};
& > img {
  object-fit: contain;
}
`

const PolicyDocWrapper = styled.div`
  padding: 1.8rem 0;
  border-bottom: 1px solid rgba(74, 144, 226, 0.3);

  & > h2 {
    margin-bottom: 0.8rem;
  }

  & > button {
    margin-top: 1.2rem;
  }
`;

const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: ${(props) => (props.active ? "1.2rem" : "0")};
`;

const Tooltip = styled.img`
  position: absolute;
`

const Heading = styled.p`
font-family: Avenir;
font-size: 16px;
color: #436ab2;
letter-spacing: 0.57px; 
font-weight: 600;
@media (min-width: 1024px) {
  font-size: 18px;
}
`

const Amount = styled.div`
    display: flex;
    align-items: flex-end;
`