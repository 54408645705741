import React, { Component } from 'react'
import styled from 'styled-components'
import {maxScreenWidth} from '../../config.json'

function inDesktopView(ChildComponent){
    return class extends Component{

        componentDidMount(){
            window.scrollTo(0,0)
        }

        render(){
            return(
                <Home>
                    <ChildComponent {...this.props} />
                </Home>
            )
            
        }
    }
}

export default inDesktopView
    

const Home = styled.div`
    display: flex;
    flex:1;
    min-height: 100vh;
    box-shadow: -2px 0px 78px -7px rgba(238,238,238,1);
    margin: 0 auto;
    flex-direction: column;
    width: 100%;
    max-width: ${maxScreenWidth};
`