import styled from "styled-components";

export const ChipWrapper = styled.span`
  background: ${props => props.background ? props.background : "#81c14b"};
  border-radius: 6px;
  color: white;
  padding: 0.4rem 0.8rem;
  font-size: 11px;
  max-width: min-content;
  text-align: center;
  user-select: none;
  white-space: nowrap;
`;
