const industry = [{
    id: 1,
    label: 'aviation, shipping, oil & gas',
    accepted: false,
},{ 
    id: 2,
    label: 'healthcare providers example hospitals, clinics etc	',
    accepted: false,
},{
    id: 3,
    label: 'insurance companies, intermediaries & claim service providers',
    accepted: false,
},{
    id: 4,
    label: 'media and entertainment	',
    accepted: false,
},{
    id: 5,
    label: 'metals and mining, manufacture of hazardous goods',
    accepted: false,
},{
    id: 6,
    label: 'non employer employee groups incl. mlm, societies or trusts',
    accepted: false,
},{
    id: 7,
    label: 'professional & semi professional sportsperson',
    accepted: false,
},{
    id: 8,
    label: 'armed forces, military, paramilitary & police	',
    accepted: false,
},{
    id: 9,
    label: 'law firm or NGO',
    accepted: false,
},{
    id: 10,
    label: 'construction',
    accepted: false,
},{
    id: 11,
    label: 'IT services & products',
    accepted: true,
},{
    id: 12,
    label: 'human resources',
    accepted: true,
},{
    id: 13,
    label: 'consumer & retail products	',
    accepted: true,
},{
    id: 14,
    label: 'travel & hospitality',
    accepted: true,
},{
    id: 15,
    label: 'real estate',
    accepted: true,
},{
    id: 16,
    label: 'government services',
    accepted: true,
},{
    id: 17,
    label: 'energy & enviromental',
    accepted: true,
},{
    id: 18,
    label: 'education',
    accepted: true,
},{
    id: 19,
    label: 'logistics & manufacturing',
    accepted: true,
},{
    id: 20,
    label: 'others',
    accepted: true,
},


]

export default industry