import React, { PureComponent,Fragment } from 'react'
// import React, { Component, Fragment } from "react";

import styled from 'styled-components'
import { Ptag } from '../../components/fonts'


import Header from "../../components/memberApp/header";
import { formatAmount } from "../../utils/utils";
import Icons from "../../components/icons";
import vitalpoints from "../../assets/svgs/points.svg";
import LargeButton from '../../components/LargeButton'
import http from "../../services/httpService";
import OverlayLoader from "../../components/overlayLoader";
import config from '../../config.json'
import { DiscountsText } from "../../components/memberApp/constants/PolicyConstants";


class SubscriptionselfPay extends PureComponent {
    constructor(props){
        super(props)
        this.state ={
            loader: true,
            sub_amount:'',
            premium:'',
            total_tax:'',
            platform_fee_tax:'',
            platform_fee:'',
        }
    }
    
     componentDidMount(){
         this.setState({
            sub_amount:localStorage.getItem("sub_amount"),
            premium:localStorage.getItem("premium"),
            total_tax:localStorage.getItem("total_tax"),
            platform_fee:localStorage.getItem("platform_fee"),
            platform_fee_tax:localStorage.getItem("platform_fee_tax")


         }) 
    
     }

    onClickPay = async (id, duration) => {

        const _this = this
    
        let body = {
            userplan:localStorage.getItem("userplan_id"),
            points_applied:0,
            payment: {
              method:0,
              plan: 0,
            }
          }
       try {
        
        let response = await http.post('/business/subscription/', { ...body })
        console.log(response, 'defefrokoko')
       
        const options = {
            async handler(response) {
    
    
            //   await http.post('/payment/verify/', {
            //     razorpay_payment_id: response.razorpay_payment_id,
            //     razorpay_signature: response.razorpay_signature,
            //     razorpay_order_id: response.razorpay_order_id
            //   })
    
    
              _this.props.history.push('/business/paymentsuccess')
            },
            ...response.data
          }
          const rzp = new window.Razorpay(options)
          rzp.open()
        } 
        catch (ex) {
            console.log('Error-Ex: ', ex)
            if (ex.status) console.log('Error-Ex-status: ', ex.status)
          }
     }
      
        
    

        


    render() {
        return (
      
            <Root>
            {this.state.loading && <OverlayLoader/>}
              <Header backPage='/business/selfpaymentoptions' />
              <Body>
                  <Ptag style={{padding: '0px 24px 18px'}} fontSize='20px' fontWeight='600' color='#336188'>
                      {'Payment Summary'}
                  </Ptag>
                 

        {/* <ApplyPointsWrapper>
        <RootDiv>
            <Icons icon="circle" fill="#436AB2" />
            <Text style={{marginLeft: '10px'}}>Apply</Text>
            <Image height='20px' width='20px' src={vitalpoints}></Image>
            <Text>32</Text>
        </RootDiv>
        </ApplyPointsWrapper>
        <Paymentonemonthdiv>
        <Ptag fontSize='12px' letterSpacing='0.5px' style={{padding: '8px 24px', }}  >
        Payment mode selected is for 1 month
        </Ptag>
        </Paymentonemonthdiv> */}
        <Taxgstdiv>
        <FlexRow style={{marginBottom:'7px'}}>
            <Ptag letterSpacing='0.2px' fontSize='14px' fontWeight='600' color='rgb(74, 74, 74)'>Total Subscription</Ptag>
            <Ptag letterSpacing='0.2px' fontSize='14px' fontWeight='600' color='rgb(74, 74, 74)'>

              { "₹" + formatAmount(this.state.sub_amount || 0) }

            </Ptag>
        </FlexRow>
        <FlexRow style={{marginBottom:'7px'}}>
            <Ptag letterSpacing='0.2px' fontSize='10px' color='rgb(74, 74, 74)'>Insurance Premium</Ptag>
            <Ptag letterSpacing='0.2px' fontSize='10px' color='rgb(74, 74, 74)'>
             
              { "₹" + formatAmount(this.state.premium || 0) }

            </Ptag>
        </FlexRow>
        <FlexRow style={{marginBottom:'7px'}}>
            <Ptag letterSpacing='0.2px' fontSize='10px' color='rgb(74, 74, 74)'>Wellness Fee</Ptag>
            <Ptag letterSpacing='0.2px' fontSize='10px' color='rgb(74, 74, 74)'>
              { "₹" + formatAmount(this.state.platform_fee || 0) }
              </Ptag>
        </FlexRow>
        <FlexRow style={{marginBottom:'7px'}}>
            <Ptag letterSpacing='0.2px' fontSize='14px' fontWeight='600' color='rgb(74, 74, 74)'>GST (@18%)</Ptag>
            <Ptag letterSpacing='0.2px' fontSize='14px' fontWeight='600' color='rgb(74, 74, 74)'>
             
              { "₹" + formatAmount(this.state.total_tax || 0) }
              </Ptag>
        </FlexRow>
        {/* <FlexRow style={{marginBottom:'7px'}}>
            <Ptag letterSpacing='0.2px' fontSize='14px' fontWeight='600' color='rgb(242, 117, 118)'>Points Applied</Ptag>
            <Ptag letterSpacing='0.2px' fontSize='14px' fontWeight='600' color='rgb(242, 117, 118)'>-₹2,607</Ptag>
        </FlexRow> */}
        <Hr />
        <FlexRow>
            <Ptag letterSpacing='0.2px' fontSize='14px' fontWeight='600' color='rgb(48, 79, 254)'>Total</Ptag>
            <Ptag letterSpacing='0.2px' fontSize='14px' fontWeight='600' color='rgb(48, 79, 254)'>
              { "₹" + formatAmount(this.state.sub_amount || 0) }
             
            </Ptag>
        </FlexRow>
        </Taxgstdiv>
              </Body>
              <SubmitWrap position={'fixed'}>
                  <LargeButton onClick={this.onClickPay} label="PAY NOW" type="submit" />
              </SubmitWrap>
          </Root>
        
        )
    }
}

export default SubscriptionselfPay

const Paymentonemonthdiv = styled.div`
    
    display: flex;
    flex-direction: column;
    background-color: rgb(233, 243, 255);
    
    margin-top: 12px;
    
`
const Hr = styled.div`
  background: #9b9b9b;
  height: 1px;
  margin: 15px 0px; 
`;

const Taxgstdiv = styled.div`
width: 100%;
padding: 30px 18px 36px;
margin-bottom:24px;
background-color: rgb(255, 255, 255);
box-shadow: rgb(0 0 0 / 15%) 0px 1px 4px 0px;
`;

const FlexRow = styled.div`
display: flex;
justify-content: space-between;
`;


const Root = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2.4rem 0 2rem 0;
`

const Card = styled.div`
  display:flex;
  flex-direction:column;
  background-color: #ffffff;
  /* padding:30px 34px 8px 34px; */
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.15);
  margin-top:12px;
  & + p {
    margin: 3.6rem 0 2.4rem 0;
  }
`;
const Headingdiv = styled.div`
  display:flex;
  flex-direction:row;
  min-width: 375px;
  /* height: 48px; */
  background-color:#e9f3ff;
  padding:8px 24px 8px 24px; 

`;


const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding:30px 34px 36px 34px;
  & div {
    margin-bottom: 7px;
  }
`;


const ApplyPointsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 1px 4px 0px rgba(74, 74, 74, 0.19);
  padding: 20px 12px 20px 26px;
`


const RootDiv = styled.div`
  display :flex;
  align-items: end;
  flex-direction: row;
  background-color: #ffffff;
`

const Text = styled.p`
  color: #436ab2;
  letter-spacing: 0.57px;
  font-size: 16px;
  font-family: Avenir;
  font-weight: 600;
`

const Image = styled.img`
  display:flex;
  margin: 0px 3px 0 8px;
`

const SubmitWrap = styled.div`
  max-width: ${config.maxScreenWidth};
  width: 100%;
  background: #fff;
  z-index: 9;
  padding: ${props => (props.position === 'fixed' ? '0 24px 24px 24px' : '0')};
  bottom: ${props => (props.position === 'fixed' ? '0' : 'unset')};
  position: ${props => props.position};
`