import styled from 'styled-components'

import React from 'react'
import suggested_credit from '../assets/svgs/suggested_credit.svg'
import arrow_forward from '../assets/svgs/arrow_forward.svg'

export default class EmiApplyTypes extends React.Component {
  constructor(props) {
    super(props)
  }
  moveToPan =() =>{
      this.props.history.push('/pan')
  }

  render() {
    return (
      <CardDiv onClick ={this.moveToPan}>
        <Text>{'Apply for EMI'}</Text>
        <Text1>{'Check Eligibility'}</Text1>
      </CardDiv>
    )
  }
}

const CardDiv = styled.div`
  width: 279px;
  height: 84px;
  margin-top:34px;
 
  object-fit: contain;
  padding: 18px 18px 0 18px;
  margin-right: 8px;
  border-radius: 18px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
  background-color: #436ab2;
  padding-bottom :0px;
  display: flex;
  position: relative;
  flex-direction: column;
  // BG IMAGE PROPERTIES
  background-image: url(${suggested_credit});
  background-position: center;
  background-3: no-repeat;
`
const Text = styled.p`
width: 245px;
height: 24px;
font-family: Avenir;
font-size: 16px;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: normal;
color: #ffffff;
`
const Text1=styled.p`
width: 104px;
  height: 20px;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: #ffffff;
  margin-top:13px;
`

const Arrow = styled.img`
  display: flex;
`

const ActivateBtn = styled.div`
  font-family: Avenir;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2px;
  text-align: center;
  cursor: pointer;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 18px;
  margin-bottom: 18px;
`

const ActivateText = styled.p`
  font-family: Avenir;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: center;
  color: #fff;
`
