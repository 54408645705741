import React, { Component } from 'react'
import styled from 'styled-components'
import Icon from '../icons'
import {Ptag} from '../fonts'
import bin from '../../assets/images/redbin.png'
import { Field } from 'formik'

export default class EmployeeDetails extends Component{

    constructor(props){
        super(props)
    }

    formatHeading = (member) => {
        if (member['relationship'] && member['relationship'].toLowerCase() === 'self')
            return 'employee'
        else 
            return member['relationship'] && member['relationship'].toLowerCase()
    }

    getOptions = (relation) => {
        const options = [
            {
                value:5,
                range: '0-5 years'
            },
            {
                value:24,
                range: '5-24 years'
            },
            {
                value:35,
                range: '25-35 years'
            },
            {
                value:40,
                range: '36-40 years'
            },
            {
                value:45,
                range: '41-45 years'
            },
            {
                value:50,
                range: '46-50 years'
            },
            {
                value:55,
                range: '51-55 years'
            },
            {
                value: 60,
                range: '56-60 years'
            },
            {
                value: 65,
                range: '60-65 years'
            }
        ]
        if (relation  && (relation.indexOf('son') === -1 && relation.indexOf('daughter') === -1)){
            return options.slice(1)
        }
        return options.slice(0,2)
    }

    renderError = (formikProps ,memberIndex, name) => {
        const {touched, errors, values} = formikProps
        let touch =  touched['employees'] && touched['employees'][this.props.id] && touched['employees'][this.props.id]['members'] && touched['employees'][this.props.id]['members'][memberIndex] && touched['employees'][this.props.id]['members'][memberIndex]['age']
        let value = formikProps.values['employees'] && formikProps.values['employees'][this.props.id] && formikProps.values['employees'][this.props.id]['members'] && formikProps.values['employees'][this.props.id]['members'][memberIndex] && formikProps.values['employees'][this.props.id]['members'][memberIndex]['age']
        let error = value ? '' : '*Required'
        return(
            <Error>
                {touch && error}
            </Error>
        )
    }


    renderAgeDropDown = (formikProps, memberIndex, member) => {
        const {touched, handleBlur, values, status, setFieldTouched, errors,handleChange} = formikProps
        const name = `employees.${this.props.id}.members.${memberIndex}.age`
        const dependentAgeValue = values && values['employees'] && values['employees'][this.props.id] && values['employees'][this.props.id]['members'] && values['employees'][this.props.id]['members'][memberIndex] && values['employees'][this.props.id]['members'][memberIndex]['age']
        return(
            <DropdownComponentWrapper>
                <DropdownWrapper>
                    <AgeWrapper 
                        name={name}
                        onChange={handleChange} 
                        onBlur={(e => setFieldTouched(name, true))}
                        >
                        <option value='' default>
                            {'Age'}
                        </option>
                        {this.getOptions(member['relationship']).map(item => (
                            <option selected={String(item.value) === String(dependentAgeValue) && 'selected'} key={item.value} value={item.value}>
                                {item.range}
                            </option>
                        ))
                        }
                    </AgeWrapper>
                    <Icon style={{'position': 'absolute', 'marginLeft': '74%'}} icon='chevron_down' fill="#7e7b7f" />
                </DropdownWrapper>
                {this.renderError(formikProps ,memberIndex, name)}
            </DropdownComponentWrapper>
        )
    }

    renderAddDependents = (formikProps) => {
        const membersType = localStorage.getItem('membersType')
        const memberCount = {
            'spouse': 1,
            'kids':4,
            'mother': Math.abs(membersType - 1),
            'father': Math.abs(membersType -1),
            'self':1
        }
        
        if (!(formikProps.values['employees'][this.props.id] && formikProps.values['employees'][this.props.id]['members']) || formikProps.values['employees'][this.props.id]['members'].length === 6)
            return null
        for (let member of formikProps.values['employees'][this.props.id]['members']){
            if (member['relationship'] === 'son' || member['relationship'] === 'daughter') {
                memberCount['kids']--
            } else {
                memberCount[member['relationship']]--
            }
        }
        let ButtonsList = []
       
        for (let [key, value] of Object.entries(memberCount)){
            if (value > 0){
                if (key === 'kids'){
                    ButtonsList.push('son')
                    ButtonsList.push('daughter')
                } else {
                    ButtonsList.push(key)
                }
            }
        }
        if (ButtonsList.length > 0){
            return(
                <AddDependentsRoot>
                    <Ptag letterSpacing='0.21px' letterHeight='1.67' fontSize='12px' >{'ADD DEPENDENTS'}</Ptag>
                    <ButtonsWrapper>
                        {ButtonsList.map((item, index) => 
                            <Buttons key={index} onClick={() => this.props.onAddFamilyMembers(this.props.id, item, formikProps)}>
                                {item}
                            </Buttons>    
                        )}
                    </ButtonsWrapper>
                </AddDependentsRoot>
            )
        }
        
        else
            return null
    }

    

    getCardHeader = () => {
        if(this.props.employee.expanded)
            return null

        
        const values = this.props.formikProps.values && this.props.formikProps.values['employees'] && this.props.formikProps.values['employees'][this.props.id] && this.props.formikProps.values['employees'][this.props.id]['members']
        if (values){
            let options = this.getOptions('self')
            let age = options.filter(item => item.value === parseInt(values[0]['age']))
            let text = `Employee ${age[0] && age[0]['range'] || ''}`
            
            return (
                <HeaderText>
                    {text}
                    {values && values.length > 1 && <Circle/>}
                    {values && values.length > 1 && `${values.length -1} more`}
                </HeaderText>
            )
        }
    }

    render(){
        const {expanded} = this.props.employee
        return(
            <Root>
                <TopRow onClick={() => this.props.onClickCardHeader(this.props.id)}>
                    <NumberAndText>
                        <Number>
                            {this.props.id + 1}
                        </Number>
                        {this.getCardHeader()}    
                    </NumberAndText>
                    {<Icon  icon={expanded ? 'chevron_down' : 'chevron_right'} fill="#7e7b7f" />}
                </TopRow>
                {
                    <div style={{'display': expanded ? 'block': 'none'}}>
                        {
                            this.props.employee && this.props.employee.members.map( (member, memberIndex, arr) =>
                                <MemberRow key={`Member${memberIndex}`}>
                                    <Ptag textTransform='capitalize' fontSize='16px' fontWeight='500' letterHeight='normal' letterSpacing='0.25px' color= '#4a4a4a'>
                                        {this.formatHeading(member)}
                                    </Ptag>
                                    <DropdownAndBin>
                                        {this.renderAgeDropDown(this.props.formikProps, memberIndex, member)}
                                        {member['relationship'] !== 'self' && <BinImage onClick={() => this.props.onDeleteFamilyMember(this.props.id, memberIndex, this.props.formikProps) } src={bin} height='24px' width='19px'/>}
                                    </DropdownAndBin>
                                </MemberRow>
                            )
                        }
                        {this.renderAddDependents(this.props.formikProps)}
                        <CardFooter margin='12px -16px -25px -17px'>
                            <CardFooterButton onClick={() => this.props.onSaveEmployee(this.props.formikProps, this.props.id)} width="50%">
                                {'SAVE'}
                            </CardFooterButton>
                            <CardFooterButton onClick={() => this.props.onDeleteEmployee(this.props.formikProps, this.props.id)} width="50%">
                                {'REMOVE'}
                            </CardFooterButton>
                        </CardFooter>
                    </div>                
                }
                
            </Root>
        )
    }
}

const Root=styled.div`
    display: flex;
    margin-top: 24px;
    flex-direction: column;
    width: 100%;
    padding: 24px 16px;
    border-radius: 15px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
`;

const TopRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const NumberAndText = styled.div`
    display:flex;
    align-items: center;
`;

const Number = styled.div`
    border-radius: 18px;
    min-width: 36px;
    height: 36px;
    padding-top: 6px;
    background-color: ${props => props.bGcolor ? props.bGcolor : '#d8d8d8'};
    color: ${props => props.color ? props.color : '#009999'};
    font-family: Open Sans;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    text-align: center;
`

const MemberRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 18px;
    align-items: center;
    margin: 18px -5px 6px 10px;
`

const DropdownAndBin = styled.div`
    display:flex;
    justify-content:space-between;
    align-items: center;
    margin-right: 36px;
    position: relative;
`;

const DropdownWrapper = styled.div`
    display: flex;
    padding: 6px;
    width: 130px;
    height: 48px;
    border-radius: 7px;
    border: solid 1px #436ab2;
    align-items: center;
`;


const AgeWrapper = styled.select`
    display: flex;
    width: 100%;
    margin-left: 8px;
    border: none;
    font-family: Open Sans;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.5);
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
`;

const BinImage = styled.img`
    margin-left: 112%;
    position:absolute;
    width: 14px;
    height: 18px;
`;

const AddDependentsRoot = styled.div`
    display:flex;
    flex-direction: column;
    margin: 24px 0 12px 10px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-wrap:wrap;
    margin-top: 18px;
`;

const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 83px;
    height: 36px;
    border-radius: 8px;
    border: solid 1px #436ab2;
    margin: 0 12px 12px 0;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #4a4a4a;
    text-transform: capitalize;
`

const DropdownComponentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Error = styled.p`
  color: red;
  font-size: 1.4rem;
  margin-top: 5rem;
  position: absolute;
  z-index: 9;
`

const CardFooter = styled.div`
    display: flex;
    border-top: 1px solid rgba(125, 124, 124, 0.2);
    height: 64px;
    align-items: center;
    margin: ${props => props.margin};
    & > :not(:last-child) {
        border-right: 1px solid rgba(125, 124, 124, 0.2);
      }
`;

const CardFooterButton = styled.div`
    display: flex;
    width: ${props => props.width};
    height: 100%;
    align-items: center;
    justify-content: center;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.71px;
    color: #f27576;
`;


const Circle = styled.div`
  width:4px;
  height:4px;
  border-radius: 2px;
  background-color: #7d7c7c;
  margin: 10px 6px;
`;

const HeaderText = styled.div`
    display: flex;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #4a4a4a;
    margin-left: 12px;
    text-overflow:ellipsis;
    overflow: hidden;
    white-space:nowrap;
`