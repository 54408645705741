import React, { Component } from 'react'
import styled from 'styled-components'

const SmallBtn = styled.button`
  height: 3.6rem;
  border-radius: 1.8rem;
  background-color: ${props => (props.selected ? '#979797' : props.bgColor ? props.bgColor : 'transparent')};
  font-size: 1.2rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  color: ${props => (props.selected ? '#fff' : props.color ? props.color : '#979797')};
  // color: ${props => (props.selected || props.color ? '#fff' : '#979797')};
  text-decoration: none;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
  outline: none;
  style: none;
  cursor: pointer;
  border-style: none;
  border: ${props => (props.border ? props.border : '#979797 solid 1px')};
  padding: 0 1.8rem;
  // overflow: hidden;
  // transition: all 0.3s ease;

  & :focus, :hover {
    background-color: ${props => (props.bgColorActive ? props.bgColorActive : '#fff')}
    color: ${props => (props.colorActive ? props.colorActive : '#fff')}
  }
`

export default class SmallButton extends Component {
  render() {
    const { label, ...props } = this.props
    return <SmallBtn {...props}>{label}</SmallBtn>
  }
}
