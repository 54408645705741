const debit_banks_name = [
  {
    bank_name: 'Kotak Mahindra Bank',
    title: 'KOTAK',
    image: require('../../../assets/images/bank/kotak.svg'),
    recurring: true
  },
  {
    bank_name: 'ICICI Bank',
    title: 'ICICI',
    image: require('../../../assets/images/bank/icici.svg'),
    recurring: true
  },
  {
    bank_name: 'Canara Bank',
    title: 'Canara',
    image: require('../../../assets/images/bank/canara.png'),
    recurring: true
  }, 
  {
    bank_name: 'Citi Bank',
    title: 'Citibank',
    image: require('../../../assets/images/bank/citi.png'),
    recurring: true
  },
  {
    bank_name: 'Others',
    title: 'Others',
    image: require('../../../assets/images/bank/others.svg')
  }
]

export default debit_banks_name
