import React, { Component } from 'react'
import styled from 'styled-components'
import Credit5 from '../assets/images/credit5.png'

const Wrapper = styled.div`
  width: 100%;
  // background: rgba(238, 238, 238, 1);
  padding-left: 2.4rem;
  margin-bottom: 3rem;


  @media (min-width: 1023px) {
    padding-left: 0;
    width: 40rem;
    margin-right: 100px;
  }

  @media (min-width: 1680px) {
    width: 60rem;
    p,
    h2 {
      margin-bottom: 4.8rem;
      width: auto;
    }
  
`
const Header = styled.div`
width: 32rem;
padding-left: 2.4rem;
margin-bottom: 3rem;

`

const Image = styled.img`
  // background: rgba(238, 238, 238, 1);
  width: 324px;
  height: 384px;

  @media (min-width: 1024px){
    width: 364px;
    height: 384px;
  }

  @media (min-width: 1680px){
    width: 384px;
    height: 404px;
  }
`

const H2 = styled.h2`
  width: 293px;
  // height: 103px;
  font-family: Proxima Nova;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.25px;
  color: #4a4a4a;

    @media (min-width: 1023px) {
      font-size: 34px;
      width: 100%;
      font-weight: 600;
    }

    @media (min-width: 1680px) {
      font-size: 44px;
      width: 100%;
      font-weight: 600;
    }
`
const ImageDv = styled.div`
    // background: rgba(238, 238, 238, 1);
    padding-left: 24px;
    padding-bottom: 36px;
    @media (min-width: 1024px) {
      padding-left: 0;
      padding-bottom: 0;
    }
`
const ParentDiv = styled.div`
  width: 100%;
  // background: rgba(238, 238, 238, 1);
  padding: 3.6rem 0 0 0;
  position: relative;

  @media (min-width: 1023px) {
    padding: 12.2rem 0;
    display: flex;
    // background: rgba(238, 238, 238, 1);
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`

export default class Page33 extends Component {
  render = () => {
    return (
    <ParentDiv>
      <Wrapper>
        {/* <Header> */}
          <H2>Now treatments not <br />covered by insurance<br /> within reach</H2>
        {/* </Header> */}
      </Wrapper>

      <ImageDv>
        {/* <Image src={Credit5}/> */}
        <Image alt="covered treatments" src = "http://images.getvital.in/homelanding/credit5.png" />
      </ImageDv>
      </ParentDiv>
    )
  }
}
