import React from 'react'
import { css } from '@emotion/core'
// First way to import
import { BeatLoader } from 'react-spinners'
// Another way to import
// import ClipLoader from 'react-spinners/ClipLoader'

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`

export default class LoaderDots extends React.Component {
  state = {
    loading: true
  }

  render() {
    return (
      <div>
        <BeatLoader css={override} sizeUnit={'px'} size={12} color={'#123abc'} loading={this.state.loading} />
      </div>
    )
  }
}
