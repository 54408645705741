import React from 'react'
import styled from 'styled-components'
import { LargeButton } from './'
import kite1920 from '../assets/images/kite1920.png'
import kite1280 from '../assets/images/kite1280.png'
import kite375 from '../assets/images/kite375.png'
import chatHomePage from '../assets/svgs/chatHomePage.svg'

const Wrapper = styled.div`
  background: url(${kite1920});
  background-position: right top;
  background-repeat: no-repeat;
  // background-size: cover;
  // height: 100rem;
  padding: 14rem 0 15.2rem 0;

  // @media (min-width: 1280px) and (max-width: 1919px) {
  // @media (max-width: 1280px) {
  // @media (max-width: 1680px) {
  // @media (max-width: 1366px) {
  @media (max-width: 1440px) {
    padding: 7.2rem 0;
    background: url(${kite1280});
    background-position: right top;
    background-repeat: no-repeat;
    background-position-y: -12rem;
  }

  @media (max-width: 768px) {
    padding: 0;
    padding-bottom: 2.46rem;
    background: url(${kite375});
    background-position: right top;
    background-repeat: no-repeat;
    // background-position-y: -12rem;
  }
`

const TagLineWrapper = styled.div`
  display: inline-block;
  padding: 10.6rem 16.8rem 12.4rem 13.8rem;
  background: #eeeeee;

  h1:last-child {
    margin-bottom: 1.3rem;
  }

  p {
    width: 76.6rem;
  }

  button {
    margin-top: 4rem;
    width: 40%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    line-height: 1.36;
    letter-spacing: 0.06px;
  }

  // @media (min-width: 1280px) and (max-width: 1919px) {
  // @media (max-width: 1280px) {
  // @media (max-width: 1680px) {
  @media (max-width: 1440px) {
    padding: 5.1rem 5.4rem 5.1rem 4.8rem;
    h1:last-child {
      margin-bottom: 3.8rem;
    }
    p {
      width: 57rem;
    }
    button {
      width: 54.6%;
      margin-top: 2.4rem;
      font-size: 2.8rem;
      letter-spacing: 0.06px;
      padding: 2.1rem 4.4rem 2.46rem 4.4rem;
    }
  }

  @media (max-width: 768px) {
    background: transparent;
    padding: 3.2rem 5.4rem 0 2.4rem;
    h1:last-child {
      margin-bottom: 3.8rem;
    }
    p {
      width: 24rem;
      font-size: 16px;
    }
    button {
      width: 84.6%;
      margin-top: 1.4rem;
      font-size: 1.6rem;
      letter-spacing: 0.03px;
      padding: 1.3rem 3.8rem;
    }
  }
`
const ChatLogo = styled.div`
  padding-right: 6.57rem;
  display: flex;
  justify-content: flex-end;

  // @media (min-width: 1280px) and (max-width: 1919px) {
  // @media (max-width: 1280px) {
  // @media (max-width: 1680px) {
  @media (max-width: 1440px) {
    margin-top: -3.5rem;
  }

  @media (max-width: 768px) {
    margin-top: 14rem;
    padding-right: 1.6rem;
    img {
      width: 5.16rem;
      height: 4.8rem;
    }
  }
`

const Font80 = styled.h1`
  font-family: Proxima Nova;
  font-size: 8rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 2px;
  text-align: left;
  color: #49a38e;

  // @media (min-width: 1280px) and (max-width: 1919px) {
  // @media (min-width: 1280px) {
  // @media (max-width: 1680px) {
  @media (max-width: 1440px) {
    font-size: 6rem;
    letter-spacing: normal;
  }
  @media (max-width: 768px) {
    font-size: 3.4rem;
    letter-spacing: 1px;
    // letter-spacing: 0.02;
    width: 20rem;
  }
`

const Font34 = styled.p`
  font-family: Avenir;
  font-size: 34px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.59;
  letter-spacing: 0.01px;
  text-align: left;
  color: #4a4a4a;

  // @media (min-width: 1280px) {
  // @media (min-width: 1280px) and (max-width: 1919px) {
  // @media (max-width: 1280px) {
  // @media (max-width: 1680px) {
  @media (max-width: 1440px) {
    line-height: 1.76;
  }
`

export default function HealthCareSolution() {
  return (
    <Wrapper>
      <TagLineWrapper>
        <Font80>Next Gen Healthcare </Font80>
        <Font80>Financing Solution</Font80>
        <Font34>Secure yourself and your family with much more for a lot less.</Font34>
        <LargeButton
          label="START FOR FREE"
          padding="2.1rem 4.4rem 2.5rem 4.4rem"
          fontSize="2.8rem"
          borderRadius="1rem"
        />
      </TagLineWrapper>
      <ChatLogo>
        <img src={chatHomePage} alt="chat.png" />
      </ChatLogo>
    </Wrapper>
  )
}
