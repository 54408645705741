import React, { Component } from 'react'
import styled from 'styled-components'
import phonecoverpage4 from '../assets/images/landingpage3/phonecoverpage4.png'
import insuranceClaim1 from '../assets/svgs/insuranceClaim1.svg'
// import insuranceClaim2 from '../assets/svgs/insuranceClaim2.svg'
import insuranceClaim2 from '../assets/images/insuranceClaim2.png'
import thumbpage4 from '../assets/images/landingpage3/thumbpage4.png'
import circlepage4 from '../assets/images/landingpage3/circlepage4.png'

const Wrapper = styled.div`
  width: 100%;
  background: #fff;
  padding: 3.6rem 0 4.8rem 0;
  position: relative;

  img:first-of-type {
    position: absolute;
    z-index: 10;
    right: 5%;
    top: 5%;
  }

  img:nth-of-type(2) {
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 16%;
  }

  @media (min-width: 1023px) {
    padding: 12.2rem 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
`
const Header = styled.div`
  width: 32rem;
  padding-left: 2.4rem;
  margin-bottom: 3rem;

  h2 {
    margin-bottom: 1.2rem;
  }

  @media (min-width: 1023px) {
    padding-left: 0;
    width: 40rem;
    margin-left: 140px;

    p {
      width: 30rem;
    }
  }

  @media (min-width: 1680px) {
    width: 60rem;
    p,
    h2 {
      margin-bottom: 4.8rem;
      width: auto;
    }
  }
`

const Image = styled.div`
  background: url(${"http://images.getvital.in/homelanding/insuranceClaim2.png"});
  background-size: cover;
  width: 237.5px;
  height: 482.29px;
  margin: 0 auto;

  @media (min-width: 1024px) {
    margin: 0;
    background: url(${"http://images.getvital.in/homelanding/insuranceClaim2.png"});
    background-size: cover;
    width: 237.5px;
    height: 482.29px;
    // height: 60.92rem;
  }
  @media (min-width: 1680px) {
    background: url(${"http://images.getvital.in/homelanding/insuranceClaim2.png"});
    background-size: cover;
    width: 42rem;
    height: 85.2rem;
    // height: 101.53rem;
  }
`

const H2 = styled.h2`
  font-family: Proxima Nova;
  font-size: 2.4rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    width: 100%;
    font-size: 3.4rem;
    line-height: 1.29;
    letter-spacing: 0.02px;
  }

  @media (min-width: 1680px) {
    font-size: 6rem;
    letter-spacing: 0.03px;
  }
`

const H3 = styled.h3`
  margin-top: 1.2rem;
  font-size: 1.6rem;
  font-family: Avenir;
  font-weight: normal;
  line-height: 1.75;
  letter-spacing: 0.02px;
  color: #4a4a4a;

  @media (min-width: 1023px) {
    margin-top: 2.4rem;
    font-size: 1.8rem;
    line-height: 1.56;
    letter-spacing: normal;
  }
  @media (min-width: 1679px) {
    margin-top: 4.5rem;
    font-size: 3.4rem;
    font-weight: normal;
    letter-spacing: 0.02px;
    line-height: 1.59;
  }
`

export default class Page36 extends Component {
  render = () => {
    return (
      <Wrapper>
        <Header>
          <H2>Hassle Free Claims</H2>
          <H3>Access coverage details anytime on your mobile and request claim with just a few clicks.</H3>
        </Header>
        <Image />
        <img alt ="hassle free insurance claims" src={thumbpage4}  />
        <img  src={circlepage4} alt="circlepage4" />
      </Wrapper>
    )
  }
}
