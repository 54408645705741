import React, { useMemo } from "react";
import { Document, Page, StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import { insurers } from "../memberApp/constants/PolicyConstants";
import _ from "lodash";
import { theme } from "../../theme";

const ECardPdf = ({ members }) => {
  const styles = StyleSheet.create({
    section: {
      margin: "18pt auto",
    },
    eCardWrapper: {
      flexShrink: 0,
      overflow: "hidden",
      display: "flex",
      fontSize: 8,
      border: "1px solid black",
      borderLeft: "14px solid " + theme.primaryColor,
      width: 200,
      padding: 8,
      marginBottom: 8,
    },
    eCardDetailWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 8,
    },
    eCardHeader: {
      fontFamily: "Helvetica-Bold",
      marginBottom: 2
    },
    eCardRow: {
      width: 90,
    },
  });

  const formatRelationship = (text) => {
    let title = text;
    switch (text) {
      case "FATHERINLAW":
        title = "Father-in-Law";
        break;
      case "MOTHERINLAW":
        title = "Mother-in-Law";
        break;
    }
    return title.toLowerCase();
  };

  const membersSegmentations = useMemo(() => {
    return _.chunk(members, 5)
  }, [members]);
  console.log({membersSegmentations})

  return (
    <Document>
      {membersSegmentations.map(membersChunk => (
        <Page size="A4">
          <View style={styles.section}>
            {membersChunk.map((member, index) => (
              <View key={index.toString()} style={styles.eCardWrapper}>
                <View style={styles.eCardDetailWrapper}>
                  <View style={styles.eCardRow}>
                    <Text style={styles.eCardHeader}>Name</Text>
                    <Text>{member.name}</Text>
                  </View>
                  <View style={styles.eCardRow}>
                    <Text style={{fontSize: "6pt"}}>Issued By</Text>
                    <Image style={{marginTop: "2pt", width: "40pt", maxHeight: "100%"}} src={insurers[member.insurer_name.toUpperCase()].logo} />
                  </View>
                </View>
                <View style={styles.eCardDetailWrapper}>
                  <View style={styles.eCardRow}>
                    <Text style={styles.eCardHeader}>Policy Number</Text>
                    <Text>{member.policy_number}</Text>
                  </View>
                  <View style={styles.eCardRow}>
                    <Text style={styles.eCardHeader}>Client ID</Text>
                    <Text>{member.client_id}</Text>
                  </View>
                </View>
                <View style={styles.eCardDetailWrapper}>
                  <View style={styles.eCardRow}>
                    <Text style={styles.eCardHeader}>Date of Birth</Text>
                    <Text>{member.dob}</Text>
                  </View>
                  <View style={styles.eCardRow}>
                    <Text style={styles.eCardHeader}>Relationship</Text>
                    <Text style={{textTransform: 'capitalize'}}>{formatRelationship(member.relationship)}</Text>
                  </View>
                </View>
              </View>
            ))}
          </View>
        </Page>
      ))}
    </Document>
  );
};

export default ECardPdf;
