import styled from 'styled-components'
import React, { Component } from 'react'
import {Ptag} from '../../components/fonts'
import {formatBackendDate} from '../../utils/utils'
import formatAmount from '../../components/memberApp/common/utils'
import check from '../../assets/images/check.png'
import {Header} from '../../components/business'


export default class Receipt extends Component {

	state= {
		receiptData : {}
	}
	componentDidMount = async () =>  {
		let receiptData = JSON.parse(localStorage.getItem('receiptDetails'))
		this.setState({receiptData})
    }
    
    

	render() {
		return (
			<Root >
				<Header onClickBack={() => this.props.history.push('/business/payment')}  headerText='Receipt'/>
				
				
				<VoucherWrapper id="Voucher">

					<VoucherHeader>
						<Ptag style={{'marginBottom':'12px'}} fontSize='24px' textAlign='center' letterSpacing='1.71' fontWeight='600'>
							{'₹ ' + formatAmount(Number(this.state.receiptData['order_id'] && this.state.receiptData['order_id']['amount_paid']))}
						</Ptag>
						<IconAndText>
							<img src={check} height='24px' width='24px'/>
							<Ptag fontSize='16px' textAlign='left' letterSpacing='0.5px' color='#666666'>
								{'Paid Successfully'}
							</Ptag>
						</IconAndText>
					</VoucherHeader>
					<Line />
					
					<PropertyAndValueWrapper width="40%">
						<Property>Payment ID:</Property>
						<Value>{this.state.receiptData['order_id'] && this.state.receiptData['order_id']['payment_id']}</Value>
					</PropertyAndValueWrapper>

					<PropertyAndValueWrapper>
						<Property>Paid on :</Property>
						<Value>{formatBackendDate(this.state.receiptData['start_date'])}</Value>
					</PropertyAndValueWrapper>
					
					{
						this.state.receiptData['order_id'] && this.state.receiptData['order_id']['method'] && 
						<PropertyAndValueWrapper>
							<Property>Method:</Property>
							<Value>{this.state.receiptData['order_id'] && this.state.receiptData['order_id']['method'].toUpperCase()}</Value>
						</PropertyAndValueWrapper>
					}
					

					<PropertyAndValueWrapper>
						<Property>Mobile No:</Property>
						<Value>{localStorage.getItem('phone')}</Value>
					</PropertyAndValueWrapper>
						
					
					
				</VoucherWrapper>
				<Footer>
					<Ptag textAlign='center' fontSize='14px' letterSpacing='0.5px' color='#666666'>
						For any product or service related queries, please contact <Support><a href="tel:+01141182266">support</a></Support> 
					</Ptag>
				</Footer>
				{/* <ButtonWrapper >
					<LargeButton id="DownloadButton" style={{"width":"100%"}} label="DOWNLOAD" type={'submit'} onClick={()=> this.saveAsPDF()}/>
				</ButtonWrapper> */}
			</Root>
		)
	}
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`

const ButtonWrapper = styled.div`
	display:flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top:10px;
	width:100%
	padding: 40px;
`;

const VoucherHeader = styled.div`
	display:flex;
	width: 100%;
	flex-direction: column;
	justify-content:center;
`;

const IconAndText = styled.div`
	display:flex;
	margin-bottom: 14px;
	justify-content: center;
	& img {
		
		margin-right: 6px;
	}

`;


const Support = styled.span`
	font-family: Avenir;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.5px;
	text-align: center;
	color: #4a90e2;
`;

const Line = styled.div`
  height: 0.1rem;
  background-color: #ffffff;
  border-bottom: solid 1px #9b9b9b;
  opacity: 0.12;
`

const VoucherWrapper = styled.div`
	padding-top: 2.4rem;
	display: flex;
	flex-direction: column;	
	position: relative;
	margin: 3.6rem 1.6rem 0rem 1.6rem;
	height: 380px;
	background: #fffff;
	border-radius: 6px 6px 0px 0px;
	box-shadow:  0px 6px 17px 0px rgba(224,215,224,1);
	&:after {
		content: "";
		display: block;
		position: absolute;
		bottom: -10px;
		width: 100%;
		height: 10px;
		background:
			linear-gradient(-45deg, transparent 33.333%,
				#ffffff 33.333%, #ffffff 66.667%,
				transparent 66.667%),
			linear-gradient(45deg, transparent 33.333%,
				#ffffff 33.333%, #ffffff 66.667%,
				transparent 66.667%);
		background-size: 20px 40px;
		transform: rotate(180deg);
	}
`

const Property = styled.p`
  text-transform: uppercase;
  margin-top: 2rem;
  font-family: Avenir;
  font-size: 10px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.21px;
  color: #666666;
`

const Value = styled.p`
  margin-top: 0.6rem;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.29px;
  color: #4a4a4a;
`

const PropertyAndValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.6rem;
  width: ${props => (props.width ? props.width : '')};
`

const Footer = styled.div`
  display: flex;
  
  margin: 90px 0 36px 0;
  padding: 0px 32px;
`
