import React from "react";
import DefaultCard from "./DefaultCard";
import creditlogo from '../../../assets/images/green_up_arrow.png'
import debitlogo from '../../../assets/images/red_down_arrow.png'
import currencyLogo from '../../../assets/svgs/currency_rupee.svg'
import { Ptag } from "../../fonts";
import formatAmount from "../../memberApp/common/utils";
import { CardRow, IconWrapper, FlexColumn } from "./styles";

const InsurancePremium = ({
    sum_premium_due,
    sum_refunds,
    net_premium_and_refunds
}) => {
//   const subtitle = `last updated on ${latest_transaction_date}`;
    const premiumRefund = () =>{
        if(((sum_premium_due).toFixed(2) || 0) < ((sum_refunds).toFixed(2) || 0)){
            return "Refund"
        } else {
            return "Amount Due"
        }
    }
  return (
    <DefaultCard title="Insurance Premium" >
      <section style={{ padding: "1rem" }}>
            { !!sum_premium_due && 
                <CardRow>
                    <IconWrapper>
                        <img src={debitlogo} />
                        <FlexColumn>
                            <Ptag fontSize="14px">Insurance Premium</Ptag>
                            <Ptag fontSize='10px' color='#4a4a4a'>(incl. 18% GST)</Ptag>
                        </FlexColumn>
                    </IconWrapper>
                    <Ptag fontSize="14px" maxWidth="100%" textAlign="center" fontWeight="600">{!!sum_premium_due && `₹${formatAmount(
                        sum_premium_due
                    )}` || "-"}</Ptag>
                </CardRow>
            }
            {!!sum_refunds &&
                <CardRow>
                    <IconWrapper>
                        <img src={creditlogo} />
                        <FlexColumn>
                            <Ptag fontSize="14px">Refund from Deletions</Ptag>
                            <Ptag fontSize='10px' color='#4a4a4a'>(incl. 18% GST)</Ptag>
                        </FlexColumn>
                    </IconWrapper>
                    <Ptag fontSize="14px" fontWeight="600">{!!sum_refunds && `₹${formatAmount(
                        sum_refunds
                    )}` || "-"}</Ptag>
                </CardRow>
            }
            {!!sum_premium_due && !!sum_refunds && (sum_premium_due  !==  sum_refunds) &&
            <>
                <hr/>
                <CardRow>
                    <IconWrapper>
                    <FlexColumn>
                            <Ptag fontSize="14px" marginLeft="24px">{premiumRefund()}</Ptag>
                            <Ptag fontSize='10px'  marginLeft="24px" color='#4a4a4a'>(incl. 18% GST)</Ptag>
                    </FlexColumn>
                    </IconWrapper>
                    <Ptag fontSize="14px" fontWeight="600">{ !!net_premium_and_refunds &&`₹${formatAmount(
                        net_premium_and_refunds
                    )}` || "-"}</Ptag>
                </CardRow>
            </>
            }
      </section>
    </DefaultCard>
  );
};

export default InsurancePremium;
