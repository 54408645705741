import React, { Component } from 'react'
import styled from 'styled-components'
import Icon from './icons'
import { ErrorMessage } from 'formik';
import { PageView, Event, initGA } from '../Tracking';


const FormGroup = styled.div`
  width: 100%;
  margin: ${props => props.margin ? props.margin : '3.6rem 0 0 0'};
  position: relative;
  z-index: 9;

  @media (max-width: 320px) {
    padding-top: 2rem;
  }
`
// padding-bottom: ${props => props.paddingBottom && '1.5rem'};

const Inputs = styled.input`
  width: 100%;
  -webkit-appearance: none;
  font-size: 1.6rem;
  font-family: 'Avenir';
  font-weight: ${props => (props.fontWt ? props.fontWt : 'normal')};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: ${props => (props.letterSpacing ? props.letterSpacing : '0.5px')};
  color: ${props => (props.color ? props.color : '#009999')};
  border: 0;
  border-radius: 0;
  border-bottom: ${props => (props.border ? props.border : '2px solid #436ab2')};
  -webkit-text-fill-color: ${props => props.disabled ? 'rgba(0, 0, 0, 0.54)' : ''};
   background: white;
  padding-left: 5px;
  padding-bottom: 10px; // 5px;
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    transition: background-color 5000s 0s;
    -webkit-text-fill-color: ${props => (props.color ? props.color : '#009999')};
  }

  &:focus {
    outline: none;
    background: none !important;
  }
`

const Small = styled.small`
  display: block;
  font-size: 1.2rem;
  line-height: 1.33;
  color: rgba(0, 0, 0, 0.54);
`

const Error = styled.p`
  color: red;
  font-size: 1.4rem;
  margin-top: 0.8rem;
  z-index: 9;
  position: absolute;
`

const InputWrapper = styled.div`
  display: flex;
  & > svg {
    margin-left: -2.8rem;
  }
  & > :last-child {
    margin-left: ${props => (props.icon2 ? '-15rem' : null)};
  }
`
//For orgnl : above is for demo fix i5 phone
// margin-left: ${props => (props.icon2 ? '-20rem' : null)};

export default class Input extends Component {


  render() {
    let additionalProps = {}
    const {
      small,
      type = 'text',
      error,
      icon1,
      icon2,
      Readonly,
      placeholder,
      e_label,
      e_category,
      disabled,
      autocomplete = 'off',
      margin,
      errorName,
      ...restProps
    } = this.props
    if (type === 'number') {
      additionalProps = {
        pattern: "[0-9]*",
        inputmode: "numeric"
      }
    }
    return (
      <FormGroup margin={margin}>
        <InputWrapper icon2={icon2}>
          <Inputs
            autoComplete={autocomplete}
            type={type}
            placeholder={placeholder}
            readOnly={!!Readonly}
            disabled={disabled}
            border={disabled && '2px solid grey'}
            color={disabled ? '#4a4a4a' : '#009999'}
            {...restProps}
            {...additionalProps}
          />
          {icon1 && (
            <Icon
              icon={icon1.name}
              width={icon1.width}
              height={icon1.height}
              fill={icon1.fill ? icon1.fill : '#436ab2'}
            />
          )}
          {icon2 && (
            <Icon
              icon={icon2.name}
              width={icon2.width}
              height={icon2.height}
              fill={icon2.fill ? icon2.fill : '#436ab2'}
            />
          )}
        </InputWrapper>
        {small && <Small>{small}</Small>}
        {error && <Error>{error}</Error>}
        {errorName && <ErrorMessage name={errorName}>
            {msg => <Error>{msg}</Error>}
          </ErrorMessage>}
      </FormGroup>
    )
  }
}
