import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router'
import Header from '../../components/memberApp/header'
import CollapsibleDiv from '../../components/memberApp/payment/CollapsibleDiv'
import DebitCollapsibleDiv from '../../components/memberApp/payment/DebitCollapsibleDiv'
import NetBankingCollapsibleDiv from '../../components/memberApp/payment/NetBankingCollapsibleDiv'
import nb_bank_names from '../../components/memberApp/constants/BankConstants.js'
import http from '../../services/httpService'
import PaymentBreakup from './paymentBreakup'
import NetBankingDetails from './netBankingDetails'

class SubsPayment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      clickedIdx: 3,
      openedIdx: 3,
      selected_index_nb: '',
      selected_index_credit: '',
      selected_index_debit: '',
      showNetBankingDetails:false
    }
  }

  componentDidMount = async () => {
    const {data} = await http.get(`/payment/subscription_details/`,{
        params: {
            plan: localStorage.getItem('userplan_id'),
            
        }

       
    })
    let maxYear = 0
    for (let year of Object.keys(data)){
      maxYear = Number(year) > maxYear ? Number(year) : maxYear
    }
    const currentPolicy = data[maxYear]
    let points_applied = localStorage.getItem('points_applied')
    points_applied = points_applied && parseInt(points_applied)
    this.setState({
        loading: false,
        amountDue: currentPolicy && currentPolicy['amount_due'] - points_applied,
        recurringAmount: currentPolicy && currentPolicy['amount'],
        suscriptionId: currentPolicy && currentPolicy['vital_subscription_id'],
        points_applied
    })
    localStorage.removeItem('via_subscription')
    localStorage.removeItem('via_subscription_to_details')
  }

  toggleOpen = async idx => {
    if (idx !== this.state.clickedIdx && this.state.openedIdx !== idx) {
      await this.setState({ clickedIdx: idx })
      await this.setState({ openedIdx: idx })
    } else if (idx === this.state.clickedIdx && this.state.openedIdx === idx) {
      await this.setState({ openedIdx: -1 })
    } else {
      await this.setState({ openedIdx: idx })
      await this.setState({ clickedIdx: idx })
    }
  }

  onSelectDebitBank(selected_idx) {
    this.setState({
      selected_index_debit: selected_idx
    })
  }

  onSelectNBBank(selected_idx) {
    this.setState({
      selected_index_nb: selected_idx
    })

    if (selected_idx === -1) return
    else if (selected_idx === 5) {
      this.props.history.push({ pathname: '/business/bankslist' })
      localStorage.setItem('via_subscription', true)
    } else {  
      this.setState({showNetBankingDetails:true})
      // this.props.history.push({ pathname: '/business/netbankingdetails'})
      localStorage.setItem('via_subscription_to_details', true)
      // store bankname in local storage
      localStorage.setItem(
        'subscriptionPayment',
        JSON.stringify({
          method: 1,
          plan: 1,
          payment_mode: 'NB',
          bank_name: nb_bank_names[selected_idx].bank_name,
          amountDue: this.state.amountDue,
          recurringAmount: this.state.recurringAmount
        })
      )
    }
  }

  onPayThroughCard = async () => {
    const _this = this
    try {
      const response = await http.post('/payment/enable_recurring/', {
        payment: {
          method: 2,
          plan: 2,
          subscription: this.state.suscriptionId
        }
      })
      const options = {
        async handler(response) {
          // await http.post('/payment/verify/', {
          //   razorpay_payment_id: response.razorpay_payment_id,
          //   razorpay_signature: response.razorpay_signature,
          //   razorpay_order_id: response.razorpay_order_id
          // })
          _this.props.history.push('/business/paymentsuccess')
        },
        ...response.data
      }
      const rzp = new window.Razorpay(options)
      rzp.open()
    } catch (ex) {
      console.error(ex)
    }
  }

  render() {
    return (
      <React.Fragment>
        {/* <Header backPage="/business/payment" /> */}
        <HomeWrapper>
        <ParentDiv>
          <TextDiv>Select mode of payment for auto debit</TextDiv>
          {/* <CollapsibleDiv
            route={this.props.location}
            title={'CREDIT CARD'}
            key={1}
            idx={1}
            openDiv={this.state.clickedIdx}
            onPay={this.onPayThroughCard}
            open={this.state.clickedIdx === 1 && this.state.openedIdx === 1}
            togglePanel={idx => this.toggleOpen(idx)}
            premium={this.state.amountDue}
            discount={this.state.recurringAmount}
          />
          <DebitCollapsibleDiv
            title={'DEBIT CARD'}
            key={2}
            idx={2}
            route={this.props.location}
            openDiv={this.state.clickedIdx}
            onPay={this.onPayThroughCard}
            open={this.state.clickedIdx === 2 && this.state.openedIdx === 2}
            close={this.state.clickedIdx != 2 && this.state.openedIdx != 2}
            togglePanel={idx => this.toggleOpen(idx) && this.state.closeIdx}
            selected_idx_debit={this.state.selected_index_debit}
            onSelectDebitBank={idx => this.onSelectDebitBank(idx)}
            premium={this.state.amountDue}
            discount={this.state.recurringAmount}
          /> */}
          <NetBankingCollapsibleDiv
            title={'SELECT BANKS'}
            key={3}
            idx={3}
            route={this.props.location}
            openDiv={this.state.clickedIdx}
            onPay={values => this.props.onPay(values)}
            open={this.state.clickedIdx === 3 && this.state.openedIdx === 3}
            togglePanel={idx => this.toggleOpen(idx)}
            selected_idx_nb={this.state.selected_index_nb}
            onSelectNBBank={idx => this.onSelectNBBank(idx)}
            premium={this.state.recurringAmount}
            discount={this.state.amountDue}
            points_applied={this.state.points_applied}
            overflow={'inherit'}
          />
          {(this.state.showNetBankingDetails || this.props.location.showNetBanking) && <NetBankingDetails/>}
        </ParentDiv>

         <PaymentBreakup></PaymentBreakup>
        
        </HomeWrapper>
      </React.Fragment>
    )
  }
}

export default withRouter(SubsPayment)

const HomeWrapper = styled.div`
  width:100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  
  `

const ParentDiv = styled.div`
  display: flex;
  padding: 54px 48px;
  min-height: 1000px;
  width:65%;
  /* width:100% */
  flex-direction: column;
  /* margin: 36px 24px 0 28px; */
  background-color: #ffffff;
  box-shadow: rgb(0 0 0 / 40%) 0px 1px 4px 0px;
`
const TextDiv = styled.div`
  display: flex;
  font-family: Avenir;
  font-size: 20px;
  margin-right: 56px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
  margin-bottom: 34px;
`

const ButtonDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background-color: #436ab2;
  margin-top: 86px;
  margin-bottom: 12px;
`
const BtnText = styled.p`
  font-family: Avenir;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  text-align: center;
  color: #ffffff;
  margin: 15px;
`
