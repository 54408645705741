import React, { Component } from 'react'
import styled from 'styled-components'
import { OverlayLoader } from '../../components'
import http from '../../services/httpService'
import { Header } from '../../components/business'
import { Ptag } from '../../components/fonts'
import TagManager from 'react-gtm-module'
import { gtmCode } from '../../utils/utils'
import config from '../../config.json'
import axios from 'axios'
import download from '../../assets/images/download.png'
import upload from '../../assets/images/upload.png'
import { withRouter } from 'react-router'

class UploadExcel extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            error: '',
            supportMessage: false,
            data: {},
            uploadErrorData: []
        }
    }

    async componentDidMount() {
        const tagManagerArgs = {
            gtmId: gtmCode(),
        }
        TagManager.initialize(tagManagerArgs)

        this.setState({ loading: false })
    }


    onUpload = async (oEvent) => {
        // if (!oEvent?.target?.files[0]) {
        //     return
        // }
        let that = this
        let formData = new FormData()
        formData.append('file_details', oEvent.target.files[0])

        try {
            this.setState({ loading: true })
            let userplan_id = localStorage.getItem('userplan_id')
            if (userplan_id) {
                formData.append('userplan_id', localStorage.getItem('userplan_id'))
                await axios({
                    url: config.apiUrl + '/business/employee/',
                    method: 'POST',
                    data: formData,
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'multipart/form-data',
                    }
                })
                this.props.history.push({
                    pathname: '/business/dashboard',
                })
            }

        } catch (ex) {
            if (ex.response && Array.isArray(ex.response.data)) {
                that.setState({ uploadErrorData: ex.response && Array.isArray(ex.response.data) && ex.response.data || [], loading: false })
            }

        }

    }
    DownloadExcel = () => {
        let flow = localStorage.getItem('flow')
        let url = "https://getvitalimages.s3.ap-south-1.amazonaws.com/images/EmployeeInformationTemplate.xlsx"
        window.open(url, true)
    }

    render() {
        return (
            <Root>
                <Body>
                    <Ptag fontFamily='Proxima Nova' color='#436ab2' fontSize='24px' fontWeight='600' letterHeight='2.7' letterSpacing='0.6px'>
                        {'Upload Employees Information'}
                    </Ptag>
                    <UploadButton id="business_uploadexcel_upload_button" onClick={() => document.getElementById('fileSelect').click()} >
                        <img style={{ 'marginRight': '10px' }} src={upload} height='20px' width='17px' />
                        <input
                            name='upload'
                            id="fileSelect"
                            type="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={this.onUpload}
                        />
                        <Ptag fontSize='14px' fontWeight='600' letterSpacing='0.44px' letterHeight='19px'>
                            {'Upload file'}
                        </Ptag>
                    </UploadButton>

                    <DownloadButton id="business_uploadexcel_download_button" onClick={this.DownloadExcel} >
                        <img style={{ 'marginRight': '12px' }} src={download} height='20px' width='17px' />
                        <Ptag textAlign='center' fontSize='16px' fontWeight='600' letterSpacing='1px' letterHeight='19px'>
                            {'Download Excel Format'}
                        </Ptag>
                    </DownloadButton>
                    
                    {
                        !!this.state.uploadErrorData.length &&
                            <ErrorTag>
                                <Ptag color='#F27576' style={{paddingBottom : '12px', height : '19px', fontSize : '14px', fontWeight : '700'}}>Errors!</Ptag>
                                <div>
                                <table border='0.4px' frame='void' rules='all' style={{width : '100%'}}>
                                    <thead>
                                        <tr>
                                            <TableData><Ptag style={{height : '10px', fontWeight :'600', textAlign : 'center'}}>ROW</Ptag></TableData>
                                            <TableData><Ptag style={{height : '10px', fontWeight :'600',  textAlign : 'center'}}>COLUMN</Ptag></TableData>
                                            <TableData><Ptag style={{paddingLeft : '3px', width : '24px', height : '14px', fontWeight :'600'}}>MESSAGE</Ptag></TableData>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                    this.state.uploadErrorData.map( (error) => 
                                        <tr>
                                            <TableData><Ptag textAlign = 'center' style={{fontSize : '12px', fontWeight :'500'}}>{(error && error.Employee_no) || ''}</Ptag></TableData>
                                            <TableData><Ptag textAlign = 'center' style={{fontSize : '12px', fontWeight :'500'}}>{(error && error.column) || ''}  {'  '}</Ptag></TableData>
                                            <TableData><Ptag style={{fontSize : '12px', fontWeight :'600'}}>{(error && error.error) || ''}</Ptag></TableData>
                                        </tr>
                                    )}    
                                    </tbody>
                                </table>
                                </div>
                            </ErrorTag>
                    }
                    

                </Body>


                {this.state.loading && <OverlayLoader />}
            </Root>
        )
    }
}

const Root = styled.div`
	width: 100%;
	background-color: #ffffff;
    display: flex;
    flex-direction: column;
  
`
const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    flex: 1;
`;

const DownloadButton = styled.div`
    display: flex;
    justify-content: center;
    align-items:center;
    border: 1px solid #436AB2;
    border-radius: 10px;
    margin-top: 24px;
    margin-bottom: 24px;
    padding: 20px;
    cursor: pointer;
`

const UploadButton = styled.div`
    display: flex;
    margin-top: 24px;
    border: 1px dashed #436AB2;
    border-radius: 10px;
    padding: 48px;
    justify-content: center; 
    cursor: pointer;
    & > input{
        display: none;
    }
    
`



const ErrorMessage = styled.div`
    display: flex;
    flex-direction: column;
    margin-top:20px;
    padding: 15px 336px 16px 22px;
    border-radius: 10px;
    background-color: #f8f8f8;
    flex: 1;
    border: 1px solid #436AB2;
`
const ErrorTag = styled.div`
  padding: 24px;
  background-color: #F8F8F8;
  border-radius: 10px;
  margin-bottom: 10px;
`
const TableData = styled.td`
    padding: 10px;

`





export default withRouter(UploadExcel)