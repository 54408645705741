import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import PrivateRoute from "./components/privateRoute";
import { openRoute } from "./components/hoc";
import * as Pages from "./pages";
import {marketplacePages} from './constants/marketplaceConstant'
import {benefitsRedemptionFlowPages} from './constants/benefitRedemption'
import Routes from './constants/routes'


class App extends Component {
  
  render() {
    return (
      <React.Fragment>
        <main>
          <Switch>
            <PrivateRoute path="/1mg" component={Pages.Mg1Component} />
            <PrivateRoute
              path={`/${marketplacePages['MARKETPLACE_POINTS']}/:vital_cover_id/`}
              component={Pages.MarketPlacePointsComponent}
            />
            <PrivateRoute
              path={`/${marketplacePages['MARKETPLACE_CONTACT_INFO']}/:vital_cover_id/`}
              component={Pages.MarketPlaceContactInfo}
            />
            <PrivateRoute
              path={`/${marketplacePages['MARKETPLACE_PAYMENT']}/:vital_cover_id/`}
              component={Pages.MarketPlacePayment}
            />
            <PrivateRoute
              path={`/${marketplacePages['HEALTH_CENTRE_SELECTION']}/:vital_cover_id/:id?/`}
              component={Pages.HealthCentreSelectionComponent}
            />
            <PrivateRoute
              path="/marketplacevoucher"
              component={Pages.MarketPlaceCodeComponent}
            />
            <PrivateRoute path="/voucher" component={Pages.VoucherComponent} />
            <PrivateRoute
              path={`/${marketplacePages['WHO_IS_IT_FOR_VOUCHER']}/:vital_cover_id`}
              component={Pages.WhoIsItForVoucherComponent}
            />
            <PrivateRoute
              path={`/${marketplacePages['DATE_OF_ADMISSION_VOUCHER']}/:vital_cover_id`}
              component={Pages.DateOfAdmissionVoucherComponent}
            />
            <PrivateRoute
              path={`/${marketplacePages['SHIPPABLE_ADDRESS']}/:vital_cover_id`}
              component={Pages.ShippableComponent}
            />

            <PrivateRoute
              path="/marketplacepaymentsuccessful/:voucherID?/"
              component={Pages.MarketPlacePaymentSuccessfulComponent}
            />

            
            <PrivateRoute path="/agreement" component={Pages.EAgreementComponent} />
            <PrivateRoute
              path="/points"
              component={Pages.VitalPointsDashboardComponent}
            />

            <PrivateRoute path='/paymentoptions' component={Pages.PaymentOptionsComponent} />
            <PrivateRoute path='/paymentcycleoptions' component={Pages.PaymentCycleComponent} />
            <PrivateRoute path='/selfpayment' component={Pages.SelfPaymentComponent} />
            <PrivateRoute path="/pay" component={Pages.PaymentModeComponent} />
            <PrivateRoute path="/flex/dashboard/completion" component={Pages.FlexPaymentCompletion} />
            <PrivateRoute path="/flex/dashboard/payment" component={Pages.FlexPaymentSummary} />
            <PrivateRoute path="/flex/dashboard/onboarding/dependents/:mode" component={Pages.FlexDependentsEdit} />
            <PrivateRoute path="/flex/dashboard/onboarding/dependents" component={Pages.FlexDependentsOverview} />
            <PrivateRoute path="/flex/dashboard/onboarding" component={Pages.FlexOnboarding} />
            <PrivateRoute path="/flex/dashboard" component={Pages.FlexPreIssuanceDashboard} />
            <PrivateRoute path="/dashboard" component={Pages.DashBoardComponent} />

            <PrivateRoute path="/support" component={Pages.SupportTab} />

            <PrivateRoute exact={true} path="/benefits" component={Pages.BenefitComponent} />
            <PrivateRoute path="/benefits/offerslist/:vital_cover_id/:category" component={Pages.BenefitsOfferList} />
            <PrivateRoute 
              path={`/${benefitsRedemptionFlowPages['OFFER_DETAILS']}/:vital_cover_id/:id`} 
              component={Pages.BenefitsOfferDetails} 
            />
            <PrivateRoute
              path={`/${benefitsRedemptionFlowPages['WHO_IS_IT_FOR']}/:vital_cover_id/:id`}
              component={Pages.WhoIsItForPageComponent}
            />
            <PrivateRoute
              path={`/${benefitsRedemptionFlowPages['CONTACT_INFO']}/:vital_cover_id/:id/`}
              component={Pages.FamilyMemberContactInfoComponent}
            />
            <PrivateRoute
              path={`/${benefitsRedemptionFlowPages['DATE_OF_ADMISSION']}/:vital_cover_id/:id/`}
              component={Pages.DOABenefitAvail}
            />
            <PrivateRoute
              path={`/${benefitsRedemptionFlowPages['HEALTH_CENTRE_SELECTION']}/:vital_cover_id/:id/`}
              component={Pages.HealthCentreSelectionForBenefits}
            />
            <PrivateRoute
              path={`/${benefitsRedemptionFlowPages['SHIPPABLE_ADDRESS']}/:vital_cover_id/:id/`}
              component={Pages.BenefitsShippableAddress}
            />
            <PrivateRoute
              path={`/${benefitsRedemptionFlowPages['VOUCHER']}/:voucherID/:backPage?`}
              component={Pages.ViewRedeemedBenefit}
            />

            <PrivateRoute 
              path='/gpadetails/:vital_cover_id'
              component={Pages.GPADetails}
            />

            <PrivateRoute path="/plans" component={Pages.InsurePlanComponent} />
            <PrivateRoute path="/optionalbenefits" component={Pages.OptionalBenefitsComponent} />
            <PrivateRoute path="/wellnessplan" component={Pages.WellnessPlan} />

            <PrivateRoute
              path="/secure_member"
              component={Pages.SecureMemberComponent}
            />
            <PrivateRoute
              path="/family_member"
              component={Pages.FamilyMembersComponent}
            />
            <PrivateRoute
              path="/workexperience"
              component={Pages.WorkExperienceComponent}
            />
            <PrivateRoute
              path="/work_company"
              component={Pages.WorkingCompanyComponent}
            />
            <PrivateRoute path="/issalaried" component={Pages.IsSalariedComponent} />
            <PrivateRoute path="/home_type" component={Pages.HomeTypeComponent} />
            <PrivateRoute path="/salary" component={Pages.SalaryMonthlyComponent} />
            <PrivateRoute path="/pan" component={Pages.PanNoComponent} />
            <PrivateRoute
              path="/isinsure"
              component={Pages.isHealthInsuranceComponent}
            />
            <PrivateRoute path="/expense" component={Pages.AnnualExpenseComponent} />
            <PrivateRoute path="/pin" component={Pages.PincodeComponent} />
            <PrivateRoute path="/e_mandate" component={Pages.EMandateComponent} />
            <PrivateRoute path="/kyc1" component={Pages.Kyc1Component} />
            <PrivateRoute path="/kyc2" component={Pages.Kyc2Component} />
            <PrivateRoute path="/selfie" component={Pages.SelfieComponent} />
            <PrivateRoute
              path="/qualification"
              component={Pages.QualificationComponent}
            />
            <PrivateRoute
              path="/personaladdress"
              component={Pages.PersonalAddressComponent}
            />
            <PrivateRoute path="/address" component={Pages.AddressComponent} />
            <PrivateRoute
              path="/insureaddress"
              component={Pages.InsureAddressComponent}
            />
            <PrivateRoute path="/family" component={Pages.FamilyComponent} />
            <PrivateRoute
              path="/familycorporate"
              component={Pages.FamilyCorporateComponent}
            />
            <PrivateRoute path="/nachgen" component={Pages.NachGenComponent} />
            <PrivateRoute
              path="/hospitalspage"
              component={Pages.HospitalsPageComponent}
            />
            <PrivateRoute
              path="/treatmenttypepage"
              component={Pages.TreatmentTypePageComponent}
            />
            <PrivateRoute
              path="/dateofadmission"
              component={Pages.DateOfAdmissionPageComponent}
            />
            <PrivateRoute
              path="/hospitaloffer"
              component={Pages.HospitalOfferPageComponent}
            />
            <PrivateRoute
              path="/makepayment"
              component={Pages.MakePaymentComponent}
            />
            <PrivateRoute
              path="/initialdiagnosis"
              component={Pages.InitialDiagnosisPageComponent}
            />
            <PrivateRoute
              path="/claimdetails"
              component={Pages.ClaimDetailsPageComponent}
            />
            <PrivateRoute
              path="/paymentdetails"
              component={Pages.PaymentDetailsComponent}
            />
            <PrivateRoute
              path="/paymentgateway"
              component={Pages.PaymentGatewayComponent}
            />
            <PrivateRoute
              path="/applyvitalcredit"
              component={Pages.ApplyVitalCreditComponent}
            />
            <PrivateRoute
              path="/viewreceipt"
              component={Pages.ViewReceiptComponent}
            />
            {/* <PrivateRoute path="/uploadbillpage" component={Pages.UploadBillPageComponent} /> */}
            <PrivateRoute
              path="/uploadbillpage"
              component={Pages.UploadBillPageComponent}
            />
            <PrivateRoute
              path="/authorizingpaymentpage"
              component={Pages.AuthorizingPaymentComponent}
            />
            <PrivateRoute
              path="/uploadsuccessfulpage"
              component={Pages.UploadSuccessfulComponent}
            />
            <PrivateRoute
              path="/paymenttab"
              component={Pages.PaymentTabPageComponent}
            />
            <PrivateRoute
              path="/eventhistory"
              component={Pages.EventHistoryComponent}
            />
            <PrivateRoute 
              path={`${Routes['HEALTH']}/:offerType?`} 
              component={Pages.HealthProfileComponent} 
            />
            <PrivateRoute 
              path={`${Routes['PROFILE']}`} 
              component={Pages.ProfileComponent} 
            />
            <PrivateRoute
              path="/pharmacyoffers"
              component={Pages.PharmacyOffersComponent}
            />
            <PrivateRoute path="/howitworks" component={Pages.HowItWorksComponent} />
            <PrivateRoute
              path="/policydetails"
              component={Pages.PolicyDetailsComponent}
            />
            <PrivateRoute
              path="/policydetailsdescription"
              component={Pages.PolicyDetailsDescriptionComponent}
            />
            <PrivateRoute
              path="/insurancesubscriptions"
              component={Pages.InsuranceSubscriptionsComponent}
            />
            <PrivateRoute
              path="/subscriptionpayment"
              component={Pages.SubscriptionPaymentComponent}
            />
            <PrivateRoute
              path="/cashlessclaimprocess"
              component={Pages.CashlessClaimProcessComponent}
            />
            <PrivateRoute
              path="/moderationprocess"
              component={Pages.ModerationProcessComponent}
            />
            <PrivateRoute
              path="/claimreimbursementprocess"
              component={Pages.ClaimReimbursementProcessComponent}
            />
            <PrivateRoute
              path="/outofpocketusage"
              component={Pages.OutOfPocketUsageComponent}
            />
            <PrivateRoute
              path="/eventtypepolicy"
              component={Pages.EventTypePolicyPageComponent}
            />
            <PrivateRoute
              path="/treatmenttypepolicy"
              component={Pages.TreatmentTypePolicyPageComponent}
            />
            <PrivateRoute
              path="/whoisitforpolicy"
              component={Pages.WhoIsItForPolicyPageComponent}
            />
            <PrivateRoute
              path="/dateofadmissionpolicy"
              component={Pages.DateOfAdmissionPolicyComponent}
            />
            <PrivateRoute
              path="/paymentdetailspolicy"
              component={Pages.PaymentDetailsPolicyComponent}
            />
            <PrivateRoute
              path="/uploadbillpolicy"
              component={Pages.UploadBillPolicyPageComponent}
            />
            <PrivateRoute
              path="/editpincode"
              component={Pages.EditPincodeComponent}
            />
            <PrivateRoute
              path="/editfamily"
              component={Pages.EditFamilyMemberComponent}
            />
            <PrivateRoute
              path="/editfamilydetails"
              component={Pages.EditFamilyDetailsComponent}
            />
            <PrivateRoute
              path="/editannualexpense"
              component={Pages.EditAnnualExpenseComponent}
            />
            <PrivateRoute
              path="/editmonthlyincome"
              component={Pages.EditMonthlySalaryComponent}
            />
            <Route
              path="/cashlessclaim"
              component={Pages.CashlessClaimComponent}
            />
            <PrivateRoute
              path="/paymentsuccessful"
              component={Pages.PaymentSuccessfulComponent}
            />
            <PrivateRoute
              path="/paymentsuccess/:payment_type?"
              component={Pages.PaymentSuccessComponent}
            />
            <PrivateRoute
              path="/insurancedeclaration"
              component={Pages.InsuranceDeclarationComponent}
            />
            <PrivateRoute
              path="/accidentalplans"
              component={Pages.AccidentalPlansComponent}
            />
            <PrivateRoute
              path="/editfamilymembers/:back?"
              component={Pages.EditFamilyMembersComponent}
            />
            <PrivateRoute
              path="/addfamilymembers"
              component={Pages.AddFamillyMembersComponent}
            />
            <PrivateRoute path="/nb_banks" component={Pages.NBBanksComponent} />
            <PrivateRoute path="/nb_details" component={Pages.NBDetailsComponent} />
            <PrivateRoute
              path="/drawdownrequest"
              component={Pages.DrawDownRequestComponent}
            />
            <PrivateRoute
              path="/sharereferral"
              component={Pages.ShareReferralComponent}
            />
            
            <PrivateRoute path="/myoffers" component={Pages.MyOffersComponent} />
            <PrivateRoute path="/myorders" component={Pages.Tab2Component} />
            <PrivateRoute path="/scheduledtests" component={Pages.ScheduledTest}/>
            <PrivateRoute
              path="/verifymembers"
              component={Pages.VerifyMembersComponent}
            />
            <PrivateRoute
              path="/yearlypayment"
              component={Pages.YearlyPayment}
            />
            <PrivateRoute
              path="/verifyaddress"
              component={Pages.VerifyAddressComponent}
            />

            <PrivateRoute 
              path='/healthquestions/:id/:type?'
              component={Pages.HealthQuestionnaire}
            />
            <PrivateRoute
              path='/subscriptionpaymentoptions'
              component={Pages.SubscriptionPaymentOption}
            />

            <PrivateRoute
              path='/memberDetail/:id'
              component={Pages.IndividualMemberDetailsPage}
            />

            <PrivateRoute
              path='/addNewMember/:relation'
              component={Pages.AddNewMemberPage}
            />

            <PrivateRoute
              path='/subscriptionselfpay'
              component={Pages.SubscriptionSelfPayment}
            />

            <PrivateRoute
              path='/pointsledger'
              component={Pages.PointsLedger}
            />
             
             <PrivateRoute
              path='/visitorphone'
              component={Pages.VisitorPhonePage}
            />

            <PrivateRoute
              path="/onboarding" 
              component={Pages.OnBoardingDisclaimerComponent}
            />

            <PrivateRoute
              path="/familymembershealth"
              component={Pages.FamilyMembersHealthChecked}
            />

            {/* Policy Doc iske niche daalein */}
            
            <Route
              path="/ghi-claim-process"
              component={Pages.GhiClaimProcess}
            />
            <Route
              path="/gpa-claim-process"
              component={Pages.GpaClaimProcess}
            />
            <Route
              path="/ghi-claim-process"
              component={Pages.GhiClaimProcess}
            />
            <Route
              path="/gpa-claim-process"
              component={Pages.GpaClaimProcess}
            />
            <Route
              path="/accidentalpolicydoc"
              component={Pages.AccidentalPolicyDocumentComponent}
            />
            <Route path="/policydocument" component={Pages.PolicyDocumentComponent} />

            <Route
              path="/cardiacDocument"
              component={Pages.CardiacPolicyDocumentComponent}
            />
            <Route path="/diabeticDocument" component={Pages.DiabeticDocComponent} />
            <Route path="/pedDocument" component={Pages.PEDDocComponent} />
            <Route path="/zypp" component={Pages.ZyppPolicyDoc} />
            <Route
              path="/falconpolicydocument"
              component={Pages.FalconPolicyDocumentComponent}
            />
            <Route
              path="/vitalpolicydoc"
              component={Pages.VitalPolicyDocumentComponent}
            />
            
            <Route
              path="/sbopolicydoc"
              component={Pages.SBOPolicyDoc}
            />
              <Route
              path="/sbomaternitypolicydocuments"
              component={Pages.SBOMaternityPolicyDocuments}
            />

            <Route path="/coveragedoc/:id" component={Pages.CoverageDoc} />

            <Route path="/claimprocess/:id" component={Pages.ClaimProcess} />
            <Route path="/faq" component={Pages.FaqComponent} />
            <Route path="/otp" component={openRoute(Pages.OtpComponent)} />
            <Route path="/password" component={openRoute(Pages.PasswordComponent)} />
            <Route path="/about" component={openRoute(Pages.AboutYouComponent)} />
            


            <Route path="/signup" component={openRoute(Pages.SignUpComponent)} />
            <Route path='/visitor' component={Pages.VitalLandingPage}/>
            <Route path="/login/member-id" component={openRoute(Pages.LoginMemberId)} />
            <Route path="/login/phone" component={openRoute(Pages.LoginNumber)} />
            <Route path="/login" component={openRoute(Pages.LoginComponent)} />
            {/* <Route
              path="/vitalpoints"
              component={Pages.VitalPointsLandingPageComponent}
            /> */}
            <Route
              path="/privacypolicydoc"
              component={Pages.PrivacyPolicyDocumentComponent}
            />

            {/* <Route
              path="/vitalinsurance"
              component={Pages.InsuranceLandingPageComponent}
            /> */}
            <Route
              path='/campaign/phone'
              component={Pages.CampaignPhonePage}
            />
            <Route path="/credit" component={Pages.CreditLandingPageComponent} />
            
            <PrivateRoute
              path="/marketplaceoffers"
              component={Pages.MarketPlaceOffersComponent}
            />

            <PrivateRoute
              path="/makepaymentemi"
              component={Pages.MakePaymentComponentEmi}
            />

            <PrivateRoute
              path="/wellnessplan"
              component={Pages.WellnessPlan}
            />
            
            <Route path="/agent" component={Pages.AgentRoutes}/>
            <Route path='/redirect' component={Pages.RedirectPage}/>
            <Route path='/internal' component={Pages.InternalRoutes}/>
            <Route path="/business" component={Pages.BusinessRoutes} />
            {/* <Route path="/aboutus" component={Pages.AboutUsLandingPageComponent} /> */}
            <Route path="/" component={openRoute(Pages.LoginComponent)} />
            
          </Switch>
        </main>
      </React.Fragment>
    );
  }
}

export default App;

