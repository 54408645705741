import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import { Field } from 'formik'
import { RelationShipOptions } from '../constants/relationShip'
import { HealthTags, DateField, Toggle, Icons, HealthQuestions } from '../components'
import RenderDropDown from './renderDropDown'
import RenderInput from './renderInput'
import { customValidate, validateAlphaOnly } from '../utils/utils.js'
import moment from 'moment'

const Root = styled.div`
  display: ${props => (props.showForm === true ? 'flex' : 'none')};
  flex-direction: column;
`
const FormContainer = styled.div`
  padding: 0 2.4rem 1.2rem 2.4rem;
`

const SelectWrapper = styled.div`
  width: 100%;
`
const TickWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.4rem;
`

const BinWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-top: 1px solid rgba(153, 163, 187, 0.12);
`

const FieldWrapper = styled.div`
  display: flex;

  & > :nth-child(2) {
    margin-left: 2rem;
  }
`

const CalendarField = styled.div`
  width: 100%;
`

const Font14 = styled.p`
  font-size: 1.4rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.3px;
  color: #4f4f4f;
`

const AgeInformation = styled.p`
  min-height: 20px;
  opacity: 0.73;
  font-family: Avenir;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  letter-spacing: 0.71px;
  text-align: left;
  color: #4a4a4a;
`

const RemoveButton = styled.p`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.5px;
  color: #f27576;
`

const HealthTagWrapper = styled.div`
  padding: 0 24px 24px 24px;
`

export default class FamilyFormOnboarding extends Component {
  state = { age: '', ageRestriction: '' }

  // componentDidMount = async () => {
  //     const dob = this.props.formikProps.initialValues.family[this.props.index]['dob']
  //     this.validateFullDate(dob)
  // }

  handleError = (errors, touched, index, field) => {
    if (
      errors &&
      errors.family &&
      errors.family[index] &&
      errors.family[index][field] &&
      touched &&
      touched.family &&
      touched.family[index] &&
      touched.family[index][field]
    ) {
      return errors.family[index][field]
    }
  }

  renderDropDown = (index, field, placeholder, option) => {
    const { e_category } = this.props
    const { values, setFieldTouched, setFieldValue, errors, touched } = this.props.formikProps
    const CurrValue = values.family[index][field]
    const setValue = `family.${index}.${field}`
    const CurrError = this.handleError(errors, touched, index, field)

    return (
      <RenderDropDown
        name={`family.${index}.${field}`}
        validate={customValidate}
        placeholder={placeholder}
        option={option}
        value={CurrValue}
        error={CurrError}
        onChange={e => {
          setFieldValue(setValue, e.value)
        }}
        onBlur={e => setFieldTouched(setValue, true)}
        e_label={field + '_' + index}
        e_category={e_category}
      />
    )
  }

  renderInput = (index, field, placeholder, validate, type = 'text') => {
    const { e_category } = this.props
    const { values, errors, touched } = this.props.formikProps
    const CurrValue = values.family[index][field]
    const CurrError = this.handleError(errors, touched, index, field)
    return (
      <RenderInput
        name={`family.${index}.${field}`}
        type={type}
        validate={validate}
        // validate={field !== 'dob' ? this.customValidate : this.validateDate}
        placeholder={placeholder}
        autoFocus={this.props.pageType !== 'InsuranceDeclaration' && field === 'first_name' && index === 0}
        value={CurrValue}
        error={CurrError}
        e_label={field + '_' + index}
        disabled={this.props.locked}
        e_category={e_category}
      />
    )
  }

  validateFullDate = DateParam => {
    if (!DateParam) return
    let date = DateParam.split('/')
    const day = date[0]
    const month = date[1]
    const year = date[2]

    //Month out of range
    if (!(Number(month) >= 1 && Number(month) <= 12)) return false

    const flag31 =
      (month === '01' ||
        month === '03' ||
        month === '05' ||
        month === '07' ||
        month === '08' ||
        month === '10' ||
        month === '12') &&
      !(Number(day) < 32 && Number(day) > 0)

    const flag28 = month === '02' && !(Number(day) > 0 && Number(day) < 29)
    const flag30 =
      (month === '04' || month === '06' || month === '09' || month === '11') && !(Number(day) > 0 && Number(day) < 31)

    const currYear = new Date().getFullYear()
    const minYear = currYear - 200
    const currMonth = new Date().getMonth()
    const monthDiff = currMonth - month + 1
    let age

    if (monthDiff === 0) age = currYear - year
    else if (monthDiff > 0) age = currYear - year
    else age = currYear - year - 1

    this.setState({ age })

    const flagYear = year >= minYear && year <= currYear
    if (flag31 || flag28 || flag30 || !flagYear) {
      this.setState({ ageRestriction: ' ' })
      return false
    } else {
      if (
        ((this.props.relationship && this.props.relationship.toLowerCase() === 'son') ||
          (this.props.relationship && this.props.relationship.toLowerCase() === 'daughter')) &&
        age > 24
      )
        this.setState({ ageRestriction: 'Will be covered in a separate Vital Insurance Plan.' })
      else if (age > 69) this.setState({ ageRestriction: 'Vital Insurance covers up to the age of 69 years.' })
      else this.setState({ ageRestriction: '' })
      return true
    }
  }

  validateDOB = value => {
    let error
    if (value === '') 
      error = '*Required'
    else if (value.length !== 10)
      return 'Date is invalid'
    else if (value.length === 10) {
      const age = moment().diff(moment(value.split('/').reverse().join(), 'YYYYMMDD'), 'years')
      let daysInDiff = moment().diff(moment(value.split('/').reverse().join(), 'YYYYMMDD'), 'days')
      if (daysInDiff <= 0 || isNaN(age) || age < 0 || age > 120) 
        error = 'Date is invalid'
    }
    return error
  }

  handleChange = (e, index) => {
    const value = e && e.target && e.target.value
    const { values, setFieldValue, errors, touched, setFieldTouched } = this.props.formikProps
    let prevDate = values.family[index]['dob']
    let newDate = value
    let prevDateLen = prevDate.length
    let newDateLen = newDate.length
    if (!touched && !touched.family[index]['dob']) {
      setFieldTouched(`family.${index}.dob`, true)
    }

    if (newDateLen < prevDateLen) {
      setFieldValue(`family.${index}.dob`, prevDate.substr(0, prevDateLen - 1))
      this.setState({ age: '' })
    } else if (newDateLen <= 10) {
      if (newDateLen === 2 || newDateLen === 5) {
        setFieldValue(`family.${index}.dob`, newDate + '/')
      } else {
        setFieldValue(`family.${index}.dob`, newDate)
      }
      this.setState({ age: '' })
    }
  }

  renderDob = (index, field, placeholder) => {
    const { e_category } = this.props
    const { values, setFieldValue, errors, touched, setFieldTouched } = this.props.formikProps
    const CurrValue = values.family[index][field]
    const CurrError = this.handleError(errors, touched, index, field)
    return (
      <CalendarField>
        <Field
          name={`family.${index}.${field}`}
          validate={this.validateDOB}
          render={({ field }) => (
            <DateField
              {...field}
              disabled={this.props.locked}
              placeholder={placeholder}
              value={CurrValue}
              age={!!CurrValue && this.state.age}
              error={CurrError}
              onChange={key => this.handleChange(key, index)}
              onBlur={e => setFieldTouched(`family.${index}.dob`, true)}
              e_category={e_category}
              e_label={field + '_' + index}
            />
          )}
        />
        <AgeInformation>
          {this.state.age && !!CurrValue && this.state.ageRestriction ? this.state.ageRestriction : ' '}
        </AgeInformation>
      </CalendarField>
    )
  }

  renderRadio = (index, field) => {
    return (
      <Field
        name={`family.${index}.${field}`}
        render={({ field }) => (
          <Toggle
            locked={this.props.lockHealthQuestions}
            active={this.props.Hissue}
            e_category={this.props.e_category}
            e_label={'health_issue_checkbox' + index}
            toggleClick={e => this.props.onClickCheckBox(index)}
          />
        )}
      />
    )
  }

  render() {
    const {
      showForm,
      index,
      openQusn,
      questions,
      onhandleCardData,
      questionResponses,
      onCloseQusnCard,
      relationship,
      onRemoveMember,
      hideOthers,
      lockHealthQuestions,
      locked
    } = this.props
    if (showForm)
      return (
        <Fragment>
          <FormContainer>
            <SelectWrapper>
              {/* {this.renderDropDown(index, 'relationship', 'Relationship', RelationShipOptions)} */}
            </SelectWrapper>
            <FieldWrapper>
              {this.renderInput(index, 'first_name', 'First Name', validateAlphaOnly)}
              {this.renderInput(index, 'last_name', 'Last Name', validateAlphaOnly)}
            </FieldWrapper>
            {this.renderDob(index, 'dob', 'DOB (DD/MM/YYYY)')}

            <HealthQuestions
              locked={lockHealthQuestions}
              questionResponses={questionResponses}
              hideOthers={hideOthers}
              openQusn={openQusn}
              cardId={index}
              questions={questions}
              onChange={onhandleCardData}
              onClose={onCloseQusnCard}
            />
          </FormContainer>
          <TickWrapper>
            <Font14>Any Known Health Issues</Font14>
            {this.renderRadio(index, 'health_issues')}
          </TickWrapper>
          <HealthTagWrapper>
            <HealthTags
              locked={lockHealthQuestions}
              index={index}
              questions={questions}
              responses={questionResponses}
              onChange={onhandleCardData}
              openQuestion={onCloseQusnCard}
            />
          </HealthTagWrapper>

          {!locked && relationship !== 'SELF' && onRemoveMember && (
            <BinWrapper onClick={() => onRemoveMember(this.props.formikProps, index)}>
              <RemoveButton>REMOVE</RemoveButton>
            </BinWrapper>
          )}
        </Fragment>
      )
    return null
  }
}
