import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import auth from '../../services/authService'
import http from "../../services/httpService"
import { getAllUrlParams } from '../../utils/utils';

const URLParams = getAllUrlParams(window.location.href, true);
const token = URLParams['token'];
const employeeId = URLParams['employee_id'];
const dependentType = URLParams['dependent_type']
const clear = URLParams['clear']
console.log({dependentType})

const BusinessPrivateRoute = ({ path, component: Component, render, ...rest }) => {
  const [shouldRender, setShouldRender] = useState(false);
  useEffect(() => {
    (async function () {
      if (path.includes("/employeeform")) {
        if(clear) sessionStorage.clear();
        sessionStorage.setItem('dependentType', dependentType);
        if (token && employeeId) {
          sessionStorage.setItem('token', token);
          localStorage.setItem('token', token);
          const isExpired = auth.isTokenExpired();
          console.log({isExpired})
          if(isExpired) {
            const newToken = await auth.renewToken(token);
            sessionStorage.setItem('token', newToken);
          }
          const { data } = await http.get("/users/get_user_data/")
          localStorage.setItem('businessAccount', "true");
          sessionStorage.setItem('employeeId', employeeId);
          sessionStorage.setItem('token', data.token);
          window.history.replaceState(null, null, window.location.pathname);
        }
      }
      setShouldRender(true);
    })();
  }, [])

  return (
    <>
      {shouldRender && <Route
        {...rest}
        render={props => {
          if (!auth.getCurrentUser() || !localStorage.getItem('businessAccount'))
            return (
              <Redirect
                to={{
                  pathname: '/business',
                  state: { from: props.location }
                }}
              />
            )
          return Component ? <Component {...props} {...(employeeId ? { employeeId: employeeId } : {})} /> : render(props)
        }}
      />}
    </>
  )
}

export default BusinessPrivateRoute