import React from 'react'
import styled from 'styled-components'
import debit_bank_names from '../../../components/memberApp/constants/DebitBankConstants.js'
import NBPaymentScrollDiv from '../../../components/memberApp/payment/NBPaymentScrollDiv'
import { CardMode, PaymentMethod, SubscriptionPlan } from '../constants/Payment'
import LoaderDots from '../../../components/loaderDots'
import { PageView, initGA, Event } from '../../../Tracking'

export default class DebitCardContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      bank: '',
      bank_name: '',
      debit_bank_branches: [],
      selected_idx_debit: '',
      isLoading: false,
      plan: -1,
      is_bank_selected: false,
      previous_selected_bank_name: '',
      loader: false
    }
  }

  onSelectBank = async idx => {
    // save current selected bank
    // bank selected
    //  if prev = others and current == others || vice versa
    //      show loader for 1 sec then hide => set loader state = true
    //   show text depending on the bank selected
    let p = this.state.current_selected_bank_name
    var x = debit_bank_names
    await this.setState({
      bank_name: x && x[idx].bank_name,
      is_bank_selected: true,
      previous_selected_bank_name: p,
      current_selected_bank_name: x && x[idx].bank_name
    })

    if (
      (this.state.previous_selected_bank_name !== 'Others' && this.state.current_selected_bank_name === 'Others') ||
      (this.state.previous_selected_bank_name === 'Others' && this.state.current_selected_bank_name !== 'Others')
    ) {
      await this.setState({ loader: true })
      setTimeout(async () => {
        await this.setState({
          loader: false
        })
      }, 1000)
    }
  }

  chose_bank_recurring() {
    // window.gtag('event', 'click', {
    //   event_category: 'recurring',
    //   event_label: 'paySecurely'
    // })
    Event('recurring', 'paysecurely', 'paysecurely Button')
    this.props.onPay({
      method: PaymentMethod.CARD,
      plan: SubscriptionPlan.RECURRING_WITH_CARD,
      payment_mode: CardMode.DEBIT_CARD,
      bank_name: this.state.bank_name
    })
  }

  chose_bank_normal() {
    // window.gtag('event', 'click', {
    //   event_category: 'recurring',
    //   event_label: 'paySecurely'
    // })
    Event('recurring', 'paySecurely', 'paySecurely Button')
  
    this.setState({ plan: 0 })
    this.props.onPay({
      method: PaymentMethod.CARD,
      plan: SubscriptionPlan.NORMAL,
      payment_mode: CardMode.DEBIT_CARD,
      bank_name: this.state.bank_name
    })
  }
  componentDidMount() {}

  render() {
    return (
      <ContentDiv>
        <Text3>Available Banks</Text3>
        <ScrollWrapper>
          <NBPaymentScrollDiv
            bank_names={this.props.route || this.props.recurring ? debit_bank_names.slice(0, debit_bank_names.length - 1) : debit_bank_names}
            selected_idx={this.props.selected_idx_debit}
            onSelectBank={idx => {
              this.onSelectBank(idx)
              
              this.props.onSelectDebitBank(idx)
            }}
          />
        </ScrollWrapper>
        {this.state.is_bank_selected && (
          <React.Fragment>
            
              <React.Fragment>
               
                <Button
                  id='debit_card_pay_securely'
                  onClick={() =>
                    this.state.bank_name !== 'Others' ? this.chose_bank_recurring() : this.chose_bank_normal()
                  }>
                  <Text>PAY SECURELY</Text>
                </Button>
              </React.Fragment>
          
          </React.Fragment>
        )}
      </ContentDiv>
    )
  }
}

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-top: solid 1px #9b9b9b;
`

const Text = styled.p`
  font-family: Avenir;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 1px;
  margin-left: 18px;
`

const Text2 = styled.p`
  font-family: Avenir;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: 0.38px;
  text-align: left;
  color: #707070;
  padding-left: 18px;
  padding-right: 18px;
`
const Text3 = styled.p`
  font-family: Avenir;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: 0.15px;
  text-align: left;
  color: #4a4a4a;
  margin-top: 10px;
  margin-bottom: 11px;
  margin-left: 18px;
`
const Button = styled.div`
  display: flex;
  text-align: center;
  margin: 0px 18px 24px 18px;
  align-items: center;
  justify-content: center;
  background-color: #f27576;
  color: #ffffff;
  height: 54px;
  border-radius: 15px;
  background-color: #436ab2;
`
const LineDiv = styled.div`
  display: flex;
  margin-top: 14px;
  height: 1px;
  border-radius: 7px;
  margin: 0px 20px 24px 20px;
`
const ScrollWrapper = styled.div`
  display: flex;
  padding-left: 11px;
  padding-right: 11px;
`
const busy_Indicator = styled.div``

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`
