import React, { Component } from "react";
import styled from "styled-components";
import {Ptag} from './fonts'


const CoronaBanner = ({...props}) => {

    return (<></>)
    // return(
    //     <CoronaBannerRoot>
    //         <CoronaBannerText>
    //         <Ptag color='#fff' fontSize='14px' fontWeight='bold' letterSpacing='0.44px' letterHeight='2.14'>
    //             {'Be fearless'}
    //         </Ptag>
    //         <Ptag fontSize='14px'  color='#fff'>
    //             {'We\'ve got you covered for '}
    //             <Strong1>{'CoVID-19'}</Strong1> 
    //             {' in our health insurance plans'}
    //         </Ptag>
    //         </CoronaBannerText>
    //         <CoronaImage height='72px' width='50px' src='https://vitalimages.s3.ap-south-1.amazonaws.com/dashboard/coronoImage.png'/>
    //     </CoronaBannerRoot>
    // )
}

export default CoronaBanner

const CoronaBannerRoot = styled.div`
display: flex;
margin-bottom: 18px;
justify-content: space-between;
border-radius: 10px;
background-color: #F6B956;
`;

const CoronaBannerText = styled.div`
display: flex;
flex-direction: column;
padding: 18px;
`;


const Strong1 = styled.strong`
font-weight:600;
letter-spacing: 0.6px;
`
;
const CoronaImage = styled.img`
margin : 13px 28px 0 8px;
align-self: flex-end;
`;
