import styled from "styled-components";

const HomeWrapper = styled.div`
  transition: margin-left 0.2s ease-in;
  padding: 30px 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: white;
  /* margin-bottom: 12px; */
`;
const CardTag = styled.div`
  padding-top: 30px;
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
  gap: 24px;
`

const Root = styled.div`
	width: 100%;
	background-color: #ffffff;
  display: flex;
  flex-direction: column;
 
`

const MessageWrapper = styled.div`
  transition: margin-left 0.2s ease-in;
  padding: 50px 80px;
  padding-bottom: 174px;
  box-sizing: border-box;
  background: #fff;
  height: 58vh;
  text-align: center;
  /* margin-left: 200px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const MsgTag = styled.div`
  justify-content: center;
`;
const Text2 = styled.p`
  font-weight: 400;
  font-size: 18px;
  color: #3b3b3b;
`;

const TopHeader = styled.div`
  min-width: 100px;
  display: flex;
  flex-direction: column;
  padding-top: 22px;
  padding-bottom: 22px;
  justify-content: center;
`;
const EmployeeHeader = styled.div`
  max-width: 200px;
  display: flex;
  flex-direction: column;
  padding-top: 22px;
  padding-bottom: 22px;
  justify-content: center;
`;
const OuterHeader = styled.div`
  min-width: 600px;
`;

const Table = styled.table`
  display: block;
  /* border-bottom: 1px solid #828080; */
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 25px;
  /* border-collapse: collapse; */
`;

const LedgerRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  margin-top: 20px;
  /* margin-left: 30px; */
`;

const LedgerButtomRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const LedgerRowOuterTag = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;

const TransactionDiv = styled.div`
  border-bottom: 1px solid #436ab2;
  /* padding-top: 10px; */
  /* margin-left: 5%;
    margin-bottom: 25px; */
  /* width: 100%; */
`;

const BoxTop = styled.div`
  padding-left: 10px;
  padding-bottom: 10px;
  padding-top: 12px;
  background-color: #f9fcff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;
const BackButton = styled.button`
  padding-top: 15px;
  margin-left: 20px;
  cursor: pointer;
  width: 12%;
  padding-left: 10px;
  background-color: white;
  border: 0px;
  text-align: center;
  display: flex;
  flex-direction: row;
`;

const BoxButton2 = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  border: 0px;
  background-color: white;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  padding-right: 16px;
  border-radius: 6px;
`;

const SummaryBox = styled.div`
  border: 1px solid #436ab2;
  border-radius: 5px;
  width: 40%;
  min-width: 514px;
  /* min-height: 220px; */
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 311px;
  align-items: flex-end;
  margin-top: 60px;
`;
const ImgHeader = styled.div`
  padding-bottom: 10px;
  text-align: top;
  border: 0px;
`;

const LongLine = styled.div`
  border-bottom: 1px solid #828080;
  padding-top: 10px;
`;

export {
  LongLine,
  ImgHeader,
  ButtonWrapper,
  SummaryBox,
  BoxButton2,
  BackButton,
  HomeWrapper,
  MessageWrapper,
  Text2,
  TopHeader,
  MsgTag,
  Table,
  EmployeeHeader,
  OuterHeader,
  LedgerRow,
  BoxTop,
  LedgerButtomRow,
  LedgerRowOuterTag,
  TransactionDiv,
  CardTag,
  Root
};
