import React, { Component } from 'react'
import styled from 'styled-components'
import safetycross from '../../assets/images/safetycross.png'
import badge from '../../assets/images/badge.png'
import support from '../../assets/images/support.png'
import rewardsImage from '../../assets/images/rewards.png'
import vitalcoin from '../../assets/images/vital-coin.png'
import formatAmount from '../../components/memberApp/common/utils'
import {Ptag} from '../../components/fonts'
import arrow from '../../assets/images/blueArrow.png'
import { withRouter } from "react-router";

const PlanOverViewCards = ({subscriptionFee, noOfLives, rewards, sumInsured, deductible, showMarketPlaceLink, showPolicyDocument, hideHeader,...props }) => {
    const onClickViewPolicy = () => {
        window.scrollTo({
            top: 0,
            left: 0,
          })
          localStorage.setItem('navigatingFrom','dashboard')
        props.history.push('/business/policydoc')
    }
    return(
        <Root>
            {!hideHeader &&
                <Subscription>
                    <SubsFee>
                        <Ptag style={{'marginBottom': '4px'}} fontSize='10px' textTransform='uppercase' fontWeight='600' letterSpacing='1px'>monthly Subscription Fees</Ptag>
                        <FeeRupee>{`₹${formatAmount(subscriptionFee)}`}</FeeRupee>
                    </SubsFee>
                    <NumberLives>
                        <Ptag style={{'marginBottom': '4px'}} fontSize='10px' textTransform='uppercase' fontWeight='600' letterSpacing='1px'>number of lives</Ptag>
                        <Lives>{noOfLives}</Lives>
                    </NumberLives>
                </Subscription>
            }
            

            <AccessHealthMarketplace>
                <img style={{'margin': '5px 12px 0 0'}} src={rewardsImage} height='24px' width='18px'/>
                <MarketPlaceContent>
                    <AccessHlth>
                        <Acsrwrd>Access to Health Marketplace</Acsrwrd>
                    </AccessHlth>
                    <AccessHlth>
                    
                        <Acsemploy>Exclusive packages and offers from leading providers</Acsemploy>
                    </AccessHlth>
                    {
                        showMarketPlaceLink && 
                        <NavigationLinks id='plan_overview_card_view_marketplace' onClick={() => props.history.push('/business/marketplace')}>
                            <Ptag color='#4a90e2' fontWeight='600' letterSpacing='0.38px'>
                                {'View Marketplace'}
                            </Ptag>
                            <img src={arrow} height='24px' width='24px'/>
                        </NavigationLinks>
                    }
                </MarketPlaceContent>
                
            </AccessHealthMarketplace>

            <WellnessReward>
                <img style={{'margin': '5px 8px 0 0'}} src={badge} height='24px' width='24px'/>
                <div>
                    <Well>
                        <Wellrwrd>Wellness Rewards</Wellrwrd>
                    </Well>
                    <Well>
                        <img src={vitalcoin} width='16px' height='16px'/>
                        <Peremploy>{`${rewards} per employee per month`}</Peremploy>
                    </Well>
                </div>
            </WellnessReward>
            
            {sumInsured &&
                <HealthInsurance>
                    <img style={{'margin': '5px 12px 0 0'}} src={safetycross} height='24px' width='20px'/>
                    <div style={{'width':'100%'}}>
                        <HealthIns>
                            <Insrwrd>Health Insurance</Insrwrd>
                        </HealthIns>
                            <Insemploy>
                                <InsSum>
                                Sum Insured
                                <Num>{`₹${formatAmount(sumInsured)}`}</Num>
                                </InsSum>
                                <InsDed>
                                Deductible
                                <Num>{`₹${formatAmount(deductible)}`}</Num>
                                </InsDed>
                            </Insemploy>
                            {
                                showPolicyDocument &&
                                <NavigationLinks style={{'marginTop': '10px'}} onClick={onClickViewPolicy}>
                                    <Ptag color='#4a90e2' fontWeight='600' letterSpacing='0.38px'>
                                        {'View Policy Document'}
                                    </Ptag>
                                    <img src={arrow} height='24px' width='24px'/>
                                </NavigationLinks>
                            }
                    </div>
                
                </HealthInsurance>
            }
            <DedicatedCareManager>
                <img style={{'margin': '5px 8px 0 0'}} src={support} height='24px' width='24px'/>
                    <div>
                        <Well>
                            <Wellrwrd>{'Dedicated Care Manager'}</Wellrwrd>
                        </Well>
                        <Well style={{'marginTop':'9px'}}>
                            <Ptag letterSpacing='0.5px' letterHeight='1.5'>{'To help with bookings, claims, and queries'}</Ptag>
                        </Well>
                    </div>
            </DedicatedCareManager>

        </Root>
    )
}

export default withRouter(PlanOverViewCards)

const Root = styled.div`
    display: flex;
    flex-direction: column;
`

const Subscription = styled.div`
    display: flex;
    width:100%;
    padding-left: 8px;
    padding-bottom: 24px;
    text-transform: uppercase;
`
const SubsFee = styled.div`
    width:80%;
    float:left;
    `
const NumberLives = styled.div`
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.8;
    letter-spacing: 1px;
    color: #4a4a4a;
    width:50%;
    // float:left;
    text-transform: uppercase;
    
    `
const FeeRupee = styled.div`
    font-family: Open Sans;
    font-size: 20px;
    line-height: normal;
    letter-spacing: 1px;
    font-weight: 600;
    color: #4a4a4a;
    `
const Lives = styled.div`
    font-family: Open Sans;
    font-size: 20px;
    line-height: normal;
    letter-spacing: 1px;
    font-weight: 600;
    color: #4a4a4a;
    `
//WellnessReward
const WellnessReward = styled.div`
    display: flex;
    width:100%;
    height: 100px;
    border-radius: 10px;
    border: solid 2px #f27576;
    background-color: white;
    margin-bottom: 12px;
    padding:19px 18px 26px 18px;
`

const Well = styled.div`
    width:100%;
    display:flex;
    align-items: center;
    & > img{
        margin-right: 8px;
        margin-top: 9px;
    }
`

const Wellrwrd = styled.div`
    font-family: Open Sans;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.14px;
    color: #4a4a4a;
`
const Peremploy = styled.div`
    font-family: Open Sans;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #4a4a4a;
    margin-top:9px;
    letter-spacing: 0.5px;
`
//AccessHealthMarketplace
const AccessHealthMarketplace = styled.div`
    display: flex;
    width:100%;
    border-radius: 10px;
    border: solid 2px #009999;
    background-color: white;
    margin-bottom: 12px;
    padding:19px 18px 18px 18px;
`
const MarketPlaceContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const AccessHlth = styled.div`
    width:100%;
    display:flex;
`
const Acsrwrd = styled.div`
    font-family: Open Sans;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    color: #4a4a4a;
`
const Acsemploy = styled.div`
    font-family: Open Sans;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #4a4a4a;
    padding-top:9px;
    letter-spacing: 0.5px;
`
//HealthInsurance
const HealthInsurance = styled.div`
   width:100%;
   display: flex;
   border-radius: 10px;
   border: solid 2px #436ab2;
   background-color: white;
   margin-bottom: 12px;
   padding:19px 18px 16px 18px;
  `
const HealthIns = styled.div`
    width:100%;
    display:flex;
`

const Insrwrd = styled.div`
    font-family: Open Sans;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.14px;
    color: #4a4a4a;
`
const Insemploy = styled.div`
    width:100%;
    display:flex;
`
const Num = styled.div`
    font-size: 20px;
    line-height: normal;
    letter-spacing: 1px;
`
const InsSum = styled.div`
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.4;
    letter-spacing: 1px;
    color: #4a4a4a;
    text-transform: uppercase;
    width: 50%;
`
const InsDed = styled.div`
    font-family: Open Sans;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.4;
    letter-spacing: 1px;
    color: #4a4a4a;
    text-transform: uppercase;
`

const NavigationLinks = styled.div`
    display: flex;
    margin-top: 2px;
    justify-content: flex-end;
    align-items: center;
    & > p {
        margin-right: 8px;
    }
`

const DedicatedCareManager = styled.div`
    display: flex;    
    border-radius: 10px;
    border: solid 2px #97b872;
    margin-bottom: 12px;
    padding:18px 24px 28px 18px;
`