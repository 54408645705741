import React, { Component } from "react";
import styled from "styled-components";
import {Ptag} from './fonts'
import {LoaderDots} from './'

const InterstitialForBenefits = ({heading, subHeading, showPopup}) => {

    return (
        <Root openPopup={showPopup}>
            <LoaderDots />
            <Ptag style={{marginTop:'24px'}} fontSize='20px' fontWeight='600' letterHeight='1.2' letterSpacing='0.5px' textAlign='center'>
                {heading}
            </Ptag>
            <Ptag style={{marginTop:'12px'}} fontSize='16px' letterHeight='1.5' letterSpacing='0.4px' textAlign='center'>
                {subHeading}
            </Ptag>
        </Root>
    )
}


export default InterstitialForBenefits


const Root = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    justify-content:center;
    align-items: center;
    min-height: 100vh;
    min-width: 100vw;
    background-color: white;
    transform: ${(props) =>
        props.openPopup === true
          ? "translateY(0) scaleY(100%)"
          : "translateY(102%) scaleY(0)"};
    transition: all 0.6s;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
`



