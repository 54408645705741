import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import ArrowBack from '../../assets/images/arrowBack.png'
import { Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Ptag } from "../../components/fonts";
import http from '../../services/httpService'
import BusyIndicator from '../../components/busyIndicator.jsx'
import formatAmount from '../../components/memberApp/common/utils'
import {maxScreenWidth} from '../../config.json'
import PaymentBreakup from './paymentBreakup'
import {withRouter} from 'react-router'
import SubscriptionPayment from './subscriptionPayment'
 class NetBankingDetails extends Component {
  constructor(props) {
    super(props)
    this.policy_navigating = {}
  }
  state = {
    loading: true,
    amount:'',
    platformFee:'',premium:'',tax:'',discounted_tax:'',
    netBanking: false,
    debitCard: true,
    method: 2,
    options: {},
    loader: false,
    voucherError: '',
    data: {},
    ifscCode:'',
    accName:'',
    accNo:'',
    totalAmount:'',
    subscription_data: {
      payment_history: [],
      token: {},
      order_id: {}
    }
  }
  componentDidMount =  () => {
    this.getAllValues()
    if (localStorage.getItem('nb_data')) {
      const nb_data = JSON.parse(localStorage.getItem('nb_data'))
      const nb_premium = nb_data.premium
      const discount = nb_data.discount
      this.setState({
        premium: nb_premium,
        discount
      })
    } 
    
  }

  getAllValues = async ()=> {
    const data = JSON.parse(localStorage.getItem('emp_data'))
    const amount_insurer = Math.round((data && data.overallsummary && data.overallsummary.premium)*1.18) || 0
    console.log(amount_insurer)
    try {
      const { data } = await http.get(`/business/employee_detail/`, {
        params: {
          userplan_id: localStorage.getItem('userplan_id'),
        }
      })
      var subscription_amount = ((data['overallsummary']['premium'] || 0)+(data['overallsummary']['platform_fee'] || 0)-(data['overallsummary']['discount'] || 0)+(data['overallsummary']['discounted_tax'] || 0) + (data['overallsummary']['gpa_cost'] || 0))
        this.setState({
        amount: subscription_amount ,
        tax:data['overallsummary']['tax'] || 0,
        discounted_tax:data['overallsummary']['discounted_tax'] || 0,
        platformFee:data['overallsummary']['platform_fee'] || 0,
        premium:data['overallsummary']['premium'] || 0,
        loading: false,
        discount: data['overallsummary']['discount'] || 0,
        amount_insurer: amount_insurer
      })
    }
    catch (error) {
      alert(error.response.data)
    }
  }

  goToPreviousPage = () => {
    if (localStorage.getItem('via_payment_to_details')){
      if (localStorage.getItem('subscriptionPayment')){
        this.props.history.push('/business/subscriptionpayment')
      } else {
        this.props.history.push('/business/makepayment')
      }
    }
    else if (localStorage.getItem('via_payment') === 'true') 
      this.props.history.push( '/business/bankslist')
    else if (localStorage.getItem('via_subscription_to_details') === 'true')
      this.props.history.push('/business/subscriptionpayment')
  }

  handleSubmit = async (values) => {
    this.setState({loader: true,accName:values.acc_name,accNo:values.acc_no,ifscCode:values.ifsc})
    var _this = this
    const nb_data = JSON.parse(localStorage.getItem('nb_data'))
    const planid= localStorage.getItem('userplan_id')
    let points_applied = localStorage.getItem('points_applied')
    points_applied = points_applied && parseInt(points_applied)
    const data = JSON.parse(localStorage.getItem('emp_data'))
    const amount_insurer = Math.round((data && data.overallsummary && data.overallsummary.premium)*1.18) || 0
    console.log(amount_insurer)

    var userPlanDetails = JSON.parse(localStorage.getItem('user_plan_details'))
      try {
        let response = await http.post(`/payment/subscription_payment/?subscription_id=${userPlanDetails['subscription']['vital_subscription_id']}`, { 
          payment: {
            method: nb_data.method,
            plan: nb_data.plan,
            account_number: values.acc_no,
            account_name:values.acc_name,
            ifsc:values.ifsc,
            // voucher: (this.state.discount && this.state.discount.id) || '',
            bank: nb_data.bank_name,
            amount_insurer: amount_insurer
          },
          term_type:"monthly", discount: this.state.discount, discounted_tax: this.state.discounted_tax, payable_amount: this.state.amount
         })
        //   await localStorage.setItem('vital_subscription_id', response.data.vital_subscription_id)
        const options = {
          async handler(response) {
            await http.post('/payment/verify/', {
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
              razorpay_order_id: response.razorpay_order_id
            })
            _this.props.history.push('/business/paymentsuccess')
          },
          ...response.data
        }
  
        this.setState({ loader: false })
        const rzp = new window.Razorpay(options)
        rzp.open()
      } catch (ex) {
        console.log('Error-Ex: ', ex)
        this.setState({loader: false})
  
      }
    
    }
 
  renderPaymentDisclaimer = () => {
    return (
      <NBText>
        ₹{formatAmount(this.state.amount)} will be charged now and ₹{formatAmount(this.state.amount)} will be
        auto-debited every subsequent month until plan expiration
      </NBText>
    )
  }

  render() {
    const nb_data = JSON.parse(localStorage.getItem('nb_data'))
    const Schema = Yup.object({
      ifsc: Yup.string()
        .length(11, 'Enter a valid IFSC')
        .required('Enter a valid IFSC'),
      acc_no: Yup.string('Enter a valid Account Number').required('Enter a valid Account Number')
    })
    return(
      <DetailsDiv>
        <Ptag fontSize="24px" letterSpacing="0.6px" lineHeight="33px" fontWeight="600" color={'#666666'}>Enter Your {nb_data.bank_name && nb_data.bank_name} Details</Ptag>
      <Formik
        initialValues={{ ifsc:this.state.ifscCode?this.state.ifscCode:'', acc_no:this.state.accNo?this.state.accNo:'',acc_name:this.state.accName?this.state.accName:'' }}
        validationSchema={Schema}
        // validate={values => {
        //   const errors = {}
        //   if (!values.ifsc) {
        //     errors.ifsc = 'Required'
        //   }
        //   if (!values.acc_no) {
        //     errors.acc_no = 'Required'
        //   }
        //   return errors
        // }}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={this.handleSubmit}
        render={formProps => {
          return (
            <Form
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%'
              }}>
              <DetailsDiv>
                <NBInfoDiv>
                  <SearchBarWrapper>
                    <Input
                      type="text"
                      key="ifsc"
                      name="ifsc"
                      placeholder="IFSC Code"
                      onChange={formProps.handleChange}
                      className="textbox"
                    />
                    <ErrorMessage name="ifsc" style={{ 'text-color': '#4a4a4a' }} />
                  </SearchBarWrapper>
                  <SearchBarWrapper>
                    <Input
                      type="text"
                      key="acc_no"
                      name="acc_no"
                      placeholder="Account Number"
                      onChange={formProps.handleChange}
                      className="textbox"
                    />
                    <ErrorMessage name="acc_no" style={{ 'text-color': '#565434' }} />
                  </SearchBarWrapper>
                  <SearchBarWrapper>
                    <Input
                      type="text"
                      key="acc_name"
                      name="acc_name"
                      placeholder="Account Name"
                      onChange={formProps.handleChange}
                      className="textbox"
                    />
                  </SearchBarWrapper>
                <PayBtnWrapper>
                {this.renderPaymentDisclaimer()}
               
                  <Button style={{ outline: 'none',border:0 }} type="submit">
                    <Text>PAY SECURELY</Text>
                  </Button>
                </PayBtnWrapper>
                </NBInfoDiv>
              </DetailsDiv>
            </Form>
          )
        }}
      />
    </DetailsDiv>
    )
  }
 }

const OuterDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  box-shadow: rgb(0 0 0 / 40%) 0px 1px 4px 0px;
`

const NavDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  width: 100%;
  padding: 0 24px 0 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
`
const BackIcon = styled.img`
  width: 24px;
  height: 24px;
  align-items: center;
  background: cover;
`

const DetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  display: flex;
  margin:24px 0px 0px 0px ;
  width: 100%;
`
const NBInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
  height: 250px;
  margin: 0px 0px 0 0px;
`

const NBText = styled.div`
  display: flex;
  flex-direction: row;
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-bottom: 16px;
  color: #4a4a4a;
`
const PayBtnWrapper = styled.div`
  display: flex;
  /* position: absolute; */
  flex-direction: column;
  width: 100%;
  margin-top:100px;
  bottom: 0;
  padding: 24px;
`
const Button = styled.button`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  box-shadow: none;
  outline: none;
  elevation: 0;
  height: 54px;
  border-radius: 15px;
  background-color: #436ab2;
`

const SearchBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 28px 28px 4px 28px;
`

const Input = styled.input`
  display: flex;
  width: 100%;
  font-family: Avenir;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #009999;
  padding-bottom: 12px;
  text-overflow: ellipsis;
  border-width: 0px 0px 2px 0px;
  border-bottom: 2px solid #436ab2;
  &:focus {
    outline: none;
    background: none !important;
  }

  &:not(:placeholder-shown) {
    & + span {
      color: rgba(0, 0, 0, 0.54);
      transform: translate(-5px, -22px) scale(0.9);
      -ms-transform: translate(-5px, -22px) scale(0.9); /* IE 9 */
      -webkit-transform: translate(-5px, -22px) scale(0.9); /* Safari */
    }
  }
`

const Text = styled.p`
  font-family: Avenir;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 1px;
`
export default withRouter(NetBankingDetails)