
import _ from 'lodash'
import React, { useState, useEffect, useMemo } from "react";
import styled from 'styled-components'
import { Ptag } from "../../components/fonts";
import http from '../../services/httpService'
import { IfComponent, OverlayLoader } from '../../components';
import { withRouter } from 'react-router'
import { formatAmount } from '../../utils/utils'

const tableTitles = [
    "Payment Date",
    "Amount",
    "No. of Employees",
    "Invoice/Credit Memo",
    "Employee-wise Breakup Sheet",
    "Status",
    "Remark"
  ];

const GmcPaymentSummary = () => {
    const [loading, setLoading] = useState(false)
    
    const [tableData, setTableData] = useState()
    const flow = localStorage.getItem('flow')

    const getSummeryData = async () =>{
        setLoading(true)
        const userplan_id = localStorage.getItem('userplan_id') || sessionStorage.getItem('userplan_id') || ''
          try{
            const { data } = await http.get(`/business/get_payment_summary/`,{
                params :{
                  userplan_id:userplan_id
                } 
            })
            // console.log(data,"subh")
            setTableData(data)
            setLoading(false)
          } catch(ex){
            console.log({ex})
          }
    }

    useEffect(() => {
        getSummeryData()
      },[])

    const tableHeaders = useMemo(() => {
        return tableTitles.map((title) => (
          <th style={{padding: '1rem 0rem', width: '200px'}}>
            <TopHeader>
              <Ptag
                fontSize="14px"
                textAlign="center"
                color="#002060"
                fontWeight="600"
              >
                {title}
              </Ptag>
            </TopHeader>
          </th>
        ));
      }, []);

    const getInvoiceUrl = (invoice_url)=>{
        window.open(invoice_url)
    }

    const renderPaymentSummery = () =>{
        return (
            <HomeWrapper>
              <Body>
                <Ptag
                  fontSize="24px"
                  fontWeight="700"
                  style={{ paddingBottom: "20px" }}
                >
                  {"Payment Summary"}
                </Ptag>
                <IfComponent condition={tableData && tableData.length >= 0}>
                  <div style={{ overflow: "auto" }}>
                    <Table
                      id="table-to-xls"
                      className="table-striped"
                      width="100%"
                      cellSpacing="0"
                      cellPadding="6"
                      showBottomBorder={tableData && tableData.length != 1}
                    >
                      <tbody>
                        <tr style={{backgroundColor: "#F1F3F6"}}>{tableHeaders}</tr>
                        {tableData &&
                          tableData.map((payment_data, index) => {
                            const showPDF = payment_data && payment_data.invoice_link;
                            const showExcel = payment_data && payment_data.employee_break_wise_link;
                            return (
                              <tr
                                key={index}
                                style={{ background: "transparent", borderBottom: `1px solid "#0054E3"` }}
                              >
                                <td>
                                  <TopHeader>
                                    <Ptag fontSize="14px" letterSpacing="1px">
                                      {payment_data.payment_date}
                                    </Ptag>
                                  </TopHeader>
                                </td>
                                <td>
                                  <TopHeader>
                                    <Ptag fontSize="14px" letterSpacing="1px">
                                      ₹{formatAmount(payment_data.amount)}
                                    </Ptag>
                                  </TopHeader>
                                </td>
                                <td>
                                  <TopHeader>
                                    <Ptag fontSize="14px" letterSpacing="1px">
                                      {payment_data.no_of_employees || "0"}
                                    </Ptag>
                                  </TopHeader>
                                </td>
                                <td>
                                  <TopHeader>
                                    {showPDF ? (
                                      <Ptag
                                        style={{ cursor: "pointer" }}
                                        color="#4A90E2"
                                        fontSize="14px"
                                        textDecoration={"underline"}
                                        letterSpacing="1px"
                                        onClick={() => {
                                          getInvoiceUrl(payment_data.invoice_link);
                                        }}
                                      >
                                        {"PDF"}
                                      </Ptag>
                                    ) : (
                                      <Ptag
                                        fontSize="14px"
                                        letterSpacing="1px"
                                      >
                                        {"-"}
                                      </Ptag>
                                    )}
                                  </TopHeader>
                                </td>
                                <td>
                                  <TopHeader>
                                    {showExcel ? (
                                      <Ptag
                                        style={{ cursor: "pointer" }}
                                        color="#4A90E2"
                                        fontSize="14px"
                                        textDecoration={"underline"}
                                        letterSpacing="1px"
                                        onClick={() => {
                                          getInvoiceUrl(payment_data.employee_break_wise_link);
                                        }}
                                      >
                                        {"EXCEL"}
                                      </Ptag>
                                    ) : (
                                      <Ptag
                                        fontSize="14px"
                                        letterSpacing="1px"
                                      >
                                        {"-"}
                                      </Ptag>
                                    )}
                                  </TopHeader>
                                </td>
                                <td>
                                  <TopHeader>
                                    <Ptag fontSize="14px" letterSpacing="1px">
                                      {payment_data.status}
                                    </Ptag>
                                  </TopHeader>
                                </td>
                                <td>
                                  <TopHeader>
                                    <Ptag fontSize="14px" textAlign="center">{payment_data.type}</Ptag>
                                  </TopHeader>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  </div>
                </IfComponent>
              </Body>
            </HomeWrapper>
          );
    }


    return (
        <>
            <Root>
                <PageBody>
                    {loading && <OverlayLoader />}
                    {renderPaymentSummery()}
                </PageBody>
            </Root>
        </>
    )
}
// }

const Root = styled.div`
	width: 100%;
	background-color: #ffffff;
    display: flex;
    flex-direction: column;
`
const Table = styled.table`
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  & td {
    border-bottom: ${props => props.showBottomBorder ? '0.5px solid #3f9dff' : 'transparent'};
    padding: 0.8rem 0.2rem;
  }
`;

const PageBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    flex: 1;
`

const TopHeader = styled.h1`
  text-transform: lowercase;
  font-family: Avenir;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: 0.6px;
  display: flex;
  padding: 12px 12px ;
  justify-content: center;
   
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 24px;
    flex: 1;
`


const HomeWrapper = styled.div`
  transition: margin-left .2s ease-in;
  padding-bottom: 20px;
  box-sizing: border-box;
  margin-top: 20px;
  background:#fff;
`


export default withRouter(GmcPaymentSummary)