
import React, { Component, Fragment } from 'react'


 const IfComponent = ({children, condition}) => {

    return(
        <>
            {
                !!condition &&
                {...children}
            }
        </>
    )
    
}

export default IfComponent