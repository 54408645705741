import {offerType} from './marketplaceConstant'

export const benefitsRedemptionFlowPages = {
    OFFER_DETAILS: 'offer',

    WHO_IS_IT_FOR: 'wiif',
    DATE_OF_ADMISSION: 'doa',
    HEALTH_CENTRE_SELECTION: 'healthcentreselection',
    SHIPPABLE_ADDRESS: 'benefitshippableaddress',
    CONTACT_INFO: 'contactinfo',

    VOUCHER: 'viewVoucher'
}

export const offerRedemptionFlow = {
    [offerType['SHIPPABLE']]: [
        benefitsRedemptionFlowPages['OFFER_DETAILS'],
        benefitsRedemptionFlowPages['SHIPPABLE_ADDRESS'],
        benefitsRedemptionFlowPages['CONTACT_INFO'], 
    ],

    [offerType['ONLINE_BOOKING_(DIAGNOSTIC)']]: [
        benefitsRedemptionFlowPages['OFFER_DETAILS'],
        benefitsRedemptionFlowPages['WHO_IS_IT_FOR'],
        benefitsRedemptionFlowPages['DATE_OF_ADMISSION'],
        benefitsRedemptionFlowPages['CONTACT_INFO'], 
    ],

    [offerType['HEALTH_DEALS']]: [
        benefitsRedemptionFlowPages['OFFER_DETAILS'],
        benefitsRedemptionFlowPages['WHO_IS_IT_FOR'],
        benefitsRedemptionFlowPages['DATE_OF_ADMISSION'],
        benefitsRedemptionFlowPages['CONTACT_INFO'], 
    ],

    [offerType['HEALTH_CENTRE_VISIT']]: [
        benefitsRedemptionFlowPages['OFFER_DETAILS'],
        benefitsRedemptionFlowPages['HEALTH_CENTRE_SELECTION'],
        benefitsRedemptionFlowPages['WHO_IS_IT_FOR'],
        benefitsRedemptionFlowPages['DATE_OF_ADMISSION'],
        benefitsRedemptionFlowPages['CONTACT_INFO'], 
    ],

    [offerType['HOME_SAMPLE_COLLECTION']]: [
        benefitsRedemptionFlowPages['OFFER_DETAILS'],
        benefitsRedemptionFlowPages['WHO_IS_IT_FOR'],
        benefitsRedemptionFlowPages['DATE_OF_ADMISSION'],
        benefitsRedemptionFlowPages['SHIPPABLE_ADDRESS'],
        benefitsRedemptionFlowPages['CONTACT_INFO'], 
    ],

    [offerType['ONLINE_CLASSES_(CLASSES/TRAINER)']]: [
        benefitsRedemptionFlowPages['OFFER_DETAILS'],
        benefitsRedemptionFlowPages['WHO_IS_IT_FOR'],
        benefitsRedemptionFlowPages['CONTACT_INFO'], 
    ],

    [offerType['DEFAULT']]: [
        benefitsRedemptionFlowPages['OFFER_DETAILS'],
        benefitsRedemptionFlowPages['WHO_IS_IT_FOR'],
        benefitsRedemptionFlowPages['CONTACT_INFO'], 
    ],

    [offerType['NORMAL_DEALS']]: [
        benefitsRedemptionFlowPages['OFFER_DETAILS'],
        benefitsRedemptionFlowPages['WHO_IS_IT_FOR'],
        benefitsRedemptionFlowPages['CONTACT_INFO'], 
    ]

}