import React  from "react";
import styled from "styled-components";
import {Ptag} from '../fonts'
import {LoaderDots} from '../'

const Modal = ({openModal}) => {

    return (
        <Home showModal={openModal}>
            <Ptag style={{marginBottom : '18px'}} fontFamily='Proxima Nova' fontSize='18px' textAlign='center' letterHeight='1.7' textAlign='center' fontWeight='600' letterSpacing='0.5px'>
                {'Building your personalized health plan'}
            </Ptag>
            <LoaderDots/>
        </Home>
    )
}

export default Modal


const Home = styled.div`
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 56px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transform: ${props => (props.showModal === true ? 'translateY(0) scaleY(100%)' : 'translateY(102%) scaleY(0)')};
    transition: all 0.6s;
`