import React, { Component } from 'react';
import styled from 'styled-components';
import Notification from '../assets/images/noti.png'
import PanelD from '../assets/images/panelD.png'
import CoronaD from '../assets/images/coronaD.png'
import Icons from '../components/icons'
const ParentDiv = styled.div`
  display: flex;
  justify-content: space-between;
  background-image: url(${Notification});
  background-position: center;
  background-size: cover;
  object-fit: contain;
  height: 166px;
  width: 100%;
  @media (min-width: 1024px) {
    background-image: url(${PanelD});
    background-position: center;
    background-size: cover;
    object-fit: contain;
    height: 200px;
    width: 100%;
  }
`
const IconDiv = styled.div`
  position: absolute;
  right: 7px;
  top: 72px;
  cursor: pointer;
  @media (min-width: 1024px) {
    margin-top: 16px;
    }
`
const TextDiv = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    margin-left: 12px;
    @media (min-width: 1024px) {
      margin-top: 100px;
      margin-left: 200px;
    }
`
const Strong1 = styled.strong`
  font-weight:600;
  letter-spacing: 0.6px;
`
const ImageDiv = styled.div`
    display: flex;
      @media(min-width: 1024px) {
        background-image: url(${CoronaD});
        margin: 100px 400px 0 0;
        // padding-right: 100px;
        background-position: center;
        background-size: cover;
        object-fit: contain;
        height: 100px;
        width: 72px;
        align-self: flex-end;
      }
`
const H2 = styled.h2`
        font-family: Proxima Nova;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.63px;
        color: #4a4a4a;
        @media (min-width: 1024px) {
        font-family: Proxima Nova;
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.63px;
        color: #4a4a4a;
        }
`
const Font16 = styled.p`
        font-family: Proxima Nova;
        font-size: 16px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.67px;
        color: #4a4a4a;
        @media (min-width: 1024px) {
        font-family: Proxima Nova;
        font-size: 20px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.67px;
        color: #4a4a4a;
        }
`
class page55 extends Component {
  render() {
    return (
      <ParentDiv>
        <IconDiv onClick={() => this.props.handleBack()} >
            <Icons icon="blackcross"  height="24" width="24" />
        </IconDiv>
        <TextDiv>
            <H2>
              Be fearless
            </H2>
            <Font16>
              We've got you covered for 
              <Strong1>{' CoVID-19 '}</Strong1> 
              in our plans
            </Font16>
        </TextDiv>
        <ImageDiv />
      </ParentDiv>
    );
  }
}
export default page55;