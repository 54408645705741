import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Header} from '../../components/business'
import {Ptag} from '../../components/fonts'
import blueArrow from '../../assets/images/blueArrow.png'
import { OverlayLoader } from '../../components'
import http from '../../services/httpService'
import formatAmount from '../../components/memberApp/common/utils'
import TagManager from 'react-gtm-module'
import {gtmCode} from '../../utils/utils'

function SelectPlanType({...props}){

    const [loader, setLoader] = useState(true)
    const [AnnualFee, setAnnualFee] = useState(99999999) 
    const [MonthlyFee, setMonthlyFee] = useState(99999999)
    const [monthlyWarning, setMonthlyWarning] = useState('')
    const [yearlyWarning, setYearlyWarning] = useState('')
    
    function monthlyPremiumToShow(item){
        return  item['sum_insured'] === 300000 && item['deductible'] === 0
    }

    function calculatePointsToShow(pointsData, members){
        return pointsData && pointsData[0] && pointsData[0]['subcategories'] &&
            pointsData[0]['subcategories'][0] && pointsData[0]['subcategories'][0]['cost'] &&
            pointsData[0]['subcategories'][0]['cost'] * members || 0
    }

    function yearlyPremiumToShow(item){
        return item['sum_insured'] === 200000
    }
    
    useEffect(  () => {
        async function fetchData(){
            
            const plan_id = localStorage.getItem('plan_id')
            const {data: dataForMonthly} = await http.patch(`business/plan/${plan_id}/`,{
                plan_type: 'Monthly'
            })

            setMonthlyWarning(dataForMonthly['monthly_disclaimer'])
            const MonthlyPremiums = dataForMonthly && dataForMonthly['insurers'] && dataForMonthly['insurers'][0] && dataForMonthly['insurers'][0]['premiums']
            const monthlyPremium = MonthlyPremiums && MonthlyPremiums.filter( monthlyPremiumToShow )[0]['premium']
            const points = calculatePointsToShow(dataForMonthly['add_ons'], dataForMonthly['total_employee_count'] || 0)
            setMonthlyFee(monthlyPremium + points)
            const {data: dataForYearly} = await http.patch(`business/plan/${plan_id}/`,{
                plan_type: 'Annual'
            })

            http.patch(`business/plan/${plan_id}/`,{
                plan_type: null
            })
            setYearlyWarning(dataForYearly['disable_annual_message'])
            const YearlyPremiums = dataForYearly && dataForYearly['insurers'] && dataForYearly['insurers'][0] && dataForYearly['insurers'][0]['premiums'] && dataForYearly['insurers'][0]['premiums'][0] &&  dataForYearly['insurers'][0]['premiums'][0]['Non Maternity']
            const YearlyPremium = YearlyPremiums && YearlyPremiums.filter(yearlyPremiumToShow)[0]['premium']
            const YearlyPoints = calculatePointsToShow(dataForYearly['add_ons'], dataForYearly['total_employee_count'] || 0)
            setAnnualFee(YearlyPoints + YearlyPremium)
            setLoader(false)
        }
        const tagManagerArgs = {
            gtmId: gtmCode(),
        }
        TagManager.initialize(tagManagerArgs)
        fetchData()
    }, [])

    const onClickCard = async (cardId) => {
        if (cardId === 0) {
            localStorage.setItem('plantype','monthly')
            props.history.push('/business/plans')
        } else if (cardId === 1) {
            if (yearlyWarning)
                return
            localStorage.setItem('plantype','annual')
            props.history.push('/business/plans')
        }
    }

    return(
        <Root>
            <Header onClickBack={() => props.history.push('/business/selectpolicytype')} progressPercentage={60}/>
            <Body>
                <Ptag fontFamily='Proxima Nova' fontSize='24px' letterHeight='1.33' letterSpacing='0.35px' color='#545454'>
                    {'choose a plan type'}
                </Ptag>

                <Card id='business_selectplantype_monthly' onClick={() => onClickCard(0)}>
                    <Ptag fontSize='18px' fontWeight='600' letterSpacing='0.5px' color='#436ab2'>
                        {'Monthly Plan'}
                    </Ptag>
                    <Ptag style={{'margin': '8px 0 12px'}} fontSize='14px' letterHeight='1.43' letterSpacing='0.5px' color='#696969'>
                        {'Get health insurance and wellness rewards without hurting your cashflow.'}
                    </Ptag>
                    <CardFooter>
                        <Ptag fontWeight='600' fontSize='16px' letterSpacing='0.57px' color='#696969'>
                            {`₹ ${formatAmount(MonthlyFee)}`}
                        </Ptag>
                        <Ptag fontWeight='600' fontSize='10px' letterSpacing='0.36px' color='#696969'>
                            {'/mo'}
                        </Ptag>
                        <img style={{'marginLeft':'auto'}} src={blueArrow} height='20px' width='20px'/>
                    </CardFooter>
                    {
                        monthlyWarning && 
                        <CardWarnings margin='12px -18px -24px -18px' bgColor='#f27576'>
                            <Ptag fontSize='10px' letterSpacing='0.36px' letterHeight='2' color='#fff'>
                                {"Note:"}
                            </Ptag>
                            <Ptag letterHeight='1.67' letterSpacing='0.43px' color='#fff'>
                                {monthlyWarning}
                            </Ptag>
                        </CardWarnings>
                    }
                </Card>


                <Card id='business_selectplantype_yearly' onClick={() => onClickCard(1)}>
                    <CardHeader opacity={yearlyWarning ? '0.4' : '1'}>
                        <Ptag fontSize='18px' fontWeight='600' letterSpacing='0.5px' color='#436ab2'>
                            {'Annual Plan'}
                        </Ptag>
                        <Ptag style={{'margin': '8px 0 12px'}} fontSize='14px' letterHeight='1.43' letterSpacing='0.5px' color='#696969'>
                            {'For health insurance with no waiting period, maternity benefits and a cover for pre-existing conditions.'}
                        </Ptag>
                    </CardHeader>
                    
                    { !yearlyWarning && 
                        <CardFooter>
                            <Ptag fontWeight='600' fontSize='16px' letterSpacing='0.57px' color='#696969'>
                                {`₹ ${formatAmount(AnnualFee)}`}
                            </Ptag>
                            <Ptag fontWeight='600' fontSize='10px' letterSpacing='0.36px' color='#696969'>
                                {'/year'}
                            </Ptag>
                            <img style={{'marginLeft':'auto'}} src={blueArrow} height='20px' width='20px'/>
                        </CardFooter>
                    }
                    {
                        yearlyWarning && 
                        <CardWarnings margin='0px -18px -24px -18px' >
                            <Ptag fontSize='10px' letterSpacing='0.36px' letterHeight='2'>
                                {"Note:"}
                            </Ptag>
                            <Ptag letterHeight='1.67' letterSpacing='0.43px'>
                                {yearlyWarning}
                            </Ptag>
                        </CardWarnings>
                    }

                </Card>
                {loader && <OverlayLoader/>}
            </Body>
            
        </Root>
    )
}

export default SelectPlanType

const Root = styled.div`
    display: flex;
    flex-direction: column;
    flex:1;
`

const Body = styled.div`
    padding: 24px;
    flex:1;
`

const Card = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 18px;
    border-radius: 10px;
    border: solid 1px #979797;
    margin-top: 24px;
    cursor: pointer;
    overflow: hidden;
`

const CardFooter = styled.div`
    display:flex;
    align-items: baseline;
`

const CardWarnings = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${props => props.bgColor ? props.bgColor : '#eeeeee'};
    padding: 18px;
    margin: ${props => props.margin ? props.margin : '0'}
`

const CardHeader = styled.div`
    display: flex;
    flex-direction: column;
    opacity: ${props => props.opacity ? props.opacity : '1'}
`