import React, { Component } from 'react'
import styled from 'styled-components'
import {Ptag} from '../../components/fonts'
import {OverlayLoader, Icons, LargeButton} from '../../components'
import http from '../../services/httpService'
import { Formik, FieldArray, Form } from 'formik'
import {EmployeeCompleteDetails, Header, HealthConditions} from '../../components/business'
import plus from '../../assets/images/addCircle.png'
import _ from 'lodash'
import TagManager from 'react-gtm-module'
import {gtmCode} from '../../utils/utils'



export default class BusinessMemberDetails extends Component{

    constructor(props){
        super(props)
        let title = 'enter employee details'
        const navigationContext = localStorage.getItem('navigatingFrom')
        const offlinequotes = localStorage.getItem('offlineQuote')
        if (navigationContext === 'profile'){
            title = 'Member Details'
        } else if (navigationContext === 'dashboard'){
            title = ''
        }
        const plantype = 'monthly'
        const conditionAgreed = true
        this.state = {
            employees: [

            ],
            loading: true,
            editFamilyCard: -1,
            navigationContext,
            conditionAgreed: conditionAgreed,
            title: title,
            plantype: plantype,
            offlinequotes: offlinequotes
        }
    }

    async componentDidMount(){
        const tagManagerArgs = {
            gtmId: gtmCode(),
          }
        TagManager.initialize(tagManagerArgs)
        let employees = []
        if ( this.state.navigationContext !== 'dashboard'){
            const {data} = await http.get('/business/employees/')
            for (let member of data){
                let memberData = this.formatBackendData(member) 
                employees.push(memberData)
            }
        }
        
        if (this.state.navigationContext !== 'profile'){
            if (employees.length === 0 || this.state.navigationContext === 'dashboard'){
                let member = {
                    'dependents': [{
                        dependent_id: '',
                        relationship: 'self',
                        first_name: '',
                        last_name: '',
                        dob: '',
                        email: '',
                        gender:'',
                        phone:''
                    }],
                    expanded: true,
                    showDependents: false
                }
                employees.push(member)
                this.setState({editFamilyCard: employees.length - 1})
            }
        }
        

        this.setState({loading: false, employees: employees})
    }

    formatBackendData = (member) => {
        let memberData = {
            'dependents': [],
            'employee_id': member['employee_id']
        }
        for (let dep of member['dependents']){
            let dependentData = {}
            if (dep['relationship'] && dep['relationship'].toLowerCase() === 'self'){
                dependentData['email'] = member['email']
                dependentData['phone'] = member['phone']
                dependentData['gender'] = member['gender'].toLowerCase()
            }
            dependentData['relationship'] = dep['relationship'] && dep['relationship'].toLowerCase()
            dependentData['dob'] = dep['dob'].split('-').reverse().join('/')
            dependentData['dependent_id'] = Number(dep['dependent_id'])
            dependentData['first_name'] = dep['first_name']
            dependentData['last_name'] = dep['last_name']
            memberData['dependents'].push(dependentData)
        }
        memberData['dependents'].sort((a,b) => { 
            if (a['relationship'] === 'self')
                return -1 
            else if (b['relationship'] === 'self')
                return 1
            return 0
        })
        memberData['expanded'] = this.state.navigationContext==='profile' ? true : false
        return memberData
    }


    prepareData = (data, employeeData, employeeId ) => {
        let prepData = {}
        if (employeeId){
            prepData['employee_id'] = Number(employeeId)
        }
        prepData['dependents'] = []
        for (let i in data['dependents']){
            let member = {}
            if (employeeData['dependents'][i]['dependent_id']){
                member['dependent_id'] = Number(employeeData['dependents'][i]['dependent_id'])
            }
            if (data['dependents'][i]['relationship'] === 'self'){
                prepData["phone"] = data['dependents'][i]['phone']
                prepData['email'] = data['dependents'][i]['email']
                prepData['gender'] = data['dependents'][i]['gender'].toUpperCase()
            }
            member['dob'] = data['dependents'][i]['dob'].split('/').reverse().join('-')
            member['relationship'] = data['dependents'][i]['relationship'].toUpperCase()
            member['first_name'] = data['dependents'][i]['first_name']
            member['last_name'] = data['dependents'][i]['last_name']
            prepData['dependents'].push(member)
        }
        return prepData
    }
    
    handleSubmit = async (values) => {
        
        if ((values && values['employees'] && values['employees'].length === 0 ))
            return
        if (this.state.editFamilyCard !== -1){
            alert('Please save the employee\'s details before proceeding.')
            return
        }
        this.setState({loading: true})
        try{
            const {data: data2} = await http.get('/business/validate/',{
                params: {
                    userplan: localStorage.getItem('userplan_id')
                }
            })
            window.scrollTo(0,0)
            const sessionStorageToken = sessionStorage.getItem('token')
            if(sessionStorageToken){
                localStorage.setItem('token',sessionStorageToken)
                sessionStorage.removeItem('token')
            }
            this.props.history.push(`/business/${this.state.navigationContext ? this.state.navigationContext : 'dashboard'}`)
        } catch (ex){
            alert(ex && ex.response && ex.response.data && ex.response.data)
        
            this.setState({loading: false})
        }
        
        
    }


    onSaveEmployee = async (formikProps, index, employee_id, employeeData) => {
        let isValid = this.checkEmployeeDataIsValid(formikProps, index)
        const requestData = this.prepareData(formikProps.values['employees'][index], employeeData, employee_id)
        
        if (isValid){
            try{
                this.setState({loading: true})
                const {data} = await http.post('business/employees/',{
                    employees: [requestData]
                })
                if (this.state.navigationContext === 'dashboard'){
                    await http.post('business/businessplan/',{
                        employee: data[0]['employee_id'],
                        userplan: localStorage.getItem('userplan_id')
                    })
                    this.props.history.push('/business/dashboard')
                    return
                }
                let employeeFormattedData = this.formatBackendData(data[0])
                let employees = this.state.employees
                employees[index] = employeeFormattedData
                this.setState({employees, editFamilyCard: -1, loading: false})
            }catch(ex){
                console.dir(ex)
                if(ex && ex.response && ex.response.data && ex.response.data[0] && ex.response.data[0].non_field_errors && ex.response.data[0].non_field_errors[0])
                    alert(ex.response.data[0].non_field_errors[0])
                this.setState({loading: false})
            }
            
        }
    }

    onCancelDraft = async (formikProps, id) => {
        if(this.state.navigationContext === 'dashboard'){
            this.props.history.push('/business/dashboard')
            return
        }
        const {employee_id} = formikProps.values['employees'][id]
        if (employee_id){
            const {data} = await http.get(`/business/employees/${employee_id}/`)
            let employeeFormattedData = this.formatBackendData(data)
            let employees = this.state.employees
            employees[id] = employeeFormattedData
            this.setState({employees, editFamilyCard: -1})
        } else {
            let values = formikProps.values['employees']
            values.splice(id,1)
            this.setState({employees: values, editFamilyCard: -1})
        }
        
    }

    onClickCardHeader = (index) => {
        const employees = this.state.employees
        this.state.employees[index]['expanded'] = !this.state.employees[index]['expanded']
        this.setState({employees})
    }

    /*
     * Returs whether the employees details are valid and sets all fields to touched
     */
    checkEmployeeDataIsValid = (formikProps, employee = this.state.editFamilyCard) => {
        if (employee < 0)
            return true
        const {values, errors,setFieldTouched} = formikProps
        const dependents = values['employees'][this.state.editFamilyCard]['dependents']
        let keys = [
            'first_name',
            'last_name',
            'dob',
            'gender',
            'phone'
        ]
        let  isFieldError = (index, key) => {
            if (errors['employees'] &&
                errors['employees'][employee] &&
                errors['employees'][employee]['dependents'] &&
                errors['employees'][employee]['dependents'][index] &&
                errors['employees'][employee]['dependents'][index][key] )
            return true
        }
        let isValid = true
        for (let i in dependents){
            for (let key of keys){
                if (dependents[i].hasOwnProperty(key) && (dependents[i][key] === '' || isFieldError(i,key))){
                    setFieldTouched(`employees.${employee}.dependents.${i}.${key}`, true)
                    isValid = false
                }
            }
        }
        return isValid
    }

    addEmployee = (formikProps) => {
        let cardInEditMode = this.state.editFamilyCard
        if (cardInEditMode !== -1){
            alert('Please save the existing changes before editing an employee')
            return
        }
        let isValid = this.checkEmployeeDataIsValid(formikProps)
        if (isValid){
            let updatedFormikProps = this.copyEmployeeStateToFormikProps(formikProps, true)
            let member = {
                'employee_id' : '',
                'dependents': [{
                    dependent_id:'',
                    relationship: 'self',
                    first_name: '',
                    last_name: '',
                    dob: '',
                    email: '',
                    gender:'',
                    phone:''
                }],
                expanded: true
            }
            updatedFormikProps.values['employees'].push(member)
            this.setState({employees: formikProps.values['employees'], editFamilyCard : updatedFormikProps.values['employees'].length -1 })
        }
    }

    onDeleteEmployee = async (formikProps, index, employee_id) => {
        if(this.state.navigationContext === 'dashboard'){
            this.props.history.push('/business/dashboard')
            return
        }
        if (window.confirm("Are you sure you want to delete this employee?")) {
            try{
                
                let updatedFormikProps = this.copyEmployeeStateToFormikProps(formikProps, true)
                if (employee_id){
                    await http.delete(`business/employees/${employee_id}/`)
                }
                updatedFormikProps.values['employees'].splice(index,1)
                this.setState({employees: updatedFormikProps.values['employees'], editFamilyCard: - 1})
                
            } catch (ex){
                console.log(ex)
            }
        }
    } 

    onEditEmployee = (index) => {
        let cardInEditMode = this.state.editFamilyCard
        if (cardInEditMode !== -1){
            alert('Please save the existing changes before editing an employee')
            return
        }
        this.setState({editFamilyCard: index})

    }

    deleteDependent = async (formikProps, employee, dependent) => {
        if (window.confirm("Are you sure you want to delete this dependent?")){
            try{
                let {dependent_id} = formikProps.values['employees'][employee]['dependents'][dependent]
                if(dependent_id){
                    await http.delete(`business/dependents/${dependent_id}/`)
                }
                let updatedFormikProps = this.copyEmployeeStateToFormikProps(formikProps)
                updatedFormikProps.values['employees'][employee]['dependents'].splice(dependent,1)
                this.setState({employees: updatedFormikProps.values['employees']})
            }
            catch(ex){
                console.log(ex)
            }
        }
    }

    copyEmployeeStateToFormikProps = (formikProps, collapseCard )=> {
        this.state.employees.map( (employee, index) => {
            formikProps.values['employees'][index]['expanded'] = collapseCard ? false : employee['expanded']
            formikProps.values['employees'][index]['employee_id'] = employee['employee_id']
            employee['dependents'].map( (dependent, dependentIndex) => {
                formikProps.values['employees'][index]['dependents'][dependentIndex]['dependent_id'] = dependent['dependent_id'] 
            })
        })
        return formikProps
    }
    
    onAddFamilyMembers = (formikProps, index, relationship) => {
        let updatedFormikProps = this.copyEmployeeStateToFormikProps(formikProps)
        let values = updatedFormikProps.values['employees']
        values[index]['dependents'].push(
        {
            relationship: relationship,
            dob: '',
            first_name: '',
            last_name: '',
            dependent_id: ''
        })
        this.setState({employees : values})
    }

    renderEmployee(item, index, formikProps){
        return(
            <EmployeeCompleteDetails
                id={index}
                key={`employee${index}`}
                editable={index === this.state.editFamilyCard}
                employee={item} 
                formikProps={formikProps}
                onAddFamilyMembers={this.onAddFamilyMembers}
                onDeleteDependent={this.deleteDependent}
                onSaveEmployee={this.onSaveEmployee}
                onClickCardHeader={this.onClickCardHeader}
                onDeleteEmployee={this.onDeleteEmployee}
                onEditEmployee={this.onEditEmployee}
                onCancelDraft={this.onCancelDraft}
                disableEdit={true}
                showHeader={this.state.navigationContext !== 'dashboard'}
            />
        )
    }

    renderAddEmployee = (formikProps) => {
        if (!this.state.navigationContext && !this.state.offlinequotes)
            return (
                <AddEmployeeWrapper active={this.state.editFamilyCard === -1 ? '1' : '0.4'} onClick={() => this.addEmployee(formikProps)}>
                    <img src={plus} height='24px' width='24px' style={{'marginRight': '10px'}}/>
                    <Ptag fontWeight='600' fontSize='16px' letterSpace='0.25px' color="#00bfa5">{'Add Employee'}</Ptag>
                </AddEmployeeWrapper>
            )
        return null
    }

    renderAgreement = () => {
        let onClickCheckbox = () => {
            if(this.state.editFamilyCard !== -1)
                return
            this.setState({ conditionAgreed: !this.state.conditionAgreed })
            }
        return(
            <Agreement>
                <IconWrapper active={this.state.editFamilyCard === -1 ? '1' : '0.4'} onClick={onClickCheckbox}>
                    <Icons icon={this.state.conditionAgreed? 'checkedCircle' : 'uncheckedCircle'} fill="#436ab2" />
                </IconWrapper>
                    <Ptag letterSpacing='0.5px' letterHeight='1.43' fontSize='14px'>
                        I declare to the best of my knowledge that none of the employees or their family members have any health conditions in this <List onClick={() => this.setState({showHealthConditions: true})}>list</List>
                    </Ptag>
            </Agreement>
        )
    }

    onClickBackOnboarding = () => {
        if(this.state.offlinequotes){
            this.props.history.push('/business/enrollmentprocess')
        } else {
            this.props.history.push('/business/optionstoaddemployees')
        }
    }

    render(){
        return(
            <Root>
                {
                    this.state.navigationContext === 'profile' &&
                    <HeaderWrapper>
                        <Header onClickBack={this.handleSubmit} />
                    </HeaderWrapper>
                }

                {
                    !this.state.navigationContext &&
                    <HeaderWrapper>
                        <Header onClickBack={() => this.props.history.push('/business/uploadexcel')} />
                    </HeaderWrapper>
                }
                
                <Ptag fontSize='20px' fontWeight='600' letterHeight='1.35' letterSpacing='0.5px'>
                    {this.state.title}
                </Ptag>
                
                <Formik
                    initialValues={{employees: this.state.employees}}
                    enableReinitialize={true}
                    onSubmit={this.handleSubmit}
                    render= {formikProps =>
                        <Form>
                            <FieldArray 
                                name='employees'
                                render={
                                    () => (
                                        this.state.employees && this.state.employees.length > 0 &&
                                        this.state.employees.map((item, index, arr) => 
                                            this.renderEmployee(item, index, formikProps)
                                        )
                                    )
                                }
                            />
                            {/* {this.renderAddEmployee(formikProps)} */}
                            <Footer>
                                {/* {!this.state.navigationContext && this.state.plantype === 'monthly' && this.renderAgreement()} */}
                                {this.state.navigationContext !== 'dashboard' && 
                                    <LargeButton style={{'marginTop': '24px'}} 
                                        id='business_memberDetails_next'
                                        opacity={(this.state.employees.length === 0 ) ? '0.4' : '1'} 
                                        label={'NEXT'} 
                                        type='submit'
                                    />}
                            </Footer>
                        </Form>
                    }
                />
                <HealthConditions showPopup={this.state.showHealthConditions} onClose={() => this.setState({showHealthConditions: false})} />
                {this.state.loading && <OverlayLoader />}
            </Root>
        )
    }

}

const Root = styled.div`
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    padding: 24px 16px;
    & > form {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
`;


const AddEmployeeWrapper = styled.div`
    display: flex;
    border-radius: 15px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 26px 0;
    width: 100%;
    margin-top: 24px;
    justify-content: center;
    opacity: ${ props => props.active};
`;

const Agreement = styled.div`
    display: flex;
    margin: 48px 0 0 0;
`

const IconWrapper = styled.div`
    width: 24px;
    height: 24px;
    margin-right: 16px;
    opacity: ${ props => props.active};
`

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: auto;
`
const HeaderWrapper = styled.div`
    margin: -25px -16px 30px -16px;
`

const List = styled.em`
    text-decoration:underline;
    letter-spacing: 0.5px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    text-transform: none;
    opacity: 1;
    font-family: Avenir;
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    color: #4a90e2;
`