import React, { Fragment } from "react";

import { Ptag } from "../fonts";
import {
  SplitCardTag,
  SplitDetailCardWrapper,
  SplitDetailWrapper,
  VerticalDivider,
} from "./styles";
import { theme } from "../../theme"

/**
 * A component that renders a select menu.
 * @param {object} props - The props object.
 * @param {string} props.title - Title of the card (optional).
 * @param {string} props.width - Width of card (optional)
 * @param {Array<details>} props.details - An array of objects containing number and title keys with string values.
 */

const SplitDetailCard = ({ title, details, width, topHeading}) => {
  return (
    <SplitDetailCardWrapper width={width}>
      <Ptag fontSize="14px" textAlign="center">
        {title}
      </Ptag>
      <SplitDetailWrapper>
        {details.map((detail, index) => (
          <Fragment key={index.toString()}>
            <div>
              <Ptag color={theme.accentColor} fontWeight="600" textAlign="center" fontSize="30px">
                {detail.number}
              </Ptag>
              <Ptag marginTop="8px" fontWeight="700" textAlign="center" fontSize="12px">
                {detail.title}
              </Ptag>
            </div>
            {index !== details.length - 1 && <VerticalDivider />}
          </Fragment>
        ))}
      </SplitDetailWrapper>
      {/* {topHeading && (
        <div style={{ display: "grid", placeItems: "center", marginTop: "1.4rem" }}>
          <SplitCardTag>
            {" "}
            <Ptag
              fontSize="12px"
              fontWeight="400"
              textAlign="center"
              color='#002060'
            >
              {topHeading}
            </Ptag>
          </SplitCardTag>
        </div>
      )} */}
    </SplitDetailCardWrapper>
  );
};

export default SplitDetailCard;
