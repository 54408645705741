import React from 'react'
import { render } from 'react-dom'
import styled from 'styled-components'
import down_arrow from '../../../assets/svgs/down_arrow.svg'
import side_arrow from '../../../assets/svgs/side_arrow.svg'
import UPIContent from './UPIContent.js'
class CollapsibleDiv extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <ParentDiv>
        <Header
          isOpen={this.props.open}
          onClick={() => {
            this.props.togglePanel(this.props.idx)
          }}>
          <HeaderText>{this.props.title} </HeaderText>
          <SideArrow isOpen={this.props.open} src={this.props.open ? down_arrow : side_arrow} />
        </Header>
        {this.props.open ? (
          <UPIContent 
            route={this.props.route}
            title={this.props.title}
            onPay={values => this.props.onPay(values)}
            premium={this.props.premium}
            discount={this.props.discount}
            upi={this.props.upi}
          />
        ) : null}
      </ParentDiv>
    )
  }
}

const ParentDiv = styled.div`
  display:flex;
  flex-direction:column
  //margin: 11px 16px 11px 16px;
  margin-bottom: 24px;
  border-radius: 7px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #436ab2;
  overflow: hidden;
`

const Header = styled.div`
  display: flex;
  height: 57px;
  flex-direction: row;
  cursor: pointer;
  padding: 18px;
  color: #fff;
  font-family: Avenir;
  justify-content: space-between;
`
const HeaderText = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 1.14px;
  text-align: left;
  color: #4a4a4a;s
`

const Content = styled.div`
  cursor: pointer;
  border-left: solid 1px #f2f2f2;
  border-right: solid 1px #f2f2f2;
  border-bottom: solid 1px #f2f2f2;
  border-radius: 0 0 5px 5px;
  padding: 15px;
  font-family: verdana;
  font-size: 14px;
`

const SideArrow = styled.img`
  max-width: 24px;
  max-height: 24px;
`
export default CollapsibleDiv
