import React from 'react'

const nb_banks_name = [
  {
    bank_name: 'ICICI Bank',
    title: 'ICICI',
    image: require('../../../assets/images/bank/icici.svg')
  },

  {
    bank_name: 'Kotak Mahindra Bank',
    title: 'KOTAK',
    image: require('../../../assets/images/bank/kotak.svg')
  },
  {
    bank_name: 'Axis Bank',
    title: 'AXIS',
    image: require('../../../assets/images/bank/axis.svg')
  },
  {
    bank_name: 'HDFC Bank',
    title: 'HDFC',
    image: require('../../../assets/images/bank/hdfc.svg')
  },
  {
    bank_name: 'State Bank of India',
    title: 'SBI',
    image: require('../../../assets/images/bank/sbi.png')
  },
  {
    bank_name: 'More Banks',
    title: 'More Banks',
    image: require('../../../assets/images/bank/others.svg')
  }
 
]

export default nb_banks_name
