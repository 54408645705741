import React, { Component,useState, useEffect } from 'react'
import styled from 'styled-components'
import { OverlayLoader } from '../../components'
import http from '../../services/httpService'
import { Header } from '../../components/business'
import { Ptag } from '../../components/fonts'
import backarrow from '../../assets/images/arrowBack.png'
import debitlogo from '../../assets/images/red_down_arrow.png'
import creditlogo from '../../assets/images/green_up_arrow.png'
import { withRouter } from 'react-router'
import Icons from '../../components/icons'
import { formatAmountWithDecimal } from '../../utils/utils'

const CdAccount = () =>{
    const [loading, setLoading] = useState(false)
    const [ledgerTable, setLedgerTable] = useState([])
    const [showCdLedger, setShowLedger] = useState(false)
    const handleChange = () => {
      if (showCdLedger == true){
        setShowLedger(false)
      } else {
        setShowLedger(true)
      }
    }

    let table_data = []
    const getData =  async () => {
        setLoading(true)
        const userplan_id = localStorage.getItem('userplan_id') || sessionStorage.getItem('userplan_id') || ''
          try{
            const {data} = await http.get(`/business/view_cd_balance/`,{
                params :{
                  userplan_id:userplan_id
                } 
            })
            

            table_data = [...table_data,...data]
            setLedgerTable(table_data)
            setLoading(false)
          } catch(ex){
            console.log({ex})
            setLoading(false)
          }
    } 
    const getLastUpdatedDate = (tableDate) => {
      let updated_date = tableDate && tableDate.length > 0 && (tableDate[0]['transaction_date_show'])
      return(
        updated_date || ''
      )
    }

    const getUpdatedAmount = (tableDate) => {
      return(
        (tableDate && tableDate.length > 0 && formatAmountWithDecimal(tableDate[0]['updated_balance'])) || 0
      )
    }

    useEffect(() => {
        getData()
      },[])

    const lastUpdatedCd = () => {
      return (
          // <Root>
              <Body>
                <Ptag fontSize='24px' fontWeight='600' marginTop='12px' letterSpacing='0.58px' color='#4a4a4a'>Cash Deposit Account</Ptag>
                <Ptag fontSize='14px' fontWeight='500' marginTop='24px' letterSpacing='0.58px' color='#4a4a4a'>A mandatory non-interest bearing CD account is created to deposit all premium payments and manage insurance related transactions with the insurer.</Ptag>
                <Ptag fontSize='14px' fontWeight='600' marginTop='4px' letterSpacing='0.58px' color='#4a4a4a'>View your balance and transaction history here.</Ptag>
                <SummaryBox style={{marginTop:'50px'}}>
                        <BoxTop>
                            <Ptag fontSize='16px'  fontWeight='600' letterSpacing='0.6px' paddingLeft='10px' marginTop='5px' marginLeft='11px' color='#4a4a4a'>Balance</Ptag>
                            {ledgerTable && ledgerTable.length > 0 &&
                              <Ptag fontSize='12px'  paddingLeft='10px' fontWeight='400' letterSpacing='0.6px'  marginLeft='11px'  color='#4a4a4a'>Last updated on {getLastUpdatedDate(ledgerTable).substring(0,10) || '-'}</Ptag>
                            }<Ptag fontSize='36px'  paddingLeft='10px' fontWeight='500' paddingTop='10px' letterSpacing='0.6px' marginLeft='11px' marginTop='20px' marginBottom='10px' color='#4a4a4a'>₹{getUpdatedAmount(ledgerTable)}</Ptag>
                        </BoxTop>
                        <TransactionDiv></TransactionDiv>
                        <BoxButton2 id='ledgerShow' onClick={handleChange}>
                            <Ptag width='100%' height='100%' marginLeft='20px' fontSize='16px'>Transaction History</Ptag>
                            <Icons style={{ 'marginTop': '15px' }} icon='chevron_right' fill="#7e7b7f" />
                        </BoxButton2>
                </SummaryBox>
              </Body>
              
          // </Root>
      )
    }


    const getImg = (transaction_status) => {
      return(
          (transaction_status === 'Credit')? 
          <img style={{ 'height': '10px','marginRight':'5px','textAlign':'top','paddingTop':'0'}} src={creditlogo} height='20px' width='17px' />:
          <img style={{ 'height': '10px','marginRight':'5px','textAlign':'top','paddingTop':'0'}} src={debitlogo} height='20px' width='17px' />
      )
    }

    const getAddDel = (transaction_status) => {
      return(
          (transaction_status === 'Credit')?  '+':'-'         
      )
    }

    const renderLedgerTable = () => {
      return(
        <Table>
          <tbody>
            {ledgerTable && ledgerTable.map((individualRow, index) => (
              <tr style={{ borderBottom: "#b01313", height: 48 }}>
                <td><ImgHeader>{getImg(individualRow.transaction_type)}</ImgHeader></td> 
                <td>
                  <OuterHeader>
                  <EmployeeHeader>
                    <Ptag paddingLeft='10px' fontWeight='600' minWidth='80%'>{individualRow.action_show}</Ptag>
                    <Ptag paddingLeft='10px'>{(individualRow.transaction_date_show)}</Ptag>
                  </EmployeeHeader>
                  </OuterHeader>
                </td>   
                <td>
                  <TopHeader>
                    <Ptag paddingLeft='10px' fontSize='14px' fontWeight='600' maxLength='80%'>{getAddDel(individualRow.transaction_type)} ₹{formatAmountWithDecimal(individualRow.transacted_balance)}</Ptag>
                    <Ptag paddingLeft='10px' fontSize='12px' fontWeight='400'>Balance ₹{formatAmountWithDecimal(individualRow.updated_balance)}</Ptag>
                  </TopHeader>
                </td> 
                {/* <LongLine></LongLine>          */}
             </tr>
            ))
            }
          </tbody>
        </Table>
      )
    }

    const renderNoHistoryMessage = () =>{
      return(
        <MsgTag>
        <MessageWrapper>
          <Text2>Currently, we do not have any transaction history regarding your CD account.</Text2>
          <Text2>If you have made any recent transactions, please reach out to your Account Manager at Vital to get the same updated here.</Text2>
        </MessageWrapper>
        </MsgTag>
      )
    }

    const cdLedger = () => {
      return (
            <Body>
                <BackButton onClick={handleChange}>
                    <img style={{ 'marginRight': '10px' }} src={backarrow} height='20px' width='17px' />
                    <Ptag fontSize='15px'>Back</Ptag>
                </BackButton>
                <Ptag fontSize='25px' fontWeight='500' marginLeft='30px' marginTop='30px' marginButtom='30px'>Transaction History</Ptag>
                <LongLine></LongLine>
                {/* {renderLedgerRow()} */}
                {!!ledgerTable.length ? renderLedgerTable(): renderNoHistoryMessage()}
            </Body>
      )
    }

    return (
        <>
          <Root>
            {loading && <OverlayLoader/>}
            {!showCdLedger && lastUpdatedCd()}
            {showCdLedger && cdLedger()}
          </Root>
           
        </>
    )
}
// }

const Root = styled.div`
	width: 100%;
	background-color: #ffffff;
  display: flex;
  flex-direction: column;
 
`
const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    flex: 1;
`
const MsgTag = styled.div`
   justify-content: center;
`
const MessageWrapper = styled.div`
  transition: margin-left .2s ease-in;
  padding: 50px 80px;
  padding-bottom: 174px;
  box-sizing: border-box;
  background:#fff;
  height:58vh;
  text-align: center;
  /* margin-left: 200px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`


const TopHeader = styled.div`
    min-width:100px;
    display: flex;
    flex-direction: column;
    padding-top: 22px;
    padding-bottom: 22px;
    justify-content: center;
   
`
const EmployeeHeader = styled.div`
    max-width:200px;
    display: flex;
    flex-direction: column;
    padding-top: 22px;
    padding-bottom: 22px;
    justify-content: center;
   
`
const OuterHeader = styled.div`
  min-width: 600px;
`

const Table = styled.table`
    display: block;
    /* border-bottom: 1px solid #828080; */
    max-width: -moz-fit-content;
    max-width: fit-content;
    padding-left: 25px;
    /* border-collapse: collapse; */
`
const Text2 = styled.p`
    font-weight: 400;
    font-size: 18px;
    color: #4a4a4a;
    font-family: 'Avenir';
`

const LedgerRow = styled.div`
  display: flex;
  flex-direction: row;
  height:20px;
  margin-top: 20px;
  /* margin-left: 30px; */
`

const TransactionDiv = styled.div`
    border-bottom: 1px solid #436AB2;
` 

const BoxTop = styled.div`
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 12px;
    background-color: #F9FCFF;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
`
const BackButton = styled.button`
    padding-top: 15px;
    margin-left: 20px;
    cursor: pointer;
    width: 12%;
    padding-left: 10px;
    background-color: white;
    border: 0px;
    text-align: center;
    display: flex;
    flex-direction: row;
`

const BoxButton2 = styled.button`
  display: flex;
  flex-direction:row;
  align-items: center;
  justify-content: space-between; 
  width: 100%;
  height: 72px;
  border: 0px;
  background-color: white;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  padding-right: 16px;
  border-radius: 6px;
`


const SummaryBox = styled.div`
    border:1px solid #436AB2;
    border-radius: 5px;
    width:40%;
    min-width:514px;
    /* min-height: 220px; */
`
const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 311px;
  align-items: flex-end;
  margin-top:60px;
`
const ImgHeader = styled.div`
    padding-bottom: 10px;
    text-align:top;
    border: 0px;
`

const LongLine = styled.div`
      border-bottom: 1px solid #828080;
      padding-top: 10px;
      margin-left: 25px
`






export default withRouter(CdAccount)