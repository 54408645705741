import React from 'react'
import { render } from 'react-dom'
import styled from 'styled-components'
import visa from '../../../assets/images/visa.png'
import master_card from '../../../assets/images/master_card.png'
import { CardMode, PaymentMethod, SubscriptionPlan } from '../constants/Payment'

export default class CreditCardContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      credit_bank_branches: []
    }
  }

  render() {
    return (
      <ContentDiv>
        <div style={{'paddingBottom':'24px'}}>
        {!this.props.upi && 
        <div>
          <Text3>Pay using Credit Card</Text3>
          <Image
            src={visa}
            style={{ position: 'absolute', top: '5px', right: '0', 'marginTop': '24px', 'marginRight': '78px' }}
          />
          <Image
            src={master_card}
            style={{ position: 'absolute', top: '0', right: '0', 'marginTop': '24px', 'marginRight': '18px' }}
          />
          <LineDiv />
        </div>
        }
        </div>

        
        <Button
          id='upi_pay_securely'
          onClick={() => {
            // window.gtag('event', 'click', {
            //   event_category: '/pay',
            //   event_label: 'paySecurely'
            // })

            this.props.onPay({
              method: this.props.upi ? PaymentMethod.UPI : PaymentMethod.CARD,
              plan: this.props.upi ? SubscriptionPlan.RECURRING_WITH_UPI : SubscriptionPlan.RECURRING_WITH_CARD,
              payment_mode:  !this.props.upi  && CardMode.CREDIT_CARD
            })
          }}>
          <Text>PAY SECURELY</Text>
        </Button>
      </ContentDiv>
    )
  }
}

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-top: solid 1px #9b9b9b;
`

const Text = styled.p`
  font-family: Avenir;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 1px;
`

const Text2 = styled.p`
  font-family: Avenir;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0.38px;
  text-align: left;
  color: #707070;
  padding-left: 18px;
  padding-right: 18px;
`

const Text3 = styled.p`
  object-fit: contain;
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 1px;
  text-align: left;
  color: #4a4a4a;
  margin: 27px 0px 27px 0px;
  padding-left: 18px;
`
const Button = styled.div`
  display: flex;
  text-align: center;
  margin: 12px 18px 24px 18px;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  height: 54px;
  border-radius: 15px;
  background-color: #436ab2;
`
const Image = styled.img`
  position: absolute;
  max-width: 50px;
  max-height: 28px;
`
const LineDiv = styled.div`
  display: flex;
  margin-top: 14px;
  height: 1px;
  border-radius: 7px;
  margin: 0px 20px 0px 20px;
`
