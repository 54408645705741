import styled, { keyframes } from 'styled-components'
import React, {Component} from 'react';
import LoadingIndicator from '../assets/images/lottie/loadingIndicator.json'
import Lottie from 'react-lottie';

export default class BusyIndicator extends Component{
	render(){
		const defaultOptions = {
			loop: true,
			autoplay: true, 
			animationData: LoadingIndicator,
			rendererSettings: {
			  preserveAspectRatio: 'xMidYMid slice'
			}
		  };
		return (<div>
			<Lottie options={defaultOptions}
              height={140}
              width={140}/>
		</div>)
	}
}

