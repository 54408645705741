import React, { Component } from "react";
import styled from "styled-components";
import { formatAmount } from "../utils/utils";
import policy_card_bg from "../assets/svgs/policyCard.svg";
import avatarMen from "../assets/svgs/avatarMen.svg";
import {PolicyMappings} from './memberApp/constants/PolicyConstants'

const PolicyWrapper = styled.div`
  width: 34.5rem;
`;
const PolicyDetail = styled.div`
  width: 100%;
  // background-color: #436ab2;
  background: url(${policy_card_bg});
  background-repeat: no-repeat;
  background-position: top left;
  border-radius: 15px 15px 0 0;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  padding: 1.8rem 2.4rem 2.6rem;

  display: flex;
`;
const Column1 = styled.div`
  width: 100%;
  flex: 2;
  // flex-grow: 2;
  // flex-basis: 0;

  p:nth-child(2) {
    margin: 3px 0 3.6rem 0;
  }
`;
const Column2 = styled.div`
  width: 100%;
  display: flex;
  // align-content: space-around
  flex-wrap: wrap;
  flex: 1;
  // flex-grow: 1;
  // flex-basis: 0;

  div {
    margin-right: 0.8rem;
  }
`;

const MemberWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    // text-align: center;
    margin-top: 0.6rem;
  }
`;

const PolicyPremium = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 1.8rem 3rem 0.8rem 1.8rem;
  // padding: 1.4rem 2.4rem 2rem;
  border-radius: 0 0 15px 15px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
`;
const Wrapper = styled.div`
  p:first-child {
    margin-bottom: 1rem;
  }
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-end;
`;

const Font8 = styled.p`
  font-family: Roboto;
  font-size: 8px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
`;

const Font10 = styled.p`
  font-family: Avenir;
  font-size: 1rem;
  font-weight: ${(props) => (props.fontWt ? props.fontWt : "normal")};
  font-style: normal;
  font-stretch: normal;
  line-height: ${(props) => (props.lineHt ? props.lineHt : "1.4")};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : "0.11px"};
  text-align: left;
  color: ${(props) => (props.color ? props.color : "#ffffff")};
`;


const Font16 = styled.p`
  font-family: Avenir;
  font-size: 1.6rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 2px;
  text-align: left;
  color: #ffffff;
`;
const Font20 = styled.p`
  font-family: Avenir;
  font-size: 2rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: ${(props) => (props.lineHt ? props.lineHt : "1.35")};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : "1px"};
  color: ${(props) => (props.color ? props.color : "#ffffff")};
`;

const Font24 = styled.p`
  font-family: Avenir;
  font-size: 2.4rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : "normal")};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : "normal"};
  color: ${(props) => (props.color ? props.color : "#ffffff")};
`;

const PolicyCard = (props) => {
  return (
    <PolicyWrapper>
      <PolicyDetail>
        <Column1>
          <Font16>{PolicyMappings['COMPREHENSIVE']['name']}</Font16>
          {/* <Font9>Cover all Hospitalization expenses</Font9> */}
          <Font10>Cover all Hospitalization expenses</Font10>
          <Font10 lineHt="2.4" letterSpacing="1px">
            {props.policyData.planType} PLAN
          </Font10>
          <Font20>₹{formatAmount(props.policyData.insured_sum)}</Font20>
        </Column1>
        <Column2>
          {props.policyData.familyMembersDetails &&
            props.policyData.familyMembersDetails.map((member) => (
              <MemberWrapper key={member.id}>
                <img src={avatarMen} alt="avatarMen.png" />
                <Font8>{member.name}</Font8>
              </MemberWrapper>
            ))}
        </Column2>
      </PolicyDetail>
      <PolicyPremium>
        <Wrapper>
          <Font10 fontWt="600" lineHt="1.9" letterSpacing="1px" color="#4a4a4a">
            PREMIUM
          </Font10>
          <Font20 lineHt="1.9" letterSpacing="1px" color="#4a4a4a">
            ₹{formatAmount(props.policyData.premium)}
            /month
          </Font20>
        </Wrapper>

        <Wrapper>
          <Font10 fontWt="600" lineHt="1.9" letterSpacing="1px" color="#4a4a4a">
            VALID UP TO
          </Font10>
          <Font20 lineHt="1.9" letterSpacing="1px" color="#4a4a4a">
            {props.policyData.validUpto}
          </Font20>
        </Wrapper>
      </PolicyPremium>
    </PolicyWrapper>
  );
};

export default PolicyCard;
