import React, { Component } from 'react'
import styled from 'styled-components'
import circlepage3 from '../assets/images/circlepage3.png'
import thumbpage3 from '../assets/images/thumbpage3.png'

const Wrapper = styled.div`
  width: 100%;
  background-color: #eeeeee;
  position: relative;

  @media (min-width: 1024px) {
    // height: 59.6rem;
  }
`
const Content = styled.p`
  font-family: Proxima Nova;
  font-size: 2.4rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.24px;
  text-align: left;
  color: #4a4a4a;
  padding: 12.2rem 3.2rem;

  @media (min-width: 1024px) {
    font-size: 3.4rem;
    line-height: 1.59;
    letter-spacing: 0.02px;
    text-align: center;
    padding: 8rem 4.8rem;
  }

  @media (min-width: 1680px) {
    font-size: 6.2rem;
    line-height: 1.61;
    letter-spacing: 0.03px;
    text-align: center;
    padding: 20rem 11.8rem;
  }
`

const Section = styled.div`
  width: 100%;
  height: 9.25rem;
  display: flex;
  flex-direction: row;
  display: -webkit-flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  position: absolute;
  margin-top: 29.9rem;
  z-index: 1;
`

const Imagethumb = styled.div`
  width: 4.32rem;
  height: 6.97rem;
  background-image: url(${thumbpage3});
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 19rem;

  @media (min-width: 1024px) {
    height: 10rem;
    width: 9.81rem;
    top: 11rem;
  }
`
const Imagecircles = styled.div`
  width: 4.57rem;
  height: 9.24rem;
  background-image: url(${circlepage3});
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 6.5rem;

  @media (min-width: 1024px) {
    background-image: url(${circlepage3});
    height: 5.81rem;
    width: 5.47rem;
    top: 23.7rem;
  }
`

export default class Page11 extends Component {
  render = () => {
    return (
      <Wrapper>
        <Content>Enjoy the new age health coverage - with rewards, smart insurance plans and a lot more care.</Content>
        {/* <Section>
          <Imagethumb />
          <Imagecircles />
        </Section> */}
      </Wrapper>
    )
  }
}
