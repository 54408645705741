import React from 'react'
import styled from 'styled-components'
import { Ptag } from './fonts'
import { gtmCode } from '../utils/utils'
import {withRouter} from 'react-router'
import TagManager from 'react-gtm-module'
import {sendActivityLSApi} from '../services/leadSquaredService'


const MarketPlaceOptions = props => {
    const onClickOptionTile = tile => {
      sendActivityLSApi(232,{
        mx_Custom_1: tile['display_name'],
      })
    let display_name_passed = 'gvMP_' + tile && tile.key
    const tagManagerArgs = {
      gtmId: gtmCode(),
      dataLayer: {
        event: 'gvMP_Whatareyoulookingfortoday',
        gvMP_Whatareyoulookingfortoday: display_name_passed
      }
    }
    TagManager.initialize(tagManagerArgs)
    localStorage.setItem('card_display_name', tile && tile.display_name)
    localStorage.setItem('card_type', tile && tile.request_param)
    localStorage.setItem('sub_categories', tile && tile.sub_categories)
    localStorage.setItem('filter', tile && tile.filters)
    props.history.push('/marketplaceoffers')
  }
  return (
    <Wrapper hideMarginBottom={props.forCreditSuggestion}>
      {props &&
        props.card_data &&
        props.card_data.map(tile => (
          <Tile key={`MarketPlaceOption_${tile.display_name}`} onClick={() => onClickOptionTile(tile)}>
            <Image src={tile.logo} height={'36px'} width={'36px'} />
            <Ptag textAlign="center">{tile.display_name}</Ptag>
          </Tile>
        ))}
    </Wrapper>
  )
}

export default withRouter(MarketPlaceOptions)

const Wrapper = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 12px 0 36px 24px;
  ::-webkit-scrollbar {
    display: none;
  }
`

const Tile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  // margin-right: 14px;
  // margin-bottom: 8px;
  // margin-top: 8px;
  margin-right: 14px;
    margin-left: 3px;
  border-radius: 10px;
  width: 100px;
  height: 100px;
  min-width: 100px;
  cursor: pointer;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
`

const Image = styled.img`
  align-self: center;
  margin-bottom: 8px;
  width:auto !important;
`