import React, { Component } from 'react'
import styled from 'styled-components'
import {Ptag} from '../../components/fonts'
import {OverlayLoader} from '../../components'
import http from '../../services/httpService'
import {Header} from '../../components/business'


export default class Marketplace extends Component{

    
    constructor(props){
        super(props)
        this.state = {
            loading:true,
            tiles: [],
            navigationContext: localStorage.getItem('navigatingFrom')
        }
    }

    async componentDidMount(){
        const {data} = await http.get(`/dashboard/categories/${JSON.parse(localStorage.getItem('about'))['email']}/`)
        this.setState({loading: false, tiles: data['categories']})
    }

    onClickOptionTile = tile => {
        localStorage.setItem('card_type', tile && tile['request_param'])
        localStorage.setItem('sub_categories', tile && tile['sub_categories']);
        localStorage.setItem('filter',tile && tile['filters']);
        this.props.history.push('/business/marketplaceoffers')
      }

    render(){
        return (
            <Root>
                <Header onClickBack={()=> this.props.history.push(`/business/${this.state.navigationContext ? this.state.navigationContext : "dashboard"}`)}/>
                <Body>
                    <Ptag fontSize='20px' fontWeight='600' letterSpacing='0.5px'>
                        {'Marketplace'}
                    </Ptag>
                    <Ptag letterHeight='1.67' letterSpacing='0.5px'>
                        {'Exclusive packages and offers from leading providers'}
                    </Ptag>
                    <Ptag style={{'margin' : '24px 0'}} fontWeight='600' fontSize='16px' letterSpacing='0.4px'>
                        {'Select a category'}
                    </Ptag>
                    <Wrapper>
                        {
                            this.state.tiles.map((tile, index) => (
                                <Tile key={index} onClick={() => this.onClickOptionTile(tile)}>
                                    <Image src={tile['logo']} height='36px' width='36px' />
                                    <Ptag fontWeight='600'  textAlign='center'>
                                        {tile['display_name']}
                                    </Ptag>
                                </Tile>
                            ))
                        }
                        
                    </Wrapper>
                </Body>
                
                {this.state.loading && <OverlayLoader />}
            </Root>
        )
    }
}

const Root = styled.div`
    display: flex;
    flex-direction: column;
`;

const Body =styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
`

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-right : -14px;
    & > div{
        margin: 0 12px 18px 0;
    }    
`;

const Tile = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 14px;
    margin-bottom: 8px;
    & > img{
        margin: 2px auto;
    }   
    width: 100px;
    height: 100px;
    border-radius: 10px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
`;

const Image = styled.img`
    align-self:center;
    width:auto !important;
`