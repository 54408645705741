import React, { Component } from "react";
import styled from "styled-components";
import {Ptag} from '../fonts'



const MarketplacePopup = ({onPressOkay, showPopup, text = 'This is a business account so these offers can only be viewed. Your employees can purchase them from their account.'}) => {

    return(
        <Wrapper show={showPopup}>
            <Body show={showPopup}>
            <Message>
                <Ptag textAlign='center' fontSize='16px' letterHeight='1.5' letterSpacing='0.25px'>
                    {text}
                </Ptag>
                
            </Message>
            <Okay onClick={onPressOkay}>
                <Ptag color='#436ab2' textAlign='center' fontWeight='600' fontSize='16px' letterHeight='1.5' letterSpacing='0.25px'>
                    {'OKAY'}
                </Ptag>
            </Okay>
                
            </Body>
        </Wrapper>
        
    )
}

export default MarketplacePopup


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    padding: 24px;
    background: #00000080;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    transform: ${props => (props.show === true ? 'translateY(0) scaleY(100%)' : 'translateY(102%) scaleY(0)')};
    transition: all 0.6s;
`
const Body = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 15px;
`

const Message = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 18px;
`

const Okay = styled.div`
    display: flex;
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    padding: 12px;
    justify-content: center;
    cursor: pointer;
`;