import React from 'react'

export default class Icon extends React.Component {
  render() {
    const { fill, width, height, icon } = this.props
    const svg = {
        checkMark : (
            <svg xmlns="http://www.w3.org/2000/svg" width={width || '20'} height={height || '20'} viewBox="0 0 20 20">
                <g fill="none" fillRule="evenodd">
                    <path fill={fill || '#436ab2'} stroke="#FFF" strokeWidth="2" d="M9 18c4.97 0 9-4.03 9-9s-4.03-9-9-9-9 4.03-9 9 4.03 9 9 9z" transform="translate(1 1)"/>
                    <path fill="#FFF" d="M11.62 6.569c.259-.258.678-.258.937 0 .258.258.258.678 0 .936l-3.972 3.97c-.258.259-.677.259-.935 0L5.443 9.269c-.257-.258-.257-.677 0-.936.26-.258.679-.258.937 0l1.738 1.739 3.502-3.503z" transform="translate(1 1)"/>
                </g>
            </svg>
        )
        
    }
    return svg[icon] || <div />
  }
}