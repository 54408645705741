import React, { Component, useEffect, useState } from 'react'
import styled from 'styled-components'
import NeoInsuranceLogo from '../../assets/svgs/neo_insurance_white.svg'
import VitalLogo from "../../assets/images/vitalLogoCopy.png";
import { Ptag } from '../fonts'
import { useHistory, useLocation } from 'react-router-dom'
import http from '../../services/httpService'
import popupcrossbutton from '../../assets/images/popupcrossbutton.png'
import { useMemo } from 'react'
import { colors, theme } from "../../theme" 
const EmployerDesktop = (Component) => {

    const { pathname } = useLocation()
    const currentRoute = pathname && pathname.replace('/', '')
    const isEmployerDashboardNew = ["business/employee-registration-dashboard"].includes(currentRoute);

    return () => {
        const [company_name, setCompanyName] = useState('')
        const [addEmp, setAddEmp] = useState(false)
        const [renderAddEmp, setRenderAddEmp] = useState(false)
        const [addEmpNoti, setAddEmpNoti] = useState('')
        const [sboType, setSboType] = useState('')

        useEffect(() => {
            (async function () {
                try {
                    const { data } = await http.get(`/business/userplans/${localStorage.getItem('userplan_id')}/`);
                    setCompanyName(data.company_name || '')
                    if (data['sbo_type']) {
                        setSboType(data['sbo_type'])
                        sessionStorage.setItem('sboType', data['sbo_type']);
                        localStorage.setItem('flow', data['sbo_type'])
                    }
                    if (data && data['insurer'] && data['insurer']['name']) {
                        localStorage.setItem('insurer', data['insurer']['name'])
                    }
                    localStorage.setItem('cname', (data.company_name || 'Details'))
                    setAddEmp(data.add_employee_button_info['add_employee_off'] || false)
                    setAddEmpNoti(data.add_employee_button_info.notification || '')
                } catch (err) {
                }
            })();
        }, []);
        const history = useHistory()

        const goToPayments = () => {
            if (localStorage.getItem('employee_details')) {
                history.push('/business/paymentsummary')
            }
        }
        const goToGmcPayments = () => {
            history.push('/business/gmcpaymentsummary')
        }

        const goToEnrolmentHistory = () => {
            history.push('/business/enrolment-history')
        }

        const goToCd = () => {
            history.push('/business/view_cd_balance')
        }

        const goToDashboard = () => {
            history.push('/business/dashboard')
        }

        const addEmployess = () => {
            if (addEmp === true) {
                setRenderAddEmp(true)
            }
            else {
                history.push('/business/uploadExcel')
            }

        }

        const logout = () => {
            localStorage.clear()
            history.push('/business/phone')
            // window.location.href = '/group-health-plan/' removed because landing pages are not working now
        }

        const changeRenderAddEmp = () => {
            setRenderAddEmp(false)
        }

        const isGmc = useMemo(() => {
            let sb = sboType;
            if(sessionStorage.getItem("sboType")) sb = sessionStorage.getItem("sboType");
            return sb === 'GMC' || sb === 'GMC_M' || sb === 'GPA' || sb === 'GTL' || localStorage.getItem('isFlex');
        }, [sboType])
        
        const flow = localStorage.getItem('flow')

        return (
            <Home>
                <HomeWrapperLeft>
                    <LogoWrapper>
                        <img src={VitalLogo} />
                    </LogoWrapper>
                    <LogoBorder />

                    <MenuBox>
                        {!isEmployerDashboardNew && (
                            <>
                                <Box onClick={goToDashboard}>
                                    <Ptag color={currentRoute.indexOf('dashboard') > -1 ? '#000' : '#393939'} fontWeight='500' fontSize='18px' letterSpacing='0.6px' >Overview</Ptag>
                                </Box>
                                {
                                    isGmc &&
                                    <Box onClick={goToCd} >
                                        <Ptag color={currentRoute.indexOf('cd') > -1 ? '#000' : '#696969'} fontWeight='500' fontSize='18px' letterSpacing='0.6px'>CD Account</Ptag>
                                    </Box>
                                }
                                {
                                    isGmc &&
                                    <Box onClick={goToEnrolmentHistory} >
                                        <Ptag color={currentRoute.indexOf('enrolment') > -1 ? '#000' : '#696969'} fontWeight='500' fontSize='18px' letterSpacing='0.6px'>Enrolment History</Ptag>
                                    </Box>
                                }

                                {
                                    !isGmc &&
                                    <Box onClick={goToPayments} >
                                        <Ptag color={currentRoute.indexOf('paymentsummary') > -1 ? '#000' : '#696969'} fontWeight='500' fontSize='18px' letterSpacing='0.6px'>Payments</Ptag>
                                    </Box>
                                }
                                {
                                    isGmc &&
                                    <Box onClick={goToGmcPayments} >
                                        <Ptag color={currentRoute.indexOf('gmcpaymentsummary') > -1 ? '#000' : '#696969'} fontWeight='500' fontSize='18px' letterSpacing='0.6px'>Payments</Ptag>
                                    </Box>
                                }

                                {/* {
                                    !isGmc &&
                                    <Box onClick={addEmployess} >
                                        <Ptag color={currentRoute.indexOf('uploadExcel') > -1 ? '#000' : '#696969'} fontWeight='500' fontSize='18px' letterSpacing='0.6px'>Add Employees</Ptag>
                                    </Box>
                                } */}
                            </>
                        )
                        }

                        <Box onClick={logout}  >
                            <Ptag color={'#696969'} fontWeight='600' fontSize='18px' letterSpacing='0.6px'>Logout</Ptag>
                        </Box>
                        {/* <Ptag marginTop="54px" fontSize='14px'>Profile</Ptag> */}
                    </MenuBox>
                </HomeWrapperLeft>
                <HomeWrapperRight>
                    {
                        renderAddEmp &&
                        <UploadExcelPopUpDiv>
                            <Innerpopup  >
                                <SubInnerDiv>
                                    <FirstRow>
                                        <Ptag fontSize='22px' fontWeight='bold'>
                                            {addEmpNoti}
                                        </Ptag>
                                        <CrossButton onClick={changeRenderAddEmp}>
                                            <img style={{ 'cursor': 'pointer' }} src={popupcrossbutton} width="24px" height="24px" />
                                        </CrossButton>
                                    </FirstRow>
                                    <SecondSubRow>
                                    </SecondSubRow>

                                </SubInnerDiv>
                            </Innerpopup>
                        </UploadExcelPopUpDiv>
                    }
                    <UpperHomeWrapperRight>
                        <MainDiv>
                            <WelcomeLeft>
                                <Ptag fontSize='24px' fontWeight='600' letterSpacing='0.6px' color={theme.accentColor} letterHeight='1.13'>{(localStorage.getItem('user_plan_details') && JSON.parse(localStorage.getItem('user_plan_details')).company_name) || (company_name && company_name) || "Hello"} </Ptag>
                                <Ptag fontSize='14px' marginTop='12px' letterSpacing='0.58px'>Welcome to Vital</Ptag>
                            </WelcomeLeft>
                        </MainDiv>
                    </UpperHomeWrapperRight>
                    <Component />
                </HomeWrapperRight>
            </Home>
        )
    }

}

export default EmployerDesktop

const Home = styled.div`
    width: 100%;
    height:100%;
    margin:0px;
    padding:0px;
`
const HomeWrapperLeft = styled.div`
    display:flex;
    flex-direction: column;
    background: rgb(220, 225, 238);
    position: fixed;
    width: 20%;
    padding-top: 4.4rem;
    height: 100%;
    box-sizing: border-box;
    /* padding: 4.4rem 4.4rem 2.4rem 2.4rem; */
    transition: width .2s ease-in;    
`
const Innerpopup = styled.div`
  position: absolute;
  left: 25%;
  right: 25%;
  top: 5%;
  bottom: 5%;
  margin: auto;

`
const SubInnerDiv = styled.div`
    width:100%;
    display:flex;
    flex-direction:column;
    padding:50px 50px 190px 50px;
    background: #ffffff;
    border-radius: 10px;
`
const FirstRow = styled.div`
    justify-content: space-between;
    display: flex;
`
const CrossButton = styled.p`
 
`
const UploadExcelPopUpDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1200;
`

const SecondSubRow = styled.div`
    padding-top:10px;

`

const MenuBox = styled.div`
    display:flex;
    flex-direction : column;
    justify-content:center;
    margin-top: 6rem;
`

const Box = styled.div`
   cursor: pointer;
    padding: 20px 5rem;
    text-decoration: none;
    transition: 0.2s ease-in-out all;
	&:hover {
		background-color: #9da5bb;
	}
    & p {
        transition: 0.2s ease-in-out all;
    }
    &:hover p {
        transform: translateX(7px);
    }
`

const LogoWrapper = styled.div`
    width:100%;
    text-align: center;
    & > img {
        width: 120px;
        max-width: 240px;
    }
`

const HomeWrapperRight = styled.div`
	margin-left: 20%;
	width:80%;
	height:100%;
	/* overflow:scroll; */
	display: flex;
	flex-direction: column;
	background-color: #dbdbdb;
`

const UpperHomeWrapperRight = styled.div`
	width:100%;
	transition: margin-left .2s ease-in;
	padding:48px 40px 18px 40px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	background-color: #FFFFFF;
	box-shadow: rgb(0 0 0 / 15%) 0px 1px 4px 0px;
	margin-bottom:12px;
`

const MainDiv = styled.div`
    display: flex;
    text-align: left;
    line-height: 1.5;
    letter-spacing: 0.5px;
    color: #436ab2;
    justify-content:space-between;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
`

const WelcomeLeft = styled.div`
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: 0.6px;
    color: #436ab2;
`

const LogoBorder = styled.div`
    margin: 0 auto;
    width: 70%;
    height: 1px;
    margin-top: 1rem;
    background-color: #E5F7FF;
    opacity: 0.7;
`