import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useMemo } from "react";
import { formatDateReverse, getFileNameFromUrl } from "../../utils/utils";
import {
  Link,
  HeaderRow,
  MaterialTable,
  MaterialTableContainer,
} from "./styles";
import { startCase } from "lodash";
import { Ptag } from "../../components/fonts";

const HEADERS = [
  "Batch Number",
  "Cut-Off Date",
  "Submission Date",
  "Total No. of Employees",
  "Employee Data",
  "Status",
];

const BatchTable = ({ enrolments, isGmcFlow }) => {
  const renderStatus = (status) => {
    return (
      <Ptag style={{ display: "inline" }}>
        {startCase(status.toLowerCase())}
      </Ptag>
    );
  };

  const headers = useMemo(() => {
  if(isGmcFlow) return HEADERS.filter(header => !["Cut-Off Date", "Employee Data"].includes(header));
  return HEADERS;
   }, [isGmcFlow])

  return (
    <TableContainer sx={MaterialTableContainer}>
      <Table aria-label="simple table" sx={MaterialTable}>
        <TableHead>
          <TableRow>
            {headers.map((header, index) => (
              <TableCell
                key={index}
                variant="head"
                align="center"
                sx={HeaderRow}
              >
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {enrolments.map((enrolment, index) => (
            <TableRow
              key={enrolment.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
              }}
            >
              <TableCell align="center" component="th" scope="row">
                <Ptag
                  style={{ display: "inline" }}
                >{`Batch ${enrolments.length - index}`}</Ptag>
              </TableCell>
              {!isGmcFlow && <TableCell align="center">
                <Ptag style={{ display: "inline" }}>
                  {formatDateReverse(enrolment.cutoff_date)}
                </Ptag>
              </TableCell>}
              <TableCell align="center">
                <Ptag style={{ display: "inline" }}>
                  {formatDateReverse(enrolment.submission_date)}
                </Ptag>
              </TableCell>
              <TableCell align="center">
                <Ptag style={{ display: "inline" }}>
                  {isGmcFlow ? enrolment.employees_count : enrolment.cutoff_date_marked_employees_count}
                </Ptag>
              </TableCell>
              {!isGmcFlow && <TableCell align="center">
                <Link href={enrolment.master_file_url}>
                  {getFileNameFromUrl(enrolment.master_file_url)}
                </Link>
              </TableCell>}
              <TableCell align="center">
                {renderStatus(enrolment.enrollment_history_screen_status)}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BatchTable;
