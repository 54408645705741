import React, { Component } from "react";
import styled from "styled-components";
import Loader from './loaderDots'
import {Ptag} from './fonts'


const LoaderWithText = ({text='This might take a few seconds', visible = false}) => {

    return(
        <RootDiv visible={visible}>
              <Loader />
              <Ptag style={{marginTop: '30px'}} fontSize='20px' fontWeight='600' letterHeight='1.6' letterSpacing='0.19px'>
                  {text}
              </Ptag>
            </RootDiv>
    )
}

const RootDiv = styled.div`
    display: ${props => !props.visible ? 'none' : 'flex'};
    width: 100%;
    height: 100vh;
    flex-direction: column;
    padding: 0px 20px 0 20px;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1000;
    background-color: white;
`

export default LoaderWithText