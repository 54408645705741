import React from "react";
import styled from "styled-components";
import { Header } from "../../components";
import Table from "../../assets/images/tandc.png";

import { TermsAndConditionsText } from "./referralTermsAndCondtext";

export default function ReferralTermsAndConditions() {
  return (
    <Root>
      <Header backPage="/business/ambassador" />
      <Body>
        <BodyText>
          <Title style={{ marginBottom: "12px" }}>Terms and Conditions</Title>
          <GreyText>
            {TermsAndConditionsText["PartOne"][0]}
            <Break />
            {TermsAndConditionsText["PartOne"][1]}
          </GreyText>
          <SubHeader>ABOUT THE COMPANY</SubHeader>
          <GreyText>
            {TermsAndConditionsText["PartTwo"]}
            <Break />
            {TermsAndConditionsText["PartThree"][0]}
          </GreyText>
          <SubHeader>1. DEFINITION</SubHeader>
          <GreyText marginLeft="12px" marginTop="24px">
            1. For the purpose of these T&Cs, the following terms shall have the
            meaning assigned to them, unless otherwise specified:
          </GreyText>
          <GreyText marginLeft="48px">
            <ol type="i">
              <li>{TermsAndConditionsText["Definition"][0]}</li>
              <Break />
              <li>{TermsAndConditionsText["Definition"][1]}</li>
              <Break />
              <li>{TermsAndConditionsText["Definition"][2]}</li>
              <Break />
              <li>{TermsAndConditionsText["Definition"][3]}</li>
              <Break />
              <li>{TermsAndConditionsText["Definition"][4]}</li>
              <Break />
              <li>{TermsAndConditionsText["Definition"][5]}</li>
              <Break />
              <li>{TermsAndConditionsText["Definition"][6]}</li>
              <Break />
              <li>{TermsAndConditionsText["Definition"][7]}</li>
              <Break />
              <li>{TermsAndConditionsText["Definition"][8]}</li>
              <Break />
              <li>{TermsAndConditionsText["Definition"][9]}</li>
              <Break />
              <li>{TermsAndConditionsText["Definition"][10]}</li>
              <Break />
              <li>{TermsAndConditionsText["Definition"][11]}</li>
              <Break />
              <li>{TermsAndConditionsText["Definition"][12]}</li>
            </ol>
          </GreyText>
          <GreyText marginLeft="12px" marginTop="24px">
            2. In these T&Cs, where the context requires:
          </GreyText>
          <GreyText marginLeft="48px">
            <ol type="i">
              <li>{TermsAndConditionsText["Definitions"][0]}</li>
              <Break />
              <li>{TermsAndConditionsText["Definitions"][1]}</li>
              <Break />
              <li>{TermsAndConditionsText["Definitions"][2]}</li>
              <Break />
              <li>{TermsAndConditionsText["Definitions"][3]}</li>
              <Break />
              <li>{TermsAndConditionsText["Definitions"][4]}</li>
              <Break />
              <li>{TermsAndConditionsText["Definitions"][5]}</li>
              <Break />
              <li>{TermsAndConditionsText["Definitions"][6]}</li>
            </ol>
          </GreyText>
          <SubHeader>2. APPOINTMENT</SubHeader>
          <GreyText marginLeft="36px" marginTop="24px">
            <ol type="i">
              <li>{TermsAndConditionsText["Appointment"][0]}</li>
              <Break />
              <li>{TermsAndConditionsText["Appointment"][1]}</li>
              <Break />
              <li>{TermsAndConditionsText["Appointment"][2]}</li>
            </ol>
          </GreyText>
          <SubHeader>3. TERM AND TERMINATION:</SubHeader>
          <GreyText marginLeft="36px" marginTop="24px">
            <ol type="i">
              <li>{TermsAndConditionsText["Termination"][0]}</li>
              <Break />
              <li>{TermsAndConditionsText["Termination"][1]}</li>
              <Break />
              <li>{TermsAndConditionsText["Termination"][2]}</li>
              <Break />
              <li>{TermsAndConditionsText["Termination"][3]}</li>
            </ol>
          </GreyText>
          <GreyText marginLeft="48px" marginBottom="0px">
            <ol type="i">
              <li>{TermsAndConditionsText["Termination"][4]}</li>
              <Break />
              <li>{TermsAndConditionsText["Termination"][5]}</li>
              <Break />
              <li>{TermsAndConditionsText["Termination"][6]}</li>
            </ol>
          </GreyText>
          <GreyText marginLeft="36px" marginTop="24px">
            <ol type="i" start="5">
              <li>{TermsAndConditionsText["Termination"][7]}</li>
              <Break />
              <li>{TermsAndConditionsText["Termination"][8]}</li>
            </ol>
          </GreyText>
          <SubHeader>4. SCOPE OF WORK:</SubHeader>
          <GreyText marginLeft="36px" marginTop="24px">
            <ol type="i">
              <li>{TermsAndConditionsText["Work"][0]}</li>
              <Break />
              <li>{TermsAndConditionsText["Work"][1]}</li>
            </ol>
          </GreyText>
          <SubHeader>5. FEES:</SubHeader>
          <GreyText marginLeft="36px" marginTop="24px">
            <ol type="i">
              <li>{TermsAndConditionsText["Fees"][0]}</li>
              <Break />
              <li>{TermsAndConditionsText["Fees"][1]}</li>
              <Break />
              <li>{TermsAndConditionsText["Fees"][2]}</li>
            </ol>
          </GreyText>
          <SubHeader>
            6. OBLIGATIONS AND RESPONSIBILITIES OF SERVICE PROVIDER:
          </SubHeader>
          <GreyText marginBottom="0px" marginLeft="24px">
            The Service Provider hereby covenants that during the Term of these
            T&Cs it shall:
          </GreyText>
          <GreyText marginLeft="36px" marginTop="24px">
            <ol type="i">
              <li>{TermsAndConditionsText["Obligations"][0]}</li>
              <Break />
              <li>{TermsAndConditionsText["Obligations"][1]}</li>
              <Break />
              <li>{TermsAndConditionsText["Obligations"][2]}</li>
              <Break />
              <li>{TermsAndConditionsText["Obligations"][3]}</li>
              <Break />
              <li>{TermsAndConditionsText["Obligations"][4]}</li>
              <Break />
              <li>{TermsAndConditionsText["Obligations"][5]}</li>
              <Break />
              <li>{TermsAndConditionsText["Obligations"][6]}</li>
              <Break />
              <li>{TermsAndConditionsText["Obligations"][7]}</li>
              <Break />
              <li>{TermsAndConditionsText["Obligations"][8]}</li>
              <Break />
              <li>{TermsAndConditionsText["Obligations"][9]}</li>
              <Break />
              <li>{TermsAndConditionsText["Obligations"][10]}</li>
            </ol>
          </GreyText>
          <SubHeader>
            7. OBLIGATIONS AND RESPONSIBILITIES OF THE COMPANY:
          </SubHeader>
          <GreyText marginLeft="36px" marginTop="24px">
            <ol type="i">
              <li>{TermsAndConditionsText["Obligations"][11]}</li>
              <Break />
              <li>{TermsAndConditionsText["Obligations"][12]}</li>
              <Break />
              <li>{TermsAndConditionsText["Obligations"][13]}</li>
            </ol>
          </GreyText>
          <SubHeader>8. REPRESENTATIONS AND WARRANTIES:</SubHeader>
          <GreyText marginLeft="36px" marginTop="24px" marginBottom="12px">
            <ol type="i">
              <li>{TermsAndConditionsText["Warrenties"][0]}</li>
              <Break />
              <li>{TermsAndConditionsText["Warrenties"][1]}</li>
            </ol>
          </GreyText>
          <GreyText marginLeft="48px" marginBottom="12px">
            <ol type="i">
              <li>{TermsAndConditionsText["Warrenties"][2]}</li>
              <Break />
              <li>{TermsAndConditionsText["Warrenties"][3]}</li>
              <Break />
              <li>{TermsAndConditionsText["Warrenties"][4]}</li>
            </ol>
          </GreyText>
          <GreyText marginLeft="36px" marginTop="0px">
            <ol type="i" start="3">
              <li>{TermsAndConditionsText["Warrenties"][5]}</li>
            </ol>
          </GreyText>
          <SubHeader>9. INDEMNIFICATION:</SubHeader>
          <GreyText marginLeft="24px" marginTop="24px">
            {TermsAndConditionsText["Indemnifcation"]}
          </GreyText>
          <SubHeader>10. RELATIONSHIP BETWEEN THE PARTIES:</SubHeader>
          <GreyText marginLeft="36px" marginTop="24px">
            <ol type="i">
              <li>{TermsAndConditionsText["Parties"][0]}</li>
              <Break />
              <li>{TermsAndConditionsText["Parties"][1]}</li>
              <Break />
              <li>{TermsAndConditionsText["Parties"][2]}</li>
            </ol>
          </GreyText>
          <SubHeader>11. CONFIDENTIALITY:</SubHeader>
          <GreyText marginLeft="36px" marginTop="24px" marginBottom="12px">
            <ol type="i">
              <li>{TermsAndConditionsText["Confidentiality"][0]}</li>
              <Break />
              <li>{TermsAndConditionsText["Confidentiality"][1]}</li>
            </ol>
          </GreyText>
          <GreyText marginLeft="48px" marginBottom="12px">
            <ol type="i">
              <li>{TermsAndConditionsText["Confidentiality"][2]}</li>
              <Break />
              <li>{TermsAndConditionsText["Confidentiality"][3]}</li>
              <Break />
              <li>{TermsAndConditionsText["Confidentiality"][4]}</li>
              <Break />
              <li>{TermsAndConditionsText["Confidentiality"][5]}</li>
              <Break />
              <li>{TermsAndConditionsText["Confidentiality"][6]}</li>
              <Break />
              <li>{TermsAndConditionsText["Confidentiality"][7]}</li>
            </ol>
          </GreyText>
          <GreyText marginLeft="36px" marginTop="0px">
            <ol type="i" start="3">
              <li>{TermsAndConditionsText["Confidentiality"][8]}</li>
              <Break />
              <li>{TermsAndConditionsText["Confidentiality"][9]}</li>
              <Break />
              <li>{TermsAndConditionsText["Confidentiality"][10]}</li>
              <Break />
              <li>{TermsAndConditionsText["Confidentiality"][11]}</li>
            </ol>
          </GreyText>
          <SubHeader>12. DISPUTE RESOLUTION:</SubHeader>
          <GreyText marginLeft="36px" marginTop="24px" marginBottom="24px">
            <ol type="i">
              <li>{TermsAndConditionsText["Dispute"][0]}</li>
              <Break />
              <li>{TermsAndConditionsText["Dispute"][1]}</li>
              <Break />
              <li>{TermsAndConditionsText["Dispute"][2]}</li>
              <Break />
              <li>{TermsAndConditionsText["Dispute"][3]}</li>
            </ol>
          </GreyText>
          <SubHeader>13. GENERAL TERMS:</SubHeader>

          <GreyText marginLeft="36px" marginTop="24px" marginBottom="12px">
            <ol type="i">
              <li>
                <b>Amendments: </b>
                {TermsAndConditionsText["General"][0]}
              </li>
              <Break />

              <li>
                <b>Intellectual Property Rights: </b>
                {TermsAndConditionsText["General"][1]}
              </li>
              <Break />

              <li>
                <b>Assignment: </b>
                {TermsAndConditionsText["General"][2]}
              </li>
              <Break />

              <li>
                <b>Severability: </b>
                {TermsAndConditionsText["General"][3]}
              </li>
              <Break />
              <li>{TermsAndConditionsText["General"][4]}</li>
            </ol>
          </GreyText>
          <GreyText marginLeft="48px" marginBottom="12px">
            <ol type="i">
              <li>{TermsAndConditionsText["General"][5]}</li>
              <Break />
              <li>{TermsAndConditionsText["General"][6]}</li>
            </ol>
          </GreyText>
          <GreyText marginLeft="58px" marginBottom="12px">
            <ol type="a">
              <li>{TermsAndConditionsText["General"][7]}</li>
              <Break />
              <li>{TermsAndConditionsText["General"][8]}</li>
              <Break />
              <li>{TermsAndConditionsText["General"][9]}</li>
            </ol>
          </GreyText>
          <GreyText marginLeft="36px" marginTop="12px" marginBottom="24px">
            <ol type="i" start="6">
              <li>
                <b>Entire T&Cs: </b>
                {TermsAndConditionsText["General"][10]}
              </li>
              <Break />

              <li>
                <b>Further Assurances: </b>
                {TermsAndConditionsText["General"][11]}
              </li>
              <Break />

              <li>
                <b>Waiver: </b>
                {TermsAndConditionsText["General"][12]}
              </li>
              <Break />

              <li>
                <b>Force Majeure: </b>
                {TermsAndConditionsText["General"][13]}
              </li>
            </ol>
          </GreyText>
          <CentreHeader>SCHEDULE – I (“Services”)</CentreHeader>
          <GreyText marginLeft="24px" marginTop="18px">
            {TermsAndConditionsText["Schedule"][0]}
          </GreyText>
          <GreyText marginLeft="56px" marginBottom="24px">
            <ol type="a">
              <li>{TermsAndConditionsText["Schedule"][1]}</li>
              <Break />
              <li>{TermsAndConditionsText["Schedule"][2]}</li>
              <Break />
              <li>{TermsAndConditionsText["Schedule"][3]}</li>
              <Break />
              <li>{TermsAndConditionsText["Schedule"][4]}</li>
              <Break />
              <li>{TermsAndConditionsText["Schedule"][5]}</li>
              <Break />
              <li>{TermsAndConditionsText["Schedule"][6]}</li>
              <Break />
              <li>{TermsAndConditionsText["Schedule"][7]}</li>
            </ol>
          </GreyText>
          <CentreHeader>SCHEDULE – II (“FEES”)</CentreHeader>
          <GreyText marginLeft="24px" marginTop="18px">
            {TermsAndConditionsText["Schedule"][8]}
          </GreyText>
          <ImageDiv>
            <img src={Table} alt="image" />
          </ImageDiv>
          <SubHeader marginLeft="24px" marginTop="24px">
            Payment Terms
          </SubHeader>
          <GreyText marginLeft="48px" marginTop="24px">
            <ol type="1">
              <li>{TermsAndConditionsText["Payment"][0]}</li>
              <Break />
              <li>{TermsAndConditionsText["Payment"][1]}</li>
            </ol>
          </GreyText>
          <SubHeader marginLeft="24px" marginTop="24px">
            YOUR DECLARATION
          </SubHeader>
          <GreyText marginLeft="24px" marginTop="18px" marginBottom="72px">
            {TermsAndConditionsText["Declaration"][0]}
          </GreyText>
        </BodyText>
      </Body>
    </Root>
  );
}

const CentreHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Proxima Nova;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.9px;
  color: #4a4a4a;
  margin-bottom: 6px;
`;

const ImageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  margin-right: 24px;

  & img {
    height: 351px;
    width: 100%;
  }
`;
const SubHeader = styled.div`
  font-family: Proxima Nova;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.9px;
  color: #4a4a4a;
  margin-bottom: 6px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px")};
`;
const GreyText = styled.p`
  width: 100%;
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  padding-right: 72px;
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : "1px"};
  text-align: left;
  color: #666666;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "24px"};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "0px")};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "0px")};
`;
const Break = styled.div`
  margin-top: 6px;
`;
const BodyText = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 24px 0 24px;

  @media (min-width: 1022px) {
    margin: 56px 36px 0 36px;
  }
`;

const Title = styled.div`
  font-family: Proxima Nova;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.9px;
  color: #4a4a4a;
  @media (min-width: 1022px) {
    font-size: 36px;
  }
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 42px;

  @media (min-width: 1022px) {
    width: 800px;
    /* height: 88vh; */
    box-shadow: 0 0 11px 0 rgba(139, 137, 137, 0.5);
    background-color: #ffffff;
  }
`;
