import React from 'react'
import styled from 'styled-components'

const RoundedButton = styled.button.attrs({ type: 'button' })`
  width: 100%;
  padding: ${props => (props.padding ? props.padding : '1.5rem 0')}

  border: none;
  border-radius: 2.4rem;
  border: solid 2px #4154da;
  // background-color: #4154da;
  background-color: #fff;

  // font-size: 1.6rem;
  font-size: ${props => (props.fontSize ? props.fontSize : '1.4rem')};
  font-weight: bold;
  font-style: normal;
  line-height: normal;
  // line-height: 1.5;
  font-stretch: normal;
  text-align: center;
  letter-spacing: 1.3px;
  color: #777777;
  // color: #ffffff;

  &:focus, &:active {
    outline: none;
    background-color: #436ab2;
    color: #fff;
  }
`

const LargeButton14 = props => {
  return <RoundedButton {...props}>{props.label}</RoundedButton>
}
export default LargeButton14
