import React, { Component, Fragment } from "react";
import styled from "styled-components";
import { Ptag } from './fonts'
import Icons from './icons';
import {formatAmount} from '../utils/utils'

const Card = ({ amount, duration, checked, id, onClickCard,
     footerText, autoPaymentText, discountedPercentage, discountedAmount}) => {

    const renderDiscount=()=>{
        return(
            discountedPercentage &&
            <DicountPerBox>
                <Ptag fontSize='10px' letterSpacing='1.25px' fontWeight='600' color="#ffffff"  textAlign={'center'} >
                    {`${formatAmount(discountedPercentage)}% off` }
                </Ptag>
            </DicountPerBox> 
            
        )
    }

    const renderPrices=()=>{
        return(
            <Prices>
            <Ptag fontSize='20px' letterSpacing='1.25px' fontWeight='600'  >
                {`₹${formatAmount((discountedAmount))}`}
            </Ptag>
            
            <Ptag textDecoration={'line-through'} fontSize='17px' color='#7d7c7c' letterSpacing='1px' fontWeight='600' marginLeft={'3px'}>
               {discountedPercentage && `₹${formatAmount(amount)}`}
            </Ptag>
        </Prices>
        )
    }

    return(
        <Root onClick={() =>(onClickCard(id, duration))}>
            <Header>
                <TextBody>
                    <DiscountedDiv>
                    <Ptag fontSize='10px' letterSpacing='0.63px'>
                        {`${duration} MONTH${duration > 1 ? 'S' : ''}`}
                    </Ptag>
                    {renderDiscount()}
                    </DiscountedDiv>
                    {renderPrices()}
                 
                    
                </TextBody>
                <IconWrapper >
                    <Icons  icon={checked ? 'checkedCircle' : 'uncheckedCircle'}  />
                </IconWrapper>
            </Header>
            {
                autoPaymentText &&
                <Ptag marginTop='4px' fontSize='10px' letterSpacing='0.63px' fontWeight='600'>
                    {autoPaymentText}
                </Ptag>
            }
        </Root>
    )
}

export default Card

const Root = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
    padding: 18px;
    border-radius: 5px;
    border: solid 1px #436ab2;
    background-color: rgba(215, 215, 215, 0.1);
    cursor: pointer;
`

const DiscountedDiv = styled.div`
    display: flex;
    flex-direction: row;
    
    min-width: 210px;
    margin-bottom: 18px;
    /* padding: 18px; */
    border-radius: 5px;
    cursor: pointer;
`

const  DicountPerBox = styled.div`
    width: 80px;
    padding: 3px 0px;
    text-align: center;
    border-radius: 5px;
    background-color: #009999;
    cursor: pointer;
    margin-left: 15px;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
`

const Prices = styled.div`
    display: flex;
    align-items: flex-end;
`

const TextBody = styled.div`
    display: flex;
    flex-direction: column;
    & > :first-child{
        margin-bottom: 10px;
    };
`

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    align-items: flex-start;
`