import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {Ptag} from '../fonts'
import Icons from "../../components/icons";
import { formatBackendDate } from '../../utils/utils';
import CardAction from './offerActions'
import { useHistory } from 'react-router';
import logoutActionImage from '../../assets/images/logout.png'
import Routes from '../../constants/routes'
import { object } from 'yup';

const HealthProfileTab = ({offer_details, memberDetails, defaultOffer}) => {
    const memberId = memberDetails && memberDetails['id']
    const [openedTabs, setOpenedTabs] = useState({})
    const [lastClickedTab, setLastClickedTab] = useState('')
    const onClickHeader = (type) => {
        openedTabs[type] = !openedTabs[type]
        
        setOpenedTabs({...openedTabs})
        if(openedTabs[type]) {
            setLastClickedTab(type)
        } else {
            setLastClickedTab('')
        }
    }
    // Object.values(offer_details).map(i=>(console.log(i),"iii"))
    useEffect(() => {
        if(defaultOffer){
            onClickHeader(defaultOffer)
        }
    },[])

    useEffect(() => {
        if(lastClickedTab){
            let yOffset = window.document.getElementById(`header_${memberId}_${lastClickedTab}`.toString()).offsetTop;
            window.scrollTo({
                top: yOffset + 150,
                left: 0,
                behavior: "smooth",
            });
        }
    },[lastClickedTab]) 

    if(!offer_details || !Object.values(offer_details || {}).length)
        return <Ptag style={{'padding': '34px 18px'}} color={'#7d7c7c'} letterSpacing='0.9px' textAlign='center' fontSize='18px' fontWeight='600' >{'Start availing your benefits to see your records here'}</Ptag>

    return (
        <Root>
            
            {/* {
                memberDetails['relationship'] && memberDetails['relationship'].toLowerCase() === 'self' &&
                <SelfCard 
                    firstName={memberDetails['first_name']} 
                    lastName={memberDetails['last_name']} 
                />
            } */}
            
            <Offers>

                {
                    Object.entries(offer_details).map( ([offerType, offers], index) => (
                        <OfferListItem key={`${memberId}_${offerType}`} >
                            <OfferListHeader id={`header_${memberId}_${offerType}`} onClick={(event) => onClickHeader(offerType, event)} >
                                <Ptag fontSize='16px' letterSpacing='0.5px' letterHeight='2' >
                                    {offerType}
                                </Ptag>
                                <Icons icon={openedTabs[offerType] ? 'chevron_down' : 'chevron_right'} />

                            </OfferListHeader>
                            {
                                openedTabs[offerType] &&
                                <OffersCardContainer>
                                    {
                                        offers.map( offerDetails => (
                                            <VoucherCard
                                                key={`${memberId}_${offerType}_${offerDetails['offer_transaction_id']}`} 
                                                offer={offerDetails}
                                            />
                                        ))
                                    }
                                </OffersCardContainer>
                            }
                            
                        </OfferListItem>
                    ))
                }
                
            </Offers>

            {/* <FooterAction onClickLogout={onClickLogout} onClickEditPincode={onClickEditPincode} /> */}
            
        </Root>
    )
}

export default HealthProfileTab



const VoucherCard = ({offer}) => {

    const history = useHistory()
    const {url} = offer
    const onClickOffer = () => {
        history.push(`/viewVoucher/${offer['offer_transaction_id']}${Routes['HEALTH']}`)
    }
    const onClickDownloadReport = () => {
        window.open(url, "_blank")
    }
    return(
        <OrderCardRoot bgColor={offer['template_color'] || '#FFA89A'} >
            <OfferCardHeader>
                <img width='auto' height={'28px'} src={offer['logo']} />
                <OfferCardHeaderRight>
                    <Ptag fontSize='9px' letterHeight='1.3' letterSpacing='0.56px' color='#ffffff' >
                        {'Order Date'}
                    </Ptag>
                    <Ptag fontSize='9px' letterHeight='1.3' letterSpacing='2px' color='#ffffff' >
                        {formatBackendDate(offer['order_date'])}
                    </Ptag>
                </OfferCardHeaderRight>
            </OfferCardHeader>
            <Ptag fontSize='14px' letterHeight='1.3' letterSpacing='1px' color='#ffffff' >
                {offer['offer_summary']}
            </Ptag>
            <FooterActions>
                <CardAction text='View Details' onClickButton={onClickOffer} />
                {
                    url &&
                    <CardAction text='View Report' onClickButton={onClickDownloadReport} />
                }
            </FooterActions>
        </OrderCardRoot>
    )
}


const SelfCard = ({firstName, lastName}) => {
    const name = `${firstName} ${lastName}`
    const phone = localStorage.getItem('phone')
    const email = localStorage.getItem('email')
    return (
        <SelfCardRoot>
            <SelfCardLeft>
                <Ptag textTransform='capitalize' fontSize='16px' fontWeight='600' letterHeight='1.69' letterSpacing='0.4px' color='#436ab2' >
                    {name}
                </Ptag>
                <Ptag letterSpacing='1px' >
                    {email}
                </Ptag>
                <Ptag letterSpacing='1px' >
                    {phone}
                </Ptag>

            </SelfCardLeft>
            <SelfCardRight>
                <Ptag textTransform='uppercase' fontSize='20px' fontWeight='600' letterSpacing='1px' color='white' >
                    {`${firstName[0] || ''}${lastName[0] || ''}`}
                </Ptag>
            </SelfCardRight>

        </SelfCardRoot>
    )
}

const FooterAction = ({onClickLogout, onClickEditPincode}) => {
    

    return (
      <LogOutRoot  >
        
        <Line />
            
            <LogOutAction style={{'borderBottom': '1px solid #4a4a4a30'}} onClick={onClickEditPincode} >
                <ActionContainer>
                    <Ptag fontSize='16px' fontWeight='600' >
                        {'Pincode'}
                    </Ptag>
                </ActionContainer>
                
                <Icons icon={'editPencil'} />
            </LogOutAction>
            
            <LogOutAction onClick={onClickLogout} >
                <ActionContainer>
                    <img src={logoutActionImage} width='24px' height='auto' />
                    <Ptag fontSize='16px' fontWeight='600' >
                        {'LOG OUT'}
                    </Ptag>
                </ActionContainer>
                
                <Icons icon={'chevron_right'} />
            </LogOutAction>
            
           
        <Line />
      </LogOutRoot>
    )
}

const Root = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    padding: 0 24px;
`

const Offers = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
`

const OfferListItem = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #4a4a4a30;
    padding: 16px 0;
`

const OfferListHeader = styled.div`
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const OffersCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`


const OrderCardRoot = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 18px;
    margin-bottom: 12px;
    background-color: ${props => props.bgColor};
`

const OfferCardHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
`

const OfferCardHeaderRight = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 90px;
    align-items: flex-start;
    & > :first-child{
        margin-bottom: 6px;
    }
`

const FooterActions = styled.div`
    display: flex;
    justify-content: ${props => props.actionsCount > 1 ? 'flex-end' : 'space-between' };
    margin-top: 36px;
`


const SelfCardRoot = styled.div`
    display: flex;
    margin: 36px 2px 0 2px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 18px 18px 24px 24px;
    border-radius: 15px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
    background-color: #fff;
`

const SelfCardLeft = styled.div`
    display: flex;
    flex-direction: column;
    & > :first-child{
        margin-bottom: 6px;
    };
    & > :nth-child(2){
        margin-bottom: 6px;
    };

`

const SelfCardRight = styled.div`
    display: flex;
    min-height: 54px;
    min-width: 54px;
    border-radius: 50%;
    background-color: #4a4a4a;
    align-items: center;
    justify-content: center;
`

const LogOutRoot = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px -24px 0 -28px;
`

const Line = styled.div`
    display: flex;
    min-height: ${props => props.minHeight || '12px'};
    background: #eee;
`

const LogOutAction = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px;
    background-color: white;
    cursor: pointer;
`

const ActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > :first-child{
        margin-right: 10px;
    }
`
