import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  align-items: center;
  // flex-direction: row-reverse;
  width: 5.4rem;
  // width: 4.8rem;
  padding: 4px;
  // padding: 2px;
  border-radius: 1.2rem;//1.5rem;
  border: ${props => (props.active ? '1px solid #37d699' : '1px solid #436ab2')};
  // background: ${props => (props.active ? '#37D699' : '#37D699')};
  background: ${props => (props.active ? '#37D699' : '#fff')};
  position: relative;
  opacity: ${props => props.locked ? '0.4' : '1'};
  p {
    // margin-left: ${props => (props.active ? 0 : '5px')};
    // margin-right: ${props => (props.active ? '5px' : 0)};

    position: relative;
    top: 0;
    left: ${props => (props.active ? '-1.2rem' : '0.6rem')};
  }

`

const Circle = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  border: ${props => (props.active ? '1px solid #fff' : '1px solid #436ab2')};
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16);
  background: #fff;

  position: relative;
  top: 0;
  left: ${props => (props.active ? '2.8rem' : 0)};
  // left: ${props => (props.active ? '2.6rem' : 0)};
  // left: ${props => (props.active ? '2.2rem' : '1px')};
  transition: all 0.2s ease;
`

const Font10 = styled.p`
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.63px;
  text-align: right;
  color: ${props => (props.active ? '#fff' : '#4a4a4a')};
`

const Toggle = props => {
  return (
    <Container active={props.active} locked={props.locked} onClick={() => !props.locked && props.toggleClick(!props.active)}>
      <Circle active={props.active} />
      <Font10 active={props.active}>{props.active ? 'Yes' : 'No'}</Font10>
    </Container>
  )
}

export default Toggle
