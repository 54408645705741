import React, { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router";
import { Ptag } from "../../fonts";
import LargeButton from "../../LargeButton";
import { formatAmountWithDecimal, formatDateReverse } from "../../../utils/utils";
import { insurers } from "../../memberApp/constants/PolicyConstants";
import {
  ButtonWrapper,
  SummaryBox,
  BoxButton2,
  BoxTop,
  TransactionDiv,
  Image,
  Root,
} from "./style";
import { toUpper } from "lodash";
import { ALL_PRODUCTS } from "../../../constants/businessConstant";

const CdbalanceCard = ({ tableData, planType }) => {
  const history = useHistory();
  const gmcDetail = JSON.parse(localStorage.getItem("gmc_details"));
  const handleChange = () => {
    // history.push("/business/view_cd_table", { transactionData: tableData }); not showing user the transaction history
    const url = tableData && tableData.cd_balance_statement_url
    if(!url) return
    window.open(url, true)
  };
  const getLastUpdatedDate = (tableDate) => {
    let updatedDateTransaction =
      (tableDate &&
        tableDate.transactions &&
        // tableDate.transactions.length() &&
        tableDate.transactions[0].transaction_date_show) ||
      "";
    let lastUpdated = (tableDate && tableDate.last_updated_date) || "";
    lastUpdated = lastUpdated || updatedDateTransaction || "";
    lastUpdated = formatDateReverse(lastUpdated.substring(0, 10))
    return lastUpdated
  };
  const getUpdatedAmount = (tableDate) => {
    const amountTransaction =
      (tableDate &&
        tableData.transactions &&
        // tableDate.transactions.length() &&
        (tableDate.transactions[0].updated_balance)) ||
      0;
    const balanceAmount = tableDate && tableDate.balance_amount;
    const returnValue = balanceAmount || amountTransaction || 0;
    return formatAmountWithDecimal(returnValue)
  };

  const insurer_name = useMemo(() => {
    if (
      gmcDetail &&
      gmcDetail[planType] &&
      gmcDetail[planType].insurer &&
      gmcDetail[planType].insurer.name
    ) {
      return toUpper(gmcDetail[planType].insurer.name);
    }
  }, [gmcDetail]);

  const lastUpdatedCd = () => {
    return (
      <div>
        <SummaryBox>
          <BoxTop>
            <div style={{ width: "290px" }}>
              <div style={{ minHeight: "68px" }}>
                <Ptag
                  fontSize="20px"
                  fontWeight="600"
                  letterSpacing="0.338333px"
                  marginTop="5px"
                  letterHeight="28px"
                >
                  {`${ALL_PRODUCTS[planType]}`}
                </Ptag>
                {/* <Ptag
                  fontSize="16px"
                  fontWeight="500"
                  letterSpacing="0.6px"
                  marginTop="5px"
                  letterHeight="21.79px"
                >
                  CD Balance
                </Ptag> */}
                {
                  getLastUpdatedDate(tableData) && (
                    <Ptag
                      fontSize="12px"
                      fontWeight="400"
                      letterSpacing="0.6px"
                      letterHeight="16px"
                    >
                      Last updated on{" "}
                      {getLastUpdatedDate(tableData).substring(0, 10) || "-"}
                    </Ptag>
                  )}
              </div>
              <Ptag
                fontSize="36px"
                fontWeight="600"
                letterSpacing="0.6px"
                letterHeight="44px"
              >
                ₹{getUpdatedAmount(tableData)}
              </Ptag>
            </div>
            <div>
              <Ptag marginTop="5px" style={{ width: "67px" }}>
                Powered By
              </Ptag>
              <Image src={insurers[insurer_name].logo} />
            </div>
          </BoxTop>
          <TransactionDiv>
            <BoxButton2>
              <LargeButton
                onClick={handleChange}
                textTransform={"initial"}
                letterSpacing={"0.25px"}
                label="View Ledger"
                opacity={tableData && !tableData.cd_balance_statement_url && "50%"}
              />
            </BoxButton2>
          </TransactionDiv>
        </SummaryBox>
      </div>
    );
  };

  return (
    <>
      <Root>{lastUpdatedCd()}</Root>
    </>
  );
};
// }

export default CdbalanceCard;
