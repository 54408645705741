import React, { Component } from 'react'
import styled from 'styled-components'
import {Ptag} from '../../components/fonts'
import pencil from '../../assets/images/pencilGrey.png'
import Icons from '../../components/icons'
import {Footer} from '../../components/business'

export default class Profile extends Component {

    constructor(props){
        super(props)
        this.state = {
            about: JSON.parse(localStorage.getItem('about')),
            showLogOutPopUp: false,
            phone: localStorage.getItem('phone')
        }
    }

    onClickLogout = () => {
        this.setState({showLogOutPopUp: true})
    }
    
    onLogoutSelected = () => {
        localStorage.clear()
        this.props.history.push('/business')
    }

    onPressCancel = () => {
        this.setState({showLogOutPopUp: false})
    }

    onClickMemberDetails = () => {

        localStorage.setItem('navigatingFrom','profile')
        this.props.history.push('/business/memberDetails')
    }

    render(){
        
        return(
            <Root>
                <Tile>
                    <Ptag textTransform='capitalize' style={{'marginBottom': '7px'}} fontSize='16px' fontWeight='600' letterHeight='1.69' letterSpacing='0.4px' color='#436ab2'>
                        {this.state.about && this.state.about['name']}
                    </Ptag>
                    <Ptag style={{'marginBottom': '3px'}}  letterSpacing='2.25'>
                        {this.state.about && this.state.about['email']}
                    </Ptag>
                    <Ptag>
                        {this.state.phone}
                    </Ptag>
                </Tile>
                
                <Rows onClick={() => this.onClickMemberDetails()}>
                    <Ptag letterSpacing='0.5px' fontSize='14px'>
                        {'Employee Details '}
                    </Ptag>
                    <Icons icon='chevron_right' fill='#4a4a4a'/>
                </Rows>


                <Rows onClick={this.onClickLogout}>
                    <Ptag letterSpacing='0.5px' fontSize='14px'>
                        {'Logout'}
                    </Ptag>
                </Rows>
                <Footer/>
                {this.state.showLogOutPopUp && (
                <SignOutPopUp>
                    <Innerpopup>
                    <FirstRowPopUp>Are you sure you wantt log out?</FirstRowPopUp>
                    <SecondRowPopUp>
                        <PopupTexts onClick={() => this.onLogoutSelected()}>Ok</PopupTexts>
                        <VerticalLine />
                        <PopupTexts onClick={() => this.onPressCancel()}>Cancel</PopupTexts>
                    </SecondRowPopUp>
                    </Innerpopup>
                </SignOutPopUp>
                )}
            </Root>
            
        )
    }

}

const Root = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 50px;
`


const Tile = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 16px;
    border-radius: 15px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    padding: 18px 24px;
    margin-bottom: 36px;
`

const Rows = styled.div`
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding: 18px 24px;
    border-bottom: 1px solid rgba(153, 163, 187, 0.10);
`


const SignOutPopUp = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
`

const Innerpopup = styled.div`
  position: absolute;
  left: 15%;
  right: 13%;
  top: 37%;
  bottom: 45%;
  margin: auto;
  border-radius: 20px;
  background: white;
  border-radius: 10px;
`

const FirstRowPopUp = styled.div`
  align-items: center;
  display: flex;
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: #191515;
  height: 63%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  padding: 10px;
  line-height: 17px;
  justify-content: center;
`

const SecondRowPopUp = styled.div`
  display: flex;
  flex-direction: row;
  height: 38%;
  align-items: center;
`

const PopupTexts = styled.p`
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: #191515;
  width: 50%;
  cursor: pointer;
`

const VerticalLine = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.16);
  height: 100%;
`
