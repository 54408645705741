import React, { Component, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Ptag } from '../../components/fonts'
import { useHistory } from 'react-router-dom'
import http from '../../services/httpService'
import employee from '../../assets/images/totallives.png';
import validtill from '../../assets/images/mask.png';
import totlemp from '../../assets/images/totlemp.png';
import payawwor22 from '../../assets/images/payarrow22.png'
import eye from '../../assets/images/eye.png'
import group from '../../assets/images/group-2.png'
import insurance from '../../assets/svgs/insurance.svg';
import Loader from './overLayLoaderBusiness'
import formatAmount from '../../components/memberApp/common/utils'
import { formatBackendDate } from '../../utils/utils';
import { connect } from 'react-redux'
import DashboardList from './dashboardList'
import { upperCase } from 'lodash'
import { Pdf } from '../../constants/businessConstant'
import PaymentPopup from './paymentBreakupPopup'
import { DisclaimerPopup, PaymentBreakupPopup } from '../../components/business'
import { FreshPolicyDisclaimer, RenewPolicyDisclaimer } from '../../constants/business'
import patient from '../../assets/images/patient.png'
import icicLombart from '../../assets/images/iciciLombard.png'
import { number } from 'yup'


const DashboardBox = (props) => {

  const [loading, setLoading] = useState(true)
  const [cardArray, setCardArray] = useState([])
  const [dueDate, setDueDate] = useState('')
  const [paymentdone, setPaymentDone] = useState(true)
  const [enable_payment, setEnablePayment] = useState(true)
  const [notification, setNotification] = useState()
  const [showPayment, setShowPayment] = useState(false)
  const [sboType, setSboType] = useState('')
  const [subscriptionId,setSubscriptionId] = useState('')
  const [policyDocURL, setPolicyDoc] = useState()
  const [showDisclaimer, setDisclaimer] = useState(false)
  const [policyRenewState, setPolicyRenewState] = useState('')
  const [GPAAmount, setGPAAmount] = useState(0)
  const [insurer, setInsurer] = useState('')
  const [instantIssuanceData , setInstantIssuanceData] = useState({})
  const [showPaymentPopup, setPaymentPopup] = useState(false)
  const [showGmcNotificationBox, setGmcNotificationBox] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const asyncFetchDailyData = async () => {
      const promise1 = await http.get(`/payment/subscription_details/`, {
        params: {
          plan: localStorage.getItem('userplan_id'),
        }
      })
      const promise2 = await http.get(`/business/userplans/${localStorage.getItem('userplan_id')}/`);
      const [{ data: data1 }, { data: data2 }] = await Promise.all([promise1, promise2])
      setDueDate(data2['subscription']['next_billing_date'] || '')
      setSboType(data2['sbo_type'] || '')
      setSubscriptionId(data2['subscription']['vital_subscription_id'] || '')
      setCardArray(data2['plan_benefits'])
      setNotification(Object.values(data1)[0]['business_user_notification'])
      setEnablePayment(Object.values(data1)[0]['enable_payment'])
      setPaymentDone(data2.is_payment_done)
      setPolicyDoc(data2['policy_doc_url'])
      setPolicyRenewState(data2['policy'])
      setGPAAmount(data2['sum_insured_gpa'])
      setInsurer(data2['insurer'] && data2['insurer']['name'] && data2['insurer']['name'].toUpperCase() )
      setLoading(false)
      localStorage.setItem('id', data2.business_user)
      localStorage.setItem('employee_details', JSON.stringify(data1))
      localStorage.setItem('user_plan_details', JSON.stringify(data2))
    }
    asyncFetchDailyData();


  }, []);

  const payment_method = () => {
    if(!paymentdone ){
      setDisclaimer(true)
      return
    }
    navigateToPayment()
  }

  const wellness_fee_payment = async() => {
      var userPlanDetails = JSON.parse(localStorage.getItem('user_plan_details'))
      //TODO
      const response = await http.post(`/payment/subscription_payment/?subscription_id=${subscriptionId}`, {
          payment: {method:0,plan:0},
          term_type:null,
          payable_amount: wellnessPayableAmount
      });
    const options = {
      async handler(response) {
       window.location.reload()
      },
      ...response.data,
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  
  }

  const exportCsv = () => {
      var csvStr = '"UHID","Name","Gender","Date of Birth","Email","Mobile","Dependents","Sum Insured","Start Date","Premium","Wellness Fee","End Date","Status","Dependent1",,,,"Dependent2",,,,"Dependent3",,,,"Dependent4",,,,"Dependent5",,,,\n,,,,,,,,,,,,,"Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB",\n'
      wellnessEmployeeData && wellnessEmployeeData.forEach(item => { 
          csvStr = csvStr+ (item.employee_uhid || '') +','
          csvStr = csvStr+ (item.name || '') +','
          csvStr = csvStr+ (item.gender|| '')+','
          csvStr = csvStr+ (item.dob || '') +','
          csvStr = csvStr+ (item.email|| '')+','
          csvStr = csvStr + (item.phone || '') + ','
          csvStr = csvStr+ (item.no_of_dependents|| '')+','
          csvStr = csvStr+ (item.sum_insured|| '')+','
          csvStr = csvStr+ (item.start_date|| '')+','
          csvStr = csvStr+ (item.premium|| '')+',' 
          csvStr = csvStr+ (item.wellness_fee|| '')+','
          csvStr = csvStr+ (item.end_date|| '')+','
          csvStr = csvStr+ (item.policy_status|| '')+','
          for (let i = 0; i < item.dependents.length; i++) {
              const role = item.dependents[i]
              csvStr = csvStr+ (role.relation || '')+','
              csvStr = csvStr+ (role.first_name || '')+','
              csvStr = csvStr+ (role.last_name || '')+','
              csvStr = csvStr+ (role.dob || '')+','
              
          }
          csvStr = csvStr+'\n'
      });
      const element = document.createElement("a");
      const fileOutput = csvStr
      const file = new Blob([fileOutput], { type: 'text/plain' });
      element.href = URL.createObjectURL(file);
      
      element.download = (localStorage.getItem('cname') && localStorage.getItem('cname').split(/[\s.]+/)[0] + '_employee.csv') || 'Employee_Details.csv';
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      element.parentNode.removeChild(element);
  }
  


  const navigateToPayment = () => {
    localStorage.setItem("is_edit", true)
    history.push('/business/payment')
  }

  const cashLessClaim = () => {
    const sbo = sboType && sboType.toUpperCase()
    if (sbo === 'GMC' || sbo === 'GMC_M'){
        if(insurer.indexOf('KOTAK') > -1){
          window.open("https://www.kotakgeneral.com/Externalfiles/Mediassit-hospital-list-03-04-2023.xlsb")
        } else if(insurer.indexOf('NIVA') > -1){
          window.open("https://transaction.nivabupa.com/Pages/hospitalnetwork.aspx")
        } else if(insurer.indexOf('ICICI') > -1) {
          window.open("https://www.icicilombard.com/cashless-hospitals")
        } else {
          window.open("https://www.careinsurance.com/health-plan-network-hospitals.html")
        }
    } else if(sbo.indexOf('KOTAK') > -1 ){
      window.open("https://www.kotakgeneral.com/Externalfiles/Mediassit-hospital-list-03-04-2023.xlsb")
    } else if(localStorage.getItem('insurer') == 'go_digit'){
      window.open("https://www.medibuddy.in/networkHospitals")
    } else {
        window.open("https://www.careinsurance.com/health-plan-network-hospitals.html")
    }
  }

  const getPDFURL = () => {
    if((sboType) === 'GMC' || (sboType) === 'GMC_M' || localStorage.getItem('insurer') == 'go_digit'){
      if(policyDocURL){
        return policyDocURL
      }
      else {
        return 
      }
    } else {
        console.log(123)
        return Pdf[sboType.toUpperCase()]
    }
  }

  const setInstantIssuanceDataChild = (data) => {
    setInstantIssuanceData(data)
  }

  const goToPlanPdf = () => {
    const pdfURL = getPDFURL()
    if(pdfURL) {
        window.open(pdfURL)
    }
  }

  const openIciciLombartPDF = () => {
    window.open("https://images.getvital.in/Marketing/Group+Accident+Cover+Policy+Document.pdf", "_blank")
  }

  const renderNotification = () => {
    return (
      (notification['business_user_main_notification'] && notification['business_user_emandate_notification']) ?
        <Notification>
          <Ptag fontSize="21px" letterSpacing="0.5px" lineHeight="28px" color="#4A4A4A" fontWeight="500" >
            {notification['business_user_emandate_notification'] && '\u2022' + ' ' + notification['business_user_emandate_notification']}
          </Ptag>
          <Ptag fontSize="21px" letterSpacing="0.5px" lineHeight="28px" color="#4A4A4A" fontWeight="500">
            {notification['business_user_main_notification'] && '\u2022' + ' ' + notification['business_user_main_notification']}
          </Ptag>
        </Notification> :

        <Notification>
          <Ptag fontSize="21px" letterSpacing="0.5px" lineHeight="28px" color="#4A4A4A" fontWeight="500" >
            {notification['business_user_emandate_notification'] && notification['business_user_emandate_notification']}
          </Ptag>
          <Ptag fontSize="21px" letterSpacing="0.5px" lineHeight="28px" color="#4A4A4A" fontWeight="500">
            {notification['business_user_main_notification'] && notification['business_user_main_notification']}
          </Ptag>
        </Notification>
    )
  }

  const renderCard = () => {
    return (
      <PlanSummaryBox>
        <SummaryBox style={{ padding: '24px 16px 0px 22px' }} >
          <PlanSummaryInnerDiv>
            <img src={insurance} width="36px" />
            <PlanSummaryNumberDiv>
              <Ptag fontSize="16px" fontWeight="600" color="#436AB2" letterSpacing="0.57px" lineHeight="24px">Insurance</Ptag>
              <Ptag fontSize="14px" marginTop='5px' letterSpacing="0.34px" lineHeight="24px" fontWeight='normal'>For hospitalizations and day care treatments</Ptag>
            </PlanSummaryNumberDiv>
          </PlanSummaryInnerDiv>
          <Cassless style={{ marginTop: 30, height: 14 }}>
            {getPDFURL() && (<div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }} onClick={goToPlanPdf}>

              <Ptag fontSize="14px" fontWeight="600" color="#4A90E2" >Policy Document</Ptag>
              <img src={group} width="14px" height="14px" style={{ marginLeft: 4, marginTop: 3 }} />
            </div>)}

            <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }} onClick={cashLessClaim} >
              <Ptag fontSize="14px" fontWeight="600" color="#4A90E2" >Cashless Network</Ptag>
              <img src={group} width="14px" height="14px" style={{ marginLeft: 4, marginTop: 3 }} />
            </div>

          </Cassless>
        </SummaryBox>
        {Array.isArray(cardArray) && cardArray.map((card, index) => (
          <SummaryBox style={{ padding: '24px 16px 18px 22px' }} key={index}>
            <PlanSummaryInnerDiv>
              <img src={card.icon_url} width="36px" />
              <PlanSummaryNumberDiv>
                <Ptag fontSize="16px" fontWeight="600" color="#436AB2" letterSpacing="0.57px" lineHeight="24px">{card.heading}</Ptag>
                <Ptag fontSize="14px" marginTop='5px' letterSpacing="0.34px" lineHeight="24px" fontWeight='normal'>{card.employer_sub_heading}</Ptag>
              </PlanSummaryNumberDiv>
            </PlanSummaryInnerDiv>
          </SummaryBox>
        ))}
        {
            !!GPAAmount &&
            <SummaryBox style={{ padding: '18px 18px 18px 18px' }}>
                  <CardHeader>
                <PlanSummaryInnerDiv>
                    
                    <img src={patient} width="36px" />
                    <PlanSummaryNumberDiv>
                        <Ptag fontSize="16px" fontWeight="600" maxwidth="188px" color="#436AB2" letterSpacing="0.57px" lineHeight="24px">
                            {"Personal Accident Cover"}
                        </Ptag>
                        
                    </PlanSummaryNumberDiv>
                </PlanSummaryInnerDiv>
                <CardHeaderRight>
                    <Ptag fontSize='8px' letterSpacing='0.5px' color="#95979D" >
                        {'POWERED BY'}
                    </Ptag>
                    <img src={icicLombart} height="18px" width="88px" /> 
                </CardHeaderRight>
                </CardHeader>
                    <Ptag fontSize="14px" marginTop='10px' marginBottom="14px" marginLeft="47px" letterSpacing="0.34px" lineHeight="24px" fontWeight='normal'>
                        {"Coverage for accidental death or permanent disablment"}
                    </Ptag>
                    <Links style={{"justifyContent": "flex-end"}} onClick={openIciciLombartPDF}>
                        <Ptag fontSize="14px" fontWeight="600" color="#4A90E2">Policy Document</Ptag>
                        <img src={group} width="14px" height="14px" style={{ marginLeft: 4, marginTop: 3 }}  />
                    </Links>
            </SummaryBox>
                    
          }
      </PlanSummaryBox>
    )
  }

  const openInvoice = () => {
    var host = window.location.host;
    // console.log(host)
    if (host.indexOf('http') < 0) {
      host = 'http://' + host
    }
    if (localStorage.getItem('insurer') == 'go_digit'){
      window.open(`${host}/business/digitproformainvoice.html?current_userplanid=${encodeURI(localStorage.getItem('userplan_id'))}`, '__blank')
    } else if(localStorage.getItem('flow') == 'GMC'){
      window.open(`${host}/business/gmcwellnessinvoice.html?current_userplanid=${encodeURI(localStorage.getItem('userplan_id'))}`, '__blank')
    } else {
      window.open(`${host}/business/perfomaInvoice.html?current_userplanid=${encodeURI(localStorage.getItem('userplan_id'))}`, '__blank')
    }

  }

  const openPaymentPopup = () => {
    setShowPayment(true)
  }

  const handleCallback = (childData) => {
    setShowPayment(childData)
  }


  const subscriptionAmount = Number(props.data.subscriptionAmount)
  const lineDiscountAmount = Number(props.data.lineThroughAmount)
  const wellnessPayableAmount = Number(props.data.wellnessPayableAmount)
  const wellnessEmployeeData = new Object(props.data.wellnessEmployeeData)
  const preEmployeeYearlyFee = Number(props.data.preEmployeeYearlyFee)
  const numberOfEmps = Number(props.data.numberOfEmps)
  const totalWellnessFee = Number(props.data.totalWellnessFee)

  return (

    loading ? <Loader></Loader> :
      <MainDiv>
        
        <PaymentBreakupPopup 
          onClose={() => setPaymentPopup(false)} 
          show={showPaymentPopup} 
          employees={instantIssuanceData && instantIssuanceData['data'] && instantIssuanceData['data']['number_of_employees']}
          advanceMonth={instantIssuanceData && instantIssuanceData['data'] && instantIssuanceData['data']['multiplier']}
          totalSubscriptionFee={instantIssuanceData && instantIssuanceData['data'] && instantIssuanceData['data']['total_payable_amount']}
          totalPremium={instantIssuanceData && instantIssuanceData['data'] && instantIssuanceData['data']['total_premium']}
          platformFee={instantIssuanceData && instantIssuanceData['data'] && instantIssuanceData['data']['total_platform_fee']}
          discount={instantIssuanceData && instantIssuanceData['data'] && instantIssuanceData['data']['discount']}
          tax={instantIssuanceData && instantIssuanceData['data'] && instantIssuanceData['data']['discounted_tax']}
        />


        {showPayment && <PaymentPopup parentCallback={handleCallback} ></PaymentPopup>}
        {
          showDisclaimer &&
          <DisclaimerPopup messages={policyRenewState === 'Renew' ? RenewPolicyDisclaimer : FreshPolicyDisclaimer} onClickAgree={navigateToPayment}  />
        }
        <HomeWrapper>
          
          <BoxHeading>
            <Ptag fontSize="24px" fontWeight="600" color="#4A4A4A" >Plan Benefits</Ptag>

          </BoxHeading>
          {renderCard()}
          <Line style={{ margin: '46px 0px 32px 0px' }}></Line>

          <CareEmployeesBox>
            <TotalEmployees>
              <InnerDiv>
                <img src={totlemp} width="50px" style={{ marginBottom: 35 }} />
                <NumberDiv>
                  <Ptag fontSize="22px" fontWeight="600" lineHeight="30px" letterSpacing="0.6px" color="#4A4A4A">Active Employees</Ptag>
                  <Ptag fontSize="36px" fontWeight="600" color="#4A4A4A" lineHeight="49px" letterSpacing="1px" >{(props.data.totalEmp && props.data.totalEmp) || 0}</Ptag>
                </NumberDiv>
              </InnerDiv>
            </TotalEmployees>
            <TotalLives>
              <InnerDiv>
                <img src={employee} width="50px" style={{ marginBottom: 35 }} />
                <NumberDiv>
                  <Ptag fontSize="22px" fontWeight="600" color="#4A4A4A" lineHeight="30px" letterSpacing="0.61px" >Active Members</Ptag>
                  <Ptag fontSize="36px" fontWeight="600" color="#4A4A4A" lineHeight="49px" letterSpacing="1px" >{(props.data.totalLives && props.data.totalLives) || 0}</Ptag>
                </NumberDiv>
              </InnerDiv>
            </TotalLives>
            {
              (sboType === 'GMC' || sboType === 'GMC_M') &&
              <TotalLives>
                <InnerDiv>
                  <img src={validtill} width="50px" style={{ marginBottom: 35 }} />
                  <NumberDiv>
                    <Ptag fontSize="22px" fontWeight="600" color="#4A4A4A" lineHeight="30px" letterSpacing="0.61px" >Valid Till</Ptag>
                    <Ptag fontSize="28px" fontWeight="600" color="#4A4A4A" lineHeight="49px" letterSpacing="1px" >{formatBackendDate(dueDate) || '-'}</Ptag>
                  </NumberDiv>
                </InnerDiv>
              </TotalLives>
            }
          </CareEmployeesBox>



          {
            (sboType !== 'GMC' && sboType !== 'GMC_M') &&
            <NotificationDiv>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <PayDiv>
                <Subscription style={{ marginRight: 100 }}>
                  <Ptag fontSize="16px" letterSpacing="1px" lineHeight="14px" >SUBSCRIPTION</Ptag>
                  <PricePart>
                    {
                      !!lineDiscountAmount &&
                      <Ptag color='#7f7c7c' fontSize='18px' textDecoration='line-through' >
                        {`₹${formatAmount(lineDiscountAmount)}`}
                      </Ptag>


                    }
                    <Ptag fontSize="18px" letterSpacing="3px" marginTop="3px" lineHeight="33px" fontWeight="600">
                      {`₹${formatAmount(subscriptionAmount)}`}
                    </Ptag>
                    <img src={eye} onClick={openPaymentPopup} style={{ margin: '8px 8px 0 0', cursor: 'pointer' }} height="18px" width="18px"></img>
                  </PricePart>
                </Subscription>
                <Duedate style={{ marginRight: 100 }}>
                  <Ptag fontSize="16px" letterSpacing="1px" lineHeight="14px" >DUE DATE</Ptag>
                  <Ptag fontSize="18px" letterSpacing="1.5px" lineHeight="33px" textAlign="center" fontWeight="600">{formatBackendDate(dueDate) || '-'}</Ptag>
                </Duedate>
                {
                  localStorage.getItem('flow') !== 'GMC' && 
                  <Invoice style={{ marginRight: 100 }}>
                    <Ptag fontSize="16px" letterSpacing="1px" lineHeight="14px" >PROFORMA INVOICE</Ptag>
                    <Ptag style={{ 'cursor': 'pointer' }} fontSize="18px" letterSpacing="1.5px" lineHeight="33px" textAlign="center" fontWeight="600" onClick={openInvoice} textDecoration="underline">{'PDF'}</Ptag>
                  </Invoice>
                }
              </PayDiv>
              {
                (enable_payment && !!subscriptionAmount) &&
                <Buttn onClick={payment_method}>

                  <Ptag fontSize="18px" fontWeight="600" color="#ffffff" >Pay</Ptag>
                  <img src={payawwor22} width="36px" />
                </Buttn>
              }
            </div>
            <Ptag fontSize="16px" fontWeight="600" color="#ffffff" >{((notification && notification['business_user_main_notification']) || (notification && notification['business_user_emandate_notification'])) && renderNotification()}</Ptag>
          </NotificationDiv>}

          {
            (sboType === 'GMC' || sboType === 'GMC_M') && !!wellnessPayableAmount &&
            <NotificationDiv>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <PayDiv>
                <Subscription style={{ marginRight: 100 }}>
                  <Ptag fontSize="16px" letterSpacing="1px" lineHeight="14px" >WELLNESS FEE</Ptag>
                  <PricePart>
                    <Ptag fontSize="18px" letterSpacing="3px" marginTop="3px" lineHeight="33px" fontWeight="600">
                      {`₹${formatAmount(wellnessPayableAmount)}`}
                    </Ptag>
                    {/* <img src={eye} onClick={openPaymentPopup} style={{ margin: '8px 8px 0 0', cursor: 'pointer' }} height="18px" width="18px"></img> */}
                  </PricePart>
                </Subscription>
                <Duedate style={{ marginRight: 100 }}>
                  <Ptag fontSize="16px" letterSpacing="1px" lineHeight="14px" >ACTIVE EMPLOYEES</Ptag>
                  <Ptag style={{ 'cursor': 'pointer' }} fontSize="18px" letterSpacing="1.5px" lineHeight="33px" textAlign="center" fontWeight="600" onClick={exportCsv} textDecoration="underline">{'Excel'}</Ptag>
                </Duedate>
                  <Invoice style={{ marginRight: 100 }}>
                    <Ptag fontSize="16px" letterSpacing="1px" lineHeight="14px" >PROFORMA INVOICE</Ptag>
                    <Ptag style={{ 'cursor': 'pointer' }} fontSize="18px" letterSpacing="1.5px" lineHeight="33px" textAlign="center" fontWeight="600" onClick={openInvoice} textDecoration="underline">{'PDF'}</Ptag>
                  </Invoice>
              </PayDiv>
              {
                (enable_payment && !!wellnessPayableAmount) &&
                <Buttn onClick={wellness_fee_payment}>

                  <Ptag fontSize="18px" fontWeight="600" color="#ffffff" >Pay</Ptag>
                  <img src={payawwor22} width="36px" />
                </Buttn>
              }
            </div>
            <Ptag fontSize="16px" fontWeight="600" color="#ffffff" >{((notification && notification['business_user_main_notification']) || (notification && notification['business_user_emandate_notification'])) && renderNotification()}</Ptag>
          </NotificationDiv>}

          {/* <Line style={{ margin: '32px 0px 26px 0px' }}></Line> */}
          {
            instantIssuanceData && !!instantIssuanceData['status'] &&
            <InstantIssuanceDIV>
              <Ptag fontSize={'20px'} fontWeight='400' letterSpacing='0.5px'>
                {`Policies for ${instantIssuanceData['data'] && instantIssuanceData['data']['number_of_employees']}  new employee${instantIssuanceData['data']['number_of_employees'] > 1 ? "s": ""}  will be issued on the next due date along with your ongoing subscription. To issue policies immediately, `}
                <PaymentLink onClick={() => setPaymentPopup(true)} >
                  click here.
                </PaymentLink>
              </Ptag> 
            </InstantIssuanceDIV> 
          }
             
        </HomeWrapper>
        <div style={{ width: '102%', height: 20, background: '#eeeeee' }}></div>
        <DashboardList setInstantIssuanceData={setInstantIssuanceDataChild} />


      </MainDiv>
  )
}
const mapStateToProps = (state) => {
  return {
    data: state
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    changeTotalLives: (value) => { dispatch({ type: 'SET_TOTAL_LIVES', payload: value }) },
    changeTotalEmp: (value) => { dispatch({ type: 'SET_TOTAL_EMP', payload: value }) },
    wellnessPayableAmount: (value) => { dispatch({ type: 'WELLNESS_PAYABLE_AMOUNT', payload: value }) },
    wellnessEmployeeData: (value) => { dispatch({ type: 'WELLNESS_EMPLOYEE_DATA', payload: value }) },
    wellnessPreEmoloyeeYearlyFeee: (value) => { dispatch({ type: 'PRE_EMP_YEARLY_FEE', payload: value }) },
    wellnessNoOfEmps: (value) => { dispatch({ type: 'NUMBER_OF_EMPLOYEES', payload: value }) },
    totalWellnessFee: (value) => { dispatch({ type: 'TOTAL_WELLNESS_FEE', payload: value }) },
  }

}
export default connect(mapStateToProps, mapDispatchToProps)(DashboardBox)


const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
`

const HomeWrapper = styled.div`
  transition: margin-left .2s ease-in;
  padding: 30px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: rgb(0 0 0 / 15%) 0px 1px 4px 0px;
`
const CareEmployeesBox = styled.div`
  display:flex;
  padding-bottom:20px;
`
const PlanSummaryBox = styled.div`
  display:grid;
  grid-template-columns:1fr 1fr 1fr;
  grid-template-rows: repeat(auto-fit);
  row-gap:24px;
  grid-gap: 24px;
  column-gap:36px;
  overflow-x:scroll
  ::-webkit-scrollbar {
      display: none;
    }
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;

`

const TotalEmployees = styled.div`
  border:2px solid #436AB2;
  padding:24px 20px;
  width:343px;
  border-radius: 8px;
  margin-right:36px;
  box-shadow: 0 1px 6px 0 rgba(0,0,0,0.13);
`
const SummaryBox = styled.div`
  border:2px solid #436AB2;
  padding:24px 18px 58px 18px;
  border-radius: 8px;
  height:181px;
  min-width:360px;
  box-shadow: 0 1px 6px 0 rgba(0,0,0,0.13);
`
const TotalLives = styled.div`
  border:2px solid #436AB2;
  padding:24px 20px;
  border-radius: 8px;
  width:343px;
  margin-right: 36px;
  box-shadow: 0 1px 6px 0 rgba(0,0,0,0.13);
`
const InnerDiv = styled.div`
  display:flex;
  align-items: center;
`
const PlanSummaryInnerDiv = styled.div`
  display:flex;
  align-items: start;
`
const NumberDiv = styled.div`
  padding-left:10px;
`
const PlanSummaryNumberDiv = styled.div`
  padding-left:13px;
`


const BoxHeading = styled.div`
  padding-top:32px;
  padding-bottom:20px;
  display:flex;
  justify-content:space-between;

`
const Line = styled.div`
  width:100%;
  height:1.5px;
  background-color: #436AB2;
`

const NotificationDiv = styled.div`
    display: flex;
    flex-direction: column;
    background: #C9D3E7;
    border-radius:8px;
    padding: 20px 136px 20px 24px;
 
`
const InstantIssuanceDIV = styled.div`
    max-width: 100%;
    border: 1px solid #436AB2;
    border-radius: 8px;
    height: 100%;
    padding: 32px 22px;
    margin: 32px 10px 10px 10px;
    box-sizing: border-box;
`

const PayDiv = styled.div`
    display: flex;
    flex-direction: row;
    text-align: center;
    margin: 0px 0px;
    align-items: center;
`

const Notification = styled.div`
  padding:30px 0px 30px 16px;
  background:#FDBD0C;
  margin:34px 0px 0px 0px ;
  border-radius: 5px;
`

const Subscription = styled.div`

`
const Duedate = styled.div`

`

const Invoice = styled.div`

`

const Buttn = styled.div`
	display:flex;
	flex-direction: row;
	align-items: center;
	border-radius: 10px;
	padding: 11px 29px 11px 29px;
	background-color: #436AB2;
	box-shadow: rgb(0 0 0 / 15%) 0px 1px 4px 0px;
	cursor:pointer;
`

const Cassless = styled.div`
  display:flex;
  flex-direction: row;
  justify-content:space-between;
  width:100%;
  height:20px;
`

const PricePart = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 4px;
    align-items: baseline;
    &> :first-child{ 
        margin-right: 8px;
    }
`

const CardHeader = styled.div`
    display: flex;
    justify-content: space-between;
`

const Links = styled.div`
    display: flex;
    flex-direction: row;
    cursor: pointer;
`

const CardHeaderRight = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 100px;
`

const PaymentLink = styled.span`
    font-weight: 600;
    font-family: Avenir;
    letter-spacing: 0.25px;
    color: #0091FF;
    cursor: pointer;
`