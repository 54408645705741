import React, { Component } from 'react'
import styled from 'styled-components'
import fitso from '../assets/images/fitso.png'
import {convert_amount, gtmCode} from '../utils/utils'
import {withRouter} from 'react-router'
import http from "../services/httpService";
import TagManager from "react-gtm-module";
import {Ptag} from './fonts'
import coin from '../assets/images/vital-coin.png'
import {sendActivityLSApi} from '../services/leadSquaredService'



function getSummary(data) {
  if (data && data) {
    return data.substr(0, 65)
  }
  return data
}

class CardImage extends Component {

  passidtomarketplaceoffersscreen = item => {
    sendActivityLSApi(231,{
      mx_Custom_1: item['brand'],
      mx_Custom_2: item['offer_name'],
    })
    localStorage.setItem('card_type', item && item.provider_type);
    localStorage.setItem('recommendation', true)
    localStorage.setItem('points_id', item.id)
    const tagManagerArgs1 = {
      gtmId: gtmCode(),
      dataLayer: {
        event: 'sessionwithproductsview',
        ecommerce: {
          currencyCode: 'INR',
          detail: {
            products: [
              {
                id: item.id,
                name: item.brand,
                category: localStorage.getItem('card_type')
              }
            ]
          }
        }
      }
    }
    TagManager.initialize(tagManagerArgs1)
    const response =  http.post('/points/insert_marketplace_eventlog', {
      offer_id: item.id,
      "event_type": "recommended_offer_view"
    })

    this.props.history.push('/marketplacepoints')
  }
  render() {

    return (
      <Wrapper>
        {this.props &&
          this.props.cards_data &&
          this.props.cards_data.map((item,index) => {
            let offer_type = item && item.offer_type && item.offer_type.toLowerCase()
            let flag = offer_type === 'deals' || offer_type === 'online_booking_deals(diagnostic)' || item.offer_mrp ===null
            const rewardCashback = item && item['cashback_rewards']
            return (
              <ParentDiv
                key={`marketplaceCards_${index}`}
                onClick={() => this.passidtomarketplaceoffersscreen(item)}
                background_image={item && item.voucher_logo}>
                <HalfDiv>
                  <TextDiv> {getSummary(item && item.offer_summary)}</TextDiv>
                  <PriceDiv>
                    <Text>
                      {' '}
                      ₹{item && item.offer_discounted_price !== 'Free' ? convert_amount(item && item.offer_discounted_price) : 0}
                    </Text>
                    {!flag ? <Textt> ₹{convert_amount(item && item.offer_mrp)}</Textt> : null}
                  </PriceDiv>
                  {
                    !!rewardCashback &&
                    <Reward>
                      <Ptag color={'white'} letterSpacing='0.3px' fontWeight={600} >
                        {'Earn'}
                      </Ptag>
                      <img height='12px' width='12px' src={coin} />
                      <Ptag color={'white'} letterSpacing='0.3px' fontWeight={600}>
                        {rewardCashback}
                      </Ptag>
                    </Reward>
                  }
                  

                </HalfDiv>
              </ParentDiv>
            )
          })}
      </Wrapper>
    )
  }
}

export default withRouter(CardImage)
const Wrapper = styled.div`
  display: flex;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  padding: 0 0 0 24px;
`

const ParentDiv = styled.div`
  margin-top: 6px;
    margin-right: 3px;
    margin-bottom: 6px;
    margin-left: 3px;
  display: flex;
  flex-direction: column;
 width: 167px;
  height: 228px;
  border-radius: 8px;
    background-image: url("${props => (props.background_image ? props.background_image : fitso)}");
  background-repeat: no-repeat;
  // background-size: 204px 152px;
  background-size:cover;
  cursor: pointer;

`
const HalfDiv = styled.div`
  margin-top: 60px;
  opacity: 1;
  border-radius: 5px;
  min-width: 167px;
`

const TextDiv = styled.div`
  display: flex;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
  margin-top: 45px;
  margin-left: 12px;
  margin-right:3px;
  height: 30px;
`
const PriceDiv = styled.div`
  display: flex;
  flex-direcion: row;
  align-items: center;
  padding-right: 12px;
  padding-bottom: 2px;
  padding-left: 12px;
  padding-top: 40px;
`
const Text = styled.p`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #ffffff;
  // padding-left: 12px;
  // padding-right: 12px;
`
const Textt = styled.p`
  font-family: Avenir;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #ffffff;
  // padding-left: 12px;
  // padding-right: 12px;
  text-decoration: line-through;
  padding-left: 8px;
`

const Reward = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px;
  & img{
    margin-right: 4px;
    margin-left: 4px;
  }
`