import React from "react";


const InvisibleForm = ({action, values, method }) => {
    return(
        <form style={{display: 'none'}} name="myForm" action={action} method={method} target="_blank">
            {
                Object.entries(values).map( item => 
                    <input
                        key={item[0]}
                        name={item[0]}
                        value={item[1]}
                    />
                )
            }
            <input id='as' type='submit' />
        </form>
    )
}

export default InvisibleForm