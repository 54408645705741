import React from "react";
import styled from "styled-components";
import { Ptag } from "../../components/fonts";
import { formatAmount } from "../../utils/utils";
import insurePlanFooter from "../../assets/images/olivefooter.png";
import RightArrow from "../../assets/images/arrowForward.png";
import { withRouter } from "react-router";
import InsuranceBenefits from './benefits'

import mfine from "../../assets/images/mfine.png"
import capsule from "../../assets/images/capsule.png"
import doctor from "../../assets/images/medicalDoctor.png"
import healthians from "../../assets/images/healthians.png"
import checkup from "../../assets/images/medicalcheckup.png"
import new1mg from "../../assets/images/new1mg.png"
import cell from '../../assets/images/cell.png'
import liver from '../../assets/images/liver.png'
import lipid from '../../assets/images/lipid.png'
import kidney from '../../assets/images/kidney.png'


class WellnessCards extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      adults: 0
    }
  }
  Cards = [];

  componentDidMount() {
    this.setState({
      policyType: 'wellness',
      openDeductable: false,
      openPremium: false,
      
    });
  }


  calculateAge = birthday => { // birthday is a date
    var ageDifMs = Date.now() - new Date(birthday.split('/').reverse()).getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }


  payNow = () => {
    
    this.props.buyPolicy();
    
  };

  handlePolicyRedirect = () => {
    window.scrollTo(0, 0);
    let data = {
      backPage: "/plans",
    };
    this.props.history.push({
      pathname: "/cashlessclaim",
      data: data,
    });
  };


  render() {
    let Data = this.props.data;
    const members = Data && Data.members || []
    const adults = Math.min(2, members.length)
    return (
      <Root>
        <Headings>
          <Ptag
            style={{'paddingBottom': '16px','paddingTop': '18px'}}
            fontSize="14px"
            letterHeight="1.71"
            fontWeight="600"
            textTransform="uppercase"
            letterSpacing='1px'
          >
            {"benefits"}
          </Ptag>
        
        </Headings>

        <InsuranceBenefits
          adults={adults}
          policyType={this.state.policyType}
          benefits={this.props.data && this.props.data.benefits}
        />


        <PaymentButtonWrapper
        >
          <div style={{ height: "6px", width: "100%", background: "white" }} />

          <PaymentButton
            backGround={insurePlanFooter}
          >
            <PaymentButtonPart>
              <EarnPointsText>{'SUBSCRIPTION FEE'}</EarnPointsText>
              <Amount>
                <Font18>
                  {`₹${formatAmount(this.props.premium ||
                      0)}`}
                </Font18>
                <Ptag letterSpacing='0.5px' color='#fff'>
                  {'/year'}
                </Ptag>

              </Amount>
            </PaymentButtonPart>
            <PaymentButtonRightPart onClick={this.payNow}>
              <PaymentButtonRightPartLeft style={{"paddingRight":"20px","paddingLeft":"20px"}}>
              <Ptag fontSize='16px' letterHeight='2' letterSpacing='0.75px' fontWeight='600'>
                {'BUY'}
              </Ptag>
               
              </PaymentButtonRightPartLeft>
              <ForwardSign bgcolor={'#97b872'}>
                <img height="20px" width="20px" src={RightArrow} />
              </ForwardSign>
            </PaymentButtonRightPart>
          </PaymentButton>
        </PaymentButtonWrapper>
        
      </Root>
    );
  }
}

export default withRouter(WellnessCards)


const PaymentButtonWrapper = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  bottom: 0px;
`;

const PaymentButtonRightPartLeft = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 6px;
`;

const PaymentButtonRightPart = styled.button`
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  border:None;
  outline:None;
  background-color: #ffffff;
  padding: 8px 8px 8px 8px;
`;

const Font18 = styled.p`
  font-family: Avenir;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1px;
  color: ${(props) => (props.color ? props.color : "#fff")};
`;

const Amount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const ForwardSign = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: ${(props) => props.bgcolor};
`;

const PaymentButtonPart = styled.div`
  display: flex;
  flex-direction: column;
`;

const EarnPointsText = styled.p`
  margin: 4px 0 8px 0;
  font-family: Avenir;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: #ffffff;
`;

const PaymentButton = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #436ab2;
  padding: 12px 12px 12px 18px;
  justify-content: space-between;
  background: url(${(props) => props.backGround ? props.backGround : insurePlanFooter});
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -3px;
  margin-right: -16px;
  margin-left: -16px;
  z-index: 3;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Headings = styled.div`
  display: flex;
  padding: ${(props) => (props.padding ? props.padding : "0")};
  flex-direction: column;
`;

