import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import image from '../../assets/images/paymentProcessing.png'
import {Ptag} from '../fonts'
import LoaderDots from '../loaderDots'

const ProcessingRequest = ({showPopup, onClickGoto}) => {

    return(
        <Home show={showPopup}>
            <Image height='140px' width='188px' src={image}/>
            <Ptag fontSize='20px' fontWeight='600' letterHeight='1.6' letterSpacing='0.19px' textAlign='center'>
                {'We are processing your request.'}
            </Ptag>
            <Ptag fontSize='20px' fontWeight='600' letterHeight='1.6' letterSpacing='0.19px' textAlign='center'>
                {'This might take a few minutes.'}
            </Ptag>
            <div style={{'marginTop': '42px'}}>
            <LoaderDots/>
            </div>
            
        </Home>
    )
}

export default ProcessingRequest

const Home = styled.div`
    display: ${props => props.show ? 'flex': 'none'};
    flex-direction: column;
    z-index: 99;
    height: 100vh;
    background-color: white;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    align-items: center;
`;

const Image = styled.img`
    margin: 92px 0 20px 0;

`;