import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from "react-router";
import home from '../../assets/images/homenew.png'
import chat from '../../assets/images/message.png'
import user from '../../assets/images/user.png'
import clipboard from '../../assets/images/clipboard.png'
import {Ptag} from '../../components/fonts'
import {maxScreenWidth} from '../../config.json'

class Footer extends Component{

    render(){

        let showVerLoop = () => {
            window.Verloop(function() {
                this.openWidget()
              })
        }
        let onClickPayment = () => {
            window.scrollTo(0,0)
            this.props.history.push('/business/payment')
        }
        let onClickDashboard = () => {
            window.scrollTo(0,0)
            this.props.history.push('/business/dashboard')
        }
        let onClickProfile = () => {
            window.scrollTo(0,0)
            this.props.history.push('/business/profile')
        }
        return(
            <Root>
                <Tab onClick={onClickDashboard}>
                    <TabImage src={home} />
                    <Ptag> HOME </Ptag>
                </Tab>
                <Tab onClick={onClickPayment}>
                    <TabImage style={{'opacity':'0.7'}} src={clipboard} />
                    <Ptag>PAYMENT</Ptag>
                </Tab>
                <Tab onClick={() => showVerLoop()}>
                    <TabImage src={chat} />
                    <Ptag>SUPPORT</Ptag>
                </Tab>
                <Tab onClick={onClickProfile}>
                    <TabImage src={user} />
                    <Ptag>PROFILE</Ptag>
                </Tab>

            </Root>
        )
    }
}

export default withRouter(Footer)

const Root = styled.div`
    display: flex;
    position: fixed;
    background-color: white;
    bottom:0;
    width: 100%;
    max-width: ${maxScreenWidth};
    padding: 10px 0;
`;

const TabImage = styled.img`
  width: 20px;
  height: 20px;
  height: auto;
  &:click {
    opacity: 1;
  }
`

const Tab = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-right: 5px;
	align-items: center;
  justify-content: center;
  cursor: pointer;
`