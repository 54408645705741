import React from "react";
import styled from "styled-components";
import { Icons, CoveredThing } from "../../components";
import AboutPremium from "../../components/aboutPremium";
import LearnDeductable from "../../components/learnDeductable";
import { Ptag } from "../../components/fonts";
import { formatAmount } from "../../utils/utils";
import insurePlanFooter from "../../assets/images/payNowBackground.png";
import RightArrow from "../../assets/images/arrowForward.png";
import PolicyType from "./constants/PolicyConstants.js";
import maroon from "../../assets/images/maroon.png";
import green from "../../assets/images/green.png";
import red from "../../assets/images/redButton.png";
import shield from '../../assets/images/shieldIcon.png'
import religareLogoRecommend from "../../assets/images/chiIcon.png";
import InsuranceBenefits from './benefits'
import { withRouter } from "react-router";
import {sendActivityLSApi} from '../../services/leadSquaredService'
import infoIcon from '../../assets/images/infoWhite.png'
import {PriceBreakPopUp} from '../'

class PlanCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adults: 0,
      deductible: 0,
      showAmountBreakUp: false,
      totalBenefitFee: 0
    }
    this.getTotalPremium = this.getTotalPremium.bind(this)
    this.renderDeductible = this.renderDeductible.bind(this)
  }
  Cards = [];

  componentDidMount() {
    let Data = this.props.data;
    
    let Premium = {
      "0": this.getTotalPremium(0, Data['insured_sum']),
      "1": this.getTotalPremium(35000, Data['insured_sum']),
      "2": this.getTotalPremium(100000, Data['insured_sum']),
    };
    const paymentData = JSON.parse(localStorage.getItem('paymentData'))
    const members = Data && Data.members || {}
    let membersRel = []
    for (const [key, value] of Object.entries(members)){
      membersRel.push(value['relation'])
    }
    membersRel = membersRel.join(', ')
    const selectedInsuredAmount = paymentData && paymentData['insured_sum'] ? paymentData['insured_sum'] : Data.insured_sum && Data.insured_sum.toString()
    const deductible = paymentData && !isNaN(paymentData['deductable']) ? paymentData['deductable'] : Data.deductable || 0
    this.setState({
      membersRel,
      policyType:this.props.policyType,
      selectedInsuredAmount,
      showInsuredSumDropDown: false,
      Premium,
      deductible,
      openDeductable: false,
      openPremium: false,
      benefits: Data.benefits,
      optionalBenefits: Data.optional_benefits
    });

    let selectedOptionalBenefits = JSON.parse(localStorage.getItem('selected_optional_benefits')) || {};
    let optionalBenefitFee = 0
	  Object.values(selectedOptionalBenefits).forEach( benefit => {
	    optionalBenefitFee += Math.ceil(benefit['cost'] * benefit['members'].length)
	  })
    this.setState({totalBenefitFee: optionalBenefitFee*12})

  }


  calculateAge = birthday => { // birthday is a date
    var ageDifMs = Date.now() - new Date(birthday.split('/').reverse()).getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }


  onClickSelectCoverage = async (amount, checkedDeductible) => {
    await this.setState({
      selectedInsuredAmount: amount,
    });
    const deductible = this.state.deductible
    const premium = this.getTotalPremium(deductible,amount);
    sendActivityLSApi(218,{
      mx_Custom_1: this.props.policyType,
      mx_Custom_2: this.state.membersRel,
      mx_Custom_3: amount,
      mx_Custom_4: this.state.deductible,
      mx_Custom_5: premium
    })
  };


  payNow = () => {
    const deductible = this.state.deductible
    const insured_sum = this.state.selectedInsuredAmount;
    const paymentData = JSON.parse(localStorage.getItem('paymentData'))
    const platformFee = paymentData && paymentData['platform_fee'] ? paymentData['platform_fee'] : this.props.data && this.props.data['platform_fee'] || 0
    const actualPremium = this.getTotalPremium(deductible,insured_sum)
    const premium = actualPremium - platformFee;
    sendActivityLSApi(219, {
      mx_Custom_1: this.props.policyType,
      mx_Custom_2: this.state.membersRel,
      mx_Custom_3: deductible,
      mx_Custom_4: insured_sum,
      mx_Custom_5: actualPremium,
    })
    this.props.buyPolicy(deductible, insured_sum, premium);
  };

  onSelectDeductible = (deductible) => {
    this.setState({deductible})
    this.onClickSelectCoverage(this.state.selectedInsuredAmount, true)
    const premium = this.getTotalPremium(deductible, this.state.selectedInsuredAmount);
    sendActivityLSApi(218,{
      mx_Custom_1: this.props.policyType,
      mx_Custom_2: this.state.membersRel,
      mx_Custom_3: this.state.selectedInsuredAmount,
      mx_Custom_4: deductible,
      mx_Custom_5: premium,
      
    })
  }

  getInsurancePremium = () => {
    const data = this.props.data && this.props.data['extra']
    const {deductible, selectedInsuredAmount} = this.state
    const premiumDetails = data && data[deductible] && data[deductible][selectedInsuredAmount] && data[deductible][selectedInsuredAmount]['premium_details'] &&
      data[deductible][selectedInsuredAmount]['premium_details'][12]
    return premiumDetails && premiumDetails['premium'] || 0
  }

  annualPlatformFee = () => {
    const data = this.props.data && this.props.data['extra']
    const {deductible, selectedInsuredAmount} = this.state
    const platformFee = data && data[deductible] && data[deductible][selectedInsuredAmount] && data[deductible][selectedInsuredAmount]['premium_details'] &&
      data[deductible][selectedInsuredAmount]['premium_details'][12] && data[deductible][selectedInsuredAmount]['premium_details'][12]['platform_fee'] || 0
    return platformFee
  }

  getPlatformFee = (addOptionalBenefit) => {
    const paymentData = JSON.parse(localStorage.getItem('paymentData'))
    const platformFee = paymentData && paymentData['platform_fee'] && addOptionalBenefit ? paymentData['platform_fee'] : this.props.data && this.props.data['platform_fee'] || 0
    return platformFee
  }

  getTotalPremium(){
    const insurancePremium = this.getInsurancePremium()
    const platformFee = this.annualPlatformFee()
    const benefits = this.state.totalBenefitFee || 0
    const total = insurancePremium + platformFee + benefits
    return total 
  }

  getBackgroundColor = () => {
    const policy = this.props.policyType;
    const background = {
      [PolicyType.COMPREHENSIVE]: "#436ab2",
      [PolicyType.HEART]: "#ff5588",
      [PolicyType.PED_INSULIN]: "#009999",
      [PolicyType.PED_NON_INSULIN]: "#f77963",
    };
    return background[policy];
  };

  getCardBackgroundColor = () => {
    const policy = this.props.policyType;
    const background = {
      [PolicyType.COMPREHENSIVE]: "#436ab2",
      [PolicyType.HEART]: "#ff5588",
      [PolicyType.PED_INSULIN]: "#009999",
      [PolicyType.PED_NON_INSULIN]: "rgba(247, 121, 99)",
    };
    return background[policy];
  };

  getPaymentButtonFooter = () => {
    const background = {
      [PolicyType.COMPREHENSIVE]: insurePlanFooter,
      [PolicyType.HEART]: maroon,
      [PolicyType.PED_INSULIN]: green,
      [PolicyType.PED_NON_INSULIN]: red,
    };
    return background[this.props.policyType];
  };

  handlePolicyRedirect = () => {
    window.scrollTo(0, 0);
    let data = {
      backPage: "/plans",
    };
    this.props.history.push({
      pathname: "/cashlessclaim",
      data: data,
    });
  };

  renderCoveredNotCovered = (flag, label) => {
    
    
    return (
      <CoverUpto>
        <FlexWrap active={true}>
          <Ptag letterSpacing='1px' fontSize='14px' textTransform='capitalize' fontWeight="600">{label}</Ptag>
          
        </FlexWrap>

        <CoveredThing
          iconHeight="24px"
          iconWidth="24px"
          fontSize="11px"
          tilePadding="1rem 0.5rem 0 0"
          features={[
            "hospitalization",
            "ambulance",
            "daycare",
            "prehospitalization",
            "posthospitalization",
          ]}
          width="9rem"
          showCovid={true}
          borderRight="none"
        />
       
      </CoverUpto>
    );
  };

  renderPolicyDoc = () => {
    const data = {
      backPage: "/plans",
    };
    var pathNameForPolicyDoc = {
      [PolicyType.COMPREHENSIVE]: "/policyDocument",
      [PolicyType.HEART]: "/cardiacDocument",
      [PolicyType.PED_INSULIN]: "/diabeticDocument",
      [PolicyType.PED_NON_INSULIN]: "/pedDocument",
    };
    return (
      <PolicyDocWrapper>
        <FlexWrap onClick={() => this.props.navigateToPage(pathNameForPolicyDoc[this.state.policyType],data)}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Ptag letterSpacing='1px' textTransform='capitalize' fontSize='14px' fontWeight="600">View Policy Terms</Ptag>
            
          </div>
          <Icons icon="chevron_right" fill="#4a4a4a" />
        </FlexWrap>
      </PolicyDocWrapper>
  )}

  renderClaimProcess = () => (
    <PolicyDocWrapper>
      <FlexWrap onClick={() => this.handlePolicyRedirect()}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Ptag letterSpacing='1px' textTransform='capitalize' fontSize='14px' fontWeight="600">Claim Process</Ptag>
          <Ptag fontSize='12px' color="#7d7d7d" letterSpacing="0.5px">
            {"8500+ hospitals in cashless network"}
          </Ptag>
        </div>
        <Icons icon="chevron_right" fill="#4a4a4a" />
      </FlexWrap>
    </PolicyDocWrapper>
  );

  renderSumInsured = () => (
    <>
      <Ptag style={{'padding': '18px 0'}} letterSpacing='1px' textTransform='capitalize' fontSize='14px' fontWeight="600">
        {'Select Cover Amount'}
      </Ptag>
      <ButtonsWrapper>
      {Object.keys(this.props.data.extra && this.props.data.extra[0] || {}).map((amount, index) => (
        <Button
          key={index}
          onClick={() => this.onClickSelectCoverage(amount)}
          selected={
            this.state && this.state.selectedInsuredAmount === amount
          }
        >
          <Ptag
            textAlign="center"
            color={
              this.state && this.state.selectedInsuredAmount === amount
                ? "white"
                : "#4a4a4a"
            }
          >
            {"₹"}
            {amount / 100000}
            {" Lakhs"}
          </Ptag>
        </Button>
      ))}
      </ButtonsWrapper>
    </>
  )

  renderDeductible = () =>{ 
    const deductibles = Object.keys(this.props.data && this.props.data['extra'] || {})
    const deductibleOptions = deductibles.map(item => Number(item));
    return (
    <>
      <Deductible>
        <Ptag letterSpacing='1px' textTransform='capitalize' fontSize='14px' fontWeight="600">
          {'Select Deductible'}
        </Ptag>
        <Ptag style={{'cursor': 'pointer'}} onClick={() => this.setState({ openDeductable: true })} letterSpacing='0.5px' color='#4a90e2' fontWeight='600'>
          {'What is this?'}
        </Ptag>
      </Deductible>

      
      <ButtonWrapper>
      {
        deductibleOptions.map( amount => 
          <SelectButtonWrapper key={amount} >
            <Button selected={this.state.deductible === amount} onClick={() => this.onSelectDeductible(amount)} >
            <Ptag
              textAlign="center"
              color={
                this.state.deductible === amount
                  ? "white"
                  : "#4a4a4a"
              }
              >
                {`₹${formatAmount(amount)}`}
            </Ptag>
            </Button>
        </SelectButtonWrapper>
        )
      }
      {/* {
        deductibleOptions[policy].indexOf(0) > -1 && 
        
      }
      {
        deductibleOptions[policy].indexOf(35000) > -1 && 
        <SelectButtonWrapper>
            <Button selected={this.state.deductible === 35000} onClick={() =>  this.onSelectDeductible(35000)}>
              <Ptag
                textAlign="center"
                color={
                  this.state.deductible === 35000
                    ? "white"
                    : "#4a4a4a"
                }
                >
                {'₹35,000'}
              </Ptag>
            </Button>
            <Ptag marginLeft="20px" maxwidth="50px" textAlign="center" fontSize='8px' fontWeight='600' letterSpacing='0.87px' color='#d73331'>
                {`${this.props.policyType !== PolicyType.COMPREHENSIVE ? '30' : '60'}% Lower Fees`}
            </Ptag>
        </SelectButtonWrapper>
      }
      {
        deductibleOptions[policy].indexOf(100000) > -1 && 
        <SelectButtonWrapper>                                    
            <Button selected={this.state && this.state.deductible === 100000}  onClick={() => this.onSelectDeductible(100000)}>
              <Ptag
                textAlign="center"
                color={
                  this.state && this.state.deductible === 100000
                    ? "white"
                    : "#4a4a4a"
                }
                >
                {'₹1,00,000'}
              </Ptag>
            </Button>
            <Ptag marginLeft="20px"  maxwidth="50px" textAlign="center" fontSize='8px' fontWeight='600' letterSpacing='0.87px' color='#d73331'>
                {'75% Lower Fees'}
            </Ptag>
        </SelectButtonWrapper>
      }
       {
        deductibleOptions[policy].indexOf(200000) > -1 && 
        <SelectButtonWrapper>                                    
            <Button selected={this.state && this.state.deductible === 100000}  onClick={() => this.onSelectDeductible(100000)}>
              <Ptag
                textAlign="center"
                color={
                  this.state && this.state.deductible === 100000
                    ? "white"
                    : "#4a4a4a"
                }
                >
                {'₹1,00,000'}
              </Ptag>
            </Button>
            <Ptag marginLeft="20px"  maxwidth="50px" textAlign="center" fontSize='8px' fontWeight='600' letterSpacing='0.87px' color='#d73331'>
                {'75% Lower Fees'}
            </Ptag>
        </SelectButtonWrapper>
      }
       {
        deductibleOptions[policy].indexOf(100000) > -1 && 
        <SelectButtonWrapper>                                    
            <Button selected={this.state && this.state.deductible === 100000}  onClick={() => this.onSelectDeductible(100000)}>
              <Ptag
                textAlign="center"
                color={
                  this.state && this.state.deductible === 100000
                    ? "white"
                    : "#4a4a4a"
                }
                >
                {'₹1,00,000'}
              </Ptag>
            </Button>
            <Ptag marginLeft="20px"  maxwidth="50px" textAlign="center" fontSize='8px' fontWeight='600' letterSpacing='0.87px' color='#d73331'>
                {'75% Lower Fees'}
            </Ptag>
        </SelectButtonWrapper>
      }
         */}
        
      </ButtonWrapper>
      
    </>
    )
  }

  onClickAmountBreakupIcon = () => {
    this.setState({showAmountBreakUp: true})
  }

  onClickCloseAmountBreakUp = () => {
    this.setState({showAmountBreakUp: false})
  }

  render() {
    let selectedOptionalBenefits = JSON.parse(localStorage.getItem('selected_optional_benefits')) || {};
    let optionalBenefitFee = 0
	  Object.values(selectedOptionalBenefits).forEach( benefit => {
	    optionalBenefitFee += Math.ceil(benefit['cost'] * benefit['members'].length)
	  })
    const amountComponents = [
      {
        text: 'Insurance Premium',
        value: this.getInsurancePremium(),
      },
      {
        text: 'Health Benefits',
        value: this.annualPlatformFee(),
      },
      {
			  text: 'Add. Wellness Benefits',
			  value: optionalBenefitFee*12
			}
    ]

    

    return (
      <Root>
        <Headings>
          <Ptag
            style={{'padding':'16px 8px 18px 8px'}}
            fontSize="16px"
            letterHeight="1.71"
            fontWeight="600"
            letterSpacing='1px'
          >
            {"Benefits Included In Your Plan"}
          </Ptag>
          

          <BlueWrapper border={this.getCardBackgroundColor()}>

            <CardHeader>
              <CardHeaderTileTitle>
                <CardIcon src={shield}/>
                <HeadingWrapper>
                <Ptag fontSize='16px' fontWeight='600' color='#436ab2' letterSpacing='0.57px' letterHeight='1.5'>
                  {'Insurance'}
                </Ptag>
                {/* <Ptag color='#666666' letterSpacing='0.5px' letterHeight='1.8'>
                  {'For hospitalisation expenses'}
                </Ptag> */}
                </HeadingWrapper>
              </CardHeaderTileTitle>
              
              <ThirdParty>
                <Ptag fontSize='8px' letterSpacing='0.5px' color='rgba(0,0,0,0.6)' textTransform='uppercase'>
                  {'ISSUED By'}
                </Ptag>
                <img src={religareLogoRecommend} width='auto' height='28px'/>
              </ThirdParty>
            </CardHeader>


            {this.renderCoveredNotCovered("cover", "Coverage")}
            {this.renderPolicyDoc()}
            {this.renderClaimProcess()}
            {this.renderSumInsured()}
            {this.renderDeductible()}

          </BlueWrapper>
        </Headings>

        <InsuranceBenefits
          policyType={this.state.policyType}
          benefits={this.state.benefits}
        />

        <PaymentButtonWrapper
        >
          <div style={{ height: "6px", width: "100%", background: "white" }} />

          <PaymentButton
            backGround={this.getPaymentButtonFooter()}
          >
            <PaymentButtonPart>
              <EarnPointsText>{'SUBSCRIPTION FEE'}</EarnPointsText>
              <Amount>
                <Font18>
                  {`₹${formatAmount(this.getTotalPremium())}`}
                </Font18>
                <Ptag style={{'marginBottom' : '2px'}} letterSpacing='0.5px' color='#fff'>
                  {'/year'}
                </Ptag>
                <InfoIconImage onClick={this.onClickAmountBreakupIcon} src={infoIcon} height={'18px'} width='18px' />
              </Amount>
            </PaymentButtonPart>

            <PaymentButtonRightPart onClick={this.payNow}>
              <PaymentButtonRightPartLeft style={{"paddingRight":"20px","paddingLeft":"20px"}}>
              <Ptag fontSize='16px' letterHeight='2' letterSpacing='0.75px' fontWeight='600'>
                {'BUY'}
              </Ptag>
               
              </PaymentButtonRightPartLeft>
              <ForwardSign bgcolor={this.getBackgroundColor()}>
                <img height="20px" width="20px" src={RightArrow} />
              </ForwardSign>
            </PaymentButtonRightPart>
          </PaymentButton>
        </PaymentButtonWrapper>
        {this.state && this.state.openDeductable && (
          <LearnDeductable
            BackBtn={() => this.setState({ openDeductable: false })}
          />
        )}
        {this.state && this.state.openPremium && (
          <AboutPremium BackBtn={() => this.setState({ openPremium: false })} />
        )}
        <PriceBreakPopUp
          showPopup={this.state.showAmountBreakUp}
          closePop={this.onClickCloseAmountBreakUp}
          amounts={amountComponents}
        />
      </Root>
    );
  }
}

export default withRouter(PlanCards)

const BlueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.border};
  border-radius: 10px;
  padding: 18px 14px;
  margin-bottom: 16px;
`

const CardHeaderTileTitle = styled.div`
  display: flex;
  align-items: center;
  & > img{
    margin-right: 4px;
  }
`

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${ props => props.paddingBottom ? props.paddingBottom : '26px'};
  margin: -18px -14px 0 -14px;
  padding: ${props => props.paddingBottom ? `18px 14px ${props.paddingBottom} 14px` : '14px 14px 13px 14px'};
  border: 1px solid #dadada;
  border-radius: 10px 10px 0 0;
  background-color: #e0eafd;
`


const CardIcon = styled.img`
  width:auto !important;
  height: 36px;
`

const ThirdParty = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  align-items: baseline;
  & > p {
    margin-bottom: ${props => props.marginBottomText || '4px'};
  };
  & > img {
    object-fit: contain;
  }
`

const FlexWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: ${(props) => (props.active ? "1.2rem" : "0")};
`;


const CoverUpto = styled.div`
  padding: 1.8rem 0;
  border-bottom: 1px solid rgba(74,144,226,0.3);
  & > h2 {
    margin-bottom: 0.8rem;
  }

  & > button {
    margin-top: 1.2rem;
  }
`;


const PolicyDocWrapper = styled.div`
  padding: 1.8rem 0;
  border-bottom: 1px solid rgba(74, 144, 226, 0.3);

  & > h2 {
    margin-bottom: 0.8rem;
  }

  & > button {
    margin-top: 1.2rem;
  }
`;


const Deductible = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 16px;
  align-items: flex-end;
`

const SelectButtonWrapper = styled.div`
    display: flex;
    flex-direction:column;
    & > :first-child{
        margin-bottom: 12px;
    }
`


const PaymentButtonWrapper = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  bottom: 0px;
  z-index: 100;
`;

const PaymentButtonRightPartLeft = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 6px;
`;

const PaymentButtonRightPart = styled.button`
  display: flex;
  align-items: center;
  flex-direction: row;
  border-radius: 10px;
  border:None;
  outline:None;
  background-color: #ffffff;
  padding: 8px 8px 8px 8px;
  cursor: pointer;
`;

const Font18 = styled.p`
  font-family: Avenir;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1px;
  color: ${(props) => (props.color ? props.color : "#fff")};
`;

const Amount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const ForwardSign = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 16px;
  background-color: ${(props) => props.bgcolor};
`;

const PaymentButtonPart = styled.div`
  display: flex;
  flex-direction: column;
`;

const EarnPointsText = styled.p`
  margin: 4px 0 8px 0;
  font-family: Avenir;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: #ffffff;
`;

const PaymentButton = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #436ab2;
  padding: 12px 12px 12px 18px;
  justify-content: space-between;
  background: url(${(props) => props.backGround ? props.backGround : insurePlanFooter});
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -3px;
  margin-right: -16px;
  margin-left: -16px;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 16px;
  flex-direction: row;
  flex-wrap:wrap;
`;

const Headings = styled.div`
  display: flex;
  padding: ${(props) => (props.padding ? props.padding : "0")};
  flex-direction: column;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Button = styled.button`
  font-family: Avenir;
  width: 90px;
  height: 36px;
  border-radius: 8px;
  border: solid 1px #436ab2;
  background-color: ${(props) => (props.selected ? "#436ab2" : "transparent")};
  margin-right: 12px;
  margin-bottom: 12px;
  cursor: pointer;
  :focus{
    outline: none
  }
`;


const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  `

const InfoIconImage = styled.img`
  margin: 0 0 4px 4px;
`