import React from 'react'
import styled from 'styled-components'
import { convert_amount } from '../utils/utils'
import Fitso from '../assets/images/fitso-logo-1.png'
import fitso from '../assets/images/fitso.png'
import coin from '../assets/images/vital-coin.png'
import {Ptag} from '../components/fonts'

class CategoryOffers extends React.Component {
  render() {
    let off = '% off'
    return (
      <Wrapper>
        {this.props &&
          this.props.cards_data &&
          this.props.cards_data.map(item => {
            let offer_type = item && item.offer_type && item.offer_type.toLowerCase()
           // let flag = offer_type === 'deals' || offer_type === 'online_booking_deals(diagnostic)'
            let flag = item && item.offer_mrp
            const {cashback_rewards} = item
            return (
              <ParentDiv
                onClick={() => this.props.marketplacepoints(item.id)}
                background_image={item && item.voucher_logo}>
                <HalfDiv>
                  <TextDiv> {getSummary(item && item.offer_summary)}</TextDiv>
                  <PriceDiv>
                    <Text>
                      ₹
                      {item && item.offer_discounted_price !== 'Free' ? convert_amount(item.offer_discounted_price) : 0}{' '}
                    </Text>
                    {flag ? <Textt>₹{convert_amount(item && item.offer_mrp)}</Textt> : null}
                  </PriceDiv>
                  {
                    !!cashback_rewards &&
                    <Reward>
                      <Ptag color='white' fontSize={'12px'} letterSpacing='0.3px' fontWeight={'600'} >
                        {'Earn'}
                      </Ptag>
                      <img height='12px' width='12px' src={coin} />
                      <Ptag color='white' fontSize={'12px'} letterSpacing='0.3px' fontWeight={'600'}>
                        {cashback_rewards}
                      </Ptag>
                    </Reward>
                  }
                </HalfDiv>
              </ParentDiv>
            )
          })}
      </Wrapper>
    )
  }
}

const PercentageDiv = styled.div``
const ImageWrapper = styled.div``
const Wrapper = styled.div`
  display: flex;
  overflow-x: auto;
  -webkit-scrollbar {
    display: none;
  }
  flex-wrap: wrap;
  margin-top: 24px;
  // width: 375px;
`
const Tile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 14px;
  margin-bottom: 8px;
  width: 167px;

  border-radius: 10px;
  background-image: url("${props => (props.background_image ? props.background_image : Fitso)}");
    // background-size: 160px 160px;
     background-repeat: no-repeat;
     background-size:cover;

  background-color: ${props => (props.background_color ? props.background_color : 'palevioletred')};;
`
const Mainimg = styled.div`
  position: relative;
  top: 0;
  left: 10px;
`
const Image = styled.img``
const CardString = styled.div`
  width: 167px;
  // height: 119px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #ffffff;
  padding-left: 12px;
  padding-right: 12px;
`
const Price = styled.div`
  display: flex;
  flex-direction: row;
  // justify-content: space-between;

  padding: 0px 12px 15px 12px;
`
const DiscountedPrice = styled.div`
  width: 43px;
  // height: 19px;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.33px;
  color: #ffffff;
`
const RealPrice = styled.div`
  width: 43px;
  // height: 19px;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;

  letter-spacing: normal;
  color: #ffffff;
`

const Discount = styled.div`
  width: 55px;
  // height: 19px;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;

  letter-spacing: normal;
  color: #ffffff;
`

// from dashboard
const ParentDiv = styled.div`
  margin: 6px;
  display: flex;
  flex-direction: column;
  width: 167px;
    height: 227px;
    border-radius: 10px;
  
  border-radius: 8px;
    background-image: url("${props => (props.background_image ? props.background_image : fitso)}");
  background-repeat: no-repeat;
  // background-size: 204px 210px;
  background-size:cover;
`
const HalfDiv = styled.div`
  margin-top: 65px;
  opacity: 1;
  border-radius: 5px;
`

const Reward = styled.div`
  display: flex;
  align-items: center;
  padding: 0 12px
  & img{
    margin-right: 4px;
    margin-left: 4px;
  }
`

const TextDiv = styled.div`
  display: flex;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: normal;
  color: #ffffff;
  text-align: left;
  margin-top: 40px;
  margin-left: 10px;
  margin-right: 10px;
  height: 30px;
  
`
const PriceDiv = styled.div`
  display: flex;
  flex-direcion: row;
  align-items: center;
  padding-top: 40px;
  padding-right: 12px;
  padding-bottom: 8px;
  padding-left: 12px;
`
const Text = styled.p`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #ffffff;
`
const Textt = styled.p`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.17px;
  color: #e8e8e8;
  padding-left: 8px;
  text-decoration: line-through;
`
const Texttt = styled.p`
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.17px;
  color: #ffffff;
  padding-left: 12px;
`

export default CategoryOffers
function getWords(str) {
  if (str !== null) {
    return str
      .split(/\s+/)
      .slice(0, 9)
      .join(' ')
  }
  return null
}
function getSummary(data) {

  if (data && data) {
    return data.substr(0, 65)
  }
  return data
}
// export default class CategoryOffers extends React.Component {
//   render() {
//     let off = '%off'
//     return (
//       <Wrapper>
//         {this.props &&
//           this.props.cards_data &&
//           this.props.cards_data.map(item => {
//             return (
//               <MainDiv color ={ item.template_color ? item.template_color : "#326a68"}  onClick={() => this.props.marketplacepoints(item.id)}>
//                 <Container >
//                   <LargeImageDiv>
//                     {console.log("item inside is", item)}
//                     <LargeImage src={item && item.voucher_logo ? item.voucher_logo : Fitso} />
//                     {/*<LargeImage src={ Fitso}/>*/}
//                   </LargeImageDiv>
//
//                   <LogoDiv>
//                     <LogoImage src={ item && item.logo ?  item.logo :FitsoLogo} />
//                     {/*<LogoImage src={FitsoLogo} />*/}
//
//                   </LogoDiv>
//                 </Container>
//                 <Inertxt>
//                   <CardSringData>{getWords(item && item.offer_summary)}</CardSringData>
//                   <PriceDiv>
//                     <Text> ₹{(item && item.offer_discounted_price) || 100}</Text>
//                     <Textt> ₹{(item && item.offer_mrp) || 200}</Textt>
//                     <Texttt> {(item && item.offer_discount) || 20}%off</Texttt>
//                   </PriceDiv>
//                 </Inertxt>
//               </MainDiv>
//             )
//           })}
//       </Wrapper>
//     )
//   }
// }
//
// // const Wrapper = styled.div`
// //   display: flex;
// //   overflow-x: auto;
// //   -webkit-scrollbar {
// //     display: none;
// //   }
// //   flex-wrap: wrap;
// //  margin-top: 20px;
// //   width: 375px;
// // `
// // const MainDiv = styled.div`
// //   width: 167px;
// //   // height: 207px;
// //   background-color: #326a68;
// //   min-width: 167px;
// //  margin-right: 10px;
// //     margin-bottom: 10px;
// //   border-radius: 5px;
// // `
// // const LargeImageDiv = styled.div`
// //   // width: 147px;
// //   // height: 127px;
// //   // margin-left: 10px;
// //   // margin-top: 10px;
// //   position: absolute;
// //   display: flex;
// //   justify-content: center;
// //   align-items: center;
// //   overflow: hidden;
// // `
// // const LargeImage = styled.img`
// //   src: url(${props => props.src});
// //   width: 100%;
// //   height: auto;
// // `
//
// // const LogoImage = styled.img`
// //   src: url(${props => props.src});
// //   width: auto;
// //   height: 20px;
// // `
// // const LogoDiv = styled.div`
// //   width: 73px;
// //   height: 24px;
// //   position: absolute;
// //   top: 10px;
// //   left: 10px;
// // `
//
// // const CardSringData = styled.div`
// //   // width: 147px;
// //   // height: 40px;
// //   font-family: Open Sans;
// //   font-size: 12px;
// //   font-weight: normal;
// //   font-stretch: normal;
// //   font-style: normal;
// //   line-height: 1.67;
// //   letter-spacing: normal;
// //   color: #ffffff;
// //   margin-top: 140px;
// //   // margin-left: 10px;
// // `
//
// // const Container = styled.div`
// //   position: relative;
// // `
// // const Inertxt = styled.div`
// // padding-left:10px;
// // padding-top: 30px;
// // `
// // const PriceDiv = styled.div`
// //   display: flex;
// //   flex-direcion: row;
// //   align-items: center;
// //   padding-right: 5px;
// //     padding-left: 5px;
// //   justify-content: space-between;
// // `
// // const Text = styled.p`
// //   font-family: Open Sans;
// //   font-size: 14px;
// //   font-weight: 600;
// //   font-stretch: normal;
// //   font-style: normal;
// //   line-height: normal;
// //   letter-spacing: 1.67px;
// //   color: #ffffff;
//
// // `
// // const Textt = styled.p`
// //   font-family: Open Sans;
// //   font-size: 14px;
// //   font-weight: 600;
// //   font-stretch: normal;
// //   font-style: normal;
// //   line-height: normal;
// //   letter-spacing: 1.17px;
// //   color: #e8e8e8;
//
// //   text-decoration: line-through;
// // `
// // const Texttt = styled.p`
// //   font-family: Open Sans;
// //   font-size: 14px;
// //   font-weight: 600;
// //   font-stretch: normal;
// //   font-style: normal;
// //   line-height: normal;
// //   letter-spacing: 1.17px;
// //   color: #ffffff;
//
// // `
//
// const Wrapper = styled.div`
//   display: flex;
//   overflow-x: auto;
//   -webkit-scrollbar {
//     display: none;
//   }
//   flex-wrap: wrap;
//   margin-top: 20px;
//   // width: 375px;
// `
// const MainDiv = styled.div`
//   width: 167px;
//   // height: 207px;
//   background-color: ${props => props.color};
//   min-width: 167px;
//   margin-right: 10px;
//   margin-bottom: 10px;
//   border-radius: 5px;
// `
// const LargeImageDiv = styled.div`
//   height: auto;
//   position: absolute;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow: hidden;
// `
// const LargeImage = styled.img`
//   src: url(${props => props.src});
//   width: 100%;
//   height: auto;
// `
//
// const LogoImage = styled.img`
//   src: url(${props => props.src});
//   width: auto;
//   height: 20px;
// `
// const LogoDiv = styled.div`
//   position: absolute;
//   top: 14px;
//   left: 10px;
// `
// const Inertxt = styled.div`
//   padding-left: 10px;
//   padding-top: 30px;
// `
//
// const CardSringData = styled.div`
//   font-size: 12px;
//   font-family: Open Sans;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   letter-spacing: normal;
//   color: #ffffff;
//   margin-top: 100px;
//   padding-right: 5px;
//   padding-bottom: 10px;
// `
//
// const Container = styled.div`
//   position: relative;
// `
// const PriceDiv = styled.div`
//   display: flex;
//   flex-direcion: row;
//   align-items: center;
//   padding-right: 10px;
//   // justify-content: space-between;
//   justify-content: start;
//   padding-bottom: 10px;
// `
// const Text = styled.p`
//   font-family: Open Sans;
//   font-size: 16px;
//   font-weight: 600;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   // letter-spacing: 1.67px;
//   color: #ffffff;
// `
// const Textt = styled.p`
//   font-family: Open Sans;
//   font-size: 12px;
//   font-weight: 600;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   // letter-spacing: 1.17px;
//   color: #e8e8e8;
//   text-decoration: line-through;
//   padding-left: 5px;
// `
// const Texttt = styled.p`
//   font-family: Open Sans;
//   font-size: 12px;
//   font-weight: 600;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   // letter-spacing: 1.17px;
//   color: #ffffff;
//   padding-left: 10px;
// `
