import React, { Component, Fragment } from 'react'
import styled, { keyframes } from 'styled-components'
import creditNotEligible from "../../assets/images/pendingCredit.png"
import {Ptag} from '../../components/fonts'


export default class ApplyCreditModal extends Component {

    state = {
        ModalTexts:[
            {
                'Heading':'Apply Credit',
                'subHeading':'Please apply for credit to avail EMI',
                'buttonText':'APPLY NOW',
                'navigateTo': '/pan'
            },
            {
                'Heading':'KYC Pending',
                'subHeading':'Please get your KYC done to avail EMI',
                'buttonText':'GET KYC DONE',
                'navigateTo':'/agreement'
            },
            {
                'Heading':'E-mandate Pending',
                'subHeading':'Please get your E-Mandate done to avail EMI',
                'buttonText':'GET E-MANDATE DONE',
                'navigateTo':'/e_mandate'
            }
        ],
        creditCase:0
    }
    componentDidMount(){
        if (this.props.CreditData && !this.props.CreditData['is_kyc']){
            this.setState({creditCase:1})
        } else if(this.props.CreditData && this.props.CreditData['is_kyc'] && !this.props.CreditData['is_emandate'] ){
            this.setState({creditCase:2})
        }
    }

    render(){
        return (
            <Root>
                <Modal>
                    <img src={creditNotEligible} alt="creditStatus.svg" />
                    <Ptag fontSize='20px' fontWeight='600' letterSpacing='0.5px'>
                        {this.state && this.state.ModalTexts && this.state.ModalTexts[this.state.creditCase]['Heading']}
                    </Ptag>
                    <Ptag fontSize='14px' letterSpacing='0.18px'>
                        {this.state && this.state.ModalTexts && this.state.ModalTexts[this.state.creditCase]['subHeading']}
                    </Ptag>
                    <Ptag onClick={()=> this.props.navigateToCreditFlow(this.state.ModalTexts[this.state.creditCase]['navigateTo'])} textAlign='right' fontSize='16px' letterSpacing='1px' color='#436ab2' fontWeight='bold'>
                        {this.state && this.state.ModalTexts && this.state.ModalTexts[this.state.creditCase]['buttonText']}
                    </Ptag>
                </Modal>
            </Root>
        )
    }
}

const Root = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0 2.4rem;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.15),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.9);
  border-radius: 1rem;
  padding: 2.4rem 2.4rem 2.4rem 2.8rem;
  & img {
    width: 14.4rem;
    margin-bottom: 1.8rem;
  }
  & > :nth-child(2){
    margin-bottom: 1.8rem;
  }
  & > :nth-child(3){
    margin-bottom: 3rem;
  }
`;