import React from "react";
import { Ptag } from "../fonts";
import {
  ECardDetailWrapper,
  ECardRightSection,
  ECardStrip,
  ECardWrapper,
  InsurerLogoWrapper,
} from "./styles";

const ECard = ({ member, noStrip }) => {
  // formatting relationship
  const formatRelationship = (text) => {
    let title = text;
    switch (text) {
      case "FATHERINLAW":
        title = "Father-in-Law";
        break;
      case "MOTHERINLAW":
        title = "Mother-in-Law";
        break;
    }
    return title.toLowerCase();
  };
  return (
    <ECardWrapper>
      {!noStrip && <ECardStrip />}
      <ECardDetailWrapper>
        <div>
          <Ptag fontSize="12px">
            Name
          </Ptag>
          <Ptag marginTop="4px" fontSize="16px">
            {member.name}
          </Ptag>
        </div>
        <ECardRightSection>
          <Ptag
            marginBottom="4px"
            marginTop="4px"
            fontSize="10px"
            fontWeight="400"
          >
            ISSUED BY
          </Ptag>
          <InsurerLogoWrapper>
            <img src={member.insurer} alt="" />
          </InsurerLogoWrapper>
        </ECardRightSection>
        <div>
          <Ptag fontSize="12px">
            Policy Number
          </Ptag>
          <Ptag marginTop="4px" fontSize="16px">
            {member.policy_number}
          </Ptag>
        </div>
        <ECardRightSection>
          <Ptag fontSize="12px">
            Client ID
          </Ptag>
          <Ptag marginTop="4px" fontSize="16px">
            {member.client_id || '-'}
          </Ptag>
        </ECardRightSection>
        <div>
          <Ptag fontSize="12px">
            Date of Birth
          </Ptag>
          <Ptag marginTop="4px" fontSize="16px">
            {member.dob}
          </Ptag>
        </div>
        <ECardRightSection>
          <Ptag fontSize="12px">
            Relationship
          </Ptag>
          <Ptag textTransform="capitalize" marginTop="4px" fontSize="16px">
            {formatRelationship(member.relationship)}
          </Ptag>
        </ECardRightSection>
      </ECardDetailWrapper>
    </ECardWrapper>
  );
};

export default ECard;
