import React from 'react'
import styled from 'styled-components'
// import voucherScreenInHand1 from '../assets/svgs/voucherScreenInHand1.svg'
import redeemVitalPoints2 from '../assets/images/redeemVitalPoints2.png'
import stripHalfCircleYellow1 from '../assets/svgs/flyingLogoWebsite/stripHalfCircleYellow1.svg'
import halfCircleLightGreen3 from '../assets/svgs/flyingLogoWebsite/halfCircleLightGreen3.svg'

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 3.6rem;
  background-color: #eeeeee;
  position: relative;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    padding: 4.8rem 0;
  }

  @media (min-width: 1680px) {
    padding: 15.2rem 0 14.6rem 0;
  }
`

const Header = styled.div`
  padding: 4.8rem 2.4rem 2.6rem 2.4rem;
  width: 31rem;

  & > p {
    margin-top: 1.2rem;
    padding-right: 5rem;
  }

  @media (min-width: 1024px) {
    padding: 0;
    h3 {
      // margin-bottom: 1.8rem;
    }
    & > p {
      width: 29rem;
      // margin-top: 0;
      margin-top: 1.8rem;
      padding: 0;
    }
  }

  @media (min-width: 1680px) {
    width: 60rem;
    h2 {
      width: 44rem;
      // margin-bottom: 4rem;
      font-size: 6.2rem;
      font-weight: normal;
      line-height: 1.61;
      letter-spacing: 0.03px;
    }
    & > p {
      width: 57rem;
      margin-top: 4rem;
      padding: 0;
    }
  }
`

const ImageWrapper = styled.div`
  width: 50rem;
  @media (max-width: 425px) {
    width: auto;
  }
`

const Image = styled.div`
  width: 30rem;
  height: 40.859rem;
  // height: 31.32rem;
  background: url(${"http://images.getvital.in/homelanding/redeemVitalPoints2.png"});
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;

  @media (min-width: 1024px) {
    // margin: 0;
    width: 50rem;
    height: 68.1rem;
  }
  @media (min-width: 1680px) {
    // width: 75rem;
    // height: 102.149rem;
    // // height: 68.1rem;
    // background: url(${"http://images.getvital.in/homelanding/redeemVitalPoints2.png"});
    // background-repeat: no-repeat;
    // background-size: cover;
  }
`

const Logo1 = styled.div`
  width: 3rem;
  height: 10rem;
  background: url(${stripHalfCircleYellow1});
  background-repeat: no-repeat;
  position: absolute;
  bottom: 25%;
  right: 0;
`

const Logo2 = styled.div`
  width: 6rem;
  height: 10rem;
  background: url(${halfCircleLightGreen3});
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
`

const H3 = styled.h3`
  font-family: Proxima Nova;
  font-size: 2.4rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  text-align: left;
  color: #4a4a4a;
  width: 319px;
  margin-bottom: 12px;

  @media (min-width: 1024px) {
    line-height: 1.16;
    margin-left: 140px;
    font-size: 28px;
  }

  @media (min-width: 1680px) {
    font-size: 6.2rem;
    font-weight: 600;
    letter-spacing: 0.03px;
  }
`

const Font16 = styled.p`
  font-family: Avenir;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a4a;

  @media (min-width: 1680px) {
    font-size: 3.4rem;
    line-height: 1.59;
    letter-spacing: 0.02px;
  }
`
const Font20 = styled.div`
  font-family: Avenir;
  font-size: 16px;
  // width: 32.7rem;
  // padding-left: 2.8rem;
  // padding-top: 2.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.01px;
  // padding-right: 24px;
  // text-align: center;
  color: #4a4a4a;
  // z-index: 100;

  @media (min-width: 1024px) {
    // font-size: 34px;
    margin-left: 140px;
    font-family: Avenir;
    font-size: 18px;
    width:100%;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: #4a4a4a;
  }
`

export default function Page25() {
  return (
    <Wrapper>
      <Header>
        <H3>
          Use Points to claim offers
        </H3>
        <Font20>
          Burn your Vital Points in exchange for exciting offers. New offers added everyday!
        </Font20>

        {/* <Font16>Use Points to avail exclusive offers from merchants in our network</Font16> */}
      </Header>
      <ImageWrapper>
        <Image alt="points to claim" />
      </ImageWrapper>
      <Logo1 />
      <Logo2 />
      {/* <img src={voucherScreenInHand1} alt="voucherScreenInHand.png" /> */}
    </Wrapper>
  )
}
