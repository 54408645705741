import React, { Component } from 'react'

function OpenRoute(ChildComponent){
    return class extends Component{
        constructor(props){
            super(props)           
        }
        render(){
            return(
                <>
                    <ChildComponent {...this.props} />
                </>
            )
            
        }
    }
}

export default OpenRoute