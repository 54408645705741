import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router'


const ThankYou = () =>{
    return (
        <>
                <Body>
                    <Root>
                        <TextUpper>Thank You!</TextUpper>
                        <Text>We will share your endorsement file with the insurer. Your account manager will get back to you with the final quotes, incase of net employee additions. In the scenario of net employee deletions, you will receive a refund in your CD Account within 7-10 business days. </Text>
                    </Root>
                </Body>
        </>
    )
}

const Body = styled.div`
    padding: 24px;
    display: flex;
    flex: 1;
    justify-content: center;
    background-color: #ffffff;
`

const Root = styled.div`
  display: flex;
  padding: 8rem 10rem;
  width: 86%;
  margin-top: 10rem;
  flex-direction: column;
  background-color : #FDF6E9;
  border-radius : 12px;
  justify-content: center;
  align-items: center;
`
const TextUpper = styled.p`
  text-align: center;
  font-family: 'Avenir';
  font-size: 30px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
`

const Text = styled.p`
  margin: 12px 0 24px 0;
  text-align: center;
  font-family: 'Avenir';
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
`

export default withRouter(ThankYou)