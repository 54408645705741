import ReactGA from "react-ga";
import config from "./config.json";
export const initGA = (trackingID) => {
  if (config.env === "dev" || config.env === "stage")
    ReactGA.initialize(trackingID);
};
export const PageView = () => {
  // pageview happens only if it is prod environment .Even if somepage is commented or not ,this will make sure pageview happens only for prod
  if (config.env === "dev" || config.env === "stage") {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
};
export const Event = (category, action, label) => {
  if (config.env === "prod") {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    })
  }
};
