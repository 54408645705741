import React, { Component } from "react";
import styled from "styled-components";
import Religare from "../assets/images/landingpage3/Religare.png";
import circle100 from "../assets/images/landingpage3/circle100.png";
// import claimStats from '../assets/svgs/claimStats.svg'
import claimStats from "../assets/images/claimStats.png";
import claimStats2 from "../assets/images/claimStats2.png";
import circle37 from "../assets/images/landingpage3/circle37.png";

const Wrapper = styled.div`
  width: 100%;
  background: #fff;
  padding: 3.6rem 0 4.8rem 0;

  @media (min-width: 1023px) {
    padding: 6.5rem 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
`;
const Header = styled.div`
  width: 32rem;
  padding-left: 2.4rem;
  margin-bottom: 3rem;

  h2 {
    margin-bottom: 1.2rem;
  }
  @media (min-width: 1023px) {
    padding-left: 0;
    width: 40rem;
    margin-left: 140px;
  }
  @media (min-width: 1680px) {
    width: 75rem;
    h2 {
      margin-bottom: 4.8rem;
    }
  }
`;

const Image = styled.img`
	width: 30rem;
	height: 42rem;
//   background: url(${claimStats2});
//   background-repeat: no-repeat;
//   background-size: cover;

  @media (min-width: 1024px) {
	// background: url(${claimStats2});
    // background-size: cover;
    width: 52rem;
    height: 73.96rem;
  }
  @media (min-width: 1680px) {
    // background: url(${claimStats2});
    // background-size: cover;
    width: 52rem;
    height: 73.96rem;
  }
`;

const H2 = styled.h2`
  font-family: Proxima Nova;
  font-size: 2.4rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    width: 100%;
    font-size: 3.4rem;
    line-height: 1.29;
    letter-spacing: 0.02px;
  }

  @media (min-width: 1680px) {
    font-size: 6rem;
    letter-spacing: 0.03px;
  }
`;

const H3 = styled.h3`
  margin-top: 1.2rem;
  font-size: 1.6rem;
  font-family: Avenir;
  font-weight: normal;
  line-height: 1.75;
  letter-spacing: 0.02px;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    margin-top: 2.4rem;
    font-size: 1.8rem;
    line-height: 1.56;
    letter-spacing: normal;
  }

  @media (min-width: 1680px) {
    margin-top: 4.5rem;
    font-size: 3.4rem;
    font-weight: normal;
    letter-spacing: 0.02px;
    line-height: 1.59;
  }
`;

const ImageContainer = styled.div`
  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

export default class Page35 extends Component {
  render = () => (
    <Wrapper>
      <Header>
        <H2>
          Developed in partnership with Care Health Insurance Limited
        </H2>
        <H3>
          This policy will be issued and serviced by Care Health Insurance
          Limited (formerly known as Religare Health Insurance Company Limited).
        </H3>
      </Header>
      <ImageContainer>
        <Image src={claimStats2} />
      </ImageContainer>
      {/* <img src={circle92} alt="circle92" /> */}
      {/* <img src={circle37} alt="circle37.png" /> */}
      {/* <img src={claimStats} alt="circle92" /> */}
    </Wrapper>
  );
}
