import styled from "styled-components";

const HomeWrapper = styled.div`
  margin-left: 248px;
  transition: margin-left 0.2s ease-in;
  padding: 30px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-bottom: 12px;
  box-shadow: rgb(0 0 / 15%) 0px 1px 4px 0px;
`;


const MessageWrapper = styled.div`
  transition: margin-left 0.2s ease-in;
  padding: 50px 80px;
  padding-bottom: 174px;
  box-sizing: border-box;
  background: #fff;
  height: 58vh;
  text-align: center;
  /* margin-left: 200px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const MsgTag = styled.div`
  justify-content: center;
`;
const Text2 = styled.p`
  font-weight: 400;
  font-size: 18px;
  color: #3b3b3b;
`;

const TopHeader = styled.div`
  min-width: 100px;
  display: flex;
  flex-direction: column;
  padding-top: 22px;
  padding-bottom: 22px;
  justify-content: center;
`;
const EmployeeHeader = styled.div`
  max-width: 200px;
  display: flex;
  flex-direction: column;
  padding-top: 22px;
  padding-bottom: 22px;
  justify-content: center;
`;
const OuterHeader = styled.div`
  min-width: 600px;
`;

const Table = styled.table`
  display: block;
  /* border-bottom: 1px solid #828080; */
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-left: 25px;
  /* border-collapse: collapse; */
`;

const LedgerRow = styled.div`
  display: flex;
  flex-direction: row;
  height: 20px;
  margin-top: 20px;
  /* margin-left: 30px; */
`;

const LedgerButtomRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const LedgerRowOuterTag = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;

const TransactionDiv = styled.div`
  border-top: 1px solid #436ab2;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 2rem;
  /* justify-content: space-between; */
`;

const BoxTop = styled.div`
  height: 167px;
  padding: 18px 24px 18px 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  flex-direction: row;
  gap: 49px;
`;
const BackButton = styled.button`
  padding-top: 15px;
  margin-left: 20px;
  cursor: pointer;
  width: 12%;
  padding-left: 10px;
  background-color: white;
  border: 0px;
  text-align: center;
  display: flex;
  flex-direction: row;
`;

const BoxButton2 = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  border: 0px;
  background-color: white;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  padding-right: 16px;
  border-radius: 6px;
`;

const SummaryBox = styled.div`
  background-color: #f9fcff;
  border: 1px solid #436ab2;
  border-radius: 10px;
  width: 22%;
  min-width: 471px;
  height: 263px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
`;
const ImgHeader = styled.div`
  padding-bottom: 10px;
  text-align: top;
  border: 0px;
`;

const LongLine = styled.div`
  border-bottom: 1px solid #828080;
  padding-top: 10px;
  margin-left: 25px;
`;


const Image = styled.img`
  max-width: 75px;
  max-height: 40px;
  margin-top: 3px;
`;

const Root = styled.div`
	/* width: 100%; */
	background-color: #ffffff;
    display: flex;
    flex-direction: column;
 `

export {
  LongLine,
  ImgHeader,
  ButtonWrapper,
  SummaryBox,
  BoxButton2,
  BackButton,
  HomeWrapper,
  MessageWrapper,
  Text2,
  TopHeader,
  MsgTag,
  Table,
  EmployeeHeader,
  OuterHeader,
  LedgerRow,
  BoxTop,
  LedgerButtomRow,
  LedgerRowOuterTag,
  TransactionDiv,
  Image,
  Root
};
