
import React, { Component } from 'react'
import styled from 'styled-components'


const RoundedButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${props => (props.padding ? props.padding : '1.5rem 0')};
  opacity:  ${props => (props.opacity ? props.opacity : '1')};
  border: ${props => (props.borderColor ? `1px solid ${props.borderColor}` : 'none')};
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '1.5rem')};
  background-color: ${props => (props.bgColor ? props.bgColor : props.theme.baseBlue)};
  // background-color: ${props => (props.bgColor ? props.bgColor : '#4154da')};

  font-family: "Avenir";
  font-size: ${props => (props.fontSize ? props.fontSize : '1.6rem')};
  font-weight:  ${props => (props.fontSize ? props.fontSize : '600')};;
  line-height: 1.5;
  text-align: center;
  color: ${props => (props.textColor ? props.textColor : '#ffffff')}; 
  text-transform:  ${props => (props.textTransform ? props.textTransform : 'none')};
  letter-spacing: ${props => (props.letterSpacing ? props.letterSpacing : '0px')};
  cursor: pointer;
  transition: 0.2s ease-in-out all;

  &:hover {
    background-color: ${props => props.hoverBgColor ? props.hoverBgColor : 'rgba(67, 106, 178, 0.9)'};
    transform: translateY(1px);
  }
 
  &:focus {
    outline: none;
  }

  & svg {
    margin-right: 1rem;
  }
`

export default class LargeButton extends Component {
  render() {
    const { disabled, loader = true, label, ...rest } = this.props
    return (
      // <RoundedButton {...this.props}>
      <RoundedButton disabled={disabled} {...rest}>
        
        {/* {disabled && loader && <Icons icon="circleLoader" fill="#fff" height="24" width="24" />} */}
        {label}
      </RoundedButton>
    )
  }
}