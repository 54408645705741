import React, { Component, useState } from 'react'
import styled from 'styled-components'
import {Ptag} from '../../components/fonts'
import bin from '../../assets/images/redbin.png'
import {formatBackendDate} from '../../utils/utils'

const MemberCard = ({policyStatus='', dependents=[], onDeleteEmployee, id, active, vitalCover, premiumAmount, nextBillingDate, validTill, planType,isPaymentDone}) => {
    const [showPopup, setShowPopup] = useState(false)
    const bannerMessages = []            
    let deleteMessage = 'Are you sure you want onClickYesForAutopayto remove the employee? The subscription amount will be updated in next billing cycle.'
    if(active){
        const inValidMembers = []
        for (let member of  dependents){
            if(!member['is_active']){
                inValidMembers.push(`${member['first_name'][0].toUpperCase()}${member['first_name'].slice(1)} ${member['last_name'][0].toUpperCase()}${member['last_name'].slice(1)}`)
            }
        }
        if (inValidMembers.length > 0){
            let invalidMembersMessages = ''
            inValidMembers.map((member, index) => {
                invalidMembersMessages = index === inValidMembers.length -1 ? 
                `${invalidMembersMessages} ${member} cannot be included because of health conditions. Refund has been initiated.` :
                `${invalidMembersMessages} ${member}, `
            })
            bannerMessages.push(invalidMembersMessages)
        }
        if (policyStatus && policyStatus.toLowerCase() === 'cancelled'){
            bannerMessages.push('This policy was cancelled by your employee')
        }
        console.log('gajendra',isPaymentDone)

        if (policyStatus && policyStatus.toLowerCase() === 'activation pending' && !vitalCover && isPaymentDone ){
            bannerMessages.push(`Rs. ${premiumAmount} has been added to the monthly subscription amount from next billing cycle onwards. Coverage will start from ${nextBillingDate} onwards.`)
        } 
        if (policyStatus && policyStatus.toLowerCase() === 'activation pending' && vitalCover)
        {   
            bannerMessages.push(`Request sent to employee to review details and declare health conditions, if any.`)
            deleteMessage = `Are you sure you want to remove the employee? The refund of Rs. ${premiumAmount} will be initiated within 5 working days. Subscription amount will be updated in the next billing cycle.`
        }

    }
    


    let onClickDeleteEmployee = () => {
        setShowPopup(false)
        onDeleteEmployee(id)
        

    }

    return(
        <Root>
            {active && planType === 'Monthly' &&
                <Bin src={bin} height='18px' width='14px' onClick={() => alert('Contact us on care@getvital.in to add/remove an employee')} />
            }
            <CardRow margin='0 0 6px 0'>
                <Ptag textTransform='uppercase'  letterSpacing='1px'>
                    {'members name'}
                </Ptag>
                <Ptag textTransform='uppercase' letterSpacing='1px'>
                    {'age'}
                </Ptag>
            </CardRow>

            {dependents.filter(item => item['is_active'] || !active).map((item, key) =>
                <CardRow key={`Member${item['dependent_id']}${key}}`} margin='0 0 4px 0'>
                    <Ptag textTransform='capitalize' fontWeight='600' letterSpacing='0.5px'>
                        {`${item['first_name'] && item['first_name'].toLowerCase()} ${item['last_name'] && item['last_name'].toLowerCase()}`}
                    </Ptag>
                    <Ptag  fontWeight='600' letterSpacing='2.33px'>
                        {`${item['age']} yrs`}
                    </Ptag>
                </CardRow>
            )}


            <CardRow margin='14px 0 6px 0'>
                {
                    (vitalCover && vitalCover['policy_number'] || policyStatus.toLowerCase() === 'activated') &&
                    <Ptag textTransform='uppercase' letterSpacing='0.5px'>
                        {'Policy number'}
                    </Ptag>
                }
                <Ptag textTransform='uppercase' letterSpacing='0.5px'>
                    {policyStatus.toLowerCase() === 'activated' ? 'valid till' : 'status'}
                </Ptag>
            </CardRow>
            <CardRow margin='0 0 4px 0'>
                {
                    (vitalCover && vitalCover['policy_number'] || policyStatus.toLowerCase() === 'activated') && 
                    <Ptag fontWeight='600' letterSpacing='2.33px'>
                        {vitalCover && vitalCover['policy_number'] || 'Under processing'}
                    </Ptag>
                }
                <Ptag textTransform='capitalize' fontWeight='600' letterSpacing='0.5px'>
                    {policyStatus.toLowerCase() === 'activated' ? formatBackendDate(validTill) : policyStatus.toLowerCase() }
                </Ptag>
            </CardRow>

            

            {
                bannerMessages.length > 0 && planType === 'Monthly' &&
                <Banner>
                    {
                        bannerMessages.map( message =>
                            <Ptag color='#fff' letterHeight='1.83' letterSpacing='0.43px'>
                                {message}
                            </Ptag>
                            
                        )
                    }
                </Banner>
            }
            
            {
                showPopup &&
                <SignOutPopUp>
                    <Innerpopup>
                    <FirstRowPopUp>{deleteMessage}</FirstRowPopUp>
                    <SecondRowPopUp>
                        <PopupTexts onClick={onClickDeleteEmployee}>Yes</PopupTexts>
                        <VerticalLine />
                        <PopupTexts onClick={() => setShowPopup(false)}>No</PopupTexts>
                    </SecondRowPopUp>
                    </Innerpopup>
                </SignOutPopUp>
            }
        </Root>
    )
}

export default MemberCard

const Root = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 24px 0px;
    border-radius: 10px;
    border: solid 1.5px #436ab2;
    padding: 24px;
    overflow: hidden;
`

const CardRow = styled.div`
    display: flex;
    justify-content:flex-start;
    margin: ${props => props.margin ? props.margin : ' 0 0 4px 0'};
    & > :first-child{
        width: 60%;
        margin-right: 8px;
    }
`

const Bin = styled.img`
    position: absolute;
    align-self: flex-end;
`


const SignOutPopUp = styled.div`
  display: flex;
  flex-direction: column;
  z-index:12;
  width: 100%;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0,0.5);
`

const Innerpopup = styled.div`
  position: absolute;
  left: 8%;
  right: 8%;
  top: 26%;
  margin: auto;
  border-radius: 20px;
  background: white;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.19);
`

const FirstRowPopUp = styled.div`
  align-items: center;
  display: flex;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: #191515;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  padding: 24px 20px;
  line-height: 22px;
  justify-content: center;
`

const SecondRowPopUp = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
`

const PopupTexts = styled.p`
  font-family: Open Sans;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: #4a90e2;
  width: 50%;
  cursor: pointer;
`

const VerticalLine = styled.div`
  border-right: 1px solid rgba(0, 0, 0, 0.16);
  height: 100%;
`

const Banner = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px;
    margin: 20px -24px -24px -24px;
    background-color: #f27576;
    
`