const ageBands = [
    {
        age : '0 - 18',
    },
    {
        age : '19 - 35'
    },
    {
        age : '36 - 45'
    },
    {
        age : '46 - 55'
    },
    {
        age : '56 - 60'
    },
    {
        age : '61 - 65'
    },
    {
        age : '66 - 70'
    },
    {
        age : '71 - 75'
    },
    {
        age : '76 - 80',
    },
    {
        age: '71 - 75',
    },
    {
        age: '76 - 80',
    },
    {
        age: '81 - 100',
    }

]


export default ageBands