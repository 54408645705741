import React from "react";
import { Ptag } from "../fonts";

const EmployeeRegistrationDashboardHeader = ({ doesEmployeeDataExist }) => {
    return (
        <>
            {doesEmployeeDataExist ? (
                <>
                    <Ptag fontSize="24px" letterHeight="32px" fontWeight="600" marginBottom="1.6rem">Your Selection</Ptag>
                    <Ptag fontSize="18px" letterHeight="26px" fontWeight="400" marginBottom="1.2rem">
                        An email has been sent to all your employees with a link to share their data.
                    </Ptag>
                </>
            ) : (
                <>
                    <Ptag fontSize="24px" letterHeight="32px" fontWeight="700" marginBottom="1.6rem">Collect Employee Data</Ptag>
                    <Ptag fontSize="16px" letterHeight="20px" fontWeight="400" marginBottom="1.2rem">
                        Enter the required details below, and click Submit. An email will be sent to each employee, with a link to submit their data.
                    </Ptag>
                </>
            )}
        </>
    )
}

export default EmployeeRegistrationDashboardHeader;