import React, { Component, useEffect, useState } from 'react'
import ReactDOM from "react-dom" 
import styled from 'styled-components'
import { Ptag } from '../fonts'
import { Icons } from '..'
import { formatAmount } from '../../utils/utils'
import {LargeButton} from '../'
import http from '../../services/httpService'

const PaymentBreakupPopup = ({show, onClose, employees, advanceMonth, totalSubscriptionFee, totalPremium, platformFee, discount, tax}) => {

    const totalPremiumFee = ( totalPremium || 0) + ( platformFee || 0 )

    const onPay = async () => {
        var userPlanDetails = JSON.parse(localStorage.getItem('user_plan_details'))
        //TODO
        const response = await http.post(`/payment/subscription_payment/?subscription_id=${userPlanDetails['subscription']['vital_subscription_id']}`, {
            payment: {method:0,plan:0},
            term_type:"monthly",
            discount:discount,
            discounted_tax: tax,
            payable_amount: totalSubscriptionFee
        });
      const options = {
        async handler(response) {
         window.location.reload()
        },
        ...response.data,
      };
  
      const rzp = new window.Razorpay(options);
      rzp.open();
    }

    const monthCount = (advanceMonth) =>{
        return (advanceMonth > 1)? 
        `Payment for ${employees} empolyee${employees > 1 ? "s": ""} will include ${advanceMonth-1} month advance.`:
        `Payment for ${employees} empolyee${employees > 1 ? "s": ""}.`
    }



    return ReactDOM.createPortal(
        <Page show={show}>
            <PaymentHome>
                <PaymentHeader>
                    <Ptag fontWeight='600' fontSize='36px' letterHeight='49px'  >
                        {'Payment'}
                    </Ptag>
                    <div onClick={onClose}>
                        <Icons icon='cross' height='44' width='44' />
                    </div>
                </PaymentHeader>
                
                <Ptag fontWeight='400' fontSize='21px' letteerSpacing='0.5px' >
                    {`${monthCount(advanceMonth)}`}
                </Ptag>
                <PaymentSummery style={{marginTop: '50px'}} >
                    <Ptag fontWeight='400' fontSize='28px' letterHeight='38px'>
                        {'Total Subscription Fee'}
                    </Ptag>
                    <Ptag fontWeight='400' fontSize='28px' letterHeight='38px'>
                    {`₹${formatAmount(totalPremiumFee)}`}
                    </Ptag>
                </PaymentSummery>

                <PaymentSummery style={{marginTop: '12px'}} >
                    <Ptag fontWeight='300' fontSize='20px' letterHeight='28px'>
                        {'Insurance Premium'}
                    </Ptag>
                    <Ptag fontWeight='300' fontSize='20px' letterHeight='28px'>
                    {`₹${totalPremium}`}
                    </Ptag>
                </PaymentSummery>
                {
                    !!platformFee && 
                    <PaymentSummery style={{marginTop: '14px'}} >
                        <Ptag fontWeight='300' fontSize='20px' letterHeight='28px'>
                            {'Health Benefits'}
                        </Ptag>
                        <Ptag fontWeight='300' fontSize='20px' letterHeight='28px'>
                            {`₹${formatAmount(platformFee)}`}
                        </Ptag>
                    </PaymentSummery>
                }
                {
                    !!discount  &&
                    <PaymentSummery style={{marginTop: '28px'}}  >
                        <Ptag fontWeight='400' fontSize='28px' letterHeight='38px'>
                            {'Discount'}
                        </Ptag>
                        <Ptag fontWeight='400' fontSize='28px' letterHeight='38px' color="##F77963">
                        -{`₹${formatAmount(discount)}`}
                        </Ptag>
                    </PaymentSummery>
                }
                {
                    !!tax && 
                    <PaymentSummery style={{marginTop: '28px', }}>
                        <Ptag fontWeight='400' fontSize='28px' letterHeight='38px'>
                            {'GST (@18%)'}
                        </Ptag>
                        <Ptag fontWeight='400' fontSize='28px' letterHeight='38px'>
                        {`₹${formatAmount(tax)}`}
                        </Ptag>
                    </PaymentSummery>
                }

                <PaymentSummery style={{paddingTop: '26px', borderTop: '1px solid #9B9B9B', marginTop: '28px'}} >
                    <Ptag fontWeight='600' fontSize='28px' letterHeight='38px' color="#436AB2" >
                        {'Total'}
                    </Ptag>
                    <Ptag fontWeight='600' fontSize='28px' letterHeight='38px' color="#436AB2" >
                    {`₹${formatAmount(totalSubscriptionFee)}`}
                    </Ptag>
                </PaymentSummery>
                
                <LargeButton onClick={onPay} style={{marginTop: '120px',alignSelf: 'center', maxWidth: "672px" }} label="PAY"  />

              

            </PaymentHome>
            
        </Page>,


        document.getElementById('popup1')
    )
}

export default PaymentBreakupPopup

const Page = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    height: 100vh;
    width: 100%;
    background: #00000080;
    top: 0;
    left: 0;
    z-index: 99;
    transform: ${props => (props.show === true ? 'translateY(0) scaleY(100%)' : 'translateY(102%) scaleY(0)')};
    transition: all 0.6s;
    position: fixed;
`

const PaymentHome = styled.div`
    display: flex;
    flex-direction:column;
    width: 100%;
    max-width: 1262px;
    margin: 0 24px;
    background: #FFFFFF;
    border: 1px solid #979797;
    box-shadow: 0px 8px 8px rgba(65, 63, 63, 0.384643);
    border-radius: 30px;
    padding: 70px 50px 105px 70px;
`

const PaymentHeader =  styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
const PaymentSummery = styled.div`
    display: flex;
    flex-direction: row;
    justify-content:space-between;
`