import React from "react";

import { ChipWrapper } from "./styles";

const Chip = ({text, background}) => {
    return (
        <ChipWrapper background={background}>{text}</ChipWrapper>
    )
}

export default Chip;