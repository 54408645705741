import React, { Component, Fragment } from "react";
import styled from "styled-components";
import Header from "../../components/memberApp/header";
import { Ptag } from "../../components/fonts";
import {PaymentCycleCard, LargeButton, OverlayLoader} from '../../components'
import http from "../../services/httpService";

export default class SelfpaymentOptions extends Component{

constructor(){
    super()
    this.state ={
        loader: true,
        sub_amount:'',
        paymentMethods : ['UPI', 'EMANDATE']
    }
}

 componentDidMount(){
     this.setState({
        sub_amount:localStorage.getItem("sub_amount")
     }) 

 }




 async onClickPay(id, duration) {

    const _this = this

    let body = {
        userplan:localStorage.getItem("userplan_id"),
        points_applied:0,
        payment: {
          method:0,
          plan: 0,
        }
      }
   try {
    
    let {data} = await http.post('/business/subscription/', { ...body })

    const premium = data.premium
    const total_tax =data.total_tax
    const platform_fee = data.platform_fee

    console.log(data, 'okoknow')
    
    await localStorage.setItem('platform_fee',platform_fee)
    await localStorage.setItem('premium',premium)
    await localStorage.setItem('total_tax',total_tax)
   
    // const options = {
    //     async handler(response) {


    //     //   await http.post('/payment/verify/', {
    //     //     razorpay_payment_id: response.razorpay_payment_id,
    //     //     razorpay_signature: response.razorpay_signature,
    //     //     razorpay_order_id: response.razorpay_order_id
    //     //   })


    //       _this.props.history.push('/business/subscriptionselfpay')
    //     },
    //     ...response.data
    //   }

    _this.props.history.push('/business/subscriptionselfpay')

    } 
    catch (ex) {
        console.log('Error-Ex: ', ex)
        if (ex.status) console.log('Error-Ex-status: ', ex.status)
      }
 }
 
// onClickPay(){
//     this.props.history.push('/business/subscriptionselfpay')

// }
   

    render(){


        return(
            <Root>
                <Header backPage='/insurancesubscriptions' />
               
               <Body>
               <Ptag fontSize='20px' fontWeight='600' color='#336188' style={{'marginBottom': '30px'}}>
                        {'Payment Cycle'}
               </Ptag>
               <PaymentCycleCard 
                                    id={1}
                                    key={1}
                                    duration={1}
                                    discountedAmount={this.state.sub_amount}
                                    amount={this.state.sub_amount}
                                    // discountedPercentage={}
                                    checked={this.state.checked == 1 }
                                    postPurchase={true}
                                    onClickCard={(id, duration) => this.onClickPay(id, duration)}
                                />
               </Body>
            </Root>
        )
    }
}



const Root = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2.4rem 2rem;
    flex: 1;
`

const currentSubscription = styled.div`
    display: flex;
    flex-direction: column;
    flex:1;
`

const ButtonWrapper = styled.div`
    display: flex;
    opacity: ${props => props.opacity};
`