import React, { Component } from "react";
import styled from "styled-components";
import coverageThings1 from "../assets/svgs/coverageThings1.svg";
// import coverageThings2 from '../assets/svgs/coverageThings2.svg'
import coverageThings2 from "../assets/images/coverageThings2.png";
import thumbpage4 from "../assets/images/landingpage3/thumbpage4.png";
import circlepage4 from "../assets/images/landingpage3/circlepage4.png";

const Wrapper = styled.div`
  width: 100%;
  background: #fff;
  padding: 3.6rem 0 4.8rem 0;
  position: relative;

  img {
    position: absolute;
    z-index: 10;
    right: 10%;
    top: 5%;
  }

  @media (min-width: 1023px) {
    padding: 12.2rem 0;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
`;
const Header = styled.div`
  width: 32rem;
  padding-left: 2.4rem;
  margin-bottom: 3rem;

  h2 {
    margin-bottom: 1.2rem;
  }

  @media (min-width: 1023px) {
    padding-left: 0;
    width: 40rem;
    margin-left: 140px;
  }
  @media (min-width: 1680px) {
    width: 75rem;
    h2 {
      margin-bottom: 4.8rem;
    }
  }
`;

const Image = styled.div`
  width: 30rem;
  height: 28.21rem;
  background: url(${"http://images.getvital.in/homelanding/coverageThings2.png"});
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;

  @media (min-width: 1024px) {
    margin: 0;
  }
  @media (min-width: 1680px) {
    background: url(${"http://images.getvital.in/homelanding/coverageThings2.png"});
    background-size: cover;
    width: 50rem;
    height: 47.02rem;
  }
`;

const H2 = styled.h2`
  font-family: Proxima Nova;
  font-size: 2.4rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    width: 100%;
    font-size: 3.4rem;
    line-height: 1.29;
    letter-spacing: 0.02px;
  }

  @media (min-width: 1680px) {
    font-size: 6rem;
    letter-spacing: 0.03px;
  }
`;

const H3 = styled.h3`
  margin-top: 1.2rem;
  font-size: 1.6rem;
  font-family: Avenir;
  font-weight: normal;
  line-height: 1.75;
  letter-spacing: 0.02px;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    margin-top: 2.4rem;
    font-size: 1.8rem;
    line-height: 1.56;
    letter-spacing: normal;
  }

  @media (min-width: 1679px) {
    margin-top: 4.5rem;
    font-size: 3.4rem;
    font-weight: normal;
    letter-spacing: 0.02px;
    line-height: 1.59;
  }
`;

export default class Page32 extends Component {
  render = () => {
    return (
      <Wrapper>
        <Header>
          <H2>Simple and Transparent</H2>
          <H3>
            Short easy coverage document without confusing insurance jargons and
            hidden terms.
          </H3>
        </Header>
        <Image alt="health insurance plans for family" />
        <img src={thumbpage4} alt="thumbpage4.png" />
      </Wrapper>
    );
  };
}
