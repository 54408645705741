import React from "react";
import styled from "styled-components";
import { openLinkInNewTab } from "../../utils/utils";
import LargeButton from "../LargeButton";
import SpiralCompletion from "./spiralCompletion";

const EmployeeRegistrationDashboardEmployeeDetail = ({ relevant_employees, total_employees, sendReminderHandler, doesEmployeeDataExist, exportCsv, employeeData}) => {
	const handleFormClick = () => {
		const url = `${window.location.origin}/business/employeeform?dependent_type=${employeeData.dependent_type}&clear=true`;
		openLinkInNewTab(url);
	}

	const checkEmployeeSubmission = () => {
		if(relevant_employees && total_employees && relevant_employees > 0 && total_employees > 0) {
			if(relevant_employees === total_employees) {
				return true;
			}
		}
		return false;
	}
	
	return (
		<EmployeeContainer>
			<EmployeeColumn>
				<SpiralCompletion relevant_employees={relevant_employees} total_employees={total_employees} />
			</EmployeeColumn>
			<EmployeeColumnBtnWrapper>
				<Button
					disabled={!doesEmployeeDataExist || checkEmployeeSubmission()}
					label="Send Reminder"
					color="#fff"
					bgColor="#436ab2"
					padding="1.8rem 0"
					onClick={sendReminderHandler}
				/>
				<Button
					disabled={!relevant_employees}
					label="Download Employee Data"
					color="#fff"
					bgColor="#436ab2"
					padding="1.8rem 0"
					onClick={exportCsv}
					margin="1.2rem 0 0 0"
				/>
				{employeeData.dependent_type && <EmployeeTemplate onClick={handleFormClick}>View Employee Form Template</EmployeeTemplate>}
			</EmployeeColumnBtnWrapper>
		</EmployeeContainer>
	)
}

const EmployeeContainer = styled.section`
	display: flex;
	align-items: center;
	gap: 14rem;
`

const Button = styled(LargeButton)`
	cursor: ${props => props.disabled ? 'default' : 'pointer'};
	border-radius: 5px;
	background-color: ${props => props.disabled ? 'rgba(67, 106, 178, 0.5) !important' : 'rgba(67, 106, 178, 1) !important'};
	margin: ${props => props.margin ? props.margin : null};
	text-transform: capitalize;
`

const EmployeeColumn = styled.section`
	flex: 1 1 0;
`

const EmployeeColumnBtnWrapper = styled(EmployeeColumn)`
	padding: 0 4rem;
`

const EmployeeTemplate = styled.p`
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.4px;
	line-height: 4rem;
	text-decoration-line: underline;
	color: #4A90E2;
	text-align: center;
	margin-top: 1.4rem;
    font-family: 'Open Sans';
	cursor: pointer;
`

export default EmployeeRegistrationDashboardEmployeeDetail;