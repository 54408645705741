import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { OverlayLoader } from "../../../components";
import ECardList from "../../../components/GmcDashbaord/ECardList";

import http from "../../../services/httpService";
import { ECardPreviewMain } from "./styles";

const ECardPreview = () => {
  // getting state to extract employeeId
  const { state } = useLocation();
  console.log({ state });
  // storing member policy details
  const [memberDetails, setMemberDetails] = useState({});
  // loading flag to display loader
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function() {
      // fetching e-cards info
      await fetchECards();
    })();
  }, []);

  const renderECards = () => {
    // sorting products in GMC -> GPA -> GTL order
    const products = Object.keys(memberDetails).sort((a, b) => {
      if (a.slice(0, 3) < b.slice(0, 3)) {
        return -1;
      } else if (a.slice(0, 3) > b.slice(0, 3)) {
        return 1;
      } else {
        return a.length < b.length ? -1 : 1;
      }
    });

    // returning JSX list of e-card list
    return (
      <ECardPreviewMain>
        <div style={{ maxWidth: "1200px" }}>
          {state.productType
            ? products
                .filter((prod) => prod === state.productType)
                .map((prod, index) => (
                  <ECardList
                    flex
                    key={index.toString()}
                    abbr={prod}
                    members={memberDetails[prod]}
                  />
                ))
            : products.map((prod, index) => (
                <ECardList
                  key={index.toString()}
                  abbr={prod}
                  members={memberDetails[prod]}
                />
              ))}
        </div>
      </ECardPreviewMain>
    );
  };

  // to fetch e-cards
  const fetchECards = async () => {
    // sending payload containing employeeId
    const payload = {
      employee_id: state.employeeId,
    };
    try {
      const { data } = await http.post(
        "business/fetch_ecard_details/",
        payload
      );
      // saving member details
      setMemberDetails(data);
    } catch (_) {
      alert("Some error has occured!");
    } finally {
      // hiding loader using flag
      setLoading(false);
    }
  };
  return <>{loading ? <OverlayLoader /> : renderECards()}</>;
};

export default ECardPreview;
