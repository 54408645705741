import React from 'react'
import Countdown from 'react-countdown-now'
import styled from 'styled-components'
import {timeOut} from '../constants/timeouts'

const TimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Para16 = styled.span`
  font-size: 1.6rem;
  color: #fff;
  letter-spacing: 1.6px;
`

// Random component
const Completionist = () => <Para16>00:00</Para16>

// Renderer callback with condition
const renderer = ({ hours, minutes, seconds, completed }) => {
  const Zero = seconds < 10 ? 0 : ''

  if (completed) {
    // Render a completed state
    return <Completionist />
  } else {
    // Render a countdown
    return (
      <Para16>
        {0}
        {minutes}:{Zero}
        {seconds}
      </Para16>
    )
  }
}

//In 2:30 min in ms
const CountDown = props => {
  return (
    <TimerWrapper>
      {props.active ? <Countdown date={Date.now() + timeOut.resendOTP*1000} renderer={renderer} /> : <Completionist />}
    </TimerWrapper>
  )
}

export default CountDown
