import React from 'react'
import styled from 'styled-components'
import Icons from '../components/icons'
import config from '../config.json'
import arrowBack from '../assets/images/arrowBack.png'

const Header = styled.div`
  width: 100%;
  padding: 2.2rem 0 2.2rem 0;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;
`

const Oval = styled.span`
  display: inline-block;
  width: ${props => (props.width ? props.width : '3.3rem')};
  height: 1rem;
  border-radius: 5px;
  margin-left: -0.7rem;
  background-color: ${props => (props.BgColor ? props.BgColor : '#cccccc')};
  z-index: ${props => (props.zIndex ? props.zIndex : 1)};
`

const BackBtn = styled.div`
  padding: 1.6rem 0 1.6rem 2rem;
  position: absolute;
  top: 0;
  left: 0;
`

const Pagination = ({ backIcon = true, currentPage, onBoardFlow = false, creditFlow = false, ...restProps }) => {
  const totalPage = onBoardFlow ? config.onBoardingScreenCount : creditFlow ? config.creditFlowScreenCount : 0

  if (totalPage < currentPage) return null
  const oneBlockWidth = onBoardFlow ? 14 : 3.3
  const ActiveBlockWidth = oneBlockWidth * currentPage + 'rem'
  const InActiveBlockWidth = oneBlockWidth * (totalPage - currentPage) + 'rem'
  return (
    <Header>
      {backIcon && (
        <BackBtn {...restProps}>{backIcon && <img src={arrowBack} width='24px' height='24px'/>}</BackBtn>
      )}

      <Oval width={ActiveBlockWidth} BgColor={'#436ab2'} zIndex="10" />
      <Oval width={InActiveBlockWidth} BgColor={'#cccccc'} />
    </Header>
  )
}

export default Pagination

/* 
===Interface to use===
<Pagination
  totalPage={8}
  currentPage={5}
/> 
*/
