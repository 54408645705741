import React, { Component } from "react";
import styled from "styled-components";
import { Ptag } from "../fonts";
import LargeButton from '../LargeButton'
import ReactDOM from "react-dom" 
import Cross from '../../assets/images/vital-cross.png'

const DisclaimerPopup = ({messages = [], onClickAgree, onClose}) => {
    

    return ReactDOM.createPortal(
        <Root>
            <Header>
                <Ptag fontSize='24px' fontWeight='600' letterSpacing='0.6px' letterHeight='30px' >
                    {'DISCLAIMER'}
                </Ptag>

                {
                    onClose &&
                    <IconWrapper onClick={onClose} >
                        <img src={Cross} height="19px" width="19px"  />
                    </IconWrapper>
                    
                
                }

            </Header>
            
            <Body>
                <Ptag style={{'marginLeft': '64px', 'marginBottom': '24px'}} fontSize='20px' fontWeight='600' letterSpacing='0.6px' letterHeight='30px' >
                    {'I hereby confirm,'}
                </Ptag>
                {
                    messages.map( (item, index) => 
                        <MessageBody>
                            <Ptag fontSize='18px' letterSpacing='0.45px' letterHeight='30px' >
                                {index + 1}.
                            </Ptag>
                            <Ptag fontSize='18px' letterSpacing='0.45px' letterHeight='30px' >
                                {item}
                            </Ptag>
                        </MessageBody>
                    )
                }
            </Body>
            {
                !onClose &&
                <Footer>
                    <LargeButton style={{'width': '322px', marginRight: '30px'}} onClick={onClickAgree} label='I AGREE' />
                    <Call>
                        <Ptag fontSize='18px' letterSpacing='0.45px' letterHeight='36px'>
                            To Know more, please call <a href="tel:+01141171787011"> 011- 41171787</a>
                        </Ptag>
                    </Call>
                </Footer>
            }
            
        </Root>,
        document.getElementById('popup1')
    )
}

export default DisclaimerPopup

const Root = styled.div`
    display: flex;
    flex-direction: column;
    padding: 60px;
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 100;
    background-color: white;
    overflow-y: scroll;
    top: 0;
    right: 0;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 16px;
`

const MessageBody = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
    & > :first-child{
        min-width: 38px;
        margin-right: 24px;
    }
`

const Footer = styled.div`
    display: flex;
    padding: 48px 122px;
    box-shadow: rgba(0,0,0,0.08);
    align-items: center;
    border-top: 1px solid #4a4a4a;
`

const Call = styled.div`
    display: flex;
    flex-direction: row;
    align-items: row;
`

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    
`

const IconWrapper = styled.div`
    border: 1px solid #4a4a4a;
    border-radius: 50%;
    display: flex;
    height: fit-content;
    padding: 6px;
`