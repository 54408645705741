import { ErrorMessage, Field, Formik } from "formik";
import moment from "moment";
import React from "react";
import * as Yup from "yup";
import styled from "styled-components";
import { formatDate, formatDateReverse, getEmployeeTypeFromEnum } from "../../utils/utils";
import { Ptag } from "../fonts";
import EmployeeRegistrationDashboardEmployeeDetail from "./employeeRegistrationDashboardEmployeeDetail";
import editIcon from "../../assets/svgs/editIcon.svg"
import tickIcon from "../../assets/svgs/tick_green.svg"
import http from "../../services/httpService";
import { useState } from "react";

const schema = Yup.object({
    hr_email: Yup.string()
        .email('Invalid email address')
        .required('*Required'),
    cutoff_date: Yup.string()
        .test('is-valid', 'Invalid date format', function (value) {
            return moment(value, 'DD/MM/YYYY', true).isValid();
        })
        .test('is-future', 'Please select a date that is in future', function (value) {
            const date = moment(value, 'DD/MM/YYYY', true);
            return date.isAfter(moment());
        })
        .required('*Required'),
})

const EmployeeRegistrationDashboardEmployeePreview = ({ employeeData, sendReminderHandler, openInNewTab, doesEmployeeDataExist, exportCsv }) => {
    const [displayTickIcon, setDisplayTickIcon] = useState({
        cutoff_date: false,
        hr_email: false,
    })
    const [initialFormValues, setInitialFormValues] = useState({
        cutoff_date: employeeData.cutoff_date,
        hr_email: employeeData.hr_email
    });
    const updateHandler = async (values, errors, field) => {
        const containsError = Object.keys(errors).length > 0;
        if(containsError || values[field] === initialFormValues[field]) return;
        const payload = {
            ...values,
            cutoff_date: formatDate(values.cutoff_date),
            update: true
        }
        try {
            const { data } = await http.post('business/update_business_employees_by_hr/', payload)
            if(data) {
                setDisplayTickIcon({
                    [field]: true,
                })
            }
        } catch(err) {
            if(err.response.data.length && err.response.data[0].error) {
                alert(err.response.data[0].error);
            }
        }
    }

    const handleDateChange = (e, setFieldValue, fieldName, variable) => {
        const caretLocation = e.target.selectionStart;
        setDisplayTickIcon({
            ...displayTickIcon,
            cutoff_date: false,
        })
        const value = e.target.value
        let newDate = value
        let prevDateLen = fieldName.length
        let newDateLen = newDate.length
        let newDob = newDate
        if (newDateLen < prevDateLen && caretLocation !== 2 && caretLocation !== 5) {
            newDob = fieldName.substr(0, caretLocation) + fieldName.substr(caretLocation + 1, newDateLen)
        }
        if (newDateLen <= 10) {
            if (caretLocation === 2 || caretLocation === 5) {
                newDob = newDate + '/'
                if (newDateLen < prevDateLen) {
                    newDob = fieldName.substr(0, caretLocation);
                }
            }
        } else {
            newDob = newDate.slice(0, 10)
        }
        if(newDateLen === 10) {
			newDob = moment(newDob, "DD/MM/YYYY").format("DD/MM/YYYY");
		}
        setFieldValue(variable, newDob)
    };

    const handleMailChange = (e, setFieldValue) => {
        setDisplayTickIcon({
            ...displayTickIcon,
            hr_email: false,
        })
        setFieldValue("hr_email", e.target.value)
    }

    return (
        <>
            <Formik
                initialValues={{
                    cutoff_date: formatDateReverse(employeeData.cutoff_date),
                    hr_email: employeeData.hr_email
                }}
                onSubmit={updateHandler}
                validationSchema={schema}
                validateOnBlur
                render={({ values, errors, setFieldValue }) => (
                    <FormWrapper>
                        {console.log({errors})}
                        <InputWrapper flex="1 1 0px">
                            <Label>Dependent Type</Label>
                            <Input
                                type="text"
                                value={getEmployeeTypeFromEnum(employeeData.dependent_type)}
                                disabled={true}
                            />
                        </InputWrapper>
                        <Field
                            name="cutoff_date"
                            render={({ field }) => (
                                <InputWrapper flex="1 1 0px">
                                    <Label>Cut Off Date</Label>
                                    <InputIconWrapper>
                                        <Input
                                            {...field}
                                            value={field.value}
                                            type="text"
                                            onChange={(e) => handleDateChange(e, setFieldValue, values['cutoff_date'], 'cutoff_date')}
                                            onBlur={() => updateHandler(values, errors, "cutoff_date")}
                                            onFocus={() => setInitialFormValues({
                                                ...initialFormValues,
                                                cutoff_date: field.value
                                            })}
                                        />
                                        <Image src={displayTickIcon.cutoff_date ? tickIcon : editIcon} />
                                    </InputIconWrapper>
                                    {errors && errors.cutoff_date && <Error>{errors.cutoff_date}</Error>}
                                </InputWrapper>
                            )}
                        />
                        <Field
                            name="hr_email"
                            render={({ field }) => (
                                <InputWrapper flex="1 1 0px">
                                    <Label>Email Id</Label>
                                    <InputIconWrapper>
                                        <Input
                                            {...field}
                                            type="text"
                                            value={field.value}
                                            onChange={(e) => handleMailChange(e, setFieldValue)}
                                            onBlur={() => updateHandler(values, errors, "hr_email")}
                                            onFocus={() => setInitialFormValues({
                                                ...initialFormValues,
                                                hr_email: field.value
                                            })}
                                        />
                                        <Image src={displayTickIcon.hr_email ? tickIcon : editIcon} />
                                    </InputIconWrapper>
                                    {errors && errors.hr_email && <Error>{errors.hr_email}</Error>}
                                </InputWrapper>
                            )}
                        />
                    </FormWrapper>
                )}
            />

            <Ptag
                fontSize="20px"
                letterSpacing="1px"
                lineHeight="32px"
                fontWeight="600"
                marginBottom="2rem"
                marginTop="6rem"
            >
                Employee Data Received
            </Ptag>
            <EmployeeRegistrationDashboardEmployeeDetail
                employeeData={employeeData}
                relevant_employees={employeeData.relevant_employees}
                total_employees={employeeData.total_employees}
                sendReminderHandler={sendReminderHandler}
                openInNewTab={openInNewTab}
                doesEmployeeDataExist={doesEmployeeDataExist}
                exportCsv={exportCsv}
            />
        </>
    )
}

const FormWrapper = styled.section`
	display: flex;
	gap: 2rem;
    flex-wrap: wrap;
	margin-bottom: 3rem;
    margin-top: 3rem;
`

const Input = styled.input`
    width: 100%;
	border: 1px solid #436AB2;
    color: #4A4A4A;
	padding: 14px 24px;
    padding-right: 38px;
    font-weight: 600;
	border-radius: 5px;
	box-sizing: border-box;
	font-size: 14px;
	vertical-align: baseline;
	min-height: 5rem;
	&::placeholder {
		color: #4A4A4A;
	}
`

const Label = styled.label`
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 2rem;
    font-family: 'Open Sans';
    color: #4a4a4a;
    margin-left: 2rem;
`

const InputWrapper = styled.section`
	display: flex;
	flex-direction: column;
	flex: ${props => props.flex ? props.flex : null};
    position: relative;
`

const Error = styled.p`
  color: red;
  font-size: 1.4rem;
  bottom: -2.6rem;
  height: 2rem;
  z-index: 9;
  position: absolute;
`

const InputIconWrapper = styled.div`
    position: relative;
`

const Image = styled.img`
    position: absolute;
    right: 0;
    top: 50%;
    width: 16px;
    height: 16px;
    transform: translateY(-50%);
    right: 2rem;
    cursor: text;
`

export default EmployeeRegistrationDashboardEmployeePreview;