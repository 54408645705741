import React, { Component } from 'react'
import styled from 'styled-components'


const OfferHeader = ({image}) => {

    return (
        <ImageWrapper img={image} />
    )
}

export default OfferHeader

const ImageWrapper = styled.div`
  width: 100%;
  height: 210px;
  object-fit: contain;
  background-repeat: no-repeat;
  background-size: cover;

  box-shadow: 0 2px 6px 0 rgba(74, 74, 74, 0.19);
  background-image: url(${props => props.img});
`