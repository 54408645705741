import React, { Component } from "react";
import styled from "styled-components";
import { Header } from "../../components/business";
import { Ptag } from "../../components/fonts";
import { LargeButton, Icons } from "../../components";
import http from "../../services/httpService";
import { OverlayLoader } from "../../components";
import formatAmount from "../../components/memberApp/common/utils";
import { WellnessRewards, BusinessIcon } from "../../components/business";
import TagManager from "react-gtm-module";
import { gtmCode } from "../../utils/utils";
import InsuranceBenefits from '../../components/business/benefits'

export default class InsurePlanSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      members: 0,
      showDeductible: false,
      showWellnessRewards: false,
      plantype: "annual",
      insurer: "",
      showInsurersList: false,
      maternityBenefits: false,
      preExistingBenefits: false,
      pointsHeading: "",
    };
  }

  componentDidMount = async () => {
    try {
      const tagManagerArgs = {
        gtmId: gtmCode(),
      };
      TagManager.initialize(tagManagerArgs);
      localStorage.removeItem("navigatingFrom");
      const userplan_id = localStorage.getItem("userplan_id");
      const { data } = await http.get(`/business/userplans/${userplan_id}/`);
      const employeesCovered = data["number_of_employees"];
      const membersCovered = data["number_of_lives"];
      const sumInsured = data["sum_insured"];
      const deductible = data["deductible"];
      const ped = data["ped_waiver"];
      const maternity = data["maternity_benefits"];
      const showBenefits = ped || maternity;
      const rewards = data["add_ons"].length
        ? (data["add_ons"][0] &&
            data["add_ons"][0]["subcategory"] &&
            data["add_ons"][0]["subcategory"]["value"]) ||
          null
        : null;
      const planType = data["plan_type"];
      const pointsHeading =
        data["add_ons"] &&
        data["add_ons"][0] &&
        data["add_ons"][0]["subcategory"]["description"];
      const insurer =
        data["insurer"] &&
        data["insurer"]["name"] &&
        data["insurer"]["name"].toLowerCase();
      const conditionAgreed = insurer === "religare";
      const insurancePremium = data["insurance_premium"];
      const tax = (data["add_ons_tax"] || 0) + (data["insurance_tax"] || 0);
      const addOnFee = data["add_ons_premium"];

      this.setState({
        employeesCovered,
        membersCovered,
        sumInsured,
        deductible,
        ped,
        maternity,
        rewards,
        insurancePremium,
        tax,
        addOnFee,
        planType,
        showBenefits,
        pointsHeading,
        insurer,
        conditionAgreed,
      });
    } catch (ex) {
      console.error(ex);
    }
    this.setState({ loading: false });
  };

  submit = async () => {
    const _this = this;
    this.props.history.push("/business/makepayment");
  };

  formatTileText = (text) => {
    if (text === "members") return `${this.state.TileData["members_covered"]}`;
    if (text === "months") return `${this.state.TileData["duration"]} months`;
  };

  formatSum = (sum) => {
    return "₹" + parseInt(sum) / 100000 + "Lakhs";
  };

  onClickMarketPlace = () => {
    localStorage.setItem("navigatingFrom", "insureplansummary");
    this.props.history.push("/business/marketplace");
  };

  onClickViewPolicy = () => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
    localStorage.setItem("navigatingFrom", "insureplansummary");
    this.props.history.push("/business/policydoc");
  };

  renderAddButton = (selected = false, color = "#436ab2", click) => {
    return (
      <AddButton onClick={click} color={color} selected={selected}>
        {selected ? "ADDED" : "ADD"}
        {selected && (
          <BusinessIcon icon="checkMark" fill={color} height="18" width="18" />
        )}
      </AddButton>
    );
  };

  renderAgreement = () => {
    let onClickCheckbox = () => {
      this.setState({ conditionAgreed: !this.state.conditionAgreed });
    };
    let clickTermsAndCondition = () => {
      window.scrollTo({
        top: 0,
        left: 0,
      });
      this.props.history.push("/business/tnc");
    };
    return (
      <Agreement>
        <IconWrapper onClick={onClickCheckbox}>
          <Icons
            width="24"
            height="24"
            icon={this.state.conditionAgreed ? "checkedBox" : "unCheckedBox"}
            fill="#436ab2"
          />
        </IconWrapper>
        <Ptag letterSpacing="0.5px" letterHeight="1.43" fontSize="14px">
         We accept that all employees will be enrolled in the plan without exception.
        </Ptag>
      </Agreement>
    );
  };

  renderPaymentSummary = () => {
    return (
      <PaymentSummary>
        <PaymentRow>
          <Ptag fontWeight="normal" fontSize="14px" letterSpacing="0.22px">
            {"Base Premium "}
          </Ptag>
          <Ptag fontSize="14px" letterSpacing="0.22px">
            {`₹ ${formatAmount(this.state.insurancePremium)}`}
          </Ptag>
        </PaymentRow>

        <PaymentRow>
          <Ptag fontWeight="normal" fontSize="14px" letterSpacing="0.22px">
            {"Wellness Fee"}
          </Ptag>
          <Ptag fontSize="14px" letterSpacing="0.22px">
            {`₹ ${formatAmount(this.state.addOnFee)}`}
          </Ptag>
        </PaymentRow>

        <PaymentRow>
          <Ptag fontWeight="normal" fontSize="14px" letterSpacing="0.22px">
            {"GST (@18%)"}
          </Ptag>
          <Ptag fontSize="14px" letterSpacing="0.22px">
            {`₹ ${formatAmount(this.state.tax)}`}
          </Ptag>
        </PaymentRow>

        <Line />

        <PaymentRow>
          <Ptag
            fontWeight="600"
            color="#436ab2"
            fontSize="14px"
            letterSpacing="0.22px"
          >
            {"Total"}
          </Ptag>
          <Ptag
            fontWeight="600"
            color="#436ab2"
            fontSize="14px"
            letterSpacing="0.22px"
          >
            {`₹ ${formatAmount(
              this.state.tax + this.state.addOnFee + this.state.insurancePremium
            )}`}
          </Ptag>
        </PaymentRow>
      </PaymentSummary>
    );
  };

  render() {
   
    return (
      <Root>
        <Header
          headerText="Plan Summary"
          onClickBack={() => this.props.history.push("/business/gst")}
        />
        <StupidBlueStrip />
        <Body>
          <Tiles>
            <Tile>
              <Ptag fontWeight="600" letterSpacing="0.86px" letterHeight="1.5">
                {"Employees Covered"}
              </Ptag>

              <Ptag fontSize="16px" fontWeight="500" letterSpacing="0.4px">
                {this.state.employeesCovered}
              </Ptag>
            </Tile>

            <Tile>
              <Ptag fontWeight="600" letterSpacing="0.86px" letterHeight="1.5">
                {"Members Covered"}
              </Ptag>
              <Ptag fontSize="16px" fontWeight="500" letterSpacing="0.4px">
                {this.state.membersCovered}
              </Ptag>
            </Tile>

            <Tile>
              <Ptag fontWeight="600" letterSpacing="0.86px" letterHeight="1.5">
                {"Cover Period"}
              </Ptag>
              <Ptag fontSize="16px" fontWeight="500" letterSpacing="0.4px">
                {"12 months"}
              </Ptag>
            </Tile>
          </Tiles>

          <CardContainer>

          </CardContainer>
          <CardsWrapper>
           
            
            <InsuranceBenefits
              cardWidth="370px"
              insuredSum={`₹ ${formatAmount(this.state.sumInsured)}`}
              hideTests={true}
            />


          </CardsWrapper>

          <Ptag
            style={{ margin: "54px 0 24px 0" }}
            fontWeight="600"
            fontSize="20px"
            color="#336188"
          >
            {"Payment Summary"}
          </Ptag>
          {this.renderPaymentSummary()}

          {this.state.insurer !== "religare" && this.renderAgreement()}

          <LargeButton
            id="business_insureplansummary_pay_now"
            disabled={!this.state.conditionAgreed}
            opacity={this.state.conditionAgreed ? "1" : "0.4"}
            onClick={this.submit}
            label="NEXT"
          />
        </Body>
        <WellnessRewards
          showPopup={this.state.showWellnessRewards}
          onClosePopup={() => this.setState({ showWellnessRewards: false })}
        />
        {this.state.loading && <OverlayLoader />}
      </Root>
    );
  }
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 34px 16px 36px 16px;
`;

const StupidBlueStrip = styled.div`
  display: flex;
  height: 16px;
  background-color: #436ab2;
`;

const Tiles = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
  border-bottom: 1px solid #4a90e230;
`;

const Tile = styled.div`
  display: flex;
  width: 32%;
  padding: 12px;
  flex-direction: column;
  border: solid 1px #4a90e230;
  & > :first-child {
    margin-bottom: 8px;
  }
`;

const ViewMarketPlace = styled.div`
  display: flex;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: right;
  color: #4a90e2;
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 24px;
`;


const CardItems = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CardFooterItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  & :first-child {
    margin-bottom: 8px;
  }
`;

const AddButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 114px;
  height: 36px;
  border-radius: 5px;
  border: solid 1px ${(props) => props.color};
  color: ${(props) => (props.selected ? "#fff" : "#4a4a4a")};
  background-color: ${(props) => (props.selected ? props.color : "#fff")};
  font-family: Avenir;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  & > svg {
    margin-left: 14px;
  }
`;

const BenefitsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

const BenefitRowItem = styled.div`
  display: flex;
  align-items: center;
  margin-top: 6px;
  & > p {
    margin-left: 4px;
  }
`;

const PaymentSummary = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  padding: 34px 28px;
  margin: 0 -16px 48px -16px;
`;

const PaymentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const Line = styled.div`
  display: flex;
  margin: 6px 0 18px 0;
  height: 1px;
  border-top: solid 1px #9b9b9b;
`;

const Addons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

const AddonCollection = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 12px;
`;

const AddonItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
`;

const AddonImage = styled.img`
  align-self: center;
  width: auto !important;
`;

const Logo = styled.img`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;

const Agreement = styled.div`
  display: flex;
  margin: -10px 0 38px 0;
  align-items: center;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const List = styled.em`
  text-decoration: underline;
  letter-spacing: 0.5px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  text-transform: none;
  opacity: 1;
  font-family: Avenir;
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  color: #4a90e2;
  cursor: pointer;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`