import React from "react";
import { Ptag } from "../../fonts";
import { DefaultCardWrapper } from "./styles";

const DefaultCard = ({ title, subtitle, children }) => {
  return (
    <DefaultCardWrapper>
      <header>
        {title && (
          <Ptag fontSize="16px" fontWeight="600">
            {title}
          </Ptag>
        )}
        {subtitle && (
          <Ptag fontSize="11px" marginTop="4px">
            {subtitle}
          </Ptag>
        )}
      </header>
      <section style={{ paddingTop: "1rem" }}>{children}</section>
    </DefaultCardWrapper>
  );
};

export default DefaultCard;
