import React, { Component } from 'react'
import { withRouter } from 'react-router'

import styled from 'styled-components'
import CollapsibleDiv from './CollapsibleDiv.js'
import DebitCollapsibleDiv from './DebitCollapsibleDiv.js'
import CreditCollapsibleDiv from './CreditCollapsibleDiv.js'
import NetBankingCollapsibleDiv from './NetBankingCollapsibleDiv.js'
import WalletCollapsibleDiv from './WalletCollapsibleDiv.js'


class PaymentAccordion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clickedIdx: -1,
      openedIdx: -1,
      selected_index_nb: '',
      selected_index_credit: '',
      selected_index_debit: ''
    }
  }

  toggleOpen = async idx => {
    if (idx !== this.state.clickedIdx && this.state.openedIdx !== idx) {
       this.setState({ clickedIdx: idx, openedIdx: idx })
    } else if (idx === this.state.clickedIdx && this.state.openedIdx === idx) {
       this.setState({ openedIdx: -1 })
    } else {
      this.setState({ openedIdx: idx, clickedIdx: idx })
    }
  }

  onSelectDebitBank(selected_idx) {
    this.setState({
      selected_index_debit: selected_idx
    })
  }
  onSelectCreditBank(selected_idx) {
    this.setState({
      selected_index_credit: selected_idx
    })
  }

  onSelectNBBank(selected_idx) {
    this.setState({
      selected_index_nb: selected_idx
    })

    if (selected_idx === -1) return
    else if (selected_idx === 5) {
      this.props.history.push({ pathname: '/business/bankslist' })
      localStorage.setItem('via_payment', "true")
    } else {
      this.props.history.push({ pathname: '/business/netbankingdetails' })
      // store bankname in local storage
      localStorage.setItem('via_payment_to_details', "true") //for navigation sequence
    }
  }

  render() {
    const PaymentMethods = this.props.methods
    return (
      <ParentDiv>
        {/* <CreditCollapsibleDiv
          title={'CREDIT CARD'}
          key={1}
          idx={1}
          openDiv={this.state.clickedIdx}
          onPay={values => this.props.onPay(values)}
          open={this.state.clickedIdx === 1 && this.state.openedIdx === 1}
          close={this.state.clickedIdx != 1 && this.state.openedIdx != 1}
          togglePanel={idx => this.toggleOpen(idx) && this.state.closeIdx}
          selected_idx_credit={this.state.selected_index_credit}
          onSelectCreditBank={idx => this.onSelectCreditBank(idx)}
          premium={this.props.premium}
          discount={this.props.discount}
        />
        <DebitCollapsibleDiv
          title={'DEBIT CARD'}
          key={2}
          idx={2}
          openDiv={this.state.clickedIdx}
          onPay={values => this.props.onPay(values)}
          open={this.state.clickedIdx === 2 && this.state.openedIdx === 2}
          close={this.state.clickedIdx != 2 && this.state.openedIdx != 2}
          togglePanel={idx => this.toggleOpen(idx) && this.state.closeIdx}
          selected_idx_debit={this.state.selected_index_debit}
          onSelectDebitBank={idx => this.onSelectDebitBank(idx)}
          premium={this.props.premium}
          discount={this.props.discount}
        /> */}
        <NetBankingCollapsibleDiv
          title={'NET BANKING'}
          key={3}
          idx={3}
          openDiv={this.state.clickedIdx}
          onPay={values => this.props.onPay(values)}
          open={this.state.clickedIdx === 3 && this.state.openedIdx === 3}
          togglePanel={idx => this.toggleOpen(idx)}
          selected_idx_nb={this.state.selected_index_nb}
          onSelectNBBank={idx => this.onSelectNBBank(idx)}
          premium={this.props.premium}
          discount={this.props.discount}
        />
        {/* <CollapsibleDiv
          title={'UPI'}
          key={4}
          idx={4}
          openDiv={this.state.clickedIdx}
          onPay={values => this.props.onPay(values)}
          open={this.state.clickedIdx === 4 && this.state.openedIdx === 4}
          togglePanel={idx => this.toggleOpen(idx)}
          premium={this.props.premium}
          discount={this.props.discount}
          upi={true}
        /> */}
      </ParentDiv>
    )  
  }
}

const ParentDiv = styled.div`
  display: flex;
  flex-direction: column;
`
export default withRouter(PaymentAccordion)
