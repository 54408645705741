import jwtDecode from 'jwt-decode'
import http from './httpService'
import config from "../config.json"
import axios from 'axios'
const tokenRenewal = axios.create();
tokenRenewal.defaults.baseURL = config.apiUrl;

const tokenKey = 'token'

function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(tokenKey) || sessionStorage.getItem(tokenKey) || sessionStorage.getItem('visitorToken')
    // if (jwt === null || jwt === undefined) return null
    const jwtDecoded = jwtDecode(jwt)
    const currDate = new Date().getTime() / 1000
    if (jwtDecoded.exp < currDate) {
      localStorage.clear()
      sessionStorage.clear()
      return null
    }
    return jwtDecoded
  } catch (ex) {
    // localStorage.clear()
    return null
  }
}

function issueToken() {
  return new Promise((resolve, reject) => {
    try {
      const response = http.post('/token-refresh/', {
        token: localStorage.getItem(tokenKey)
      })
      resolve(response)
    } catch (ex) {
      reject(ex)
    }
  })
}

function IsTokenAboutToExpired() {
  const jwt = getCurrentUser()
  if (jwt === null) return false

  const exp = jwt.exp
  const currDate = new Date().getTime() / 1000

  //Refresh Token if tokenLive remains 30 min only
  if (exp - currDate <= 1200 && exp - currDate >= 0) {
    return true
  }
  return false
}

function isTokenExpired() {
  const jwt = getCurrentUser()
  if (!jwt) return true;

  const currDate = new Date().getTime() / 1000;
  const exp = jwt.exp

  return exp < currDate;
}

async function renewToken(expiredToken) {
  const payload = {
    token: expiredToken,
  }
  try {
    const { data } = await tokenRenewal.post(`/phone/renew_token/`,
      payload
    )
    return data.token
  } catch (ex) {
    return new Error('Token not issued')
  }
}

export default {
  getCurrentUser,
  issueToken,
  IsTokenAboutToExpired,
  renewToken,
  isTokenExpired
}
