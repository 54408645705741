import axios from 'axios'
import config from '../config.json'
import auth from './authService'
import logger from './logService'

let isRefreshCalled = false

axios.defaults.baseURL = config.apiUrl
// axios.defaults.headers.common['Authorization'] = config.jwtPrefix + AUTH_TOKEN;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
//     'Content-Type': 'multipart/form-data',

// Add a request interceptor
axios.interceptors.request.use(
  async request => {
    if (!auth.getCurrentUser()) 
    {
      localStorage.removeItem(config.tokenKey)
    }
      

    const token = localStorage.getItem(config.tokenKey) || sessionStorage.getItem(config.tokenKey) || sessionStorage.getItem('visitorToken')
    if (token) {
      request.headers['Authorization'] = config.jwtPrefix + token
    }
    // else {
    //   delete axios.defaults.headers.common['Authorization']
    // }

    let originalRequest = request
    const path_is_not_login =
      window.location.pathname !== '/' ||
      window.location.pathname !== '/about' ||
      window.location.pathname !== '/otpverify'
    const tokenIsExpired = auth.IsTokenAboutToExpired()
 
    if (tokenIsExpired && path_is_not_login && !isRefreshCalled) {
      try {
        isRefreshCalled = true
        const newToken = await auth.renewToken(token)
        localStorage.setItem('token', newToken)
        originalRequest.headers['Authorization'] = config.jwtPrefix + newToken

        return Promise.resolve(originalRequest)
      } catch (error) {
        return Promise.reject(error)
      }
    }
    return request
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(null, error => {
  const expectedError = error.response && error.response.status >= 400 && error.response.status < 500
  if(error && error.response && (error.response.status === 401 || error.response.status === 403) ){
    localStorage.clear()
    sessionStorage.clear()
    window.location.reload()
  }
  const backendmessage = error && error.response && error.response.data && error.response.data.details && error.response.data.details.error
  if (!expectedError) {
    logger.log(error)
    // alert('An Unexpected error Occurred')
    console.log('An Unexpected error Occurred')
  }
  if(backendmessage)
    alert(backendmessage)

  return Promise.reject(error)
})

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  patch: axios.patch
  // setJwt
}
