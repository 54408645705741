import axios from 'axios'
import config from '../config.json'


const APIAccessID = 'u$rc8e1c560f87d1c7cc75d640e695854cd'
const APISecretKey = '72d8b819ff36bb074971c96a874d5d8a4fa91362'

const leadSquaredService = axios.create()

const isProd = config.env === 'prod'

export async function createLeadLSApi(payload){
    if(isProd){
        const data = []
        for (const [Attribute, Value] of Object.entries(payload)){
            data.push({
                Attribute,
                Value
            })
        }
        try{
            await leadSquaredService.post(`https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=${APIAccessID}&secretKey=${APISecretKey}`,
                data
            )
        } catch(ex){
            console.error({LeadSquaredError: ex})
        }
    }
    
}

export async function sendActivityLSApi( eventID, fieldsObj, email = true){
    if(isProd && !sessionStorage.getItem('visitorToken')){
        try{
            let Fields = []
            for (let [SchemaName, Value] of Object.entries(fieldsObj || {})){
                Fields.push({
                    SchemaName,
                    Value
                })
            }
            if(email && localStorage.getItem('email')){
                await leadSquaredService.post(`https://api-in21.leadsquared.com/v2/ProspectActivity.svc/Create?accessKey=${APIAccessID}&secretKey=${APISecretKey}`,
                {
                    EmailAddress: email && localStorage.getItem('email'),
                    ActivityEvent: eventID,
                    Fields,
                })
            }
            
            
        } catch(ex){
            console.error({LeadSquaredError: ex})
        }
    }
} 

export async function createSalesActivity(body){
    if(isProd){
        try{
            
            const ProspectId = await getProspectIDByEmail()
            await leadSquaredService.post(`https://api-in21.leadsquared.com/v2/SalesActivity.svc/Create?accessKey=${APIAccessID}&secretKey=${APISecretKey}`,
                {
                ...body,
                ProspectId,
                SalesDate: getDateLS()
            })

        } catch(ex){
            console.error({LeadSquaredError: ex})
        }
    }
}

export async function getProspectIDByEmail(email){
    if(isProd){
        try{
            const inputID = email || localStorage.getItem('email')
            if(inputID){
                const {data} = await leadSquaredService.get(`https://api-in21.leadsquared.com/v2/LeadManagement.svc/Leads.GetByEmailaddress?accessKey=${APIAccessID}&secretKey=${APISecretKey}&EmailAddress=${inputID}`)
                return data && data[0] && data[0]['ProspectID']
            }
        } catch(ex){
            // throw new Error('Can\'t get Prospect ID')
        }
    }
}


const getDateLS = () => {
    let date = new Date().toISOString()
    date = date.replace('T', ' ').slice(0, date.indexOf('.'))
    return date
}

export async function markProspectInvalidByEmail(email, message){
    if(isProd){
        try{
            const inputEmail = email || localStorage.getItem('email')
            const {data} = await leadSquaredService.post(`https://api-in21.leadsquared.com/v2/LeadManagement.svc/MarkEmailInvalid?accessKey=${APIAccessID}&secretKey=${APISecretKey}`,{
                EmailAddress: inputEmail,
                Message: message
            })
            return data
        }
        catch(ex){
            console.error("Unable to mark lead invalid.")
        }
    }
}

export function skipLeadCreation({utm_source}){
    console.log({utm_source})
    if(utm_source && utm_source.toLowerCase() === 'mi'){
        return true
    }
}