import React, { Component } from 'react'
import styled from 'styled-components'
import Icon from '../icons'
import {Ptag} from '../fonts'
import {DateField, GenderButton} from '../../components'
import Input from '../../components/input'
import { validateAlphaOnly, validateEmptyEmail, validatePhone, customValidate} from '../../utils/utils.js'
import { Field } from 'formik'
import moment from 'moment'
import mother from '../../assets/images/relationship/mother.png'
import self from '../../assets/images/relationship/self.png'
import father from '../../assets/images/relationship/father.png'
import son from '../../assets/images/relationship/son.png'
import daughter from '../../assets/images/relationship/daughter.png'
import spouse from '../../assets/images/relationship/spouse.png'
import bin from '../../assets/images/redbin.png'


export default class EmployeeCompleteDetails extends Component{


    getError = (index,field)=>{
        const {touched, errors} = this.props.formikProps
      
        if (touched &&
            touched['employees'] &&
            touched['employees'][this.props.id] &&
            touched['employees'][this.props.id]['dependents'] &&
            touched['employees'][this.props.id]['dependents'][index] &&
            touched['employees'][this.props.id]['dependents'][index][field] &&
            errors &&
            errors['employees'] &&
            errors['employees'][this.props.id] &&
            errors['employees'][this.props.id]['dependents'] &&
            errors['employees'][this.props.id]['dependents'][index] &&
            errors['employees'][this.props.id]['dependents'][index][field]            
        )
        return errors['employees'][this.props.id]['dependents'][index][field]          
    }

    validateDOB = (value) => {
        if (!value){
            return '*Required'
        }
        const planType = localStorage.getItem('plantype')
        if (value.length !== 10)
            return 'Date is invalid'
        let age = moment().diff(moment(value.split('/').reverse().join(), 'YYYYMMDD'), 'years')
        let daysInDiff = moment().diff(moment(value.split('/').reverse().join(), 'YYYYMMDD'), 'days')
        if (daysInDiff <= 0 ||  isNaN(age) || age < 0){        
            return 'Date is invalid'
        } else if( age >= 66 && planType === 'monthly'){
            return 'Our plan covers members up to 65 years of age'
        }
    }

    handleChange =  (e, index) => {
        const value = e.target.value
        const { values, setFieldValue } = this.props.formikProps
        const name = `employees.${this.props.id}.dependents.${index}.dob`
        let prevDate = values && values['employees'] && values['employees'][this.props.id] && values['employees'][this.props.id]['dependents'] && values['employees'][this.props.id]['dependents'][index] && values['employees'][this.props.id]['dependents'][index]['dob']
        let newDate = value
        let prevDateLen = prevDate && prevDate.length
        let newDateLen = newDate.length
        if (newDateLen < prevDateLen) {
           setFieldValue(name, prevDate.substr(0, prevDateLen - 1))
        } else if (newDateLen <= 10) {
          if (newDateLen === 2 || newDateLen === 5) {
            newDate += '/'
            document.getElementById(name).value = newDate
            setFieldValue(name, newDate)
          } else {
             setFieldValue(name, newDate)
          }
        }
    }

    renderDOB = (index, field, placeholder, relationship) => {
        const {handleBlur} = this.props.formikProps
        const name = `employees.${this.props.id}.dependents.${index}.${field}`
        const error = this.getError(index, field)
        
        return(
            <InputWrapper>
                <Field
                name={name}
                validate={(value) => this.validateDOB(value, relationship)}
                render={({ field }) => (
                    <DateField
                    {...field}
                    id={name}
                    placeholder={placeholder}
                    error={error}
                    onChange={event => this.handleChange(event, index)}
                    onBlur={handleBlur}
                    />
                )}
                />
            </InputWrapper>
        )
    }

    renderInput = (index, field, placeholder, validate, type = 'text', width='100%', length=45) => {
        const name = `employees.${this.props.id}.dependents.${index}.${field}`
        const error = this.getError(index, field)
        const {values, setFieldValue, handleBlur } = this.props.formikProps
        return(
            <InputWrapper width={width}>
                 <Field
                    name={name}
                    validate={validate}
                    render={() => (
                        <Input
                            name={name}
                            placeholder={placeholder}
                            type={type}
                            error={error}
                            value={values['employees'] && values['employees'][this.props.id] && values['employees'][this.props.id]['dependents'] && values['employees'][this.props.id]['dependents'][index] && values['employees'][this.props.id]['dependents'][index][field]}
                            onChange={(e) => setFieldValue(name, e.target.value.slice(0,length))}
                            onBlur={handleBlur}
                        />
                    )}/>
            </InputWrapper>    
        )
    }


    formatHeading = (member) => {
        if (member['relationship'] && member['relationship'].toLowerCase() === 'self')
            return 'employee'
        else 
            return member['relationship'] && member['relationship'].toLowerCase()
    }

    renderAddDependents = (formikProps) => {
        console.log(formikProps,"print2")
        const memberCount = {
            'spouse': 1,
            'kids':4,
            'mother':1,
            'father':1,
            'self':1
        }
        if (!(formikProps.values['employees'][this.props.id] && formikProps.values['employees'][this.props.id]['dependents']) || formikProps.values['employees'][this.props.id]['dependents'].length === 6)
            return null
        for (let member of formikProps.values['employees'][this.props.id]['dependents']){
            if (member['relationship'] === 'son' || member['relationship'] === 'daughter') {
                memberCount['kids']--
            } else {
                memberCount[member['relationship']]--
            }
        }
        let ButtonsList = []
        for (let [key, value] of Object.entries(memberCount)){
            if (value > 0){
                if (key === 'kids'){
                    ButtonsList.push('son')
                    ButtonsList.push('daughter')
                } else {
                    ButtonsList.push(key)
                }
            }
        }
        if (ButtonsList.length > 0){
            return(
                <AddDependentsRoot>
                    <Ptag letterSpacing='0.21px' letterHeight='1.67' fontSize='12px' >{'ADD DEPENDENTS'}</Ptag>
                    <ButtonsWrapper>
                        {ButtonsList.map((item, index) => 
                            <Buttons key={index} onClick={() => this.props.onAddFamilyMembers(formikProps, this.props.id, item)}>
                                {item}
                            </Buttons>    
                        )}
                    </ButtonsWrapper>
                </AddDependentsRoot>
            )
        }
        else
            return null
    }

    

    

    renderGenderBtn = (formikProps, index) => {
        let onClickGender = (value, formikProps, name) => {
            formikProps.setFieldValue(name, value)
        }
        const name = `employees.${this.props.id}.dependents.${index}.gender`
        const {values} = formikProps
        let validate = value => !value && '*required' 
        const error = this.getError(index, 'gender')
        return (
            <Field
                name={name}
                validate={validate}
                render={
                    ({field }) => (
                        <GenderButtons style={{'marginTop': '20px'}}>
                            <GenderButtonsWrapper>
                                <Gender
                                onClick={event => onClickGender('male', formikProps, name)}
                                selected={values['employees'] && values['employees'][this.props.id] && values['employees'][this.props.id]['dependents'] && values['employees'][this.props.id]['dependents'][index]['gender'] === 'male'}
                                >
                                {'MALE'}
                                </Gender>
                                <Gender
                                onClick={event => onClickGender('female', formikProps, name)}
                                selected={values['employees'] && values['employees'][this.props.id] && values['employees'][this.props.id]['dependents'] && values['employees'][this.props.id]['dependents'][index]['gender'] === 'female'}
                                >
                                    {'FEMALE'}
                                </Gender>
                            </GenderButtonsWrapper>
                            
                            
                            { error && <GenderError>{error}</GenderError>}
                        </GenderButtons>
                    )
                }
            />  
        )
      }

    renderSaveFooter = () => {
        const {onDeleteEmployee, formikProps, id, employee} = this.props
        const {employee_id} = employee
        return(
            <CardFooter margin='12px -24px -24px -24px'>
                <CardFooterButton onClick={() => this.props.onSaveEmployee(this.props.formikProps, this.props.id, employee_id, employee)}  width="50%">
                    {'SAVE'}
                </CardFooterButton>
                <CardFooterButton onClick={() => onDeleteEmployee(formikProps, id, employee_id)} width="50%">
                    {localStorage.getItem('navigatingFrom') === 'dashboard' ? 'CANCEL' : 'REMOVE'}
                </CardFooterButton>
            </CardFooter>
        )
    }

    renderReadOnlyCard = () => {
        const self = this.props.employee['dependents'][0]
        const dependents = this.props.employee['dependents'].slice(1)
        const { onEditEmployee, id, employee} = this.props
        
        return(
            <React.Fragment>
                <NameAndDob>
                    <HeadingAndValue>
                        <Ptag textTransform='uppercase' letterHeight='1.67' letterSpacing='0.75px' >
                            {'Name'}
                        </Ptag>
                        <Ptag fontSize='16px' textTransform='capitalize' letterSpacing='0.4px' color='#009999'>
                            {`${self['first_name']} ${self['last_name']}`}
                        </Ptag>
                    </HeadingAndValue>
                    <HeadingAndValue>
                        <Ptag textTransform='uppercase' letterHeight='1.67' letterSpacing='0.75px' >
                            {'dob'}
                        </Ptag>
                        <Ptag fontSize='16px' letterSpacing='0.4px' color='#009999'>
                            {`${self['dob']}`}
                        </Ptag>
                    </HeadingAndValue>
                </NameAndDob>

                <HeadingAndValue>
                    <Ptag textTransform='uppercase' letterHeight='1.67' letterSpacing='0.75px' >
                        {'mobile'}
                    </Ptag>
                    <Ptag fontSize='16px' letterSpacing='0.4px' color='#009999'>
                        {`${self['phone']}`}
                    </Ptag>
                </HeadingAndValue>
                {
                    self['email'] && 
                    <HeadingAndValue>
                        <Ptag textTransform='uppercase' letterHeight='1.67' letterSpacing='0.75px' >
                            {'email'}
                        </Ptag>
                        <Ptag fontSize='16px' letterSpacing='0.4px' color='#009999'>
                            {`${self['email']}`}
                        </Ptag>
                    </HeadingAndValue>
                }
                
                {
                    dependents && dependents.length > 0 &&
                    <HeadingAndValue>
                        <Ptag textTransform='uppercase' letterHeight='1.67' letterSpacing='0.75px' >
                            {'dependents'}
                        </Ptag>
                    
                        {
                            dependents.map( (item, index) => (
                                <Ptag style={{'marginTop': '2px'}} textTransform='capitalize' fontSize='16px' letterSpacing='0.4px' color="#009999">
                                    {`${item['first_name']} ${item['last_name']}, ${moment().diff(moment(item['dob'].split('/').reverse().join(), 'YYYYMMDD'), 'years')} yrs`}
                                </Ptag>
                                
                        ))
                        }
                    </HeadingAndValue>
                }
                {
                    !this.props.disableEdit && 
                    <CardFooter margin='20px -24px -24px -24px'>
                        <CardFooterButton onClick={() => onEditEmployee(id)} width="100%">
                            {'EDIT'}
                        </CardFooterButton>
                    </CardFooter>
                }
               
            </React.Fragment>
        )
    }

    render(){
        const {employee, formikProps, editable, showHeader} = this.props
        const {expanded} = employee
        function isSelf(index){
            return employee && employee['dependents'] && employee['dependents'][index] && employee['dependents'][index]['relationship'] && employee['dependents'][index]['relationship'].toLowerCase() === 'self'
        }
        const name = formikProps.values && formikProps.values['employees'] && formikProps.values['employees'][this.props.id] && formikProps.values['employees'][this.props.id]['dependents'] && formikProps.values['employees'][this.props.id]['dependents'][0] && (formikProps.values['employees'][this.props.id]['dependents'][0]['first_name'] + ' ' + formikProps.values['employees'][this.props.id]['dependents'][0]['last_name'])
        const values = formikProps.values && formikProps.values['employees'] && formikProps.values['employees'][this.props.id] && formikProps.values['employees'][this.props.id]['dependents'] && formikProps.values['employees'][this.props.id]['dependents']
        return(
            <Root>
                {showHeader &&
                    <TopRow onClick={() => this.props.onClickCardHeader(this.props.id)}>
                        <NumberAndText>
                            <Number>
                                {this.props.id + 1}
                            </Number>
                            {!expanded && 
                                <HeaderText>
                                    <Ptag textTransform='capitalize' fontSize='16px' letterSpacing='0.25px' >
                                        {name}
                                    </Ptag>
                                    {values && values.length > 1 && <Circle/>}
                                    {values && values.length > 1 && 
                                        <Ptag textTransform='capitalize' fontSize='16px' letterSpacing='0.25px' >
                                            {`${values.length -1} more`}
                                        </Ptag>
                                    }
                                </HeaderText>
                            }
                        </NumberAndText>
                        {<Icon  icon={expanded ? 'chevron_down' : 'chevron_right'} fill="#7e7b7f" />}
                    </TopRow>
                }
                {
                    <CardBody expanded={expanded && !editable}>
                        {this.renderReadOnlyCard()}
                    </CardBody>
                }
                <CardBody expanded={expanded && editable}>
                    {employee && employee['dependents'] && employee['dependents'].map((dependent, index) => 
                        <Details key={`employee${index}`}>
                            <DependentBody >
                                <CardHeading>
                                    {
                                        dependent['relationship'] !== 'self' && 
                                        <CardHeadingEmployee>
                                            <RelationshipMap src={self}/>
                                            <Ptag textTransform='uppercase' color='#009999' letterSpacing='0.5px' fontWeight='600' fontSize='16px'>{this.formatHeading(dependent)}</Ptag>
                                        </CardHeadingEmployee>
                                    }
                                    {dependent['relationship'] !== 'self' && <img onClick={() => this.props.onDeleteDependent(formikProps, this.props.id, index)} height='18px' width='14px' src={bin}/>}
                                </CardHeading>
                                {
                                    isSelf(index) && this.renderGenderBtn(formikProps, index)
                                }
                                <NameRow>
                                    {this.renderInput(index, 'first_name', 'First Name', customValidate, 'text','48%')}
                                    {this.renderInput(index, 'last_name', 'Last Name', customValidate, 'text','48%')}
                                </NameRow>
                                {this.renderDOB(index, 'dob', 'DOB', dependent['relationship'])}
                                {isSelf(index) && this.renderInput(index, 'phone', 'Mobile Number', null, 'number','100%',10)}
                                {isSelf(index) && this.renderInput(index, 'email', 'Email (optional)', null, 'text')}
                            </DependentBody>
                        </Details>
                    )}
                    {this.renderAddDependents(formikProps)}
                    {this.renderSaveFooter()}
                </CardBody>
                
               
            </Root>
        )
    }
}

const Root = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    padding: 24px;
    margin-top: 24px;
`;


const Details = styled.div`
    display: flex;
    flex-direction: column;
    margin-top:24px;
    padding-bottom: 36px;
    border-bottom: 1px dashed #979797;
`

const TopRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const NumberAndText = styled.div`
    display:flex;
    align-items: center;
`;

const Number = styled.div`
    border-radius: 18px;
    width: 36px;
    height: 36px;
    margin-right: 12px;
    padding-top: 6px;
    background-color: ${props => props.bGcolor ? props.bGcolor : '#d8d8d8'};
    color: ${props => props.color ? props.color : '#009999'};
    font-family: Open Sans;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    text-align: center;
`

const DependentBody = styled.div`
    display: flex;
    flex-direction: column;
`

const CardBody = styled.div`
    display: ${props => props.expanded ? 'flex' : 'none'};
    flex-direction: column;
`

const InputWrapper = styled.div`
    display: flex;
    width: ${props => props.width ? props.width : '100%'};
    
    & > div {
        margin-top: 2.4rem;
        padding: 0;
    }
`

const NameRow = styled.div`
    display: flex;
    justify-content: space-between;  
`;

const AddDependentsRoot = styled.div`
    display:flex;
    flex-direction: column;
    margin-top: 24px;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-wrap:wrap;
    margin-top: 18px;
`;


const Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 86px;
    height: 36px;
    border-radius: 8px;
    border: solid 1px #436ab2;
    margin: 0 12px 12px 0;
    font-family: Open Sans;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #4a4a4a;
    text-transform: capitalize;
`;

const GenderError = styled.p`
  display: flex;
  font-size: 14px;
  color: red;
  margin-top: 48px; 
  height: 20px;
  position:absolute;
`

const CardHeading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const CardHeadingEmployee = styled.div`
    display: flex;
    align-items: center;
`

const RelationshipMap = styled.img`
    height: 32px;
    width: 32px;
    margin-right: 10px;
`;

const CardFooter = styled.div`
    display: flex;
    border-top: 1px solid rgba(125, 124, 124, 0.2);
    height: 64px;
    align-items: center;
    margin: ${props => props.margin};
    & > :not(:last-child) {
        border-right: 1px solid rgba(125, 124, 124, 0.2);
      }
`;

const CardFooterButton = styled.div`
    display: flex;
    width: ${props => props.width};
    height: 100%;
    align-items: center;
    justify-content: center;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.71px;
    color: #f27576;
`;


const Dependent = styled.div`
    display: flex;
    flex-direction: column;
    & > :first-child{
        margin-top: 24px;
    }
    & > :nth-child(2){
        margin-top: 12px;
    }
`

const GenderButtons = styled.div`
    display: flex;
    flex-direction: column;
`;

const GenderButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

const Gender = styled.div`
    display: flex;
    width: 48%;
    height: 44px;
    align-items: center;
    justify-content: center;
    border: 2px solid #436ab2;
    border-radius: 15px;
    background-color: ${props => props.selected ? '#436ab2' : 'white'};
    font-family: Open Sans;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 1.25px;
    text-align: center;
    color: ${props => (props.selected ? '#fff' : 'rgba(119, 119, 119, 0.87)')}
`

const AddDependentWrapper = styled.div`
    display: flex;
    border-radius: 10px;
    border: solid 1px #dad7d0;
    background-color: #ffffff;
    padding: 21px 0;
    width: 100%
    margin: 24px 0;
    justify-content: center;
`;

const HeaderText = styled.div`
    display: flex;
`

const Circle = styled.div`
  width:4px;
  height:4px;
  border-radius: 2px;
  background-color: #7d7c7c;
  margin: 10px 8px;
`;

const HeadingAndValue = styled.div`
    display: flex;
    flex-direction: column;
    & > :first-child {
        margin-bottom: 2px;
    }
    margin-bottom: 20px;
`

const NameAndDob = styled.div`
    display: flex;
    margin-top: 24px;
    & > :first-child{
        width: 60%;
    };
`