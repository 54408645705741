import React from "react";
import styled from "styled-components";
import { Ptag } from "../fonts";

import mfine from "../../assets/images/mfine.png"
import capsule from "../../assets/images/capsule.png"
import doctor from "../../assets/images/medicalDoctor.png"
import healthians from "../../assets/images/healthians.png"
import checkup from "../../assets/images/medicalcheckup.png"
import new1mg from "../../assets/images/new1mg.png"
import cell from '../../assets/images/cell.png'
import flask from '../../assets/images/flask.png'
import liver from '../../assets/images/liver.png'
import lipid from '../../assets/images/lipid.png'
import kidney from '../../assets/images/kidney.png'
import shield from '../../assets/images/shieldIcon.png'
import icicil from "../../assets/images/iciciLombard.png";

import arrow1 from "../../assets/images/blueArrow.png";
import PolicyType from "../memberApp/constants/PolicyConstants.js";


const InsuranceBenefits = ({onClickCoverageDoc, policyType, cardWidth, insuredSum, hideTests, showLinks, onClickViewClaimProcess}) => {

    const getConsultationDescription = () => {
        return 'Unlimited tele/video consultations with general physicians from top hospitals for the entire family'
    }
    

    const Benefits = [
      {
        id: 'insurance',
        HeaderIcon: shield,
        Heading: 'Insurance',
        sideIcon: icicil ,
        sideHeading: 'ISSUED BY',
        description: 'For treatment and hospitalization expenses',
        iconHeight: '25px',
        iconWidth: '100px',
        marginBottom: '4px',
        insured: {
          sum: insuredSum
        },
        links: true
      },
      {
        id: 'mfine',
        HeaderIcon: doctor,
        Heading: 'Consultation',
        sideIcon: mfine ,
        sideHeading: 'POWERED BY',
        description: getConsultationDescription(),
        iconHeight: '25px',
        iconWidth: '55px',
        marginBottom: '4px',
      },


      {
        id: 'checkup',
        HeaderIcon: checkup,
        Heading: 'Checkup',
        sideIcon: healthians,
        sideHeading: 'POWERED BY',
        description: `Annual full body health checkup for employeee with home sample collection ${ !hideTests  && 'which includes'}` ,
        iconHeight: '20px',
        iconWidth: '72px',
        marginBottom: '4px',
        tests: [{
          key: 'bloodprofile',
          Icon: cell,
          title: 'Blood Profile',
          not: 24,
          type: 'all'
        },
        {
          key: 'lipidprofile',
          Icon: lipid,
          title: 'Lipid Profile',
          not: 9,
          type: 'all'
        },
        {
          key: 'kidneyfunction',
          Icon: kidney,
          title: 'Kidney Function',
          not: 11,
          type: 'all'
        },
        {
          key: 'liverfunction',
          Icon: liver,
          title: 'Liver Function',
          not: 12,
          type: 'all'
        },
        {
          key: 'hepatitis',
          Icon: flask,
          title: 'HbA1c Test',
          not: 1,
          type: 'diabetics'
        }
        ]
      },

      
      {
        id: 'pharmacy',
        HeaderIcon: capsule,
        Heading: 'Pharmacy ',
        sideIcon: new1mg,
        sideHeading: 'PARTNER',
        description: 'Flat 25% off on your prescription medicine or OTC bills through our pharmacy partner',
        iconHeight: '20px',
        iconWidth: '36px',
        marginBottom: '4px',
      }
    ]

    if (!insuredSum){
      Benefits.splice(0,1)
    }
    
    const tileVisibility = type => {
      if (type === 'all')
        return 'flex'
      if (type === 'diabetics' && (policyType === PolicyType.PED_NON_INSULIN || policyType === PolicyType.PED_INSULIN))
        return 'flex'
      return 'none'
    }

    const getCardBackgroundColor = () => {
        return '#436ab2'
      };
    
    return (
      <>
        {Benefits.map( item => 
          <BlueWrapper key={`${item['id']}_benefits`} cardWidth={cardWidth} border={getCardBackgroundColor()}>
            <CardHeader paddingBottom='14px'>
              <CardHeaderTileTitle>
                <CardIcon  src={item['HeaderIcon']}/>
                <Heading>
                  {item['Heading']}
                </Heading>
              </CardHeaderTileTitle>
              
              <ThirdParty marginBottomText={item['marginBottom']}>
                <Ptag fontSize='8px' letterSpacing='0.5px' textAlign='right' color='rgba(0,0,0,0.6)' textTransform='uppercase'>
                  {item['sideHeading']}
                </Ptag>
                <img src={item['sideIcon']} height={item['iconHeight']} width={item['iconWidth']} />
              </ThirdParty>
            </CardHeader>
            <Ptag style={{'marginTop': '16px'}} color='#666666' letterSpacing='0.5px' letterHeight='1.8'>
              {item['description']}
            </Ptag>
            {
              item['insured'] &&
              <CardFooterItem>
                <Ptag
                  textTransform="uppercase"
                  fontSize="10px"
                  fontWeight="600"
                  letterHeight="1"
                  letterSpacing="1px"
                >
                  {"Sum Insured"}
                </Ptag>
                <Ptag fontWeight="600" fontSize="18px" letterSpacing="1px">
                  {item['insured']['sum']}
                </Ptag>
              </CardFooterItem>
            }
            {
              item['links'] && showLinks &&
              <LinksWrapper>
                  <ViewMarketPlace onClick={() => onClickCoverageDoc()}>
                    <u style={{ textDecoration: "none" }}>
                      {"Coverage  Document"}
                    </u>
                    <img
                      style={{ margin: "2px 0px 0px 6px" }}
                      src={arrow1}
                      width="14px"
                      height="14px"
                    />
                  </ViewMarketPlace>
                  <ViewMarketPlace
                    onClick={() => onClickViewClaimProcess()}
                  >
                    <u style={{ textDecoration: "none" }}>{"Claim Process"}</u>
                    <img
                      style={{ margin: "2px 0px 0px 6px" }}
                      src={arrow1}
                      width="14px"
                      height="14px"
                    />
                  </ViewMarketPlace>
                </LinksWrapper>
            }
            {
              item['tests'] && !hideTests &&
                <TestsWrapper>
                    {
                       item['tests'].map( test => 
                          <Test view={tileVisibility(test['type'])} key={test['key']}>
                            <img src={test['Icon']}/>
                            <Ptag color='#666666' maxwidth="45px" textAlign='center' fontSize='11px' letterSpacing='0.5px' >
                              {test['title']}
                            </Ptag>
                            <Ptag fontSize='9px' fontWeight='600' color='#4a4a4a'>
                              {`${test['not']} Tests`}
                            </Ptag>
                            
                          </Test>
                        )
                    }
                </TestsWrapper> 
             
            }
          </BlueWrapper>

        )}
      </>
      
    )    
  }

export default InsuranceBenefits

const BlueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${props => props.cardWidth ? props.cardWidth : 'none'};
  min-width: ${props => props.cardWidth ? props.cardWidth : 'none'};
  border: 1px solid ${props => props.border};
  border-radius: 10px;
  padding: 18px 14px;
  margin-bottom: 16px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
`


const CardHeaderTileTitle = styled.div`
  display: flex;
  align-items: center;
  & > img{
    margin-right: 4px;
  }
`

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${ props => props.paddingBottom ? props.paddingBottom : '26px'};
  border-bottom: 1px solid #4a90e230;
`


const CardIcon = styled.img`
  width:auto !important;
  height: 36px;
`


const TestsWrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  margin-top: 16px;
  margin-right: -14px;
  ::-webkit-scrollbar {
    display: none;
  }
`

const Test = styled.div`
  display: ${props => props.view ? props.view : 'hidden'};
  flex-direction: column;
  align-items: center;
  min-width: 60px;
  max-width: 60px;
  margin-right: 16px;
  & > img{
    margin-bottom: 8px;
    object-fit: contain;
    height: 28px;
  };
  p:first-of-type{
    margin-bottom: 4px;
  }
`


const ThirdParty = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  align-items: end;
  & > p {
    margin-bottom: ${props => props.marginBottomText || '4px'};
  };
  & > img {
    object-fit: contain;
  }
`

const CardFooterItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: 18px;
  & :first-child {
    margin-bottom: 8px;
  }
`;

const ViewMarketPlace = styled.div`
  display: flex;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.38px;
  text-align: right;
  color: #4a90e2;
  cursor: pointer;
`;

const LinksWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const Heading = styled.p`
font-family: Open Sans;
font-size: 16px;
color: #436ab2;
letter-spacing: 0.57px; 
font-weight: 600;
@media (min-width: 1024px) {
  font-size: 18px;
}
`