import React, { useMemo } from "react";
import styled from "styled-components";
import { getEmployeeTypeFromEnum } from "../../utils/utils";
import { theme } from "../../theme";
import { Ptag } from "../fonts";

const EmployeeTypeSelectionCard = ({ isSelected, type, employeeOptionHandler }) => {
    return (
        <TextContainer onClick={employeeOptionHandler} isSelected={isSelected}>
            <Ptag
                fontSize="16px"
                // letterSpacing="1px"
                lineHeight="22px"
                color={isSelected ? "#ffffff" : "#4A4A4A"}
                fontWeight="400"
            >
                {getEmployeeTypeFromEnum(type)}
            </Ptag>
        </TextContainer>
    )
}

const TextContainer = styled.li`
    border: 1px solid #436AB2;
    background-color: ${props => (props.isSelected ?  theme.accentColor: "#ffffff")};
    padding: 12px 30px;
    border-radius: 8px;
    display: grid;
    place-items: center;
    text-align: center;
    cursor: pointer;
`

export default EmployeeTypeSelectionCard;