import React, { Component } from "react";
import styled, { keyframes } from "styled-components";
import {Header} from '../../components/business'
import {Ptag} from '../../components/fonts'
import {Input, LargeButton, OverlayLoader, } from '../../components'
import { Formik, Form, FieldArray } from 'formik'
import * as Yup from 'yup'
import http from '../../services/httpService'
import TagManager from 'react-gtm-module'
import {gtmCode} from '../../utils/utils'


export default class EmployerAddress extends Component{
    state = {
        loader: false
    }

    componentDidMount(){
        const tagManagerArgs = {
            gtmId: gtmCode(),
        }
        TagManager.initialize(tagManagerArgs)
    }

    handleSubmit = async (values) => {
        const address = `${values['address']} # ${values['city']} # ${values['state']} # ${values['pincode']}`
        this.setState({loader: true})
        const id = Number(localStorage.getItem('companyId'))
        let _this = this
        try{
            await http.patch(`/business/company/${id}/`,{
                address: address,
            })        
            const userplan_id = localStorage.getItem('userplan_id')
            let body = {
                userplan: userplan_id,
                payment: {
                  method: 0,
                  plan: 0,
                }
              }
                let response = await http.post('/business/subscription/', { ...body })
          
                const options = {
                  async handler(response) {
                    await http.post('/payment/verify/', {
                      razorpay_payment_id: response.razorpay_payment_id,
                      razorpay_signature: response.razorpay_signature,
                      razorpay_order_id: response.razorpay_order_id
                    })
                    localStorage.setItem('policyBought',true)
                    localStorage.removeItem('offlineQuote')
                    _this.props.history.push('/business/paymentsuccess')
                  },
                  ...response.data
                }
          
                const rzp = new window.Razorpay(options)
                rzp.open()
        } catch (ex) {
            console.log('Error-Ex: ', ex)
            _this.setState({loader: false})  
        }
        this.setState({loader: false})
    }

    handleChange = (event, setFieldValue, name, length) => {
        const value = event.target.value
        setFieldValue(name, value.slice(0, length))
    }

    renderInput = ({name, placeholder, type, formikProps, length=100} = {}) => {
        const {errors, touched, handleBlur, setFieldValue, values} = formikProps
        const error = touched[name] && errors[name]
        return(
            <InputContainer>
                <Input value={values[name]} name={name} placeholder={placeholder} 
                    type={type} error={error} 
                    onChange={(event) => this.handleChange(event, setFieldValue, name, length)}
                    onBlur={handleBlur}/>
            </InputContainer>
        )
    }

    renderForm = (formikProps) => {
        return(
            <FormContainer>
                <Form>
                    {this.renderInput({name:'address', placeholder:'address', type:'text', formikProps,})}
                    {this.renderInput({name:'city', placeholder:'city',type:'text', formikProps})}
                    {this.renderInput({name:'state', placeholder:'state',type:'text', formikProps})}
                    {this.renderInput({name:'pincode', placeholder:'pincode', type:'number', formikProps, length: 6})}
                    <ButtonWrapper>
                        <LargeButton id="business_employeraddress_pay_now" label= {'PAY NOW'} type="submit"/>
                    </ButtonWrapper>
                </Form>
                
            </FormContainer>
        )
    }

    render(){
        const Schema = Yup.object().shape({
            address: Yup.string().required('*Required'),
            pincode: Yup.number()
            .typeError('pincode must be a number')
            .required('*Required')
            .test('len', 'pincode must be exactly 6 digits', val => String(val).length === 6),
            city: Yup.string().required('*Required'),
            state: Yup.string().required('*Required'),
          })
        const about = JSON.parse(localStorage.getItem('about'))
        const initialValues = {
            city: '',
            pincode :  about && about['pincode'] || '',
            state: '',
            address: '',
        }
        return(
            <Root>
                {this.state.loading && <OverlayLoader />}
                <Header onClickBack={() => this.props.history.push('/business/uploadpan')} progressPercentage={100}/>
                <Body>
                    <Ptag fontSize='24px' fontWeight='500' letterHeight='1.33' letterSpacing='0.35px'>
                        {'office address'}
                    </Ptag>

                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={Schema}
                        onSubmit={this.handleSubmit}
                        render={formikProps => this.renderForm(formikProps)}
                    />
                </Body>

                {this.state.loader && <OverlayLoader/>}
            </Root>
        )
    }
}


const Root = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 26px 20px;
    flex: 1;
`

const FormContainer = styled.div`
    display:flex;
    flex-direction: column;
    margin-top: 34px;
    flex:1;
    & > form{
        display: flex;
        flex-direction: column;
        flex:1;
    }
`;


const InputContainer = styled.div`
    display: flex;
    margin-bottom: 4px;
    
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex:1;
    align-items: flex-end;
`