import styled from "styled-components";
import { colors } from "../../theme";

export const DetailWrapper = styled.section`
  width: 300px;
  border: 1.5px solid #436ab2;
  padding: 20px 24px;
  border-radius: 10px;
  margin-right: 2rem;
  user-select: none;
`;
export const SplitCardTag = styled.div`
  padding: 0.4rem 1.4rem;
  border-radius: 10px;
  width: min-content;
  background: #fac979;
`;

export const DetailWrapperHeader = styled.header`
  display: flex;
  align-items: center;
`;

export const InsuranePlanWrapper = styled.section`
  user-select: none;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  width: 36rem;
  background: #f5f8ff;
  border-radius: 15px;
  max-width: 44rem;
  position: relative;
  transition: 0.2s all ease-in-out;
  /* &:hover {
    background-color: rgba(230, 237, 255, 0.6);
    transform: scale(1.005);
  } */
`;

export const Header = styled.header`
  display: flex;
  width: 45rem;
  flex-direction: row;
  align-items: center;
  & > img {
    transition: 0.2s all ease-in-out;
  }
  /* ${InsuranePlanWrapper}:hover & > img {
    transform: scale(1.1);
  } */
`;

export const Link = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: 0.2s ease-in-out all;
`;

export const Image = styled.img`
  transition: transform 0.2s ease-in-out;
  margin-left: 0.4rem;
  ${Link}:hover & {
    transform: translateX(4px);
  }
`;

export const LinksWrapper = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0rem 2rem 2rem 2rem;
`;

export const Footer = styled.footer`
  padding: 20px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 0px;
  background-color: #d0d9e9;
  height: 80px;
  border-radius: 0 0 10px 10px;
  transition: 0.2s all ease-in-out;
  /* ${InsuranePlanWrapper}:hover & {
    background-color: #cbd5e7;
  } */
`;

export const BenefitCardWrapper = styled.section`
  border-radius: 12px;
  min-width: 320px;
  min-height: 160px;
  background-color: #f5f8ff;
  padding: 20px 24px;
  transition: 0.2s ease-in-out all;
  /* &:hover {
    background-color: rgba(230, 237, 255, 0.6);
    transform: scale(1.01);
  }
  &:hover > header > img {
    transform: scale(1.05);
  } */
`;

export const BenefitsWrapper = styled.section`
  display: flex;
  gap: 2rem;
  overflow: scroll;
  width: 100%;
  margin-top: 2rem;
  user-select: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }
`;

export const InsurerIcon = styled.img`
  max-width: 7rem;
  max-height: 3rem;
  object-fit: cover;
`;

// export const TableWrapper = styled.div`
//   max-width: 100%;
//   max-height: 100vh;
//   float: left;
//   border-radius: 10px;
//   padding-bottom: 8rem;
//   overflow-x: scroll;
//   ::-webkit-scrollbar {
//     display: none;
//   }
// `

export const StickyCell = styled.th`
  position: sticky;
  left: 0;
  z-index: 8;
  background: ${(props) => props.bgColor};
`;

export const TopHeader = styled.div`
  min-width: 175px;
  display: flex;
  padding: 22px 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Table = styled.table`
  display: block;
  max-width: -moz-fit-content;
  max-width: fit-content;
  border-collapse: collapse;
  border: none;
  thead {
    position: sticky;
    top: 0;
    z-index: 10;
    & > tr:first-child th:first-child {
      background: ${props => props.theme.cardBackgroundColor};
      position: sticky;
      top: 0;
      left: 0;
      z-index: 13;
    }
    & > tr:first-child :not(:first-child) {
      background: ${props => props.theme.cardBackgroundColor};
    }

    td {
      background: white;
      position: sticky;
      top: 0;
      left: 0;
      z-index: 12;
    }
  }
  tbody tr {
    border-bottom: 0.5px solid #3F9DFF;
  }
`;

export const TableWrapperHeader = styled.section`
  display: flex;
  margin: 4rem 0rem 2rem 0rem;
  justify-content: space-between;
  align-items: center;
`;

export const DownloadButton = styled.button`
  border: 0px;
  color: rgb(255, 255, 255);
  background: ${props => props.theme.accentColor};
  padding: 14px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  letter-spacing: 0.5px;
  font-size: 0px;
  cursor: pointer;
  letter-spacing: 0.5px;
  border-radius: 10px;
  margin-right: 10px;
`;

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: ${props => props.theme.cardBackgroundColor};
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 24px;
`;

export const SearchInput = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
  width: 50%;
  border-radius: 8px;
  & > input {
    font-size: 14px;
    line-height: 1.5;
    border: none;
    background: #ffffff;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'><path fill='%23838D99' d='M13.22 14.63a8 8 0 1 1 1.41-1.41l4.29 4.29a1 1 0 1 1-1.41 1.41l-4.29-4.29zm-.66-2.07a6 6 0 1 0-8.49-8.49 6 6 0 0 0 8.49 8.49z'></path></svg>");
    background-repeat: no-repeat;
    background-position: 10px 10px;
    background-size: 20px 20px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);
    border-radius: 10px;
    border: ${props => "1px solid " + props.theme.primaryColor};
    width: 100%;
    padding: 10px 10px 10px 40px;
  }
  & > input::placeholder {
    color: #838d99;
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
    cursor: pointer;
    justify-content: space-between;
    margin-right: 10px;
    width: 200px;
    height: 43px;
    margin: 0 14px;
    border-radius: 10px;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    z-index:0;
    & > div {
        position: absolute;
        user-select: none;
        pointer-events: none;
        right: 4px;
        top: 50%;
        transform: translateY(-50%);
    }
`;

export const Dropdown = styled.select`
  display: flex;
  position: relative;
  width: 100%;
  border: none;
  font-family: "Avenir";
  font-size: 14px;
  padding: 10px 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  padding-left: 36px;
  color: ${props => props.color ? props.color : 'rgba(0, 0, 0, 0.5)'};
  background-color: ${props => props.theme.accentColor};
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  &:hover,
  &:focus 
  {
  outline: 0 !important;
  };
`;

export const Option = styled.option`
  color: ${(props) => (props.color ? props.color : "black")};
`;

export const SplitDetailCardWrapper = styled.section`
  background-color: #E5F7FF;
  width: ${props => props.width ? props.width : '24rem'};
  padding: 2rem;
  border-radius: 10px;
`;
export const SplitDetailWrapper = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-top: 1rem;
  padding: 0.4rem 0rem;
  & > div {
    width: 40%;
  }
`;

export const VerticalDivider = styled.section`
  width: 1px;
  height: 4rem;
  background-color: #c6c2c2;
`;

export const DownloadLink = styled.p`
  font-family: "Open Sans";
  font-size: 14px;
  color: #2287ff;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
`;

export const ECardStrip = styled.aside`
  width: 20px;
  background-color: ${props => props.theme.primaryColor};
`;

export const ECardWrapper = styled.section`
  border-radius: 10px;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  width: max-content;
`;



export const ECardHeader = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ECardLink = styled.a`
  font-family: "Open Sans";
  color: #4a90e2;
  font-size: 14px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
`;

export const Line = styled.div`
  margin-top: 10px;
  height: 1px;
  background-color: #828080;
`;

export const ECardDetailWrapper = styled.section`
  display: grid;
  gap: 2rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 44px 44px 44px;
  padding: 2.4rem 4rem 2.4rem 2.8rem;
`;

export const ECardScroll = styled.section`
  margin-bottom: 2rem;
`;

export const ECardRightSection = styled.div`
  margin-left: 2rem;
`;

export const InsurerLogoWrapper = styled.div`
  width: 90px;
  height: 30px;
  & > img {
    max-width: 90%;
    max-height: 90%;
  }
`

export const FilterImage = styled.img`
    position: absolute;
    height: 14px;
    top: 14px;
    left: 14px;
    z-index: 999;
    user-select: none;
    pointer-events: none;
`
export const TableWrapper = styled.div`
  height: ${(props) => props.showHalf ? '40vh' : '80vh'};
  display: inline-block;
  overflow: scroll;
  width: 100%;
`;

export const ContentRow = {
  borderBottom: "0.5px solid rgb(63, 157, 255)",
  fontFamily: "Avenir !important",
  color: colors.defaultFontColor,
  fontWeight: "500",
  overflow: 'hidden',
  wordWrap: 'break-word',
};

export const HeaderRow = {
  backgroundColor: "rgb(241, 243, 246)",
  borderBottom: "none",
  fontFamily: "Avenir",
  whiteSpace: "nowrap",
  color: colors.primaryColor,
  paddingTop: "24px",
  paddingBottom: "24px",
  wordWrap: 'break-word',
};

export const MaterialTable = {
  tableLayout: "fixed",
  border: "none",
  borderCollapse: "separate",
  "*": {
    fontSize: "14px !important",
  },
};

export const MaterialTableContainer = {
  borderRadius: "14px !important",
  scrollbarWidth: "thin",
};

export const BoxHeading = styled.div`
  display:flex;
  justify-content:space-between;
  padding-bottom: 16px;
  align-items: center;
`

export const Download = styled.div`
    margin-top: 0px;
    border: 0px;
    color: rgb(255, 255, 255);
    background: ${props => props.theme.accentColor};
    padding: 10px 20px;
    display: flex;
    padding: 1.2rem 2rem;
    justify-content: space-between;
    align-items: center;
    letter-spacing: 0.5px;
    font-size: 0px;
    cursor: pointer;
    letter-spacing: 0.5px;
    height: 44px;
    border-radius: 10px;
    margin-right:10px;
    transition: 0.2s ease-in-out all;
`

export const SearchfilterDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${props => props.theme.cardBackgroundColor};
  width:100%;
  padding: 10px;
  border-radius:10px;
  margin-bottom:24px;
`
export const InputDiv = styled.div`
    display:flex;
    flex-direction: row;
    width: 100%;
    padding:15px;
    border-radius:8px;
`
export const FilterDiv = styled.div`
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    margin-right: 10px;
    width: 180px;
    height: 43px;
    margin: 0 14px;
    border-radius: 10px;
    font-size: 12px;
    position: relative;
    overflow: hidden;
    z-index:0;
    & > div {
        position: absolute;
        user-select: none;
        pointer-events: none;
        right: 4px;
        top: 50%;
        transform: translateY(-50%);
    }
`