import React, {useEffect} from 'react'
import styled from 'styled-components'
import {Header} from '../../components/business'
import {Ptag} from '../../components/fonts'
import {LargeButton} from '../../components'
import TagManager from 'react-gtm-module'
import {gtmCode} from '../../utils/utils'

function Renewal({...props}){
    function onClickOkay(){
        props.history.push('/business')
    }
    useEffect(  () => {
        const tagManagerArgs = {
            gtmId: gtmCode(),
        }
        TagManager.initialize(tagManagerArgs)
        localStorage.clear()
    })
    return(
        <Home>
            <Header/>
            <Ptag textTransform='uppercase' fontFamily='Proxima Nova' style={{'marginTop': '180px', 'padding': '0 20px'}} fontSize='20px' textAlign='center' >
                {'Thank you for considering Vital!'}
            </Ptag>
            <Ptag style={{'marginTop': '10px', 'padding': '0 20px'}} fontFamily='Proxima Nova' fontSize='20px' textAlign='center' >
                {'We will contact you within 24 hours'}
            </Ptag>
            <SubmitWrap>
                <LargeButton id='business_renewal_ok' onClick={onClickOkay} label='OKAY!'/>
            </SubmitWrap>
        </Home>
    )
}


export default Renewal

const Home = styled.div`
    display: flex;
    flex-direction: 100%;
    height: 100vh;
    flex-direction: column;
`

const SubmitWrap = styled.div`
    display: flex;
    flex:1;
    padding: 24px;
    align-items: flex-end;
`


