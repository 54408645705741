
const Text = {
    'tnc':[
        'The rates and adjustment parameters are applicable only for new business only \
        (Corporates purchasing insurance for the first time). ',
        ' All insurable members in the group to be insured under policy and there shall \
        be no selection of members. Voluntary parental selection is not allowed.',
        ' Members, already employed but not declared by the employer at the time of providing\
         the quote, shall not be covered subsequently after commencement of the policy.',
        ' Renewals  are outside scope of this quote',
        ' Addition and deletion shall be allowed within 45 days enrolment period'
    ],
    'specialNote': 
        'No individual can be covered more than once in the policy – specifically if an employee and \
        spouse are working for the same \
        organization both cannot cover each other \
        and cannot cover the same set of \
        dependents. In case, at the time of claim it is \
        found that the member is covered twice, a \
        deletion endorsement of member will be \
        processed to remove that member and there \
        will be no refund for such deletions.',
    'addition': 'Additions  of newly joined employees and their dependants, if covered under the policy,\
        may be done on prorata basis, with effect  from date of joining of the employee.  \
        In case of deletion of employee and their dependants, if covered under the policy, pro-rata refund for\
         entire family/or for memeber,as the case may be, to be done subject to nil claims lodged against \
         employee and their dependants as the case may be.',
    'additionPoints': [
        'Data of such addition/deletion has to be  submitted with Bharti AXA General  \
        Insurance Co. Ltd. within 15th day of subsequent month of \"Date of Joining\" \
        and or "Date of Leaving" of the Employee.',
        'New Born Baby and Newly Married Spouse can be added in the policy, with effect\
        from \"Date of Birth\" and \"Date of Marriage\" respectively ,subject to coverage of \
        Child\/Spouse under the policy and sufficient premium being provided to the insurance company.',
        'Any such Intimations should reach the Insurance Co. within 45 days from \"Date of Birth\"\
        of New Born Baby and \"Date of Marriage\" of Newly Married Spouse.',
        'In case of New Joinees, names of all dependants to be submitted one time only \
        and along with the name of the employee.'
    ]
    
}

export default Text