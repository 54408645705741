import React from 'react'
import { render } from 'react-dom'
import styled from 'styled-components'
import down_arrow from '../../../assets/svgs/down_arrow.svg'
import side_arrow from '../../../assets/svgs/side_arrow.svg'
import CreditCardContent from './CreditCardContent.js'
class CreditCollapsibleDiv extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected_idx_credit: -1
     
    }
  }

  
  render() {
    return (
      <ParentDiv>
        <Header
          isOpen={this.props.open}
          onClick={() => {
            this.props.togglePanel(this.props.idx)
            this.props.onSelectCreditBank(this.state.selected_idx_credit)
          }}>
          <HeaderText>{this.props.title}</HeaderText>
          <SideArrow isOpen={this.props.open} src={this.props.open ? down_arrow : side_arrow} />
        </Header>
        {this.props.open ? (
          <CreditCardContent
            onPay={values => this.props.onPay(values)}
            selected_idx_credit={this.props.selected_idx_credit}
            onSelectCreditBank={idx => this.props.onSelectCreditBank(idx)}
            premium={this.props.premium}
            discount={this.props.discount}
            route={this.props.route}
          />
        ) : null}
      </ParentDiv>
    )
  }
}

const ParentDiv = styled.div`
  display:flex;
  flex-direction:column
  //margin: 11px 16px 11px 16px;
  margin-bottom: 24px;
  border-radius: 7px;
  border: solid 1px #436ab2;
  overflow: hidden;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 57px;
  cursor: pointer;
  padding: 18px;
  color: #fff;
  font-family: Avenir;
  justify-content: space-between;
`
const HeaderText = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 1.14px;
  text-align: left;
  color: #4a4a4a;
`
const Content = styled.div`
  padding: 15px;
  font-family: verdana;
  font-size: 14px;
`

const SideArrow = styled.img`
  max-width: 24px;
  max-height: 24px;
`
export default CreditCollapsibleDiv
