import React, { Component } from "react";
import styled from "styled-components";
import arrowBack from "../../assets/images/arrowBack.png";
import { withRouter } from "react-router";

class MarketplaceHeader extends Component {
  onBackButtonClick = () => {
    if (this.props.navigateToContext) {
      this.props.navigateToContext(this.props.backPage);
    } else {
      this.props.history.push(this.props.backPage);
    }
  };

  render() {
    return (
      <Root>
        {this.props.backButton && (
          <BackButton
            onClick={() => this.props.history.push(this.props.backPage)}
          >
            <BackImage src={arrowBack} />
          </BackButton>
        )}
        {this.props.headerText && (
          <HeaderText>{this.props.headerText.toLowerCase()}</HeaderText>
        )}
        {}
      </Root>
    );
  }
}

export default withRouter(MarketplaceHeader);

MarketplaceHeader.defaultProps = {
  closebutton: false,
  headerText: "",
  backButton: true,
};

const Root = styled.div`
  width: 100%;
  height: 5.6rem;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  display: flex;
`;

const BackButton = styled.div`
  margin-left: 1.6rem;
  margin-top: 1.6rem;
  cursor: pointer;
`;

const BackImage = styled.img`
  width: 2.4rem;
  height: 2.4rem;
`;

const HeaderText = styled.p`
  text-transform: capitalize;
  padding: 1.7rem 2rem 1.9rem 1.4rem;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: ${(props) => (props.color ? props.color : "#436ab2")};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
