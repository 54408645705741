import React from 'react'
import { render } from 'react-dom'
import styled from 'styled-components'
import down_arrow from '../../../assets/svgs/down_arrow.svg'
import side_arrow from '../../../assets/svgs/side_arrow.svg'
import NetBankingCardContent from './NetBankingCardContent.js'
class NetBankingCollapsibleDiv extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected_idx_nb: -1,
    }
  }

  render() {
    return (
      <ParentDiv overflow={this.props.overflow && this.props.overflow}>
        <Header
          isOpen={this.props.open}
          onClick={() => {
            this.props.togglePanel(this.props.idx)
            this.props.onSelectNBBank(this.state.selected_idx_nb)
          }}>
          <HeaderText>{this.props.title}</HeaderText>
          {/* <SideArrow isOpen={this.props.open} src={this.props.open ? down_arrow : side_arrow} /> */}
        </Header>
        {this.props.open ? (
          <Content>
            {/* <this.props.div /> */}
            <NetBankingCardContent
              route={this.props.route}
              onPay={values => this.props.onPay(values)}
              selected_idx_nb={this.props.selected_idx_nb}
              onSelectNBBank={idx => this.props.onSelectNBBank(idx)}
              premium={this.props.premium}
              discount={this.props.discount}
              points_applied={this.props.points_applied}
            />
          </Content>
        ) : null}
      </ParentDiv>
    )
  }
}

const ParentDiv = styled.div`
  display:flex;
  flex-direction:column
  //margin: 11px 16px 11px 16px;
  margin-bottom: 24px;
  border-radius: 7px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.15);
  border: solid 1px #436ab2;
  /* overflow: hidden; */
  overflow: ${(props) => (props.overflow ? props.overflow :'hidden')}
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 57px;
  cursor: pointer;
  padding: 18px;
  color: #fff;
  font-family: Avenir;
  justify-content: space-between;
`
const HeaderText = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 1.14px;
  text-align: left;
  color: #4a4a4a;s
`

const Content = styled.div`
  cursor: pointer;
  font-family: verdana;
  font-size: 14px;
`

const SideArrow = styled.img`
  max-width: 24px;
  max-height: 24px;
`
export default NetBankingCollapsibleDiv
