import React from "react";
import styled from "styled-components";
import { Header } from "../../components";
import Table from "../../assets/images/tandc.png";

function referralfees() {
  return (
    <Root>
      <Header backPage="/business/ambassador" />

      <Body>
        <div className="text">Fee Structure</div>
        <ImageDiv>
          <img src={Table} alt="image" />
        </ImageDiv>
      </Body>
    </Root>
  );
}

export default referralfees;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .text {
    font-family: Proxima Nova;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.9px;
    color: #4a4a4a;
    margin-bottom: 48px;
    margin-top: 24px;
  }

  @media (min-width: 1022px) {
    width: 800px;
    height: 88vh;
    box-shadow: 0 0 11px 0 rgba(139, 137, 137, 0.5);
    background-color: #ffffff;
  }
`;

const ImageDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 24px;
  margin-right: 24px;

  & img {
    height: 200px;
    width: 350px;
  }
  @media (min-width: 1022px) {
    & img {
      height: 511px;
      width: 100%;
    }
  }
`;
