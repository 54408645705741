import styled from 'styled-components'

import React from 'react'
import vitalcash from '../../../assets/images/vitalcash.png'

export default class ScrollDivPayment extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <Card>{this.props.div_list}</Card>
  }
}

const Card = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;
  padding-top: 1px;
  padding-bottom: 16px;
  '&::-webkit-scrollbar': {
    display: 'none';
  }
  '&::-webkit-scrollbar-thumb': {
    display: 'none';
  }
  ::-webkit-scrollbar {
    width: 0px;
    /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: none;
  }
`
