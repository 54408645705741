import React from "react";
import { Route, Redirect, Switch, useRouteMatch } from "react-router-dom";
import * as Pages from "./pages";
import { BusinessPrivateRoute } from "../../components/business";
import { inDesktopView } from "../../components/hoc";
import EmployerDesktop from "../../components/hoc/employerDesktop";
import {PolicyDocumentComponent} from '../'

const BusinessRouting = ({ match }) => {
  let { path, url } = useRouteMatch();

  return (
    <React.Fragment>
      <main>
        <Switch>
          <Route
            path={`${path}/enrolment-history`}
            component={EmployerDesktop(Pages.EnrolmentHistory)}
          />
          <Route
            path={`${path}/about`}
            component={inDesktopView(Pages.About)}
          />
          <Route
            path={`${path}/phone`}
            component={Pages.Phone}
          />
          <Route path={`${path}/otp`} component={inDesktopView(Pages.Otp)} />
          <BusinessPrivateRoute
            path={`${path}/payment`}
            component={EmployerDesktop(Pages.Payment)}
          />
          <BusinessPrivateRoute
            path={`${path}/employees`}
            component={inDesktopView(Pages.Employees)}
          />
          <BusinessPrivateRoute
            path={`${path}/plans`}
            component={inDesktopView(Pages.Plans)}
          />
          <BusinessPrivateRoute
            path={`${path}/enrollmentprocess`}
            component={inDesktopView(Pages.EnrollmentProcess)}
          />
          <BusinessPrivateRoute
            path={`${path}/memberDetails`}
            component={inDesktopView(Pages.BusinessMemberDetails)}
          />
          <BusinessPrivateRoute
            path={`${path}/makepayment`}
            component={inDesktopView(Pages.MakePayment)}
          />
          <BusinessPrivateRoute
            path={`${path}/dashboard`}
            component={EmployerDesktop(Pages.Dashboard)}
          />
          <BusinessPrivateRoute
            path={`${path}/employee-registration-dashboard`}
            component={EmployerDesktop(Pages.EmployeeRegistrationDashboard)}
          />
           <BusinessPrivateRoute
            path={`${path}/paymentsummary`}
            component={EmployerDesktop(Pages.PaymentSummary)}
          />
            <BusinessPrivateRoute
            path={`${path}/paysuccess`}
            component={EmployerDesktop(Pages.PaymentSuccessful)}
          />
          <BusinessPrivateRoute
            path={`${path}/bankslist`}
            component={EmployerDesktop(Pages.BanksList)}
          />
            
          <BusinessPrivateRoute
            path={`${path}/netbankingdetails`}
            component={EmployerDesktop(Pages.NetBankingDetails)}
          />
          <BusinessPrivateRoute
            path={`${path}/paymentsuccess`}
            component={EmployerDesktop(Pages.PaymentSuccessful)}
          />
          <BusinessPrivateRoute
            path={`${path}/profile`}
            component={inDesktopView(Pages.Profile)}
          />
          <BusinessPrivateRoute
            path={`${path}/receipt`}
            component={inDesktopView(Pages.Receipt)}
          />
          <BusinessPrivateRoute
            path={`${path}/marketplace`}
            component={inDesktopView(Pages.Marketplace)}
          />
          <BusinessPrivateRoute
            path={`${path}/marketplaceoffers`}
            component={inDesktopView(Pages.MarketPlaceOffers)}
          />
          <BusinessPrivateRoute
            path={`${path}/policydoc/:id`}
            component={inDesktopView(Pages.PolicyDocument)}
          />
          <BusinessPrivateRoute
            path={`${path}/memberstype`}
            component={inDesktopView(Pages.MembersType)}
          />
          <BusinessPrivateRoute
            path={`${path}/selectplantype`}
            component={inDesktopView(Pages.SelectPlanType)}
          />
          <BusinessPrivateRoute
            path={`${path}/selectpolicytype`}
            component={inDesktopView(Pages.SelectPolicyType)}
          />
          <BusinessPrivateRoute
            path={`${path}/insureplansummary`}
            component={inDesktopView(Pages.InsurePlanSummary)}
          />
          <BusinessPrivateRoute
            path={`${path}/optionstoaddemployees`}
            component={inDesktopView(Pages.OptionForEmployeeDetails)}
          />
          <BusinessPrivateRoute
            path={`${path}/uploadexcel`}
            component={EmployerDesktop(Pages.UploadExcel)}
          />
          <BusinessPrivateRoute
            path={`${path}/cdaccount`}
            component={EmployerDesktop(Pages.CdAccount)}
          />
          <BusinessPrivateRoute
            path={`${path}/view_cd_balance`}
            component={EmployerDesktop(Pages.ViewCdBalance)}
          />
          <BusinessPrivateRoute
            path={`${path}/view_cd_table`}
            component={EmployerDesktop(Pages.ViewCdTable)}
          />
          <BusinessPrivateRoute
            path={`${path}/addDeleteEmployee`}
            component={EmployerDesktop(Pages.AddDeleteEmployee)}
          />
          <BusinessPrivateRoute
            path={`${path}/employeeform`}
            component={Pages.EmployeeDetailForm}
          />
          <BusinessPrivateRoute
            path={`${path}/prePurchasePlanRecommendation`}
            component={EmployerDesktop(Pages.PrePurchasePlanRecommendation)}
          />
          <BusinessPrivateRoute
            path={`${path}/thankYou`}
            component={EmployerDesktop(Pages.ThankYou)}
          />
          <BusinessPrivateRoute
            path={`${path}/gmcpaymentsummary`}
            component={EmployerDesktop(Pages.GmcPaymentSummary)}
          />
          <BusinessPrivateRoute
            path={`${path}/gst`}
            component={inDesktopView(Pages.GSTno)}
          />
          <BusinessPrivateRoute
            path={`${path}/uploadpan`}
            component={inDesktopView(Pages.UploadPanDetails)}
          />
          <BusinessPrivateRoute
            path={`${path}/employeraddress`}
            component={inDesktopView(Pages.EmployerAddress)}
          />
          <BusinessPrivateRoute
            path={`${path}/renewal`}
            component={inDesktopView(Pages.Renewal)}
          />
          <BusinessPrivateRoute
            path={`${path}/tnc`}
            component={inDesktopView(Pages.BhartiTnC)}
          />
          <BusinessPrivateRoute
            path={`${path}/claimprocess/:id`}
            component={inDesktopView(Pages.ClaimProcess)}
          />
          <BusinessPrivateRoute
            path={`${path}/subscriptionpayment`}
            component={EmployerDesktop(Pages.SubsPayment)}
          />
          <BusinessPrivateRoute
            path={`${path}/offlinequotes`}
            component={inDesktopView(Pages.OfflineQuotes)}
          />
           <BusinessPrivateRoute
            path={`${path}/selfpaymentoptions`}
            component={inDesktopView(Pages.SelfpaymentOptions)}
          />
           <BusinessPrivateRoute
            path={`${path}/autopaymentoptions`}
            component={inDesktopView(Pages.AutopaymentOptions)}
          />
           
            <BusinessPrivateRoute
            path={`${path}/subscriptionselfpay`}
            component={inDesktopView(Pages.SubscriptionselfPay)}
          />
          <BusinessPrivateRoute
            path={`${path}/subscriptionautoPay`}
            component={inDesktopView(Pages.SubscriptionautoPay)}
          />
         <BusinessPrivateRoute
            path={`${path}/autobankslist`}
            component={inDesktopView(Pages.AutoBanksList)}
          />

          <Route path={`${path}/referral`}>
            <Redirect
              to={{
                pathname: '/business/ambassador'
              }}
            />
          </Route>
          <Route path={`${path}/ambassador`} component={Pages.Referral} />
          <Route path={`${path}/carepolicyDoc`} component={PolicyDocumentComponent} />

          <Route
            path={`${path}/tnc`}
            component={Pages.ReferralTermsAndConditions}
          />
          <Route
            path={`${path}/shareonwhatsapp`}
            component={Pages.ShareOnWhatsapp}
          />
          <Route
            path={`${path}/autopaymentoptions`}
            component={Pages.AutopaymentOptions}
          />
           <Route
            path={`${path}/e-card-preview/download`}
            component={Pages.ECardDownload}
          />
          <Route
            path={`${path}/e-card-preview`}
            component={EmployerDesktop(Pages.ECardPreview)}
          />
          <Route path={`${path}/referralfees`} component={Pages.ReferralFees} />
          <Route path={path} component={inDesktopView(Pages.HomePage)} />
         
        </Switch>
      </main>
    </React.Fragment>
  );
};

export default BusinessRouting;
