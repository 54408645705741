import React from "react";
import styled from "styled-components";
import vitalMobile1 from "../assets/svgs/vitalMobile1.svg";
import vitalMobile2 from "../assets/svgs/vitalMobile2.svg";
import { withRouter } from "react-router";
import { initGA, PageView, Event } from "../Tracking";
const Navigation = styled.div`
  width: 100%;
  height: 6rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 20;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2.4rem;

  @media (min-width: 1024px) {
    height: 8rem;
    padding: 0 4.8rem;
  }

  @media (min-width: 1680px) {
    height: 10.5rem;
    padding: 0 4.8rem;
  }
`;
const Logodesign = styled.div`
  height: 2.44rem;
  width: 7.52rem ;
  background-image:url(${vitalMobile1});
  background-repeat: no-repeat ;

  @media (min-width: 1024px) {
  }

  @media (min-width: 1680px ) {
    width:14.8rem;
    height:4.8rem
    background-image:url(${vitalMobile2});
  }
`;

const Loginbutton = styled.button`
  // display: flex;
  // align-items: center;
  // justify-content: center;
  height: 3.2rem;
  width: 8rem;
  cursor: pointer;
  background-color: #ffffff;
  font-family: Avenir;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 1.2px;
  border-radius: 5px;
  margin-left: 12px;
  // border: none;
  // margin-right: 18px;
  border: solid 1px #436ab2;
  color: #436ab2;
  // text-transform: uppercase;
  // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  // -moz-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  // -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  @media (min-width: 1024px) {
    width: 14rem;
    height: 4.8rem;
    text-align: center;
    font-size: 2rem;
    line-height: 1.35;
    letter-spacing: 2px;
    text-align: center;
  }
`;
const Font12 = styled.div`
  display: flex;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: #436ab2;
  margin-left: 60px;
  align-items: center;
  cursor: pointer;

  @media (min-width: 1024px) {
    /* width: 14rem; */
    /* height: 4.8rem; */
    text-align: center;
    font-size: 2rem;
    line-height: 1.35;
    letter-spacing: 2px;
    text-align: center;
    margin-right: 12px;
  }
`;
const MainDiv = styled.div`
  display: flex;
`;

function NavBar(props) {
  const e_category = props.location.pathname;
  const redirectToHomePage = () => {
    window.scrollTo(0, 0);
    props.history.push("/");
  };

  const redirectToLogin = () => {
    window.scrollTo(0, 0);
    Event(e_category, "Login Button", "Login Button Clicked");

    localStorage.setItem("navigatingFrom", "login");
    props.history.push("/login");
  };
  const redirectToSignUp = () => {
    props.history.push("/business");
  };
  return (
    <Navigation>
      <Logodesign onClick={() => redirectToHomePage()} />
      <MainDiv>
        <Font12 id='getvital_home_for_business' onClick={() => redirectToSignUp()}>For Business</Font12>
        <Loginbutton id='getvital_home_login' onClick={() => redirectToLogin()}> Login</Loginbutton>
      </MainDiv>
    </Navigation>
  );
}

export default withRouter(NavBar);
