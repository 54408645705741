import React, { Component } from 'react'
import styled from 'styled-components'
import phonecoverpage4 from '../assets/images/phonecoverpage4.png'
import Screenshotpage4 from '../assets/images/Screenshotpage4.png'
import Groupdotspage4 from '../assets/images/Groupdotspage4.png'
// import vitalChat1 from '../assets/svgs/vitalChat1.svg'
import chatBox1 from '../assets/svgs/chatBox1.svg'
import chatBox2 from '../assets/svgs/chatBox2.svg'
import ImageE from '../assets/images/imageE.png'
import vitalChat_2 from '../assets/images/vitalChat_2.png'
// import vitalChat2 from '../assets/svgs/vitalChat2.svg'
// import vitalChat_2 from '../assets/svgs/vitalChat_2.svg'
import thumbpage4 from '../assets/images/thumbpage4.png'
import DMIGroup from '../assets/images/DMIGroup.png'
import Phonecover1024page4 from '../assets/images/assets1024/Phonecover1024page4.png'
import Group from '../assets/images/Group.png'
import Groupdotspage5 from '../assets/images/Groupdotspage5.png'
import screenshotpage2 from '../assets/images/assets1024/screenshotpage2.png'
import thumbpage2 from '../assets/images/thumbpage2.png'

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background-image: linear-gradient(#fafafa, #eaeaea);
`
const Header = styled.div`
  // top: 3.5rem;
  width: 100%;
  // padding: 3.6rem 0 19rem 2.4rem;
  padding: 3.6rem 0 3.6rem 0;
  position: relative;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    padding: 17rem 0 14rem 0;
  }

  @media (min-width: 1680px) {
    padding: 29rem 0 29rem 0;
  }
`
const Subheader = styled.div`
  width: 32rem;
  padding-left: 2.4rem;
  margin-bottom: 3.65rem;
  @media (min-width: 1024px) {
    width: 45rem;
    padding-left: 0;
    margin-left: 140px;
    margin-bottom: 0;
  }
  @media (min-width: 1680px) {
    // margin-left: 6.6rem;
    margin-left: 20.8rem;
    width: 55rem;
  }
`
const H2 = styled.h2`
  font-family: Proxima Nova;
  font-size: 2.4rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: 0.01px;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    font-size: 4.4rem;
    line-height: 1.3;
    letter-spacing: 0.02px;
  }

  @media (min-width: 1680px) {
    font-size: 6rem;
    line-height: 1.33;
    letter-spacing: 0.03px;
  }
`

const H3 = styled.h3`
  margin-top: 1.2rem;
  // width: 29rem;
  font-family: Avenir;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: 0.02px;
  text-align: left;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    // width: 45rem;
    margin-top: 2.4rem;
    font-size: 1.8rem;
    line-height: 1.56;
    letter-spacing: 0px;
  }

  @media (min-width: 1680px) {
    margin-top: 4.5rem;
    // width: 69.3rem;
    font-size: 3.4rem;
    line-height: 1.59;
    letter-spacing: 0.02px;
  }
`

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  display: -webkit-flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: center;
  position: absolute;
  top: 10rem;
  height: 16.5rem;

  @media (min-width: 1024px) {
  }
`

const Imagephone = styled.div`
  width: 30rem;
  // width: 37rem;
  height: 26.79rem;
  margin: 0 auto;
  background-image: url("${props =>
    props.error ? props.errorSrc : props.loading ? props.loadingSrc : props.actualSrc}");
}
  background-size: cover;
  background-repeat: no-repeat;
  // position: relative;
  // top: ;

  @media (min-width: 1024px) {
    margin: 0;
  }

  @media (min-width: 1680px) {
    // width: 89rem;
    width: 50rem;
    height: 44.66rem;
    margin: 0;
    background-image: url("${props =>
      props.error ? props.errorSrc : props.loading ? props.loadingSrc : props.actualSrc}");
    background-size: cover;
  }
`

const Divmessage = styled.div`
  // left: 2.4rem;
  // top: 5rem;
  height: 16.2rem;
  width: 30.2rem;
  background-color: #d9e1ec;
  border-radius: 1rem;
  // position: absolute;
  position: relative;

  @media (min-width: 1024px) {
    width: 37.7rem;
    height: 19.21rem;
    position: relative;
    // left: -20rem;
    // top: ;
  }
`
const Divmessage1 = styled.div`
border-radius: 1rem ;
height: 2.8rem ;
width: 19.4rem ;
margin-top: 1.4rem ;
margin-left: 9.2rem ;
color : #4A4A4A ;
background-color: #FFFFFF ;
position: absolute ;
font-size: 1.2rem ;
font-family: Avenir ;
text-align: center ;
padding-top: 4.5px ;
padding-left: 7px ;

@media (min-width: 1024px) {
    font-size: 4.4rem ;
    width: 25.98rem ;
    height:3.82rem
    font-size: 1.6rem ;
    
      
        } 


`
const Divmessage2 = styled.div`
border-radius: 1rem ;
height: 2.8rem ;
width: 19.4rem ;
margin-top: 5.3rem ;
margin-left: 1.3rem ;
color : #4A4A4A ;
background-color: #FFFFFF ;
position: absolute ;
font-size: 1.2rem ;
font-family: Avenir ;
text-align: center ;
padding-top: 4.5px ;
padding-left: 7px ;

@media (min-width: 1024px) {
    font-size: 4.4rem ;
    width: 25.98rem ;
    height:3.82rem
    font-size: 1.6rem ;
    margin-top: 7.28rem ;
    margin-left: 1.54rem ;
    
      
        } 
`
const Divmessage3 = styled.div`
border-radius: 1rem ;
height: 2.8rem ;
width: 27.7rem ;
margin-top: 12.4rem ;
margin-left: 1.3rem ;
background-color: #FFFFFF ;
position: absolute ;

@media (min-width: 1024px) {
    width: 34.6rem ;
    height:3.32rem
    margin-top: 14.7rem ;
    margin-left: 1.54rem ;
    
      
        } 

`

const Message3content = styled.p`
  font-size: 1.2rem;
  font-family: Avenir;
  color: #4a4a4a;
  opacity: 0.3;
  padding-top: 4.5px;
  padding-left: 7px;

  @media (minimum-width: 1024px) {
    font-size: 1.6rem;
  }
`

const GroupImage = styled.div`
  width: 5.15rem;
  height: 4.8rem;
  background-image: url(${Group});
  background-repeat: no-repeat;
  position: relative;
  top: 14.4rem;
  left: 28rem;

  @media (min-width: 1024px) {
    top: 17rem;
    left: 36rem;
  }
`

const Imagedots = styled.div`
  background-image: url(${Groupdotspage5});
  background-repeat: no-repeat;
  height: 4.36rem;
  width: 4.28rem;
  position: absolute;
  bottom: 36rem;
  right: 1rem;

  @media (min-width: 1024px) {
    background-image: url(${Groupdotspage5});
    height: 7.89rem;
    width: 7.89rem;
    right: 3.3rem;
    bottom: 30rem;
  }
`

const Section2 = styled.div`
  width: 100%;
  height: 7rem;
  display: flex;
  display: -webkit-flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  position: absolute;
  top: 35rem;
`

const Imagethumb = styled.div`
  width: 4.32rem;
  height: 6.97rem;
  background-image: url(${thumbpage4});
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  position: absolute;
  left: 0;
  top: 17rem;
  display: none;

  @media (min-width: 1024px) {
    height: 10rem;
    width: 9.81rem;
    background-image: url(${thumbpage2});
  }
`

const ChatBoxImg = styled.div`
  width: 17rem;
  height: 7.3rem;
  background-image: url(${chatBox1});
  background-repeat: no-repeat;
  position: absolute;
  left: 18rem;
  top: 20rem;
  z-index: 10;

  @media (min-width: 1024px) {
    height: 7.5rem;
    width: 17rem;
    left: 25rem;
    top: 19rem;
    background-image: url(${chatBox1});
  }

  @media (min-width: 1680px) {
    height: 20.5rem;
    width: 48.1rem;
    background-image: url(${chatBox2});
    top: 22rem;
    left: 45rem;
  }
`
const ImageDv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Image = styled.img`
  display: flex;
  // width: 327px;
  height: 514px;
  
  @media (min-width: 1680px) {
    // width: 400px;
    height: 700px;
  }
`
const Font16 = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  text-align: center;
  color: #4a4a4a;
`

const ChatMsg = styled.p`
  font-family: Avenir;
  font-size: 1.4rem;
  font-weight: 600;
  white-space: nowrap;
  letter-spacing: 0.5px;
  color: #4a4a4a;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -85%);

  @media (min-width: 1680px) {
    font-size: 3.4rem;
  }
`
const LazyImage = ({ loadingSrc, actualSrc, errorSrc, ...props }) => {
  const [isImageLoaded, setImageLoaded] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)
  React.useEffect(
    () => {
      const img = new Image()

      img.onload = () => setImageLoaded(true)
      img.onerror = () => setHasError(true)

      img.src = actualSrc
    },
    [actualSrc]
  )

  return (
    <Imagephone
      loadingSrc={loadingSrc}
      actualSrc={actualSrc}
      errorSrc={actualSrc}
      loading={!isImageLoaded}
      error={hasError}
      {...props}
    />
  )
}

export default class Page5 extends Component {
  render = () => {
    return (
      <Wrapper id={this.props.id}>
        <Header>
          <Subheader>
            <H2>Instant everything</H2>
            <H3>
              Faster, simpler, and easier! Redeem offers, buy insurance, 
              file claim, and make EMI payments with an<br />
              easy-to-use mobile website.
            </H3>
          </Subheader>
          {/* <ChatBoxImg><ChatMsg>Hi, how can we help?</ChatMsg></ChatBoxImg>
          <LazyImage
                       loadingSrc={vitalChat_2}
                       actualSrc="http://images.getvital.in/homelanding/imageE.png/images/chat-50%25_176.9kb+(1).png"
                       errorSrc={vitalChat_2}
          /> */}
          {/* <Font16>
              30 Seonds
          </Font16> */}
          <ImageDv>
            {/* <Image src={ImageE}/> */}
            <Image alt="low cost health insurance" src= "http://images.getvital.in/homelanding/imageE.png" />
          </ImageDv>
          {/* <Divmessage>
            <Divmessage1>Hi there, how can we help you?</Divmessage1>
            <Divmessage2>How do I claim my insurance?</Divmessage2>
            <Divmessage3>
              <Message3content>Send a message...</Message3content>
            </Divmessage3>
            <GroupImage />
          </Divmessage> */}
        </Header>

        {/* <Section>
          <Divmessage>
            <Divmessage1>Hi there, how can we help you?</Divmessage1>
            <Divmessage2>How do I claim my insurance?</Divmessage2>
            <Divmessage3>
              <Message3content>Send a message...</Message3content>
            </Divmessage3>
            <GroupImage />
          </Divmessage>
        </Section> */}

        <Section2>
          <Imagethumb />
          <Imagedots />
        </Section2>
      </Wrapper>
    )
  }
}
