import Loadable from "react-loadable";
import { withCallButton } from "../components/hoc";
import { OverlayLoader, LoaderDots,  } from "../components";
import { inDesktopView } from "../components/hoc";
import Business from './business'



export const MarketPlacePointsComponent = inDesktopView( Loadable({
  loader: () => import("./MarketPlacePoints"),
  loading: OverlayLoader,
}));

export const MarketPlaceContactInfo = inDesktopView( Loadable({
  loader: () => import("./memberApp/marketplaceContactInfo"),
  loading: OverlayLoader,
}));

export const MarketPlacePayment = inDesktopView( Loadable({
  loader: () => import("./MarketPlacePayment"),
  loading: OverlayLoader,
}));

export const MyOrdersComponent = inDesktopView( Loadable({
  loader: () => import("./MyOrders"),
  loading: OverlayLoader,
}));

export const ScheduledTest = inDesktopView( Loadable({
  loader: () => import("./ScheduledTests"),
  loading: OverlayLoader,
}))
export const MarketPlaceCodeComponent = inDesktopView( Loadable({
  loader: () => import("./MarketPlaceDiscount"),
  loading: OverlayLoader,
}));
// export const MarketPlacePointsComponent = inDesktopView( Loadable ({
//   loader :() => import('./MarketPlacePoints'),
//   loading:OverlayLoader
// }))

export const MarketPlaceOffersComponent = inDesktopView( Loadable({
  loader: () => import("./MarketPlaceOffers"),
  loading: OverlayLoader,
}));

export const HealthCentreSelectionComponent = inDesktopView( Loadable({
  loader: () => import("./HealthCentreSelection"),
  loading: OverlayLoader,
}));

export const MyOffersComponent = inDesktopView( Loadable({
  loader: () => import("./MyOffers"),
  loading: OverlayLoader,
}));

export const SubscriptionPaymentComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/subscriptionPayment"),
  loading: OverlayLoader,
}));

export const CashlessClaimComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/cashlessClaimSteps"),
  loading: OverlayLoader,
}));

export const SignUpComponent = inDesktopView( Loadable({
  loader: () => import("./signUp"),
  loading: OverlayLoader,
}));

export const AboutYouComponent = inDesktopView( Loadable({
  loader: () => import("./aboutYou"),
  loading: OverlayLoader,
}));
export const OnBoardingDisclaimerComponent = inDesktopView( Loadable({
  loader: () => import("./onBoardingDisclaimer"),
  loading: OverlayLoader,
}));
export const LoginComponent = inDesktopView( Loadable({
loader: () => import("./Login/index.jsx"),
  loading: OverlayLoader,
}));

export const LoginNumber = inDesktopView( Loadable({
  loader: () => import("./Login/loginNumber.jsx"),
    loading: OverlayLoader,
  }));

export const LoginMemberId = inDesktopView( Loadable({
  loader: () => import("./Login/memberId.jsx"),
    loading: OverlayLoader,
  }));

//export {default as OtpComponent} from './otp';
export const OtpComponent = inDesktopView( Loadable({
  loader: () => import("./otp"),
  loading: OverlayLoader,
}));

export const PasswordComponent = inDesktopView( Loadable({
  loader: () => import("./Password/index.jsx"),
  loading: OverlayLoader,
}));

export const FamilyComponent = inDesktopView( Loadable({
  loader: () => import("./family"),
  loading: OverlayLoader,
}));

export const AddressComponent = inDesktopView( Loadable({
  loader: () => import("./address"),
  loading: OverlayLoader,
}));

export const InsureAddressComponent = inDesktopView( Loadable({
  loader: () => import("./insureAddress"),
  loading: OverlayLoader,
}));

export const ShippableComponent = inDesktopView( Loadable({
  loader: () => import("./shippableAddress"),
  loading: OverlayLoader,
}));

export const SelfieComponent = inDesktopView( Loadable({
  loader: () => import("./selfie"),
  loading: OverlayLoader,
}));

export const Kyc1Component = inDesktopView( Loadable({
  loader: () => import("./kyc1"),
  loading: OverlayLoader,
}));

export const Kyc2Component = inDesktopView( Loadable({
  loader: () => import("./kyc2"),
  loading: OverlayLoader,
}));

export const EnrolmentHistory = inDesktopView( Loadable({
  loader: () => import("./EnrolmentHistory"),
  loading: OverlayLoader,
}));

export const NachGenComponent = inDesktopView( Loadable({
  loader: () => import("./nachGen"),
  loading: OverlayLoader,
}));

export const PincodeComponent = inDesktopView( Loadable({
  loader: () => import("./pincode"),
  loading: OverlayLoader,
}));

export const SalaryMonthlyComponent = inDesktopView( Loadable({
  loader: () => import("./salaryMonthly"),
  loading: OverlayLoader,
}));

export const AnnualExpenseComponent = inDesktopView( Loadable({
  loader: () => import("./annualExpense"),
  loading: OverlayLoader,
}));

export const isHealthInsuranceComponent = inDesktopView( Loadable({
  loader: () => import("./isHealthInsurance"),
  loading: OverlayLoader,
}));

export const PanNoComponent = inDesktopView( Loadable({
  loader: () => import("./panNo"),
  loading: OverlayLoader,
}));

export const HomeTypeComponent = inDesktopView( Loadable({
  loader: () => import("./homeType"),
  loading: OverlayLoader,
}));

export const IsSalariedComponent = inDesktopView( Loadable({
  loader: () => import("./isSalaried"),
  loading: OverlayLoader,
}));

export const WorkingCompanyComponent = inDesktopView( Loadable({
  loader: () => import("./workingCompany"),
  loading: OverlayLoader,
}));

export const WorkExperienceComponent = inDesktopView( Loadable({
  loader: () => import("./workExperience"),
  loading: OverlayLoader,
}));

export const SecureMemberComponent = inDesktopView( Loadable({
  loader: () => import("./secureMember"),
  loading: OverlayLoader,
}));

export const FamilyMembersComponent = inDesktopView( Loadable({
  loader: () => import("./family_member"),
  loading: OverlayLoader,
}));

export const FamilyCorporateComponent = inDesktopView( Loadable({
  loader: () => import("./familyCorporate"),
  loading: OverlayLoader,
}));

export const InsurePlanComponent = withCallButton(
  inDesktopView( Loadable({
    loader: () => import("./insurePlan"),
    loading: OverlayLoader,
  }))
);

export const OptionalBenefitsComponent = withCallButton(
  inDesktopView( Loadable({
    loader: () => import("./optionalBenefits"),
    loading: OverlayLoader,
  }))
);

export const InsuranceSubscriptionsComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/insuranceSubscriptions"),
  loading: OverlayLoader,
}));

export const DashBoardComponent = inDesktopView( Loadable({
  loader: () => import("./dashBoard"),
  loading: OverlayLoader,
}));

export const SupportTab = inDesktopView( Loadable({
  loader: () => import("./memberApp/SupportTab"),
  loading: OverlayLoader,
}));

export const FlexPreIssuanceDashboard = inDesktopView( Loadable({
  loader: () => import("./memberApp/FlexPreIssuance"),
  loading: OverlayLoader,
}));

export const FlexOnboarding = inDesktopView( Loadable({
  loader: () => import("./memberApp/FlexPreIssuance/FlexOnboarding"),
  loading: OverlayLoader,
}));

export const FlexDependentsOverview = inDesktopView( Loadable({
loader: () => import("./memberApp/FlexPreIssuance/FlexDependentsOverview"),
  loading: OverlayLoader,
}));

export const FlexDependentsEdit = inDesktopView( Loadable({
loader: () => import("./memberApp/FlexPreIssuance/FlexDependentEdit"),
  loading: OverlayLoader,
}));

export const FlexPaymentSummary = inDesktopView( Loadable({
  loader: () => import("./memberApp/FlexPreIssuance/PaymentSummary"),
  loading: OverlayLoader,
}));

export const FlexPaymentCompletion = inDesktopView( Loadable({
  loader: () => import("./memberApp/FlexPreIssuance/CompletionInfo"),
  loading: OverlayLoader,
}));

export const BenefitComponent = inDesktopView( Loadable({
  loader: () => import("./benefits"),
  loading: OverlayLoader,
}));

export const BenefitsOfferList = inDesktopView( Loadable({
  loader: () => import("./offersList"),
  loading: OverlayLoader,
}));

export const EAgreementComponent = inDesktopView( Loadable({
  loader: () => import("./eAgreement"),
  loading: OverlayLoader,
}));

export const EMandateComponent = inDesktopView( Loadable({
  loader: () => import("./eMandate"),
  loading: OverlayLoader,
}));
export const PersonalAddressComponent = inDesktopView( Loadable({
  loader: () => import("./personalAddress"),
  loading: OverlayLoader,
}));

export const QualificationComponent = inDesktopView( Loadable({
  loader: () => import("./qualification"),
  loading: OverlayLoader,
}));

export const PaymentModeComponent = inDesktopView( Loadable({
  loader: () => import("./paymentMode"),
  loading: OverlayLoader,
}));

export const PaymentOptionsComponent = inDesktopView( Loadable({
  loader: () => import("./paymentOptions"),
  loading: OverlayLoader,
}));

export const PaymentCycleComponent = inDesktopView( Loadable({
  loader: () => import("./paymentCycle"),
  loading: OverlayLoader,
}));


export const SelfPaymentComponent = inDesktopView( Loadable({
  loader: () => import("./selfPayment"),
  loading: OverlayLoader,
}));

export const VitalPointsDashboardComponent = inDesktopView( Loadable({
  loader: () => import("./vitalPointsDashboard"),
  loading: OverlayLoader,
}));

export const VoucherComponent = inDesktopView( Loadable({
  loader: () => import("./voucher"),
  loading: OverlayLoader,
}));

export const HospitalsPageComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/hospitalsPage"),
  loading: OverlayLoader,
}));

export const TreatmentTypePageComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/treatmentTypePage"),
  loading: OverlayLoader,
}));

export const WhoIsItForPageComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/whoIsItForPage"),
  loading: OverlayLoader,
}));

export const FamilyMemberContactInfoComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/contactInfo"),
  loading: OverlayLoader,
}))

export const DateOfAdmissionPageComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/dateOfAdmissionPage"),
  loading: OverlayLoader,
}));

export const HospitalOfferPageComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/hospitalOffer"),
  loading: OverlayLoader,
}));

export const MakePaymentComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/makePayment"),
  loading: OverlayLoader,
}));

export const MakePaymentComponentEmi = inDesktopView( Loadable({
  loader: () => import("./memberApp/makePaymentEmi"),
  loading: OverlayLoader,
}));

export const InitialDiagnosisPageComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/initialDiagnosisPage"),
  loading: OverlayLoader,
}));

export const ClaimDetailsPageComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/claimDetailsPage"),
  loading: OverlayLoader,
}));

export const PaymentDetailsComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/paymentDetailsPage"),
  loading: OverlayLoader,
}));

export const PaymentGatewayComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/paymentGatewayForHospitalisationPage"),
  loading: OverlayLoader,
}));

export const ApplyVitalCreditComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/applyVitalCreditPage"),
  loading: OverlayLoader,
}));

export const ViewReceiptComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/viewReceipt"),
  loading: OverlayLoader,
}));

export const Mg1Component = inDesktopView( Loadable({
  loader: () => import("./mg1"),
  loading: OverlayLoader,
}));

export const UploadBillPageComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/uploadBillPage"),
  loading: OverlayLoader,
}));

export const DrawDownRequestComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/drawDownRequest"),
  loading: OverlayLoader,
}));

export const NotFoundComponent = inDesktopView( Loadable({
  loader: () => import("./notFound"),
  loading: OverlayLoader,
}));
export const AuthorizingPaymentComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/authorizingPaymentPage"),
  loading: OverlayLoader,
}));

export const UploadSuccessfulComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/UploadSuccessfulPage"),
  loading: OverlayLoader,
}));

export const PaymentTabPageComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/paymentTabPage"),
  loading: OverlayLoader,
}));

export const EventHistoryComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/eventsHistoryPage"),
  loading: OverlayLoader,
}));

export const HealthProfileComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/healthProfilePage"),
  loading: OverlayLoader,
}));

export const ProfileComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/oldProfilePage"),
  loading: OverlayLoader,
}));

export const PharmacyOffersComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/pharmacyOffersPage"),
  loading: OverlayLoader,
}));

export const HowItWorksComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/howItWorksPage"),
  loading: OverlayLoader,
}));

export const PolicyDetailsComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/policyDetailsPage"),
  loading: OverlayLoader,
}));

export const PolicyDetailsDescriptionComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/policyDetailsDescriptionPage"),
  loading: OverlayLoader,
}));

export const CashlessClaimProcessComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/cashlessClaimProcessPage"),
  loading: OverlayLoader,
}));

export const GhiClaimProcess = inDesktopView( Loadable({
  loader: () => import("./memberApp/ghiClaimProcess"),
  loading: OverlayLoader,
}));
export const GpaClaimProcess = inDesktopView( Loadable({
  loader: () => import("./memberApp/gpaClaimProcess"),
  loading: OverlayLoader,
}));

export const ModerationProcessComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/moderationPageForApplyVitalCredit"),
  loading: OverlayLoader,
}));

export const HomeLandingComponent = inDesktopView( Loadable({
  loader: () => import("./homeLanding"),
  loading: OverlayLoader,
}));

export const ClaimReimbursementProcessComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/claimReimbursementProcessPage"),
  loading: OverlayLoader,
}));

export const OutOfPocketUsageComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/outOfPocketUsagePage"),
  loading: OverlayLoader,
}));

export const EditPincodeComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/editPincodePage"),
  loading: OverlayLoader,
}));

export const EditFamilyMemberComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/editFamilyMembersPage"),
  loading: OverlayLoader,
}));

export const EditFamilyDetailsComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/editFamilyDetailsPage"),
  loading: OverlayLoader,
}));

export const EditAnnualExpenseComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/editAnnualExpensePage"),
  loading: OverlayLoader,
}));

export const EditMonthlySalaryComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/editMonthlyIncomePage"),
  loading: OverlayLoader,
}));

export const SubscriptionsComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/insuranceSubscriptions"),
  loading: OverlayLoader,
}));

export const PolicyDocumentComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/policyDocumentPage"),
  loading: OverlayLoader,
}));

export const FalconPolicyDocumentComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/falconPolicyDoc"),
  loading: OverlayLoader,
}));

export const CardiacPolicyDocumentComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/cardiacDocument"),
  loading: OverlayLoader,
}));

export const DiabeticDocComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/diabeticPolicyDoc"),
  loading: OverlayLoader,
}));

export const PEDDocComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/pedPolicyDoc"),
  loading: OverlayLoader,
}));

export const InsuranceDeclarationComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/insuranceDeclarationPage"),
  loading: OverlayLoader,
}));

export const VitalPointsLandingPageCompoent = inDesktopView( Loadable({
  loader: () => import("./vitalPointsLandingPage"),
  loading: OverlayLoader,
}));

export const InsuranceLandingPageComponent = inDesktopView( Loadable({
  loader: () => import("./insuranceLandingPage"),
  loading: OverlayLoader,
}));

export const CreditLandingPageComponent = inDesktopView( Loadable({
  loader: () => import("./creditLandingPage"),
  loading: OverlayLoader,
}));

export const AboutUsLandingPageComponent = inDesktopView( Loadable({
  loader: () => import("./aboutUsLandingPage"),
  loading: OverlayLoader,
}));

export const ShareReferralComponent = inDesktopView( Loadable({
  loader: () => import("./shareReferral"),
  loading: OverlayLoader,
}));

export const ReferralTermsAndConditionsComponent = inDesktopView( Loadable({
  loader: () => import("./business/referralTermsAndConditions"),
  loading: OverlayLoader,
}));

export const FaqComponent = inDesktopView( Loadable({
  loader: () => import("../components/memberApp/faq/Faq"),
  loading: OverlayLoader,
}));

export const PaymentSuccessfulComponent = inDesktopView( Loadable({
  loader: () => import("./PaymentSuccessful"),
  loading: OverlayLoader,
}));

export const Tab2Component =inDesktopView( Loadable({
    loader:()=> import("./Tab2"),
    loading:OverlayLoader
}))
export const MarketPlacePaymentSuccessfulComponent = inDesktopView( Loadable({
  loader: () => import("./MarketPlacePaymentSuccessful"),
  loading: OverlayLoader,
}));

export const PaymentSuccessComponent = inDesktopView( Loadable({
  loader: () => import("./paymentSuccess"),
  loading: OverlayLoader,
}));

export const AccidentalPlansComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/accidentalPlans"),
  loading: OverlayLoader,
}));

export const AccidentalPolicyDocumentComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/accidentalPolicyDoc"),
  loading: OverlayLoader,
}));

export const WhoIsItForPolicyPageComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/whoIsItForPolicyPage"),
  loading: OverlayLoader,
}));

export const EventTypePolicyPageComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/eventTypePolicyPage"),
  loading: OverlayLoader,
}));

export const PaymentDetailsPolicyComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/paymentDetailsPolicyPage"),
  loading: OverlayLoader,
}));

export const DateOfAdmissionPolicyComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/dateOfAdmissionPolicy"),
  loading: OverlayLoader,
}));

export const UploadBillPolicyPageComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/uploadBillPolicyPage"),
  loading: OverlayLoader,
}));

export const DateOfAdmissionVoucherComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/dateOfAdmissionVoucher"),
  loading: OverlayLoader,
}));

export const WhoIsItForVoucherComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/whoIsItForVoucher"),
  loading: OverlayLoader,
}));

export const BlogsHomeComponent = inDesktopView( Loadable({
  loader: () => import("./blogsHome"),
  loading: OverlayLoader,
}));

export const VitalPolicyDocumentComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/vitalPolicyDoc"),
  loading: OverlayLoader,
}));

export const TreatmentTypePolicyPageComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/treatmentTypePolicyPage"),
  loading: OverlayLoader,
}));

export const VitalPointsLandingPageComponent = inDesktopView( Loadable({
  loader: () => import("./vitalPointsLandingPage"),
  loading: OverlayLoader,
}));

export const CorporatePolicyComponent = inDesktopView( Loadable({
  loader: () => import("./corporatePolicy"),
  loading: OverlayLoader,
}));

export const EditFamilyMembersComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/editFamilyMembers"),
  loading: OverlayLoader,
}));

export const AddFamillyMembersComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/addFamilyMembers"),
  loading: OverlayLoader,
}));

export const PrivacyPolicyDocumentComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/privacyPolicyDoc"),
  loading: OverlayLoader,
}));

export const NBBanksComponent = inDesktopView( Loadable({
  loader: () => import("./payment_banks_nb"),
  loading: OverlayLoader,
}));

export const NBDetailsComponent = inDesktopView( Loadable({
  loader: () => import("./payment_nb_details"),
  loading: OverlayLoader,
}));

export const ZyppPolicyDoc = inDesktopView( Loadable({
  loader: () => import("./memberApp/zyppPolicyDoc"),
  loading: OverlayLoader,
}));

export const AgentRoutes = inDesktopView( Loadable({
  loader: () => import('./agent'),
  loading: OverlayLoader
}))

export const VerifyMembersComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/verifyMembers"),
  loading: OverlayLoader,
}));

export const VerifyAddressComponent = inDesktopView( Loadable({
  loader: () => import("./memberApp/verifyAddress"),
  loading: OverlayLoader,
}));

export const CoverageDoc = inDesktopView( Loadable({
  loader: () => import("./memberApp/coverageDoc"),
  loading: OverlayLoader,
}));

export const ClaimProcess = inDesktopView( Loadable({
  loader: () => import("./memberApp/claimprocess"),
  loading: OverlayLoader,
}));

export const InternalRoutes = inDesktopView( Loadable({
  loader: () => import('./internal'),
  loading: OverlayLoader
}))

export const WellnessPlan = inDesktopView( Loadable({
  loader: () => import("./wellnessPlan.jsx"),
  loading: OverlayLoader,
}));

export const RedirectPage = inDesktopView( Loadable({
  loader: () => import("./redirect"),
  loading: OverlayLoader
}))

export const HealthQuestionnaire = inDesktopView( Loadable({
  loader: () => import('./memberApp/healthQuestionnaire'),
  loading: OverlayLoader
}))

export const YearlyPayment = inDesktopView( Loadable({
  loader: () => import("./yearlyPayment"),
    loading: OverlayLoader
}))

export const SubscriptionPaymentOption = inDesktopView( Loadable({
  loader: () => import('./subscriptionPaymentOptions'),
  loading: OverlayLoader
}))

export const IndividualMemberDetailsPage = inDesktopView( Loadable({
  loader: () => import('./memberApp/individualMemberDetails'),
  loading: OverlayLoader
}))

export const AddNewMemberPage = inDesktopView( Loadable({
  loader: () => import('./memberApp/addNewMember'),
  loading: OverlayLoader
}))

export const PointsLedger = inDesktopView( Loadable({
  loader: () => import('./memberApp/pointsLedger'),
  loading: OverlayLoader
}))

export const SubscriptionSelfPayment = inDesktopView( Loadable({
  loader: () => import('./memberApp/subscriptionSelfpay'),
  loading: OverlayLoader
}))

export const CampaignPhonePage = inDesktopView( Loadable({
  loader: () => import('./campaign/phone'),
  loading: OverlayLoader
}))

export const VitalLandingPage = inDesktopView( Loadable({
  loader: () => import('./visitorLanding'),
  loading: OverlayLoader
}))

export const VisitorPhonePage = inDesktopView( Loadable({
  loader: () => import('./visitorphone'),
  loading: OverlayLoader
}))

export const BenefitsOfferDetails = inDesktopView( Loadable({
  loader: () => import('./memberApp/benefitsOfferDetails'),
  loading: OverlayLoader
}))

export const ViewRedeemedBenefit = inDesktopView( Loadable({
  loader: () => import('./memberApp/viewRedeemedBenefit'),
  loading: OverlayLoader
}))

export const DOABenefitAvail = inDesktopView( Loadable({
  loader: () => import('./memberApp/dateOfAdmissionBenefitAvail'),
  loading: OverlayLoader
}))

export const HealthCentreSelectionForBenefits = inDesktopView( Loadable({
  loader: () => import('./healthCentreSelectionForBenefits'),
  loading: OverlayLoader
}))

export const BenefitsShippableAddress = inDesktopView( Loadable({
  loader: () => import('./benefitShippableAddress'),
  loading: OverlayLoader
}))

export const SBOPolicyDoc = inDesktopView( Loadable({
  loader: () => import('./memberApp/sboPolicyDoc'),
  loading: OverlayLoader
}))

export const  SBOMaternityPolicyDocuments = inDesktopView( Loadable({
  loader: () => import('./memberApp/sboMaternityPolicyDocuments'),
  loading: OverlayLoader
}))

export const FamilyMembersHealthChecked = inDesktopView( Loadable({
  loader: () => import('./onboarding/familyMembersList'),
  loading: OverlayLoader
}))


export const GPADetails = inDesktopView( Loadable({
  loader: () => import('./memberApp/GPAPage'),
  loading: OverlayLoader
})) 

export const ECardPreview = inDesktopView( Loadable({
  loader: () => import('./business/GmcDashboard/ECardPreview'),
  loading: OverlayLoader
})) 

export {default as BusinessRoutes} from './business'
