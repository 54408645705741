import React, { Component, useState, useEffect } from 'react'
import styled from 'styled-components'
import Header from '../../components/memberApp/header'
import Icons from '../../components/icons'
import {Ptag} from '../../components/fonts'
import arrow from '../../assets/images/rightArrowWhite.png'
import {hospitalNetwork, claimForms, Insurers} from '../../utils/claimProcessMappings'


const ClaimProcess = ({...props}) => {
    const backPage = localStorage.getItem('navigatingFrom')
    
    const [tabChecked, setTab] = useState(0)
    const [link, setLink] = useState("")
    const [form, setClaimForm] = useState('')
    const [insurer, setInsurer] = useState('')

    useEffect(() => {
      setClaimForm(claimForms.get(props.match.params.id))
      setLink(hospitalNetwork.get(props.match.params.id))
      setInsurer(Insurers.get(props.match.params.id))
    },[])
    

    const onTabClicked = async (tabNumber) => {
        if (tabChecked=== tabNumber) {
            setTab(-1)
          
        } else {
          setTab(tabNumber)
        }
        let yOffset = window.document.getElementById(tabNumber.toString()).offsetTop
        window.scrollTo({
          top: yOffset,
          left: 0,
          behavior: 'smooth'
        })
    }

    return(
        <Root>
            <Header
                 headerText="Claim Process"
                 backPage={`/business/${backPage}`}
            />
            <Body>
                
            <TitleBox id='1' >
                <TileWrapper>
                <BlueText16>Cashless Claims Process</BlueText16>
                </TileWrapper>
                
            </TitleBox>

          {
		
			    <ProcessBody style={{'marginBottom':'24px'}}>
                    <ListOfHospitalWrapper>
                        <Ptag fontSize='16px' fontWeight='600' letterHeight='1.5' letterSpacing='.57px'  >
                        {'List of Hospitals in Cashless Network'}
                        </Ptag>
                        <List>
                        <Ptag color='#666666' letterSpacing='2' >
                            {'8500+ pan-India'}
                        </Ptag>
                        <Circle onClick={() => window.open(link,'_blank')}>
                            <img src={arrow} height='24px' width='24px'/>
                        </Circle>
                        </List>
                    </ListOfHospitalWrapper>

                    <StepText>Step 1:</StepText>
                    <NumberedBox>
                        <GreyText>
                          {`Show the ${insurer} Health Insurance Policy Number, which will be shared in your Vital account, to the Hospital TPA desk. Fill the pre- authorization form at the Hospital mentioning details.`}
                        </GreyText>
                    </NumberedBox>

                    <StepText>Step 2:</StepText>
                    <NumberedBox>
                        <GreyText>
                          {'Hospitals will submit the pre-authorization form to the Health Insurance Company, and they will revert back with a decision within 30 minutes whether they can authorize the claim or not based on your eligibility.'}                        
                        </GreyText>
                    </NumberedBox>

                    <StepText>Step 3:</StepText>
                    <NumberedBox >
                        <GreyText marginBottom='12px'>
                          {'If the claim is approved, the Hospital will submit all bills at the time of discharge to the Health Insurance Company for payment. The Insurance Company will pay the eligible amount directly to the Hospital.'}
                        </GreyText>
                    </NumberedBox>
                </ProcessBody>
          }

          <Line/>

          <TitleBox id='2'>
                <TileWrapper>
                <BlueText16>Reimbursement Claims Process</BlueText16>
                </TileWrapper>
                
            </TitleBox>
            
                <ProcessBody style={{'marginBottom':'24px'}}>

                <BulletHeading>
                    Step 1:
                </BulletHeading>
                <GreyText marginBottom='12px'>
                    Download the claim form from the link below, and fill the required details:
                </GreyText>
                <div style={{ 'marginBottom': '24px' }}>
                    <LinkCustom target="_blank" href={form} download="claimForm.pdf">
                    Claim form
                    </LinkCustom>
                </div>

                <BulletHeading style={{'marginTop':'12px'}}>
                    Step 2:
                </BulletHeading>

                <Step marginBottom='12px'>
                  Arrange for the following documents: 
                </Step>

                <Step marginBottom='12px'>
                        <Pre>1. </Pre> 
                        <p>Valid photo-id proof</p>
                </Step>
                <Step marginBottom='12px'>
                        <Pre>2. </Pre> 
                        <p>Medical practitioner's referral letter advising Hospitalization</p>
                </Step>
                <Step marginBottom='12px'>
                        <Pre>3. </Pre> 
                        <p>Medical practitioner's prescription advising drugs/diagnostic test/ consultation</p>
                </Step>
                <Step marginBottom='12px'>
                        <Pre>4. </Pre> 
                        <p>Original bills, receipts and discharge card from the hospital/medical practitioners</p>
                </Step>
                <Step marginBottom='12px'>
                        <Pre>5. </Pre> 
                        <p>Original bills from pharmacy/chemists</p>
                </Step>
                <Step marginBottom='12px'>
                        <Pre>6. </Pre> 
                        <p>Original pathological/diagnostic tests reports/radiology reports and payment receipts</p>
                </Step>
                <Step marginBottom='12px'>
                        <Pre>7. </Pre> 
                        <p>Indoor case papers prepared by the hospital </p>
                </Step>
                <Step marginBottom='12px'>
                        <Pre>8. </Pre> 
                        <p>First information report, final police report, if applicable.</p>
                </Step>
                <Step marginBottom='12px'>
                        <Pre>9. </Pre> 
                        <p>Post mortem report, if conducted</p>
                </Step>
                <Step marginBottom='24px'>
                        <Pre>10. </Pre> 
                        <p>Copy of a canceled cheque and the PAN card (in case the reimbursement amount is greater than Rs.1 lac)</p>
                </Step>
                
                
                <BulletHeading style={{'marginTop':'12px'}}>
                    Step 3:
                </BulletHeading>
                <GreyText marginBottom='12px'>
                  The claim form and additional documents have to be sent to the following address:
                </GreyText>
                {
                  props.match.params.id == 2 &&
                  <div>
                    <GreyText style={{'fontWeight':'600'}} marginBottom='0px'>
                      {'Bharti AXA General Insurance Company Limited'}
                    </GreyText>
                    <GreyText style={{'fontWeight':'600'}} marginBottom='0px'>
                      {'First Floor Hosto Centre'}
                    </GreyText>
                    <GreyText style={{'fontWeight':'600'}} marginBottom='0px'>
                      {'No. 43, Millers Road, Vasanth Nagar'}
                    </GreyText>
                    <GreyText style={{'fontWeight':'600'}} marginBottom='24px'>
                      {'Bangalore - 560052'}
                    </GreyText>
                    <GreyText marginBottom='24px'>
                      {'You will be duly intimidated by Bharti Axa on your email id and address.'}
                    </GreyText>
                    <GreyText marginBottom='24px'>
                      {'In case of any queries, you can contact Bharti Axa at 1800-103-2292 or email them at claims@bhartiaxa.com'}
                    </GreyText>
                  </div>
                }

              {
                  props.match.params.id == 3 &&
                  <div>
                    <GreyText style={{'fontWeight':'600'}} marginBottom='0px'>
                      {'ICICI Lombard General Insurance Company Limited'}
                    </GreyText>
                    <GreyText style={{'fontWeight':'600'}} marginBottom='0px'>
                      {'ICICI Lombard Health Care, ICICI Bank Tower, Plot No. 12'}
                    </GreyText>
                    <GreyText style={{'fontWeight':'600'}} marginBottom='0px'>
                      {'Financial District, Nanakram Guda,'}
                    </GreyText>
                    <GreyText style={{'fontWeight':'600'}} marginBottom='24px'>
                      {'Gachibowli, Hyderabad, Telangana,'}
                    </GreyText>
                    <GreyText style={{'fontWeight':'600'}} marginBottom='0px'>
                      {'Fax : 040-66989160/61'}
                    </GreyText>
                    <GreyText style={{'fontWeight':'600'}} marginBottom='0px'>
                      {'Toll free Fax No : 1800-209-8880'}
                    </GreyText>
                    <GreyText style={{'fontWeight':'600'}} marginBottom='24px'>
                      {'Toll free No: 1800 209 8880'}
                    </GreyText>
                    <GreyText marginBottom='24px'>
                      {`You will be duly intimidated by ICICI Lombard on your email id and address.`}
                    </GreyText>
                    
                  </div>
                }
                
                

                <BulletHeading style={{'marginTop':'12px'}}>
                    Step 4:
                </BulletHeading>

                <GreyText marginBottom='6px' letterSpacing='0.5px'>
                    Inform your Vital Care Manager or mail us at:
                </GreyText>
                <LinkCustom href="mailto:care@getvital.in">care@getvital.in</LinkCustom>
			

                </ProcessBody>
            
            </Body>
        </Root>
    )
}

export default ClaimProcess


const Root = styled.div`
    display: flex;
    flex-direction: column;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
`


const Subheading = styled.p`
font-family: Avenir;
font-size: 12px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 2;
letter-spacing: 0.43px;
color: rgba(0, 0, 0, 0.6);
`;


const BulletHeading = styled.p`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 1px;
  text-align: left;
  color: #4a4a4a;
  margin-bottom: 10px;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
`


const GreyText = styled.p`
  width: 100%;
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : "1px" };
  text-align: left;
  color: #666666;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '24px')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
`


const LinkCustom = styled.a`
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.18px;
  text-align: left;
  color: #436ab2;
  margin-bottom: 8px;
`

const Step = styled.div`
  display:flex;
  width: 100%;
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : "1px" };
  text-align: left;
  color: #666666;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '24px')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
`;

const Pre = styled.pre`
	font-family: Avenir;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.43;
	letter-spacing: 1px;
	text-align: left;
  color: #666666;
  min-width:20px;
`;


const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
`

const TileWrapper = styled.div`
  dispaly: flex;
  flex-direction: column;
  width: 90%;
`


const BlueText16 = styled.p`
  margin: 24px 0px;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #436ab2;
`

const Circle = styled.div`
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  justify-content:center;
  box-shadow: 0 1px 6px 0 rgba(136, 136, 136, 0.5);
  background-color: #ffffff;
  border-radius: 12px;
`


const ListOfHospitalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 18px 18px 18px;
  border-radius: 15px;
  margin-bottom:24px;
  background-color: rgba(158, 187, 239, 0.2);
`

const List = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;


const StepText = styled.p`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 1px;
  text-align: left;
  color: #666666;
  margin-bottom: 10px;
`


const TileIcon = styled.div`
  display: flex;
  height: auto;
  align-items: center;
`


const NumberedBox = styled.div`
  display: flex;
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : 'row')};
`


const Line = styled.div`
  min-width: 100%;
  height: 1px;
  background-color: #9b9b9b;
`

const ProcessBody= styled.div`
    display: flex;
    flex-direction: column;
`