import React, { Component, Fragment, useEffect, useState } from 'react'
import styled from 'styled-components'
import {Ptag} from '../fonts'
import { formatRelation,} from '../../utils/utils'
import {withRouter} from 'react-router'

const AddMembersButtonNew = ({members, history}) => {
    const useMembersCount = () => {
        const [membersCount, setMembersCount ] = useState({})
        useEffect(() => {
            const permissibleMembersCount = {
                SPOUSE: 1,
                SON: 4,
                DAUGHTER: 4,
                FATHER: 1,
                MOTHER: 1,
                FATHERINLAW: 1,
                MOTHERINLAW: 1,
                SELF: 1,
            }
            for ( let item of members){
                if(item['relationship'].toUpperCase() === 'DAUGHTER' || item['relationship'].toUpperCase() === 'SON'){
                    permissibleMembersCount['DAUGHTER']--
                    permissibleMembersCount['SON']--
                } else {
                    permissibleMembersCount[item['relationship'].toUpperCase()]--
                } 
            }
            setMembersCount(permissibleMembersCount)
        },[members])
        return membersCount
    }
    const membersCount = useMembersCount()
    
    
    const onClickButton = (item) => {
        localStorage.removeItem('addFamilyMember')
        history.push(`/addNewMember/${item[0].toLowerCase()}`)
    }
    if(Object.values(membersCount).filter(item => item > 0).length > 0 ){
        return(
            <Root>
                <Ptag fontSize='14px' letterSpaicng='1.71' letterSpacing='1px' >
                    {'ADD MEMBER'}
                </Ptag>
                <ButtonsWrapper>
                    {
                        Object.entries(membersCount).filter(i => i[1] > 0).map(
                            item => (
                                <Button key={item[0]} onClick={() => onClickButton(item)}>
                                    {formatRelation(item[0].toLowerCase())}
                                </Button>
                            )
                        )
                    }
                </ButtonsWrapper>
            </Root>
        )
    } else {
        return null
    }
    
}

export default withRouter(AddMembersButtonNew)

const Root = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;

`
const ButtonsWrapper = styled.div`
    display:grid;
    grid-template-columns: 33% 33% 33%;
    margin-top: 12px;
`

const Button = styled.div`
    padding: 9px 0;
    border-radius: 8px;
    min-width: 96px;
    border: solid 1px #436ab2;
    margin: 0 12px 16px 0;
    font-family: Avenir;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a4a4a;
    text-transform: capitalize;
    cursor: pointer;
`