import React, { useMemo } from "react";
import { useLocation } from "react-router-dom";
import ECard from "../../../components/GmcDashbaord/ECard";
import { ECardDownloadWrapper } from "./styles";

const ECardDownload = () => {
  // getting members state from router
  const { state } = useLocation();
  // getting members from the state
  const members = useMemo(() => {
    return state.data;
  }, [state]);
  
  return (
    <ECardDownloadWrapper>
      {members.map((member) => (
        <ECard noStrip={true} member={member} />
      ))}
    </ECardDownloadWrapper>
  );
};

export default ECardDownload;
