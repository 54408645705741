import React, { Component } from "react";
import styled from "styled-components";
import arrowBack from "../../assets/images/arrowBack.png";
import { withRouter } from "react-router";

class Header extends Component {
  onBackButtonClick = () => {
    if (this.props.navigateToContext) {
      this.props.navigateToContext(this.props.backPage);
    } else {
      this.props.history.push(this.props.backPage);
    }
  };

  render() {
    return (
      <Root zindex={this.props.zindex}>
        {this.props.backButton && this.props.backPage && (
          <BackButton
            onClick={() => this.props.history.push(this.props.backPage)}
          >
            <BackImage src={arrowBack} />
          </BackButton>
        )}
        {this.props.headerText && (
          <HeaderText>{this.props.headerText.toLowerCase()}</HeaderText>
        )}

      </Root>
    );
  }
}

export default withRouter(Header);

Header.defaultProps = {
  closebutton: false,
  headerText: "",
  backButton: true,
};

const Root = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  background-color: #ffffff;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index:${(props)=>(props.zindex?props.zindex:0)};
  padding: 18px 0 18px 16px ;
`;

const BackButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const BackImage = styled.img`
  width: 2.4rem;
  height: 2.4rem;
`;

const HeaderText = styled.p`
  text-transform: capitalize;
  padding-left: 1rem;
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  font-weight: 600;
  color: #436ab2;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
