import React, { Component } from "react";
import styled from "styled-components";
import { Ptag } from "../../components/fonts";
import { Input, LargeButton, OverlayLoader } from "../../components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Logo from "../../assets/images/referral_logo.png";
import Icons from "../../components/icons";
import http from "../../services/httpService";

export default class Referral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      agreement: false,
    };
  }

  onClickCheckbox = () => {
    this.setState({ agreement: !this.state.agreement });
  };

  handleSubmit = async (values, actions) => {
    this.setState({ loading: true });

    try {
      const { data } = await http.post("/ref/business/getcode/", {
        name: values["name"],
        name_of_organisation: values["name_of_organisation"],
        email: values["email"],
        phone_no: values["phone_no"],
        pincode: values["pincode"],
      });
      sessionStorage.setItem("referralCode", data["code"]);
      this.props.history.push("/business/shareonwhatsapp");
    } catch (ex) {
      this.setState({ loading: false });
      const error = ex.response && ex.response.data;
      if (error) {
        Object.keys(error).map((item) =>
          actions.setFieldError(item, error[item])
        );
      }
      this.setState({ loading: false });
    }
  };

  onClickTnc = (formikProps) => {
    sessionStorage.setItem("ambassador", JSON.stringify(formikProps.values));
    this.props.history.push("/business/tnc");
  };
  onClickFees = (formikProps) => {
    sessionStorage.setItem("ambassador", JSON.stringify(formikProps.values));
    this.props.history.push("/business/referralfees");
  };

  handleChange = (event, setFieldValue, name, length) => {
    const value = event.target.value;
    setFieldValue(name, value.slice(0, length));
  };

  renderInput = ({
    name,
    placeholder,
    type,
    formikProps,
    length = 100,
  } = {}) => {
    const { errors, touched, handleBlur, values, setFieldValue } = formikProps;
    const error = touched[name] && errors[name];
    return (
      <Input
        value={values[name]}
        name={name}
        placeholder={placeholder}
        type={type}
        error={error}
        onBlur={handleBlur}
        onChange={(event) =>
          this.handleChange(event, setFieldValue, name, length)
        }
      />
    );
  };

  renderHeader = () => {
    return (
      <Header>
        <div className="text-one">
          <i className="logo">
            <img src={Logo} alt="logo" />
          </i>
          <i className="line" />
          BUSINESS
        </div>
      </Header>
    );
  };

  renderForm = (formikProps) => {
    const isDisabled = !this.state.agreement;
    return (
      <FormContainer>
        <Form>
          {this.renderInput({
            name: "name",
            placeholder: "Name",
            type: "text",
            formikProps,
          })}
          {this.renderInput({
            name: "name_of_organisation",
            placeholder: "Name of your Firm",
            type: "text",
            formikProps,
          })}
          {this.renderInput({
            name: "email",
            placeholder: "Email",
            type: "text",
            formikProps,
          })}
          {this.renderInput({
            name: "phone_no",
            placeholder: "Mobile Number",
            type: "number",
            formikProps,
            length: 10,
          })}
          {this.renderInput({
            name: "pincode",
            placeholder: "Office Pincode",
            type: "number",
            formikProps,
            length: 6,
          })}

          <Agreement>
            <IconWrapper onClick={this.onClickCheckbox}>
              <Icons
                icon={
                  this.state.agreement ? "checkedCircle" : "uncheckedCircle"
                }
                fill="#436ab2"
              />
            </IconWrapper>
            <Ptag letterSpacing="0.5px" letterHeight="1.43" fontSize="14px">
              By creating an account, I accept the{" "}
              <List onClick={() => this.onClickTnc(formikProps)}>
                Terms and Conditons
              </List>{" "}
              and{" "}
              <List onClick={() => this.onClickFees(formikProps)}>
                Fee Structure
              </List>
              .
            </Ptag>
          </Agreement>

          <ButtonWrapper>
            <LargeButton
              id="referral_next"
              label={"SIGN UP"}
              type="submit"
              disabled={isDisabled}
              opacity={isDisabled ? "0.4" : "1"}
            />
          </ButtonWrapper>
        </Form>
      </FormContainer>
    );
  };

  render() {
    const Schema = Yup.object().shape({
      name: Yup.string().required("*Required"),
      pincode: Yup.number()
        .typeError("pincode must be a number")
        .required("*Required")
        .test(
          "len",
          "pincode must be exactly 6 digits",
          (val) => String(val).length === 6
        ),
      phone_no: Yup.number()
        .typeError("mobile no must be a number")
        .required("*Required")
        .test(
          "len",
          "monile no must be exactly 10 digits",
          (val) => String(val).length === 10
        ),
      name_of_organisation: Yup.string().required("*reuired"),
      email: Yup.string()
        .email()
        .required("*required"),
    });
    const about = JSON.parse(sessionStorage.getItem("ambassador"));
    const initialValues = {
      name: (about && about["name"]) || "",
      name_of_organisation: (about && about["name_of_organisation"]) || "",
      email: (about && about["email"]) || "",
      phone_no: (about && about["phone_no"]) || "",
      pincode: (about && about["pincode"]) || "",
    };
    return (
      <Parent>
        {this.renderHeader()}
        <Body>
          <Ptag
            fontFamily="Proxima Nova"
            fontSize="32px"
            fontWeight="600"
            letterSpacing="0.9px"
          >
            {"Welcome to Vital Ambassador"}
          </Ptag>
          <Ptag
            fontFamily="Avenir"
            fontSize="16px"
            fontWeight="500"
            letterHeight="1.33"
            letterSpacing="0.4px"
            color="#4a4a4a"
            marginTop="10px"
          >
            {"we just need a few details to get you started"}
          </Ptag>

          <Formik
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={this.handleSubmit}
            render={(formikProps) => this.renderForm(formikProps)}
          />
        </Body>
        {this.state.loading && <OverlayLoader />}
      </Parent>
    );
  }
}

const Parent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: -webkit-fill-available;
`;

const Agreement = styled.div`
  display: flex;
  margin: 40px 0 60px 0;
`;

const IconWrapper = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  opacity: ${(props) => props.active};
  cursor: pointer;
`;

const List = styled.em`
  text-decoration: underline;
  letter-spacing: 0.5px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  text-transform: none;
  opacity: 1;
  font-family: Avenir;
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  color: #4a90e2;
  cursor: pointer;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 22px;
  flex: 1;
  align-self: center;
  box-shadow: 0 0 11px 0 rgba(139, 137, 137, 0.5);
  /* max-width: 800px; */
  @media (min-width: 1022px) {
    padding: 24px 50px;
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 34px;
  flex: 1;
  & > form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

const InputContainer = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
  position: relative;
  padding: 24px;
  & .text-one {
    display: flex;
    font-family: Avenir;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #436ab2;

    // @media (min-width: 1020px) {

    //   font-family: Avenir;
    //   font-size: 16px;
    //   font-weight: normal;
    //   font-stretch: normal;
    //   font-style: normal;
    //   line-height: normal;
    //   letter-spacing: 0.5px;
    //   text-align: center;
    //   color: #436ab2;
    // }
  }

  & .text-two {
    /* height: 22px; */
    font-family: Avenir;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: right;
    color: #436ab2;
    margin-right: 6px;
    text-decoration: none;
    // @media (min-width: 1022px) {
    //   height: 22px;
    //   font-family: Avenir;
    //   font-size: 18px;
    //   font-weight: 600;
    //   font-stretch: normal;
    //   font-style: normal;
    //   line-height: normal;
    //   letter-spacing: 1px;
    //   text-align: right;
    //   color: #436ab2;
    //   margin-right: 22px;
    // }
  }

  & img {
    width: 60px;
    height: 20px;
    object-fit: contain;
    // @media (min-width: 1022px) {
    //   width: 61px;
    //   height: 20px;
    //   object-fit: contain;
    //   margin-left: 24px;
    //   margin-right: 19px;
    // }
  }

  & .line {
    margin-left: 12px;
    margin-right: 12px;
    left: 80px;
    width: 1px;
    height: 20px;
    border: solid 0.5px #979797;

    // @media (min-width: 1022px) {
    //   position: absolute;
    //   left: 95px;
    //   width: 1px;
    //   height: 20px;
    //   border: solid 0.5px #979797;
    // }
  }
`;
