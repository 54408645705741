import React, { useState } from "react";
import HeroBanner from "../../../assets/svgs/employer_login_hero.png";
import NeoInsuranceLogo from "../../../assets/svgs/neo_insurance_fill.svg";
import VitalLogo from "../../../assets/images/vitalLogoCopy.png";

import { LargeButton } from "../../../components";
import { Ptag } from "../../../components/fonts";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import {
  Error,
  LoginContentWrapper,
  LoginPageWrapper,
  PhoneInput,
  PhoneInputWrapper,
} from "./styles";
import http from "../../../services/httpService";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const EmployerLogin = () => {
  const [phoneNo, setPhoneNo] = useState("");
  const [errorSnackbar, setErrorSnackbar] = useState(false);
  const [error, setError] = useState("");

  const history = useHistory();

  const numberValidator = (event) => {
    removeError();
    const number = event.target.value;
    const cleanedStr = number.replace(/\D/g, "");
    setPhoneNo(cleanedStr);
  };

  const loginHandler = async () => {
    if (phoneNo.length !== 10) {
      setError("*Required");
      return;
    }
    try {
      await http.post("/phone/send_otp/", {
        phone: phoneNo,
        action: "LOGIN",
        business: true,
      });
      setupLocalStorage();
      history.push("/business/otp");
    } catch (err) {
      console.log({ err });
      setErrorSnackbar(true);
      //   alert("Some error has occured!");
    }
  };

  const setupLocalStorage = () => {
    localStorage.setItem("navigatingFrom", "login");
    localStorage.setItem("phone", phoneNo);
  };

  const removeError = () => {
    setError("");
  };

  const useStyles = makeStyles({
    cookieAlert: {
      "& .MuiAlert-message": {
        fontSize: "14px"
      }
    }
  });

  const classes = useStyles();

  return (
    <>
      <Snackbar
        open={errorSnackbar}
        autoHideDuration={4000}
        onClose={() => setErrorSnackbar(false)}
      >
        <Alert
          severity="error"
          className={classes.cookieAlert}
        >
          Please enter the correct phone number.
        </Alert>
      </Snackbar>
      <LoginPageWrapper>
        <aside>
          <img src={HeroBanner} />
        </aside>
        <LoginContentWrapper>
          <div>
            <img style={{width: "100px"}}  src={VitalLogo} />
            <Ptag fontSize="20px" fontWeight="600" marginTop="2em">
              Hey there, welcome back!
            </Ptag>
            <PhoneInputWrapper>
              <p>+91</p>
              <div></div>
              <PhoneInput
                type="tel"
                value={phoneNo}
                onChange={numberValidator}
                pattern="[0-9]{10}"
                maxLength="10"
              />
              {error && <Error>{error}</Error>}
            </PhoneInputWrapper>
            <LargeButton label="Login" onClick={loginHandler} />
          </div>
        </LoginContentWrapper>
      </LoginPageWrapper>
    </>
  );
};

export default EmployerLogin;
