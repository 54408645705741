import React from 'react'
import styled from 'styled-components'
import vitalJoin1 from '../assets/svgs/vitalJoin1.svg'
// import vitalJoin2 from '../assets/svgs/vitalJoin2.svg'
import vitalJoin2 from '../assets/images/vitalJoin2.png'
import halfCircleLightBlue from '../assets/svgs/flyingLogoWebsite/halfCircleLightBlue.svg'
import stripHalfCircleYellow2 from '../assets/svgs/flyingLogoWebsite/stripHalfCircleYellow2.svg'

const Wrapper = styled.div`
  width: 100%;
  padding-top: 3.6rem;
  background-color: #fff;
  position: relative;
  & > h3 {
    padding-left: 2.4rem;
    // width: 21rem;
  }

  @media (min-width: 1024px) {
    & > h3 {
      width: auto;
      text-align: center;
      margin-bottom: 3rem;
      font-size: 2.8rem;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.02px;
    }
  }

  @media (min-width: 1680px) {
    & > h3 {
      margin-bottom: 9rem;
      font-size: 6.2rem;
      font-weight: 600;
      line-height: 1.39;
      letter-spacing: 0.04px;
    }
    padding-top: 15.6rem;
  }
`
const Header = styled.div`
  padding: 0 2.4rem 0 2.4rem;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

const FlexContainer = styled.div`
  width: 31rem;
  & > p {
    margin-top: 1.2rem;
    padding-right: 7.8rem;
  }

  @media (min-width: 1024px) {
    h3 {
      // margin-bottom: 1.8rem;
    }
    & > p {
      width: 29rem;
      // margin-top: 0;
      margin-top: 1.8rem;
      padding: 0;
    }
  }

  @media (min-width: 1680px) {
    width: 60rem;
    h3 {
      // margin-bottom: 4rem;
      font-size: 3.6rem;
      font-weight: normal;
      line-height: 1.61;
      letter-spacing: 0.03px;
    }
    & > p {
      width: auto;
      margin-top: 4rem;
      padding: 0;
    }
  }
`

const BottomBorder = styled.div`
  margin: 0 2.4rem;
  border-bottom: 2px solid #f9f9f9;

  @media (min-width: 1680px) {
    margin: 0 14.6rem;
  }
`

const ImageWrapper = styled.div`
  width: 50rem;
  @media (max-width: 425px) {
    width: auto;
  }
`

const Image = styled.div`
  background: url(${"http://images.getvital.in/homelanding/vitalJoin2.png"});
  background-size: cover;
  width: 30rem;
  height: 32.58rem;
  margin: 0 auto;
  margin-top: 2.6rem;

  @media (min-width: 1024px) {
    // margin: 0;
    // background: url(${"http://images.getvital.in/homelanding/vitalJoin2.png"});
    // background-size: cover;
    // width: 40rem;
    // height: 43.44rem;
    margin-left: 85px;
  }
  @media (min-width: 1680px) {
    background: url(${"http://images.getvital.in/homelanding/vitalJoin2.png"});
    background-size: cover;
    width: 40rem;
    height: 44.3rem;
  }
`

const Logo1 = styled.div`
  width: 5rem;
  height: 10rem;
  background: url(${halfCircleLightBlue});
  background-repeat: no-repeat;
  position: absolute;
  top: 12%;
  right: 0;
`

const Logo2 = styled.div`
  width: 6rem;
  height: 10rem;
  background: url(${stripHalfCircleYellow2});
  background-repeat: no-repeat;
  position: absolute;
  bottom: 5%;
  left: 0;
`

const Circle = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 1.2rem;

  @media (min-width: 1024px) {
    margin-bottom: 2.4rem;
  }

  @media (min-width: 1680px) {
    margin-bottom: 4rem;
    width: 8rem;
    height: 8rem;
  }
`

const H3 = styled.h3`
  font-family: Proxima Nova;
  font-size: 2rem;
  font-weight: ${props => (props.fontWt ? props.fontWt : 'normal')};
  // font-weight: normal;
  font-style: normal;
  padding-right: 78px;
  font-stretch: normal;
  // line-height: ${props => (props.lineHt ? props.lineHt : 'normal')};
  line-height: normal;
  letter-spacing: 0.02px;
  text-align: left;
  color: #4a4a4a;

   @media (min-width: 1024px) {
    line-height: 1.16;
    padding-right: 40px;
    padding-top: 24px;
    font-size: 28px;
  }

  @media (min-width: 1680px) {
    font-size: 3.6rem;
    font-weight: 600;
    // font-size: 28px;
    // line-height: 1.39;
    letter-spacing: 0.03px;
  }
`

const Font20 = styled.p`
  font-family: Avenir;
  font-size: 2rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: 0.01px;
  color: #436ab2;

  @media (min-width: 1680px) {
    font-size: 4.8rem;
    line-height: 0.58;
  }
`
const Font16 = styled.p`
  font-family: Avenir;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a4a;
  @media (min-width: 1024px) {
    font-size: 18px;
  }

  @media (min-width: 1680px) {
    font-size: 3.4rem;
    line-height: 1.59;
    letter-spacing: 0.02px;
  }
`

export default function Page22() {
  return (
    <Wrapper>
      <H3 fontWt="600">Ways to Earn Vital Points</H3>
      <Header>
        <FlexContainer>
          <Circle>
            <Font20>1</Font20>
          </Circle>
          <H3> On signing up at Vital </H3>
          <Font16> Earn 500 bonus Vital Points for registering on Vital. </Font16>
        </FlexContainer>
        <ImageWrapper>
          <Image />
        </ImageWrapper>
      </Header>
      <Logo1 />
      <Logo2 />
      <BottomBorder />
    </Wrapper>
  )
}
