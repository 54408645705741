export const PaymentMethod = {
  NONE: 0,
  NETBANKING: 1,
  CARD: 2,
  UPI: 3,
  WALLET: 4,
}

export const CardMode = {
  CREDIT_CARD: 'CC',
  DEBIT_CARD: 'DC'
}

export const SubscriptionPlan = {
  NORMAL: 0,
  RECURRING_WITH_NETBANKING: 1,
  RECURRING_WITH_CARD: 2,
  RECURRING_WITH_UPI: 3
}

//export default { CardMode, PaymentMethod, SubscriptionPlan }
