import React, { Component } from 'react'
import styled from 'styled-components'
// import Network1 from '../assets/svgs/Network1.svg'
// import Network2 from '../assets/svgs/Network2.svg'
import pointsSS from '../assets/images/pointsSS.png'
import halfCircleLightGreen2 from '../assets/svgs/flyingLogoWebsite/halfCircleLightGreen2.svg'
import triangleBlue from '../assets/svgs/flyingLogoWebsite/triangleBlue.svg'

const Wrapper = styled.div`
  width: 100%;
  padding-top: 4.8rem;
  background-color: #fff;
  position: relative;

  @media (min-width: 1680px) {
    padding-top: 15.2rem;
  }
`

const Header = styled.div`
  padding: 0 2.4rem;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: center;
  }

  @media (min-width: 1680px) {
    // padding-bottom: 14.6rem;
  }
`

const FlexContainer = styled.div`
  width: 31rem;
  & > p {
    margin-top: 1.2rem;
    padding-right: 5rem;
  }

  @media (min-width: 1024px) {
    // padding-right: 140px;
    // margin-left: -75px;
    width: 310px;
    h3 {
      // margin-bottom: 1.8rem;
    }
    & > p {
      width: 29rem;
      margin-top: 1.8rem;
      // margin-bottom: 1.8rem;
      // margin-top: 0;
      padding-right: 0px;
    }
  }

  @media (min-width: 1680px) {
    width: 60rem;
    h3:last-child {
      // margin-bottom: 4rem;
      font-size: 6.2rem;
      font-weight: normal;
      line-height: 1.61;
      letter-spacing: 0.03px;
    }
    & > p {
      width: auto;
      margin-top: 4rem;
      padding: 0;
    }
  }
`

const BottomBorder = styled.div`
  margin: -0.1rem 2.4rem 0 2.4rem;
  border-bottom: 2px solid #f9f9f9;

  @media (min-width: 1680px) {
    margin: 14.6rem 14.6rem 0 14.6rem;
  }
`

const Circle = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.2rem;

  @media (min-width: 1024px) {
    margin-bottom: 2.4rem;
  }

  @media (min-width: 1680px) {
    margin-bottom: 4rem;
    width: 8rem;
    height: 8rem;
  }
`

const ImageWrapper = styled.div`
display: flex;
align-items: center;
justify-content: center;
  // width: 50rem;
  @media (max-width: 425px) {
    width: auto;
  }

  @media(min-width: 1024px) {
    // width: 225px;
    // height: 265px;
    margin-left: -45px;
  }
`



const Logo1 = styled.div`
  width: 6rem;
  height: 10rem;
  background: url(${halfCircleLightGreen2});
  background-repeat: no-repeat;
  position: absolute;
  bottom: 30%;
  left: 0;
`
const Logo2 = styled.div`
  width: 6rem;
  height: 5rem;
  background: url(${triangleBlue});
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 4%;
`

const H3 = styled.h3`
  font-family: Proxima Nova;
  font-size: 2rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  text-align: left;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    line-height: 1.16;
    font-size: 28px;
  }

  @media (min-width: 1680px) {
    font-size: 3.6rem;
    // line-height: 1.61;
    // font-size: 28px;
    letter-spacing: 0.03px;
  }
`

const Font20 = styled.p`
  font-family: Avenir;
  font-size: 2rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: 0.01px;
  text-align: left;
  color: #436ab2;

  @media (min-width: 1680px) {
    font-size: 4.8rem;
    line-height: 0.58;
  }
`
const Font16 = styled.p`
  font-family: Avenir;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a4a;
  margin-bottom: 24px;

  @media (min-width: 1024px) {
    font-size: 18px;
  }

  @media (min-width: 1680px) {
    font-size: 3.4rem;
    line-height: 1.59;
    letter-spacing: 0.02px;
  }
`
const Image = styled.img`
  width: 225px;
  height: 265px;

  @media(min-width: 1024px) {
    width: 225px;
    height: 265px;
    margin-right: 220px;
  }

  @media (min-width: 1680px) {
    width: 380px;
    height: 423px;
  }
`

export default class Page23 extends Component {
  render = () => {
    return (
      <Wrapper>
        <Header>
          <FlexContainer>
            <Circle>
              <Font20>2</Font20>
            </Circle>
            <H3> On redeeming a voucher </H3>
            {/* <H3> to a provider in our network</H3> */}
            <Font16>           
               Get points in cashback each time you apply our voucher to make a payment at certain providers.
            </Font16>
          </FlexContainer>
          <ImageWrapper>
           {/* <Image src={pointsSS} /> */}
           <Image alt="redeeming a voucher" src = "https://images.getvital.in/homelanding/pointsSS.png" />
          </ImageWrapper>
        </Header>
        <Logo1 />
        {/* <Logo2 /> */}
        <BottomBorder />
      </Wrapper>
    )
  }
}
