import React, { Component } from 'react'
import styled from 'styled-components'
import { Ptag } from '../fonts'
import greyCross from '../../assets/images/greyCross.png'
import pencil from '../../assets/images/pencilGrey.png'

const HealthTags = ({ questions, responses, onChange, openQuestion, index, locked,...props }) => {
  let onClickCross = async healthIssue => {
    await onChange(index, healthIssue, undefined)
    await openQuestion(index, false)
  }

  if (responses && Object.keys(responses).length) {
    let Tags = []
    for (let question of questions) {
      if (question['key'] in responses && responses[question['key']]) Tags.push(question)
    }
    if (Tags.length) {
      return (
        <Root style={props.style}>
          {Tags.map((object, key) => (
            <TagDiv key={key}>
              <Ptag style={{ marginRight: '4px', width: 'max-content' }} textTransform="capitalize" letterSpacing="1px">
                {object['tag']}
              </Ptag>
              {!locked && <Cross onClick={() => onClickCross(object['key'])} width="16px" height="16px" src={greyCross} />}
            </TagDiv>
          ))}
          {!locked && 
            <TagDiv marginLeft="auto" marginRight="0px" borderRadius="5px" minWidth="36x">
              <Cross onClick={() => openQuestion(index, true, false)} src={pencil} width="16px" height="16px" />
           </TagDiv>
          }
        </Root>
      )
    }
  }
  return null
}

export default HealthTags

const Root = styled.div`
  display: flex;
  overflow-x: auto;
  margin-top: 12px;
`

const TagDiv = styled.div`
  display: flex;
  padding: 10px;
  height: 36px;
  border-radius: ${props => (props.borderRadius ? props.borderRadius : '8px')};
  border: solid 1px #436ab2;
  justify-content: space-between;
  align-items: center;
  margin-right: ${props => (props.marginRight ? props.marginRight : '12px')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0px')};
`

const Cross = styled.img`
    margin-left:: 4px;
`
