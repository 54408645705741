import React, { Component } from "react";
import styled from "styled-components";
import { Ptag } from "../components/fonts";
import vitalInsuranceDeductible from "../assets/images/vitalInsuranceDeductible.png";
import DeductibleZ from "../assets/images/deductibleZ.png";

const Wrapper = styled.div`
  width: 100%;
  background: rgba(238, 238, 238, 1);
  padding: 3.6rem 0 0 0;

  @media (min-width: 1024px) {
    padding: 6rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const Header = styled.div`
  width: 32rem;
  h2 {
    margin-bottom: 1.2rem;
  }
  @media (min-width: 1023px) {
    padding-left: 0;
    width: 40rem;
    margin-right: 140px;
  }
  @media (min-width: 1680px) {
    width: 60rem;
    h2 {
      margin-bottom: 4.8rem;
    }
  }
`;

const Image = styled.div`
  width: 33.4rem;
  height: 24.5rem;
  background: url(${vitalInsuranceDeductible});
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 auto;
  background-repeat: no-repeat;

  @media (min-width: 1024px) {
    margin: 0;
    width: 33.4rem;
    height: 24.5rem;
    background: url(${vitalInsuranceDeductible});
    background-repeat: no-repeat;
    background-size: contain;
    background-repeat: no-repeat;
  }
  @media (min-width: 1680px) {
    background: url(${vitalInsuranceDeductible});
    background-size: contain;
    width: 50.4rem;
    height: 36.5rem;
    background-repeat: no-repeat;
  }
`;

const Image2 = styled.div`
  width: 32.7rem;
  height: 22.6rem;
  background: url(${DeductibleZ});
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  background-repeat: no-repeat;

  @media (min-width: 1024px) {
    margin: 0;
    width: 33.4rem;
    height: 24.5rem;
    background: url(${DeductibleZ});
    background-repeat: no-repeat;
    background-size: contain;
  }
  @media (min-width: 1680px) {
    background: url(${DeductibleZ});
    background-size: contain;
    width: 50.4rem;
    height: 41.5rem;
    background-repeat: no-repeat;
  }
`;

const H2 = styled.h2`
  font-family: Proxima Nova;
  font-size: 2.4rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    width: 100%;
    font-size: 3.4rem;
    line-height: 1.29;
    letter-spacing: 0.02px;
  }

  @media (min-width: 1680px) {
    font-size: 6rem;
    letter-spacing: 0.03px;
  }
`;

const Strong = styled.strong`
  font-weight: 600;
`;

const H3 = styled.h3`
  margin-top: 1.2rem;
  font-size: 1.6rem;
  font-family: Avenir;
  font-weight: normal;
  line-height: 1.75;
  letter-spacing: 0.02px;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    margin-top: 2.4rem;
    font-size: 1.8rem;
    line-height: 1.56;
    letter-spacing: normal;
  }

  @media (min-width: 1680px) {
    margin-top: 4.5rem;
    font-size: 3.4rem;
    font-weight: normal;
    letter-spacing: 0.02px;
    line-height: 1.59;
  }
`;

const ImageContainer = styled.div`
  @media (max-width: 1024px) {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`;

const WhyHaveDeductible = styled.div`
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 24px 24px;
  }
  @media (max-width: 1679px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 350px;
  }

  @media (min-width: 1680px) {
    display: none;
  }
`;

const WhyHaveDeductible1680 = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
  @media (max-width: 1679px) {
    display: none;
  }

  @media (min-width: 1680px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 350px;
  }
`;

const WhyHaveDeductibleText = styled.div`
  display: flex;
  flex-direction: column;
`;

export default class Page33 extends Component {
  render = () => {
    return (
      <Wrapper>
        <Header>
          <H2>The Smart Plan</H2>
          <H3>
            {
              "Pay premiums monthly instead of annually and lower them by selecting a small deductible. Deductible is the amount you will pay yourself towards treatments covered in the plan before insurance starts to pay. You can choose between a ₹0, ₹35,000 or ₹1,00,000 deductible."
            }
          </H3>
        </Header>

        <WhyHaveDeductible>
          <Ptag letterSpacing="1px" color="#436ab2">
            {"FOR EXAMPLE"}
          </Ptag>
          <Ptag
            style={{ margin: "9px 0 24px 0" }}
            opcaity="0.8"
            fontSize="14px"
            letterHeight="1.71"
            letterSpacing="0.5px"
          >
            {
              "Ajay bought our plan with a deductible of 35,000 deductible and 10 lakhs sum insured to cover him, his wife and son."
            }
          </Ptag>
          <ImageContainer>
            <Image />
          </ImageContainer>
          <WhyHaveDeductibleText>
            <Ptag
              style={{ margin: "24px 0 12px 0" }}
              fontSize="14px"
              fontWeight="600"
              letterHeight="1.71"
              letterSpacing="0.5px"
            >
              {"Why have deductible?"}
            </Ptag>
            <Ptag
              style={{ marginBottom: "24px" }}
              letterSpacing="0.5px"
              fontSize="14px"
              letterHeight="1.71"
            >
              {"By choosing a deductible, you can reduce premium by up to 70%."}
            </Ptag>
            <Ptag style={{ marginBottom: "9px" }} color="#436ab2">
              {"FOR EXAMPLE"}
            </Ptag>
            <Ptag
              style={{ marginBottom: "12px" }}
              opacity="0.8px"
              fontSize="14px"
              letterHeight="1.71"
              letterSpacing="0.5px"
            >
              {"Ajay saves"} <Strong>{"₹10,000"}</Strong>{" "}
              {
                "in premium per year for choosing our value plan with a deductible of"
              }{" "}
              <Strong>{"₹35,000."}</Strong> {"If there are no claims for"}{" "}
              <Strong>{"4 years:"}</Strong>
            </Ptag>
          </WhyHaveDeductibleText>

          <ImageContainer>
            <Image2 />
          </ImageContainer>
        </WhyHaveDeductible>

        <WhyHaveDeductible1680>
          <Ptag fontSize="18px" letterSpacing="1px" color="#436ab2">
            {"FOR EXAMPLE"}
          </Ptag>
          <Ptag
            style={{ margin: "18px 0 24px 0" }}
            opcaity="0.8"
            fontSize="20px"
            letterHeight="1.71"
            letterSpacing="0.5px"
          >
            {
              "Ajay bought our plan with a deductible of ₹35,000 deductible and ₹10 lakhs sum insured to cover him, his wife and son."
            }
          </Ptag>

          <ImageContainer>
            <Image />
          </ImageContainer>

          <WhyHaveDeductibleText>
            <Ptag
              style={{ margin: "24px 0 12px 0" }}
              fontSize="20px"
              fontWeight="600"
              letterHeight="1.71"
              letterSpacing="0.5px"
            >
              {"Why have deductible?"}
            </Ptag>
            <Ptag
              style={{ marginBottom: "24px" }}
              letterSpacing="0.5px"
              fontSize="20px"
              letterHeight="1.71"
            >
              {"By choosing a deductible, you can reduce premium by up to 70%."}
            </Ptag>
            <Ptag
              style={{ marginBottom: "9px" }}
              fontSize="18px"
              color="#436ab2"
            >
              {"FOR EXAMPLE"}
            </Ptag>
            <Ptag
              style={{ marginBottom: "12px" }}
              opacity="0.8px"
              fontSize="20px"
              letterHeight="1.71"
              letterSpacing="0.5px"
            >
              {"Ajay saves"} <Strong>{"₹10,000"}</Strong>{" "}
              {
                "in premium per year for choosing our value plan with a deductible of"
              }{" "}
              <Strong>{"₹35,000."}</Strong> {"If there are no claims for"}{" "}
              <Strong>{"4 years:"}</Strong>
            </Ptag>
          </WhyHaveDeductibleText>

          <ImageContainer>
            <Image2 />
          </ImageContainer>
        </WhyHaveDeductible1680>

        {/* <img src={smartPlan2} alt="thumbpage4.png" /> */}
      </Wrapper>
    );
  };
}
