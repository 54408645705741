import React, { Component } from "react";
import styled from "styled-components";
import Icons from "../icons";
import {Ptag} from '../fonts'
import vitalcoin from '../../assets/images/vital-coin.png'
import rewards from '../../assets/images/rewardsSnap.png'

export default function WellnessRewards({showPopup = false, ...props}){


    return(
        <Home show={showPopup}>
             <Header>
                <IconDiv onClick={() => props.onClosePopup()}>
                    <Icons icon="cross" fill="#7d7c7c" height="24" width="24" />
                </IconDiv>
            </Header>
            <Body>
                <Ptag fontWeight='600' fontSize='20px' letterSpacing='0.5px' color='#436ab2'>
                    {'Wellness Rewards'}
                </Ptag>
                <Ptag fontSize='14px' letterHeight='2' letterSpacing='0.5px' color='#666666'>
                    {'Say hello to Vital Points'}
                </Ptag>
                <Ptag style={{'margin':'18px 0 12px 0'}} fontSize='14px' letterHeight='2' letterSpacing='0.5px' color='#666666'>
                    {'Use Vital Points to pay for product and services in our Health Marketplace'}
                </Ptag>

                <VitalCashDiv>
                    <img src={vitalcoin} height='16px' width='16px' />
                    <Ptag fontSize='16px' fontWeight='600' letterHeight='1.5' letterSpacing='0.57px' color ='#666666'>
                        {'1 = ₹1'}
                    </Ptag>
                </VitalCashDiv>

                <Ptag fontSize='14px' fontWeight='600' letterHeight='1.71' letterSpacing='0.5px' color='#666666'>
                    {'Apply at the time of checkout'}
                </Ptag>
                <img style={{'marginTop': '14px', 'alignSelf': 'center'}} src={rewards} width='250px' height='337px'/>
            </Body>
            
        </Home>
    )
}


const Home = styled.div`
width: 100%;

overflow-y: scroll;
-webkit-overflow-scrolling: touch;
background: #fff;
position: fixed;
top: 0;
left: 0;
z-index: 20;
transform: ${props =>
  props.show
    ? "translateY(0) scaleY(100%)"
    : "translateY(102%) scaleY(0)"};
transition: all 0.6s;
min-height: 100vh;
`;

const IconDiv = styled.div`
  display: flex;
  position: absolute;
  right: 0px;
  margin: 18px 12px 0px 0px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  height: 56px;
  background-color: #ffffff;
`;


const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 24px;
`

const VitalCashDiv = styled.div`
    display: flex;
    margin: 12px 0 34px 0;
    & > :first-child{
        margin-top: 4px;
        margin-right: 4px;
    }
`