import styled from "styled-components";
import { colors } from "../../theme";

export const Link = styled.a`
  color: ${colors.linkColor};
  font-family: "Avenir";
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
`;

export const HeaderRow = {
  backgroundColor: "rgb(241, 243, 246)",
  borderBottom: "none",
  fontFamily: "Avenir",
  whiteSpace: "nowrap",
  color: colors.primaryColor,
  paddingTop: "24px",
  paddingBottom: "24px",
  overflow: "hidden",
  wordWrap: "break-word",
};

export const MaterialTable = {
  tableLayout: "fixed",
  border: "none",
  borderCollapse: "separate",
  "*": {
    fontSize: "14px !important",
  },
};

export const MaterialTableContainer = {
  borderRadius: "14px !important",
  scrollbarWidth: "thin",
};
