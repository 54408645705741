import React, { Component } from 'react'
import styled from 'styled-components'
import {Header} from '../../components/business'
import {Ptag} from '../../components/fonts'
import {Input, LargeButton, OverlayLoader} from '../../components'
import { Formik, Form, FieldArray } from 'formik'
import * as Yup from 'yup'
import http from '../../services/httpService'
import TagManager from 'react-gtm-module'
import {gtmCode} from '../../utils/utils'


export default class GSTno extends Component{

    state= {
        loader: false
    }

    componentDidMount(){
        const tagManagerArgs = {
            gtmId: gtmCode(),
        }
        TagManager.initialize(tagManagerArgs)
    }


    handleSubmit = async (values) => {
        this.setState({loader: true})
        const id = Number(localStorage.getItem('companyId'))
        try{
            await http.patch(`/business/company/${id}/`,{
                gst_no: values['gst'],
            })
            this.props.history.push('/business/insureplansummary')
        }
        catch(ex){
            console.error(ex)
        }
        this.setState({loader: false})
        
    }

    handleChange = (event, setFieldValue, name, length) => {
        const value = event.target.value
        setFieldValue(name, value.slice(0, length))
    }

    renderInput = ({name, placeholder, type, formikProps, length=15} = {}) => {
        const {errors, touched, handleBlur, values, setFieldValue} = formikProps
        const error = touched[name] && errors[name]
        return(
            <InputContainer>
                <Input value={values[name]} name={name} placeholder={placeholder} type={type} error={error}
                    onChange={(event) => this.handleChange(event, setFieldValue, name, length)}
                    onBlur={handleBlur}/>
            </InputContainer>
        )
    }

    renderForm = (formikProps) => {
        return(
            <FormContainer>
                <Form>
                    {this.renderInput({name:'gst', placeholder:'enter GST no', type:'text', formikProps,})}
                    <ButtonWrapper>
                        <LargeButton id="business_gst_next" label= {'NEXT'} type="submit"/>
                    </ButtonWrapper>
                    
                </Form>
                
            </FormContainer>
        )
    }


    render(){
        const initialValues = {
            gst: '',
        }
        const Schema = Yup.object().shape({
            gst: Yup.string().required('*Required').
            test('len', 'gst no must be exactly 15 digits', val => String(val).length === 15)
          })
        return(
            <Root>
                <Header onClickBack={() => this.props.history.push('/business/enrollmentprocess')}  progressPercentage={70}/>
                <Body>
                    <Ptag fontFamily='Proxima Nova' fontSize='24px' letterHeight='1.33' letterSpacing='0.35px' color='#545454'>
                        {'enter GST number'}
                    </Ptag>

                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}
                        validationSchema={Schema}
                        onSubmit={this.handleSubmit}
                        render={formikProps => this.renderForm(formikProps)}
                    />
                </Body>
                {this.state.loader && <OverlayLoader/>}
            </Root>

        )
    }

}

const Root = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100vh;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    flex: 1;
`

const FormContainer = styled.div`
    display:flex;
    flex-direction: column;
    margin-top: 34px;
    flex:1;
    & > form{
        display: flex;
        flex-direction: column;
        flex:1;
    }
`;

const InputContainer = styled.div`
    display: flex;
    margin-bottom: 4px;
    
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex:1;
    align-items: flex-end;
`