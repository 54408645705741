import React, { Component } from 'react';
import styled from 'styled-components';
import arrowBack from '../../assets/images/arrowBack.png'
import {Pagination} from './'
import { withRouter } from "react-router";

class Header extends Component{

	render(){
		return	(<Root>
			{this.props.onClickBack  && 
				<BackButton onClick={(oEvent)=>this.props.onClickBack(oEvent)}>
					<BackImage src={arrowBack} />
				</BackButton>
			}
			{
				this.props.headerText && 
				<HeaderText>{this.props.headerText.toLowerCase()}</HeaderText>
			}
			{
                !!this.props.progressPercentage &&
                <Pagination progress={this.props.progressPercentage} />
                
			}
		</Root>)
	}
}



export default withRouter(Header);

Header.defaultProps  = {
	closebutton: false,
	headerText : "",
    backButton : true,
    proressPercentage: 0
}

const Root = styled.div`
	width: 100%;
	height: 5.6rem;
	background-color: #ffffff;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	display: flex;
  
`;

const BackButton = styled.div`
	margin-left: 1.6rem;
	margin-top: 1.6rem;
`

const BackImage = styled.img`
	width: 2.4rem;
	height: 2.4rem;
`;

const HeaderText = styled.p`
	text-transform: capitalize;
	padding : 1.9rem 2rem 1.9rem 1.4rem;
	font-family: Open Sans;
	font-size: 14px;
	font-weight: 600;
  	color: #436ab2;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: normal;
	letter-spacing: 1px;
	text-align: center;
	color: ${props => (props.color ? props.color : '#4a4a4a')};
	text-overflow: ellipsis;
	white-space: nowrap;
    overflow: hidden;
`;

