import React from 'react'
import styled from 'styled-components'

const Home = styled.div`
  padding: 1rem 2rem;
  border-radius: 20px;
  background: #fff;
  border: 1px solid #436ab2;
  backface-visibility: hidden;

  position: fixed;
  top: 80%;
  left: 50%;
  z-index: ${props => (props.show ? 10 : '-1')};

  transform: ${props => (!props.show ? 'translate(-50%, 120%) scale(0.5)' : 'translate(-50%, -150%) scale(1)')};
  transform: ${props =>
    !props.show ? '-webkit-translate(-50%, 120%) scale(0.5)' : '-webkit-translate(-50%, -150%) scale(1)'};
  opacity: ${props => (!props.show ? '0' : '1')};
  transition: all 0.5s ease-in;
`

const Font12 = styled.p`
  font-size: 1.2rem;
  font-family: Proxima Nova;
  color: #436ab2;
  font-weight: bold;
  font-style: normal;
  letter-spacing: 2px;
`

const Toast = ({ showToast, message }) => (
  <Home show={showToast}>
    <Font12>{message}</Font12>
  </Home>
)

export default Toast
