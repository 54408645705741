import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import ArrowBack from '../../assets/images/arrowBack.png'
import { withRouter } from 'react-router'
import http from '../../services/httpService'
import BusyIndicator from '../../components/busyIndicator.jsx'
import cloneDeep from 'lodash/cloneDeep'
import BankRow from '../../components/memberApp/payment/BankRow.js'
import all_nb_bank_names from '../../components/memberApp/constants/AllNBBankConstants.js'
import PaymentBreakup from './paymentBreakup'

class BanksList extends Component {
  state = {
    netBanking: false,
    debitCard: true,
    method: 2,
    data: {},
    options: {},
    loader: false,
    voucherError: '',
    nb_data: {}
  }
  componentDidMount = async () => {
    if (localStorage.getItem('paymentData')) {
      const data = JSON.parse(localStorage.getItem('paymentData'))
      this.setState({ data })
    }
    if (localStorage.getItem('nb_data')) {
      const nb_data = await JSON.parse(await localStorage.getItem('nb_data'))
      await this.setState({ nb_data: nb_data })
    }
  }

  onSelectBank = async idx => {
    // store bankname in local storage
    // if (idx !== 28) {
      //not 'other' bank option
      await localStorage.setItem(
        'nb_data',
        JSON.stringify({
          method: 1,
          plan: 1,
          payment_mode: 'NB',
          bank_name: all_nb_bank_names[idx].bank_name,
          premium: this.state.nb_data.premium,
          discount: this.state.nb_data.discount
        })
      )
      this.props.history.push({
        pathname: '/business/subscriptionpayment',
        bank_name: all_nb_bank_names[idx].bank_name,
        showNetBanking:true
      })
    }
      // }
  //   } else {
  //     //other option
  //       this.setState({ loader: true })
  //     let data = {
  //       method: 1,
  //       plan: 0,
  //       payment_mode: 'NB',
  //       bank: all_nb_bank_names[idx].bank_name,
  //       premium: this.state.nb_data.premium,
  //       discount: this.state.nb_data.discount
  //     }
  //     localStorage.setItem('nb_data', JSON.stringify(data))

  //     // TODO
  //     // take to razorpay normal option
  //     this.onPay_normal_nb(data)
  //   }
  // }
  get_bank_rows() {
    // let credit_suggestion_wrapper_div = []
    // this.props.credit.suggested_credit.map((credit, key) =>
    //   credit_suggestion_wrapper_div.push(<SuggestedCreditWrapperDiv suggested_credit={credit} />)
    // )
    let div = []
    var banks = cloneDeep(all_nb_bank_names)
    // remove "others" option from bank list for subscription payment
    if (localStorage.getItem('via_subscription') === 'true') {
      banks = all_nb_bank_names.slice(0, -1)
    }

    banks.map((bank, key) =>
      div.push(
        <BankRow
          bank={bank}
          key={key}
          idx={key}
          onSelectBank={idx => {
            this.onSelectBank(idx)
          }}
        />
      )
    )

    return div
  }

  // MAKE MORMAL OTHERS MODE PAYMENT AFTER CHOOSING NB
  onPay_normal_nb = async data => {
    if ('smartech' in window) {
      window.smartech('dispatch', 3, [])
    }

    const plan = localStorage.getItem('userplan_id')
    await localStorage.setItem('premium', this.state.data.premium)
    let body = { 
      userplan: plan,
      payment: {
        bank: data.bank,
        method: data.method,
        plan: data.plan,
        account_number: '',
        ifsc: ''
      }
    }
    // }
    const _this = this
    try {
      let response = await http.post('/business/subscription/', { ...body })
      localStorage.setItem('vital_subscription_id', response.data.vital_subscription_id)

      const options = {
        async handler(response) {
          await http.post('/payment/verify/', {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            razorpay_order_id: response.razorpay_order_id
          })
          await localStorage.setItem('razorpay_payment_id', response.razorpay_payment_id)
          _this.props.history.push('/business/paymentsuccess')
        },
        ...response.data
      }
      this.setState({ loader: false })
      const rzp = new window.Razorpay(options)
      rzp.open()
    } catch (ex) {
      console.log('Error-Ex: ', ex)

      if (ex.status) console.log('Error-Ex-status: ', ex.status)
    }
  }

 

  render() {
    let bank_rows = []
    bank_rows = this.get_bank_rows()
    let home_data
    if (this.state.loader) {
      home_data = (
        <HomeWrapper>
        <OuterDiv style={{ width: '100%', height: '100vh', 'justify-content': 'center', 'align-items': 'center' }}>
          <BusyIndicator />
        </OuterDiv>
        <PaymentBreakup disablePayButton={sessionStorage.getItem('payButton')}></PaymentBreakup>
        </HomeWrapper>
      )
    } else {
      home_data = (
        <HomeWrapper>
        <OuterDiv>
          <BanksDiv>{all_nb_bank_names.length !== 0 && bank_rows}</BanksDiv>
        </OuterDiv>
        <PaymentBreakup ></PaymentBreakup>
        </HomeWrapper>
      )
    }
    return home_data
  }
}

const HomeWrapper = styled.div`
  width:100%
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  `
const OuterDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  height: 100%;
  background:white
  box-shadow: rgb(0 0 0 / 40%) 0px 1px 4px 0px;
`

const NavDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 56px;
  width: 100%;
  padding: 0 24px 0 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
`

const BackIcon = styled.img`
  width: 24px;
  height: 24px;
  align-items: center;
  background: cover;
`
const BanksDiv = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
`


export default withRouter(BanksList)
