import React from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { ErrorMessage } from 'formik'

const DateWrapper = styled.div`
  width: 100%;
  margin-top: 3.6rem;
  position: relative;
`

const InputWrapper = styled.div`
  // display: flex;
  position: relative;
`

const Input = styled.input`
  width: 100%;
  -webkit-appearance: none;
  font-size: 1.6rem;
  font-family: Avenir;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: ${props => props.color ? props.color : props.disabled ? 'grey' : '#009999'};
  border: 0;
  border-radius: 0;
  border-bottom:  ${props => props.border ? props.border : props.disabled ? '2px solid grey' : '2px solid #436ab2'}; 
  -webkit-text-fill-color: ${props => props.disabled ? 'rgba(0, 0, 0, 0.54)' : ''} ;
   -webkit-opacity: 1; 
   background: white;
  padding-left: 5px;
  padding-bottom: 10px; // 5px;
  // margin-bottom: 5px;
  background: none;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-text-fill-color: ${props => (props.color ? props.color : '#009999')};
    transition: background-color 5000s 0s;
  }

  &:focus {
    outline: none;
    background: none !important;
  }
`

const AgeWrapper = styled.div`
  // padding: 4px 4px 10px 4px;
  position: absolute;
  top: 0;
  right: 0;
`
const Font16 = styled.p`
  font-size: 1.6rem;
  font-family: Avenir;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  // text-align: left;
  color: ${props => props.disabled ? 'grey' : '#009999'};
`

const Error = styled.p`
  color: red;
  font-size: 1.4rem;
  margin-top: 0.8rem;
  z-index: 9;
  position: absolute;
  // @media (max-width: 768px) {
  //   font-size: 14px;
  //   padding-top: 4px;
  // }
  // @media (min-width: 769px) {
  //   font-size: 14px;
  //   padding-top: 4px;
  // }
  // @media (max-width: 400px) {
  //   font-size: 10px;
  //   padding-top: 4px;
  // }
`

const DateField = ({ value, error, placeholder,disabled, errorName, ...restProps }) => {

  const age = ( function (DateParam){
    if (!DateParam || DateParam.length !== 10) return
    let age = moment().diff(moment(DateParam.split('/').reverse().join(), 'YYYYMMDD'), 'years') 
    if (age && age <=120)
    return age
  })(value)

  return (
    <DateWrapper>
      <InputWrapper>
        <Input name="date" type="text" size='10' placeholder={placeholder} value={value} disabled={disabled} {...restProps} />
        {(age && age >= 0) || age === 0 ? (
          <AgeWrapper>
            <Font16 disabled={disabled}>{age} years</Font16>
          </AgeWrapper>
        ) : null}
      </InputWrapper>
      {error && <Error>{error}</Error>}
      {errorName && <ErrorMessage name={errorName}>
            {msg => <Error>{msg}</Error>}
          </ErrorMessage>}
    </DateWrapper>
  )
}

export default DateField
