import React from 'react'
import styled from 'styled-components'
import w0 from '../assets/svgs/w0.svg'
import w30 from '../assets/svgs/w30.svg'
import w2 from '../assets/svgs/w2.svg'
import w4 from '../assets/svgs/w4.svg'

const Home = styled.div`
  display: flex;
  overflow-x: auto;
  & > div {
    display: inline-block;
  }

  & > :last-child {
    div {
      border: none;
    }
  }

  //Chrome, Safari, newer versions of Opera
  ::-webkit-scrollbar {
    display: none;
  }
  //Firefox
  overflow: -moz-scrollbars-none;
  //Internet Explorer +10
  -ms-overflow-style: none;
`

const ImgWrap = styled.div`
  width: 10rem;
  // height: 10rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.2rem 0;
  border-right: 1px solid #e8e8e8;

  & img {
    width: 4.8rem;
    height: 4.8rem;
    margin-bottom: 1rem;
  }
`

const Font12 = styled.p`
  font-family: Avenir;
  font-size: 1.2rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #4a4a4a;
`

const WaitingPeriod = props => {
  return (
    <Home>
      <div>
        <ImgWrap>
          <img src={w0} alt="hospital.png" />
          <Font12>Accidental Coverage</Font12>
        </ImgWrap>
      </div>
      <div>
        <ImgWrap>
          <img src={w30} alt="ambulance.png" />
          <Font12>Hospitalization Expenses</Font12>
        </ImgWrap>
      </div>
      <div>
        <ImgWrap>
          <img src={w2} alt="dayCare.png" />
          <Font12>13 Listed Conditions </Font12>
        </ImgWrap>
      </div>
      <div>
        <ImgWrap>
          <img src={w4} alt="PRE_POST.png" />
          <Font12>Pre-existing Diseases </Font12>
        </ImgWrap>
      </div>
    </Home>
  )
}

export default WaitingPeriod
