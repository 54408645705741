
import React, { Component, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Ptag } from '../../components/fonts'
import { LargeButton } from '../../components'
import PaymentBreakup from './paymentBreakup'
import Icons from "../../components/icons";
import {useHistory} from 'react-router-dom'
import { connect } from 'react-redux'
import { DisclaimerPopup } from '../../components/business'
import {RepaymentDisclaimer, RenewPolicyDisclaimer} from '../../constants/business'


const PaymentPage = (props) => {
    const [color,setColor]=useState('')
    const [autoPay,setAutoPay]=useState(false)
    const [selfPay,setSelfPay]=useState(false)
    const [showDisclaimer, setDisclaimer] = useState(false)
    const history = useHistory()
    const planDetails = JSON.parse(localStorage.getItem('user_plan_details'))
    const { policy: policyRenewalState} = planDetails
    useEffect(() => {
      window.scrollTo(0,0)
    },[])

    const onClickNoForAutopay = async () => {
      props.changePaymentMode(true)
         setSelfPay(true)
        setColor('#436AB2')
         setAutoPay(false)
        
      };
      const onClickYesForAutopay = () =>{
        props.changePaymentMode(false)
        setAutoPay(true)
        setColor("#436AB2")
        setSelfPay(false)
        setTimeout(() => {
          history.push('/business/subscriptionpayment')  
        },200);
      };
    return (
      <>
        <PaymentBox>
          {
            showDisclaimer &&
            <DisclaimerPopup messages={policyRenewalState === 'Renew' ? RenewPolicyDisclaimer : RepaymentDisclaimer} onClose={() => setDisclaimer(false)} />
          }
          
            <HomeWrapper>
              <CheckBoxWrapper style={{overflowX:'scroll'}}>
                <Heading>
                    <Ptag fontSize="24px" fontWeight="600" >Payment Method</Ptag>
                </Heading>
                <div style={{display:'flex',flexDirection:'row'}}>
                <CheckboxWrapper style={{maxWidth:343,width:'100%'}}>
                    <CheckboxInner style={{ marginRight: '25px',background:selfPay?'#436AB2':'white' }} onClick={onClickNoForAutopay}>
                      <Circle style={{border:selfPay?'1px solid white':'1px solid #436AB2'}}>
                        <Icons
                            height='19'
                            width='19'
                            icon={selfPay?'checkedCircle':"unCheckedCircle"}
                        >
                        </Icons>
                      </Circle>
                        <Ptag fontSize="16px" color={selfPay ?"white":'#436AB2'} fontWeight="600" letterHeight="24px" letterSpacing="0.57px" marginTop="10px">Pay Every Month</Ptag>
                        <Ptag fontSize="14px" color={selfPay ?"white":'#666666'} fontWeight="regular" letterHeight="24px" letterSpacing="0.34px" marginTop="10px">You will have to make a payment manually every month.</Ptag>
                    </CheckboxInner>
                </CheckboxWrapper>
                <CheckboxWrapper style={{maxWidth:343,width:'100%'}}>
                    <CheckboxInner style={{background:autoPay?'#436AB2':"white"}}   onClick={onClickYesForAutopay}>
                      <Circle style={{border:autoPay?'1px solid white':'1px solid #436AB2'}}>
                        <Icons
                            height='19'
                            width='19'
                            icon={autoPay?'checkedCircle':"unCheckedCircle"}
                        >
                        </Icons>
                      </Circle>
                        <Ptag fontSize="16px" color={autoPay ?"white":'#436AB2'} fontWeight="600" letterHeight="24px" letterSpacing="0.57px" marginTop="10px">Auto Debit</Ptag>
                        <Ptag fontSize="14px" color={autoPay ?"white":'#666666'} fontWeight="regular" letterHeight="24px" letterSpacing="0.34px" marginTop="10px">Your subscription fee will be automatically debited every month.</Ptag>
                    </CheckboxInner>
                    
                </CheckboxWrapper>
                </div>
              </CheckBoxWrapper>
            </HomeWrapper>
            <PaymentBreakup setDisclaimer={setDisclaimer} />
        </PaymentBox>
      </>
    )
}
const mapStateToProps = (state) =>{
  return{
    name:state
  }
}
const mapDispatchToProps = (dispatch) =>{
  return{
    changePaymentMode:(value)=>{dispatch({type:'ChangeSelfpay',payload:value})}
  }

} 
 
export default connect(mapStateToProps,mapDispatchToProps)(PaymentPage)



const PaymentBox = styled.div`
      display: flex;
      flex-direction: row;
`

const HomeWrapper = styled.div`
  transition: margin-left .2s ease-in;
  width:65%;
  background-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(0 0 0 / 40%) 0px 1px 4px 0px;
`

const CheckBoxWrapper = styled.div`
  transition: margin-left .2s ease-in;
  padding: 30px 46px;
  width:100%;
  background-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ::-webkit-scrollbar {
    display: none;
  }
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
`

const Circle =styled.div`
  width:21px;
  height:21px;
  border-radius: 100%;
  border:1px solid #436AB2;
`

const Heading = styled.div`
padding-bottom:20px;
   
`

const CheckboxInner = styled.div`
    box-shadow: rgb(0 0 0 / 40%) 0px 1px 4px 0px;
    padding: 24px 76px 48px 18px;
    border: 1.5px solid #436AB2;
  border-radius: 10px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 6px 0 rgba(0,0,0,0.13);
    
    width:343px;
    height:188px;
`

const CheckboxWrapper = styled.div`
  display:flex;
  cursor: pointer;

`