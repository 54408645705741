import React from "react";
import { render } from "react-dom";
import styled from "styled-components";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import NBPaymentScrollDiv from "../../../components/memberApp/payment/NBPaymentScrollDiv";
import nb_bank_names from "../../../components/memberApp/constants/BankConstants.js";
import {
  CardMode,
  PaymentMethod,
  SubscriptionPlan,
} from "../constants/Payment";
import LoaderDots from "../../../components/loaderDots";
import side_arrow from "../../../assets/svgs/side_arrow.svg";
import { PageView, initGA, Event } from "../../../Tracking";

export default class NetBankingCardContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bank: "",
      show_nb_div: false,
      branch_ifsc_list: [],
      nb_bank_branches: [],
      ifsc_codes: [],
      ifsc: "",
      selected_idx_nb: "",
      branchname: "",
      isLoading: false,
      plan: -1,
      is_bank_slected: false,
      previous_selected_bank_name: "",
      current_selected_bank_name: "",
      loader: false,
    };
  }
  static MoreBankIdx = 5;

  componentDidMount() {}

  chose_bank_recurring(body) {
    this.setState({ plan: 1 });
    this.props.onPay({
      method: 1,
      plan: 1,
      payment_mode: "NB",
      bank_name: this.state.bank,
      payment: body,
    });
  }
  chose_bank_normal(body) {
    this.setState({ plan: 0 });
    this.props.onPay({
      method: 1,
      plan: 0,
      payment_mode: "NB",
      bank_name: this.state.bank,
      payment: body,
    });
  }

  setParams = (formProps, values) => {
    formProps.setFieldValue("ifsc", values.ifsc);
    formProps.setFieldValue("branchname", values.branchname);
    this.setState({
      ifsc: values.ifsc,
      branchname: values.branchname,
    });
  };
  save_nb_payment_data_inlocalStorage = (idx) => {
    if (idx !== 5) {
      //last index of
      localStorage.setItem(
        "nb_data",
        JSON.stringify({
          method: 1,
          plan: 1,
          payment_mode: "NB",
          bank_name: nb_bank_names[idx].bank_name,
          premium: this.props.premium,
          discount: this.props.discount,
          points_applied: this.props.points_applied
        })
      )
    } else {
      localStorage.setItem(
        "nb_data",
        JSON.stringify({
          payment_mode: "NB",
          premium: this.props.premium,
          discount: this.props.discount,
          points_applied: this.props.points_applied
        })
      );
    }
  };

  render() {

    return (
      <ContentDiv>
        {/* <Text3>Available Banks</Text3> */}
        <ScrollWrapper>
          <NBPaymentScrollDiv
            bank_names={nb_bank_names.slice(0, -1)}
            selected_idx={this.props.selected_idx_nb}
            onSelectBank={(idx) => {
              //this.onSelectBank(idx)
              this.save_nb_payment_data_inlocalStorage(idx);
              this.props.onSelectNBBank(idx);
            }}
          />
        </ScrollWrapper>
        <LineDivv />
        <ParentDiv
          onClick={() => {
            //this.onSelectBank(idx) more banks index is 5 in nb_banks_constant array .
            this.save_nb_payment_data_inlocalStorage(5);
            this.props.onSelectNBBank(5);
          }}
        >
          <Footer>
            <Text>
              More Banks
              </Text>
            <ImageDiv >
              <img src={side_arrow} />
            </ImageDiv>
          </Footer>
          
        </ParentDiv>
      </ContentDiv>
    );
  }
}

const ParentDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ImageDiv = styled.div`
  display: flex;
  padding:0px 12px 10px 0px
  /* padding-right: 16px; */
  /* margin-top: 9px; */
  /* margin-right: 24px; */
  /* align-items: right; */
`;

const Footer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const Text = styled.p`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-family: Avenir;
  /* margin: 9px 0px 24px 24px; */
  padding: 9px 16px 24px 24px;
  font-size: 14px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 1px;
`;

const LineDivv = styled.div`
  display: flex;
  margin: 0px 24px 0px 24px;
  height: 1px;
  border-radius: 7px;
  background-color: #d0d0d0;
`;

const Text2 = styled.p`
  font-family: Avenir;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: 0.38px;
  text-align: left;
  color: #707070;
`;
const Button = styled.button`
  display: flex;
  width: 100%;
  text-align: center;
  margin: 12px 0px 0 0px;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  box-shadow: none;
  outline: none;
  elevation: 0;
  height: 54px;
  border-radius: 15px;
  background-color: #436ab2;
`;
const LineDiv = styled.div`
  display: flex;
  width: 100%;
  margin-right: 36px;
  margin-left: 36px;
  margin-bottom: 24px;
  height: 1px;
  border-radius: 7px;
  background-color: #dcdcdc;
`;


const ScrollWrapper = styled.div`
  display: flex;
  padding-left: 11px;
  padding-right: 11px;
  padding-top: 18px;
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border-top: solid 1px #9b9b9b;
`