import React, { Component } from 'react'
import styled from 'styled-components'
import thumbpage5 from '../assets/images/landingpage3/thumbpage5.png'
import dotspage5 from '../assets/images/landingpage3/dotspage5.png'
import tableText2 from '../assets/images/tableText2.png'
import tableText3 from '../assets/images/tableText3.png'

const Wrapper = styled.div`
  width: 100%;
  padding: 3.6rem 0 4.8rem 0;
  background: rgba(238, 238, 238, 1);
  position: relative;

  img:first-of-type {
    position: absolute;
    z-index: 10;
    right: 5%;
    top: 15%;
  }

  img:nth-of-type(2) {
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 16%;
  }

  @media (min-width: 1023px) {
    padding: 6.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const Header = styled.div`
  width: 32rem;
  padding-left: 2.4rem;
  margin-bottom: 3rem;

  h2 {
    margin-bottom: 1.2rem;
  }
  @media (min-width: 1023px) {
    padding-left: 0;
    width: 40rem;
    margin-right: 50px;

    p,
    h2 {
      width: 30rem;
    }
  }

  @media (min-width: 1680px) {
    width: 56rem;
    p,
    h2 {
      margin-bottom: 4.8rem;
      width: auto;
    }
  }
`

const Image = styled.div`
  background: url(${"http://images.getvital.in/homelanding/tableText3.png"});
  background-size: cover;
  width: 35rem;
  height: 38rem;
  margin: 0 auto;

  @media (min-width: 1024px) {
    margin: 0;
    background: url(${"http://images.getvital.in/homelanding/tableText3.png"});
    background-size: cover;
    width: 60rem;
    height: 65.65rem;
  }
  @media (min-width: 1680px) {
    background: url(${"http://images.getvital.in/homelanding/tableText3.png"});
    background-size: cover;
    width: 60rem;
    height: 65.65rem;
  }
`

const Font28 = styled.p`
  font-family: Proxima Nova;
  font-size: 2.4rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    width: 100%;
    font-size: 3.4rem;
    line-height: 1.29;
    letter-spacing: 0.02px;
  }

  @media (min-width: 1680px) {
    font-size: 6rem;
    letter-spacing: 0.03px;
  }
`

export default class Page35 extends Component {
  render = () => (
    <Wrapper>
      <Header>
        <Font28>See all differences <br />in one place</Font28>
      </Header>
      <Image alt="health insurance plans"/>
      <img src={dotspage5} alt="dotspage5" />
      <img src={thumbpage5} alt="thumbpage5.png" />
    </Wrapper>
  )
}
