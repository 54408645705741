import React, { Component } from 'react'
import { withRouter } from 'react-router'

import styled from 'styled-components'
import CollapsibleDiv from './CollapsibleDiv.js'
import DebitCollapsibleDiv from './DebitCollapsibleDiv.js'
import CreditCollapsibleDiv from './CreditCollapsibleDiv.js'
import NetBankingCollapsibleDiv from './NetBankingCollapsibleDiv.js'
import WalletCollapsibleDiv from './WalletCollapsibleDiv.js'
import {sendActivityLSApi} from '../../../services/leadSquaredService'



let paymentMap={
  1:'Credit_Card',
  2:'Debit_Card',
  3:'Net_Banking',
  4:'Upi',
  5:'Wallet'
}

class PaymentAccordion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      clickedIdx: 3,
      openedIdx: 3,
      selected_index_nb: '',
      selected_index_credit: '',
      selected_index_debit: ''
    }
  }

  toggleOpen = async idx => {
    //console.log('clicked no. is :' + idx)
    let payment_method =paymentMap[idx];
    localStorage.setItem('payment_method',payment_method);
    if (idx !== this.state.clickedIdx && this.state.openedIdx !== idx) {
      // console.log('idx ++: ' + idx)
      // console.log('clickedIdx ++ : ' + this.state.clickedIdx)
      await this.setState({ clickedIdx: idx })
      await this.setState({ openedIdx: idx })
      //console.log('openedIdx : ' + this.state.openedIdx)
    } else if (idx === this.state.clickedIdx && this.state.openedIdx === idx) {
      //console.log('clickedIdx via 2 : ' + idx)
      await this.setState({ openedIdx: -1 })
    } else {
      //console.log('clickedIdx via 3 : ' + idx)
      await this.setState({ openedIdx: idx })
      await this.setState({ clickedIdx: idx })
    }
    //console.log('*********************************')
  }

  onSelectDebitBank(selected_idx) {
    console.log('print debit bank :', selected_idx)
    this.setState({
      selected_index_debit: selected_idx
    })
  }
  onSelectCreditBank(selected_idx) {
    console.log('print credit bank :', selected_idx)
    this.setState({
      selected_index_credit: selected_idx
    })
  }

  onSelectNBBank(selected_idx) {
    this.setState({
      selected_index_nb: selected_idx
    })
    const insurance = JSON.parse(localStorage.getItem('paymentData') || '{}')
    const {type, deductable, insured_sum, renewal} = insurance
    const members = Object.values(insurance['familyMembersDetails'] || {}).map(item => item['relation'])
    if(!renewal){
      const {type, deductable, insured_sum, renewal} = insurance

      sendActivityLSApi(224, {
        mx_Custom_1: type,
        mx_Custom_2: members.join(', '),
        mx_Custom_3: insured_sum,
        mx_Custom_4: deductable,
      })
    }
    
    if (selected_idx === -1) return
    else if (selected_idx === 5) {
      this.props.history.push({ pathname: '/nb_banks' })
      localStorage.setItem('via_payment', "true")
      localStorage.setItem('recurring',true)
    } else {
      this.props.history.push({ pathname: '/nb_details' })
      // store bankname in local storage
      localStorage.setItem('via_payment_to_details', "true") //for navigation sequence
    }
  }

  render() {
    return (
      <ParentDiv>
        {/* <CreditCollapsibleDiv
          title={'CREDIT CARD'}
          key={1}
          idx={1}
          openDiv={this.state.clickedIdx}
          onPay={values => this.props.onPay(values)}
          open={this.state.clickedIdx === 1 && this.state.openedIdx === 1}
          close={this.state.clickedIdx != 1 && this.state.openedIdx != 1}
          togglePanel={idx => this.toggleOpen(idx) && this.state.closeIdx}
          selected_idx_credit={this.state.selected_index_credit}
          onSelectCreditBank={idx => this.onSelectCreditBank(idx)}
          premium={this.props.premium}
          discount={this.props.discount}
          route={'/pay'}
        /> */}
        {/* <DebitCollapsibleDiv
          title={'DEBIT CARD'}
          key={2}
          idx={2}
          openDiv={this.state.clickedIdx}
          onPay={values => this.props.onPay(values)}
          open={this.state.clickedIdx === 2 && this.state.openedIdx === 2}
          close={this.state.clickedIdx != 2 && this.state.openedIdx != 2}
          togglePanel={idx => this.toggleOpen(idx) && this.state.closeIdx}
          selected_idx_debit={this.state.selected_index_debit}
          onSelectDebitBank={idx => this.onSelectDebitBank(idx)}
          premium={this.props.premium}
          discount={this.props.discount}
          recurring={this.props.recurring}
        /> */}
        {
          this.props.enableEmandate &&
          <NetBankingCollapsibleDiv
            title={'SELECT BANK'}
            key={3}
            idx={3}
            openDiv={this.state.clickedIdx}
            onPay={values => this.props.onPay(values, 'NET BANKING')}
            open={true}
            togglePanel={idx => this.toggleOpen(idx)}
            selected_idx_nb={this.state.selected_index_nb}
            onSelectNBBank={idx => this.onSelectNBBank(idx)}
            premium={this.props.premium}
            discount={this.props.discount}
            recurring={this.props.recurring}
            is_points_applied = {this.props.is_points_applied}
          />
        }
        {
          this.props.enableUPI && 
          <CollapsibleDiv
            title={'UPI'}
            key={4}
            idx={4}
            openDiv={this.state.clickedIdx}
            onPay={values => this.props.onPay(values, 'UPI')}
            open={this.state.clickedIdx === 4 && this.state.openedIdx === 4}
            togglePanel={idx => this.toggleOpen(idx)}
            premium={this.props.premium}
            discount={this.props.discount}
            upi={true}
          />
        }
        
        {/* <WalletCollapsibleDiv
          title={'WALLETS'}
          key={5}
          idx={5}
          openDiv={this.state.clickedIdx}
          onPay={values => this.props.onPay(values)}
          open={this.state.clickedIdx === 5 && this.state.openedIdx === 5}
          togglePanel={idx => this.toggleOpen(idx)}
          premium={this.props.premium}
          discount={this.props.discount}
        /> */}
      </ParentDiv>
    )  
  }
}

const ParentDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding:0px 24px 18px;
`
export default withRouter(PaymentAccordion)

PaymentAccordion.defaultProps = {
  enableUPI: true,
  enableEmandate: true,
}