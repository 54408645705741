import React, { Component } from "react";
import styled from "styled-components";
import { Header } from "../../components/business";
import { Ptag } from "../../components/fonts";
import {
  Input,
  LargeButton,
  OverlayLoader,
} from "../../components";
import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import http from "../../services/httpService";
import TagManager from "react-gtm-module";
import { gtmCode } from "../../utils/utils";
import industries from "../../utils/industries";
import Icon from '../../components/icons'


export default class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    const tagManagerArgs = {
      gtmId: gtmCode(),
    };
    sessionStorage.removeItem('token')
    TagManager.initialize(tagManagerArgs);
    localStorage.removeItem("token");
    let phone = localStorage.getItem("phone");
    if (!phone) this.props.history.push("/business/phone");
  }

  handleChange = (event, setFieldValue, name, length) => {
    const value = event.target.value;
    setFieldValue(name, value.slice(0, length));
  };

  renderInput = ({
    name,
    placeholder,
    type,
    formikProps,
    length = 70,
  } = {}) => {
    const { errors, touched, handleBlur, setFieldValue, values } = formikProps;
    const error = touched[name] && errors[name];
    return (
      <InputContainer>
        <Input
          value={values[name]}
          name={name}
          placeholder={placeholder}
          type={type}
          error={error}
          onChange={(event) =>
            this.handleChange(event, setFieldValue, name, length)
          }
          onBlur={handleBlur}
        />
      </InputContainer>
    );
  };

  handleSubmit = async (values, actions) => {
    this.setState({ loading: true });
    let utm_source = localStorage.getItem("utm_source") || "";
    let utm_campaign = localStorage.getItem("utm_campaign") || "";
    let utm_medium = localStorage.getItem("utm_medium") || "";
    try {
      await http.post("/users/user/", {
        email: values["email"],
        phone_no: localStorage.getItem("phone"),
        company: {
          company_name: values["name"],
          number_of_employees: values["numberOfEmployees"],
          pincode: values["pincode"],
          referral: sessionStorage.getItem("referral_id") || undefined
        },
        utm_source,
        utm_medium,
        utm_campaign,
      });
      await http.post("/phone/send_otp/", {
        phone: localStorage.getItem("phone"),
        action: "REGISTER",
        business: true,
      });
      localStorage.setItem("pinCode", values["pincode"]);
      localStorage.setItem("about", JSON.stringify(values));
      this.props.history.push("/business/otp");
    } catch (ex) {
      this.setState({ loading: false });
      const error = ex.response && ex.response.data;
      if (error) {
        error.pincode &&
          actions.setFieldError(
            "pincode",
            error["pincode"] && error["pincode"][0]
          );
      }
      console.error(ex);
    }
  };

  renderError = (formikProps) => {
    const {touched, errors, } = formikProps
    let touch =  touched && touched['industry']
    let error = errors && errors['industry']
    return(
        <Error>
            {touch && error}
        </Error>
    )
  }

  renderAgeDropDown = (formikProps) => {
    const { values, setFieldTouched, handleChange} = formikProps
    const value = values && values['industry']
    return(
        <DropdownComponentWrapper>
            <DropdownWrapper>
                <AgeWrapper 
                    name={'industry'}
                    onChange={handleChange} 
                    onBlur={(e => setFieldTouched('industry', true))}
                    color={value && '#009999' }
                    >
                    <Option value='' default>
                        {'Industry'}
                    </Option>
                    {industries.map(item => (
                        <Option selected={String(item.value) === String(value) && 'selected'} key={item.value} value={item.value}>
                            {item.label}
                        </Option>
                    ))
                    }
                </AgeWrapper>
                <Icon style={{'position': 'absolute', 'marginLeft': '74%'}} icon='chevron_down' fill="#7e7b7f" />
            </DropdownWrapper>
            {this.renderError(formikProps)}
        </DropdownComponentWrapper>
    )
}

  renderForm = (formikProps) => {
    const isDisabled =
      formikProps &&
      formikProps.values &&
      Object.values(formikProps.values).filter((item) => !item).length > 0;
    return (
      <FormContainer>
        <Form>
          {this.renderInput({
            name: "name",
            placeholder: "Name of the Company",
            type: "text",
            formikProps,
          })}
          {this.renderInput({
            name: "pincode",
            placeholder: "Office Pincode",
            type: "number",
            formikProps,
            length: 6,
          })}
          {this.renderInput({
            name: "numberOfEmployees",
            placeholder: "How Many Employees",
            type: "number",
            formikProps,
          })}
          {this.renderInput({
            name: "email",
            placeholder: "Email",
            type: "text",
            formikProps,
          })}
          {this.renderAgeDropDown(formikProps)}
          <ButtonWrapper>
            <LargeButton
              disabled={isDisabled}
              opacity={isDisabled ? "0.4" : "1"}
              id="business_about_next"
              label={"NEXT"}
              type="submit"
            />
          </ButtonWrapper>
        </Form>
      </FormContainer>
    );
  };

  checkValidIndustry(value){
    // const filteredIndustry = industries.filter(item => (item['label'] && item['label'].toLowerCase()) === (value && value.toLowerCase()))
    // return filteredIndustry.length && filteredIndustry[0]['accepted']
    return true
    
  }

  render() {
    const Schema = Yup.object().shape({
      name: Yup.string().required("*Required"),
      pincode: Yup.number()
        .typeError("pincode must be a number")
        .required("*Required")
        .test(
          "len",
          "pincode must be exactly 6 digits",
          (val) => String(val).length === 6
        ),
      numberOfEmployees: Yup.string().required("*Required"),
      email: Yup.string()
        .email()
        .required("*Required"),
      industry: Yup.string()
        .required('*Required')
        .test('len', 'This industry type is not accepted', this.checkValidIndustry)
    });
    const about = JSON.parse(localStorage.getItem("about"));
    const initialValues = {
      name: (about && about["name"]) || "",
      pincode: (about && about["pincode"]) || "",
      numberOfEmployees: (about && about["numberOfEmployees"]) || "",
      email: (about && about["email"]) || "",
      industry: '', 
    };
    return (
      <Root>
        {this.state.loading && <OverlayLoader />}
        <Header
          onClickBack={() => this.props.history.push("/business/phone")}
          progressPercentage={10}
        />
        <Body>
          <Ptag
            fontFamily="Proxima Nova"
            fontSize="24px"
            fontWeight="500"
            letterHeight="1.33"
            letterSpacing="0.35px"
          >
            {"tell us about your company"}
          </Ptag>

          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={Schema}
            onSubmit={this.handleSubmit}
            render={(formikProps) => this.renderForm(formikProps)}
          />
        </Body>
      </Root>
    );
  }
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 26px 24px;
  flex: 1;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 34px;
  flex: 1;
  & > form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

const InputContainer = styled.div`
  display: flex;
  margin-bottom: 4px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
`;

const Error = styled.p`
  color: red;
  font-size: 1.4rem;
  margin-top: 5rem;
  position: absolute;
  z-index: 9;
`

const DropdownComponentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
`;

const DropdownWrapper = styled.div`
    display: flex;
    padding: 6px;
    border-bottom: 2px solid #436ab2;
    align-items: center;
`;


const AgeWrapper = styled.select`
    display: flex;
    width: 100%;
    border: none;
    font-family: Avenir;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.25px;
    color: ${props => props.color ? props.color : 'rgba(0, 0, 0, 0.5)'}};
    background-color:transparent;
    -webkit-appearance:none;
    -moz-appearance:none;
    &:hover,
    &:focus 
    {
      outline: 0 !important;
    };
    :
`;

const Option = styled.option`
 color: ${props => props.color ? props.color : 'rgba(0, 0, 0, 0.5)'}
`