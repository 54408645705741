import React, { Component } from 'react'
import styled from 'styled-components'
import { LargeButton, OverlayLoader} from '../../components'
import {Header} from '../../components/business'
import {Ptag} from '../../components/fonts'
import TagManager from 'react-gtm-module'
import {gtmCode} from '../../utils/utils'
import circle from '../../assets/images/goldenCircle.png'
import step1 from '../../assets/images/step1.png'
import step2 from '../../assets/images/step2.png'
import step3 from '../../assets/images/step3.png'
import step4 from '../../assets/images/step4.png'



export default class EnrollmentProcess extends Component {

    constructor(props){
        super(props)
        const policyType = 'monthly'
        this.state = {
            loading: true,
            data: {},
            CardData:[
                {
                    Heading: 'Pay online',
                    subHeading: 'Choose a convenient mode of payment and make the purchase.',
                    img: step2
                },
                {
                    Heading: 'Add member details',
                    subHeading: 'Upload all your employees\' details in one go through an Excel sheet.',
                    img: step1
                },
                {
                    Heading: 'Get policies instantly',
                    subHeading: policyType === 'monthly' ?
                        'Get policies issued and accounts created instantly for your employees.'
                        : 'Policy numbers will be issued instantly and coverage will start within 24 hours of purchase.'
                        ,
                    img: step3
                },
                {
                    Heading: 'Manage employees on dashboard',
                    subHeading: 'Add or remove employees with a single click on an intuitive dashboard.',
                    img: step4
                }
            ] 
        }
    }

    async componentDidMount(){
        const tagManagerArgs = {
            gtmId: gtmCode(),
          }
        TagManager.initialize(tagManagerArgs)
        
        this.setState({ loading: false})
    }

    onClickNext = () => {
        if (localStorage.getItem('offlineQuote')){
            this.props.history.push('/business/memberDetails')
        } else {
            this.props.history.push('/business/optionstoaddemployees')
        }
    }

    onClickBack = () => {
        if (localStorage.getItem('offlineQuote')){
            this.props.history.push('/business/offlinequotes')
        } else {
            this.props.history.push('/business/plans')
        }
    }

    render() {
        return (
            <Root>
                <Header headerText='Plan Summary' onClickBack={this.onClickBack} />
                <Body>
                    <Ptag fontFamily='Proxima Nova' style={{'marginLeft': '4px'}} fontSize='24px' fontWeight='500' letterHeight='1.33' letterSpacing='0.35px'>
                        {'process for enrollment'}
                    </Ptag>

                    {
                        this.state.CardData.map((item, index) =>
                            <Card key={index}>
                                <CardLeft>
                                    <img src={circle} height='68px' width='102px' />
                                    <Step>
                                        {`${index + 1}`}
                                    </Step>
                                </CardLeft>
                                <CardRight>
                                    <Ptag fontWeight='600' fontSize='16px' letterSpacing='0.58px'>
                                        {item['Heading']}
                                    </Ptag>
                                    <Ptag fontSize='12px' letterSpacing='0.42px'>
                                        {item['subHeading']}
                                    </Ptag>
                                    <Img src={item['img']} height='40px' width='40px'/>
                                </CardRight>
                            </Card>
                        )
                    }


                    <Footer>
                        <Crossline></Crossline>
                        <LargeButton id='business_enrollmentprocess_enter_member_details' style={{'minHeight' : '52px'}} onClick={()=> this.props.history.push('/business/gst')} label= {'NEXT'}/>
                    </Footer>
                </Body>
                
                
               {this.state.loading && <OverlayLoader />}
            </Root>
        )
    }
}

const Root = styled.div`
	width: 100%;
	background-color: #ffffff;
    display: flex;
    flex-direction: column;
  
`
const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    flex: 1;
`;

const Card = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 24px;
`

const CardLeft = styled.div`
    display: flex;
`

const Step = styled.p`
    position: relative;
    left: -74px;
    top: 24px;
    font-family: Avenir;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    text-align: center;
    color: #4a4a4a;
`

const CardRight= styled.div`
    display: flex;
    flex-direction: column;
    min-height: 122px;
    max-width: 332px;
    margin-left: 12px;
    border-radius: 8px;
    border: solid 1px #979797;
    padding: 16px 8px 8px 12px ;
    & > :first-child{
        margin-bottom: 6px;
    }
`

const Img = styled.img`
    align-self: flex-end;
`

const Crossline = styled.div`
  height: 1px;
  border: solid 1px #d0d0d080;
  margin: 24px 0;
`

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 24px;
`