import React, { useMemo } from "react";
import {
  InsuranePlanWrapper,
  Header,
  LinksWrapper,
  Link,
  Image,
  Footer,
  InsurerIcon,
} from "./styles";
import moment from "moment";
import InsuranceIcon from "../../assets/svgs/shield.svg";
import GpaIcon from "../../assets/svgs/gpa_card_icon.svg";
import GtlIcon from "../../assets/svgs/gtl_card_icon.svg";
import LinkIcon from "../../assets/svgs/link_arrow.svg";
import { CASHLESS_CLAIM_URLS } from "../../constants/businessConstant";
import { insurers } from "../memberApp/constants/PolicyConstants";
import { Ptag } from "../fonts";

const InsurancePlanCard = ({ plan_detail, details, type }) => {
  // getting insurer name
  const insurerName = details.insurer.name.toUpperCase();

  // opening policy document
  const openPolicyDoc = () => {
    window.open(details.policy_doc_url);
  };
  const benefitManual = () => {
    window.open('https://healthfront-kyc-docs.s3.ap-south-1.amazonaws.com/'+details.employee_policy_doc);
  };

  // opening cashless url based on insurer
  const cashlessClaimHandler = () => {
    if (insurerName.includes("NIVA BUPA")) {
      window.open(CASHLESS_CLAIM_URLS.NIVA);
    } else if (insurerName.includes("KOTAK")) {
      window.open(CASHLESS_CLAIM_URLS.KOTAK);
    } else if (insurerName.includes("ICICI LOMBARD")) {
      window.open(CASHLESS_CLAIM_URLS.ICICI);
    } else if (insurerName.includes("GO_DIGIT")) {
      window.open(CASHLESS_CLAIM_URLS.DIGIT);
    } else if (insurerName.includes("TATA_AIG")) {
      window.open(CASHLESS_CLAIM_URLS.TATA);
    }else {
      window.open(CASHLESS_CLAIM_URLS.DEFAULT);
    }
  };

  // displaying header icon based on product
  const icon = useMemo(() => {
    let blob = '';
    switch(type) {
      case 'GPA':
        blob = GpaIcon;
        break;
      case 'GTL':
        blob = GtlIcon;
        break;
      default:
        blob = InsuranceIcon;
        break;
    }
    return blob;
  }, [type])

  return (
    <InsuranePlanWrapper>
      <div style={{ padding: "20px 24px" }}>
        <Header>
          <img src={icon} width="36px" />
          <Ptag
            fontSize="16px"
            fontWeight="600"
            color="#436AB2"
            marginLeft="1rem"
          >
            {plan_detail.title}
          </Ptag>
        </Header>
        <Ptag fontSize="14px" marginTop="1.4rem" marginBottom="1.4rem" letterHeight="24px">
          {plan_detail.description}
        </Ptag>
        {details.group_policy_number && <div style={{display: 'flex'}}>
          <Ptag>Policy Number :&nbsp;</Ptag>
          <Ptag fontWeight="700"> {details.group_policy_number || "-"}</Ptag>
        </div>}
      </div>
      <div>
        <LinksWrapper>
          {details.policy_doc_url ? (
            <Link onClick={openPolicyDoc}>
              <Ptag fontSize="12px" fontWeight="600" color="#4A90E2">
                Policy Certificate
              </Ptag>
              <Image src={LinkIcon} />
            </Link>
          ) : (
            <span></span>
          )}
          {/* {["GMC", "GMC_M"].includes(type) && <Link onClick={cashlessClaimHandler}>
            <Ptag fontSize="12px" fontWeight="600" color="#4A90E2">
              Cashless Network
            </Ptag>
            <Image src={LinkIcon} />
          </Link>} */}
          {details.employee_policy_doc &&
          <Link onClick={benefitManual}>
            <Ptag fontSize="12px" fontWeight="600" color="#4A90E2">
              Employee Benefit Manual
            </Ptag>
            <Image src={LinkIcon} />
          </Link>}
        </LinksWrapper>
        <Footer>
          <div>
            <Ptag fontSize="12px" fontWeight="400" textAlign="center">
              Valid Till
            </Ptag>
            <Ptag
              fontSize="14px"
              fontWeight="600"
              textAlign="center"
              marginTop="0.4rem"
            >
              {details.subscription && details.subscription.end_date
                ? moment(details.subscription.end_date).format("DD/MM/YYYY")
                : "-"}
            </Ptag>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "1rem",
            }}
          >
            <Ptag fontSize="8px" fontWeight="600" textAlign="center">
              POWERED BY
            </Ptag>
            <InsurerIcon src={insurers && insurers[insurerName] && insurers[insurerName].logo} />
          </div>
        </Footer>
      </div>
    </InsuranePlanWrapper>
  );
};

export default InsurancePlanCard;
