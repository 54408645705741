import _ from 'lodash'
import Loader from './overLayLoaderBusiness'
import React from "react";
import styled from 'styled-components'
import { Ptag } from "../../components/fonts";
import http from '../../services/httpService'
import { formatAmount, formatBackEndDateDDMMYY } from '../../utils/utils';
import emptyPayment from '../../assets/images/emptyPayment.png'
import { IfComponent } from '../../components';
import { CSVLink } from "react-csv";
import { historyHeaders } from '../../constants/businessConstant';
export default class PaymentSummary extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            sub_details: [],
            csvData: [],
            myCsvRef: React.createRef(),
        }
    }
    componentDidMount = async () => {
        try {
            const { data } = await http.get(`/payment/subscription_details/`, {
                params: {
                    plan: localStorage.getItem('userplan_id'),
                }
            })
            let sub_details = data[0]['payments_all'] || data[0]['payments_all']
            this.setState({
                sub_details: sub_details, loading: false
            })
        }
        catch (e) {
            alert(e.response)
        }

    }

    goTopaymentReciept = (sub_detail) => {
        var host = window.location.host;
        var orderid = sub_detail['order_id']['order_id']
        if (host.indexOf('http') < 0) {
            host = 'http://' + host
        }
        if ((localStorage.getItem('insurer') == 'go_digit') && (sub_detail['order_id']['order_status'] === 'Paid' && sub_detail['order_id']['order_date_flag'] === true)){
            window.open(`${host}/business/digitinvoice.html?current_orderid=${orderid}&current_userplanid=${encodeURI(localStorage.getItem('userplan_id'))}`)
        }
        else if (sub_detail['order_id']['order_status'] === 'Paid' && sub_detail['order_id']['order_date_flag'] === true) {
            window.open(`${host}/business/businessinvoice.html?current_orderid=${orderid}&current_userplanid=${encodeURI(localStorage.getItem('userplan_id'))}`)
        }
    }

    getPaymentDetails = async (sub_detail) => {
        var orderid = sub_detail['order_id']['order_id']
        try {
            const { data } = await http.get(`/business/get_employees_details_for_each_payment/?order_id=${orderid}`)
            var csvStr = '"Employee First Name","Employee Last Name","Gender","Date of Birth","Email","Mobile","Dependents","Sum Insured","Deductible","Dependent1",,,,"Dependent2",,,,"Dependent3",,,,"Dependent4",,,,"Dependent5",,,,"Dependent6",,,\n,,,,,,,,,"Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB"\n'
            Array.isArray(data.data) && data.data.forEach(item => { 
                csvStr = csvStr+item.employee_first_name+','
                csvStr = csvStr+item.employee_last_name+','
                csvStr = csvStr+item.gender+','
                csvStr = csvStr+item.employee_dob+','
                csvStr = csvStr+item.email+','
                csvStr = csvStr+item.phone+','
                csvStr = csvStr+item.dependents.length+','
                csvStr = csvStr+item.sum_insured+','
                csvStr = csvStr+item.deductible+','
                for (let i = 0; i < item.dependents.length; i++) {
                    const role = item.dependents[i]
                    csvStr = csvStr+role.relationship+','
                    csvStr = csvStr+role.first_name+','
                    csvStr = csvStr+role.last_name+','
                    csvStr = csvStr+role.dob+','
                    
                }
                csvStr = csvStr+'\n'
            });
            const element = document.createElement("a");
            const fileOutput = csvStr
            const file = new Blob([fileOutput], {type: 'text/plain'});
            element.href = URL.createObjectURL(file);
            element.download = localStorage.getItem('cname').split(/[\s.]+/)[0] + '_employee.csv' || 'Employee_Details.csv';
            document.body.appendChild(element); // Required for this to work in FireFox
            element.click();
            element.parentNode.removeChild(element);
        }
        catch (e) {

            console.dir(e)
        }

    }

    exportCsv = () => {
        this.myCsvRef.link.click()
    }

    render() {
      
        return (
            <HomeWrapper>
                {/* <CSVLink style={{ textDecoration: 'none' }}   headers={historyHeaders} ref={(r) => this.myCsvRef = r} {...csvReport}></CSVLink> */}

                <IfComponent condition={this.state.loading} >
                    <Loader />
                </IfComponent>
                <IfComponent condition={this.state.sub_details.length > 0 && !this.state.loading} >
                    <>
                        <SummaryDiv>
                            <Ptag fontSize="20px" fontWeight="600" color="#4A4A4A" lineHeight="33px" letterSpacing="0.6px">Subscription Summary</Ptag>

                        </SummaryDiv>
                        <div style={{ overflow: 'auto' }}>
                            <table id="table-to-xls" className="table-striped" width="100%" cellSpacing="0" cellPadding="6" style={{ border: 1 }}>
                                <tbody>
                                    <tr  >
                                        <th ><TopHeader><Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#585858'} fontWeight="600">Due Date</Ptag></TopHeader></th>
                                        <th ><TopHeader><Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#585858'} fontWeight="600">Amount</Ptag> </TopHeader></th>
                                        <th ><TopHeader><Ptag fontSize="13px" textAlign="center" lineHeight={1.3} color={'#585858'} fontWeight="600">Employees List</Ptag></TopHeader> </th>
                                        <th ><TopHeader><Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#585858'} fontWeight="600">Invoice</Ptag> </TopHeader></th>
                                        <th ><TopHeader><Ptag fontSize="16px" textAlign="center" lineHeight={1.3} color={'#585858'} fontWeight="600">Status</Ptag></TopHeader> </th>
                                        {/* <th ><TopHeader><Ptag fontSize="13px" textAlign="center" lineHeight={1.3} color={'#585858'} fontWeight="600">Reciept</Ptag></TopHeader> </th> */}
                                    </tr>
                                    {this.state.sub_details && this.state.sub_details.map((sub_detail, index) => {
                                        const showPDF = (sub_detail['order_id']['order_status'] === 'Paid') && sub_detail['order_id']['order_date_flag']
                                        const userplanId = localStorage.getItem('userplan_id');
                                        // const nuvoretailHandleFlag = userplanId === "plan_3138f03b41a3a7";
                                        return (<tr key={index} style={index % 2 ? { background: "#EEEEEE", height: 48 } : { background: "#D0D9E9", height: 48, }}>
                                            <td ><TopHeader><Ptag fontSize="16px" lineHeight={1.3} letterSpacing='1px'>{formatBackEndDateDDMMYY(sub_detail.due_date)}</Ptag></TopHeader></td>
                                            <td ><TopHeader><Ptag fontSize="16px" lineHeight={1.3} letterSpacing='1px'>₹{formatAmount(sub_detail['order_id']['amount'])}</Ptag></TopHeader></td>
                                            <td ><TopHeader><Ptag style={{'cursor': 'pointer'}} fontSize="16px" lineHeight={1.3} textDecoration="underline" onClick={() => { this.getPaymentDetails(sub_detail) }}>{'Employee.csv'}</Ptag></TopHeader></td>
                                            <td >
                                                <TopHeader>
                                                    {
                                                        showPDF ? 
                                                        <Ptag style={{'cursor': 'pointer'}} fontSize="16px" textDecoration={"underline"} letterSpacing='1px' lineHeight={1.3} onClick={() => { this.goTopaymentReciept(sub_detail) }}>
                                                            {'PDF'}
                                                        </Ptag> : 
                                                        <Ptag fontSize="16px"  letterSpacing='1px' lineHeight={1.3}>
                                                            {'-'}
                                                        </Ptag>
                                                    }
                                                    
                                                </TopHeader>
                                            </td>
                                            <td ><TopHeader><Ptag fontSize="16px" lineHeight={1.3} letterSpacing='1px'>{sub_detail['order_id']['order_status']}</Ptag></TopHeader></td>
                                            {/* <td ><TopHeader><Ptag fontSize="12px" lineHeight={1.3}>{sub_detail.reciept}</Ptag></TopHeader></td> */}

                                        </tr>
                                        )}
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </>
                </IfComponent >
                <IfComponent condition={this.state.sub_details.length === 0 && !this.state.loading}>
                    <HomeWrapper>
                        <EmptyPayment style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                            <EmptyPaymentBox>
                                <img src={emptyPayment} width="242px" height="216px" style={{ marginLeft: 100 }} />
                                <Ptag fontSize="24px" fontWeight="600" color="#4A4A4A" letterSpacing="0.6px">There is currently no on going subscription</Ptag>
                            </EmptyPaymentBox>
                        </EmptyPayment>
                    </HomeWrapper>
                </IfComponent>
            </HomeWrapper>

        )

    }
}

const HomeWrapper = styled.div`
  transition: margin-left .2s ease-in;
  padding: 50px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: white;
  /* box-shadow: rgb(0 0 0 / 15%) 0px 1px 4px 0px; */
`

const TopHeader = styled.h1`
  text-transform: lowercase;
  font-family: Avenir;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: 0.6px;
  display: flex;
  padding: 12px 12px ;
  justify-content: center;
   
`

const SummaryDiv = styled.div`
padding:0px 0px 20px 0px 

`
const EmptyPayment = styled.div`
    
`
const EmptyPaymentBox = styled.div`
display:flex;
flex-direction:column
    
`
