import React, { useEffect, useMemo, useState } from "react";
import DetailCard from "../../../components/GmcDashbaord/DetailCard";
import {
  CareEmployeesBox,
  Divider,
  InsuranceCardsWrapper,
  MainDiv,
  SplitWrapper,
} from "./styles";
import EmployeeIcon from "../../../assets/svgs/employee.svg";
import MembersIcon from "../../../assets/svgs/total_employee.svg";
import { Ptag } from "../../../components/fonts";
import http from "../../../services/httpService";
import { OverlayLoader } from "../../../components";
import InsurancePlanCard from "../../../components/GmcDashbaord/InsurancePlanCard";
import BenefitCardList from "../../../components/GmcDashbaord/BenefitsCardList";
import MisTable from "../../../components/GmcDashbaord/MisTable";
import SplitDetailCard from "../../../components/GmcDashbaord/SplitDetailCard";
import { renderSplitCards } from "./renderSplitCards";

const GmcDashboard = () => {
  const [companyData, setCompanyData] = useState({});
  const [employeeData, setEmployeeData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function() {
      await getCompanyData();
      await getEmployeeData();
      setLoading(false);
    })();
  }, []);

  const products = useMemo(() => {
    const result = [];
    if (companyData["GMC"]) result.push("GMC");
    if (companyData["GMC_M"]) result.push("GMC_M");
    if (companyData["GPA"]) result.push("GPA");
    if (companyData["GTL"]) result.push("GTL");
    return result;
  }, [companyData]);

  const getCompanyData = async () => {
    try {
      const { data } = await http.get("business/business_user_mis_data/");
      localStorage.setItem("gmc_details", JSON.stringify(data));

      setCompanyData(data);
    } catch (err) {
      alert("Some error has occured!");
    }
  };

  const getEmployeeData = async () => {
    try {
      const { data } = await http.get(`/business/employee_detail/`);
      setEmployeeData(data);
    } catch (err) {
      console.log(err);
      alert("Some error has occured!");
    }
  };

  const renderInsuranceProducts = () => {
    const generateJsx = (type, details, index) => {
      let JSX = "";
      switch (type) {
        case "GMC":
          JSX = (
            <InsurancePlanCard
              plan_detail={{
                title: "Group Health Insurance",
                description: "For hospitalizations and day care treatments",
              }}
              key={index.toString()}
              details={details}
              type="GMC"
            />
          );
          break;
        case "GMC_M":
            JSX = (
              <InsurancePlanCard
                plan_detail={{
                  title: "Group Health Insurance",
                  description: "For hospitalizations and day care treatments",
                }}
                details={details}
                type="GMC_M"
              />
            );
            break;
        case "GPA":
          JSX = (
            <InsurancePlanCard
              plan_detail={{
                title: "Group Personal Accident",
                description:
                  "Coverage for accidental death or permanent disablement",
              }}
              key={index.toString()}
              type="GPA"
              details={details}
            />
          );
          break;
        case "GTL":
          JSX = (
            <InsurancePlanCard
              plan_detail={{
                title: "Group Term Life",
                description:
                  "For financial protection against loss of life",
              }}
              key={index.toString()}
              type="GTL"
              details={details}
            />
          );
          break;
      }
      return JSX;
    };
    const prod = Object.keys(companyData).sort((a, b) => {
      if (a.slice(0, 3) < b.slice(0, 3)) {
        return -1;
      } else if (a.slice(0, 3) > b.slice(0, 3)) {
        return 1;
      } else {
        return a.length < b.length ? -1 : 1;
      }
    });

    return (
      <InsuranceCardsWrapper>
        {prod.map((key, index) =>
          generateJsx(key, companyData[key], index)
        )}
      </InsuranceCardsWrapper>
    );
  };

  const renderBenefits = () => {
    let benefitsPol = "";
    if (
      companyData["GMC"] &&
      companyData["GMC"].plan_benefits &&
      companyData["GMC"].plan_benefits.length
    ) {
      benefitsPol = "GMC";
    } else if (
      companyData["GMC_M"] &&
      companyData["GMC_M"].plan_benefits &&
      companyData["GMC_M"].plan_benefits.length
    ) {
      benefitsPol = "GMC_M";
    } else if (
      companyData["GPA"] &&
      companyData["GPA"].plan_benefits &&
      companyData["GPA"].plan_benefits.length
    ) {
      benefitsPol = "GPA";
    } else if (
      companyData["GTL"] &&
      companyData["GTL"].plan_benefits &&
      companyData["GTL"].plan_benefits.length
    ) {
      benefitsPol = "GTL";
    }
    if (!benefitsPol) return null;
    return (
      <BenefitCardList benefits={companyData[benefitsPol].plan_benefits} />
    );
  };

//   const renderSplitCards = (type, details, index) => {
//     const splitDetails = [];
//     if(details.number_of_employee >= 0) splitDetails.push({
//         title: details.number_of_employee === 1 ? "EMPLOYEE" : "EMPLOYEES",
//         number: details.number_of_employee || 0,
//     })
//     if(details.no_of_lives >= 0) splitDetails.push({
//         title: details.no_of_lives === 1 ? "LIFE" : "LIVES",
//         number: details.no_of_lives || 0,
//     })
//     let JSX = "";
//     switch(type) {
//         case 'GMC':
//             JSX = <SplitDetailCard key={index.toString()} title="Group Health Insurance" details={splitDetails} />
//             break;
//         case 'GPA':
//             JSX = <SplitDetailCard key={index.toString()} title="Group Personal Accident" details={splitDetails} />
//             break;
//         case 'GTL':
//             JSX = <SplitDetailCard key={index.toString()} title="Group Term Life" details={splitDetails} />
//             break;
//     }
//     return JSX;
// }

  const renderTopCards = () => {
    const products = Object.keys(employeeData).sort((a, b) => {
      if (a.slice(0, 3) < b.slice(0, 3)) {
        return -1;
      } else if (a.slice(0, 3) > b.slice(0, 3)) {
        return 1;
      } else {
        return a.length < b.length ? -1 : 1;
      }
  });

  return (
      <SplitWrapper>
          {products.map((prod, index) => {
              return renderSplitCards(prod, employeeData[prod].overallsummary, index)
          })}
      </SplitWrapper>
  )
  };

  return (
    <MainDiv>
      <div style={{width: '95%'}}>
        {loading ? (
          <OverlayLoader />
        ) : (
          <>
            {renderTopCards()}
            <Divider />
            <Ptag fontSize="24px" fontWeight="700" marginTop="3rem" marginBottom="3rem">
              Plan Summary
            </Ptag>
            {renderInsuranceProducts()}
            {renderBenefits()}
            <div style={{marginTop: '36px', height: '16px', backgroundColor: '#dadada', marginLeft: '-4rem', width: '140%'}}></div>
            <MisTable products={products} employeeData={employeeData} />
          </>
        )}
      </div>
    </MainDiv>
  );
};

export default GmcDashboard;
