import React, { Component } from "react";
import styled from "styled-components";
import DeductibleG from "../../assets/images/deductibleG.png";
import Deductible from "../../assets/images/deductibleExplain.png";
import Icons from "../../components/icons";
import {maxScreenWidth} from '../../config.json'


const OuterDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const ImageDv = styled.div`
  display: flex;

  & img {
    margin-top: 24px;
    // margin-left: -8px;
    width: 325px;
    height: 243px;
  }
`;
const Image = styled.img`
  align-items: center;
  justify-content: center;
`;

const RedBox = styled.div`
    display:flex;
    margin-top: 24px;
    border-radius: 5px;
    background-color: #f5d5d5;
    padding: 10px 22px; 
    font-family: Open Sans;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.75px;
    color: #f77963;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  height: 116px;
  background-color: #336188;
`;
const IconDiv = styled.div`
  display: flex;
  position: absolute;
  right: 0px;
  margin: 12px 12px 0px 0px;
`;
const Font24 = styled.div`
  font-family: Proxima Nova;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #ffffff;
  padding: 61px 0px 31px 24px;
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px;
`;
const Font14 = styled.p`
  // display: flex;
  // flex-direction: column;
  font-family: Open Sans;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14px")};
  font-weight: ${(props) => (props.fontweight ? props.fontweight : "normal")};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: ${(props) => (props.color ? props.color : "#666666")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "none")};
`;

const Font16 = styled.div`
  display: flex;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.57px;
  color: #4a4a4a;
  margin-left: 24px;
  margin-top: 14px;
`;

const Home = styled.div`
  width: 100%;
  max-width: ${maxScreenWidth};
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 50;
  transform: ${props =>
    props.show
      ? "translateY(0) scaleY(100%)"
      : "translateY(102%) scaleY(0)"};
  transition: all 0.6s;
`;

const Points = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: ${props => props.alignSelf ? props.alignSelf : ''};
    & > img {
        margin-top: 6px;
    }
`;

const Line = styled.div`
    border-right: 1px dashed #4a4a4a;
    height:100%;
    margin-top: 4px;
    align-self: center;
`

const Bodyy = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px
`;

const InstructionBox = styled.div`
  display: flex;
  padding: 0 24px;
  & > p {
      margin-bottom: 16px;
      margin-left: 12px;
  }
`;

export default class Deductable extends Component {
 
  render() {
    return (
      <Home show={this.props.showPopup}>
        <Header>
          <Font24>What is Deductible?</Font24>
          <IconDiv onClick={() => this.props.BackBtn()}>
            <Icons icon="cross" fill="#ffffff" height="24" width="24" />
          </IconDiv>
        </Header>

        <Body>
          <Font14>
            Deductible is the amount the employee will pay towards treatments
            covered in the plan before insurance starts to pay.
          </Font14>

          <Font14 fontsize="12px" marginTop="24px" color="#436ab2">
            FOR EXAMPLE
          </Font14>

          <Font14 marginTop="18px">
          You have bought the Silver Plan of ₹5,00,000 for your employees.  
          </Font14>

          <Font14 marginTop="8px">
          Now one of your employees, Ajay, incurs a bill of ₹1,00,000 in the first month and another bill of ₹50,000 in the next month.

          </Font14>

          <OuterDiv>
            <ImageDv>
              <Image src={Deductible} />
            </ImageDv>
          </OuterDiv>

          <Font14 fontsize="14px" marginTop="40px" fontweight="600">
            Why choose a plan with deductible?
          </Font14>

          <Font14 marginTop="12px">
              Cover your employees for health emergencies without burning a hole in your pocket.
          </Font14>

          <RedBox>
              {'Save up to 60% on monthly premiums'}
          </RedBox>

        </Body>

        <Bodyy>
          <Font16 style={{'marginBottom': '18px'}}>How does it work?</Font16>
          <InstructionBox>
            <Points>
                <img src={DeductibleG} height='12px' width='12px' />
                <Line/>
            </Points>
            <Font14>
                {'Your employees can upload the relevant bills on our portal.'}
            </Font14>
          </InstructionBox>
          <InstructionBox>
            <Points>
                <img src={DeductibleG} height='12px' width='12px' />
                <Line/>
            </Points>
            <Font14>
                {'We will verify and account the bills in their deductible.'}
            </Font14>
          </InstructionBox>
          <InstructionBox>
            <Points>
                <img src={DeductibleG} height='12px' width='12px' />
                <Line/>
            </Points>
            <Font14>
                {'Your employees can track the pending deductible on their dashboard.'}
            </Font14>
          </InstructionBox>
          <InstructionBox>
            <Points alignSelf='flex-start'>
                <img src={DeductibleG} height='12px' width='12px' />
             
            </Points>
            <Font14>
                {'Insurance will pay for all eligible claims once the deductible is crossed'}
            </Font14>
          </InstructionBox>
        </Bodyy>
      </Home>
    );
  }
}
