import React, { useState, useEffect } from "react";
import { Ptag } from '../../../components/fonts'
import { OverlayLoader } from '../../../components'
import http from '../../../services/httpService'
import CdbalanceCard from "../../../components/business/CdBalance/CdBalanceCard";
import {
  HomeWrapper,
  CardTag,
  Root
} from "./styles";

const ViewCdBalance = () => {
  const flexProduct = localStorage.getItem('isFlex') || ''
  const [loading, setLoading] = useState(false);
  const [ledgerTable, setLedgerTable] = useState([]);
  
  let table_data = {};

  useEffect(() => {(
    async function(){
      await getData()
    }()
  )}, []);
  
  const getData = async () => {
    setLoading(true);
    try {
      const { data } = await http.get(`/business/view_cd_balance/`);

      table_data = data;
      setLedgerTable(data)
      setLoading(false);
    } catch (ex) {
      console.log({ ex });
      setLoading(false);
    }
  };
  const renderCard = () => {
      var planKey;
      if(flexProduct){
        planKey = JSON.parse(localStorage.getItem('flex_product_priority_list'))
      } else {
        planKey = Object.keys(ledgerTable).sort((a, b) => {
          if (a.slice(0, 3) < b.slice(0, 3)) {
            return -1;
          } else if (a.slice(0, 3) > b.slice(0, 3)) {
            return 1;
          } else {
            return a.length < b.length ? -1 : 1;
          }
        });
      }
      console.log(planKey)
      return planKey && ledgerTable && planKey.filter(key => key !== "health_checkup").map(key => <CdbalanceCard  planType = {key} tableData = {ledgerTable[key]}/>)
      // return  Object.entries(ledgerTable).map(([key, value]) => <CdbalanceCard  planType = {key} tableData = {value}/>)
   }

  const lastUpdatedCd = () => {
    return (
      <HomeWrapper>
        <Ptag
          fontSize="24px"
          fontWeight="700"
          marginTop="12px"
        >
          Cash Deposit Account
        </Ptag>
        <Ptag
          fontSize="16px"
          fontWeight="400"
          marginTop="16px"
          letterHeight="22px"
        >
          A mandatory non-interest bearing CD account is created to deposit all
          premium payments and manage insurance related transactions with the
          insurer.
        </Ptag>
        <CardTag>
          {renderCard()}
        </CardTag>
      </HomeWrapper>
    );
  };

  return (
    <>
        <Root>
            {loading && <OverlayLoader />}
            {lastUpdatedCd()}
        </Root>
    </>
  );
};
// }

export default ViewCdBalance;
