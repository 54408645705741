// import {
//   HeaderDasNew,
//   MainBody,
//   OverlayLoader,
//   Ptag,
// } from "components/core";
import { OverlayLoader,  } from "../../components";
import React, { useEffect, useState } from "react";
import BatchTable from "./BatchTable";
import http from "../../services/httpService";
import { Ptag } from "../../components/fonts";

const EnrolmentHistory = () => {
  const isGmcFlow = (localStorage.getItem("flow") === "GMC" || localStorage.getItem("flow") === "GMC_M");
  const [enrolments, setEnrolments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      await getEnrolments();
    })();
  }, []);

  const getEnrolments = async () => {
    let endpoint = "/business/get_all_tasksets/";
    if(isGmcFlow) {
      endpoint = "/business/get_batches_for_enrollment_history_gmc/";
    }
    try {
      setLoading(true);
      const { data } = await http.get(endpoint);
      setEnrolments(data);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <OverlayLoader />
      ) : (
        <>
          <div style={{background: "white", padding: "4rem"}}>
            <Ptag fontWeight="800" fontSize="20px" marginBottom="24px">
              Enrolment History
            </Ptag>
            <BatchTable enrolments={enrolments} isGmcFlow={isGmcFlow} />
          </div>
        </>
      )}
    </>
  );
};

export default EnrolmentHistory;
