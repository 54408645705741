import React from 'react'
import styled from 'styled-components'
import hospital from '../../assets/svgs/hospital.svg'
import dayCare from '../../assets/svgs/dayCare.svg'
import ambulance from '../../assets/svgs/ambulance.svg'
import postHospitalisation from '../../assets/images/postHospitalisation.png'
import stethoscope from '../../assets/svgs/stethoscope.svg'
import cosmetic from '../../assets/svgs/cosmetic.svg'
import dental from '../../assets/svgs/dental.svg'
import maternity from '../../assets/images/Maternity.png'
import prosthetics from '../../assets/svgs/prosthetics.svg'
import mentalDisorders from '../../assets/svgs/mentalDisorders.svg'
import drugTreatment from '../../assets/svgs/drugTreatment.svg'
import covid from '../../assets/images/covid.png'

import { Ptag } from "../fonts";


const Home = styled.div`
  display: flex;
  overflow-x: auto;
  & > div {
    position: relative;
    display: inline-block;
  }

  & > :last-child {
    div {
      border: none;
    }
  }

  //Chrome, Safari, newer versions of Opera
  ::-webkit-scrollbar {
    display: none;
  }
  //Firefox
  overflow: -moz-scrollbars-none;
  //Internet Explorer +10
  -ms-overflow-style: none;
`

const ImgWrap = styled.div`
  width:${props=>props.width?props.width: "10rem"};
  // height: 10rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${props => props.padding ? props.padding : '1rem 1.2rem 0'};
  border-right: ${ props => props.borderRight ? props.borderRight : '1px solid rgba(74, 144, 226, 0.3)'};

  & img {
    margin-bottom: 1rem;
  }
`

const TagUI = styled.div`
  display: flex;
  padding: 4px;
  background-color: #7342cc;
  border-radius: 0 5px 5px 5px;
  width: fit-content;
  margin: 4px auto;
`;

const Font12 = styled.p`
  font-family: Open Sans;
  font-size: ${props => props.fontSize};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.2px;
  text-align: center;
  color: #4a4a4a;
`


const CoveredThing = props => {
	let Mapping = {
		'dayone' : {
			image: hospital,
			alt: 'hospital.png',
            text: 'Day One Hospitalisation',
            tag: ''
		},
		'hospitalization' : {
			image: hospital,
			alt: 'hospital.svg',
            text: 'Hospitalization Expenses',
            tag: 'No waiting Period'
		},
		'ambulance': {
			image: ambulance,
			alt: 'ambulance.svg',
            text: 'Ambulance Charges',
            tag: ''
		},
		'daycare': {
			image: dayCare,
			alt: "dayCare.svg",
            text: 'Day Care Procedures',
            tag: 'No waiting Period'
		},
		'prehospitalization': {
			image: stethoscope,
			alt:"stethoscope.svg",
            text: 'Pre/Post Hospitalization',
            tag: ''
		},
		'posthospitalization': {
			image: postHospitalisation,
			alt:"post.svg",
            text: 'Post Hospitalization ',
            tag: ''
		},
		'maternity': {
			image: maternity,
			alt: "Maternity.png",
            text: 'Maternity Benefits',
            tag: ''
		},
		'cosmetic': {
			image: cosmetic,
			alt: 'cosmetic.svg',
            text: 'Cosmetic Procedures',
            tag: ''
		},
		'dental':{
			image: dental,
			alt: 'dental.svg',
            text: 'Dental Treatments',
            tag: ''
		},
		'artificial_limbs' :{
			image: prosthetics,
			alt: 'prosthetics.svg',
            text: 'Artficial<br/>Limbs',
            tag: ''
		},
		'mental_disorder' :{
			image: mentalDisorders,
			alt: 'mentalDisorders.svg',
			text: 'Mental<br/>Disorders',
            tag: ''
		},
		'drug_abuse': {
			image: drugTreatment,
			alt: 'drugTreatment.svg',
			text: 'Drug<br/>Abuse',
            tag: ''
		},
		'covid':{
			image: covid,
			alt: 'covid.png',
			text: 'COVID-19 Treatment',
            tag: 'No waiting Period'
		}
    }
    const tags = props.tags
  return (
    <Home style={{"marginRight":"-14px"}}>
	  { props.features && props.features.map( (value, index) => (
		  <div key={index} >
		  <ImgWrap width ={props.width} borderRight={props.borderRight } padding={props.tilePadding}>
			<img height={props.iconHeight ? props.iconHeight : '48px'} width={props.iconWidth ? props.iconWidth : '48px'} src={Mapping[value].image} alt={Mapping[value].alt}/>
			<Font12 fontSize={props.fontSize ? props.fontSize : '12px'} >{Mapping[value].text}</Font12>
		  </ImgWrap>
		  {
			  tags.indexOf(value) > -1 &&
			  <TagUI> 
				<Ptag fontSize='8px' fontWeight='600' color='#f4f8ff' textAlign='center'>
					{Mapping[value].tag}
				</Ptag>
			  </TagUI>
		  }
		  
		</div>
	  ))}
    </Home>
  )
}

export default CoveredThing
