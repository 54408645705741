import React, { Component } from 'react'
import styled from 'styled-components'
import Icons from '../components/icons'

const Header = styled.div`
    display: flex;
    flex-direction: column;
    height: 214px;
    border-radius: 10px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    margin-left: 36px;
    margin-right: 36px;
    margin-top: 213px;
    position: relative;
`
const IconDiv = styled.div`
    display: flex;
    padding-right: 16px;
    padding-top: 16px;
    position: absolute;
    right: 0;
`
const Font14 = styled.div`
    font-family: Avenir;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.5px;
    color: #4a4a4a;
    margin: 42px 37px 18px 37px;
    text-align: center;
`
const FontC14 = styled.a`
    font-family: Avenir;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #436ab2;
    text-decoration: none;
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '17px' )}
    margin-top: ${props => (props.marginTop ? props.marginTop : 'none' )}
`
const Home = styled.div`
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;
`
const LineDiv = styled.div`
    height: 1px;
    width: 100%;
    background-color: #f5f5f5;
    margin-bottom: ${props => (props.marginBottom ? props.marginBottom : 'none' )}
`


    export default class ContactUs extends Component {
        
        onChatClick = () => {
            this.showVerloop()
          }
        
          showVerloop() {
            window.Verloop(function() {
              this.openWidget()
            })
          }

         render() {
           return (
                <Home>
                    <Header>
                        <Font14>
                             Talk to our care managers and enjoy your EMI benefits
                        </Font14>
                         <IconDiv onClick={() => this.props.BackBtn()} >
                             <Icons icon="cross" fill="grey" fill-opacity=".54" height="18" width="18" />
                         </IconDiv>
                         <LineDiv />
                         <FontC14 onClick={this.onChatClick} marginTop="17px">
                            Chat with Us
                         </FontC14>
                         <LineDiv  marginBottom="12px"/>
                         <FontC14 marginBottom="18px" href="tel:+01141171787">
                             Call Us
                         </FontC14>
                    </Header>
                </Home>
    )
  }
}
