import React from 'react'
import styled from 'styled-components'

import { CardMode, PaymentMethod, SubscriptionPlan } from '../constants/Payment'

export default class CreditCardContent extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <ContentDiv>
          <Text2>
          ₹{this.props.discount} will be charged now and ₹{this.props.premium} has to be paid every subsequent month
            until policy expiration.
          </Text2>

        <Button
          onClick={() => {

            this.props.onPay({
              method: PaymentMethod.WALLET,
              plan: SubscriptionPlan.NORMAL,
              payment_mode: CardMode.CREDIT_CARD
            })
          }}>
          <Text>PAY SECURELY</Text>
        </Button>
      </ContentDiv>
    )
  }
}

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const Text = styled.p`
  font-family: Avenir;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 1px;
`

const Text2 = styled.p`
  font-family: Avenir;
  font-size: 12px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.42;
  letter-spacing: 0.38px;
  text-align: left;
  color: #707070;
  padding-left: 18px;
  padding-right: 18px;
  margin-top: 24px;
`

const Text3 = styled.p`
  object-fit: contain;
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 1px;
  text-align: left;
  color: #4a4a4a;
  margin: 27px 0px 27px 0px;
  padding-left: 18px;
`
const Button = styled.div`
  display: flex;
  text-align: center;
  margin: 12px 18px 24px 18px;
  align-items: center;
  justify-content: center;
  background-color: #f27576;
  color: #ffffff;
  height: 54px;
  border-radius: 15px;
  background-color: #436ab2;
`
const Image = styled.img`
  position: absolute;
  max-width: 50px;
  max-height: 28px;
`
const LineDiv = styled.div`
  display: flex;
  margin-top: 24px;
  height: 1px;
  border-radius: 7px;
  background-color: #dcdcdc;
  margin: 0px 20px 0px 20px;
`
