import React, { useState } from "react";
import styled from "styled-components";
import {getRelativesIcon, getTags,} from '../utils/utils'
import {Ptag} from './fonts'
import {Icons} from './'
import HealthTags from "./memberApp/healthTags";


const CompleteMemberDetailsIfa = ({memberDetails, healthQuestions}) => {

    const [isCardOpen, setCardOpen] = useState(true)

    var health_details = {...memberDetails['family_member']}
    health_details['new_health_questions'] = {}
    health_details['new_health_questions']['activity_levels'] = memberDetails['family_member']['activity_levels']
    health_details['new_health_questions']['body_type'] = memberDetails['family_member']['body_type']
    health_details['new_health_questions']['hereditary_health_conditions'] = memberDetails['family_member']['hereditary_health_conditions']
    health_details['new_health_questions']['mental_wellbeing'] = memberDetails['family_member']['mental_wellbeing']
    health_details['new_health_questions']['smoke_and_drinking_habits'] = memberDetails['family_member']['smoke_and_drinking_habits']
    delete health_details['activity_levels']
    delete health_details['body_type']
    delete health_details['hereditary_health_conditions']
    delete health_details['mental_wellbeing']
    delete health_details['smoke_and_drinking_habits']

    const healthTags = getTags(health_details, healthQuestions)

    return(
        <Root>
            <Header onClick={() => setCardOpen(!isCardOpen)}>

                <LeftHeader>
                    <img height='30px' width='30px' src={getRelativesIcon(memberDetails['family_member']['relationship'])}/>
                    <Ptag textTransform='capitalize' color='#4a4a4a' letterSpacing='0.25px' fontSize='16px' >
                        {memberDetails['family_member']['first_name'] + ' ' + memberDetails['family_member']['last_name']}
                    </Ptag>
                </LeftHeader>
                <CheckboxWrapper >
                    <Icons
                        height='20'
                        width='20'
                        icon={isCardOpen ? 'chevron_down' : 'chevron_right'}
                        
                    />
                </CheckboxWrapper>
                
            </Header>

            {
                isCardOpen && 
                <Body>
                    
                    <Ptag textTransform='uppercase' style={{'marginTop': '24px'}} letterHeight='1.67' letterSpacing='1px' color='#009999'>
                        {'date of birth'}
                    </Ptag>
                    <Ptag style={{'marginTop': '6px'}} fontSize='14px'  letterHeight='1.67' letterSpacing='3px'>
                        {memberDetails['family_member']['dob']}
                    </Ptag>
                    
                    {
                        healthTags.length > 0 &&
                        <>
                            <Ptag textTransform='uppercase' style={{'marginTop': '24px'}} letterHeight='1.67' letterSpacing='1px' color='#009999'>
                                {'health status'}
                            </Ptag>
                            <HealthConditionWrapper>
                                {
                                    healthTags.map(
                                        item => 
                                        <HealthCondition>
                                            {item}
                                        </HealthCondition>
                                    )
                                }
                            </HealthConditionWrapper>
                        </>
                       
                            
                    }

                </Body>
            }
            

        </Root>
    )
}

export default CompleteMemberDetailsIfa

const Root = styled.div`
    display: flex;
    flex-direction: column;
    margin:0 0 18px;
    padding: 26px 18px 24px 18px;
    border-radius: 15px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`

const LeftHeader = styled.div`
    display: flex;
    align-items: center;
    & > img{
        margin-right: 10px; 
    }
`

const CheckboxWrapper = styled.div`
    width: 24px;
    height: 24px;
`

const HealthConditionWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 14px;
`

const HealthCondition = styled.div`
    display: flex;
    padding: 9px 12px;
    margin: 0 8px 8px 0;
    border-radius: 4px;
    background-color: #eff7ff;
    font-family: Avenir;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #4a4a4a;
    text-transform: capitalize;
`

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 18px;
   
`

const FooterContent = styled.div`
    display: flex;
    align-items: center;
    & > img{
        margin-left: 8px;
    }
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
`