import React, { Component } from 'react'
import styled from 'styled-components'
import ReligareLogo from '../../assets/images/Religarenewlogo.jpg'
import Icons from '../../components/icons'
import Header from '../../components/memberApp/header'
import { PolicyDocumentTexts } from '../../utils/corporatePlan'
import Support from '../../assets/images/support.png'
import arrow from '../../assets/images/rightArrowWhite.png'
import {Ptag} from '../../components/fonts'
import {PolicyMappings} from '../../components/memberApp/constants/PolicyConstants'


export default class PolicyDocument extends Component {
  state = {
    id: 1, // 1 = less than 25 , 2 = more tahn 25
    tabOpened: 0,
    backPage: localStorage.getItem('navigatingFrom')
  }



  componentDidMount(){
    this.setState({id: this.props.match.params.id})
  }

  pageOffSetMapping={
    0:387,
    1:462,
    2:530,

  }

  onTabClicked = async (tabNumber) => {
    if (this.state.tabOpened === tabNumber) {
      await this.setState({ tabOpened: -1 })
      
    } else {
      await this.setState({ tabOpened: tabNumber })
    }
    let yOffset = window.document.getElementById(tabNumber.toString()).offsetTop
    window.scrollTo({
      top: yOffset,
      left: 0,
      behavior: 'smooth'
    })
  }

  render() {
    const link = this.state.id == 1 ? 'https://vitalimages.s3.ap-south-1.amazonaws.com/policyDocuments/Group+Health+(Master+Policy+_+less+than+25).pdf' :
    'https://vitalimages.s3.ap-south-1.amazonaws.com/policyDocuments/Group+Health+(Master+Policy+_more+than+25).pdf'
    return (
      <Root ref={ref => (this.myRef = ref)}>
        <Header
          headerText="Coverage Document"
          backPage={
            `/business/${this.state.backPage}`
          }
        />
        <Body>
          <Download>
            <DownloadButton href={link} target='_blank' download="PolicyDocuments.pdf">
              <Icons icon="downloadPDF" />
              <PDFText>PDF</PDFText>
            </DownloadButton>
          </Download>

          <BodyHead>
            <Title>
            {PolicyMappings['COMPREHENSIVE']['name']}
            </Title>
            {/* <Logo src={ReligareLogo} ></Logo> */}
          </BodyHead>
          
          <GreyText marginBottom='12px'>{'Your insurance policy will cover all the insured members for hospitalization expenses, accidents or illnesses up to the sum insured.'}</GreyText>
         

          <TitleBox id = '0' onClick={() => this.onTabClicked(0)}>
            <TileWrapper >
              <BlueText16>What is covered?</BlueText16>
            </TileWrapper>
           
          </TitleBox>


              <CoronaBanner>
                <CoronaBannerText>
                  <Ptag
                    color="#fff"
                    fontSize="14px"
                    fontWeight="bold"
                    letterSpacing="0.44px"
                    letterHeight="2.14"
                  >
                    {"Be fearless"}
                  </Ptag>
                  <Ptag color="#fff">
                    {"We've got you covered for "}
                    <Strong1>{"CoVID-19"}</Strong1>
                    {" in our health insurance plans"}
                  </Ptag>
                </CoronaBannerText>
                <CoronaImage
                  height="72px"
                  width="50px"
                  src="https://vitalimages.s3.ap-south-1.amazonaws.com/dashboard/coronoImage.png"
                />
              </CoronaBanner>
              <BulletPointContainer>

                <BulletTextContainer>
                  <BulletHeading>Hospitalization Expenses</BulletHeading>
                  <GreyText marginBottom='0px'>{PolicyDocumentTexts['WhatsCovered'][0]}</GreyText>
                </BulletTextContainer>
              </BulletPointContainer>

              <BoxesContainer>
                {PolicyDocumentTexts[this.props.match.params.id == 1 ? 'HospitalizationExpensesYearly' : 'HospitalizationExpenses'].map((value, index) => (
                  <GreyBoxes key={index}>{value}</GreyBoxes>
                ))}
              </BoxesContainer>
              {
                this.props.match.params.id == 1 &&
                <GreyText marginTop='12px' marginBottom='0px'>{`Hospital room rent eligibility is upto Single A/C for Sum Insured of 3 lacs, 4lacs &5 lacs. \
                For Sum Insured of 2 lacs the room rent eligibility is upto 2% \
                of Sum Insured for normal room and 4% of Sum Insured for ICU.`}</GreyText>
              }
              <BulletPointContainer marginTop="30px">
                <BulletTextContainer>
                  <BulletHeading>Day Care Procedures</BulletHeading>

                  <GreyText marginBottom="6px">{PolicyDocumentTexts['DayCare']}</GreyText>
                  <GreyText marginBottom="6px">{'An indicative list of Day Care Procedures include:'}</GreyText>
                  {
                    PolicyDocumentTexts['DayCareProcedures'].map((text, index)=>
                      <GreyText marginBottom='4px' key={index}>
                        {index +1}{'. '}{text}
                      </GreyText>
                    )
                  }
                  
                  <div style={{'margin':'12px 0px 0px 0px'}}>
                  <LinkCustomNew
                    target="_blank"
                    href="https://vitalimages.s3.ap-south-1.amazonaws.com/policyDocuments/List+of+Daycare.pdf"
                    download="dayCareList.pdf">
                    {'View Annexure to see the complete list'}                    
                    </LinkCustomNew>
                    
                  </div>
                </BulletTextContainer>
              </BulletPointContainer>

              <BulletPointContainer marginTop="30px">

                <BulletTextContainer>
                  <BulletHeading>Pre-Hospitalization Medical Expenses</BulletHeading>

                  <GreyText marginBottom="0px">{PolicyDocumentTexts['PreHospitalization']}</GreyText>
                </BulletTextContainer>
              </BulletPointContainer>

              <BulletPointContainer marginTop="30px">

                <BulletTextContainer>
                  <BulletHeading>Post-Hospitalization Medical Expenses</BulletHeading>

                  <GreyText marginBottom="0px">{PolicyDocumentTexts['PostHospitalization']}</GreyText>
                </BulletTextContainer>
              </BulletPointContainer>

              <BulletPointContainer marginTop="30px">

                <BulletTextContainer>
                  <BulletHeading>Domestic Ambulance Charges</BulletHeading>

                  <GreyText marginBottom="0px">{PolicyDocumentTexts['Ambulance']}</GreyText>
                </BulletTextContainer>

              </BulletPointContainer>

                {/* <BulletPointContainer marginTop="30px">

                  <BulletTextContainer>
                    <BulletHeading>{'Maternity Benefits (If opted) '}</BulletHeading>
                    <GreyText marginBottom="5px">{'This benefit can be claimed only twice during the insured person’s lifetime.'}</GreyText>
                    {PolicyDocumentTexts['Maternity'].map((value, index) => (
                      <NumberedBox style={{'margin': '10px 0'}} key={index}>
                        <Points>{index + 1}.</Points>
                        <GreyText marginBottom='0'>{value}</GreyText>
                      </NumberedBox>
                    ))}
                  </BulletTextContainer>

              </BulletPointContainer> */}



              <BulletPointContainer marginTop="20px">

                <BulletTextContainer>
                  <BulletHeading>{'Special Note:'}</BulletHeading>
                  
                  {
                    this.state.id == 1 &&
                    PolicyDocumentTexts['Note1'].map((value, index) => (
                      <NumberedBox key={index}>
                        <Points>{index + 1}.</Points>
                        <GreyText marginBottom='4px'>{value}</GreyText>
                      </NumberedBox>
                    ))
                  }
                  {
                    this.state.id == 2 &&
                    PolicyDocumentTexts['Note2'].map((value, index) => (
                      <NumberedBox key={index}>
                        <Points>{index + 1}.</Points>
                        <GreyText marginBottom='4px'>{value}</GreyText>
                      </NumberedBox>
                    ))
                  }
                </BulletTextContainer>

              </BulletPointContainer>


          

          <TitleBox id='1' onClick={() => this.onTabClicked(1)}>
            <TileWrapper >
              <BlueText16>What is not covered?</BlueText16>
            </TileWrapper>
            
          </TitleBox>

            <NumberedBox style={{'marginBottom':'8px'}}  flexDirection="column">
              {PolicyDocumentTexts['WhatsNotCovered'].map((value, index) => (
                <NumberedBox key={index}>
                  <Points>{index + 1}.</Points>
                  <GreyText marginBottom='24px'>{value}</GreyText>
                </NumberedBox>
              ))}
            </NumberedBox>

            <BulletPointContainer marginTop="0px">
              <BulletTextContainer>
                <BulletHeading>
                  
                  {'Pre-existing illnesses covered from Day 1.'} 
                </BulletHeading>

                {/* <GreyText marginBottom="0px">
                  
                  {PolicyDocumentTexts['PreExistingDef']}
                </GreyText> */}
              </BulletTextContainer>
            </BulletPointContainer>

            


          <Footer>
						<HelpFooter>
							<img src={Support} height='72px' width='72px'></img>
							<HelpText>
								Chat. Call. Mail. <br/>
								anytime of the day
							</HelpText>
						</HelpFooter>
						<FooterText>
							A dedicated Care Manager will be assigned to you post purchase of the policy and will be your singular point of contact for all claims.
						</FooterText>
					</Footer>
        </Body>
      </Root>
    )
  }
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : 'auto')};
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
`

const BodyHead = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const Title = styled.p`
  font-family: Avenir;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1.13px;
  color: #4a4a4a;
`;

const Logo = styled.img`
  width: 138px;
  height: 24px;
  margin-top: 4px;
`;

const PhoneContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const GreyText = styled.p`
  width: 100%;
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : "1px" };
  text-align: left;
  color: #666666;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '24px')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
`

const IconWrapper = styled.div`
  display: flex;
  width: 10px;
  margin-right: 8px;
  margin-top: 3px;
`

const TileWrapper = styled.div`
  dispaly: flex;
  flex-direction: column;
  width: 90%;
`

const TileIcon = styled.div`
  display: flex;
  height: auto;
  align-items: center;
`

const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`

const BlueText16 = styled.p`
  margin: 24px 0px;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #436ab2;
`

const Download = styled.div`
  display: flex;
  justify-content: flex-end;
`

const DownloadButton = styled.a`
  display: flex;
  align-items: center;
  height: 29px;
  border-radius: 15px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;
  padding: 8px;
  text-decoration: none;
  margin-top: -64px;
  padding-bottom: 12px;
`

const BlueText14 = styled.div`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 1px;
  text-align: left;
  color: ${ props => props.color ? props.color : '#436ab2'};
  margin-bottom: 24px;
`

const Line = styled.div`
  min-width: 100%;
  height: 1px;
  background-color: #9b9b9b;
`

const BulletHeading = styled.p`
  font-family: Avenir;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 1px;
  text-align: left;
  color: #4a4a4a;
  margin-bottom: 10px;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
`

const BulletPointContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
`

const BulletTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 12px;
`
const GreyBoxes = styled.p`
  border-radius: 15px;
  color: #666666;
  background-color: #dcdcdc;
  font-family: Avenir;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.86px;
  text-align: left;
  padding: 3px 12px;
  margin-right: 8px;
  margin-top: 10px;
`

const Pre = styled.pre`
	font-family: Avenir;
	font-size: 14px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.43;
	letter-spacing: 1px;
	text-align: left;
  color: #666666;
  min-width:20px;
`;

const BoxesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const NumberedBox = styled.div`
  display: flex;
  flex-direction: ${props => (props.flexDirection ? props.flexDirection : 'row')};
`

const Points = styled.p`
  min-width: 30px;
  height: auto;
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: 1px;
  text-align: left;
  color: #666666;
`

const Step = styled.div`
  display:flex;
  width: 100%;
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: ${props => props.letterSpacing ? props.letterSpacing : "1px" };
  text-align: left;
  color: #666666;
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '24px')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0px')};
`;

const PDFText = styled.p`
  font-family: Avenir;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.86px;
  text-align: left;
  color: #436ab2;
  margin-left: 4px;
`
const LinkCustomNew = styled.a`
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: 0.18px;
  text-align: left;
  color: #4a90e2;
  margin-bottom: 12px;
`

const Footer = styled.div`
	display:flex;
	flex-direction: column;
	padding: 24px;
	margin:24px 0 0 0;
	border-radius: 15px;
	background-color: #f5f5f5;
`;

const HelpFooter = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items:flex-end;
`;

const FooterText= styled.p`
	font-family: Avenir;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.71;
	letter-spacing: 0.44px;
	color: #4a4a4a;
	margin-top:12px;
`;

const HelpText= styled.p`
	font-family: Avenir;
	font-size: 16px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.88;
	letter-spacing: 1px;
	color: #436ab2;
`;


const CoronaBanner = styled.div`
  display: flex;
  margin-bottom:  18px;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #f6b956;
`;

const CoronaBannerText = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
`;

const Strong1 = styled.strong`
  font-weight: 600;
  letter-spacing: 0.6px;
`;

const CoronaImage = styled.img`
  margin: 13px 28px 0 8px;
  align-self: flex-end;
`;

