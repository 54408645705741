import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import {Ptag} from '../fonts'
import noHospital from '../../assets/images/noHospital.png'

const NoHospital = (props) =>{
    return(
        <Root>
            <img src={noHospital} width='182px' height='166px'/>
            <Ptag textAlign='center' letterSpacing='0.19px' letterHeight='1.6' fontSize='20px' fontWeight='600'>
                {'Oops! Provider not found'}
            </Ptag>
            <AddProvider onClick={() => props.addHospital('/addHospital')}>ADD PROVIDER</AddProvider>
        </Root>
    )
}

export default NoHospital;

const Root = styled.div`
    display:flex;
    width: 100%;
    flex-direction: column;
    justify-content:space-between;
    & > :first-child{
        margin: 24px auto;
    }
`;

const AddProvider = styled.button`
    width: 152px;
    margin:auto;
    margin-top:24px;
    border-radius: 5px;
    border: solid 1px #436ab2;
    padding: 10px 24px;
    font-family: Avenir;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #4a4a4a;
    background-color: white;
`;

