import React from 'react'
import styled from 'styled-components'
import {Header,OverlayLoader,Icons} from '../../components'
import {Ptag} from '../../components/fonts'
import { formatAmount } from '../../utils/utils'


export default class TrackDownCard extends React.Component {

    state = {
        expanded:false
    }
    constructor(props){
        super(props)
    }
    onClickCardHeader = () => {
        const expanded = this.state.expanded
        this.setState({expanded: !expanded})
    }

    render(){
        const date = this.props && this.props.transactionData && this.props.transactionData &&  this.props.transactionData.transactions[0] && this.props.transactionData.transactions[0].credit_transaction && this.props.transactionData.transactions[0].credit_transaction.emi_tenure && this.props.transactionData.transactions[0].credit_transaction.date 
        const formattedDate = date.split('T')[0].split('-').reverse().join('/')
        return(
            <Card>
                <CardHeader onClick={() => this.onClickCardHeader()}>
                    <Ptag style={{'textOverflow': 'ellipsis', 'overflow' : 'hidden', 'whiteSpace': 'nowrap', 'display':'inline-block' }} fontSize='14px' textTransform='capitalize' letterSpacing='0.3px'>{this.props.transactionData.hospital && this.props.transactionData.hospital['hospital_name'].toLowerCase() || 'No hospital'}</Ptag>
                    <div style={{'min-width':'17px'}} >
                        {this.state && this.state.expanded ? <Icons icon='chevron_up'/> : <Icons icon='chevron_down' />}
                    </div>
                </CardHeader>
                {
                    this.state && this.state.expanded &&
                    <CardBody>
                        <CardBodyTable>
                            <thead>
                                <CardBodyRow>
                                    <TableHeading>
                                        {'Total Amount'}
                                    </TableHeading>
                                    <TableHeading>
                                        {'Tenure'}
                                    </TableHeading>
                                    <TableHeading>
                                        {'Status'}
                                    </TableHeading>
                                </CardBodyRow>
                                <CardBodyRow>
                                    <TableBody>
                                        {'₹'}{this.props && this.props.transactionData && this.props.transactionData &&  this.props.transactionData.transactions[0] && formatAmount(parseInt(this.props.transactionData.transactions[0]['total_amount']))}
                                    </TableBody>
                                    <TableBody>
                                        {this.props && this.props.transactionData && this.props.transactionData &&  this.props.transactionData.transactions[0] && this.props.transactionData.transactions[0].credit_transaction && this.props.transactionData.transactions[0].credit_transaction.emi_tenure && this.props.transactionData.transactions[0].credit_transaction.emi_tenure.months} {'months'}
                                    </TableBody>
                                    <TableBody >
                                    {this.props && this.props.transactionData && this.props.transactionData &&  this.props.transactionData.transactions[0] && this.props.transactionData.transactions[0].transaction_status}
                                    </TableBody>
                                </CardBodyRow>
                            </thead>
                            
                        </CardBodyTable>
                        
                        <ViewReceiptRow>
                            <LastUpdate>
                                <Ptag letterSpacing='0.43px' color='#7d7c7c'>
                                    {'Last Updated'}
                                </Ptag>
                                <Ptag fontWeight='600' color='#4a4a4a' letterSpacing='1.25px'>
                                    {formattedDate}
                                </Ptag>
                            </LastUpdate>
                            {
                                this.props.transactionData.transaction_status === 'Success' && 
                                <ViewButton onClick={this.onClickViewReceipt}>
                                    {'View Reciept'}
                                </ViewButton>
                            }
                        </ViewReceiptRow>
                        {/* <Ptag lineHeight='1.29' fontWeight='600' fontSize='14px' style={{'marginTop':'14px',}}>
                            {'EMI Details'}
                        </Ptag>
                        
                        <CardBodyTable marginTop='14px'>
                            <tfoot>
                                <CardBodyRow>
                                    <TableHeading paddingBottom='14px' fontSize='14px'>
                                        {'Total Amount'}
                                    </TableHeading>
                                    <TableHeading paddingBottom='14px' fontSize='14px'>
                                        {'Tenure'}
                                    </TableHeading>
                                
                                </CardBodyRow>
                            </tfoot>                            
                        </CardBodyTable> */}

                    </CardBody>
                }
            </Card>
        )
    }
}

const Card = styled.div`
    display:flex;
    flex-direction:column;
    border-radius: 5px;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
    margin-bottom: 12px;
`;

const CardHeader = styled.div`
    display:flex;
    width:100%;
    justify-content:space-between;
    align-items: center;
    padding:16px;
    height:46px;
`;

const CardBody = styled.div`
    padding: 20px 18px 30px 18px;
    border-top: 1px solid #979797;
`;

const CardBodyTable = styled.table`
    table-layout: fixed;
    width:100%;
    margin-top: ${props => props.marginTop ? props.marginTop : '0px'}
`;

const CardBodyRow = styled.tr`
    border-collapse: collapse;
`;

const TableHeading = styled.th`
    font-family: Avenir;
    font-size: ${ props => props.fontSize ? props.fontSize : '12px'};
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #436ab2;
    padding-bottom: ${props => props.paddingBottom ? props.paddingBottom : '8px'};
`;

const TableBody = styled.td`
    font-family: Avenir;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    text-transform: capitalize;
    color: #4a4a4a;
`;

const ViewReceiptRow = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 34px;
    //padding-bottom: 18px;
    //border-bottom: 1px solid #d8d8d8;
`;

const LastUpdate = styled.div`
    display:flex;
    flex-direction:column;
    & > :nth-child(1){
        margin-bottom: 4px;
    }
`;

const ViewButton =styled.button`
    display:flex;
    padding: 10px 20px;
    border-radius: 5px;
    border: solid 1px #436ab2;
    font-family: Avenir;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    color: #7d7c7c;
    background-color:white;
`;