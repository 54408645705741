import React, { Component } from "react";
import styled from "styled-components";
import Credit5 from "../assets/svgs/credit5.svg";

const Wrapper = styled.div`
  width: 100%;
  background: rgba(238, 238, 238, 1);
  padding: 2px 0 3.6rem 0;
`;
const Header = styled.div`
  // width: 32rem;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  // margin-bottom: rem;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Image = styled.img`
  background: rgba(238, 238, 238, 1);
`;

const Font28 = styled.p`
  font-family: Proxima Nova;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.21;
  letter-spacing: 0.5px;
  text-align: left;
  color: #4a4a4a;
`;
const Font24 = styled.div`
    font-family: Proxima Nova;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: 1px;
    color: #009999;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "none")}

    @media (min-width: 1024px) {
      font-size: ${(props) => (props.fontSize ? props.fontSize : "none")}
      margin-bottom: 8px;
    }
`;
const Font16 = styled.div`
    font-family: Avenir;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: 0.47px;
    color: #4a4a4a
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "16px")};

    @media (min-width: 1024px) {
      font-size: 18px;
      width: 801px;
      padding-top: 6px;
      margin-bottom: ${(props) =>
        props.marginBottom ? props.marginBottom : ""}
    }
`;
const ButtonStart = styled.button`
  margin-top: 2.4rem;
  width: 18rem;
  height: 4.8rem;
  background-color: #436ab3;
  border-radius: 1rem;
  font-size: 1.6rem;
  letter-spacing: 1px;
  color: #ffffff;
  font-family: Avenir;
  font-weight: 600;
  border: none;

  @media (min-width: 1024px) {
    // margin-top: 2.4rem;
    margin-top: 3.8rem;
    width: 30.93rem;
    height: 8.4rem;
    font-size: 2.8rem;
    margin-bottom: 16px;
  }

  @media (min-width: 1680px) {
    // margin-top: 3.85rem;
    // margin-top: 1.3rem;
  }
`;

export default class Page46 extends Component {
  render = () => {
    return (
      <React.Fragment>
        <Wrapper>
          <Header>
            <Font24 fontSize="34px"> We’ve got you covered! </Font24>
            <Font16>
              Vital finances any unforeseen hospitalization expenses, planned
              elective procedures & all wellness treatments when you need it.
              And what's more it also gets you the best offers for your regular
              health needs and gets you cashbacks.
            </Font16>
            <Font16 marginTop="12px" marginBottom="48px">
              All this is done by bringing together a curated health & wellness
              marketplace, customised health insurance and a zero cost health
              credit line.
            </Font16>
            <Font24 marginTop="24px" fontSize="36px">
              In short, we help you create your personal safety net!
            </Font24>
            <ButtonStart onClick={() => this.props.redirectToSignUp()}>
              GET STARTED
            </ButtonStart>
          </Header>
        </Wrapper>
      </React.Fragment>
    );
  };
}
