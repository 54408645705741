import styled from 'styled-components'

import React from 'react'
import vitalcash from '../../../assets/svgs/hospitals.svg'

export default class BankComponent extends React.Component {
  constructor(props) {
    super(props)
  } 

  render() {
    return (
      <VCareSubDiv onClick={() => this.props.onSelectBank(this.props.idx)} isSelected={this.props.isSelected}>
        <VCareImage src={this.props.image_url} />
        <VCareHeading>{this.props.title}</VCareHeading>
      </VCareSubDiv>
    )
  }
}

const VCareSubDiv = styled.div`
  min-width: 83px;
  height: 83px;
  display: flex;
  border-radius: 15px;
  border: solid 1px #f5f5f5;
  background-color: #ffffff;
  margin-right: 24px;
  flex-direction: column;
  justify-content: center;
  box-shadow: ${props => (props.isSelected ? '0 3px 6px 0 rgba(0, 0, 0, 0.16)' : null)};
  border: ${props => (props.isSelected ? 'solid 1px #436ab2' : null)};
  border-radius: 15px;
  border: solid 1px #f5f5f5;
  background-color: #ffffff;
  // &:hover,
  // &:select {
  //   box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  //   border: solid 1px #436ab2;
  //   background-color: #ffffff;
  // }
  // &:focus {
  //   box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  //   border: solid 1px #436ab2;
  //   background-color: #ffffff;
  // }
`

const VCareImage = styled.img`
  max-height: 40px;
  max-width: 60px;
  display: flex;
  padding: 5px;
  padding-bottom: 0px;
  align-self: center;
`

const VCareHeading = styled.p`
  font-family: Avenir;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  margin-top: 8px;
  letter-spacing: 0.15px;
  text-align: center;
  color: #4a4a4a;
`

const VCareText = styled.p`
  font-family: Avenir;
  font-size: 10px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  margin: 5px;
  margin-top: 4px;
`
const LineDiv = styled.div`
  height: 1px;
  margin-top: 14px;
  border-radius: 12px;
  background-color: rgba(247, 201, 201, 0.37);
`
const VCareTextDiv = styled.div`
  width: 100%;
  height: 50px;
`
