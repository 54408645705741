import React, { Component } from 'react'
import styled from 'styled-components'
import Phonecoverpage6 from '../assets/images/Phonecoverpage6.png'
import Screenshotpage6 from '../assets/images/Screenshotpage6.png'
import dashboardPage from '../assets/images/dashboardPage.png'
import circlepage3 from '../assets/images/circlepage3.png'
import Groupdotspage4 from '../assets/images/Groupdotspage4.png'
import chatBox1 from '../assets/svgs/chatBox1.svg'
import chatBox2 from '../assets/svgs/chatBox2.svg'
import vitalChat_2 from '../assets/images/vitalChat_21.png'
import thumbpage3 from '../assets/images/thumbpage3.png'
import vitalIphone2 from '../assets/images/vitalIphone2.png'
// import vitalIphone2 from '../assets/svgs/vitalIphone2.svg'
import ReligareGroup from '../assets/images/ReligareGroup.png'
import Screenshot1024page6 from '../assets/images/assets1024/Screenshot1024page6.png'
import Phonecover1024page6 from '../assets/images/assets1024/Phonecover1024page6.png'
import page3 from '../assets/images/updatedlanding1/page3.png'
import page6small from '../assets/images/updatedlanding1/page6small.png'
import page6update from '../assets/images/updatedlanding1/page6update.png'
import Dummy from "../assets/images/abc.png";
const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background-image: linear-gradient(#fafafa, #eaeaea);

  @media (min-width: 1024px) {
    // height: 52.6rem;
  }
`
const Header = styled.div`
  // top: 3.55rem;
  padding: 3.6rem 0 3.6rem 0;
  // padding: 3.55rem 2.4rem 3.6rem 2.4rem;
  //   padding: 3.55rem 2.4rem 2.4rem 2.4rem;
  position: relative;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17rem 0 14rem 0;
  }

  @media (min-width: 1680px) {
    padding: 24.9rem 0;
  }
`
const Subheader = styled.div`
  margin-bottom: 2.4rem;
  padding-left: 2.4rem;
  @media (min-width: 1024px) {
    padding-left: 0;
    width: 40.6rem;
    margin: 0;
    margin-right: 180px;
  }

  @media (min-width: 1680px) {
    // padding-left: 14.6rem;
    width: 73.3rem;
    margin: 0;
    margin-right: 18.7rem;
  }
`

const H2 = styled.h2`
  width: 32rem;
  font-family: Proxima Nova;
  font-size: 2.4rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: 0.2px;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    width: 100%;
    font-size: 3.4rem;
    line-height: 1.29;
    letter-spacing: 0.02px;
  }

  @media (min-width: 1680px) {
    font-size: 6rem;
    letter-spacing: 0.03px;
  }
`

const H21 = styled.p`
  width: 26rem;
  font-family: Proxima Nova;
  font-size: 2.8rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  // line-height: 1.67;
  letter-spacing: 0.24px;
  text-align: left;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    width: 100%;
    font-size: 3.4rem;
    line-height: 1.3;
    // line-height: 1.59;
    letter-spacing: 0.02px;
  }

  @media (min-width: 1680px) {
    font-size: 6rem;
    line-height: 1.2;
    // line-height: 1.61;
    letter-spacing: 0.03px;
  }
`

const H3 = styled.h3`
  margin-top: 1.2rem;
  width: 29rem;
  font-family: Avenir;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: 0.02px;
  text-align: left;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    width: 45rem;
    margin-top: 2.4rem;
    font-size: 1.8rem;
    line-height: 1.56;
    letter-spacing: 0px;
  }

  @media (min-width: 1680px) {
    margin-top: 4.5rem;
    width: 69.3rem;
    font-size: 3.4rem;
    font-weight: 400;
    line-height: 1.59;
    letter-spacing: 0.02px;
  }
`

const Section = styled.div`
  width: 100%;
  height: 9.25rem;
  display: flex;
  flex-direction: row;
  display: -webkit-flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  position: absolute;
  margin-top: 29.9rem;
  z-index: 1;
`

const Imagecircles = styled.div`
  width: 4.57rem;
  height: 9.24rem;
  background-image: url(${circlepage3});
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  bottom: 0;
  // top: 6.5rem;

  @media (min-width: 1024px) {
    background-image: url(${circlepage3});
    height: 5.81rem;
    width: 5.47rem;
    // top: 16.7rem;
  }
`

const Imagedots = styled.div`
  background-image: url(${Groupdotspage4});
  background-repeat: no-repeat;
  position: absolute;
  height: 4.44rem;
  width: 4.45rem;
  left: 3rem;
  top: 40%;

  @media (min-width: 1024px) {
    // top: 12rem;
  }
`

const Footer = styled.div`
  width: 100%;
  display: flex;
  display: webkit-flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  top: 33.6rem;
  height: 24rem;

  @media (min-width: 1024px) {
    height: 28rem;
    top: 25rem;
  }
`

const Imagephone = styled.div`
 width: 23.75rem;
  height: 48.230rem;
  margin: 0 auto;
  background-image: url("${props => props.error ? props.errorSrc : (props.loading ? props.loadingSrc : props.actualSrc)}");
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: 1024px) {
    margin: 0;
    // background-image: url(${page6update});
  }

  @media (min-width: 1680px) {
     height: 106.615rem;
    width: 52.5rem;
    background-image: url("${props => props.error ? props.errorSrc : (props.loading ? props.loadingSrc : props.actualSrc)}");
  }
`
const Font16 = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: 1px;
  color: #4a4a4a;
  margin-top: 16px;
  // padding-left: 24px;
  padding-bottom: 24px;
`

const ScreenshotImage = styled.div`
  background-image: url(${Screenshotpage6});
  width: 10.4rem;
  height: 18.59rem;
  position: absolute;
  margin-top: 6rem;
  margin-left: 15rem;
  display: none;
  @media (min-width: 1024px) {
    display: none;
    height: 27.5rem;
    width: 15.5rem;
    background-image: url(${Screenshot1024page6});
    position: absolute;
    left: 7rem;
  }
`
const ChatBoxImg = styled.div`
  width: 17rem;
  height: 7.3rem;
  background-image: url(${chatBox1});
  background-repeat: no-repeat;
  position: absolute;
  left: 18rem;
  top: 20rem;
  z-index: 10;

  @media (min-width: 1024px) {
    height: 7.5rem;
    width: 17rem;
    left: 25rem;
    top: 19rem;
    background-image: url(${chatBox1});
  }

  @media (min-width: 1680px) {
    height: 20.5rem;
    width: 48.1rem;
    background-image: url(${chatBox2});
    top: 22rem;
    left: 45rem;
  }
`
const ChatMsg = styled.p`
  font-family: Avenir;
  font-size: 1.4rem;
  font-weight: 600;
  white-space: nowrap;
  letter-spacing: 0.5px;
  color: #4a4a4a;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -85%);

  @media (min-width: 1680px) {
    font-size: 3.4rem;
  }
`
const ImageDv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Image = styled.img`
  display: flex;
  width: 300px;
  height: 267px;

  @media (min-width: 1680px) {
    width: 500px;
    height: 467px;
  }
`

const LazyImage = ({ loadingSrc, actualSrc, errorSrc, ...props }) => {
  const [isImageLoaded, setImageLoaded] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  React.useEffect(() => {
    const img = new Image();

    img.onload = () => setImageLoaded(true);
    img.onerror = () => setHasError(true);

    img.src = actualSrc;
  }, [actualSrc])

  return (
    <Imagephone
      loadingSrc={loadingSrc}
      actualSrc={actualSrc}
      errorSrc={actualSrc}
      loading={!isImageLoaded}
      error={hasError}
      {...props}
    />
  )
}

export default class Page6 extends Component {
  render = () => {
    return (
      <Wrapper id={this.props.id}>
        <Header>
          <Subheader>
            <H2>A concierge service <br />just for you</H2>
            {/* <H21> company not an insurance company or a bank</H21> */}
            <H3>
              Every member gets a dedicated care manager, which means that every time you call 
              or message you reach the same person and not a call centre. Get help on benefits, 
              coverage, and insurance claims from a team that knows you well.
            </H3>
            <Font16>
              Chat. Mail. Talk
            </Font16>
          </Subheader>
          <ImageDv>
            {/* <Image src={vitalChat_2}/> */}
            <Image alt="concierge service" src = "http://images.getvital.in/homelanding/vitalChat_21.png" />
          </ImageDv>
          {/* <ChatBoxImg><ChatMsg>Hi, how can we help?</ChatMsg></ChatBoxImg> */}
          {/* <LazyImage
                       loadingSrc={vitalChat_2}
                       actualSrc="http://images.getvital.in/images/chat-50%25_176.9kb+(1).png"
                       errorSrc={vitalChat_2}
          /> */}
          

        
          
         
            
          <Imagecircles />
          {/* <Imagedots /> */}
        </Header>
        {/* <Section>
          <Imagecircles />
          <Imagedots />
        </Section> */}
        {/* <Footer>
          <Imagephone>
            <ScreenshotImage />
          </Imagephone>
        </Footer> */}
         {/* <LazyImage
            actualSrc={"http://images.getvital.in/images/Onboarding+2x.png"}
            loadingSrc={vitalIphone2}
            errorSrc= {vitalIphone2}
            /> */}
      </Wrapper>
    )
  }
}
