import React, { Component } from 'react'
import styled from 'styled-components'
import onClickOutside from 'react-onclickoutside'
import Input from './input'
import Icons from '../components/icons'
import {initGA,Event,PageView} from '../Tracking'
const Input16 = styled.p`
  display: block;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.87);
`

const SelectWrapper = styled.div`
  width: 100%;
  position: relative;

  & > label > p {
    color: ${props => props.selected !== '' && 'rgba(0, 0, 0, 0.54)'};
    transform: ${props => props.selected !== '' && 'translateY(-22px) scale(0.9)'};
  }
`
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;

  & svg {
    margin-left: -2.8rem;
  }
`

const Ul = styled.ul`
  width: 100%;
  padding: 8px 0;
  max-height: 16rem;
  border-radius: 8px;
  list-style: none;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  background-color: #fff;

  white-space: no-wrap;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;

  transform-origin: top;
  transform: ${props => (props.toggleList === false ? 'translateY(0) scaleY(0)' : 'translateY(50%) scaleY(100%)')};
  transition: all 0.3s;
  backface-visibility: hidden;
`

const Li = styled.li`
  padding: 1.2rem 1.6rem;

  &:hover,
  &:focus {
    background-color: #3586f6;
  }
`
const ListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;

  & > svg {
    // margin-left: -10rem;
    margin-left: -20rem;
  }
`

const Error = styled.p`
  color: red;
  font-size: 1.4rem;
  // @media (max-width: 768px) {
  //   font-size: 14px;
  //   padding-top: 4px;
  // }
  // @media (min-width: 769px) {
  //   font-size: 14px;
  //   padding-top: 4px;
  // }
  // @media (max-width: 400px) {
  //   font-size: 10px;
  //   padding-top: 4px;
  // }
`

class DropDownSmartSearch extends Component {
  state = {
    toggleList: false,
    selected: '',
    Checked: true
  }

  findItem = value => this.props.option.filter(item => item.value === value)

  componentDidMount = () => {
    // initGA('UA-146381335-1');
    // PageView();
    if (this.props.value && this.props.value !== '') {
      const itemArr = this.findItem(this.props.value)
      const selected = itemArr[0] && itemArr[0].label ? itemArr[0].label : ''

      this.setState({ selected })
    } else {
      this.setState({ selected: this.props.selectedOption.label })
    }
    
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.value !== prevProps.value) {
      const itemArr = this.findItem(this.props.value)
      const selected = itemArr[0] && itemArr[0].label ? itemArr[0].label : ''

      this.setState({ selected })
    }
  }

  handleClickOutside = evt => this.props.toggleList && this.props.onCloseList()

  handleItemClick = item => this.props.onListItemClick(item)

  render() {
    const {
      INRSign,
      error,
      Readonly,
      Checked,
      recommend,
      value,
      enableIcon,
      uploaded,
      e_category,
      e_label,
      option,
      onListItemClick,
      onCloseList,
      ...restProps
    } = this.props
    return (
      <SelectWrapper selected={this.state.selected}>
        <InputWrapper>
          <ListItem>
            <Input
              placeholder={this.props.placeholder}
              // readOnly={true}
              // value={this.state.selected}
              value={this.props.value}
              error={error}
              //icon1={this.props.toggleList ? { name: 'triangle_up' } : { name: 'triangle_down' }}
              icon2={
                enableIcon && value !== '' && uploaded ? { name: 'roundCheckCircle', width: 26, height: 24 } : null
              }
              {...restProps}
            />
          </ListItem>
        </InputWrapper>

        {/* {!this.state.toggleList && <Error>{error}</Error>} */}
        {option.length > 0 &&
          this.props.toggleList && (
            <Ul>
              {option.map((item, i) => (
                <Li key={item.id || i} onClick={() => this.handleItemClick(item)}>
                  <ListItem>
                    {INRSign ? <Input16>&#8377; {item.label}</Input16> : <Input16>{item.label}</Input16>}
                    {enableIcon &&
                      item.value.toUpperCase() === recommend && (
                        <Icons icon="roundCheckCircle" width={26} height={24} />
                      )}
                  </ListItem>
                </Li>
              ))}
            </Ul>
          )}
      </SelectWrapper>
    )
  }
}

export default onClickOutside(DropDownSmartSearch)
