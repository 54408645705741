import React from "react";
import DefaultCard from "./DefaultCard";
import PaymentIcon from '../../../assets/svgs/payments.svg';
import { Ptag } from "../../fonts";
import formatAmount from "../../memberApp/common/utils";
import { CardRow, IconWrapper } from "./styles";

const CdCardDetail = ({
  latest_transaction_date,
  current_cd_balance,
  shortfall,
  remaining_cd_balance,
}) => {
  const subtitle = `last updated on ${latest_transaction_date}`;
  return (
    <DefaultCard title="CD Account Details" subtitle={subtitle}>
      <section style={{ paddingTop: "1rem",paddingRight: "2rem", paddingButtom:"1rem", paddingLeft: "2rem"}}>
        {!!current_cd_balance &&
          <CardRow>
            <IconWrapper>
              <img src={PaymentIcon} />
              <Ptag fontSize="14px">Current Balance</Ptag>
            </IconWrapper>
            <Ptag fontSize="14px" fontWeight="600">{`₹${formatAmount(
              current_cd_balance
            )}`}</Ptag>
          </CardRow>
        }
        {!!shortfall &&
          <CardRow>
            <IconWrapper>
              <img src={PaymentIcon} />
              <Ptag fontSize="14px">Shortfall Amount</Ptag>
            </IconWrapper>
            <Ptag fontSize="14px" fontWeight="600">{`₹${formatAmount(
              shortfall
            )}`}</Ptag>
          </CardRow>
        }
        {!!remaining_cd_balance &&
          <CardRow>
            <IconWrapper>
              <img src={PaymentIcon} />
              <Ptag fontSize="14px">Remaining Balance</Ptag>
            </IconWrapper>
            <Ptag fontSize="14px" fontWeight="600">{`₹${formatAmount(
              remaining_cd_balance
            )}`}</Ptag>
          </CardRow>
        }
      </section>
    </DefaultCard>
  );
};

export default CdCardDetail;
