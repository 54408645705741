import styled from "styled-components"

export const CareEmployeesBox = styled.div`
    display:flex;
    padding-top: 4rem;
    padding-bottom: 2rem;
`

export const MainDiv = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-left: 4rem;
  overflow-x: hidden;
`

export const Divider = styled.div`
    background-color: #436AB2;
    height: 1.5px;
    margin: 2rem 0rem;
`

export const InsuranceCardsWrapper = styled.section`
  display: flex;
  gap: 2rem;
  overflow: scroll;
  width: 100%;
  margin-top: 2rem;
  user-select: none;
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }
`

export const SplitWrapper = styled.section`
  display: flex;
  flex-direction: row;
  padding-top: 4rem;
  padding-bottom: 2rem;
  gap: 2rem;
`

export const ECardPreviewMain = styled.section`
  background-color: white;
  padding: 3rem 4rem;
`

export const ECardDownloadWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
  margin: 6rem 0rem;
`