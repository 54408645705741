import React, { useState, useMemo, useEffect, forwardRef, useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { TableVirtuoso } from "react-virtuoso";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { formatNumberToIndianNationalSystem } from "../../../utils/utils";

import {
  DownloadLink,
  Dropdown,
  FilterImage,
  BoxHeading,
  ContentRow,
  FilterDiv,
  HeaderRow,
  InputDiv,
  MaterialTable,
  MaterialTableContainer,
  Option,
  SearchfilterDiv,
  TableWrapper,
  Download,
} from "../../../components/GmcDashbaord/styles";
import { Ptag } from "../../../components/fonts";
import DownloadIcon from "../../../assets/svgs/download_white.svg";
import FilterIcon from "../../../assets/svgs/filter.svg";
import { FlexProductList, IndividualList } from "./styles";
import { FLEX_PRODUCTS } from "../../../constants/businessConstant";
import { theme } from "../../../theme";
import { Chip, LoaderDots } from "../../../components";
import http from "../../../services/httpService";
import Icons from "../../../components/business/icons";

const FlexMisTable = () => {
  const [sumInsured, setSumInsured] = useState('')
  const [columns, setColumns] = useState([
    {
      width: 120,
      label: "Employee ID",
      dataKey: "enrollment_id",
    },
    {
      width: 120,
      label: "Name",
      dataKey: "name",
    },
    {
      width: 120,
      label: "Gender",
      dataKey: "gender",
    },
    {
      width: 120,
      label: "Date Of Birth",
      dataKey: "dob",
    },
    {
      width: 220,
      label: "Email",
      dataKey: "email",
    },
    {
      width: 120,
      label: "Mobile",
      dataKey: "phone",
    },
    {
      width: 120,
      label: "Dependents",
      dataKey: "no_of_dependents",
    },
    {
      width: 130,
      label: "Cover Start Date",
      dataKey: "start_date",
    },
    {
      width: 150,
      label: "System Update Date",
      dataKey: "system_update_date",
    },
    {
      width: 120,
      label: "E-Card",
      dataKey: "e_card",
    }
  ]);
  
  const [misTbaleData, setMisTableData] = useState([]);
  const [overallData, setOverallData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [currentProductDetail, setCurrentProductDetail] = useState();
  const timerRef = useRef(null);
  const [currentProduct, setCurrentProduct] = useState(
    JSON.parse(localStorage.getItem("flex_product_priority_list"))[0]
  );
  const attributes = JSON.parse(localStorage.getItem("gmc_details"))
  const history = useHistory();
  useEffect(() => {
    getModernFlex();
  }, []);
  const KeyAttributePair = useMemo(() => {
    const result = Object.entries(attributes).map(([key, value]) => ([
      key, value.attributes_data
        .filter((attribute) => attribute.employee_visibility)
        .map(({ attribute_id }) => attribute_id),
    ]));
  
    return result;
  }, [attributes]);

  const memoizedAttribute = useMemo(() => {
    const attributesData = attributes[currentProduct] && attributes[currentProduct].attributes_data || [];
    
    const filteredAttributes = attributesData
      .filter((attribute) => attribute.employee_visibility)
      .map(({ attribute_name, attribute_id }) => [attribute_name, attribute_id]);
    const index = filteredAttributes.findIndex((sum_insured) => {
        return sum_insured[0] === "Sum Insured"
      });
      const sumInsuredElement = index !== -1 ? filteredAttributes[index][1] : null;
      setSumInsured(sumInsuredElement)
    const newColumns = [
      ...columns.slice(0, 10), // Keep the first 10 columns
      ...filteredAttributes.map(([label, dataKey]) => ({
        width: 120,
        label,
        dataKey: dataKey.toString(),
      })),
    ];
    newColumns.push({
      width: 120,
      label: "Status",
      dataKey: "policy_status",
    })
    setColumns(newColumns);
    
    return 1;
  }, [currentProduct]);

  useEffect(() => {
    window.addEventListener('error', e => {
      console.log(e.message)
        if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
            const resizeObserverErrDiv = document.getElementById(
                'webpack-dev-server-client-overlay-div'
            );
            const resizeObserverErr = document.getElementById(
                'webpack-dev-server-client-overlay'
            );
            if (resizeObserverErr) {
                resizeObserverErr.setAttribute('style', 'display: none');
            }
            if (resizeObserverErrDiv) {
                resizeObserverErrDiv.setAttribute('style', 'display: none');
            }
        }
    });
}, []);

  const VirtuosoTableComponents = {
    Scroller: forwardRef((props, ref) => (
      <TableContainer {...props} ref={ref} sx={MaterialTableContainer} />
    )),
    Table: (props) => <Table {...props} sx={MaterialTable} />,
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  };
  
  function fixedHeaderContent() {
    return (
      <TableRow>
        {columns.map((column, index) => (
          <TableCell
            key={index}
            variant="head"
            align="center"
            style={{ width: column.width }}
            sx={HeaderRow}
          >
            {column.label}
          </TableCell>
        ))}
      </TableRow>
    );
  }
  function isActivatedFlexEmployee(row){
    return row.policy_status === "ACTIVATED";
  }
  const getFlexProductStatus = (detail) => {
    const productStatus = detail.policy_status
    switch (productStatus) {
        case "ACTIVATION PENDING":
          return <Chip background="#F7CB59" text={` Pending`} />;
        case "ACTIVATED":
          return <Chip background="#81C14B" text={`Activated`} />;
        case "DELETION INITIATED":
          return <Chip background="#FF9446" text={`Deletion Initiated`} />;
        case "STOPPED":
          return <Chip background="#F27576" text={`Stopped`} />;
        default:
          return null;
      }
}


  function jsxRow(column, index, row){
    let JSX;
    const columnWidth = column.width + "px";
    switch(column.dataKey) {
      case 'e_card':
        JSX = 
        <TableCell
          align="center"
          sx={ContentRow}
          key={index}
          style={{ width: columnWidth }}
        >
            {isActivatedFlexEmployee(row)? (
              <DownloadLink
              onClick={()=>navigateToEcardPreview(row)}
              >
                View
              </DownloadLink>
            ): (
              <Ptag
                textAlign="center"
                fontSize="14px"
                lineHeight={1.3}
                fontWeight="500"
            >
                -
            </Ptag>
            )
            }
        </TableCell>
        break;
      case 'policy_status':
        JSX = <TableCell>
              {getFlexProductStatus(row)}
          </TableCell>
        break;
      case sumInsured.toString():
        JSX = <TableCell>
          {`₹${formatNumberToIndianNationalSystem(row[column.dataKey])}`}
        </TableCell>
        break;
      default:
        JSX = <TableCell
          align="center"
          sx={ContentRow}
          key={index}
          // style={{ width: column.width }}
        >
          {row[column.dataKey]}
        </TableCell>
    }
    return JSX
  }
  
  function rowContent(_index, row) {
    let JSX;
    try {
      JSX = (
        <>
          {columns.map((column, index) => (
            jsxRow(column, index, row)
          ))}
        </>
      );
    } catch (error) {}
    return JSX;
  }
  

  const navigateToEcardPreview = (row) => {
    history.push({
            pathname: "/business/e-card-preview",
            state: { employeeId: row.employee_id, productType: currentProduct },
          });
    // navigate("/e-card-preview", {state:{employeeId:row.employee_id ,productType: currentProduct}})
  }
  const getModernFlex = async (searchString) => {
    let localToken = -1;
    let error = false;
    while (localToken !== 0 && localToken !== null && error === false) {
      const payload = {
        token: localToken,
        offset: 100,
        mis_flag: true,
      }
      if(searchString) payload.search_string = searchString;
      try {
        let { data } = await http.get(
          "business/get_employee_detail_for_group_products/",
          {
            params: payload
          }
        );
        // this.setState({token: originalData[1]})
        localToken = data[1];
        const employeeSuperset = Object.entries(data[0]).map(([key, val]) => [
          val.product_name,
          val.employees_data,
        ]);
        const updatedEmployeeSuperset = employeeSuperset.reduce((acc, [key, value]) => {
          const newData = KeyAttributePair.find(val => val[0] === key);
          
          const updatedValue = value.map(employee => {
            const updatedEmployee = { ...employee };
            newData[1].forEach(val => {
              const attr = employee.attributes_data.find(attr => attr.attribute === val);
              let v = attr.selected_value || attr.default_value
              updatedEmployee[val] = attr.selected_value || attr.default_value || '-';
            });
            return updatedEmployee;
          });
          
          acc.push([key, updatedValue]);
          
          return acc;
        }, []);
        updatedEmployeeSuperset.forEach(([value, itemToAdd]) => {
          setOverallData((prevState) => {
            let found = false;
            const updatedState = prevState.map((innerArray) => {
              if (innerArray[0] === value) {
                found = true;
                return [innerArray[0], innerArray[1].concat(itemToAdd)];
              } else {
                return innerArray;
              }
            });

            if (!found) {
              updatedState.push([value, itemToAdd]);
            }
            return updatedState;
          });
        });
        const newData = Object.values(data[0])[1]["employees_data"];
        let previousData = misTbaleData;
        previousData.concat(newData);
        await setMisTableData((previousState) => previousState.concat(newData));
      } catch (e) {
        error = true
        console.log(e);
      }
    }
  };
  const navigeteToAddDeletPage = () => {
    history.push("/flex/setup/master", { state: { addDelete: true } });
  };
  const flexProductList = () => {
    const productList = Object.entries(
      JSON.parse(localStorage.getItem("gmc_details"))
    );
    return (
      <>
        <FlexProductList>
          <thead>
            <tr>
              {productList.map(([productName, value], index) => {
                return (
                  <IndividualList
                    key={index}
                    bgColor={
                      productName === currentProduct
                        ? theme.primaryColor
                        : "white"
                    }
                    onClick={() => setCurrentProduct(productName)}
                  >
                    <Ptag
                      fontSize="14px"
                      textAlign="center"
                      lineHeight={1.3}
                      color={
                        productName === currentProduct
                          ? "white"
                          : theme.primaryColor
                      }
                      fontWeight="600 !important"
                    >
                      {FLEX_PRODUCTS[productName]}
                    </Ptag>
                  </IndividualList>
                );
              })}
            </tr>
          </thead>
        </FlexProductList>
      </>
    );
  };
  const downloadExcel = async () => {
    let {data} = await http.post("business/download_flex_mis_data/", {}, { responseType: 'blob' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(new Blob([data]));
    link.download = 'employee_data.xlsx';

    // Append link to the DOM and click it
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);
  };
  const getCurrentEmployees = () => {
    let data = overallData.find((innerList) => innerList[0] === currentProduct);
    return data && data[1];
  };

  const shouldShowHalf = () => {
    const totalEmp = getCurrentEmployees().length;
    if(totalEmp > 4) {
      return false;
    }
    return true;
  }

  const handleSearchChange = (e) => {
    const newSearchString = e.target.value;
    setSearchString(newSearchString);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    // Set a new timer to call the API after 2 seconds
    timerRef.current = setTimeout(async() => {
      setOverallData([]);
      await getModernFlex(newSearchString);
    }, 500);
  }

  return (
    <>{
      !getCurrentEmployees()?
      <div style={{display:'flex', justifyContent:'center', padding:'44px'}}><LoaderDots/></div>:
      <>
      <BoxHeading>
        <Ptag fontSize="24px" fontWeight="700" marginTop="28px">
          Manage Employees
        </Ptag>
      </BoxHeading>
      <SearchfilterDiv>
        <InputDiv>
          <input
            value={searchString}
            onChange={handleSearchChange}
            className="input-elevated"
            type="text"
            placeholder="Search with Employee ID, Mobile No. or Name"
          />
        </InputDiv>
        <div style={{ display: "flex" }}>
          {/* <FilterDiv>
            <FilterImage src={FilterIcon} />
            <Dropdown color="#fff">
              <Option value="" default disabled selected hidden>
                Filter
              </Option>
              <Option value={"none"}>{"None"}</Option>
            </Dropdown>
            <div style={{ marginRight: "12px" }}>
              <Icons icon={"chevron_down"} fill="#ffffff" />
            </div>
          </FilterDiv> */}
          <Download onClick={downloadExcel}>
            <img
              src={DownloadIcon}
              width="18px"
              height="18px"
              style={{ marginRight: 8 }}
            />
            <Ptag
              fontSize="14px"
              fontWeight="600"
              color="#FFFFFF"
              lineHeight="24px"
              letterSpacing="0.5px"
            >
              Download
            </Ptag>
          </Download>
        </div>
      </SearchfilterDiv>
      {flexProductList()}
      <TableWrapper showHalf={shouldShowHalf()}>
        <TableVirtuoso
          data={getCurrentEmployees()}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={rowContent}
        />
      </TableWrapper>
    </>
    }
    </>
  );
};

export default FlexMisTable;
