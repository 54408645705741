import React, { Component } from 'react'
import styled from 'styled-components'
import Header from '../components/memberApp/MarketplaceHeader'
import EmiApplyTypes from '../components/EmiApplyTypes'
import CategoryOffers from './CategoryOffers.jsx'
import http from '../services/httpService'
import OverlayLoader from '../components/overlayLoader'
import LaodMoreLoader from './Loader'
import SearchImage from '../assets/images/search.png'
import { checkStringForNumbers } from '../utils/utils'
import { debounce } from 'throttle-debounce'
import CrossIcon from '../assets/images/vital-cross.png'
import { findLastIndex } from 'lodash'
import { gtmCode } from '../utils/utils'
import TagManager from 'react-gtm-module'
import MarketplacePopup from '../components/memberApp/marketplacePopUp'

const Home = styled.div`
  width: 100%;
`
const Wrapper = styled.div`
  margin-left: 8px;
  margin-right: 8px;
  padding-bottom: 80px;
`

const Body = styled.div``
function getDocHeight() {
  var D = document
  return Math.max(
    D.body.scrollHeight,
    D.documentElement.scrollHeight,
    D.body.offsetHeight,
    D.documentElement.offsetHeight,
    D.body.clientHeight,
    D.documentElement.clientHeight
  )
}

var startTime, endTime
function start() {
  startTime = new Date()
}

function end() {
  endTime = new Date()
  var timeDiff = endTime - startTime //in ms
  // strip the ms
  timeDiff /= 1000

  // get seconds
  var seconds = Math.round(timeDiff)
  //////////console.log(seconds + " seconds");
  return seconds
}
const Suggestions = props => {
  const options = props.results.map(r => (
    <Lists onClick={e => props.selectsuggestions(e, r)} key={r.id}>
      {r.city || r.pincode}
    </Lists>
  ))
  return <ul>{options}</ul>
}
var isMobile = {
  Android: function() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function() {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function() {
    return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
  },
  any: function() {
    return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
  }
};
class MarketPlaceOffers extends React.Component {
  constructor(props) {
    super(props)
    this.callSearchSuggestions = debounce(500, this.callSearchSuggestions)
    this.state = {
      recommended_cards: '',
      loader: true,
      page: 1,
      next: 0,
      results: [],
      loadMoreLoader: false,
      card_type: '',
      totalCount: 0,
      subcategories: [],
      pincode: '',
      city: '',
      case: 0,
      suggestions: [],
      selectedCity: null,
      selectedPinCode: null,
      crossClicked: false,
      showPopupForEmployers: false,
      page_size:10
    }
    this.lastPage = ''

  }
  componentDidMount() {
    window.addEventListener('scroll', this.loadMore)
    this.setState({
      selectedCity: localStorage.getItem('selectedCity'),
      page_size: isMobile.any() ? 10:100

    },()=>{
      this.getOffers()
    })

    this.subCategories()
  }
  subCategories = () => {
    let sub_categories = localStorage.getItem('sub_categories') ||[]
    if (sub_categories.length > 0) {
      let sub_categories_array = sub_categories.split(',')
      let sub_categories_mapping =
        sub_categories_array &&
        sub_categories_array.map((item, index) => {
          return {
            active: false,
            item
          }
        })
      //////////console.log("len", sub_categories_mapping);
      this.setState(
        {
          subcategories: sub_categories_mapping
        },
        () => {
          //////////console.log("ans", this.state.subcategories);
        }
      )
    }
  }
  urlFormationApiCall = async (subcategorires, city, pincode, page) => {
    const{page_size} = this.state;
    let defaultCity = window.localStorage.getItem('marketplace_city')
    if (subcategorires && subcategorires.length >= 0) {
      let card_type = window.localStorage.getItem('card_type')
      card_type = card_type && card_type.toLowerCase()
      let url = '/dashboard/category/' + card_type + '/?'
      for (let i = 0; i < subcategorires.length; i++) {
        if (i == 0) url += 'subcategory=' + subcategorires[i].item
        else url += '&subcategory=' + subcategorires[i].item
      }
      const { selectedCity, selectedPinCode, page } = this.state
      let flag
      let filter = localStorage.getItem('filter')
      filter = filter && filter.toLowerCase()
      if (selectedPinCode === null && selectedCity === null && !this.state.crossClicked && filter === 'location') {
        flag = true
      }
      if (city !== null && city !== 'null') {
        url += '&city=' + selectedCity
      }
      if (pincode) {
        url += '&pincode=' + selectedPinCode
      }
      if (page) {
        url += '&page=' + this.state.page +'&page_size='+page_size
      }
      if (flag) {
        let pincodeVal = localStorage.getItem('pinCode')

        //url+='&pincode='+pincodeVal
        url += '&city=' + defaultCity //changing from pincode to city
      }
      url = encodeURI(url)
      //////////console.log("url is", url);

      this.setState({
        loader: true,
        case: 1
      })
      const response = await http.get(url)
      //////////console.log("response is ", response);

      this.setState({
        results: response && response.data && response.data.results,
        next: response && response.data && response.data.next,
        totalCount: response && response.data && response.data.count
      })
      //disabling loader
      await this.setState({ loader: false })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadMore)
  }
  loadMore = async () => {
    const{page_size} = this.state;
    let defaultCity = window.localStorage.getItem('marketplace_city')
    if (
      window.localStorage.getItem('selectedCity') !== null &&
      window.localStorage.getItem('selectedCity') !== 'null'
    ) {
      defaultCity = window.localStorage.getItem('selectedCity')
    }
    // console.log('loadmore', window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2)
    if (window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2) {
      //////////console.log("LoadMoreCalled");
      // console.log('state', this.state, 'this.lastPage ', this.lastPage)
      // console.log('state1', this.state.page + 1, this.lastPage)
      if (
        this.state.next !== null &&
        this.state.results.length < this.state.totalCount &&
        this.state.case === 0 &&
        this.state.page + 1 !== this.lastPage
      ) {
        //////////console.log("results length", this.state.results.length);
        //////////console.log("totalCount", this.state.totalCount);
        //////////console.log("next", this.state.next);
        this.setState({ loadMoreLoader: true })
        // increase the page number using set state and call the api now
        let val = this.state.page + 1
        this.lastPage = val

        let card_type = window.localStorage.getItem('card_type')
        card_type = card_type && card_type.toLowerCase()
        //////////console.log("this.state", this.state);
        //////////console.log("this.lastPage", this.lastPage);

        let url = '/dashboard/category/' + card_type + '/?page=' + val +'&page_size='+page_size
        let pincodeVal = localStorage.getItem('pinCode')
        let filter = window.localStorage.getItem('filter')

        if (filter === 'location' && this.state.crossClicked === false) {
          // url+='&pincode='+pincodeVal
          url += '&city=' + defaultCity
        }
        const response = await http.get(url)

        let arr1 = this.state.results
        let arr2 = response && response.data && response.data.results

        let array3 = [...arr1, ...arr2]

        let newArray = [...this.state.results, response.data && response.data.results]

        this.setState(
          {
            data: response,
            results: array3,
            next: response && response.data && response.data.next,
            page: this.state.page + 1
          },
          () => {
            ////////////console.log('async value is ', this.state)
          }
        )
        await this.setState({ loadMoreLoader: false })
      } else if (
        this.state.next !== null &&
        this.state.results.length < this.state.totalCount &&
        this.state.case === 1 &&
        this.state.page + 1 !== this.lastPage
      ) {
        this.setState({ loadMoreLoader: true })
        let val = this.state.page + 1
        this.lastPage = val
        let selectedSubCategories = this.state.subcategories.filter(item => {
          return item.active === true
        })
        if (selectedSubCategories && selectedSubCategories.length >= 0) {
          let card_type = window.localStorage.getItem('card_type')
          card_type = card_type && card_type.toLowerCase()
          let url = '/dashboard/category/' + card_type + '/?'
          for (let i = 0; i < selectedSubCategories.length; i++) {
            if (i == 0) url += 'subcategory=' + selectedSubCategories[i].item
            else url += '&subcategory=' + selectedSubCategories[i].item
          }
          const { selectedCity, selectedPinCode, page } = this.state
          if (selectedCity && selectedCity !== 'null') {
            url += '&city=' + selectedCity
          } else if (selectedPinCode) {
            url += '&pincode=' + selectedPinCode
          }

          url += '&page=' + val+'&page_size='+page_size

          url = encodeURI(url)
          //////////console.log("url is", url, val, page);
          const response = await http.get(url)
          //////////console.log("response is ", response);

          let arr1 = this.state.results
          let arr2 = response && response.data && response.data.results
          let array3 = [...arr1, ...arr2]

          let newArray = [...this.state.results, response.data && response.data.results]

          this.setState(
            {
              data: response,
              results: array3,
              next: response && response.data && response.data.next,
              page: this.state.page + 1
            },
            () => {
              //////////console.log("async value is ", this.state);
            }
          )
          await this.setState({ loadMoreLoader: false })
        }
      } else if (
        this.state.next !== null &&
        this.state.results.length < this.state.totalCount &&
        this.state.case === 2 &&
        this.state.page + 1 !== this.lastPage
      ) {
        //////////console.log("working case2");
        this.setState({ loadMoreLoader: true })
        let val = this.state.page + 1
        this.lastPage = val
        let card_type = window.localStorage.getItem('card_type')
        card_type = card_type && card_type.toLowerCase()
        const { selectedCity, selectedPinCode } = this.state
        const { subcategories } = this.state
        let selectedSubCategories = this.state.subcategories.filter(item => {
          return item.active === true
        })

        let url = '/dashboard/category/' + card_type + '/?'
        if (selectedSubCategories.length === 0) {
          if (selectedCity) {
            url = url + 'city=' + selectedCity
          } else {
            let pinCodeval = selectedPinCode ? selectedPinCode : localStorage.getItem('pinCode')
            //url=url+"pincode="+pinCodeval;
            url += '&city=' + defaultCity
          }
        } else {
          // append subcategories
          for (let i = 0; i < selectedSubCategories.length; i++) {
            if (i == 0) url += 'subcategory=' + selectedSubCategories[i].item
            else url += '&subcategory=' + selectedSubCategories[i].item
          }
          if (selectedCity) {
            url = url + '&city=' + selectedCity
          } else url = url + '&pincode=' + selectedPinCode
        }

        url += '&page=' + val+'&page_size='+page_size
        url = encodeURI(url)
        //////////console.log("url is", url);

        const response = await http.get(url)
        //////////console.log("response is ", response);

        let arr1 = this.state.results
        let arr2 = response && response.data && response.data.results
        let array3 = [...arr1, ...arr2]

        let newArray = [...this.state.results, response.data && response.data.results]

        this.setState(
          {
            data: response,
            results: array3,
            next: response && response.data && response.data.next,
            page: this.state.page + 1
          },
          () => {
            //////////console.log("async value is ", this.state);
          }
        )
        await this.setState({ loadMoreLoader: false })
      }
    }
  }
  getOffers = async () => {
    const{page_size} = this.state;
    let card_type = window.localStorage.getItem('card_type')
    let pincodeVal = window.localStorage.getItem('pinCode')
    let filter = window.localStorage.getItem('filter')
    let city = window.localStorage.getItem('marketplace_city')
    if (
      window.localStorage.getItem('selectedCity') !== null &&
      window.localStorage.getItem('selectedCity') !== 'null'
    ) {
      city = window.localStorage.getItem('selectedCity')
    }

    card_type = card_type && card_type.toLowerCase()
    let url
    if (filter === 'location' && !this.state.crossClicked)
      url = '/dashboard/category/' + card_type + '/?page=' + this.state.page + '&city=' + city+
      '&page_size='+page_size
    else url = '/dashboard/category/' + card_type + '/?page=' + this.state.page+'&page_size='+page_size
    const response = await http.get(url)

    this.setState({
      recommended_cards: response,
      results: response && response.data && response.data.results,
      next: response && response.data && response.data.next,
      totalCount: response && response.data && response.data.count,
      case: 0
    })
    //disabling loader
    await this.setState({ loader: false })
  }

  marketplacepointsClick = id => {
    const{page_size} = this.state;
    //////////console.log("id is", id);
    if (this.props.location.pathname.indexOf('business') > -1) {
      this.setState({ showPopupForEmployers: true })
      return
    }
    localStorage.setItem('points_id', id)
    localStorage.setItem('selectedPinCode', this.state.selectedPinCode)
    localStorage.setItem('selectedCity', this.state.selectedCity)
    let selectedSubCategories = this.state.subcategories.filter(item => {
      return item.active === true
    })
    let selectedSubCategories_array = selectedSubCategories.map(item => item.item)
    let brandName
    let data = this.state.results.filter(item => {
      return item.id === id
    })
    if (data && data.length > 0) {
      brandName = data && data[0].brand
    }
    let card_display_name = localStorage.getItem('card_display_name')
    const tagManagerArgs1 = {
      gtmId: gtmCode(),
      dataLayer: {
        event: 'sessionwithproductsview',
        ecommerce: {
          currencyCode: 'INR',
          detail: {
            products: [
              {
                id: id,
                name: brandName,
                category: localStorage.getItem('card_type')
              }
            ]
          }
        }
      }
    }
    TagManager.initialize(tagManagerArgs1)
    const tagManagerArgs = {
      gtmId: gtmCode(),
      dataLayer: {
        event: `gvMP_${card_display_name}`,
        gvMPV_testcategory: selectedSubCategories_array,
        gvMPV_selectedCity: this.state.selectedCity,
        gvMPV_brandName: brandName,
        gvMPV_brandId: id,
        gvMPV_search_filter: localStorage.getItem('filter') ? 'true' : 'false'
      }
    }
    TagManager.initialize(tagManagerArgs)
    const logresponse = http.post('/points/insert_marketplace_eventlog', {
      offer_id: id,
      event_type: 'offer_view'
    })

    this.props.history.push('/marketplacepoints')
  }
  takeToPan = () => {
    this.props.history.push('/pan')
  }
  loadOffersWithSubcategories = () => {
    const{page_size} = this.state;
    let selectedSubCategories = this.state.subcategories.filter(item => {
      return item.active === true
    })
    if (selectedSubCategories.length === 0 && this.state.selectedPinCode === null && this.state.selectedCity === null) {
      this.getOffers()
    }
    const { selectedCity, selectedPinCode, page } = this.state
    this.urlFormationApiCall(selectedSubCategories, selectedCity, selectedPinCode, page) // url
  }
  handleactivesubcategory = indexToChange => {
    start()
    this.setState(
      {
        subcategories: this.state.subcategories.map((item, index) => {
          if (index === indexToChange) {
            return {
              ...item,
              active: !item.active
            }
          } else {
            return {
              ...item
            }
          }
        }),
        page: 1
      },
      () => {
        this.lastPage = ''
        //////////console.log("val of state", this.state.subcategories);
        this.loadOffersWithSubcategories()
      }
    )
  }

  updateInputValue = evt => {
    const re = /^[a-zA-Z ]+$/
    //////////console.log("reg is ", re.test(evt.target.value));
    if (re.test(evt.target.value)) {
      let selectedSubCategories = this.state.subcategories.filter(item => {
        return item.active === true
      })
      const { selectedCity, selectedPinCode } = this.state
      if (
        evt.target.value.length === 0 &&
        selectedSubCategories.length === 0 &&
        selectedPinCode === null &&
        selectedCity === null
      ) {
        this.setState(
          {
            suggestions: [],
            page: 1,
            case: 0
          },
          () => {
            this.getOffers()
          }
        )
      } else this.callSearchSuggestions(evt.target.value)
    }
  }
  callSearchSuggestions = value => {
    //////////console.log("keyup", value);
    //////////console.log(typeof value);
    let isPincode = checkStringForNumbers(value)
    if (!isPincode) {
      this.setState(
        {
          city: value,
          pincode: '',
          userInput: value
        },
        () => {
          this.callsuggestionApi(this.state.city, this.state.pincode)
        }
      )
    } else {
      this.setState(
        {
          pincode: value,
          city: '',
          userInput: value
        },
        () => {
          this.callsuggestionApi(this.state.city, this.state.pincode)
        }
      )
    }
  }
  callsuggestionApi = async (city, pincode) => {
    let url = 'points/searchsuggestion/?'
    if (city) {
      url = url + 'city=' + city
    } else url = url + 'city=' + pincode
    const response = await http.get(url)
    this.setState({
      suggestions: response && response.data
    })
  }
  selectsuggestions = (e, item) => {
    //////////console.log("e.currentTarget.innerText", e.currentTarget.innerText);
    if (item.city) {
      this.setState(
        {
          selectedCity: item.city,
          inputValue: e.currentTarget.innerText,
          selectedPinCode: null,
          suggestions: [],
          userInput: e.currentTarget.innerText,
          page: 1
        },
        () => {
          this.lastPage = ''
          this.callCityPinCodeSelectionApi(this.state.selectedCity, this.state.selectedPinCode)
        }
      )
    } else {
      this.setState(
        {
          selectedPinCode: item.pincode,
          selectedCity: null,
          inputValue: e.currentTarget.innerText,
          suggestions: []
        },
        () => {
          this.callCityPinCodeSelectionApi(this.state.selectedCity, this.state.selectedPinCode)
        }
      )
    }
  }
  callCityPinCodeSelectionApi = async (selectedCity, selectedPinCode) => {
    const{page_size} = this.state;
    let card_type = window.localStorage.getItem('card_type')
    card_type = card_type && card_type.toLowerCase()
    const { subcategories } = this.state
    let selectedSubCategories = this.state.subcategories.filter(item => {
      return item.active === true
    })

    let url = '/dashboard/category/' + card_type + '/?'
    if (selectedSubCategories.length === 0) {
      if (selectedCity) {
        url = url + 'city=' + selectedCity
      } else if (selectedPinCode) url = url + 'pincode=' + selectedPinCode
    } else {
      // append subcategories
      for (let i = 0; i < selectedSubCategories.length; i++) {
        if (i == 0) url += 'subcategory=' + selectedSubCategories[i].item
        else url += '&subcategory=' + selectedSubCategories[i].item
      }
      if (selectedCity) {
        url = url + '&city=' + selectedCity
      } else if (selectedPinCode) url = url + '&pincode=' + selectedPinCode
    }
    const { page } = this.state
    if (page) {
      url += '&page=' + page+'&page_size='+page_size
    }

    this.setState({
      loader: true,
      case: 2
    })
    const response = await http.get(url)

    this.setState({
      results: response && response.data && response.data.results,
      next: response && response.data && response.data.next,
      totalCount: response && response.data && response.data.count
    })
    //disabling loader
    await this.setState({ loader: false })
  }
  updateOnChange = e => {
    const re = /^[a-zA-Z ]*$/
    if (re.test(e.target.value)) {
      this.setState({
        inputValue: e.target.value,
        selectedCity: null,
        selectedPinCode: null,
        page: 1
      })
    }
  }
  clearSearch = e => {
    let selectedSubCategories = this.state.subcategories.filter(item => {
      return item.active === true
    })
    if (selectedSubCategories.length === 0) {
      this.setState(
        {
          inputValue: '',
          page: 1,
          crossClicked: true,
          case: 0,
          selectedCity: null,
          selectedPinCode: null
        },
        () => {
          // call the deafult api if there is no subcategory selected
          this.getOffers()
        }
      )
    } else {
      this.setState(
        {
          inputValue: '',
          page: 1,
          crossClicked: true,
          case: 2,
          selectedCity: null,
          selectedPinCode: null
        },
        () => {
          // user has selected some subcategory and then he clicks on cross button
          this.callCityPinCodeSelectionApi(this.state.selectedCity, this.state.selectedPinCode)
        }
      )
    }
  }
  onKeyPress = event => {
    var key = event.keyCode
    return (key >= 65 && key <= 90) || key == 8
  }

  render() {
    //console.log('this.lastpage', this.state);
    const { recommended_cards, results, subcategories } = this.state
    let card_type = window.localStorage.getItem('card_type')
    let filter = localStorage.getItem('filter')
    filter = filter && filter.toLowerCase()
    let pinCode = localStorage.getItem('pinCode')
    let defaultCity = localStorage.getItem('marketplace_city')
    if (this.state.selectedCity) {
      defaultCity = this.state.selectedCity
    }
    let ids = results && results.map(item => item.id)
    let descriptions = results && results.map(item => item.offer_description_1)
    let card_display_name = localStorage.getItem('card_display_name')
    const backPage = this.props.location.pathname.indexOf('business') > -1 ? '/business/marketplace' : '/dashboard'
    return (
      <Home>
        <Header color="#436ab2" backPage={backPage} headerText={card_display_name} title="Working" />

        {filter === 'location' ? (
          <SearchRectangle>
            <SearchImageDiv>
              <SearchImg src={SearchImage} />
            </SearchImageDiv>
            <div>
              <input
                key={this.state.selectedCity}
                type="text"
                style={{
                  borderRadius: '10px',
                  marginLeft: '30px',
                  backgroundColor: 'rgb(239, 239, 239)',

                  height: '48px',
                  width: '100%',
                  outline: 'none',
                  border: 'none',
                  fontSize: '1.6rem',
                  fontFamily: 'Open Sans',
                  fontWeight: 'normal',
                  fontStyle: 'normal',
                  fontStretch: 'normal',
                  paddingLeft: '6px'
                }}
                value={this.state.inputValue}
                defaultValue={defaultCity}
                placeholder={'Search Location'}
                onChange={evt => this.updateOnChange(evt)}
                onKeyPress={event => this.onKeyPress(event)}
                onKeyUp={evt => this.updateInputValue(evt)}
              />
            </div>
          </SearchRectangle>
        ) : null}

        {this.state.suggestions && this.state.suggestions.length > 0 && this.state.inputValue.length > 0 ? (
          <SuggestionDiv>
            <Suggestions selectsuggestions={(e, r) => this.selectsuggestions(e, r)} results={this.state.suggestions} />
          </SuggestionDiv>
        ) : null}

        {subcategories && subcategories.length > 0 ? (
          <SubCategoryContainer>
            {subcategories.map((item, index) => (
              <SubCategory key={index} onClick={() => this.handleactivesubcategory(index)} active={item.active}>
                <Font12 active={item.active}>{item.item}</Font12>
              </SubCategory>
            ))}
          </SubCategoryContainer>
        ) : null}
        <Wrapper>
          <CategoryOffers
            {...this.props}
            cards_data={results}
            selectedCity={this.state.selectedCity}
            selectedPinCode={this.state.selectedPinCode}
            marketplacepoints={id => this.marketplacepointsClick(id)}
          />
        </Wrapper>
        {this.state.loader && <OverlayLoader />}
        <LoaderDiv>{this.state.loadMoreLoader && <LaodMoreLoader />}</LoaderDiv>
        <MarketplacePopup
          onPressOkay={() => this.setState({ showPopupForEmployers: false })}
          showPopup={this.state.showPopupForEmployers}
        />
      </Home>
    )
  }
}
export default MarketPlaceOffers
const LoaderDiv = styled.div`
position: fixed;
   bottom:0;
    display:flex;
    justify-content:center;
    align-items:center;
    left:50%
    margin-bottom:30px;
    
`

const SubCategoryContainer = styled.div`
  width: 100%;
  padding-top: 2.4rem;
  padding-right: 0px;

  padding-left: 1.6rem;
  white-space: nowrap;
  overflow-x: auto;
  & > div {
    display: inline-block;
  }
  div:not(:last-child) {
    margin-right: 12px;
  }
  & > :last-child {
    margin-right: 1.6rem;
  }
  //Chrome, Safari, newer versions of Opera
  ::-webkit-scrollbar {
    display: none;
  }
  //Firefox
  overflow: -moz-scrollbars-none;
  //Internet Explorer +10
  -ms-overflow-style: none;
`

const SubCategory = styled.div`
  height: 36px;
  border-radius: 8px;
  border: solid 1px #436ab2;
  background-color: ${props => (props.active === true ? '#436ab2' : '#fff')};
  padding-top:9px;
  min-width:112px;
  color: #ffffff;
}
`
const Font12 = styled.div`  
  height: 18px;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: center;
  color: ${props => (props.active === true ? '#ffffff' : '#4a4a4a')};
  padding-left: 10px;
    padding-right: 10px;
}
}
`
const SearchRectangle = styled.div`
  display: flex;
  height: 48px;
  border-radius: 10px;
  // background-color: rgba(216, 216, 216, 0.41);
  // box-shadow: 0 2px 4px 0 rgba(74, 74, 74, 0.19);
  // background-color: #ffffff;
  background-color: rgba(216, 216, 216, 0.41);
  margin-left: 12px;
  margin-right: 12px;
  margin-top: 20px;
`
const SearchImg = styled.img`
  object-fit: contain;
  src: url(${props => props.src});
`
const CrossImg = styled.img`
  object-fit: contain;
  src: url(${props => props.src});
`
const SearchImageDiv = styled.div`
  width: 14px;
  height: 20px;
  padding-left: 21px;
  margin-top: 12px;
`
const SuggestionDiv = styled.div`
    position: absolute;
    background-color: #f9f9f9;
    min-width: 335px; 
 
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 0px 0px;
    z-index: 1000;
    margin-left:12px;
    
}
 
`
const Lists = styled.li`
  color: black;
  padding: 12px 13px;
  text-decoration: none;
  font-size: 14px;
  color: #fff;
  display: block;
  box-shadow: 0 2px 4px 0 rgba(74, 74, 74, 0.19);
  color: #446aad;
  background-color: #ffffff;
  &:hover {
    background-color: '#446aad';
    color: #ffffff;
  }
`
const CrossDiv = styled.div`
  width: 16px;
  height: 16px;
  object-fit: contain;
  padding-left: 55px;
  padding-top: 14px;
  font-size: 18px;
`
