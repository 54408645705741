import React, { Component } from "react";
import styled from "styled-components";
import groupAbout from "../assets/images/groupAbout.png";
import concentricCircle from "../assets/images/concentricCircle2.png";

const Wrapper = styled.div`
  width: 100%;
  padding: 3.6rem 0 0 0;
`;

const Image = styled.img`
  width: 375px;
  height: 281px;

  @media (min-width: 1024px) {
    width: 830px;
    height: 622px;
  }
`;
const Iwrapper = styled.div``;
const H2 = styled.h2`
  display: flex;
  font-family: Proxima Nova;
  font-size: 24px;
  font-weight: 600;
  // font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 1px;
  color: #009999;
  margin-top: 36px;
  padding-left: 24px;
  padding-right: 24px;

  @media (min-width: 1024px) {
    font-size: 34px;
    padding-bottom: 6px;
    width: 814px;
    text-align: center;
    line-height: 1.41;
    letter-spacing: 1.42px;
    display: block;
  }
`;
const H1 = styled.h1`
  font-family: Proxima Nova;
  font-size: 34px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #4a4a4a;
  margin-top: 48px;
  padding-left: 24px;
  padding-right: 24px;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 44px;
    text-align: center;
    margin-right: 140px;
  }
`;
const H3 = styled.h3`
    font-family: Avenir;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: 0.47px;
    color: #4a4a4a
    margin-top: 16px;
    padding-left: 24px;
    padding-right: 24px;

    @media (min-width: 1024px) {
      font-size: 18px;
      padding-bottom: 36px;
      width: 842px;
      line-height: 2.44;
      letter-spacing: 0.53px;
      // text-align: center;
    }
`;
const ImageDv = styled.div`
  // background: rgba(238, 238, 238, 1);
  padding-left: 36px;
  padding-bottom: 51.6px;
`;
const ParentDiv = styled.div`
  display: flex;
`;
const LaptopDiv = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row-reverse;
    background-color: #eeeee0;
  }
`;
const TextDiv = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 68px;
  }
`;
const Logo1 = styled.div`
  display: none;

  @media (min-width: 1024px) {
    display: flex;
    width: 155px;
    height: 400px;
    opacity: 0.85;
    background: url(${concentricCircle});
    background-size: cover;
    position: absolute;
    top: 22%;
    left: 0;
  }
`;

export default class Page45 extends Component {
  render = () => {
    return (
      <ParentDiv>
        <Wrapper>
          <LaptopDiv>
            <Iwrapper>
              {/* <Image src={groupAbout} /> */}
              <Image src="http://images.getvital.in/homelanding/groupAbout.png" />
            </Iwrapper>
            <Logo1 />
            <H1>About Us</H1>
          </LaptopDiv>
          <TextDiv>
            <H2>
              Making health financing simple, personalized and affordable
            </H2>
            <H3>
              Vital is a next-gen health financing platform that covers you for
              all your health & wellness needs. We aim to provide the best
              healthcare through comprehensive yet simple-to-use financial
              products.
            </H3>
            <H2>Be Fearless,</H2>
          </TextDiv>
        </Wrapper>
      </ParentDiv>
    );
  };
}
