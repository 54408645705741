import React from 'react'
import { Field } from 'formik'
import Input from './input'
String.prototype.capitalize = function() {
  return this.replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
};
const RenderInput = ({ placeholder, name, validate = null, Readonly = false, value, error, icon1,disabled, inputOnClick,  ...restProps }) => {
  
  let val;
  if(value ==="MOTHERINLAW")
   val="Mother-In-Law";
  else if (value ==="FATHERINLAW")
   val="Father-In-Law";
   else if (value ==="SON")
   val="Son"
   else if (value ==="MOTHER")
   val="Mother"
   else if (value ==="FATHER")
   val="Father"
   else if (value ==="DAUGHTER")
   val="Daugther"
   else if(value =="SPOUSE")
   val="Spouse"
   else
   val=value;

  const onClickHandler = () => {
    if(inputOnClick) {
      inputOnClick();
    }
  }
  return(
    <Field
    name={name}
    validate={validate}
    render={({ field }) => (
      <Input
        {...field}
        onClick={onClickHandler}
        Readonly={Readonly}
        placeholder={placeholder}
        value={val}
        error={error}
        icon1={icon1}
        disabled={disabled}
        {...restProps}
      />
    )}
  />
  );
}


export default RenderInput
