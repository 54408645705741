import React, { Component } from 'react'
import styled from 'styled-components'
import {PageView, initGA,Event} from '../Tracking';
const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  // margin: 3.5rem 0;
  & > :nth-child(2) {
    margin-left: 1.6rem;
  }
`

const RadioLabel = styled.label`
  width: 100%;
  display: block;
  text-align: center;
  color: #fff;
  padding: 1rem 0;
  border-radius: 15px;
  border: 2px solid #436ab2;
  background-color: ${props => (props.Color ? '#436ab2' : '#fff')};
`
const RadioGrp = styled.div`
  width: 100%;
  & > :nth-child(2) {
    display: none;
  }
`
const Font16 = styled.p`
  font-size: 1.4rem;
  font-family: Avenir;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  text-transform: uppercase;
  line-height: 1.71;
  letter-spacing: 1.3px;
  text-align: center;
  color: #ffffff;
  color: ${props => (props.Color ? '#fff' : 'rgba(119, 119, 119, 0.87)')};
`

export default class GenderButton extends Component {
  state = {
    // male: true
  }

  componentDidMount = () => {
    const { radioValue } = this.props
    if (radioValue === 'male' || '') this.setState({ male: true, female: false })
    else if (radioValue === 'female') this.setState({ female: true, male: false })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.radioValue !== this.props.radioValue) {
      const { radioValue } = this.props

      if (radioValue === 'male' || '') this.setState({ male: true, female: false })
      else if (radioValue === 'female') this.setState({ female: true, male: false })
    }
  }

  handleRadio = e => {
    if (e.target.value === 'female') this.setState({ female: true, male: false })
    else this.setState({ male: true, female: false })
    // window.gtag('event', 'click', {
    //   event_category: this.props.pathname,
    //   event_label: e.target.value === 'female' ? 'female_button' : 'male_button'
    // })
    let data =e.target.value === 'female' ? 'female_button' : 'male_button';
    Event(this.props.pathname,data,'gender button')

    return this.props.onClick(e)
  }

  render() {
    const { onBlur } = this.props
    return (
      <BtnWrapper>
        <RadioGrp>
          <RadioLabel Color={this.state.male} htmlFor={'male'}>
            <Font16 Color={this.state.male}>Male</Font16>
          </RadioLabel>
          <input
            type="radio"
            id="male"
            name="gender"
            value="male"
            onClick={e => this.handleRadio(e)}
            onBlur={e => onBlur(e)}
          />
        </RadioGrp>
        <RadioGrp>
          <RadioLabel Color={this.state.female} htmlFor={'female'}>
            <Font16 Color={this.state.female}>Female</Font16>
          </RadioLabel>
          <input
            type="radio"
            id="female"
            name="gender"
            value="female"
            onClick={e => this.handleRadio(e)}
            onBlur={e => onBlur(e)}
          />
        </RadioGrp>
      </BtnWrapper>
    )
  }
}
