import React, { Component } from 'react'
import styled from 'styled-components'
// import Select from 'react-select'
import onClickOutside from 'react-onclickoutside'
import Input from './input'
import Icons from '../components/icons'
import {PageView,Event,initGA} from '../Tracking';
const Input16 = styled.p`
  display: block;
  width: 100%;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.87);
`

const SelectWrapper = styled.div`
  width: 100%;
  position: relative;

  & > label > p {
    color: ${props => props.selected !== '' && 'rgba(0, 0, 0, 0.54)'};
    transform: ${props => props.selected !== '' && 'translateY(-22px) scale(0.9)'};
  }
`
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 9;

  & svg {
    margin-left: -2.8rem;
  }
`

const Ul = styled.ul`
  width: 100%;
  padding: 8px 0;
  max-height: 16rem;
  border-radius: 8px;
  list-style: none;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  background-color: #fff;

  white-space: no-wrap;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10;

  transform-origin: top;
  transform: ${props => (props.toggleList === false ? 'translateY(0) scaleY(0)' : 'translateY(50%) scaleY(100%)')};
  transition: all 0.3s;
  backface-visibility: hidden;
`

const Li = styled.li`
  padding: 1.2rem 1.6rem;

  &:hover,
  &:focus {
    background-color: #3586f6;
  }
`
const ListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  flex: 1;

  & > svg {
    // margin-left: -10rem;
    margin-left: -20rem;
  }
`

const Error = styled.p`
  color: red;
  font-size: 1.4rem;
  // @media (max-width: 768px) {
  //   font-size: 14px;
  //   padding-top: 4px;
  // }
  // @media (min-width: 769px) {
  //   font-size: 14px;
  //   padding-top: 4px;
  // }
  // @media (max-width: 400px) {
  //   font-size: 10px;
  //   padding-top: 4px;
  // }
`

class DropDown extends Component {
  state = {
    toggleList: false,
    selected: '',
    Checked: true
  }

  findItem = value => this.props.option.filter(item => item.value === value)

  componentDidMount = () => {
    console.log("this.props.value", this.props.value);
    
  
    if (this.props.value && this.props.value !== '') {
      // this.setState({ selected: this.props.value })
      // console.log('Inside-IF')

      const itemArr = this.findItem(this.props.value)
      // console.log('ItemArr: ', itemArr, 'this.props.value: ', this.props.value)

      const selected = itemArr[0] && itemArr[0].label ? itemArr[0].label : ''

      this.setState({ selected })

      //=================================
      // const itemArr = this.findItem(this.props.value)
      // const selected = itemArr[0] && itemArr[0].label ? itemArr[0].label : ''
      // this.setState({ selected })
    } else {
      this.setState({ selected: this.props.selectedOption.label })
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    // console.log('[CDU-1]: selectedOption: ', prevProps.selectedOption)
    // console.log('[CDU-2]: value ', prevProps.value)

    // || this.state.selected !== this.props.value) && prevProps.value === ''
    if (this.props.value !== prevProps.value) {
      const itemArr = this.findItem(this.props.value)

      const selected = itemArr[0] && itemArr[0].label ? itemArr[0].label : ''
      this.setState({ selected })
    }
  }

  handleChange = item => {
    this.setState({ toggleList: !this.state.toggleList })
    return this.props.onChange(item)
  }

  handleClickOutside = evt => {
    this.state.toggleList && this.setState({ toggleList: false })
  }

  render() {
    const {
      INRSign,
      error,
      Readonly,
      Checked,
      recommend,
      value,
      enableIcon,
      uploaded,
      e_category,
      e_label,
      option,
      selectedOption,
      ...restProps
    } = this.props
  
    return (
      <SelectWrapper key={this.props.value}  selected={this.state.selected}>
        {/* <InputWrapper onClick={() => this.setState({ toggleList: !this.state.toggleList })}> */}
        <InputWrapper
          onClick={() => {
            this.setState({ toggleList: !this.state.toggleList })

            Event(e_category,e_label,e_label);
          }}>
          {INRSign ? (
            <ListItem>
              <Input
                placeholder={this.props.placeholder}
                readOnly={true}
                value={'₹' + this.state.selected }
                onBlur={e => this.props.onBlur(e)}
              />
              {enableIcon && value !== '' && uploaded && <Icons icon="roundCheckCircle" width={26} height={24} />}
            </ListItem>
          ) : (
            <ListItem>
              <Input
                placeholder={this.props.placeholder}
                readOnly={true}
                value={this.state.selected || this.props.value}
                error={error}
                icon1={this.state.toggleList ? { name: 'triangle_up' } : { name: 'triangle_down' }}
                icon2={
                  enableIcon && value !== '' && uploaded ? { name: 'roundCheckCircle', width: 26, height: 24 } : null
                }
                {...restProps}
              />
            </ListItem>
          )}
        </InputWrapper>

        {/* {!this.state.toggleList && <Error>{error}</Error>} */}

        <Ul toggleList={this.state.toggleList}>
          {option.map(item => (
            <Li key={item.id} onClick={() => this.handleChange(item)}>
              <ListItem>
                {INRSign ? <Input16>&#8377; {item.label}</Input16> : <Input16>{item.label}</Input16>}
                {enableIcon &&
                  item.value.toUpperCase() === recommend && <Icons icon="roundCheckCircle" width={26} height={24} />}
              </ListItem>
            </Li>
          ))}
        </Ul>
      </SelectWrapper>
    )
  }
}

export default onClickOutside(DropDown)

//**
// componentDidUpdate = (prevProps, prevState) => {
//   console.log('[CDU-1]: selectedOption: ', prevProps.selectedOption)
//   console.log('[CDU-2]: value ', prevProps.value)

//   // || this.state.selected !== this.props.value)
//   if (this.props.value !== prevProps.value && prevProps.value === '')
//   {
//     const itemArr = this.findItem(this.props.value)
//     console.log('ItemArr: ', itemArr)

//     const selected = itemArr[0] && itemArr[0].label ? itemArr[0].label : ''
//     console.log('Selected: CDU', selected)
//     this.setState({ selected })
//   } */
