import React, { Component } from "react";
import styled from "styled-components";
import Icons from "../../components/icons";
import {Ptag} from '../../components/fonts'

export default function HealthConditions({onClose, showPopup}) {

    const column = [
        'Hypertension for more than 15 years or diagnosed before the age of 30',
        'Diabetes for more than 10 years or diagnosed before the age of 30 or which requires Insulin intake',
        'Arthritis (or with a Joint replaced already)',
        'Chronic lung disease',
        'Thyroid, Pancreatic, Pituitary or Adrenal Gland disorder',
        'Heart condition',
        'Tuberculosis',
        'Cancer',
        'Clinical Obesity',
        'Blood Disorder',
        'Liver, Kidney, Digestive System, Reproductive  System Disorder',
        'Muscular, Nervous, Mental or Psychiatric disorder',
        'STDs/HIV',
        'Pregnant with the first trimester completed'
    ]
    return(
        <Home show={showPopup}>
            <IconDiv onClick={onClose}>
                <Icons icon="cross" fill="#7d7c7c" height="24" width="24" />
            </IconDiv>
            <Ptag fontWeight='600' fontSize='15px' color='#436ab2' letterSpacing='0.66px' letterHeight='1.43'>
                {'We won’t be able to include employees or their family members with the following conditions:'}
            </Ptag>
            <Conditions>
            {
                column.map( (item, index) =>
                    <Condition>
                        <Number>
                            {`${index+1}. `}
                        </Number>
                        <Ptag fontSize='13px' letterHeight='2' letterSpacing='0.56px'>
                            {item}
                        </Ptag>
                    </Condition>
                )
            }
            </Conditions>
            
        </Home>
    )

}


const Home = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height:100vh;
  background: #fff;
  position: fixed;
  padding: 16px 16px 40px 16px;
  top: 0;
  left: 0;
  z-index: 20;
  transform: ${props =>
    props.show
      ? "translateY(0) scaleY(100%)"
      : "translateY(102%) scaleY(0)"};
  transition: all 0.6s;
  overflow:auto;
`;

const IconDiv = styled.div`
  display: flex;
  align-self: flex-end;
  margin-bottom: 16px;
`;

const Conditions = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 8px;
`

const Condition = styled.div`
    display: flex;
`

const Number = styled.div`
    margin-right: 4px;
    min-width: 19px;
    font-family: Open Sans;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 0.56px;
    color: #4a4a4a;
    text-align: right;
`