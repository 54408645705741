export const headers = [
    { label: "Name", key: "name" },
    { label: "Gender", key: "gender" },
    { label: "Date of Birth", key: "dob" },
    { label: "Email", key: "email" },
    { label: "Mobile", key: "employee_phone_number" },
    { label: "Dependents", key: "no_of_dependents" },
    { label: "Sum Insured", key: "sum_insured" },
    { label: "Subscription Amount", key: "total_amount" },
    { label: "Status", key: "policy_status" },
    { label: "Date of Addtion", key: "date_of_addition" },
    { label: "Policy No.", key: "policy_number" },
    { label: "Dependent Type", key: "dependents" },
    { label: "Dependent First Name", key: "first_name" },
    { label: "Dependent Last Name", key: "last_name" },
    { label: "Dependent DOB", key: "dependent_dob" },
]

export const planName = {
    gmc : "Group Health Insurance",
    gmc_m :  "Group Health Insurance",
    gpa : "Group Personal Accident",
    gtl : "Group Term Life",
    GMC : "Group Health Insurance",
    GMC_M :  "Group Health Insurance",
    GPA : "Group Personal Accident",
    GTL : "Group Term Life"
  }

export const FLEX_PRODUCTS = {
    'health_insurance': "Group Health Insurance",
    'personal_accident': "Group Personal Accident",
    'term_life': "Group Term Life",
    'opd_cover': "OPD Cover",
    'critical_illness': "Critical Illness",
    'health_checkup': "Health Checkup",
    'mandatory_top_up_health': "Mandatory Top Up Health",
    'top_up_health': "Top Up Health",
    'parental_cover': "Parental Cover"
}

export const ALL_PRODUCTS = {
    'GMC': "Group Health Insurance",
    'GMC_M': "Group Health Insurance",
    'GPA': "Group Personal Accident",
    'GTL': "Group Term Life",
    'health_insurance': "Group Health Insurance",
    'personal_accident': "Group Personal Accident",
    'term_life': "Group Term Life",
    'opd_cover': "OPD Cover",
    'critical_illness': "Critical Illness",
    'health_checkup': "Health Checkup",
    'mandatory_top_up_health': "Mandatory Top Up Health",
    'top_up_health': "Top Up Health",
    'parental_cover': "Parental Cover"
  }

export const PRIORITY_ORDER = [
    'GMC',
    'GMC_M',
    'GPA',
    'GTL',
    'health_insurance',
    'personal_accident',
    'term_life',
    'mandatory_top_up_health',
    'top_up_health',
    'opd_cover',
    'critical_illness',
    'health_checkup',
    'parental_cover'
  ]

export const Pdf=
{
	SBO:'https://vitalimages.s3.ap-south-1.amazonaws.com/Marketing/policy/Group+Care+Policy+Document_Care_Without_Maternity.pdf',
	SBOM:'https://vitalimages.s3.ap-south-1.amazonaws.com/Marketing/policy/Group+Care+Policy+Document_Care_With_Maternity.pdf',
	RETAIL:'https://vitalimages.s3.ap-south-1.amazonaws.com/policyDocuments/comprehensive_1.pdf',
    "KOTAK_ESC_M":'https://vitalimages.s3.ap-south-1.amazonaws.com/policyDocuments/VITAL_Kotak_Group_Care_Policy+Document_with_Maternity.pdf',
    "KOTAK_ESC":'https://vitalimages.s3.ap-south-1.amazonaws.com/policyDocuments/VITAL_Kotak_Group_Care_Policy+Document_without_Maternity.pdf',
    "KOTAK_PARENTAL":'https://vitalimages.s3.ap-south-1.amazonaws.com/Marketing/policy/Group+Care+Policy+Document_Kotak_Without_Maternity.pdf',
    "KOTAK_UNLIMITED":'https://vitalimages.s3.ap-south-1.amazonaws.com/Marketing/policy/Group+Care+Policy+Document_Kotak_Without_Maternity+-+no+capping.pdf',
    "KOTAK_UNLIMITED_M":'https://vitalimages.s3.ap-south-1.amazonaws.com/Marketing/policy/Group+Care+Policy+Document_Kotak_With_Maternity_no+capping.pdf',
    "GO_DIGIT":'https://vitalimages.s3.ap-south-1.amazonaws.com/Marketing/policy/Group+Care+Policy+Document_Kotak_With_Maternity_no+capping.pdf'
}

export const ArrayForFilter = [
    {name:'None',id:0},
    {name:'Activated',id:1},
    {name:'Activation Pending',id:2},
    {name:'Stopped',id:3}
]

export const historyHeaders = [
    { label: "Employee First Name", key: "employee_first_name" },
    { label: "Employee Last Name", key: "employee_last_name" },
    { label: "Gender", key: "gender" },
    { label: "Employee DOB", key: "employee_dob" },
    { label: "Mobile", key: "phone" },
    { label: "Email", key: "email" },
    { label: "Dependent Type", key: "relationship" },
    { label: "Dependent First Name", key: "first_name" },
    { label: "Dependent Last Name", key: "last_name" },
    { label: "Dependent DOB", key: "dob" },
    { label: "Sum Insured", key: "sum_insured" },
    { label: "Deductible", key: "deductible" },
]

export const CASHLESS_CLAIM_URLS = {
    NIVA: "https://transaction.nivabupa.com/Pages/hospitalnetwork.aspx",
    KOTAK: "https://www.kotakgeneral.com/Externalfiles/Kotak-Updated-Network-list_OCT-Mediassist.xlsx",
    ICICI: "https://www.icicilombard.com/cashless-hospitals",
    DIGIT: "https://www.medibuddy.in/networkHospitals",
    TATA:  "https://www.tataaig.com/locator/cashless-network-hospitals",
    DEFAULT: "https://www.careinsurance.com/health-plan-network-hospitals.html",
}
 
