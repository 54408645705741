import React, { Component } from "react";
import styled from "styled-components";
import DeductibleG from "../assets/svgs/deductibleG.svg";
import DeductibleJ from "../assets/images/deductibleJ.png";
import DeductibleN from "../assets/images/deductibleN.png";
import DeductibleZ from "../assets/images/deductibleZ.png";
import DeductibleQA from "../assets/images/deductibleQA.png";
import DeductibleK from "../assets/svgs/deductibleK.svg";
import faqData from "../components/memberApp/faq/faqData";
import Icons from "../components/icons";

const OuterDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

const ImageDv = styled.div`
  display: flex;

  & img {
    margin-top: 24px;
    // margin-left: -8px;
    width: 325px;
    height: 243px;
  }
`;
const ImgDv = styled.div`
  margin: 16px 0px 12px 24px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "16px")} @media
    (min-width: 400px) {
    margin: 14px 0px 12px 24px;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "14px")};
  }
`;
const Image = styled.img`
  align-items: center;
  justify-content: center;
`;

const TxtDiv = styled.div`
  margin: 10px 15px 0px 16px;
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: #4a4a4a;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "none"};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "10px")};
`;
const Header = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  height: 116px;
  background-color: #336188;
`;
const IconDiv = styled.div`
  display: flex;
  position: absolute;
  right: 0px;
  margin: 12px 12px 0px 0px;
`;
const Font24 = styled.div`
  font-family: Proxima Nova;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #ffffff;
  padding: 61px 0px 31px 24px;
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px;
`;
const Font14 = styled.p`
  // display: flex;
  // flex-direction: column;
  font-family: Avenir;
  font-size: ${(props) => (props.fontsize ? props.fontsize : "14px")};
  font-weight: ${(props) => (props.fontweight ? props.fontweight : "normal")};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.5px;
  color: ${(props) => (props.color ? props.color : "")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "none")};
`;


const Font16 = styled.div`
  display: flex;
  font-family: Avenir;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.57px;
  color: #4a4a4a;
  margin-left: 24px;
  margin-top: 14px;
`;
const Home = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
`;

const Dotted1 = styled.div`
  border-left: 3px dotted #436ab2;
  height: 40px;
  position: absolute;
  left: 28px;
  margin-top: 30px;

  @media (min-width: 400px) {
    height: 20px;
    margin-top: 30px;
  }
  @media (min-width: 1024px) {
    height: 34px;
  }
`;

const Dotted3 = styled.div`
  border-left: 3px dotted #436ab2;
  height: 22px;
  position: absolute;
  left: 28px;
  margin-top: 29px;
`;

const TexxtDiv = styled.p`
    display: flex;
    font-family: Avenir;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: 0.5px;
    color: #666666;
    margin: 18px 24px 0 42px;
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "18px")}
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "0")}
`;

const Bodyy = styled.div`
  display: flex;
  flex-direction: column;
`;

const Strong = styled.strong`
  font-weight: 600;
`;

const QuestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 24px 36px 24px;
  border: 1px solid #c8c8c8;
`;

const QuestionDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #c8c8c8;
`;

const Question = styled.p`
  margin: 18px 65px 16px 16px;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.2px;
  color: #436ab2;
`;

const Answer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 42px 24px 18px;
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: 0.25px;
  color: #4a4a4a;
  transition: all 3s;

  & ol {
    margin-left: 18px;
    margin-top: 6px;
  }
`;

const IcDiv = styled.div`
  display: flex;
  margin: 0 12px 0 0;
`;

const BreakDiv = styled.div`
  height: 12px;
  background-color: #eeeeee;
  width: 100%;
`;

const TextImageDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 34px 0px 26px 24px;

  .text {
    font-family: Avenir;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #4a4a4a;
    margin-right: 70px;
  }

  .image {
    display: flex;
    margin-right: 24px;
  }

  & img {
    height: 75px;
    width: 45px;
  }
`;

export default class LearnDeductable extends Component {
  state = {
    tabOpened: -1,
  };

  onquestionClicked = async (tabNumber) => {
    if (this.state.tabOpened === tabNumber) {
      await this.setState({ tabOpened: -1 });
    } else {
      await this.setState({ tabOpened: tabNumber });
    }
  };
  render() {
    return (
      <Home>
        <Header>
          <Font24>What is Deductible?</Font24>
          <IconDiv onClick={() => this.props.BackBtn()}>
            <Icons icon="cross" fill="#ffffff" height="24" width="24" />
          </IconDiv>
        </Header>

        <Body>
          <Font14>
            Deductible is the amount you will pay yourself towards treatments
            covered in the plan before insurance starts to pay.
          </Font14>

          <Font14 fontsize="12px" marginTop="24px" color="#436ab2">
            FOR EXAMPLE
          </Font14>

          <Font14 marginTop="18px">
            Ajay bought our plan with a deductible of ₹35,000 deductible
            and ₹10 lakhs sum insured to cover him, his wife and son.
          </Font14>

          <Font14 marginTop="8px">

            Ajay gets a bill of ₹1 lakh for himself. He pays ₹35,000
            towards this bill and his policy takes care of the rest.
          </Font14>

          <Font14 marginTop="8px">
            After a few months, Ajay gets a second bill of ₹1 lakh for his
            wife and this time his policy takes care of the entire bill.
          </Font14>

          <OuterDiv>
            <ImageDv>
              <Image src={DeductibleN} />
            </ImageDv>
          </OuterDiv>

          <Font14 fontsize="14px" marginTop="40px" fontweight="600">
            Why have deductible?
          </Font14>

          <Font14 marginTop="12px">
            By choosing a deductible, you can reduce your premium by up to 70%.
          </Font14>

          <Font14 fontsize="12px" marginTop="24px" color="#436ab2">
            FOR EXAMPLE
          </Font14>

          <Font14 marginTop="18px">
            Ajay saves <Strong>₹10,000</Strong> in premium per year for choosing
            our health plan with a deductible of <Strong>₹35,000. </Strong>
            If there are no claims for <Strong>4 years:</Strong>
          </Font14>

          <OuterDiv>
            <ImageDv>
              <Image src={DeductibleZ} />
            </ImageDv>
          </OuterDiv>
        </Body>

        <Bodyy>
          <Font16>How does it work?</Font16>

          <TexxtDiv>
            <ol>
              <li> For bills below the deductible limit </li>
            </ol>
          </TexxtDiv>

          <OuterDiv>
            <ImgDv>
              <Image src={DeductibleG} />
            </ImgDv>
            <TxtDiv marginTop="9px">
              Upload them through a tab on your dashboard.
            </TxtDiv>
            <Dotted1 />
          </OuterDiv>

          <OuterDiv>
            <ImgDv marginTop="12px">
              <Image src={DeductibleG} />
            </ImgDv>
            <TxtDiv marginTop="6px">
              We will account them in your deductible.
            </TxtDiv>
            {/* <Dotted2 /> */}
          </OuterDiv>

          <TexxtDiv marginTop="14px">
            <ol start="2">
              <li>
                {" "}
                For the first bill which crosses the <br />
                deductible limit{" "}
              </li>
            </ol>
          </TexxtDiv>

          <OuterDiv>
            <ImgDv>
              <Image src={DeductibleG} />
            </ImgDv>
            <TxtDiv>File an insurance claim.</TxtDiv>
            <Dotted3 />
          </OuterDiv>

          <OuterDiv>
            <ImgDv marginTop="10px">
              <Image src={DeductibleG} />
            </ImgDv>
            <TxtDiv marginTop="4px" marginBottom="px">
              Insurance will substract the pending deductible amount from the
              claim amount and settle the bill.
            </TxtDiv>
          </OuterDiv>

          <TexxtDiv marginTop="14px" marginBottom="36px">
            <ol start="3">
              <li>
                {" "}
                Insurance will pay in full for all future claims in the policy
                year.{" "}
              </li>
            </ol>
          </TexxtDiv>
        </Bodyy>
        {/* <MainWrap>
                        <Wrapper>
                            <ButtonText>
                                Use <b>Vital EMI</b> to pay <br />your <b>Deductibles</b>
                            </ButtonText>
                            <IDiv>
                                <Image src={DeductibleK}/>
                            </IDiv>
                        </Wrapper>
                    </MainWrap> */}
        <BreakDiv />

        <TextImageDiv>
          <div className="text">Common Questions from Members</div>
          <div className="image">
            <img src={DeductibleQA} />
          </div>
        </TextImageDiv>
        <QuestionWrapper>

          {
            faqData["deductiblequestion"].map((item, index) => 
            <div>
              <QuestionDiv id={String(index)} onClick={() => this.onquestionClicked(index)}>
                <Question>
                  {item}
                </Question>
                <IcDiv>
                {this.state.tabOpened === index ? (
                  <Icons icon="chevron_down" fill="#436ab2" />
                ) : (
                  <Icons icon="chevron_right" fill="#436ab2" />
                )}
                </IcDiv>
                </QuestionDiv>
                {this.state.tabOpened === index && (
                  <Answer
                    
                  >
                    {faqData["deductibleanswer"][index]}
                  </Answer>
                )}
              
            </div>
            )
          }

        </QuestionWrapper>
      </Home>
    );
  }
}
