export const marketplacePages = {
    MARKETPLACE_POINTS: 'marketplacepoints',

    WHO_IS_IT_FOR_VOUCHER: 'whoisitforvoucher',
    DATE_OF_ADMISSION_VOUCHER: 'dateofadmissionvoucher',
    HEALTH_CENTRE_SELECTION: 'healthcentreselection',
    SHIPPABLE_ADDRESS: 'shippableaddress',
    MARKETPLACE_CONTACT_INFO: 'marketplacecontactinfo',

    MARKETPLACE_PAYMENT: 'marketplacepayment',
}

export const offerType = {
    'SHIPPABLE' : 'shippable',
    'ONLINE_BOOKING_(DIAGNOSTIC)' : 'online_booking_(diagnostic)',
    'HEALTH_DEALS': 'health_deals',
    'HEALTH_CENTRE_VISIT': 'health_centre_visit',
    'HOME_SAMPLE_COLLECTION' : 'home_sample_collection',
    'ONLINE_CLASSES_(CLASSES/TRAINER)': 'online_classes_(classes/trainer)',
    'NORMAL_DEALS': 'normal_deals',
    // "DEFAULT" offer type if no offer type is mapped from backend 
    'DEFAULT': 'default'
}

export const offerBuyFlow = {
    [offerType['SHIPPABLE']]: [
        marketplacePages['MARKETPLACE_POINTS'],
        marketplacePages['WHO_IS_IT_FOR_VOUCHER'],
        marketplacePages['SHIPPABLE_ADDRESS'],
        marketplacePages['MARKETPLACE_CONTACT_INFO'], 
        marketplacePages['MARKETPLACE_PAYMENT'] 
    ],

    [offerType['ONLINE_BOOKING_(DIAGNOSTIC)']]: [
        marketplacePages['MARKETPLACE_POINTS'],
        marketplacePages['WHO_IS_IT_FOR_VOUCHER'],
        marketplacePages['DATE_OF_ADMISSION_VOUCHER'],
        marketplacePages['MARKETPLACE_CONTACT_INFO'], 
        marketplacePages['MARKETPLACE_PAYMENT'] 
    ],

    [offerType['HEALTH_DEALS']]: [
        marketplacePages['MARKETPLACE_POINTS'],
        marketplacePages['WHO_IS_IT_FOR_VOUCHER'],
        marketplacePages['DATE_OF_ADMISSION_VOUCHER'],
        marketplacePages['MARKETPLACE_CONTACT_INFO'], 
        marketplacePages['MARKETPLACE_PAYMENT'] 
    ],

    [offerType['HEALTH_CENTRE_VISIT']]: [
        marketplacePages['MARKETPLACE_POINTS'],
        marketplacePages['HEALTH_CENTRE_SELECTION'],
        marketplacePages['WHO_IS_IT_FOR_VOUCHER'],
        marketplacePages['DATE_OF_ADMISSION_VOUCHER'],
        marketplacePages['MARKETPLACE_CONTACT_INFO'], 
        marketplacePages['MARKETPLACE_PAYMENT'] 
    ],

    [offerType['HOME_SAMPLE_COLLECTION']]: [
        marketplacePages['MARKETPLACE_POINTS'],
        marketplacePages['WHO_IS_IT_FOR_VOUCHER'],
        marketplacePages['DATE_OF_ADMISSION_VOUCHER'],
        marketplacePages['SHIPPABLE_ADDRESS'],
        marketplacePages['MARKETPLACE_CONTACT_INFO'], 
        marketplacePages['MARKETPLACE_PAYMENT'] 
    ],

    [offerType['ONLINE_CLASSES_(CLASSES/TRAINER)']]: [
        marketplacePages['MARKETPLACE_POINTS'],
        marketplacePages['WHO_IS_IT_FOR_VOUCHER'],
        marketplacePages['MARKETPLACE_CONTACT_INFO'], 
        marketplacePages['MARKETPLACE_PAYMENT']
    ],

    [offerType['DEFAULT']]: [
        marketplacePages['MARKETPLACE_POINTS'],
        marketplacePages['WHO_IS_IT_FOR_VOUCHER'],
        marketplacePages['MARKETPLACE_CONTACT_INFO'], 
        marketplacePages['MARKETPLACE_PAYMENT']
    ],

    [offerType['NORMAL_DEALS']]: [
        marketplacePages['MARKETPLACE_POINTS'],
        marketplacePages['WHO_IS_IT_FOR_VOUCHER'],
        marketplacePages['MARKETPLACE_CONTACT_INFO'], 
    ]

}