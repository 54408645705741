import React, {useState} from "react";
import styled from "styled-components";
import {Ptag} from './fonts'


const ProposerDetailsIfa = ({memberDetails}) => {

    const [isCardOpen, setCardOpen] = useState(true)

    return(
        <Root>
            
            {
                isCardOpen && 
                <Body>
                    
                    <Ptag textTransform='uppercase' style={{'marginTop': '24px'}} letterHeight='1.67' letterSpacing='1px' color='#009999'>
                        {'Name'}
                    </Ptag>
                    <Ptag style={{'marginTop': '6px'}} fontSize='14px'  letterHeight='1.67' letterSpacing='3px'>
                        {memberDetails['name']}
                    </Ptag>


                    <Ptag textTransform='uppercase' style={{'marginTop': '24px'}} letterHeight='1.67' letterSpacing='1px' color='#009999'>
                        {'Mobile'}
                    </Ptag>
                    <Ptag style={{'marginTop': '6px'}} fontSize='14px'  letterHeight='1.67' letterSpacing='3px'>
                        {memberDetails['mobile']}
                    </Ptag>


                    <Ptag textTransform='uppercase' style={{'marginTop': '24px'}} letterHeight='1.67' letterSpacing='1px' color='#009999'>
                        {'Email'}
                    </Ptag>
                    <Ptag style={{'marginTop': '6px'}} fontSize='14px'  letterHeight='1.67' letterSpacing='3px'>
                        {memberDetails['email']}
                    </Ptag>


                    <Ptag textTransform='uppercase' style={{'marginTop': '24px'}} letterHeight='1.67' letterSpacing='1px' color='#009999'>
                        {'Address'}
                    </Ptag>
                    <Ptag style={{'marginTop': '6px'}} fontSize='14px'  letterHeight='1.67' letterSpacing='3px'>
                        {memberDetails['address']}
                    </Ptag>
                    
                </Body>
            }
            

        </Root>
    )
}

export default ProposerDetailsIfa

const Root = styled.div`
    display: flex;
    flex-direction: column;
    margin:0 0 18px;
    padding: 26px 18px 24px 18px;
    border-radius: 15px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.19);
    background-color: #ffffff;
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
`

const LeftHeader = styled.div`
    display: flex;
    align-items: center;
    & > img{
        margin-right: 10px; 
    }
`

const CheckboxWrapper = styled.div`
    width: 24px;
    height: 24px;
`

const HealthConditionWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 14px;
`

const HealthCondition = styled.div`
    display: flex;
    padding: 9px 12px;
    margin: 0 8px 8px 0;
    border-radius: 4px;
    background-color: #eff7ff;
    font-family: Avenir;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #4a4a4a;
    text-transform: capitalize;
`

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 18px;
   
`

const FooterContent = styled.div`
    display: flex;
    align-items: center;
    & > img{
        margin-left: 8px;
    }
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
`