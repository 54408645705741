import React, { Component } from 'react'
import styled from 'styled-components'
// import vitalInsuranceBuy1 from '../assets/svgs/vitalInsuranceBuy1.svg'
// import vitalInsuranceBuy2 from '../assets/svgs/vitalInsuranceBuy2.svg'
import vitalInsuranceBuy2 from '../assets/images/vitalInsuranceBuy2.png'
import halfCircleLightGreen1 from '../assets/svgs/flyingLogoWebsite/halfCircleLightGreen1.svg'
import halfCircleLightGreen4 from '../assets/svgs/flyingLogoWebsite/halfCircleLightGreen4.svg'
import pontsPage from '../assets/images/pointsPage.png'

const Wrapper = styled.div`
  width: 100%;
  padding: 4.8rem 0 3.6rem 0;
  background-color: #fff;
  position: relative;

  @media (min-width: 1024px) {
    padding-bottom: 4.8rem;
  }
  @media (min-width: 1680px) {
    padding-top: 15.2rem;
    
  }
`

const Header = styled.div`
  padding: 0 2.4rem;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1680px) {
    // padding-bottom: 14.6rem;
  }
`
const BottomBorder = styled.div`
  margin: 0 2.4rem;
  border-bottom: 2px solid #f9f9f9;

  @media (min-width: 1680px) {
    margin: 0 14.6rem;
  }
`

const FlexContainer = styled.div`
  width: 31rem;
  & > p {
    margin-top: 1.2rem;
    padding-right: 5rem;
  }

  @media (min-width: 1024px) {
    h3 {
      // margin-bottom: 1.8rem;
    }
    & > p {
      width: 29rem;
      margin-top: 1.8rem;
      // margin-right: 120px;
      // margin-bottom: 1.8rem;
      // margin-top: 0;
      padding: 0;
    }
  }

  @media (min-width: 1680px) {
    width: 60rem;
    h3:last-child {
      // margin-bottom: 4rem;
      font-size: 6.2rem;
      font-weight: normal;
      line-height: 1.61;
      letter-spacing: 0.03px;
    }
    & > p {
      width: auto;
      margin-top: 4rem;
      padding: 0;
    }
  }
`

const Circle = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.2rem;

  @media (min-width: 1024px) {
    margin-bottom: 2.4rem;
  }

  @media (min-width: 1680px) {
    margin-bottom: 4rem;
    width: 8rem;
    height: 8rem;
  }
`

const ImageWrapper = styled.div`
  // margin-top: 26px;
  display: flex;
  align-items: center;
  width: 50rem;
  div {
    margin: 0 auto;
  }

  @media (max-width: 425px) {
    width: auto;
  }
`

const Logo1 = styled.div`
  width: 3rem;
  height: 10rem;
  background: url(${halfCircleLightGreen4});
  background-repeat: no-repeat;
  position: absolute;
  bottom: 10%;
  right: 0;
`

const Logo2 = styled.div`
  width: 6rem;
  height: 8rem;
  background: url(${halfCircleLightGreen1});
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  left: 0;
`

const H3 = styled.h3`
  font-family: Proxima Nova;
  font-size: 2rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.02px;
  text-align: left;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    line-height: 1.16;
    font-size: 28px;
  }

  @media (min-width: 1680px) {
    font-size: 3.6rem;
    // line-height: 1.61;
    // font-size: 28px;
    letter-spacing: 0.03px;
  }
`

const Font20 = styled.p`
  font-family: Avenir;
  font-size: 2rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: 0.01px;
  text-align: left;
  color: #436ab2;

  @media (min-width: 1680px) {
    font-size: 4.8rem;
    line-height: 0.58;
  }
`
const LDiv = styled.div`
  margin-top: 48px;
`
const Font16 = styled.p`
  font-family: Avenir;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: 0px;
  text-align: left;
  color: #4a4a4a;
  margin-bottom: 24px;

  @media (min-width: 1024px) {
    font-size: 18px;
  }

  @media (min-width: 1680px) {
    font-size: 3.4rem;
    line-height: 1.59;
    letter-spacing: 0.02px;
  }
`
const Image = styled.img`
  width: 327px;
  height: 265px;
  @media (min-width: 1024px) {
    margin-left: 85px;
  }

  @media (min-width: 1680px) {
    width: 460px;
    height: 423px;
  }
  
`

export default class Page24 extends Component {
  render = () => {
    return (
      <Wrapper>
        <Header>
          <FlexContainer>
            <Circle>
              <Font20>3</Font20>
            </Circle>
            <H3>On buying health insurance </H3>
            {/* <H3> Vital Insurance</H3> */}
            <Font16>
              Get 500 Vital points per member added to your account when you buy insurance.
            </Font16>
          </FlexContainer>
          <ImageWrapper>
            {/* <Image src={pontsPage}/> */}
            <Image alt="buying health insurance" src = "http://images.getvital.in/homelanding/pointsPage.png" />
          </ImageWrapper>
        </Header>
        <Logo1 />
        <Logo2 />
        {/* <img src={vitalInsuranceBuy2} /> */}
        {/* <BottomBorder /> */}
      </Wrapper>
    )
  }
}
