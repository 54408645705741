import {offerBuyFlow, offerType as OfferTypesMappings, } from '../constants/marketplaceConstant'
import {offerRedemptionFlow} from '../constants/benefitRedemption'
export class AbstractMarketPlaceNavigation {
       
    getBackPage(){}
    getNextPage(){}
}
export class MarketPlaceNavigationHelper {

    /** 
     * offerType: type of offer coming from backend
     * currentPath: pass-in the PATH not route
    **/

    constructor(offerType, currentPath){
    
        if(!currentPath || !offerType){
            throw new Error('Invalid arguments to the constructor')
        } else {
            this.offerType = offerType.toLowerCase()
            const pathWithoutParams = currentPath.slice(0, currentPath.indexOf(':'))
            const pathWithoutSlash = pathWithoutParams.replaceAll('/', '')
            this.currentPath = pathWithoutSlash.toLowerCase()
            this.flow = offerBuyFlow[this.offerType] || offerBuyFlow[OfferTypesMappings['DEFAULT']]
        }   
    }

    getBackPage(){
        let flow = this.flow
        let currentIndexInFlow = flow.indexOf(this.currentPath)
        if (currentIndexInFlow < 1){
            throw new Error('Invalid current route!')
        }
        return `/${flow[currentIndexInFlow - 1]}`
    }

    getNextPage(){
        let flow = this.flow
        let currentIndexInFlow = flow.indexOf(this.currentPath)
        if (currentIndexInFlow < 0 || ((currentIndexInFlow + 1) >= flow.length)){
            throw new Error('Invalid current route!')
        }
        return `/${flow[currentIndexInFlow + 1]}`
    }
}

export class BenefitsRedemptionNavigationHelper extends MarketPlaceNavigationHelper {
    /** 
     * extends from MarketPlaceNavigationHelper 
     * offerType: type of offer coming from backend
     * currentPath: pass-in the PATH not route
    **/

    constructor(offerType, currentPath){
        super(offerType, currentPath)
        if(!currentPath || !offerType){
            throw new Error('Invalid arguments to the constructor')
        } else {
            this.offerType = offerType.toLowerCase()
            const pathWithoutParams = currentPath.slice(0, currentPath.indexOf(':'))
            const pathWithoutSlash = pathWithoutParams.replaceAll('/', '')
            this.currentPath = pathWithoutSlash.toLowerCase()
            this.flow = offerRedemptionFlow[this.offerType] || offerRedemptionFlow[OfferTypesMappings['DEFAULT']]
        }   
    }
}

