import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {getRelativesIcon, formatRelation,} from '../../utils/utils'
import {Ptag} from '../../components/fonts'
import blueArrow from '../../assets/images/darkBlueArrow.png'
import {useHistory} from 'react-router-dom'
import {getTags,getAge} from '../../utils/utils'
import editButton from '../../assets/images/pencilOnSlate.png'
import deleteIcon from '../../assets/images/redBin2.png'
import http from "../../services/httpService";

const MemberItemSelectable = ({memberDetails, healthQuestions, getFamilyMembers}) => {

    let history = useHistory();
    const tags = getTags(memberDetails && memberDetails['health_issue_details'], healthQuestions)
   
    const onClickModifyMember = () => {
        history.push(`/memberDetail/${memberDetails['member_id']}`)
    }

    const onDeleteMember = async () => {
        await  http.delete(`/users/family/${memberDetails["member_id"]}/`);
        getFamilyMembers()
    }
    

    const capitalize = (text) => {
        if(text){
            return text[0].toUpperCase() + text.slice(1)
        }
    }
    return(
        <Root selected={true}>
            <Header>

                <LeftHeader>
                    <img height='30px' width='30px' src={getRelativesIcon(memberDetails['relationship'])}/>
                    <Ptag  color='#009999' letterSpacing='0.25px' fontSize='16px' >
                        {`${capitalize(formatRelation(memberDetails['relationship']))}, ${getAge(String(memberDetails['dob']), '-')}yr`}
                    </Ptag>
                </LeftHeader>

                {
                    <CheckboxWrapper onClick={onClickModifyMember}>
                        <img src={editButton} width='18px' height='18px' />
                    </CheckboxWrapper>
                }
                
                
            </Header>
            {
                tags && tags.length > 0 &&
                <HealthConditionWrapper>
                    {
                        tags.map(
                            item => 
                            <HealthCondition key={item}>
                                {item}
                            </HealthCondition>
                        )
                    }
                </HealthConditionWrapper>
                    
            }
            <Footer>
                {
                    !memberDetails['is_active'] && memberDetails['relationship'].toUpperCase() !== 'SELF' &&
                    <FooterContent onClick={onDeleteMember}>
                        <img  src={deleteIcon} height='18px' width='16px'/>
                        <Ptag fontSize='14px' fontWeight='600' letterSpacing='0.58px' color='#f77963' >
                            {'DELETE'}
                        </Ptag>
                    </FooterContent>
                }
                
                
            </Footer>
        </Root>
    )
}

export default MemberItemSelectable

const Root = styled.div`
    display: flex;
    flex-direction: column;
    margin:0 0 18px;
    padding: 26px 18px 24px 18px;
    border-radius: 15px;
    box-shadow: 0 1px 6px 1px rgb(0 0 0 / 19%);
    background-color: #ffffff;
    border: ${props => props.selected ? '1px solid #436ab2' : ''}
`

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #4a4a4a30;
    padding-bottom: 18px;

`

const LeftHeader = styled.div`
    display: flex;
    align-items: center;
    & > img{
        margin-right: 10px; 
    }
`

const CheckboxWrapper = styled.div`
    width: 24px;
    height: 24px;
`

const HealthConditionWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 18px;
`

const HealthCondition = styled.div`
    display: flex;
    padding: 9px 12px;
    margin: 0 8px 8px 0;
    border-radius: 4px;
    background-color: #eff7ff;
    font-family: Avenir;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: #4a4a4a;
    text-transform: capitalize;
`

const Footer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
   
`

const FooterContent = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    & > img{
        margin-right: 8px;
    }
`