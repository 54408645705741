const credit_banks_name = [
    {
      bank_name: 'Visa',
      title: '',
      image: require('../../../assets/images/visa.png')
    },
    {
      bank_name: 'Mastercard',
      title: '',
      image: require('../../../assets/images/master_card.png')
    },
    {
      bank_name: 'Others',
      title: 'OTHERS',
      image: require('../../../assets/images/payCredit.png')
    }
  ] 
  
  export default credit_banks_name
  