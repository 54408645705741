import React, { Component } from 'react'
import styled from 'styled-components'
import { OverlayLoader} from '../../components'
import http from '../../services/httpService'
import {Header} from '../../components/business'
import {Ptag} from '../../components/fonts'
import TagManager from 'react-gtm-module'
import {gtmCode} from '../../utils/utils'
import blueArrow from '../../assets/images/blueArrow.png'


export default class OptionForEmployeeDetails extends Component {

    constructor(props){
        super(props)
        this.state = {
            loading: true,
            data: {}
        }
    }

    async componentDidMount(){
        const tagManagerArgs = {
            gtmId: gtmCode(),
          }
        TagManager.initialize(tagManagerArgs)
        
        this.setState({ loading: false})
    }

    onClickCard = (card) => {
        return card ? this.props.history.push('/business/uploadexcel') : this.props.history.push('/business/memberDetails')
    }

    render() {
        return (
            <Root>
                <Header/>
                <Body>
                    <Ptag fontFamily='Proxima Nova' style={{'marginLeft': '4px'}} fontSize='24px' fontWeight='500' letterHeight='1.33' letterSpacing='0.35px'>
                        {'enter member details'}
                    </Ptag>
                    <Card id='business_optionstoaddemployees_enter_details_manually' onClick={() => this.onClickCard(0)}>
                        <Ptag fontSize='18px' fontWeight='600' letterSpacing='0.5px' color='#436ab2'>
                            {'Enter Details'}
                        </Ptag>
                        <Ptag style={{'margin': '8px 0 12px'}} fontSize='14px' letterHeight='1.43' letterSpacing='0.5px' color='#696969'>
                            {'Enter employee and dependents data manually'}
                        </Ptag>
                       
                        <img style={{'marginLeft':'auto'}} src={blueArrow} height='20px' width='20px'/>
                        
                    </Card>
                    <Card id="business_optionstoaddemployees_upload_excel" onClick={() => this.onClickCard(1)}>
                        <Ptag fontSize='18px' fontWeight='600' letterSpacing='0.5px' color='#436ab2'>
                            {'Upload an Excel'}
                        </Ptag>
                        <Ptag style={{'margin': '8px 0 12px'}} fontSize='14px' letterHeight='1.43' letterSpacing='0.5px' color='#696969'>
                            {'Enter employee and dependents data in an Excel'}
                        </Ptag>
                        <img style={{'marginLeft':'auto'}} src={blueArrow} height='20px' width='20px'/>
                        
                    </Card>
                </Body>
                
                
               {this.state.loading && <OverlayLoader />}
            </Root>
        )
    }
}

const Root = styled.div`
	width: 100%;
	background-color: #ffffff;
    display: flex;
    flex-direction: column;
  
`
const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    flex: 1;
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 18px;
    border-radius: 10px;
    border: solid 1px #979797;
    margin-top: 24px;
    cursor: pointer;
`
