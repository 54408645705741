import React from 'react'
import { Field } from 'formik'
import DropDown from './dropDown'

const RenderDropDown = ({
  name,
  placeholder,
  option,
  value,
  validate = null,
  selectedOption = { id: '0', label: '' },
  error,
  ...restProps
}) => {
  return(
    <Field
    name={name}
    validate={validate}
    render={({ field }) => (
      <DropDown
        placeholder={placeholder}
        option={option}
        selectedOption={selectedOption}
        value={value}
        error={error}
        {...restProps}
      />
    )}
  />
  );
}



export default RenderDropDown
