export const TermsAndConditionsText = {
  PartOne: [
    "These terms and conditions (“T&Cs”) for marketing services set out the basis on which you, as an individual resident in India (hereinafter referred to as “Service Provider”) shall provide the marketing services to Lifefront Cover Private Limited (hereinafter referred to as “Company”) through its website or mobile application (hereinafter referred to as “GetVital Portal”).",
    "The Service Provider and the Company shall hereinafter collectively be referred to as the “Parties” and individually as the “Party”.",
  ],
  PartTwo: [
    "The Company is inter alia, engaged in the business of providing health and wellness related products and services in India(hereinafter referred to as “Products”) on the GetVital Portal, and is desirous of availing the marketing and related services thereto, which are specifically set out in Schedule 1 of these T & Cs, from the Service Provider(“Services”), and Service Provider agrees to offer the Services described to the Company on the terms and conditions set out hereunder.",
  ],
  PartThree: [
    "By accepting these T&Cs, the Service Provider is agreeing to enter into a contract with the Company which is governed by these T&Cs and created when the Service Provider accepts these T&Cs. If you as the Service Provider do not wish to be bound by these T&Cs, please do not accept these T&Cs.",
    "In consideration of the representations made by the Service Provider and pursuant to the mutual understanding hereinbelow contained along with the applicable consideration set out hereunder, the Parties hereto do hereby agree as follows:",
  ],
  Definition: [
    "T&Cs: Means these T&Cs, the schedules hereto, and includes each of them as may be amended from time to time as agreed between the Parties;",
    "Advertisement Material: Means the content, copy, layouts, artwork, storyboards, scripts, presentations, drawings, documents, charts, graphics, photographs, films and/or other materials provided by the Authorised Personnel of the Company to the Service Provider for advertising in accordance with the terms of these T&Cs, which shall not be intended for solicitation and procurement of insurance business by the Service Provider and shall contain the registered name, address, toll-free number and Logo of the Company.",
    "Authorised Personnel: Means the person authorised by the Parties respectively for the performance of its respective duties and under these T & Cs, and duly communicated to other Party.",
    "Confidential Information: Shall have the meaning ascribed to it under Clause 12.1.",
    "Effective Date: Shall have the meaning ascribed to it under Clause 3.1.",
    "Fees: Means the fees as set out in Schedule - II of these T&Cs, subject to deduction of applicable taxes.",
    "Force Majeure Conditions: Shall have the meaning ascribed to it under Clause 14.9.",
    "GetVital Portal: Shall mean the Company’s website ‘getvital.in’ or mobile application through which it provides its customers with the Products.",
    "Logo: Shall mean the Company’s logo, trade name or any other symbol.",
    "Medium: The electronic or physical media used by the Service Provider for providing the Services.",
    "Products: Shall mean the health and wellness related products and services offered by the Company, from time to time.",
    "Services: Shall mean the advertisement and sales related services thereto services as specifically set out in Schedule - I of these T&Cs.",
    "Term: Shall have the meaning ascribed to it under Clause 3.1.",
  ],
  Definitions: [
    "references to any statute or statutory provisions include a reference to the statutory amendments, modifications or re-enactments of it;",
    "words imparting one gender include all other genders and words imparting the singular include the plural and vice versa; references to any gender-neutral terms or pronouns shall include references to either individuals or entities.",
    "the clause headings do not form part of these T&Cs and shall not be taken into account in its construction or interpretation;",
    "reference in these T&Cs to any clause, sub-clause, schedule or paragraph without further designation shall be constructed as reference to the clause, sub-clause, schedule or paragraph of these T&Cs as so numbered; ",
    "references to a “person” include any individual, company, body corporate, corporation, government, state or agency of a state, firm, partnership, joint venture, association, organisation or trust (in each case, whether or not having separate legal personality and irrespective of the jurisdiction in or under the law of which it was incorporated or exists) and a reference to any of them shall include a reference to the others.",
    "Any obligation on a Party not to do something includes an obligation not to allow that thing to be done.",
    "Any words following the terms including, include, in particular, for example or any similar expression shall be construed as illustrative and shall not limit the sense of the words, description, definition, phrase or term preceding those terms.",
  ],
  Appointment: [
    "The Company hereby appoints the Service Provider on a non-exclusive basis to provide the Services under these T&Cs (more specifically described in Schedule - I of these T&Cs) in relation to the Company’s Products.",
    "The Company hereby grants the Service Provider the right to advertise, promote, market, and sell/offer for sale the Company’s Products throughout the territory of India, to the extent required for the provision of the Services under these T&Cs",
    "Notwithstanding the foregoing, the Company shall be free to appoint other providers of the Services in relation to the advertising, promotion, marketing and sale of the Company’s Products.",
  ],
  Termination: [
    "The Service Provider hereby agrees to provide to the Company the Services listed in Schedule - I of these T&Cs during the Term of these T&Cs subject to the terms and conditions of these T&Cs.",
    "These T&Cs shall come into effect from the date of acceptance of these T&Cs by the Service Provider (“Effective Date”) and unless terminated earlier as per the terms of these T&Cs shall remain in force for 1 year (“Term”).Upon expiry of the Term, the Parties may extend the Term of these T&Cs based on mutual agreement.",
    "These T&Cs may be terminated at any time during the Term of these T&Cs by either Party without assigning any reason by providing 15 (fifteen) days’ written notice to the other Party. In the event of such termination, the Service Provider shall still be entitled to receive the Fees due from the Company for the sales performed till the date of such termination as a part of the Services provided under these T&Cs.",
    "Notwithstanding the provisions contained above, the Company reserves the right to terminate the T&Cs forthwith by providing a notice in writing to the Service Provider on the occurrence of any or all of the following events:",
    "If the Service Provider breaches any law, rule or regulation as applicable from time to time.",
    "Unsatisfactory provision of the Services by the Service Provider during the Term of these T&Cs.",
    "Any representations and/or warranties made by the Service Provider under these T&Cs being found to be false or untrue.",
    "It is hereby clarified that termination of these T&Cs shall be without prejudice to any accrued rights or obligations of the Parties up to the date of termination.",
    "Upon expiry or termination of these T&Cs, the Service Provider shall immediately cease all further performance of the Services under these T&Cs. Further, the Service Provider shall immediately cease to use, display or exhibit, and forthwith return and hand over all materials/ documents along with any data, information, electronic files, electronic data files and such other information, including the Confidential Information defined in Clause 12 herein below, without retaining any copy (whether in hard copy or electronic format) with itself to the Company. Such hand over shall be conducted by the Service Provider in the presence of a representative of the Company. It is hereby clarified that the obligation under this Clause shall survive the termination of these T&Cs.",
  ],
  Work: [
    "The Service Provider hereby agrees to provide to the Company the Services listed in Schedule - I of these T&Cs during the Term of these T&Cs subject to the terms and conditions of these T&Cs. ",
    "The Parties hereby agree that per the requirements of the Company, the scope of the Services set out in Schedule - I of these T&Cs may be revised or amended in accordance with Clause 14.1 of these T&Cs. ",
  ],
  Fees: [
    "In consideration of the satisfactory performance of the Services by the Service Provider and performance of the terms and conditions contained in these T&Cs, the Company shall pay to the Service Provider the fees as detailed in Schedule - II of these T&Cs and in the manner specified therein (“Fees”), subject to deduction of applicable taxes.",
    "The Service Provider acknowledges and agrees that other than the Fees agreed to be paid by the Company to the Service Provider under these T&Cs, the Company shall not be liable or obligated to pay any other sums of money, by whatever name called, to the Service Provider in consideration of the Services provided by the Service Provider.",
    "In the event the Company disputes the amount raised in an invoice or any part thereof, the Company shall have the right to withhold payment of such disputed amount. The Parties agree that they shall endeavour to negotiate on the disputed amount in good faith and expeditiously and based on the decisions made by them, the amount will be paid on the revised invoices. In the event the Parties are unable to resolve the dispute, the matter shall be referred to arbitration in accordance with Clause 13.3 of the T&Cs.",
  ],
  Obligations: [
    "ensure compliance with all applicable laws, statutes, rules and regulations in relation to providing the Services to the Company.",
    "ensure that while providing the Services, it does not commit any act, which has the effect of the Company or the Service Provider breaching any law, statute, rules and regulations applicable to the Company and/or the Service Provider.",
    "in performing the Services under these T&Cs, the Service Provider shall not, at any time, use any confidential or proprietary information of a third party or infringe the intellectual property rights of such party.",
    "except to the extent expressly provided in these T&Cs, the Service Provider shall not, at any time, use the name and/or Logo of the Company in any manner whatsoever without the prior written consent of the Company.",
    "perform its obligations and duties in terms of these T&Cs in accordance with best industry practice, using all due skill, care and diligence, and further agrees to abide by such directions and instructions relating to the performance of its obligations and duties under these T&Cs as may, from time to time, be issued by the Company.",
    "provide all the necessary assistance/co-operation to the Company to enable the Company to comply with the provisions of applicable law, including any direction issued by the Insurance Regulatory and Development Authority of India (“IRDAI”), and also provide all the information/documents/clarifications, as may be required by the IRDAI from time to time.",
    "not, in any manner, represent to any customer that it is an insurance agent or insurance intermediary or is in any way authorized to solicit or promote any insurance related products or services for the Company.",
    "not solicit any insurance products or business for or on behalf of the Company from any person/customer while providing the Services to the Company. Moreover, it shall not represent to any person/customer that it is authorised to act as an insurance agent or insurance intermediary or that it is soliciting and procuring any insurance products or business on behalf of the Company. For the avoidance of doubt, dissemination of any general information pertaining to the features and description of a group insurance policy obtained by the Company as a group master policyholder for its customers, from an Insurance Company duly registered with the IRDAI, including any Advertisement Material containing the same, shall not be considered as solicitation or procurement of insurance business, and may be carried out by the Service Provider. For the purpose of such group insurance policy, it is further clarified that the Service Provider may assist in the admission of such members into the group insurance policy, where available as a part of the Company’s Product.",
    "not carry out other activities for the Company in relation to these T&Cs, which fall outside the scope of the Services set out in Schedule - I of these T&Cs.",
    "provide to the Company, its duly appointed agents and auditor(s), upon reasonable notice from the Company in writing, reasonable access to the sites, infrastructure and resources from which the Services are being provided under these T&Cs (including any relevant personnel, systems, facilities, books and records) for the purposes of performing audits or inspections of the Service Provider’s performance of its obligations (including the Services) under these T&Cs. Such audits shall be conducted in such a way as to not adversely impact the Services or the main business of the Service Provider.",
    "provide the Company, its duly appointed agents and auditors, upon reasonable notice from the Company in writing, with access to such records and supporting documentation (including financial records) as may be reasonably requested by the Company in connection with or relating to the performance of the Service Provider’s obligations under these T&Cs (including in relation to the Fees) and to determine that such Fees are accurate and in accordance with the T&Cs and that the Services in relation to which Fees are being paid are actually being provided.",
    "The Company and its Authorised Personnel shall co-operate with the Service Provider and shall provide to the Service Provider, at the Service Provider's request, such information concerning the Company, the Company's requirements in respect of the Services and the advertising and sales to which the Services relate as is reasonably necessary to enable the Service Provider to perform the Services.",
    "The Company shall provide the relevant inputs regarding any approval, request and/or sign-off as may be required by the Service Provider in relation to providing the Services under these T&Cs within reasonable timelines. ",
    "Ensure that the Company’s Authorised Personnel shall review the performance of the Service Provider while rendering the Services under these T&Cs on a periodical basis and provide the requisite sign-off in relation to the sales recorded on the GetVital Portal while processing the invoice received from the Service Provider under these T&Cs.",
  ],
  Warrenties: [
    "Each Party represents and warrants to the other that (i) it has and will continue to have the requisite authority to enter into these T&Cs and to perform its obligations hereunder; (ii) it is neither a party to any agreement nor is it aware of any law, regulation or order that would prohibit it from entering into and performing its obligations under these T&Cs, or that would conflict with the terms of these T&Cs; (iii) these T&Cs constitutes a legal, valid and binding obligation upon it, enforceable against it in accordance with the terms of these T&Cs; (iv) execution of and performance of its obligations under these T&Cs shall not breach any oral or written agreement with any third party; and (v) it shall comply with all applicable laws, statutes, rules, regulations and applicable industry standards.",
    "The Service Provider hereby specifically represents and warrants to the Company that:",
    "The Service Provider has and shall continue to have the necessary infrastructure, resources, experience, facilities, operational and process capabilities to perform its obligations under these T&Cs in the most timely, efficient and effective manner.",
    "The Service Provider, during the Term of these T&Cs, shall not engage itself, directly or indirectly, in any business that is in competition with the activity/business/services of the Company;",
    "The information submitted by the Service Provider in the form of representations and/or warranties to the Company or otherwise is true and complete and there is no material fact that has not been disclosed which may have an adverse impact on the decision of the Company to enter into these T&Cs.",
    "The Service Provider hereby specifically acknowledges that it is not a licensed insurance agent or insurance intermediary, and hence it cannot solicit or procure insurance business in any form for the Company. The Service Provider further undertakes that at no time during the term of these T&Cs, either represent itself as an insurance agent or an insurance intermediary, either directly or indirectly, or solicit or procure insurance business for the Company in any manner whatsoever.",
  ],
  Indemnifcation: [
    "The Service Provider hereby covenants to indemnify and hold harmless the Company, its employees, servants and agents from and against all actions, claims, demands, losses, damages, costs and expenses for which the Company shall or may be or become liable which directly arise from, are incurred as a consequence of or are otherwise directly attributable to the Services provided by the Service Provider, except to the extent such liability directly arises in relation to the contents of the Advertisement Material provided by the Company.",
  ],
  Parties: [
    "The Parties hereby agree that the Service Provider’s relationship with the Company shall be that of an independent service provider and nothing contained in these T&Cs shall be construed or deemed to be construed to create a partnership, joint venture, or employer-employee relationship between the Parties.",
    "The Parties agree that the Service Provider shall not be regarded as an agent or representative of the Company and is not authorized to make any contract or commitment for and on behalf of the Company.",
    "It is hereby clarified that any employee (or other personnel by whatever named called) used by the Service Provider in providing the Services or otherwise fulfilling its obligations under these T&Cs shall not be employees of the Company. In this regard, it is further clarified that the Service Provider shall be solely liable for the discharge of all obligations of an employer towards such employees (including without limitation the payment of all remuneration of any type whatsoever as may be contractually agreed between the Service Provider and the employee or under the provision of any law or under the provisions of any collective bargaining settlement) under applicable law. For the purposes of clarifying any ambiguity or misapprehension that may exist amongst any employee, the Service Provider shall inform the employees that at all times they shall be considered to be the employees of the Service Provider only and shall not be eligible to claim at any time to be employees of the Company. ",
  ],
  Confidentiality: [
    "For the purpose of these T&Cs, the term “Confidential Information” shall mean any information disclosed or provided by the Company to the Service Provider in connection with these T&Cs, which is disclosed or provided (as the case may be) in writing, orally or by inspection and whether or not is identified as “Confidential” or “Proprietary”, is of a confidential or proprietary nature or which a reasonable person would believe should be treated as such. All technical or business information furnished or disclosed, in whatever form or medium, by the Company to the Service Provider, including but not limited to Advertisement Material, trademarks, trade names, logos, and designs, computer programs (source code, object code and listings), reports, documentation, drawings, models, work-in-progress, product/service specifications, prototypes, personnel statistics, marketing and strategic information, proprietary research data and financial plans and analyses, information concerning customers, trade secrets, methods, processes or procedures of the Comapny, or its financial information, whether or not specifically identified herein, shall be treated as Confidential Information.",
    "In relation to any Confidential Information, the Service Provider agrees:",
    "To not use or permit the use of the Confidential Information in any manner which is incompatible or inconsistent with the terms of these T&Cs;",
    "To not misuse or permit misuse directly or indirectly, commercially exploit the Confidential Information for its own purpose or for any purpose other than fulfilling its obligations per the terms and conditions of these T&Cs;",
    "To not cause any unauthorized disclosure, misuse, loss or destruction of the Confidential Information through any failure to exercise all due care and diligence or through any failure to take all reasonable precautions.",
    "To notify the Company promptly of any unauthorized disclosure, misuse, loss or destruction of the Confidential Information;",
    "To not make or retain any copies or records of any Confidential Information submitted by the Company other than as may be required for the performance of Service Provider’s obligations under these T&Cs;",
    "To return all the Confidential Information that is in the custody of Service Provider and/or its employees upon termination/expiry of the T&Cs;",
    "Notwithstanding the foregoing or any other provision of these T&Cs, nothing contained herein shall prevent the Service Provider from disclosing all or any portion of the Confidential Information as required pursuant to any law, or order or requirement of a court, administrative agency or other governmental or regulatory body. Provided, however, that the Service Provider shall, to the extent permissible under applicable law, promptly notify the Company along with the sufficient details, and shall use its best efforts as may be reasonable, at the Company’s expense, to obtain a protective order against such order or other legal or regulatory request or otherwise prevent disclosure of such information.",
    "In the event of any breach by the Service Provider of this clause, including any threatened breach, the Company shall be entitled to injunctive relief in addition to monetary damages with respect to such breach or threatened breach. ",
    "The Service Provider hereby agrees that the terms and conditions of these T&Cs, including the terms and information contained in all Schedules attached hereto are confidential and crucial to the business interests / prospects and as such, the Service Provider agrees to keep such terms, conditions, information as strictly confidential.",
    "The obligations under this Clause shall survive the expiry and/or termination of these T&Cs.",
  ],
  Dispute: [
    "The provisions of these T&Cs shall be governed by and constructed in accordance with Indian law.",
    "If any dispute, controversy or claim arises under or in relation to these T&Cs, including any question regarding its existence, validity or termination the Parties shall use all reasonable endeavours to resolve the matter amicably.",
    "All disputes which are unresolved pursuant to Clause 13.2 above shall be referred to the decision of a sole arbitrator as may be agreed upon by both Parties and any such reference shall be subject to and governed by the Indian Arbitration and Conciliation Act, 1996 and the rules formed thereunder. The seat of arbitration and hearings shall be New Delhi. The language of the arbitration shall be English.",
    "Subject to Clause 13.3, the courts in [New Delhi] shall have the exclusive jurisdiction over any disputes or differences arising under or in relation to these T&Cs. ",
  ],
  General: [
    "Any provision of these T&Cs shall be amended only if such amendment is in writing and signed by each Party.",
    "Property Rights: The intellectual property in the name/ Logo of the Company and any additions, substitutes or derivatives thereof and in all the information/documents provided to the Service Provider by the Company, including any Confidential Information and Advertisement Material, in any form or medium, shall solely and exclusively belong to the Company and the Service Provider shall not acquire any right whatsoever in the same",
    "The Service Provider shall not assign or purport to assign any of its rights and obligations under these T&Cs, except with the prior written consent of the Company.",
    "If any provision of these T&Cs or the application thereof to any person or circumstance shall be invalid or unenforceable to any extent for any reason including by reason of any law or regulation or government policy or any amendment thereof, the remainder of these T&Cs and the application of such provision to persons or circumstances other than those as to which it is held invalid or unenforceable shall not be affected thereby, and each provision of these T&Cs shall be valid and enforceable to the fullest extent permitted by law. Any invalid or unenforceable provision of these T&Cs shall be replaced with a provision that is valid and enforceable and most nearly reflects the original intent of the unenforceable provision.",
    "Notices :",
    "Any notice, request, demand, approval, consent or other communication permitted or required to be given under these T&Cs shall be effective only if it is in writing, in the English language, and delivered (i) personally, or (ii) by registered or certified mail, or (iii) by courier, or (iv) by telecopier or other electronic communication, transmission confirmed and addressed to the other Party.",
    "Any notice, document or communication:",
    "given by hand is deemed to be received at commencement of the working day next following delivery to that addressee; or",
    "sent by fax is deemed to be received at the commencement of the working day next following receipt by the sending Party of an electronic confirmation of transmission of the notice to that addressee, which transmission is to be confirmed by a courier transmission date-marked the same day as the fax transmission it is confirming; or",
    "the working day of receipt, if sent by prepaid registered post with acknowledgement due or courier. ",
    "These T&Cs together with all its schedules is the final, complete and exclusive agreement of the Parties with respect to the subject matter hereof and supersedes all prior discussions, negotiations and understanding between the Parties with respect to the subject matter of these T&Cs.",
    "Each of the Parties to these T&Cs shall from time to time execute and deliver all such further documents and do all acts and things as any other Party may reasonably require to effectively implement the full intent and meaning of these T&Cs and/or to the complete the transactions contemplated hereunder.",
    "The failure on the part of either Party to enforce at any time any provision of these T&Cs or non-exercise of any of its rights hereunder shall not constitute a waiver thereof nor shall it affect the right of that Party to subsequently enforce that provision or to terminate these T&Cs for any subsequent default. It is further clarified that the rights and remedies herein provided shall be cumulative and not exclusive of any rights or remedies provided by law.",
    "Neither Party shall be liable for any delay or failure to perform its obligations under these T&Cs or shall be in breach of these T&Cs, if such delay or failure or breach occurs due to any force majeure causes, including but not limited to acts of God, floods, fire, strikes, riots, acts of war or hostilities of any nature, laws or regulations of any government (whether foreign or domestic, country, state or municipal) or any other similar cause beyond the reasonable control of the Party unable to perform its obligations hereunder (“Force Majeure Conditions”)",
  ],
  Schedule: [
    "The Service Provider will have a non-exclusive right to advertise, promote, market and sell the Company’s Products available on the GetVital Portal throughout the territory of India, for such duration as mutually agreed between the Parties, for payment of such fees as stipulated under these T&Cs. The Service Provider will provide the following Services:",
    "Display the Advertisement Material of the Company during the Term of these T&Cs, as approved by and communicated/provided to the Service Provider by the Authorised Personnel of the Company from time to time, on a Medium approved by such Authorised Personnel. The Service Provider shall not display any other information regarding the Service Provider on any Medium, without the prior written approval of the Authorised Personnel of the Company. ",
    "Identify prospective customers, and explain the features of the Products as described in the Advertisement Material provided to the Service Provider.",
    "Offer the range of Products, as may be updated from time to time on the GetVital Portal, for sale to prospective customers during the Term of these T&Cs, and also use reasonable efforts to effect such sale. ",
    "Where available as a part of the Product, assist in the admission of individuals as members under the group insurance policy obtained by the Company for its customers, from an Insurance Company duly registered with the IRDAI.",
    "Provide a written summary of completed sales activity to the Company in an electronic format, on a monthly basis, with details of the Company’s Products sold to customers referred by the Service Provider during such calendar month, and the total amount of each transaction.",
    "Collect the contact information of each customer that it has referred to the Company, including but not limited to, the complete name, city of residence, phone number and e-mail address of the customer, and communicate the same to the Company on a daily basis. ",
    "Service Provider will be liable for its own costs and expenses relating to the provision of the Services under these T&Cs, including all “out-of-pocket” expenses incurred in relation to advertising, marketing, promoting, selling, administrative and other expenses, including any publication of the Advertisement Material provided by the Company on any Medium, and shall not be entitled to reimbursement from the Company.",
    "For Services set out in Schedule –I, the Service Provider will receive a one time advertising fee per company to which the services are sold based on the number of employees in the company as per the following table:",
  ],
  Payment: [
    "For the payment of the Fees, the Service Provider shall receive payment for the amounts due and payable under these T&Cs on a monthly basis. ",
    "In addition, the applicable taxes, including, but not limited to, the goods and service tax, shall be borne by the Party who is liable to pay such taxes as per the provisions of applicable law. ",
  ],
  Declaration: [
    "You, as the Service Provider, declare that all the information provided to the Company is true, accurate and complete to the best of your knowledge and information, and that you have read these T&Cs and agree to be bound by them. If it is discovered that the information provided contains material inaccuracies or fraudulent information, the Company may terminate the T&Cs pursuant to Clause 3.",
  ],
};
