import React from 'react'
import styled from 'styled-components'


const Header = styled.div`
  width: 100%;
  margin: 24px 77px 24px 24px;
  display: flex;
  position: relative;
  height:10px;
  border-radius: 5px;
  background-color: #d8d8d8;
`

const Oval = styled.span`
  display: inline-block;
  position: absolute;
  width: ${props => (props.width + '%')};
  height: 10px;
  border-radius: 5px;
  background-color: ${props => (props.BgColor ? props.BgColor : '#436ab2')};
  z-index: ${props => (props.zIndex ? props.zIndex : 10)};
`


const Pagination = ({ progress, ...restProps }) => {
  return (
    <Header>
      <Oval width={progress}/>
    </Header>
  )
}

export default Pagination


