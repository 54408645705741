import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import {Ptag} from './fonts'

export default class AddFamilyMemberButtons extends Component {


    renderButton = (button,key) => {
        const inlawIndex = button.indexOf('INLAW')
        const buttonNew = inlawIndex > -1 ? button.substr(0, inlawIndex) + '-IN-LAW' : button
        if (this.props.members[button]){
            return (
                <Button type='button' key={key} onClick={() => this.props.onClickFamilyButton(button,this.props.formikProps) }>
                    <Ptag textTransform="capitalize" textAlign='center'>
                        {buttonNew.toLowerCase()}
                    </Ptag>
                </Button>
            )
        }
        return null
    }

    render() {
        if (this.props && this.props.members && Object.values(this.props.members).filter((member) => member > 0).length)
            return (
                <Home>
                    <Ptag style={{'marginBottom':'12px'}} letterHeight='2' letterSpacing='0.86px'>
                        {'ADD MEMBERS'}
                    </Ptag>
                    <ButtonsWrapper>
                        {
                            this.props &&  Object.keys(this.props.members).map((member, key)=>(
                                this.renderButton(member, key)
                            ))
                        }
                    </ButtonsWrapper>
                </Home>
            )
        return <div></div>
    }
}

const Home = styled.div`
    display:flex;
    width:100%;
    margin: 12px 0px;
    flex-direction: column;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    width:104%;
    flex-wrap: wrap;
`;

const Button = styled.button`
    width: 100px;
    height: 36px;
    border-radius: 8px;
    border: solid 1px #436ab2;
    background-color: white;
    margin-right: 12px;
    margin-bottom: 24px;
`;

