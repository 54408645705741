import React, { Fragment, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Ptag } from '../../components/fonts'
import http from '../../services/httpService'
import popupcrossbutton from '../../assets/images/popupcrossbutton.png'
import { formatAmount } from '../../utils/utils'
import { LargeButton } from '../../components'
import Loader from './overLayLoaderBusiness'
import { LoaderDots } from '../../components'
const DeletePopup = (props) => {
    console.log({ props })
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])

    // useEffect(() => {
    //   const asyncFetchDailyData = async () => {
    //       try{
    //           const { data } = await http.get(`/business/employee_detail/`, {
    //               params: {
    //                 userplan_id: localStorage.getItem('userplan_id'),
    //               }
    //             })
    //             setData(data.overallsummary)
    //             setLoading(false)
    //         }
    //         catch(e){
    //             alert(e.response.data)
    //         }  
    //     }
    //     asyncFetchDailyData();
    // }, []);

    const closeDeletePopup = (e) => {
        e.stopPropagation();
        props.parentCallback(false);
    }

    const closeAfterDelete = (e) => {
         e.stopPropagation();
        onClickDelete()
        props.closeAfterDel(false);
    }

    const onClickDelete = async () => {
        setLoading(false)
        let a = []
        let listCheckEmp = []
        for (let i = 0; i < props.data.length; i++) {
            if (props.data[i].isChecked == true) {
                a.push(String(props.data[i].employee_id))
                listCheckEmp.push(i)
            }
        }
        try{
            const { data } = await http.delete(`business/employees1/`, {
            data: {
                id: a,
                userplan_id: localStorage.getItem('userplan_id')
            }

            })
            setLoading(true)
        }catch(e){
            alert(e.response['data'])
            for(let i in listCheckEmp){
                props.data[listCheckEmp[i]].isChecked = false
            }
            setLoading(true)
        }
        

    }

    return (
        <>
        loading ?  
            <UploadExcelPopUpDiv onClick={() => this.onDeleteNoSelected()} >
                <Innerpopup onClick={(e) => e.stopPropagation()} >
                    <SubInnerDiv>
                        <FirstRow>
                            <Ptag fontSize='22px' fontWeight='bold'>
                                Are you sure you want to remove following employees?
                            </Ptag>
                            <CrossButton >
                                <img style={{ 'cursor': 'pointer' }} src={popupcrossbutton} onClick={closeDeletePopup} width="24px" height="24px" />
                            </CrossButton>
                        </FirstRow>
                        <SecondSubRow>
                        </SecondSubRow>
                        <EmployeeAmountDiv>
                            <AmountDiv style={{ paddingBottom: '10px' }}>
                                <Ptag fontSize='16px' fontWeight='bold'>Name of Employee</Ptag>
                                <Ptag fontSize='16px' fontWeight='bold'>Amount</Ptag>
                            </AmountDiv>
                            {
                                props.data.filter(item => item['isChecked']).map((item, index) => (
                                    <AmountDiv>
                                        <Ptag textTransform='capitalize' fontSize='14px' >
                                            {`${index + 1}. ${item['name']}`}
                                        </Ptag>
                                        <Ptag fontSize='14px' >₹{formatAmount(item.total_amount) || 0}</Ptag>
                                    </AmountDiv>
                                ))

                            }
                            <Hr />
                        </EmployeeAmountDiv>

                        <AddExcelbtn>
                            <LargeButton onClick={closeAfterDelete} label="Yes" style={{ padding: "8px 0px", margin: "8px 6px" }} />
                            <LargeButton onClick={closeDeletePopup} label="No" style={{ padding: "8px 0px", margin: "8px 6px" }} />
                        </AddExcelbtn>
                    </SubInnerDiv>
                </Innerpopup>
            </UploadExcelPopUpDiv>:<Loader/>
        </>
        //     <AddPopUpDiv >

        //     <Innerpopup >

        //         <SubInnerDiv>
        //         {loading ?<div style={{display:'flex',alignSelf:'center',marginTop:80}}><LoaderDots/></div>:
        //         <>
        //             <FirstRow>
        //                 <Ptag fontSize='24px' fontWeight='bold'>
        //                     Subscription break-up
        //                 </Ptag>
        //                 <CrossButton >
        //                     <img style={{'cursor': 'pointer'}} src={popupcrossbutton} onClick = {closePaymentPopup} width="24px" height="24px" />
        //                 </CrossButton>
        //             </FirstRow>
        //             </>}
        //         </SubInnerDiv>
        //     </Innerpopup>

        // </AddPopUpDiv>
    );
}
const AddPopUpDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index:999;
  background-color: rgba(0, 0, 0, 0.5);
`
const Innerpopup = styled.div`
  position: absolute;
  left: 25%;
  right: 25%;
  top: 5%;
  bottom: 5%;
  margin: auto;

`
const SubInnerDiv = styled.div`
width:100%;
display:flex;
flex-direction:column;
padding:50px 50px 190px 50px;
background: #ffffff;
border-radius: 10px;
`
const FirstRow = styled.div`
justify-content: space-between;
display: flex;
`
const CrossButton = styled.p`
 
`
const UploadExcelPopUpDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1200;
`

const SecondSubRow = styled.div`
    padding-top:10px;

`
const EmployeeAmountDiv = styled.div`
    background:#f3f4f7;
    width:100%;
    padding:30px 20px;
    margin-top: 20px;
    border-radius: 10px;
    height: 250px;
    overflow: auto;
`
const AmountDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px;

`
const Hr = styled.div`
    border: 1px solid #cdc9c9;
    margin:10px 0px;
`

const AddExcelbtn = styled.div`
padding-top:20px;
display: flex;
justify-content: space-between;
`




export default DeletePopup
