import React,{ Fragment ,useState ,useEffect} from 'react'
import styled from 'styled-components'
import { Ptag } from '../../components/fonts'
import http from '../../services/httpService'
import popupcrossbutton from '../../assets/images/popupcrossbutton.png'
import { formatAmount } from '../../utils/utils'
import Loader from './overLayLoaderBusiness'
import { LoaderDots } from '../../components'
const PaymentPopup = (props) => {

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([])

    useEffect(() => {
      const asyncFetchDailyData = async () => {
          try{
              const { data } = await http.get(`/business/employee_detail/`, {
                  params: {
                    userplan_id: localStorage.getItem('userplan_id'),
                  }
                })
                setData(data.overallsummary)
                setLoading(false)
            }
            catch(e){
                alert(e.response.data)
            }  
        }
        asyncFetchDailyData();
    }, []);

   const closePaymentPopup = (e) =>{
    e.stopPropagation();
    props.parentCallback(false);
   }


   const renderSummaryRow = (label, amt, color = null, margin = '0 0 24px 0', fontSize,fontWeight) => {
    let rupee = color === "#f27576" ? "-₹" : "₹";
    return (
      <FlexRow margin={margin}>
        <Ptag fontSize={fontSize ? `${fontSize}px` : '14px'} letterSpacing='1px' fontWeight={fontWeight} color={color} >{label}</Ptag>
        <Ptag fontSize={fontSize ? `${fontSize}px` : '14px'} letterSpacing='0.5px' fontWeight={fontWeight} color={color}>
          {rupee + formatAmount(amt || 0)}
        </Ptag>
      </FlexRow>
    );
  };

  const renderEmpRow = (label, amt, color = null, margin = '0 0 24px 0', fontSize,fontWeight) => {
    return (
      <FlexRow margin={margin}>
        <Ptag fontSize={fontSize ? `${fontSize}px` : '14px'} letterSpacing='1px' fontWeight={fontWeight} color={color} >{label}</Ptag>
        <Ptag fontSize={fontSize ? `${fontSize}px` : '14px'} letterSpacing='0.5px' fontWeight={fontWeight} color={color}>
          {formatAmount(amt || 0)}
        </Ptag>
      </FlexRow>
    );
  };


  const renderAnnualDiscountRow = (label, amt, color , margin = '24px 0 8px 0') => {
    let rupee = color === "#f27576" ? "-₹" : "-₹";
    return (
      <FlexRow margin={margin}>
        <Ptag fontSize='20px' letterSpacing='0.2px' fontWeight="400" color={color}>{label}</Ptag>
        <Ptag fontSize='20px' letterSpacing='0.4px' fontWeight="400" color={"#f27576"}>
          {rupee + formatAmount(amt || 0)}
        </Ptag>
      </FlexRow>
    );
  };


  const total = () =>{
    var subscription_amount = ((data.premium || 0)+(data.platform_fee || 0)-(data.discount || 0)+(data.discounted_tax || 0) + (data['gpa_cost'] || 0))
    return subscription_amount
  }

  const wellnessPaymentSummary = () => {
    return (
      <Fragment>
        <Card>
 
          <InnerWrapper>
            {renderSummaryRow("Per Employee Yearly Fee", (data.payable_amount || 0) , null, '0 0 2px 0', 20,400)}
            {renderEmpRow("No. of Employeess", data.number_of_employees || 0, null, '0 0 12px 0', 20,400)}
            <Hr />
            {renderSummaryRow(
                  "Total",
                  data.total_wellness_fee || 0,
                  "#304ffe",'0',28,600
                )}
          </InnerWrapper>
        </Card>
      </Fragment>
    )
  }


  const paymentSummary = () => {
  
    return (
      <Fragment>
        <Card>
 
          <InnerWrapper>
            {renderSummaryRow("Total Subscription", ((data.premium || 0) + (data.platform_fee || 0) + (data.gpa_cost || 0)) , null, '0 0 2px 0', 20,400)}
            <SubComponentRow margin='0 0 3px 0px'>
              <Ptag fontSize='16px' letterSpacing='0.5px' fontWeight='300'>
                {'Insurance Premium'}
              </Ptag>
              <Ptag fontSize='16px' fontWeight='300' letterSpacing='0.3px'>
                {"₹" + formatAmount(data.premium || 0)}
              </Ptag>
            </SubComponentRow>
            {
             !!data.platform_fee &&
              <SubComponentRow margin='0 0 3px 0px'>
                <Ptag fontSize='16px' letterSpacing='0.5px' fontWeight='300'>
                  {"Health Benefits"}
                </Ptag>
                <Ptag fontSize='16px' fontWeight='300' letterSpacing='0.3px'>
                  {"₹" + formatAmount(data.platform_fee || 0)}
                </Ptag>
              </SubComponentRow>
            }
            {
             !!data.gpa_cost &&
              <SubComponentRow margin='0 0 3px 0px'>
                <Ptag fontSize='16px' letterSpacing='0.5px' fontWeight='300'>
                  {"GPA Cost"}
                </Ptag>
                <Ptag fontSize='16px' fontWeight='300' letterSpacing='0.3px'>
                  {"₹" + formatAmount(data.gpa_cost || 0)}
                </Ptag>
              </SubComponentRow>
            }
            {!!data.discount && renderAnnualDiscountRow('Monthly Discount', data.discount, null,'24px 0 8px 0')}
            {renderSummaryRow("GST (@18%)", data.discounted_tax ? data.discounted_tax : data.discount , null, '0 0 12px 0', 20,400)}
            <Hr />
            {renderSummaryRow(
                  "Total",
                  total(),
                  "#304ffe",'0',28,600
                )}
          </InnerWrapper>
        </Card>
      </Fragment>
    );
  };


    return (
        <AddPopUpDiv >
            
        <Innerpopup >
            { !(localStorage.getItem('flow') == 'GMC')?
              <SubInnerDiv>
              {
                loading ?<div style={{display:'flex',alignSelf:'center',marginTop:80}}><LoaderDots/></div>:
                <>
                    <FirstRow>
                        <Ptag fontSize='24px' fontWeight='bold'>
                            Subscription break-up
                        </Ptag>
                        <CrossButton >
                            <img style={{'cursor': 'pointer'}} src={popupcrossbutton} onClick = {closePaymentPopup} width="24px" height="24px" />
                        </CrossButton>
                    </FirstRow>
                    {paymentSummary()}
                  </>
                  }
              </SubInnerDiv>:
              <SubInnerDiv>
              {
                loading ?<div style={{display:'flex',alignSelf:'center',marginTop:80}}><LoaderDots/></div>:
                <>
                    <FirstRow>
                        <Ptag fontSize='24px' fontWeight='bold'>
                          Wellness Fee Break-up
                        </Ptag>
                        <CrossButton >
                            <img style={{'cursor': 'pointer'}} src={popupcrossbutton} onClick = {closePaymentPopup} width="24px" height="24px" />
                        </CrossButton>
                    </FirstRow>
                    {wellnessPaymentSummary()}
                  </>
                  }
              </SubInnerDiv>
            }
        </Innerpopup>
            
    </AddPopUpDiv>
    );
}
const AddPopUpDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index:999;
  background-color: rgba(0, 0, 0, 0.5);
`
const Innerpopup = styled.div`
  position: absolute;
  left: 25%;
  right: 25%;
  top: 5%;
  bottom: 5%;
  margin: auto;

`
const SubInnerDiv = styled.div`
width:100%;
display:flex;
flex-direction:column;
padding:50px 50px 190px 50px;
background: #ffffff;
border-radius: 10px;
`
const FirstRow = styled.div`
justify-content: space-between;
display: flex;
`
const CrossButton = styled.p`
 
`

const Card = styled.div`
  display:flex;
  flex-direction:column;
  background-color: #ffffff;
  /* box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.15); */
  /* margin-top:12px; */
  & + p {
    margin: 3.6rem 0 2.4rem 0;
  }
`;
const FlexRow = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
margin: ${props => props.margin};
`;

const InnerWrapper = styled.div`
  padding:30px 18px 32px 18px;
`;

const Hr = styled.div`
  background: #9b9b9b;
  height: 1px;

  & + div {
    margin-top: 1.6rem;
  }
`;

const SubComponentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${props => props.margin ? props.margin : '0px'}
`

export default PaymentPopup
