import React from 'react'
import styled from 'styled-components'
import { LargeButton } from './'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 2.8rem 2.9rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  position: fixed;
  top: 0;
  left: 0;

  button {
    width: 14rem;
  }

  @media (max-width: 1440px) {
    padding: 2.8rem 2.8rem;
    button {
      font-size: 2rem;
      width: 14rem;
      height: 4.8rem;
      padding: 0;
    }
  }
  @media (max-width: 768px) {
    padding: 1.2rem 2.4rem;
    button {
      font-size: 1.2rem;
      width: 8.4rem;
      height: 3.6rem;
      padding: 0;
    }
  }
`

export default function LoginHeader() {
  return (
    <Wrapper>
      <LargeButton label="LOGIN" padding="1.1rem 3.4rem" borderRadius="1rem" bgColor="#f37576" />
      <LargeButton label="LOGIN" padding="1.1rem 3.4rem" borderRadius="1rem" bgColor="#f37576" />
    </Wrapper>
  )
}
