import React, { Fragment, Component, useEffect, useState } from 'react'
import { render } from 'react-snapshot'
import styled from 'styled-components'
import { Ptag } from '../../components/fonts'
import LargeButton from '../../components/LargeButton'
import formatAmount from '../../components/memberApp/common/utils'
import http from '../../services/httpService'
import Loader from './overLayLoaderBusiness'
import {withRouter} from 'react-router'
import { connect } from 'react-redux'
import { Icons } from '../../components'
class PaymentBreakup extends Component {
  constructor(props) {
    super(props)
    const planDetails = JSON.parse(localStorage.getItem('user_plan_details'))
    const {is_payment_done,} = planDetails
    this.state = {
      loading: true,
      amount:'',
      autoPay: false,
      selfPay: false,
      color: '',platformFee:'',premium:'',tax:'',discounted_tax:'',
      checkedDisclaimer: !is_payment_done,
      is_payment_done,
    }
  }

  async componentDidMount() {
    try {
      const { data } = await http.get(`/business/employee_detail/`, {
        params: {
          userplan_id: localStorage.getItem('userplan_id'),
        }
      })
      var subscription_amount = (data['overallsummary']['premium'] || 0)+(data['overallsummary']['platform_fee'] || 0)-(data['overallsummary']['discount'] || 0)+(data['overallsummary']['discounted_tax'] || 0) + ((data['overallsummary']['gpa_cost'] || 0))
        this.setState({
        amount: subscription_amount ,
        tax:data['overallsummary']['tax'] || 0,
        discounted_tax:data['overallsummary']['discounted_tax'] || 0,
        platformFee:data['overallsummary']['platform_fee'] || 0,
        premium:data['overallsummary']['premium'] || 0,
        loading: false,
        discount: data['overallsummary']['discount'] || 0,
        gpaCost: data['overallsummary']['gpa_cost'] || 0
      })
    }
    catch (error) {
      alert(error.response.data)
    }
  }

  renderSummaryRow = (label, amt, color = null, margin = '0 0 24px 0', fontSize) => {
    let rupee = color === "#f27576" ? "-₹" : "₹";
    return (
      <FlexRow margin={margin}>
        <Ptag fontSize={fontSize ? `${fontSize}px` : '14px'} letterSpacing='0.2px' fontWeight="600" color={color} >{label}</Ptag>
        <Ptag fontSize={fontSize ? `${fontSize}px` : '14px'} letterSpacing='0.4px' fontWeight="600" color={color}>
          {rupee + formatAmount(amt || 0)}
        </Ptag>
      </FlexRow>
    );
  };

  renderAnnualDiscountRow = (label, amt, color , margin = '24px 0 8px 0') => {
    let rupee = color === "#f27576" ? "-₹" : "-₹";
    return (
      <FlexRow margin={margin}>
        <Ptag fontSize='20px' letterSpacing='0.2px' fontWeight="600" color={color}>{label}</Ptag>
        <Ptag fontSize='20px' letterSpacing='0.4px' fontWeight="600" color={"#f27576"}>
          {rupee + formatAmount(amt || 0)}
        </Ptag>
      </FlexRow>
    );
  };
  // getTotal = () => {
  //   // console.log(this.state.discount,this.state.platformFee,this.state.premium,"snjdbj")
  //   const totalPrice = ((Number(this.state.premium) || 0) + (Number(this.state.platformFee) || 0) - (this.state.discount ? Number(this.state.discount) : 0)) + (this.state.discounted_tax ? Number(this.state.discounted_tax) : Number(this.state.tax))
  //   localStorage.setItem('totalAmountShown',totalPrice)
  //   return   totalPrice
  // }
 
  paymentSummary = () => {
  
    return (
      <Fragment>
        <Card>
          <Hr />
 
          <InnerWrapper>
            {this.renderSummaryRow("Total Subscription", ((this.state.premium || 0) + (this.state.platformFee || 0) + (this.state.gpaCost || 0)) , null, '0 0 2px 0', 20)}
            <SubComponentRow margin='0 0 3px 0px'>
              <Ptag fontSize='16px' letterSpacing='0.5px' fontWeight='300'>
                {'Insurance Premium'}
              </Ptag>
              <Ptag fontSize='16px' fontWeight='300' letterSpacing='0.3px'>
                {"₹" + formatAmount(this.state.premium || 0)}
              </Ptag>
            </SubComponentRow>
            {
              //   (type != PolicyType.ACCIDENTAL && !!fixedBenefitFee) &&
              !!this.state.platformFee &&
              <SubComponentRow margin='0 0 3px 0px'>
                <Ptag fontSize='16px' letterSpacing='0.5px' fontWeight='300'>
                  {"Health Benefits"}
                </Ptag>
                <Ptag fontSize='16px' fontWeight='300' letterSpacing='0.3px'>
                  {"₹" + formatAmount(this.state.platformFee || 0)}
                </Ptag>
              </SubComponentRow>
            }
            {
              !!this.state.gpaCost &&
              <SubComponentRow margin='0 0 3px 0px'>
                <Ptag fontSize='16px' letterSpacing='0.5px' fontWeight='300'>
                  {"GPA Cost"}
                </Ptag>
                <Ptag fontSize='16px' fontWeight='300' letterSpacing='0.3px'>
                  {"₹" + formatAmount(this.state.gpaCost || 0)}
                </Ptag>
              </SubComponentRow>
            }
            {!!this.state.discount && this.renderAnnualDiscountRow('Monthly Discount', this.state.discount || 0, null,'24px 0 8px 0')}
            {this.renderSummaryRow("GST (@18%)", this.state.discounted_tax ? this.state.discounted_tax : this.state.tax, null, '0 0 12px 0', 20)}
            <Hr />
            {this.renderSummaryRow(
                  "Total",
                  this.state.amount,
                  "#304ffe",'0',24
                )}
          </InnerWrapper>
        </Card>
      </Fragment>
    );
  };
  
  pay = async () => {
    const data = JSON.parse(localStorage.getItem('emp_data'))
    const amount_insurer = Math.round((data && data.overallsummary && data.overallsummary.premium)*1.18) || 0
    console.log(amount_insurer)
    var userPlanDetails = JSON.parse(localStorage.getItem('user_plan_details'))
      const response = await http.post(`/payment/subscription_payment/?subscription_id=${userPlanDetails['subscription']['vital_subscription_id']}`, {
          payment: {method:0,plan:0},
          term_type:"monthly",
          discount: this.state.discount || 0, discounted_tax: this.state.discounted_tax || 0, payable_amount: this.state.amount || 0,
          amount_insurer: amount_insurer
      });
      var _this = this
      const options = {
        async handler(response) {
          // window.location.reload(false)
          _this.props.history.push('/business/paymentsuccess')
  
        },
        ...response.data,
      };
  
      const rzp = new window.Razorpay(options);
      rzp.open();
  
  }

  render() {
    // console.log(this.props.selfpayButton,"props")

    const {checkedDisclaimer, is_payment_done} = this.state
    return (
      <HomeWrapper>
        
        {
          this.state.loading?<Loader></Loader>:
          <>
          <Ptag style={{ padding: '0px 24px 36px' }} fontSize='24px' fontWeight='600' color='#666666'>
          {'Payment Summary'}
        </Ptag>
        {this.paymentSummary()}
        {this.props.selfpayButton &&
          <DisclaimerWithButton>
            {
              (is_payment_done ) &&
              <DisclaimerWithCheckbox>
                <IconWrapper onClick={() => this.setState({checkedDisclaimer: !this.state.checkedDisclaimer})}>
                  <Icons height='28px' width='28px' icon={checkedDisclaimer ? "checkedBox" : "unCheckedBox"} />

                </IconWrapper>
                <Ptag fontSize="16px" fontWeight='600' letterSpacing='0.5px' >
                  I agree to <Atag onClick={() => this.props.setDisclaimer(true)} > Terms and Conditions</Atag>
                </Ptag>
              </DisclaimerWithCheckbox>
            }
           
            <SubmitWrap position={'fixed'}>
              <LargeButton opacity={checkedDisclaimer ? 1 : 0.7} disabled={!checkedDisclaimer} label="PAY NOW"  onClick={this.pay} />
            </SubmitWrap>
          </DisclaimerWithButton>
          
        }
          </>
        }
      </HomeWrapper> 
    )
  }
}

const mapStateToProps = (state) =>{
  return{
    selfpayButton:state.selfpay
  }
}
export default connect(mapStateToProps,null)(withRouter(PaymentBreakup))

const HomeWrapper = styled.div`
   padding: 70px 34px 151px 34px;
  display: flex;
  background-color: #ffffff;
  width:34%;
  flex-direction: column;
  margin-left:1% ;
  box-shadow: rgb(0 0 0 / 40%) 0px 1px 4px 0px;

`

const Card = styled.div`
  display:flex;
  flex-direction:column;
  background-color: #ffffff;
  /* box-shadow: 0 1px 4px 0px rgba(0, 0, 0, 0.15); */
  /* margin-top:12px; */
  & + p {
    margin: 3.6rem 0 2.4rem 0;
  }
`;



const InnerWrapper = styled.div`
  padding:30px 18px 36px 18px;
`;

const Hr = styled.div`
  background: #9b9b9b;
  height: 1px;

  & + div {
    margin-top: 1.6rem;
  }
`;


const FlexRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: ${props => props.margin};
`;

const SubComponentRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${props => props.margin ? props.margin : '0px'};
`


const DisclaimerWithButton = styled.div`
  display: flex;
  flex-direction: column;

`

const DisclaimerWithCheckbox = styled.div`
  display: flex;
  flex-direction: row;
  margin: 50px 0 50px;
  align-items: center;
  & > p{
    margin-left: 12px;
  }
`

const SubmitWrap = styled.div`
  width: 100%;
  background: #fff;
  padding: ${props => (props.position === 'fixed' ? '0' : '0')};
  bottom: ${props => (props.position === 'fixed' ? '0' : 'unset')};
`

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

const Atag = styled.a`
  text-decoration: underline;
  cursor: pointer;
`