import React, { Fragment } from 'react'
import styled from 'styled-components'
import LargeButton14 from '../components/LargeButton14'
import {Icons} from './'

const Home = styled.div`
  width: 95.9%;
  background-color: #ffffff;
  border-radius: 15px;
  padding-bottom: 4.4rem;
  margin: 0 0.8rem;
  position:fixed;
  left: 0;
  bottom: 0;
  z-index: 0
`

const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1.8rem 1.8rem 0;
`
const QsnTag = styled.div`
  margin: 2.4rem 2.4rem 0;
  & > :first-child {
    margin-bottom: 1.2rem;
  }
`

const Font12 = styled.h3`
  font-size: 1.2rem;
  font-weight: 500;
  text-transform: uppercase;
  font-style: normal;
  font-family: Avenir;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 0.6);
`
const Font20 = styled.h1`
  font-size: 2rem;
  font-weight: 500;
  // font-family: Roboto;
  font-family: inherit;
  font-style: normal;
  font-family: Avenir;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: 0.3px;
  color: #4a4a4a;
  height: 9.6rem;
`
const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10.8rem 0 0;
  & > :last-child {
    margin-left: 2.4rem;
    flex-grow: 1;
  }
`

export const Question = ({ id, active, title, listSize, onClick, onClose }) =>
  active ? (
    <Fragment>
      <Home active={active}>
        <IconWrapper onClick={e => onClose()}>
          <Icons fill={'rgba(0, 0, 0, 0.54)'} icon={'cross'} width={24} height={24} />
        </IconWrapper>
        <QsnTag>
          <Font12>
            Question {id} of {listSize}
          </Font12>
          <Font20>{title}</Font20>
          <BtnWrapper>
            <LargeButton14 label={'YES'} onClick={e => onClick(true)} />
            <LargeButton14 label={'NO'} onClick={e => onClick(false)} />
          </BtnWrapper>
        </QsnTag>
      </Home>
    </Fragment>
  ) : null
