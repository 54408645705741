import React, { Component } from "react";
import DashboardBox from "./dashboardBox";
import DashboardList from "./dashboardList";
import GmcDashboard from "./GmcDashboard";
import FlexDashboard from "./FlexDashboard";

const PlanOverView = () => {
  const isFlex = localStorage.getItem("isFlex");
  const ifGmcFlow = () => {
    const flow = localStorage.getItem("flow");
    const options = ["GMC", "GMC_M", "GPA", "GTL"];
    return options.includes(flow);
  };
  if (ifGmcFlow()) {
    return <GmcDashboard />;
  }
  if (isFlex) {
    return <FlexDashboard />;
  }
  return (
    <>
      <DashboardBox />
    </>
  );
};

export default PlanOverView;
