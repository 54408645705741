import React from "react";
import SplitDetailCard from "../../../components/GmcDashbaord/SplitDetailCard";
import { PRODUCTS_NAME } from "../../../constants";
import { SplitWrapper } from "../GmcDashboard/styles";
import { renderSplitCards } from "../GmcDashboard/renderSplitCards";

const ProductStatusCardList = ({ products }) => {
    
   return (
      <SplitWrapper>
          {
            Object.keys(products).map((prod, index)=> {
              return renderSplitCards(prod, products[prod], index)
            })
          }
      </SplitWrapper>)
};

export default ProductStatusCardList;
