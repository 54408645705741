import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import {Header} from '../../components/business'
import {Ptag} from '../../components/fonts'
import http from '../../services/httpService'
import TagManager from 'react-gtm-module'
import {gtmCode} from '../../utils/utils'
import {OverlayLoader} from '../../components'

function SelectPolicyType({...props}){

    const [plusPlantype, setPlusPlantype] = useState(-1)
    const [loading, setLoading] = useState(false)
    useEffect( () => {
        const tagManagerArgs = {
            gtmId: gtmCode(),
        }
        TagManager.initialize(tagManagerArgs)
    }, [])
    const onClickTile = async (planid) => {
        setPlusPlantype(planid)
        setLoading(true)
        setTimeout(async () => {
            const plan_id = localStorage.getItem('plan_id')
            await http.patch(`business/plan/${plan_id}/`,{
                policy: `${planid ? 'Renew' : 'New'}`
            })
            props.history.push(`/business/${planid ? 'renewal' : 'selectplantype'}`)}
        , 300)
    }

    return(
        <Root>
            <Header onClickBack={() => props.history.push('/business/employees')} progressPercentage={50}/>
            <Body>
                <Ptag style={{'marginBottom': '24px'}} fontFamily='Proxima Nova' fontSize='24px' letterHeight='1.33' letterSpacing='0.35px' color='#545454'>
                    {'choose policy type'}
                </Ptag>

                <Tiles id="business_selectpolicytype_new" selected={plusPlantype === 0} active onClick={() => onClickTile(0)} >
                    <Ptag color={plusPlantype === 0 && '#fff' || '#4a4a4a'} letterHeight='1.5' fontSize='16px' fontWeight='500' letterSpacing='1px'>
                        {'New Policy'}
                    </Ptag>
                </Tiles >
                <Tiles id="business_selectpolicytype_renewal" selected={plusPlantype === 1} onClick={() => onClickTile(1)} >
                    <Ptag color={plusPlantype === 1 && '#fff' || '#4a4a4a'} letterHeight='1.5' fontSize='16px' fontWeight='500' letterSpacing='1px'>
                        {'Renewal Policy'}
                    </Ptag>
                </Tiles>

            </Body>
            {loading && <OverlayLoader />}
        </Root>
    )
}

export default SelectPolicyType

const Root = styled.div`
    display: flex;
    flex-direction: column;
    flex:1;
`

const Body = styled.div`
    padding: 24px;
    flex:1;
`

const Tiles = styled.div`
    display: flex;
    border: 1px solid #436ab2;
    border-radius: 5px;
    padding: 16px;
    height: 66px;
    align-items: center;
    margin-bottom: 20px;
    background-color:${props => props.selected ? '#436ab2' : '#fff'}
`