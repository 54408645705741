import styled from 'styled-components'
import React, { Component } from 'react'
import store from '../../assets/images/store.png'
import message from '../../assets/svgs/Support.svg'
import benefits from '../../assets/images/benefits.png'
import user from '../../assets/svgs/Profile.svg'
import tele from '../../assets/images/tele.png'
import healthProfile from '../../assets/images/healthProfile.png'
import { withRouter } from 'react-router'
import {Ptag} from '../fonts'
import http from '../../services/httpService'
import {maxScreenWidth} from '../../config.json'
import {createLeadLSApi} from '../../services/leadSquaredService'
import Routes from '../../constants/routes'

class FooterDiv extends React.Component {

  support = {
    customer: {
      image: message,
      text: 'Support'
    },
    prospect: {
      image: tele,
      text: 'Call'
    }
  }

  constructor(props){
    super(props)

    this.state = {
      benefitsNotifications: 0,
      loading: true
    }
  }



  async componentDidMount(){
    let that = this
    try{
      let {data} = await http.get('/dashboard/get_bought_policies/')
      const policies = data['data'] && data['data'].slice(0,3)
      if(policies.length){
        let LSBody = {
          EmailAddress: localStorage.getItem('email'),
        }
        let LSProperties = ['mx_Policy_Number', 'mx_Policy_Number_2', 'mx_Policy_Number_3']
        for ( let i in policies){
          LSBody[LSProperties[i]] = String(Object.keys(policies[Number(i)])[0])
        } 
        createLeadLSApi(LSBody)
        this.setState({userType: ''})
      }
      this.setState({benefitsNotifications : data['data'] && data['data'].length,
        loading: false
      })
    } catch(ex){
      that.setState({
        loading: false
      })
    }
    
  }
  
  onHomeClick = () => {
    this.setState({ home: true, chat: false })
    this.props.history.push('/dashboard')
  }

  onBenefitClick = () => {
    this.props.history.push('/benefits')
    }

  onProfileClick = () => {
    this.props.history.push(Routes['PROFILE'])
  }

  onHealthClick = () => {
    this.props.history.push(Routes['HEALTH'])
  }

  chatClick = () => {
      // this.showVerloop() 
      this.props.history.push('/support')
  }


  showVerloop() {
    if(window.Verloop){
      window.Verloop(function() {
        this.openWidget()
      })
    } else {
      const initVerloop = function(w, d, s, u) {
        w.Verloop = function(c) { w.Verloop._.push(c) }; w.Verloop._ = []; w.Verloop.url = u;
        var h = d.getElementsByTagName(s)[0], j = d.createElement(s); j.async = true;
        j.src = 'https://getvital.verloop.io/livechat/script.min.js';
        h.parentNode.insertBefore(j, h);
        window.Verloop(function() {
          this.setUserParams({
            name: localStorage.getItem('first_name'),
            email: localStorage.getItem('email'),
            phone: localStorage.getItem('phone'),
            user_id: localStorage.getItem('healthfront_id')
          })
          const department = localStorage.getItem('department')
          if (department !== null || department !== undefined){
            this.setCustomField('department', department, { scope: 'user' })
          }
          this.openWidget()
          
        })
         
      };
    initVerloop(window, document, 'script', 'https://getvital.verloop.io/livechat')
    }
    
    
  }

  showPlansTab = () => {
    const policies = JSON.parse(localStorage.getItem('policies'))
    // for (let i in policies){
    //   const policyType = (Object.values(policies[i])[0] || '').toLowerCase()
    //   if( policyType.indexOf('gmc') > -1 || policyType.indexOf('niva') > -1 || policyType.indexOf('kotak') > -1 )
    //   return false
    // }
    return true
  }

  renderSupport = () => {
    const isFlex = localStorage.getItem("employee_plan_type") === "FLEX"
    let userType = this.state.benefitsNotifications > 0 ? 'customer' : 'prospect'
    const {support} = this

    return (
      <CallTab onClick={this.chatClick}>
        <TabImage opacity={'0.7'} height='20px' width='auto' src={support[userType]['image']} />
        <TabTitle >{support[userType]['text']}</TabTitle>
      </CallTab> 
    )

    // return (
    //   <CallTab  href="tel:01141193909">
    //     <TabImage opacity={'0.7'} height='20px' width='auto' src={support[userType]['image']} />
    //     <TabTitle >{support[userType]['text']}</TabTitle>
    //   </CallTab> 
    // )
  }

  render() {
    const path = this.props.location && this.props.location.pathname
    return (
      <Footer show={!this.state.loading} >

        <Tab opacity={path.indexOf('/dashboard') > -1 ? '1' : '0.7'} onClick={() => this.onHomeClick()}>
          <TabImage  height='20px'  width='auto' src={store} />
          <TabTitle> PLANS </TabTitle>
        </Tab>
        
        {
          this.renderSupport()      // rendering call drawer
        }
    

        <Tab opacity={path.indexOf('benefits') > -1 ? '1' : '0.7'} onClick={() => this.onBenefitClick()}>
          {
            !!this.state.benefitsNotifications &&
            <Notification >
              <Ptag fontSize='10px' fontWeight='600' letterSpacing='0.1px' color='#fff' >
                {this.state.benefitsNotifications}
              </Ptag>
            </Notification>

          }
          <TabImage height='20px' width='auto' src={benefits} />
          <TabTitle> BENEFITS</TabTitle>
        </Tab>
        {/* {
          this.showPlansTab() &&
          <Tab opacity={path.indexOf('/dashboard') > -1 ? '1' : '0.7'} onClick={() => this.onHomeClick()}>
            <TabImage  height='20px'  width='auto' src={store} />
            <TabTitle> PLANS </TabTitle>
          </Tab>
        } */}
        
        {/* {
          this.showPlansTab() && 
          this.renderSupport()      
        } */}
    
        
        {/* <Tab opacity={path.indexOf(Routes['HEALTH']) > -1 ? '1' : '0.7'} onClick={() => this.onHealthClick()}>
          <TabImage  height='20px' width='auto' src={healthProfile} />
          <TabTitle>HEALTH</TabTitle>
        </Tab> */}

        {
          !this.showPlansTab() && 
          
            this.renderSupport()
          
        }

        <Tab opacity={path.indexOf(Routes['PROFILE']) > -1 ? '1' : '0.7'}  onClick={() => this.onProfileClick()}>
          <TabImage height='20px' width='auto' src={user} />
          <TabTitle>PROFILE</TabTitle>
        </Tab>
      </Footer>
    )
  }
}

const Footer = styled.div`
  display: ${props => props.show ? 'flex' : 'none'} ;
  flex-direction: row;
  width: 100%;
  align-items: center;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.15);
  padding: 1rem 1rem 0.8rem 1rem;
  position: fixed;
  bottom: 0;
  max-width: ${maxScreenWidth};
  z-index: 999;
  background-color: #fff;
`
const Tab = styled.div`
  display: flex;
  position: relative;
	flex-direction: column;
	margin: 0 auto;
	align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: ${props => props.opacity};
`

const CallTab = styled.a`
  display: flex;
  position: relative;
	flex-direction: column;
	margin: 0 auto;
	align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: ${props => props.opacity};
  text-decoration: none;
`

const TabImage = styled.img`
  opacity: ${props => props.opacity ? props.opacity : '1'};
`

const TabTitle = styled.p`
  max-width: 100%;
  align-items: center;
  font-family: Avenir;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  margin-top: 2px;
  text-transform: uppercase;
  &:click {
    opacity: 1;
  }
`

const Notification = styled.div`
  display: flex;
  position: absolute;
  padding: 2px 8px;
  bottom: 65%;
  right: 2%;
  border-radius: 2px;
  background-color: #f27576;
  z-index: 12;
`

export default withRouter(FooterDiv)
