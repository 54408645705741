import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Ptag } from '../../../components/fonts'
import { OverlayLoader } from '../../../components'
import backarrow from '../../../assets/images/arrowBack.png'
import debitlogo from '../../../assets/images/red_down_arrow.png'
import creditlogo from '../../../assets/images/green_up_arrow.png'
import { formatAmountWithDecimal } from "../../../utils/utils";
import {
  LongLine,
  ImgHeader,
  BackButton,
  HomeWrapper,
  MessageWrapper,
  Text2,
  TopHeader,
  MsgTag,
  Table,
  EmployeeHeader,
  OuterHeader,
} from "./styles";

const ViewCdTable = () => {
  const location = useLocation()
  const history = useHistory()
  const [loading, setLoading] = useState(false);
  const [ledgerTable, setLedgerTable] = useState([]);
  const state = location.state.transactionData
  const userplan_id =
        localStorage.getItem("userplan_id") ||
        sessionStorage.getItem("userplan_id") ||
        ""; 
  const handleChange = () => {
    history.push('/business/view_cd_balance')
  };

  const getData = async () => {
    setLoading(true)
    setLedgerTable(state)
    setLoading(false)
  };
  useEffect(() => {
    getData();
  }, []);


  const getImg = (transaction_status) => {
    const  logo = (transaction_status === "Credit") ?  creditlogo : debitlogo
    return (
      <img
        style={{
          height: "10px",
          marginRight: "5px",
          textAlign: "top",
          paddingTop: "0",
        }}
        src={logo}
        height="20px"
        width="17px"
      />
    ) 
  };

  const getTransactionSign = (transaction_status) => {
    return transaction_status === "Credit" ? "+" : "-";
  };

  const renderLedgerTable = () => {
    return (
      <Table>
        <tbody>
          {ledgerTable &&
            ledgerTable.map((individualRow, index) => (
              <tr style={{ borderBottom: "#b01313", height: 48 }}>
                <td>
                  <ImgHeader>
                    {getImg(individualRow.transaction_type)}
                  </ImgHeader>
                </td>
                <td>
                  <OuterHeader>
                    <EmployeeHeader>
                      <Ptag
                        paddingLeft="10px"
                        fontSize="14px"
                        fontWeight="600"
                        minWidth="80%"
                      >
                        {individualRow.action_show}
                      </Ptag>
                      <Ptag paddingLeft="10px" fontSize="12px" fontWeight="400">
                        {individualRow.transaction_date_show}
                      </Ptag>
                    </EmployeeHeader>
                  </OuterHeader>
                </td>
                <td>
                  <TopHeader>
                    <Ptag
                      paddingLeft="10px"
                      fontWeight="600"
                      fontSize="14px"
                      maxLength="80%"
                    >
                      {getTransactionSign(individualRow.transaction_type)} ₹
                      {formatAmountWithDecimal(
                        individualRow.transacted_balance
                      )}
                    </Ptag>
                    <Ptag paddingLeft="10px" fontSize="12px" fontWeight="400">
                      Balance ₹
                      {formatAmountWithDecimal(individualRow.updated_balance)}
                    </Ptag>
                  </TopHeader>
                </td>
                {/* <LongLine></LongLine>          */}
              </tr>
            ))}
        </tbody>
      </Table>
    );
  };

  const renderNoHistoryMessage = () => {
    return (
      <MsgTag>
        <MessageWrapper>
          <Text2>
            Currently, we do not have any transaction history regarding your CD
            account.
          </Text2>
          <Text2>
            If you have made any recent transactions, please reach out to your
            Account Manager at Vital to get the same updated here.
          </Text2>
        </MessageWrapper>
      </MsgTag>
    );
  };

  const cdLedger = () => {
    return (
      <HomeWrapper>
        <BackButton onClick={handleChange}>
          <img
            style={{ marginRight: "10px" }}
            src={backarrow}
            height="20px"
            width="17px"
          />
          <Ptag fontSize="15px">Back</Ptag>
        </BackButton>
        <Ptag
          fontSize="25px"
          fontWeight="500"
          marginLeft="30px"
          marginTop="30px"
          marginButtom="30px"
        >
          Transaction History
        </Ptag>
        <LongLine></LongLine>
        {!(typeof(ledgerTable) == 'string') ? renderLedgerTable() : renderNoHistoryMessage()}
      </HomeWrapper>
    );
  };

  return (
    <>
      {loading && <OverlayLoader />}
      {cdLedger()}
    </>
  );
};

export default ViewCdTable;
