import styled from 'styled-components'
import { withRouter } from 'react-router'
import React from 'react'
import vitalcash from '../../../assets/svgs/hospitals.svg'
import lifeline from '../../../assets/svgs/lifeline.svg'
import tablets from '../../../assets/svgs/tablets.svg'
import BankComponent from './BankComponent'
import ScrollDivPayment from '../common/ScrollDivPayment.js'

class NBPaymentScrollDiv extends React.Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props)
  }

  render() {
    // this.props.credit.suggested_credit.map((credit, idx) => {
    //   credit_suggestion_wrapper_div.push(
    //     <SuggestedCreditWrapperDiv
    //       key={idx}
    //       suggested_credit={credit}
    //       idx={idx}
    //       onActivate={value => this.props.onActivate(value)}
    //     />
    //   )
    // })
    let div1 = []
    this.props.bank_names.map((data, idx) => {
      div1.push(
        <BankComponent
          title={data.title}
          key={idx}
          idx={idx}
          isSelected={this.props.selected_idx === idx}
          onSelectBank={idx => this.props.onSelectBank(idx)}
          image_url={data.image}
        />
      )
    })

    return <ScrollDivPayment div_list={div1} />
  }
}

export default withRouter(NBPaymentScrollDiv)

const WrapperDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 16px;
`

const Card = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: scroll;
  padding-left: 16px;
  padding-right: 15px;

  '&::-webkit-scrollbar': {
    display: 'none';
  }
  '&::-webkit-scrollbar-thumb': {
    display: 'none';
  }
  ::-webkit-scrollbar {
    width: 0px;
    /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: none;
  }
`

const Outer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  min-width: 100%;
  margin-right: 15px;
  padding-top: 16px;
  padding-bottom: 16px;
  float: left;
`
const AccessTitle = styled.p`
  margin-top: 36px;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-left: 24px;
  color: #4a4a4a;
`

const InfoTitle = styled.p`
  font-family: Avenir;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: 0.5px;
  margin-top: 12 px;
  margin-bottom: 24px;
  maring-left: 24px;
  color: #4a4a4a;
`

const Title = styled.p`
  padding-top: 4px;
  margin-top: 23px;
  margin-left: 24px;
  margin-right: 16px;
  font-family: Avenir;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #4a4a4a;
`
