const faqData = {
  question: [
    "Is Vital a health insurance company?",
    "Why can’t I see these plans on the insurance company’s website?",
    "Can I claim upto the full cover amount  within 3 months of the policy, even though I have not paid the full year's premium ? ",
    "Do I have to undergo a medical test?",
    "Can I port my existing policy into Vital?",
    "How do I renew my policy? Can I increase the coverage amount? ",
    "Can I change the deductible?",
    "Is the cover amount offered per member or is applicable for everyone in the plan?",
    "Who should I contact while filing a claim, Vital or the insurance company?",
    "How will I get my policy documents?",
    "When can I add a new born child to the policy?",
    "What is the waiting period?",
    "Are advanced and robotic treatments covered under the policy?"
    
    
    
  ],
  answer: [
    "No, Vital is a platform offering customized health plans for its members. It covers all your healthcare requirements that include insurance for hospitalization, doctor consults, diagnostics, pharmacy expenses, nutritional needs, mental health requirements and more. We do this by partnering with leading insurance, health and wellness providers to create a unified product offered in monthly subscription.",
    "These plans are developed exclusively for Vital members and cannot be purchased from anywhere else.",
    "Yes, you can claim up to the full sum insured anytime in the policy year as long as your monthly subscription is active.",
    "No, the plans are enabled with digital questionnaires or use tele-underwriting to determine your health status. Please answer the questions honestly during the process to avoid rejection of claims later.",
    "Unfortunately, we do not offer porting from an existing policy to our platform as of yet.\n\nHowever, at the time of renewal, you can port out of Vital to Care Health Insurance (you can keep just the health insurance policy), subject to the insurer’s underwriting rules.",
    "The policy can be renewed after 12 months right from the app. You can increase the coverage amount at the time of renewal but the waiting periods will be applied fresh to the difference in amount.",
    "Yes, you can change the deductible while renewing your plan. Please remember that if you select a lower deductible, your policy will be considered as a fresh one and your waiting period will start again. ",
    "The cover amount is the total sum insured for all the members in the plan. Anyone insured in the plan can use it.",
    "You can just contact your Care Manager and they will take care of it.",
    "You will get a digital card and a policy number on your Vital account as soon as you make the payment. While filing a claim, just show this policy number at the hospital TPA desk.",
    "You can add a new born child 90 days post birth. Please contact your Care Manager to get your policy updated.",
    "There is a standard waiting period of 30 days from the date of your policy being activated. You can file a claim anytime after. The waiting period, however, is not applicable in the case of accidents. If you have any pre-existing diseases, then waiting period for covering any expenses related to them is 48 months. Planned surgeries, too, have a 24-month waiting period (please check the policy document for more details).",
    "Yes, robotic treatments will be covered if the hospital/doctor deems it to be an absolute necessity for the treatment."
    
    
  ],
  deductiblequestion: [
    "Can I pay my deductible from another insurance policy?	",
    "Will my deductible expenses get carried over to the next year?",
    "Do I have to pay deductible on every claim in a policy year?",
    "Is the deductible amount for each member of the policy or does it cover everyone?",
    "Is it in percentage or fixed amount?",
  ],
  deductibleanswer: [
    "Yes, you can. Your deductible amount can be paid either by you directly towards your medical bill or claimed from an existing health insurance policy.",
    "No, just like your cover amount gets renewed every year, your deductible gets applied every year as well.",
    "No, you only have to cross your deductible once in a year. Once you have covered the deductible, your insurance will take care of all the hospital bills.",
    "The deductible covers all your policy members. It doesn’t matter for whom you have used the deductible amount. Once it is covered, you can claim your policy benefits.",
    "The deductible is a fixed amount. You can choose from either a Rs 35,000 or a Rs 1,00,000 deductible.",
  ],
};

export default faqData;

