import React, { useMemo } from "react";
import { theme } from "../../theme";
const SpiralCompletion = ({ relevant_employees = 0, total_employees = 0 }) => {
    const isEmpty = useMemo(() => {
        if (relevant_employees === 0 && total_employees === 0) return true;
        return false
    }, [relevant_employees, total_employees])

    let percentage = (relevant_employees / total_employees) * 100;

    if(isEmpty) {
        percentage = 0
    }
    const radius = 30;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (percentage / 100) * circumference;

    return (
        <svg viewBox="0 0 100 100">
            <circle cx={50} cy={50} r={radius} stroke="#D9D9D9" fill="transparent" strokeWidth={4} strokeDasharray={circumference} strokeDashoffset={0} />
            <circle cx={50} cy={50} r={radius} stroke="#0054E3" fill="transparent" strokeWidth={4} strokeDasharray={circumference} strokeDashoffset={offset} transform="rotate(-90 50 50)" />
            <text style={{fill: '#4a4a4a'}} x="50%" y="45%" textAnchor="middle" dominantBaseline="central" fontFamily="Open Sans">
                <tspan fontSize={8} fontWeight={700}>{isEmpty ? "0" : `${relevant_employees}/${total_employees}`}</tspan>
                <tspan fontSize={3} x="50%" dy="4em">Employee Data Received</tspan>
            </text>
        </svg>
    );
};

export default SpiralCompletion;



