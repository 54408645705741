import React from 'react'
import styled from 'styled-components'
import pointsS from '../assets/images/pointsS.png'
import concentricCircle from '../assets/images/concentricCircle2.png'


const Wrapper = styled.div`
  width: 100%;
  padding-top: 8rem;
  background-color: #eeeee0;
  position: relative;
  // height: 100vh;

  & h1 {
    // width: 32.7rem;
    padding-left: 2.8rem;
    padding-top: 2.7rem;
  }

  @media (min-width: 1024px) {
  }

  @media (min-width: 1680px) {
  }
`


const H2 = styled.div`
  font-family: Avenir;
  font-size: 16px;
  // width: 32.7rem;
  padding-left: 2.8rem;
  padding-top: 2.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.01px;
  padding-right: 24px;
  // text-align: center;
  color: #4a4a4a;
  // z-index: 100;

  @media (min-width: 1024px) {
    // font-size: 34px;
    margin-left: 48px;
    font-family: Avenir;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.59;
    letter-spacing: normal;
    color: #4a4a4a;
  }
`

const H1 = styled.h1`
  font-family: Proxima Nova;
  font-size: 34px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  // line-height: 1.35;
  // letter-spacing: 0.02px;
  // text-align: center;
  color: #4a4a4a;


  @media (min-width: 1024px) {
    // font-size: 6rem;
    margin-left: 48px;
    margin-top: 140px;
    font-family: Proxima Nova;
    font-size: 44px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 56px;
    letter-spacing: 0.44px;
    color: #4a4a4a;
    margin-right: -80px;
  }

  @media (min-width: 1680px) {
    margin-left: 48px;
    margin-top: 140px;
    font-family: Proxima Nova;
    font-size: 62px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 56px;
    letter-spacing: 0.44px;
    color: #4a4a4a;
    margin-right: -80px;

  }
`
const Header = styled.div`
    width: 100%;
    positon: relative;

    @media (min-width: 1024px) {
        display: flex;
        justify-content: flex-end;
        font-size: 34px;
        margin-top: 34px;
      }
    
      @media (min-width: 1680px) {
        display: flex;
        align-items: center;
        font-size: 48px;
      }
`
const IWrapper = styled.div`
display: flex;
padding-top: 48px;
width: 100%;
align-items: center;
justify-content: center;

@media (min-width: 1024px) {
  display: flex;
  width: 723px;
  // height: 753px;
}
`
const Image = styled.img`
      display: flex;
      width: 375px;
      z-index: 10;

      @media (min-width: 1024px) {
        display: flex;
        width: 723px;
        height: 753px;
      }
`
const Logo1 = styled.div`
  width: 155px;
  height: 352px;
  opacity: 0.85;
  background: url(${concentricCircle});
  background-size: cover;
  position: absolute;
  top: 24%;
  left: 0;
`
const SubHeader = styled.div`
      display: flex;
      flex-direction: column;
      
      @media (min-width: 1024px) {
          padding-left: 36px;
      }

      @media (min-width: 1680px) {
        margin-top: -240px;
      }
`


export default function Page21() {
  return (
    <Wrapper>
      <Header>
        <SubHeader>
      <H1> Save up to 50% on routine health and wellness expenses </H1>
      <H2>
        Redeem exclusive offers on doctor consultation, gym membership and wellness packages with  <br />
        <b>Vital Points - your new health currency.</b> 
      </H2>
      </SubHeader>
      <IWrapper>
        {/* <Image src={pointsS} /> */}
        <Image alt="vital points" src = "http://images.getvital.in/homelanding/pointsS.png" />
      </IWrapper>
      <Logo1 />
      </Header>
    </Wrapper>
  )
}
