import React, { Component } from "react";
import styled from "styled-components";
import { Ptag } from "../../components/fonts";
import { OverlayLoader } from "../../components";
import copyToClipboard from "../../assets/svgs/copyToClipboard.svg";
import Logo from "../../assets/images/referral_logo.png";
import copy from "copy-to-clipboard";
import { Toast } from "../../components";
import { WhatsappShareButton } from "react-share";
import whatsappIcon from "../../assets/images/whatsappIcon.png";

export default class ShareOnWhatsapp extends Component {
  constructor(props) {
    super(props);
    const code = sessionStorage.getItem("referralCode");
    if (!code) {
      this.props.history.push("/referral");
    }
    this.state = {
      loading: false,
      agreement: false,
      showToast: false,
      code: code,
    };
  }

  onClickCheckbox = () => {
    this.setState({ agreement: !this.state.agreement });
  };

  handleCopyCode = (code) => {
    copy(code);

    this.setState({ showToast: true, isClick: true });
    setTimeout(() => this.setState({ isClick: false }), 150);
    setTimeout(() => this.setState({ showToast: false }), 1200);
  };

  renderHeader = () => {
    return (
      <Header>
        <div className="text-one">
          <i className="logo">
            <img src={Logo} alt="logo" />
          </i>
          <i className="line" />
          BUSINESS
        </div>
      </Header>
    );
  };

  render() {
    let url =
      window.location.hostname === "localhost"
        ? "https://stg.getvital.in/business/"
        : `https://${window.location.hostname}/business/`;
    url = new URL(url);
    url.searchParams.append("referralCode", this.state.code);
    return (
      <Parent>
        {this.renderHeader()}
        <Root>
          <Body>
            <Ptag
              textAlign="center"
              fontSize="28px"
              style={{ margin: "140px 0 22px 0" }}
              fontFamily="Proxima Nova"
              textTransform="uppercase"
              fontWeight="600"
              letterSpacing="0.9px"
              color="#f77963"
            >
              {"Congratulations!"}
            </Ptag>
            <Ptag
              textAlign="center"
              fontSize="20px"
              style={{ margin: "0 0 18px 0" }}
              fontWeight="600"
              letterSpacing="0.5px"
              letterHeight="1.5"
              letterSpacing="0.9px"
            >
              {"You're now a Vital Ambassador!"}
            </Ptag>
            <Ptag
              style={{ width: "80%" }}
              fontSize="20px"
              letterSpacing="0.5px"
              textAlign="center"
              letterHeight="1.75"
              letterSpacing="0.9px"
            >
              {"Please check your mail for the Referral Code and Starter Kit."}
            </Ptag>

            <CouponContainer>
              <Ptag fontSize="20px" letterSpacing="2px">
                {this.state.code}
              </Ptag>
              <img
                src={copyToClipboard}
                height="24px"
                width="24px"
                alt="copyToClipboard.png"
                onClick={() => this.handleCopyCode(this.state.code)}
              />
            </CouponContainer>
            <Toast message="Copied!" showToast={this.state.showToast} />
            <WhatsappShareButton
              title="Check this Comprehensive Health Cover for your employees with convenient monthly and annual payment options. 

              Sign Up with this link for added benefits: "
              url={String(url)}
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "flex-end",
                flex: "1",
              }}
            >
              <BtnDiv>
                <Image src={whatsappIcon} />
                SHARE ON WHATSAPP
              </BtnDiv>
            </WhatsappShareButton>
            {this.state.loading && <OverlayLoader />}
          </Body>
        </Root>
      </Parent>
    );
  }
}

const Parent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: -webkit-fill-available;
`;

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: -webkit-fill-available;
  flex: 1;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 50px;
  flex: 1;
  box-shadow: 0 0 11px 0 rgba(139, 137, 137, 0.5);
  /* max-width: 800px; */
  align-items: center;
`;

const CouponContainer = styled.div`
  display: flex;
  margin-top: 48px;
  justify-content: space-between;
  width: 75%;
  padding: 20px;
  background-color: #ededed;
  border: 1px solid #bebebe;
  border-radius: 8px;
`;

const BtnDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  margin-bottom: 18px;
  width: 100%;
  border: none;
  cursor: pointer;
  background-color: #4caf50;
  border-radius: 15px;
  font-family: Avenir;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 1px;
  color: #ffffff;
  height: 54px;

  img: first-of-type {
    display: flex;
    height: 32px;
    width: 32px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 16px;
  }
`;

const Image = styled.img`
  display: flex;
  width: 182px;
  height: 121.4px;
  margin-top: 4rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
  position: relative;
  padding: 24px;
  & .text-one {
    display: flex;
    font-family: Avenir;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: center;
    color: #436ab2;

    // @media (min-width: 1020px) {

    //   font-family: Avenir;
    //   font-size: 16px;
    //   font-weight: normal;
    //   font-stretch: normal;
    //   font-style: normal;
    //   line-height: normal;
    //   letter-spacing: 0.5px;
    //   text-align: center;
    //   color: #436ab2;
    // }
  }

  & .text-two {
    /* height: 22px; */
    font-family: Avenir;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    text-align: right;
    color: #436ab2;
    margin-right: 6px;
    text-decoration: none;
    // @media (min-width: 1022px) {
    //   height: 22px;
    //   font-family: Avenir;
    //   font-size: 18px;
    //   font-weight: 600;
    //   font-stretch: normal;
    //   font-style: normal;
    //   line-height: normal;
    //   letter-spacing: 1px;
    //   text-align: right;
    //   color: #436ab2;
    //   margin-right: 22px;
    // }
  }

  & img {
    width: 60px;
    height: 20px;
    object-fit: contain;
    // @media (min-width: 1022px) {
    //   width: 61px;
    //   height: 20px;
    //   object-fit: contain;
    //   margin-left: 24px;
    //   margin-right: 19px;
    // }
  }

  & .line {
    margin-left: 12px;
    margin-right: 12px;
    left: 80px;
    width: 1px;
    height: 20px;
    border: solid 0.5px #979797;

    // @media (min-width: 1022px) {
    //   position: absolute;
    //   left: 95px;
    //   width: 1px;
    //   height: 20px;
    //   border: solid 0.5px #979797;
    // }
  }
`;
