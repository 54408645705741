const initialState = {
    selfpay: false,
    totalLives: '',
    totalEmp: '',
    subscriptionAmount: '',
    wellnessPayableAmount: '',
    wellnessEmployeeData:'',
    preEmployeeYearlyFee: '',
    numberOfEmps: '',
    totalWellnessFee: '',
    userLoading: false,
    users: [],
    userError: '',
    lineThroughAmount: '',
    showLineThroughAmount: false

}


export const PaymentModeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ChangeSelfpay':
            return {
                ...state,
                selfpay: action.payload
            }
        case 'SET_TOTAL_LIVES':
            return {
                ...state,
                totalLives: action.payload
            }
        case 'SET_TOTAL_EMP':
            return {
                ...state,
                totalEmp: action.payload
            }
        case 'SET_TOTAL_SUBSCRIPTION_AMOUNT':
            return {
                ...state,
                subscriptionAmount: action.payload
            }
        case 'WELLNESS_PAYABLE_AMOUNT':
            return {
                ...state,
                wellnessPayableAmount: action.payload
            }
        case 'WELLNESS_EMPLOYEE_DATA':
            return {
                ...state,
                wellnessEmployeeData: action.payload
            }
        case 'PRE_EMP_YEARLY_FEE':
            return {
                ...state,
                preEmployeeYearlyFee: action.payload
            }
        case 'NUMBER_OF_EMPLOYEES':
            return {
                ...state,
                numberOfEmps: action.payload
            }
        case 'TOTAL_WELLNESS_FEE':
            return {
                ...state,
                totalWellnessFee: action.payload
            }
        case 'SET_LINE_THROUGH_SUBSCRIPTION_AMOUNT':
            return {
                ...state,
                lineThroughAmount: action.payload,
                showLineThroughAmount: true
            }
        case 'FETCH_USER_REQUEST':
            return {
                ...state,
                userLoading: true
            }
        case 'FETCH_USER_SUCCESS':
            return {
                ...state,
                userLoading: false,
                users: action.payload,
                userError: ''
            }
        case 'FETCH_USER_FAILURE':
            return {
                ...state,
                userLoading: false,
                users: [],
                userError: action.payload
            }


    }

    return state
}