import React, { Component } from 'react'
import styled from 'styled-components'
import phonecoverpage4 from '../assets/images/phonecoverpage4.png'
import Screenshotpage4 from '../assets/images/Screenshotpage4.png'
// import vital_credit_mobile from '../assets/images/vital_credit_mobile.png'
import vitalCreditMobile1 from '../assets/svgs/vitalCreditMobile1.svg'
import vitalCreditMobile2 from '../assets/images/vitalCreditMobile2.png'
// import vitalCreditMobile2 from '../assets/svgs/vitalCreditMobile2.svg'
import Groupdotspage4 from '../assets/images/Groupdotspage4.png'
import thumbpage4 from '../assets/images/thumbpage4.png'
import religare_desktop from '../assets/svgs/religare_desktop.svg'
import ImageD from '../assets/images/imageD.png'
import dmi_desktop from '../assets/svgs/dmi_desktop.svg'
import icon_navigation_arrow_back from '../assets/svgs/icon_navigation_arrow_back.svg'
import dmiLogo1 from '../assets/svgs/dmiLogo1.svg'
import dmiLogo2 from '../assets/svgs/dmiLogo2.svg'
import DMIGroup from '../assets/images/DMIGroup.png'
import Phonecover1024page4 from '../assets/images/assets1024/Phonecover1024page4.png'
import Screenshot1024page42 from '../assets/images/assets1024/Screenshot1024page42.jpg'
import dmi from '../assets/images/assets1024/dmi.png'
import DMI1530 from '../assets/images/DMI1530.png'
import DMI from '../assets/images/updatedlanding1/DMI.png'
import page3 from '../assets/images/updatedlanding1/page3.png'
import page4small from '../assets/images/updatedlanding1/page4small.png'
//import LazyImage from "./LazyImage";
import './Page4.css'
const Wrapper = styled.div`
  position: relative;

  @media (min-width: 1024px) {
    // height: 57.6rem;
  }
`
const Header = styled.div`
  // top: 3.55rem;
  width: 100%;
  padding: 3.6rem 0 3.6rem 0;
  // padding: 3.55rem 2.4rem 3.6rem 2.4rem;
  // padding: 3.55rem 2.4rem 2.4rem 2.4rem;
  position: relative;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17rem 0 14rem 0;
  }

  @media (min-width: 1680px) {
    // display: flex;
    // align-items: center;
    padding: 24.9rem 0;
  }
`

const Subheader = styled.div`
  padding-left: 2.4rem;
  @media (min-width: 1024px) {
    padding-left: 0;
    margin-right: 13rem;
  }

  @media (min-width: 1680px) {
    // padding-left: 14.6rem;
    margin-right: 20.8rem;
  }
`
const H2 = styled.h2`
  font-family: Proxima Nova;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.86px;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    width: 450px;
    font-size: 3.4rem;
    line-height: 1.59;
    letter-spacing: 0.02px;
  }

  @media (min-width: 1680px) {
    font-size: 5.2rem;
    line-height: 1.61;
    width: 650px;
    letter-spacing: 0.03px;
  }
`
const Header2 = styled.p`
  font-family: Proxima Nova;
  font-size: 2.8rem;
  font-weight: 600;
  letter-spacing: 0.28px;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    font-size: 4.4rem;
    font-weight: bold;
    letter-spacing: 0.02px;
  }

  @media (min-width: 1680px) {
    font-size: 72px;
    font-weight: 600;
    letter-spacing: 0.04px;
  }
`

const H3 = styled.h3`
  margin-top: 1.2rem;
  width: 29rem;
  font-family: Avenir;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: 0.02px;
  text-align: left;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    width: 45rem;
    margin-top: 2.4rem;
    font-size: 1.8rem;
    line-height: 1.56;
    letter-spacing: 0px;
  }

  @media (min-width: 1680px) {
    margin-top: 4.5rem;
    width: 69.3rem;
    font-size: 3.4rem;
    line-height: 1.59;
    letter-spacing: 0.02px;
  }
`

const Content2 = styled.p`
  font-size: 1.4rem;
  font-family: Avenir;
  font-weight: bold;
  color: #436ab3;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  text-align: left;
  text-transform: uppercase;

  margin-top: 2.4rem;
  margin-bottom: 2.3rem;
  // position: relative;
  // bottom: 1.4rem;
  // z-index: 10;
  cursor: pointer;

  @media (min-width: 1024px) {
    margin-top: 2.3rem;
    margin-bottom: 2.5rem;
    font-size: 1.6rem;
    line-height: 1.38;
    letter-spacing: 0.32px;
  }

  @media (min-width: 1680px) {
    margin-top: 5.4rem;
    margin-bottom: 4.45rem;
    font-size: 3.4rem;
    line-height: 1.35;
    letter-spacing: 0.68px;
  }
`
const Dmilogo = styled.div`
  margin-bottom: 2.4rem;
  background-image: url(${dmi_desktop});
  background-repeat: no-repeat;
  background-size: contain;

  height: 10rem;
  width: 10rem;
  padding-top: 3.5rem;

  @media (min-width: 1024px) {
    background-image: url(${dmi_desktop});
    background-size: contain;
    // height: 18.129rem;
    width: 20rem;
    // margin-top: 0.5rem;
  }

  @media (min-width: 1680px) {
    background-image: url(${dmi_desktop});
    height: 12rem;
    width: 30rem;
  }
`

const Section = styled.div`
  width: 100%;
  height: 7rem;
  display: flex;
  flex-direction: row;
  display: -webkit-flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  position: absolute;
  top: 29.9rem;
`

const Imagethumb = styled.div`
  width: 4.32rem;
  height: 6.97rem;
  background-image: url(${thumbpage4});
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 5rem;
`
const Imagedots = styled.div`
  width: 3.87rem;
  height: 3.97rem;
  background-image: url(${Groupdotspage4});
  background-repeat: no-repeat;
  position: relative;
  top: 21.86rem;
  right: 3rem;

  @media (min-width: 1024px) {
    display: none;
  }
`

const Imagedots1024 = styled.div`
  // @media (min-width: 200px) and (max-width: 1023px) {
  //   display: none;
  // }

  background-image: url(${Groupdotspage4});
  background-repeat: no-repeat;
  height: 3.29rem;
  width: 3.21rem;
  position: absolute;
  bottom: 5%;
  left: 3%;
  // margin-left: 4rem;
  // top: 15rem;
`

const Footer = styled.div`
  width: 100%;
  display: flex;
  display: webkit-flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
  position: absolute;
  margin-top: 37rem;
  height: 12rem;
  justify-content: center;

  @media (min-width: 1024px) {
    margin-top: 0rem;
  }
`

const Imagephone = styled.div`
  // width: 25.5rem;
  // width: 21rem;
  width: 23.75rem;
  height: 48.230rem;
  margin: 0 auto;


  background-image: url("${props =>
    props.error ? props.errorSrc : props.loading ? props.loadingSrc : props.actualSrc}");
  background-repeat: no-repeat;
  background-size: cover;
    position: relative;
//top: ;

  @media (min-width: 1024px) {
    // margin-left: 10rem;
    // height: 46rem;
    margin: 0;
   background-image: url("${props =>
     props.error ? props.errorSrc : props.loading ? props.loadingSrc : props.actualSrc}");
  }

  @media (min-width: 1680px) {
    // margin-left: 20rem;
    // height: 71rem;
    height: 106.615rem;
    width: 52.5rem;
    // width: 38rem;
    background-image: url("${props =>
      props.error ? props.errorSrc : props.loading ? props.loadingSrc : props.actualSrc}");
  }
`

const ScreenshotImage = styled.div`
  background-image: url(${Screenshotpage4});
  backgroun-repeat: no-repeat;
  width: 15.1rem;
  height: 23.7rem;
  position: absolute;
  margin-top: 4.3rem;
  margin-left: 2.1rem;
  display: none;

  @media (min-width: 1024px) {
    display: none;
    height: 41rem;
    width: 26.2rem;
    background-image: url(${Screenshot1024page42});
    position: absolute;
    left: 1rem;
    top: 1rem;
  }
`
const ImageDv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Image = styled.img`
  display: flex;
  // width: 259px;
  height: 468px;

  @media (min-width: 1680px) {
    // width: 400px;
    height: 800px;
  }
`

const Font14 = styled.p`
  font-size: 1.2rem;
  font-family: Avenir;
  font-weight: 600;
  color: #4a4a4a;
  line-height: 1.7;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  margin-top: 2.5rem;
`
const Religarelogo = styled.div`
  margin-bottom: 2.4rem;
  background-image: url(${religare_desktop});
  background-size: contain;
  background-repeat: no-repeat;
  height: 4rem;
  width: 10rem;

  @media (min-width: 1024px) {
    background-image: url(${religare_desktop});
    background-size: contain;
    // height: 6.54rem;
    width: 20rem;
  }

  @media (min-width: 1680px) {
    background-image: url(${religare_desktop});
    background-size: contain;
    height: 8rem;
    width: 30rem;
  }
`


const Font14a = styled.div`
  font-size: 14px;
  font-family: Avenir;
  color: #436AB2;
  font-weight: 600;
  letter-spacing: 2px;
  padding-bottom: 12px;
`

const LazyImage = ({ loadingSrc, actualSrc, errorSrc, ...props }) => {
  const [isImageLoaded, setImageLoaded] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)

  React.useEffect(
    () => {
      const img = new Image()

      img.onload = () => setImageLoaded(true)
      img.onerror = () => setHasError(true)

      img.src = actualSrc
    },
    [actualSrc]
  )

  return (
    <Imagephone
      loadingSrc={loadingSrc}
      actualSrc={actualSrc}
      errorSrc={actualSrc}
      loading={!isImageLoaded}
      error={hasError}
      {...props}
    />
  )
}
export default class Page4 extends Component {
  render = () => {
    return (
      <Wrapper id={this.props.id}>
        <Header>
          <Subheader>
          <Font14a>3. EMI NETWORK</Font14a>
            <H2>Get 0% EMI for planned elective procedures </H2>
            {/* <Header2>Vital EMI</Header2> */}
            <H3>
              Pay in EMIs at over 6000+ hospitals, labs, dental clinics, eye care centres, 
              maternity & IVF clinics, wellness centres, and gyms. Convert big bills into 
              easy monthly payments with just a click of a button.
            </H3>
            <Content2 onClick={() => this.props.redirectToCreditPage()}>
              See where can it be used{' '}
              <span>
                <img src={icon_navigation_arrow_back} alt="icon_navigation_arrow_back.svg" height="12px" width="12px" />
              </span>
            </Content2>
            {/* <Content2>Learn more </Content2> */}
            <Font14>powered By</Font14>
            {/* <Religarelogo /> */}
            <Dmilogo />
          </Subheader>
          {/* <LazyImage
            loadingSrc={vitalCreditMobile2}
            actualSrc="http://images.getvital.in/images/credit---13.25kb.png"
            errorSrc={vitalCreditMobile2}
          /> */}
          <ImageDv>
            {/* <Image src={ImageD}/> */}
            <Image alt="health insurance loan" src = "http://images.getvital.in/homelanding/imageD.png" />
          </ImageDv>

          <Imagethumb />
          <Imagedots1024 />
        </Header>
      </Wrapper>
    )
  }
}
