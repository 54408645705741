import React from 'react'
import styled from 'styled-components'
import Icons from './icons'

const RectangleBtn = styled.button`
  width: 100%;
  padding: 1.6rem 1.2rem 1.6rem 2rem;
  border: 2px solid #436ab2;
  border-radius: 7px;
  background: ${props => (props.active ? '#436ab2' : '#fff')};
  display: flex;
  justify-content: space-between;

  &:active,
  &:focus {
    outline: none;
  }
`
// & svg {
//   display: ${props => (props.active ? 'hidden' : 'none')};
// }

const Font16 = styled.p`
  font-family: Avenir;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: ${props => (props.active ? '#fff' : '#4a4a4a')};
`

const RectangleButton = props => (
  <RectangleBtn {...props}>
    <Font16 {...props}>{props.label}</Font16>
    <Icons icon="roundCheckCircle1" fill={props.active ? '#436AB2' : '#fff'} />
    {/* <Icons icon="roundCheckCircle1" fill="#fff" /> */}
  </RectangleBtn>
)

export default RectangleButton

// // const RectangleButton = ({ active, label, handleClick }) => {
// export default class RectangleButton extends Component {
//   state = {
//     active: true
//   }
//   render() {
//     // console.log('R-Button--ACtive: ', this.props.active)
//     console.log('R-Button--ACtive: ', this.state.active)

//     return (
//       // <RectangleBtn active={this.props.active} onClick={() => this.props.handleClick(!this.props.active)}>
//       <RectangleBtn active={this.state.active} onClick={() => this.setState({ active: !this.state.active })}>
//         <Font16 active={this.state.active}>{this.props.label}</Font16>
//         <Icons icon="roundCheckCircle" fill="#fff" />
//       </RectangleBtn>
//     )
//   }
// }
// // export default RectangleButton

// const RectangleButton = props => {
//   const { label, active, ...restProps } = props
//   return (
//     <RectangleBtn {...restProps}>
//       <Font16 {...props}>{props.label}</Font16>
//       <Icons icon="roundCheckCircle" fill="#fff" />
//     </RectangleBtn>
//   )
// }

// const RectangleButton = props => {
//   const { label, active, ...restProps } = props
//   return (
//     // <RectangleBtn active={active} {...restProps}>
//     <RectangleBtn {...props} {...restProps}>
//       <Font16 {...props}>{label}</Font16>
//       {/* <Font16 active={active}>{label}</Font16> */}
//       <Icons icon="roundCheckCircle" fill="#fff" />
//     </RectangleBtn>
//   )
// }

// const RectangleButton = props => {
//   // const { label, active, ...restProps } = props
//   return (
//     <RectangleBtn {...props}>
//       <Font16 {...props}>{props.label}</Font16>
//       <Icons icon="roundCheckCircle" fill="#fff" />
//     </RectangleBtn>
//   )
// }

// export default RectangleButton
