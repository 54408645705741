import React, { Component } from 'react'
import styled from 'styled-components'
import {Header} from '../../components/business'
import {Ptag} from '../../components/fonts'
import Icon from '../../components/icons'
import { Toggle } from '../../components'

import {CoveredThing } from '../../components'
import RightArrowIcon from "../../assets/images/forward.png";
import arrow from '../../assets/images/blueArrow.png'
import info from '../../assets/images/i.png'
import http from '../../services/httpService'
import {OverlayLoader} from '../../components'
import formatAmount from '../../components/memberApp/common/utils'
import vitalcoin from '../../assets/images/vital-coin.png'
import {Deductible, WellnessRewards, BusinessIcon} from '../../components/business'
import TagManager from 'react-gtm-module'
import {gtmCode} from '../../utils/utils'
import buttonBackground from "../../assets/images/payNowBackground.png";
import cross from "../../assets/images/vital-cross.png";
import $ from 'jquery'
import {maxScreenWidth} from '../../config.json'


export default class OfflineQuotes extends Component{


    constructor(props){
        super(props)
        this.state = {
            loading: true,
            selectedInsurer: '',
            premiumSelected: '',
            members: 0,
            showDeductible: false,
            showWellnessRewards: false,
            plantype: localStorage.getItem('plantype'),
            showInsurersList: false,
            maternityBenefits: false,
            preExistingBenefits: false,
            Insurers: new Map(),
            displayedInsurers: [],
            pointsSelected: true,
            pointsSelectedIndex: 0,
            pointsCategories: [],
            selectedDeductible: 0,
            estimate: false,
            pointsHeading: ''
        }
    }

    createAmountTree = (data) => {
        const insurers = this.state.Insurers
        let defaultQuote = null
        data.forEach((item) => {
            
            if (item['quote']){
                if (!defaultQuote){
                    defaultQuote = item
                }
                const insurer = item['quote']['insurer']['insurer_id']
                if (insurers.has(insurer)){
                    const insuranceItem = insurers.get(insurer)['quotes']
                    insuranceItem.push(item)
                } else {
                    insurers.set(insurer, {
                        name: item['quote']['insurer']['name'],
                        quotes: [item]
                    })
                }
            }
        })
        return {insurers, defaultQuote}
    }

    componentDidMount = async () => {
        try {
            const tagManagerArgs = {
                gtmId: gtmCode(),
              }
            TagManager.initialize(tagManagerArgs)

            const {data} = await http.get('/business/offline_quotes/')
            const {insurers, defaultQuote} = this.createAmountTree(data)
            for (let value of insurers.values()){
                value['quotes'].sort( (item1 , item2) => {
                    return item1['quote']['sum_insured'] - item2['quote']['sum_insured'] 
                })
            }
            const premiumSelected = defaultQuote['quote']['sum_insured']
            const maternityBenefits = defaultQuote['quote']['maternity_benefits']
            const preExistingBenefits = defaultQuote['quote']['ped_waiver']
            const roomType = defaultQuote['quote']['room_type']
            const selectedInsurer = defaultQuote['quote']['insurer']['insurer_id']
            this.setState({ 
                Insurers: insurers, 
                selectedInsurer,
                premiumSelected,
                maternityBenefits,
                preExistingBenefits,
                roomType,
            })
        }
        catch(ex){
            console.error(ex)
        }
        this.setState({loading: false})
    }

    onClickPayNow = async () => {
        const that = this
        try{
            that.setState({loading: true})
            const quote = this.getSelectedQuote()[0]
            
            if (quote){ 
                const requestObj = {
                    offline_quote: quote['quote'] && quote['quote']['id'],
                    add_ons: that.state.pointsSelected ? 
                        [{ 
                            subcategory: quote && quote['add_ons'] && quote['add_ons'][0] &&
                                quote['add_ons'][0]['subcategories'] && quote['add_ons'][0]['subcategories'][that.state.pointsSelectedIndex] &&
                                quote['add_ons'][0]['subcategories'][that.state.pointsSelectedIndex]['id']
                        }] : undefined
                }
                var {data} = await http.post('/business/userplans/',{
                    ...requestObj
                })
            }
            window.scrollTo(0,0)
            localStorage.removeItem('navigatingFrom')
            localStorage.setItem('plantype','annual')
            localStorage.setItem('userplan_id',data['userplan_id'])
            this.props.history.push('/business/enrollmentprocess')
            
        }
        catch(ex){
            this.setState({loading: false})
            console.error(ex)
        }
       
    }

    formatTileText = (text) => {
        if(text === 'members')
            return `${this.state.TileData['members_covered']}`
        if(text === 'months')
            return `${this.state.TileData['duration']} months`
    }

    formatSum = (sum) => {
        return '₹' + parseInt(sum)/100000 + 'Lakhs'
    }

    onClickMarketPlace = () => {
        localStorage.setItem('navigatingFrom', 'offlinequotes')
        this.props.history.push('/business/marketplace')
    }

    onClickViewPolicy = () => {
        window.scrollTo({
            top: 0,
            left: 0,
          })
        localStorage.setItem('navigatingFrom','offlinequotes')
        const policyDoc = this.state.membersCovered <= 25 ? 1 : 2
        this.props.history.push(`/business/policydoc/${policyDoc}`)
    }

    onClickViewClaimProcess = (item) => {
        window.scrollTo(0,0)
        localStorage.setItem('navigatingFrom', 'offlinequotes')
        const id = item['name'] && item['name'].toLowerCase() && item['name'].toLowerCase().indexOf('care') > -1  ? 1 : 2
        this.props.history.push(`/business/claimprocess/${id}`)
    }

    onClickSelectInsurer = (insurerID) => {
        this.setState({ selectedInsurer: insurerID})
        //this.setStates()
    }

    renderAddButton = (selected=false, color = '#436ab2', text, insurerID) => {
        return(
            <AddButton onClick={() => this.onClickSelectInsurer(insurerID)} color={color} selected={selected}>
                {selected ? `${text}ED` : text}
                {selected && <BusinessIcon icon='checkMark' fill={color} height='18' width='18'/>}
            </AddButton>
        )
    }

    renderSelectPointsButton = (selected=false, color = '#436ab2', text, clickHandler) => {
        return(
            <AddButton onClick={clickHandler} color={color} selected={selected}>
                {selected ? `${text}ED` : text}
                {selected && <BusinessIcon icon='checkMark' fill={color} height='18' width='18'/>}
            </AddButton>
        )
    }


    getUniqueValues = (item,index,self) =>  self.indexOf(item) === index


    renderSumInsuredButtons = () => {
        if (this.state.Insurers){
            let ButtonsList = []
            for ( let [key, value] of this.state.Insurers.entries()){
                let sumInsuredArray = value['quotes'] && value['quotes'].map( item => {
                    return item['quote'] && item['quote']['sum_insured']
                    
                })
                ButtonsList = [...ButtonsList, ...sumInsuredArray]
            }

            const selectSumInsured = async (sum) => {
                await this.setState({premiumSelected: sum})
                this.setStates()
            }
            const ButtonsFilteresList = ButtonsList && ButtonsList.filter(this.getUniqueValues)
            return(
                <ButtonWrapper>
                    {ButtonsFilteresList && ButtonsFilteresList.map((item, index) => (
                        <SelectButton key={`business_plans_sum_insured_${item}`} selected={this.state.premiumSelected === item} onClick={() => selectSumInsured(item)} style={{'marginBottom': '12px'}}>
                            {`${item/100000} Lakhs`}
                        </SelectButton>
                    ))}
                </ButtonWrapper>
            )
        }
        
    }   

    renderRoomType = () => {
        if (this.state.Insurers){
            let ButtonsList = []
            for ( let [key, value] of this.state.Insurers.entries()){
                let sumInsuredfiltered = value['quotes'] && value['quotes'].filter( item => 
                    item['quote'] && item['quote']['sum_insured'] === this.state.premiumSelected)
                let roomTypes = sumInsuredfiltered && sumInsuredfiltered.map( item => 
                    item['quote'] && item['quote']['room_type']
                ) || []
                ButtonsList = [...ButtonsList, ...roomTypes]
            }
            const ButtonsFilteresList = ButtonsList && ButtonsList.filter(this.getUniqueValues)

            const onSelectRoomType = async (item) => {
                await this.setState({roomType: item})
                this.setStates()
            }

            return(
                <ButtonWrapper>
                    {ButtonsFilteresList && ButtonsFilteresList.map((item, index) => (
                        <SelectButton key={`business_plans_room_${item}`} selected={this.state.roomType === item} onClick={() => onSelectRoomType(item)} style={{'marginBottom': '12px'}}>
                            {item}
                        </SelectButton>
                    ))}
                </ButtonWrapper>
            )
        }
    }

    renderPointsButton = () => {

        const quotes = this.getSelectedQuote()
        const PointsButtons = quotes && quotes[0] && quotes[0]['add_ons'] && quotes[0]['add_ons'][0]['subcategories']
        return (
            <ButtonWrapper style={{'marginTop': '16px'}}>
                {
                    PointsButtons && PointsButtons.map( (item, index) => 
                        <SelectButton onClick={() => this.setState({pointsSelectedIndex: index})} selected={this.state.pointsSelectedIndex === index} backgroundColor='#f27576'>
                            <img src={vitalcoin} width='16px' height='16px'/>
                            {item['value']}
                        </SelectButton>
                    )
                }
            </ButtonWrapper>
        )
    }

    calculatePremium = (withPoints = false, insurerID) => {
        const quote = this.getSelectedQuote(this.state.maternityBenefits ,this.state.preExistingBenefits, insurerID)
        if (!quote || quote.length === 0){
            return 'Quote not found'
        }
        const amount = quote[0] && quote[0]['quote'] && quote[0]['quote']['premium'] || 0
        const points = withPoints && this.state.pointsSelected ? 
        this.getSelectedPoints() * this.getLivesAndEmployees(true)
        : 0
        return `₹ ${formatAmount(amount+ points)}` 

    }

    getSelectedPoints = () => {
        const selectedQuote = this.getSelectedQuote()
        const pointsperEmployee = selectedQuote && selectedQuote[0] && selectedQuote[0]['add_ons'] && selectedQuote[0]['add_ons'][0] &&
        selectedQuote[0]['add_ons'][0]['subcategories'] && selectedQuote[0]['add_ons'][0]['subcategories'][this.state.pointsSelectedIndex]['cost']
        return pointsperEmployee
    }

    onClickMaternityBenefits = async () => {
        this.setState({maternityBenefits: !this.state.maternityBenefits})
    }

    onClickPEDBenefits = () => {
        this.setState({preExistingBenefits: !this.state.preExistingBenefits})
    }

    getToogleButtonLockedState = (button) => {
        if (button === 'maternity'){
            const selectedQuote = this.getInsurers({maternity: !this.state.maternityBenefits})
            console.log('maternity')
            console.dir(selectedQuote)
            return selectedQuote.length === 0
        } else if (button === 'ped'){
            const selectedQuote = this.getInsurers({ped: !this.state.preExistingBenefits})
            console.log('ped')
            console.dir(selectedQuote)
            return selectedQuote.length === 0
        }
    }

    onClickTipCalled = async (tip, id, event) => {
        event.preventDefault()
        let that = this
        await this.setState({[tip]: true})
        const ele = $(`#${id}`)
        ele.focus()
        ele.focusout(function(){
            that.setState({[tip]: false})
        })
    }

    onClickCashlessNetwork = (item) => {
        const page = item['name'] && item['name'].toLowerCase()  && item['name'].toLowerCase().indexOf('care') > -1 ? "https://www.religarehealthinsurance.com/health-plan-network-hospitals.html" : 'https://www.bharti-axagi.co.in/cashless-hospital-network'
        window.open(page, '_blank')
    }

    getInsurers = ({maternity = this.state.maternityBenefits, ped = this.state.preExistingBenefits, sum = this.state.premiumSelected}={}) => {
        let Items = []
        const room = this.state.roomType
        for ( let [key, value] of this.state.Insurers.entries()){
            let filtered = value['quotes'] && value['quotes'].filter( item => {
                return item['quote'] && item['quote']['room_type'] === room &&
                item['quote']['maternity_benefits'] === maternity &&
                item['quote']['ped_waiver'] === ped &&
                item['quote']['sum_insured'] === sum
            })
            if (filtered.length)
                Items.push({
                    id: key,
                    name: value['name']
                })
        }
        return Items
    }

    setStates = () => {
        let AmountQuotes = []
        for (let [key, value] of this.state.Insurers.entries()){
            const filteredQuotes = value['quotes'] && value['quotes'].filter(
                item => item['quote'] && item['quote']['sum_insured'] === this.state.premiumSelected
            )
            AmountQuotes = [...filteredQuotes, ... AmountQuotes]
        }

        //  Filtering logic
        //  |
        //  
        //      '-> Amount
        //          '-> RoomType
        //              '-> Maternity Benefits
        //                  '-> PED Benefits

        // If current selected insured amount is not valid for the selected insurer, then select the first quote and set its sum_insured as amount insured
        
        const RoomTypeQuotes = AmountQuotes.filter(
            item => item['quote'] && item['quote']['room_type'] === this.state.roomType
        )
        
        if (RoomTypeQuotes.length === 0){
            let selectedQuote = AmountQuotes[0]['quote']
            this.setState({maternityBenefits: selectedQuote['maternity_benefits'],
                preExistingBenefits: selectedQuote['ped_waiver'],
                roomType: selectedQuote['room_type'],
                selectedInsurer: selectedQuote['insurer'] && selectedQuote['insurer']['insurer_id']
            })
        
        } else {
            const MaternityQuotes = RoomTypeQuotes.filter(
                item => item['quote'] && item['quote']['maternity_benefits'] === this.state.maternityBenefits
            )

            if (MaternityQuotes.length === 0){
                let selectedQuote = RoomTypeQuotes[0]['quote']
                this.setState({
                    maternityBenefits: selectedQuote['maternity_benefits'],
                    preExistingBenefits: selectedQuote['ped_waiver'],
                    selectedInsurer: selectedQuote['insurer'] && selectedQuote['insurer']['insurer_id']
            })
            
            
            } else {
                let PedQuotes = MaternityQuotes.filter(
                    item => item['quote'] && item['quote']['ped_waiver'] === this.state.preExistingBenefits
                )
                if (PedQuotes.length === 0){
                    let selectedQuote = MaternityQuotes[0]['quote']
                    this.setState({
                        preExistingBenefits: selectedQuote['ped_waiver'],
                        selectedInsurer: selectedQuote['insurer'] && selectedQuote['insurer']['insurer_id']
                    })
                }
            }
        }
        
    }

    getSelectedQuote = (maternityBenfits = this.state.maternityBenefits, pedBenefits = this.state.preExistingBenefits, insurer = this.state.selectedInsurer) => {
        return this.state.Insurers.get(insurer) && this.state.Insurers.get(insurer)['quotes'].filter( item => 
            item['quote'] && item['quote']['sum_insured'] === this.state.premiumSelected && 
            item['quote']['room_type'] === this.state.roomType && 
            item['quote']['maternity_benefits'] === maternityBenfits && 
            item['quote']['ped_waiver'] === pedBenefits
        ) || []
    }

    getLivesAndEmployees = (employee) => {
        const selectedQuote = this.getSelectedQuote()
        return selectedQuote && selectedQuote[0] && selectedQuote[0]['requirement'] && selectedQuote[0]['requirement'][employee ? 'number_of_employees' : 'number_of_lives'] || ''
    }


    render(){
        return(
            <Root>
                <Header headerText='Plan Options' onClickBack={()=> this.props.history.push('/business/phone')}/>
                <StupidBlueStrip />
                <Body>
                    <Tiles>
                        <Tile>
                            <Ptag fontWeight='600' letterSpacing='0.86px' letterHeight='1.5'>
                                {'Employees Covered'}
                            </Ptag>
                           
                            <Ptag fontSize='16px' fontWeight='500' letterSpacing='0.4px'>
                                {this.getLivesAndEmployees(true)}
                            </Ptag>
                                
                        </Tile>

                        <Tile>
                            <Ptag fontWeight='600' letterSpacing='0.86px' letterHeight='1.5'>
                                {'Members Covered'}
                            </Ptag>                            
                            <Ptag fontSize='16px' fontWeight='500' letterSpacing='0.4px'>
                            {this.getLivesAndEmployees()}
                            </Ptag>

                        </Tile>

                        <Tile>
                            <Ptag fontWeight='600' letterSpacing='0.86px' letterHeight='1.5'>
                                {'Cover Period'}
                            </Ptag>                            
                            <Ptag fontSize='16px' fontWeight='500' letterSpacing='0.4px'>
                                {'12 months'}
                            </Ptag>
                        </Tile>

                    </Tiles>

                    <ThickBorder/>

                    <Cards margin={'0 0 24px 0'} boxShadow={false}>
                        <Ptag fontSize='14px' fontWeight='600' letterHeight='1.71' letterSpacing='0.5px' color='#436ab2'>
                            {'Health Insurance'}
                        </Ptag>
                        <Ptag style={{'marginTop': '4px'}} letterSpacing='0.5px' color='#7d7c7c'>
                            {'Cover for each employee, and a family floater in case dependents are added'}
                        </Ptag>

                        <CardComponents>
                            <Ptag textTransform='uppercase' fontWeight='600' letterHeight='2' letterSpacing='0.86px' >
                                {'COVERAGE '}
                            </Ptag>
                            <CoveredThing
                                iconHeight="24px"
                                iconWidth="24px"
                                fontSize="10px"
                                tilePadding="0 1.2rem 0 0"
                                features={['covid', this.state.plantype === 'annual' ? 'dayone' : 'hospitalization', 'ambulance', 'daycare', 'prehospitalization', 'posthospitalization']}
                                borderRight="none"
                            />
                        </CardComponents>
                        
                        <ViewMarketPlace style={{'marginTop':'12px','justifyContent': 'flex-end'}} id='business_plans_view_policy'  onClick={this.onClickViewPolicy}>
                            <Ptag fontWeight='600' letterSpacing='0.75px' color='#4a90e2' >
                                {'View Details'}
                            </Ptag>
                            <img style={{'margin': '3px 0 0 6px'}}  src={arrow} width='12px' height='12px'/>
                        </ViewMarketPlace>
                        
                        <CardComponents>
                            <Ptag textTransform='uppercase' fontWeight='600' letterHeight='2' letterSpacing='0.86px' >
                                {'Select Sum Insured '}
                            </Ptag>

                            {this.renderSumInsuredButtons()}

                        </CardComponents>

                        <CardComponents>
                            <Ptag textTransform='uppercase' fontWeight='600' letterHeight='2' letterSpacing='0.86px' >
                                {'Select Room Type '}
                            </Ptag>

                            {this.renderRoomType()}

                        </CardComponents>
                        
                      
                        <BenefitsRow>
                            
                            <BenefitRowItem>
                                
                                <Ptag fontWeight='600' letterSpacing='1px'>
                                    {'Do you want to include Maternity Benefits?'}
                                    <img style={{'marginLeft' : '4px'}} onClick={(event) => this.onClickTipCalled('showDeliveryTip', 'maternitytip', event)} height='14px' width='14px' src={info}/>
                                </Ptag>
                                
                    
                            </BenefitRowItem>

                            <Toggle
                                locked={this.getToogleButtonLockedState('maternity')}
                                active={this.state.maternityBenefits}
                                toggleClick={this.onClickMaternityBenefits}
                            />
                            
                        </BenefitsRow>
                  

                      
                        <BenefitsRow>
                            <BenefitRowItem>
                                <Ptag fontWeight='600' letterSpacing='1px'>
                                    {'Do you want to cover Pre-existing Diseases?'}
                                    <img style={{'marginLeft' :'4px'}} onClick={(event) => this.onClickTipCalled('showPedTip', 'pedtip', event)} height='14px' width='14px' src={info}/>
                                </Ptag>
                                
                                
                            </BenefitRowItem>
                            <Toggle
                                locked={this.getToogleButtonLockedState('ped')}
                                active={this.state.preExistingBenefits}
                                toggleClick={this.onClickPEDBenefits}
                            />
                        </BenefitsRow>

                        
                        
                        
                        <Ptag style={{'margin': '24px 0 16px 0'}} fontWeight='600' letterSpacing='1px' color='#4a4a4a'>
                            {'INSURER WITH BEST QUOTES'}
                        </Ptag>
                        <InsurerCardWrapper>

                            {
                                this.getInsurers().map( (item, index) =>
                                    <InsurerCard key={`InsurerCar_${index}`}>
                                
                                        <InsurerCardTop>
                                            <Ptag textTransform='capitalize' fontSize='14px' fontWeight='600' letterSpacing='1.17px'>
                                                {item['name']
                                                }
                                            </Ptag>

                                            <LinksWrapper>
                                                <ViewMarketPlace onClick={() => this.onClickCashlessNetwork(item)}>
                                                    <u style={{'textDecoration':'none'}} >
                                                        {'Cashless  Network'}
                                                    </u>
                                                    <img style={{'marginLeft': '4px'}} src={arrow} width='14px' height='14px'/>
                                                </ViewMarketPlace>
                                                <ViewMarketPlace onClick={() => this.onClickViewClaimProcess(item)}>
                                                    <u style={{'textDecoration':'none'}}>
                                                        {'Claim Process'}
                                                    </u>
                                                    <img style={{'marginLeft': '4px'}} src={arrow} width='14px' height='14px'/>
                                                </ViewMarketPlace>
                                            </LinksWrapper>
                                        </InsurerCardTop>
                                        
                                        <InsurerCardFooter>
                                            <CardFooterBottomText>
                                                <Ptag fontSize='8px' letterHeight='2' letterSpacing='0.67px'>
                                                    {'TOTAL PREMIUM'}
                                                </Ptag>
                                                <Ptag fontSize='18px' fontWeight='600' letterHeight='1.33' letterSpacing='0.9px'>
                                                    {this.calculatePremium(false, item['id'])}
                                                </Ptag>
                                            </CardFooterBottomText>
                                            {this.state.Insurers.size > 1 && this.renderAddButton(this.state.selectedInsurer === item['id'], '#436ab2', 'SELECT', item['id'])}
                                        </InsurerCardFooter>
                                    </InsurerCard>
                        
                                )
                            }

                        
                        </InsurerCardWrapper>
                        {
                            this.state.estimate && 
                            <Ptag style={{'marginTop': '8px'}} fontSize='10px' letterSpacing='0.5px'>
                                {'*Estimated price based on the age assumptions of members.'}
                            </Ptag>
                        }
                        

                       
                    </Cards>
                    
                    <ThickBorder />

                    <Cards boxShadow={true} style={{'margin': ' 0 -24px'}} >
                        <BenefitsBody>
                            <Ptag style={{'marginBottom': '8px'}} fontSize='14px' fontWeight='600' letterHeight='1.29' letterSpacing='0.5px' color='#436ab2'>
                                {'Wellness Rewards'}
                            </Ptag>
                        
                            <Ptag fontSize='12px' letterSpacing='0.71px' color='#7d7c7c'>
                                {'Vital Points offered to each employee to use in the marketplace'}
                                {/* <img onClick={() => this.setState({showWellnessRewards: true})} style={{'margin': '0 0 -4px'}} src={question} height='16px' width='16px' /> */}
                            </Ptag>
                            

                            <ViewMarketPlace style={{'marginTop': '12px'}} id='business_plans_view_marketplace' onClick={this.onClickMarketPlace}>
                                <u>
                                    {'View Marketplace'}
                                </u>
                                <img style={{'marginLeft': '4px'}} src={arrow} width='14px' height='14px'/>
                            </ViewMarketPlace>
                            
                            <BenefitsRow style={{'marginTop': '28px', 'justifyContent':'unset' }}>
                                <Ptag letterSpacing='0.5px'>
                                    {'Select points per employee'}
                                </Ptag>
                                <PointsConversion>
                                    <img src={vitalcoin} width='14px' height='14px'/>
                                    {'1 = ₹ 1'}
                                </PointsConversion>
                            </BenefitsRow>

                        {this.renderPointsButton()}
                        
                        </BenefitsBody>
                        <CardFooter style={{'margin': '28px 0 0 0'}} bgColor='#f2757616'>
                            <CardFooterBottomText>
                                <Ptag fontSize='10px' letterHeight='1.6' letterSpacing='0.83px'>
                                    {'AMOUNT'}
                                </Ptag>
                                <PointsConversion margin='0'>
                                    <Ptag fontSize='18px' fontWeight='600' letterHeight='1.33' letterSpacing='0.9px'>
                                        {`₹ ${formatAmount(this.getSelectedPoints())}`}
                                    </Ptag>
                                    <Ptag style={{ 'marginTop': '6px'}} fontSize='12px' fontWeight='600' letterHeight='1.33' letterSpacing='0.9px'>
                                        {'/employee'}
                                    </Ptag>
                                </PointsConversion>
                            </CardFooterBottomText>
                            {this.renderSelectPointsButton(this.state.pointsSelected,'#f27576', 'ADD' ,() => this.setState({pointsSelected: !this.state.pointsSelected}))}
                        </CardFooter>
                    </Cards>

                    <ThickBorder/>
                </Body>

                <PaymentFooter>
                    <FooterLeft>
                    <Ptag color="#fff" letterSpacing="0.4px" letterHeight="1.33">
                        {"Total Due"}
                    </Ptag>
                    <Ptag fontSize="20px" color="#fff" letterSpacing="1px" letterHeight="1.2">
                        {`${this.calculatePremium(true, this.state.selectedInsurer)}`}
                    </Ptag>
                    </FooterLeft>
                    <AuthoriseButton id="business_plan_continue" onClick={this.onClickPayNow}>
                        <AuthoriseText>CONTINUE</AuthoriseText>
                        <RightArrow src={RightArrowIcon} />
                    </AuthoriseButton>
                </PaymentFooter>
                
                <Deductible
                    showPopup={this.state.showDeductible}
                    BackBtn={() => this.setState({showDeductible: false})}
                />
                <WellnessRewards showPopup={this.state.showWellnessRewards} onClosePopup={() => this.setState({showWellnessRewards: false})}/>
                <EditNumberPopup showTip={this.state.showDeliveryTip} id='maternitytip' margin='0px 12px 0 190px'>
                    <img onClick={() => this.setState({showDeliveryTip: false})}  style={{'alignSelf': 'flex-end'}} height='14px' width='14px' src={cross}/>
                    
                    <Ptag style={{'margin':  '12px 0'}} fontWeight='600' letterSpacing='0.5px'>
                        {'Maternity Benefits'}
                    </Ptag>
                    <Ptag letterSpacing='0.5px'>
                        {'1. Up to Rs. 50,000 for Normal or Cesarean delivery'}
                    </Ptag>
                    <Ptag letterSpacing='0.5px'>
                        {'2. Up to Rs. 5000 for pre and post natal expenses (+/- 30 days of hospitalization)'}
                    </Ptag>
                    <Ptag letterSpacing='0.5px'>
                        {'3. Newborn babies covered from day one (in case spouse is covered)'}
                    </Ptag>
                </EditNumberPopup>
                <EditNumberPopup id='pedtip' showTip={this.state.showPedTip} >

                    <img onClick={() => this.setState({showPedTip: false})} style={{'alignSelf': 'flex-end'}} height='14px' width='14px' src={cross}/>

                    <Ptag style={{'margin': ' 12px 0'}} fontWeight='600' letterSpacing='0.5px'>
                        {'PED Benefits'}
                    </Ptag>              
                    <Ptag letterSpacing='0.5px'>
                        {'This will cover your employees for any illness, condition or injury which they had been diagnosed with, anytime prior to the policy being issued.'}
                    </Ptag>
                    
                </EditNumberPopup>
                {this.state.loading && <OverlayLoader />}
            </Root>
        )
    }
}


const Root = styled.div`
    display: flex;
    flex-direction: column;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 24px 36px 24px;
    margin-bottom: 38px;
    @media(max-width:425px){
        padding: 24px 16px 36px 16px;
    }
`

const StupidBlueStrip = styled.div`
    display: flex;
    height: 16px;
    background-color: #436ab2;
`;

const Tiles = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
`

const Tile = styled.div`
    display: flex;
    width: 32%;
    padding: 12px;
    flex-direction: column;
    border: solid 1px #4a90e230;
    & > :first-child{
        margin-bottom: 8px;
    };
`;


const ViewMarketPlace = styled.div`
    display: flex;
    font-family: Avenir;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: right;
    color: #4a90e2;
    cursor: pointer;
    @media(min-width: ${maxScreenWidth}){
        margin-right: ${props => props.giveMarginOnDesktop ? '165px' : ''}
    }
`;


const Cards = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    overflow: hidden;
    box-shadow: ${props => props.boxShadow ? '1px 1px 4px 0 rgba(0, 0, 0, 0.18)' : ''};
    z-index:12;
    margin: ${props => props.margin ? props.margin : '0'}
`

const BenefitsBody = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 24px;
`

const CardComponents = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    & > :first-child{
        margin-bottom: 12px;
    }
`

const SelectButton = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    min-width: 88px;
    height: 36px;
    border-radius: 5px;
    border: solid 1px ${props => props.backgroundColor ? props.backgroundColor : '#436ab2'};
    color: ${props => props.selected ? '#fff' : '#4a4a4a'};
    background-color: ${props => props.selected ? (props.backgroundColor ? props.backgroundColor : '#436ab2') : '#fff'};
    font-family: Avenir;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    & > img{
        margin-right: 4px;
    };
    cursor: pointer;
`

const AddButton = styled.div`
    display: flex;
    align-items: center;
    justify-content:center;
    min-width: 114px;
    height: 36px;
    border-radius: 5px;
    border: solid 1px ${props => props.color};
    color: ${props => props.selected ? '#fff' : '#4a4a4a'};
    background-color: ${props => props.selected ? props.color : '#fff'};
    font-family: Avenir;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    & > svg {
        margin-left: 14px 
    };
    cursor: pointer;
`

const SelectButtonWrapper = styled.div`
    display: flex;
    flex-direction:column;
    & > :first-child{
        margin-bottom: 6px;
    }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  //Chrome, Safari, newer versions of Opera
  ::-webkit-scrollbar {
    display: none;
  }
  //Firefox
  overflow: -moz-scrollbars-none;
  //Internet Explorer +10
  -ms-overflow-style: none;
  & > :not(:last-child){
    margin-right: 20px;
  };
`

const SelectDeductible = styled.div`
  display: flex;
  & > :nth-child(2){
      height: 14px;
      width: 14px;
      margin: 4px 0 0 4px;
  };
`

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items:center;
  background-color: ${props => props.bgColor ? props.bgColor : '#436ab214'} ;
  padding: 24px;
  margin: 28px -18px 0 -18px;
`

const CardFooterBottomText = styled.div`
  display: flex;
  flex-direction: column;
`

const InsurerCardWrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  & > :not(:last-child){
      margin-right: 12px
  };
  ::-webkit-scrollbar {
    display: none;
  }
`

const InsurerCard = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 370px;
    justify-content: space-between;
    border-radius: 5px;
    border: solid 1px #436ab2;
    background-color: #ffffff;
    border-radius: 5px;
    @media (min-width: 425px): {
        min-width: 420px;
    }
`

const InsurerCardTop = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px 20px 28px 20px;
`
const LinksWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top:10px; 
`

const InsurerCardFooter =styled.div`
  display: flex;
  padding: 18px 20px 18px 20px;
  background-color: #436ab222;
  justify-content: space-between;

`

const BenefitsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`

const BenefitRowItem = styled.div`
  display: flex;
  align-items: center;
`

const PointsConversion = styled.div`
    display: flex;
    min-width: 54px;
    justify-content: space-between;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.86px;
    text-align: center;
    color: #7d7c7c;
    margin: ${props => props.margin ? props.margin : '0 0 0 12px'};
`


const PaymentFooter = styled.div`
  position: fixed;
  width: 100%;
  max-width: ${maxScreenWidth};
  background-image: url(${buttonBackground});
  background-size: cover;
  background-postion: left bottom;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.1rem 1.8rem 1.1rem 2.4rem;
  z-index: 30;
`;

const FooterLeft = styled.div`
  dispaly: flex;
  flex-direction: column;
  & > :first-child {
    margin-bottom: 8px;
  }
`;

const AuthoriseButton = styled.div`
  height: 5.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  background-color: #ffffff;
  cursor: pointer;
`;

const AuthoriseText = styled.p`
  height: 2.2rem;
  font-family: Avenir;
  font-size: 1.6rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.114rem;
  color: #4a4a4a;
  padding: 0 8px 0 12px;
`;

const RightArrow = styled.img`
  margin-left: 0.8rem;
  height: 3.2rem;
  width: 3.2rem;
  margin-right: 1rem;
`;

const ThickBorder = styled.div`
      display: flex;
      height: 12px;
      margin: 0px -24px;
      background-color: #ececec;
`

const HelperText = styled.button`
  display: flex;
  max-width: 154px;
  margin: ${props => props.margin};
  z-index: 10;
  background-color: white;
  flex-direction: column;
  position: absolute;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid #4a4a4a;
  box-shadow: ;
  :focus {
    outline: none;
    box-shadow:10px 10px 39px 0px rgba(194,190,194,1);
}
`

const EditNumberPopup = styled.button`
  width: 100%;
  max-width: 538px;
  display: flex;
  border-radius: 8px 8px 0 0 ;
  margin: 12px auto 0 auto;
  align-self: center;
  flex-direction: column;
  padding: 0 1.2rem;
  bottom: 0;
  position: fixed;
  background-color: white;
  padding: 24px 24px 48px 24px;
  z-index: 35;
  :focus {
    outline: none;
  };
  transform: ${(props) =>
    props.showTip === true
      ? "translateY(0) scaleY(100%)"
      : "translateY(102%) scaleY(0)"};
  transition: all 0.6s;
`;