import React, { Component } from 'react'
import styled from 'styled-components'
import thumbpage2 from '../assets/images/thumbpage2.png'
import Groupdots from '../assets/images/Groupdots.png'
import MaskGroup from '../assets/images/MaskGroup.png'
import Group1417 from '../assets/images/assets1024/Group1417.png'
import vitalPoints_Mobile2 from '../assets/images/vitalPoints_Mobile2.png'
import icon_navigation_arrow_back from '../assets/svgs/icon_navigation_arrow_back.svg'
import vitalPointsHome from '../assets/images/vitalPointsHome.png'

import MaskGroup12 from '../assets/images/assets1024/MaskGroup12.png'

const Wrapper = styled.div`
  position: relative;

  @media (min-width: 1024px) {
  }
`
const Header = styled.div`
  width: 100%;
  padding: 3.6rem 0 3.6rem 0;
  position: relative;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17rem 0 14rem 0;
  }

  @media (min-width: 1680px) {
    padding: 24.9rem 0;
  }
`

const Subheader = styled.div`
  padding-left: 2.4rem;
  @media (min-width: 1024px) {
    padding-left: 0;
    margin-right: 140px;
  }

  @media (min-width: 1680px) {
    // padding-left: 14.6rem;
  }
`
const H2 = styled.h2`
  font-family: Proxima Nova;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.86px;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    width: 100%;
    font-size: 3.4rem;
    line-height: 1.59;
    letter-spacing: 0.02px;
  }

  @media (min-width: 1680px) {
    font-size: 5.2rem;
    line-height: 1.61;
    letter-spacing: 0.03px;
  }
`
const Header2 = styled.p`
  font-family: Proxima Nova;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.86px;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    font-size: 4.4rem;
    font-weight: bold;
    letter-spacing: 0.02px;
  }

  @media (min-width: 1680px) {
    font-size: 72px;
    font-weight: 600;
    letter-spacing: 0.04px;
  }
`

const H3 = styled.h3`
  margin-top: 1.2rem;
  width: 29rem;
  font-family: Avenir;
  font-size: 1.6rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: 0.02px;
  text-align: left;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    width: 45rem;
    margin-top: 2.4rem;
    font-size: 1.8rem;
    line-height: 1.56;
    letter-spacing: 0px;
  }

  @media (min-width: 1680px) {
    margin-top: 4.5rem;
    width: 69.3rem;
    font-size: 3.4rem;
    line-height: 1.59;
    letter-spacing: 0.02px;
  }
`
const Content2 = styled.p`
  font-size: 1.4rem;
  font-family: Avenir;
  font-weight: bold;
  color: #436ab3;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  text-align: left;
  text-transform: uppercase;

  margin-top: 2.4rem;
  margin-bottom: 2.3rem;
  cursor: pointer;

  span {
    img {
      height: 1.2rem;
    }
  }

  @media (min-width: 1024px) {
    margin-top: 2.3rem;
    margin-bottom: 2.5rem;
    font-size: 1.6rem;
    line-height: 1.38;
    letter-spacing: 0.32px;

    span {
      img {
        height: 1rem;
      }
    }
  }

  @media (min-width: 1680px) {
    margin-top: 5.4rem;
    margin-bottom: 4.45rem;
    font-size: 3.4rem;
    line-height: 1.35;
    letter-spacing: 0.68px;

    span {
      img {
        height: 2.4rem;
      }
    }
  }
`

const Imagethumb = styled.div`
  width: 2.8rem;
  height: 5.83rem;
  background-image: url(${thumbpage2});
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  bottom: 3%;

  @media (min-width: 1024px) {
    height: 10rem;
    width: 9.81rem;
  }
`

const Imagecircles = styled.div`
  width: 5.9rem;
  height: 6rem;
  background-image: url(${MaskGroup});
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  bottom: 0;
  // top: 41rem;

  @media (min-width: 1024px) {
    background-image: url(${MaskGroup12});
    height: 5.81rem;
    width: 5.47rem;
    // top: 22rem;
  }
`
const Imagedots = styled.div`
  width: 4.72rem;
  height: 4.72rem;
  background-image: url(${Groupdots});
  background-repeat: no-repeat;
  position: absolute;
  right: 3%;
  top: 30%;

  @media (min-width: 1024px) {
    background-image: url(${Group1417});
    height: 4.44rem;
    width: 4.45rem;
    top: 12%;
  }
`

const ImageWrapper = styled.div`
  // width: 50rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1367px) {
    // width: 40rem;
  }

  @media (max-width: 425px) {
    // width: auto;
  }
`

const Imagephone = styled.div`
  width: 26.5rem;
  height: 26.5rem;
  margin: 0 auto;
  background-image: url("${props =>
    props.error ? props.errorSrc : props.loading ? props.loadingSrc : props.actualSrc}");
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: 1024px) {
    margin: 0;
    width: 40rem;
    height: 40rem;
  }

  @media (min-width: 1680px) {
    width: 50rem;
    height: 50rem;
    margin: 0;
    background-image: url("${props =>
      props.error ? props.errorSrc : props.loading ? props.loadingSrc : props.actualSrc}");
    background-size: cover;
  }
`
const Image = styled.img`
  width: 296px;
  height: 293px;

  @media (min-width: 1024px) {
    width: 400px;
    height: 400px;
  }

  @media (min-width: 1680px) {
    width: 600px;
    height: 600px;
  }
`

const Font14 = styled.div`
  font-size: 14px;
  font-family: Avenir;
  color: #436AB2;
  font-weight: 600;
  letter-spacing: 2px;
  padding-bottom: 12px;
`

const LazyImage = ({ loadingSrc, actualSrc, errorSrc, ...props }) => {
  const [isImageLoaded, setImageLoaded] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)
  React.useEffect(
    () => {
      const img = new Image()

      img.onload = () => setImageLoaded(true)
      img.onerror = () => setHasError(true)

      img.src = actualSrc
    },
    [actualSrc]
  )

  return (
    <Imagephone
      loadingSrc={loadingSrc}
      actualSrc={actualSrc}
      errorSrc={actualSrc}
      loading={!isImageLoaded}
      error={hasError}
      {...props}
    />
  )
}

export default class Page2 extends Component {
  render = () => {
    return (
      <Wrapper id={this.props.id}>
        <Header>
          <Subheader>
            <Font14>1. OFFERS</Font14>
            <H2>Save big on regular health<br /> and wellness expenses</H2>
            {/* <Header2>Save big on regular health and wellness expenses</Header2> */}
            <H3>
              With exclusive offers on doctor consultation, health checkups, medicines, 
              gym membership, and wellness packages 
              from the best providers at best prices.
            </H3>
            <Content2 onClick={() => this.props.redirectToVitalPointsPage()}>
              Redeem with Vital Points{' '}
              <span>
                <img src={icon_navigation_arrow_back} alt="icon_navigation_arrow_back.svg" />
              </span>
            </Content2>
          </Subheader>
          <ImageWrapper>
            
            {/* <Image src={vitalPointsHome} /> */}
            <Image alt="Vital Points Partners" src= "http://images.getvital.in/homelanding/vitalPointsHome.png" />
          {/* <LazyImage
            loadingSrc={vitalPoints_Mobile2}
            actualSrc={"http://images.getvital.in/images/Points-34.71kb+(1).png"}
            errorSrc={vitalPoints_Mobile2}
          /> */}
          </ImageWrapper>
          <Imagethumb />
          <Imagecircles />
          <Imagedots />
        </Header>
      </Wrapper>
    )
  }
}
