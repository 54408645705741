import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {Header} from '../../components/business'
import {Ptag} from '../../components/fonts'
import TagManager from 'react-gtm-module'
import {gtmCode} from '../../utils/utils'
import http from '../../services/httpService'
import {OverlayLoader} from '../../components'


function MembersType(props){
    const [selectedTile, setTile] = useState('');
    const [loading, setLoader] = useState(false)
    useEffect( () => {
        const tagManagerArgs = {
            gtmId: gtmCode(),
        }
        TagManager.initialize(tagManagerArgs)
    }, [])
    const onBack = () => {
        props.history.push('/business/about')
    }
    const onClickTile = async (tile) => {
        const mapping = ['E', 'ESC', 'ESCP']

        await http.post('/business/plan/',{
            policy_type: mapping[tile]
            }
        )
        setTile(tile)
        localStorage.setItem('membersType', tile)
        setTimeout(() =>
            props.history.push('/business/employees')
        , 200)
        
    }
    return(
        <Root>
            <Header progressPercentage={20}/>
            <Body>
                <Ptag fontFamily='Proxima Nova' style={{'marginBottom': '24px'}} fontSize='24px' fontWeight='500' letterHeight='1.33' letterSpacing='0.35px'>
                    {'coverage type'}
                </Ptag>
                <Tiles id="business_memberstype_E" selected={selectedTile === 0} active onClick={() => onClickTile(0)} >
                    <Ptag color={selectedTile === 0 && '#fff' || '#4a4a4a'} letterHeight='1.5' fontSize='16px' fontWeight='500' letterSpacing='1px'>
                        {'Employee only'}
                    </Ptag>
                </Tiles >
                <Tiles id="business_memberstype_ESK" selected={selectedTile === 1} onClick={() => onClickTile(1)} >
                    <Ptag color={selectedTile === 1 && '#fff' || '#4a4a4a'} letterHeight='1.5' fontSize='16px' fontWeight='500' letterSpacing='1px'>
                        {'Employee, Spouse and Kids'}
                    </Ptag>
                </Tiles>
                <Tiles id="business_memberstype_ESCK" selected={selectedTile === 2} onClick={() => onClickTile(2)} >
                    <Ptag color={selectedTile === 2 && '#fff' || '#4a4a4a'} letterHeight='1.5' fontSize='16px' fontWeight='500' letterSpacing='1px'>
                        {'Employee, Spouse, Kids and Parents'}
                    </Ptag>
                </Tiles>
            </Body> 
            {loading && <OverlayLoader />}
        </Root>
    )
}

export default MembersType

const Root = styled.div`
    display: flex;
    flex-direction: column;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
`

const Tiles = styled.button`
    display: flex;
    border: 1px solid #436ab2;
    border-radius: 5px;
    padding: 16px;
    height: 66px;
    align-items: center;
    margin-bottom: 20px;
    background-color:${props => props.selected ? '#436ab2' : '#fff'};
    :focus {
        outline: none;
        box-shadow: none;
    }
`