const Insurers = new Map()
Insurers.set('1', 'Care Health Insurance Limited (formerly known as Religare Health Insurance Company Limited)')
Insurers.set('2', 'Bharti AXA')
Insurers.set('3', 'ICICI Lombard')


const hospitalNetwork = new Map()
hospitalNetwork.set('1', "https://www.religarehealthinsurance.com/health-plan-network-hospitals.html")
hospitalNetwork.set('2', 'https://www.bharti-axagi.co.in/cashless-hospital-network')
hospitalNetwork.set('3', 'https://www.icicilombard.com/cashless-hospitals')

const claimForms = new Map()
claimForms.set('1', 'https://www.careinsurance.com/siteupload/doc/59235CARE-_INDEMNITY_HEALTH_CLAIM_FORM.pdf')
claimForms.set('2', 'https://www.bharti-axagi.co.in/sites/default/files/downloadcenter/Claim%20Form%20A-SuperShip.pdf')
claimForms.set('3', 'https://www.icicilombard.com/docs/default-source/Policy-Wordings-product-Brochure/english-health-claim-form8c0003ff45fd68ff8a0df0055e0d3bfe.pdf?sfvrsn=39fd6bfe_6')

export  {
    Insurers,
    hospitalNetwork,
    claimForms
}