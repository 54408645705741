import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import http from '../../services/httpService'
import { Ptag } from '../../components/fonts'
import { withRouter } from 'react-router'
import Icons from '../../components/icons'
import { theme } from '../../theme'
import NeoInsuranceLogo from '../../assets/svgs/neo_insurance_white.svg'
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Input, DateField, LargeButton, OverlayLoader } from '../../components'
import { validateDOB, validateMobileNumber, formatDate, formatDateReverse } from '../../utils/utils'
import plus from '../../assets/images/add-circle-outline.png'
import moment from 'moment'
import { emptyPersonalState, filledPersonalState, actions, spouseOptions, emptyFields } from '../../constants/employee'



const EmployeeDetailForm = () => {
    const employeeId = sessionStorage.getItem('employeeId');
    const previewDependentType = sessionStorage.getItem('dependentType');

    const [loading, setLoading] = useState(true)
    const [employeeData, setEmployeeData] = useState({})
    const [displaySuccessMsg, setDisplaySuccessMsg] = useState(false);
    const [showSpouse, setSpouseField] = useState(false)
    const [displaySection, setDisplaySection] = useState(emptyPersonalState)
    const [dependentIdsToDelete, setDependentIdsToDelete] = useState([]);

    useEffect(() => {
        if (!employeeId) {
            if (previewDependentType) {
                dependentType(previewDependentType)
            } else {
                setDisplaySection(filledPersonalState)
            }
            setEmployeeData({
                spouse: emptyFields,
                children: [emptyFields],
                parents: [emptyFields]
            })
            setSpouseField(true)
        }
    }, [])

    const dependentType = (value) => {
        switch (value) {
            case "E":
                setDisplaySection(emptyPersonalState)
                break;
            case "ES":
                setDisplaySection(prev => ({
                    ...prev,
                    spouse: true
                }))
                break;
            case "ESC":
                setDisplaySection(prev => ({
                    ...prev,
                    spouse: true,
                    children: true,
                }))
                break;
            case "ESCP":
                setDisplaySection(filledPersonalState)
                break;
        }
        return
    }

    const setShowSouseField = (data) => {
        if (!employeeId) return;
        data = !data
        setSpouseField(data)
    }

    const maxLimiter = (arrayHelpers, noOfPeople, maxLimit, values, type) => {
        if (!employeeId) return;
        let totalPersonnels = 1;
        if (values && values.children && values.children.length > 0) {
            totalPersonnels += values.children.length;
        }
        if (values.spouse && values.spouse.first_name) {
            totalPersonnels += 1;
        }
        if (values.parents && values.parents.length > 0) {
            totalPersonnels += values.parents.length;
        }
        if (totalPersonnels >= 6) {
            alert("You can add maximum of 6 personnel.");
            return;
        }
        if (noOfPeople < maxLimit) {
            arrayHelpers.push({ first_name: '', last_name: '', dob: '', relationship: '', action: actions.add })
        }
    }

    const addAction = (arr) => {
        return arr.map((el) => ({
            ...el,
            action: el.dependent_id ? actions.update : actions.add
        }))
    }

    const getData = async () => {
        try {
            setLoading(true);
            const params = {
                params: {
                    employee_id: employeeId
                }
            }
            const { data } = await http.get(`/business/get_business_employee_data/`, params)
            const initalEmployeeData = {
                ...data,
                dob: data.dob && formatDateReverse(data.dob) || '',
                spouse: data && data.spouse && {
                    first_name: data && data.spouse && data.spouse.first_name || '',
                    last_name: data && data.spouse && data.spouse.last_name || '',
                    dob: data && data.spouse && data.spouse.dob && formatDateReverse(data.spouse.dob) || '',
                    dependent_id: data && data.spouse && data.spouse.dependent_id,
                    action: (data && data.spouse && data.spouse.dependent_id) ? actions.update : actions.add
                },
            }
            if (data) {
                if (data.children) {
                    initalEmployeeData.children = addAction(data.children);
                    initalEmployeeData.children = formatArrDate(initalEmployeeData.children, true);
                }
                if (data.parents) {
                    initalEmployeeData.parents = addAction(data.parents);
                    initalEmployeeData.parents = formatArrDate(initalEmployeeData.parents, true);
                }
            }
            data && data.spouse && data.spouse.first_name && setSpouseField(true)
            setEmployeeData(initalEmployeeData)
            dependentType(data.dependent_type)
        } catch (ex) {
            console.log(ex)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        (async function () {
            await getData()
        })();
    }, [])

    const handleDateChange = (e, formik, fieldName, variable) => {
        const value = e.target.value
        const { values, setFieldValue } = formik
        let prevDate = fieldName
        let newDate = value
        let prevDateLen = fieldName.length
        let newDateLen = newDate.length
        let newDob = newDate

        if (newDateLen < prevDateLen) {
            newDob = fieldName.substr(0, prevDateLen - 1)
        } else if (newDateLen <= 10) {
            if (newDateLen === 2 || newDateLen === 5) {
                newDob = newDate + '/'
            }
        } else {
            newDob = newDate.slice(0, 10)
        }
        setFieldValue(variable, newDob)
    }

    const handlePhoneChange = (e, formik, variable) => {
        const value = e.target.value
        const { setFieldValue } = formik

        const newPhone = value.slice(0, 10)

        setFieldValue(variable, newPhone)
    }

    const deletePersonnel = (remove, index, values, field, formikProps) => {
        if (!remove && !index && field === "spouse") {
            const data = {
                ...values,
                action: actions.delete,
                type: field
            }
            if (formikProps) {
                formikProps.setFieldValue("spouse.first_name", "")
                formikProps.setFieldValue("spouse.last_name", "")
                formikProps.setFieldValue("spouse.dob", "")
            }
            setDependentIdsToDelete(prev => [...prev, data]);
            setSpouseField(false);
            return;
        }
        if (values.dependent_id) {
            const data = {
                ...values,
                action: actions.delete,
                type: field
            }
            setDependentIdsToDelete(prev => [...prev, data]);
        }
        remove(index);
    }

    const addingChild = (index, formikProps, { remove }) => {
        const { values } = formikProps

        return (
            <>
                <Ptag fontSize='1.6rem' fontWeight='600' marginTop='4rem' marginBottom="-1rem" marginLeft='5px' letterSpacing='0.44px' color='#4A90E2' letterHeight='19px'>{`CHILD ${index + 1}`}</Ptag>
                <Field
                    name={`children[${index}].first_name`}
                >
                    {
                        ({ field }) => (
                            <>
                                <Input
                                    {...field}
                                    placeholder={'Child First Name'}
                                    value={values.children[index]['first_name']}
                                    disabled={!employeeId}
                                    border="1px solid #979797"
                                    color="#4a4a4a"
                                    errorName={`children[${index}]first_name`}
                                />
                            </>
                        )
                    }
                </Field>
                <Field
                    name={`children[${index}].last_name`}
                >
                    {
                        ({ field }) => (
                            <div>
                                <Input
                                    {...field}
                                    placeholder={'Child Last Name'}
                                    value={values.children[index]['last_name']}
                                    disabled={!employeeId}
                                    border="1px solid #979797"
                                    color="#4a4a4a"
                                    errorName={`children.${index}.last_name`}
                                />
                            </div>
                        )
                    }
                </Field>
                <Field
                    name={`children[${index}].dob`}
                // validate={validateDOB}
                >
                    {
                        ({ field }) => (
                            <DateField
                                {...field}
                                placeholder={'DOB (DD/MM/YYYY)'}
                                value={values.children[index]['dob']}
                                errorName={`children[${index}].dob`}
                                disabled={!employeeId}
                                color="#4a4a4a"
                                border="1px solid #979797"
                                onChange={(e) => handleDateChange(e, formikProps, formikProps.values.children[index]['dob'], `children[${index}]['dob']`)}
                            />
                        )
                    }
                </Field>
                <Dropdown component="select" name={`children[${index}].relationship`} disabled={!employeeId}>
                    <option disabled selected value="">Select Relationship</option>
                    <option value="SON">Son</option>
                    <option value="DAUGHTER">Daughter</option>
                </Dropdown>
                <ErrorMessage name={`children[${index}].relationship`}>
                    {msg => <Error>{msg}</Error>}
                </ErrorMessage>
                <Footer>
                    <DeleteButton onClick={() => deletePersonnel(remove, index, values.children[index], "children")}>Delete</DeleteButton>
                </Footer>
            </>
        )
    }

    const addingParent = (index, formikProps, { remove }) => {
        const { values } = formikProps
        return (
            <>
                <Ptag fontSize='16px' fontWeight='600' marginTop='4rem' marginBottom="-1rem" marginLeft='5px' letterSpacing='0.44px' color='#4A90E2' letterHeight='19px'>{`PARENT ${index + 1}`}</Ptag>
                <Field
                    name={`parents[${index}].first_name`}
                >
                    {
                        ({ field }) => (
                            <Input
                                {...field}
                                placeholder={'Parent First Name'}
                                value={values.parents[index]['first_name']}
                                color="#4a4a4a"
                                disabled={!employeeId}
                                border="1px solid #979797"
                                errorName={`parents[${index}].first_name`}
                            />
                        )
                    }
                </Field>
                <Field
                    name={`parents[${index}].last_name`}
                >
                    {
                        ({ field }) => (
                            <Input
                                {...field}
                                placeholder={'Parent Last Name'}
                                value={values.parents[index]['last_name']}
                                color="#4a4a4a"
                                disabled={!employeeId}
                                border="1px solid #979797"
                                errorName={`parents[${index}].last_name`}
                            />
                        )
                    }
                </Field>
                <Field
                    name={`parents[${index}].dob`}
                // validate={validateDOB}
                >
                    {
                        ({ field }) => (
                            <DateField
                                {...field}
                                placeholder={'DOB (DD/MM/YYYY)'}
                                value={values.parents[index]['dob']}
                                color="#4a4a4a"
                                disabled={!employeeId}
                                border="1px solid #979797"
                                errorName={`parents[${index}].dob`}
                                onChange={(e) => handleDateChange(e, formikProps, formikProps.values.parents[index]['dob'], `parents[${index}]['dob']`)}
                            />
                        )
                    }
                </Field>
                <Dropdown component="select" name={`parents[${index}].relationship`} disabled={!employeeId}>
                    <option disabled selected value="">Select Relationship</option>
                    <option value={spouseOptions.father}>Father</option>
                    <option value={spouseOptions.mother}>Mother</option>
                </Dropdown>
                <ErrorMessage name={`parents[${index}].relationship`}>
                    {msg => <Error>{msg}</Error>}
                </ErrorMessage>
                <Footer>
                    <DeleteButton onClick={() => deletePersonnel(remove, index, values.parents[index], "parents")}>Delete</DeleteButton>
                </Footer>
            </>
        )
    }

    const removeEmptyFields = (fieldsArr) => {
        const newFieldsArr = [];
        fieldsArr.map(field => {
            if (field.dependent_id) {
                newFieldsArr.push(field);
            } else {
                if (Object.keys(field).every(key => field[key])) {
                    newFieldsArr.push(field);
                }
            }
        })
        return newFieldsArr;
    }

    const handleSubmit = async (data, { setSubmitting }) => {
        const payload = {
            employee_id: employeeId,
            first_name: data.first_name || '',
            last_name: data.last_name || '',
            dob: formatDate(data.dob) || '',
            gender: data.gender || '',
            phone_number: String(data.phone_number) || '',
            email: data.email || '',
            dependent_id: data.dependent_id || null,
        }

        if (data) {
            if (data.children) {
                const validChildren = removeEmptyFields(data.children);
                payload.children = formatArrDate(validChildren);
            }
            if (data.parents) {
                const validParents = removeEmptyFields(data.parents);
                payload.parents = formatArrDate(validParents);
            }
            const deletedSpouse = dependentIdsToDelete.find(spouse => spouse.type === "spouse")
            if (data.spouse && data.spouse.first_name) {
                payload.spouse = {
                    first_name: data && data.spouse && data.spouse.first_name || '',
                    last_name: data && data.spouse && data.spouse.last_name || '',
                    dob: data && data.spouse && formatDate(data.spouse.dob) || '',
                    dependent_id: data && data.spouse && data.spouse.dependent_id || null,
                }
                if (data.spouse.action) {
                    payload.spouse.action = data.spouse.action || actions.update;
                }
            }
            if (deletedSpouse) payload.spouse = {
                ...payload.spouse,
                action: deletedSpouse.action,
                dependent_id: deletedSpouse.dependent_id
            };
            const deletedChildren = dependentIdsToDelete.filter(child => child.type === "children")
                .map(child => ({
                    action: actions.delete,
                    dependent_id: child.dependent_id,
                }));

            const deletedParents = dependentIdsToDelete.filter(parent => parent.type === "parents")
                .map(parent => ({
                    action: actions.delete,
                    dependent_id: parent.dependent_id,
                }));

            if (deletedChildren.length > 0) {
                payload.children = [...payload.children, ...deletedChildren];
            }
            if (deletedParents.length > 0) {
                payload.parents = [...payload.parents, ...deletedParents];
            }
        }
        console.log({ payload })
        console.log({ dependentIdsToDelete }, "SET")
        try {
            setLoading(true);
            await http.post('/business/update_business_employee_data/', payload)
            setDisplaySuccessMsg(true);
        } catch (ex) {
            alert("Some Error Occured!")
        } finally {
            setLoading(false)
            setSubmitting(false)
        }
    }

    const formatArrDate = (arr, reverse) => {
        return arr.map(el => ({
            ...el,
            dob: reverse ? formatDateReverse(el.dob) : formatDate(el.dob)
        }))
    }

    const renderForm = (formikProps) => {
        const { errors, touched, values, handleSubmit } = formikProps
        console.log({ errors })
        return (<>
            <Ptag
                fontSize="26px"
                fontWeight="600"
                marginLeft="1.4rem"
                letterHeight="1.33"
                letterSpacing="0.35px"
            >
                Employee Detail Form
            </Ptag>
            <Line />
            <Form onSubmit={handleSubmit}>
                <Ptag fontSize="20px" lineHeight={40.85} fontWeight='600' color="#436AB2" marginLeft="5px" marginBottom="3rem">
                    Self
                </Ptag>
                <Field
                    name='first_name'
                >
                    {
                        ({ field }) => (
                            <Input
                                {...field}
                                placeholder={'First Name'}
                                value={values['first_name']}
                                color="#4a4a4a"
                                border="1px solid #979797"
                                disabled={!employeeId}
                                error={touched['first_name'] && errors['first_name']}
                            />
                        )
                    }
                </Field>
                <Field
                    name='last_name'
                >
                    {
                        ({ field }) => (
                            <Input
                                {...field}
                                placeholder={'Last Name'}
                                value={values['last_name']}
                                color="#4a4a4a"
                                disabled={!employeeId}
                                border="1px solid #979797"
                                error={touched['last_name'] && errors['last_name']}
                            />
                        )
                    }
                </Field>
                <Field
                    name={'dob'}
                    validate={validateDOB}
                >
                    {
                        ({ field }) => (
                            <DateField
                                {...field}
                                placeholder={'DOB (DD/MM/YYYY)'}
                                value={values.dob}
                                color="#4a4a4a"
                                disabled={!employeeId}
                                border="1px solid #979797"
                                error={touched['dob'] && errors['dob']}
                                onChange={(e) => handleDateChange(e, formikProps, formikProps.values['dob'], 'dob')}
                            />
                        )
                    }
                </Field>
                <Dropdown component="select" name="gender" disabled={!employeeId}>
                    <option disabled selected value="">Select Gender</option>
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                </Dropdown>
                <ErrorMessage name="gender">
                    {msg => <Error>{msg}</Error>}
                </ErrorMessage>
                <Field
                    name='phone_number'
                    validate={validateMobileNumber}
                >
                    {
                        ({ field }) => (
                            <Input
                                {...field}
                                placeholder={'Mobile'}
                                value={values['phone_number']}
                                error={touched['phone_number'] && errors['phone_number']}
                                disabled={!employeeId}
                                color="#4a4a4a"
                                border="1px solid #979797"
                                maxlength={'10'}
                                onChange={(e) => handlePhoneChange(e, formikProps, `phone_number`)}
                                type='number'
                            />
                        )
                    }
                </Field>
                <Field
                    name='email'
                >
                    {
                        ({ field }) => (
                            <Input
                                {...field}
                                placeholder={'Email'}
                                value={values.email}
                                color="#4a4a4a"
                                disabled={!employeeId}
                                border="1px solid #979797"
                                error={touched['email'] && errors['email']}
                            />
                        )
                    }
                </Field>

                {displaySection.spouse &&
                    <>
                        <MemberTag onClick={() => setShowSouseField(showSpouse)}>
                            <Ptag fontSize="20px" lineHeight={40.85} fontWeight='600' color="#436AB2">
                                Add Spouse
                            </Ptag>
                            {employeeId && <Icons icon={!employeeData.spouse.first_name ? 'circularPlus' : showSpouse ? 'chevron_down' : 'chevron_right'} fill='#808080' />}
                        </MemberTag>
                        {showSpouse &&
                            <>
                                <Field
                                    name='spouse.first_name'
                                >
                                    {
                                        ({ field }) => (
                                            <Input
                                                {...field}
                                                placeholder={'Spouse First Name'}
                                                value={values && values.spouse && values.spouse.first_name}
                                                color="#4a4a4a"
                                                border="1px solid #979797"
                                                disabled={!employeeId}
                                                error={touched.spouse && touched.spouse.first_name && errors.spouse && errors.spouse.first_name}
                                            />
                                        )
                                    }
                                </Field>
                                <Field
                                    name='spouse.last_name'
                                >
                                    {
                                        ({ field }) => (
                                            <Input
                                                {...field}
                                                placeholder={'Spouse Last Name'}
                                                value={values && values.spouse && values.spouse.last_name}
                                                color="#4a4a4a"
                                                disabled={!employeeId}
                                                border="1px solid #979797"
                                                error={touched.spouse && touched.spouse.last_name && errors.spouse && errors.spouse.last_name}
                                            />
                                        )
                                    }
                                </Field>
                                <Field
                                    name={'spouse.dob'}
                                // validate={validateDOB}

                                >
                                    {
                                        ({ field }) => (
                                            <DateField
                                                {...field}
                                                placeholder={'DOB (DD/MM/YYYY)'}
                                                value={values.spouse.dob}
                                                color="#4a4a4a"
                                                disabled={!employeeId}
                                                border="1px solid #979797"
                                                error={touched.spouse && touched.spouse.dob && errors.spouse && errors.spouse.dob}
                                                onChange={(e) => handleDateChange(e, formikProps, formikProps.values.spouse['dob'], 'spouse.dob')}
                                            />
                                        )
                                    }
                                </Field>
                                <Footer>
                                    <DeleteButton onClick={() => deletePersonnel(null, null, values.spouse, "spouse", formikProps)}>Delete</DeleteButton>
                                </Footer>
                            </>
                        }</>
                }
                {
                    displaySection.children &&
                    <FieldArray
                        name="children"
                        render={arrayHelpers => (
                            <div>
                                <MemberTag onClick={() => maxLimiter(arrayHelpers, values.children.length, 4, values, "children")}>
                                    <Ptag fontSize="20px" lineHeight={40.85} fontWeight='600' color="#436AB2">
                                        Add Child
                                    </Ptag>
                                    <AddImage src={plus} />
                                </MemberTag>
                                {values.children && values.children.length > 0 && (
                                    values.children.map((child, index) => (
                                        <div key={index}>
                                            {addingChild(index, formikProps, arrayHelpers)}
                                        </div>
                                    )))
                                }
                            </div>
                        )}
                    />}

                {
                    displaySection.parents &&
                    <FieldArray
                        name="parents"
                        render={arrayHelpers => (
                            <div>
                                <MemberTag onClick={() => maxLimiter(arrayHelpers, values.parents.length, 2, values, "parents")}>
                                    <Ptag fontSize="20px" lineHeight={40.85} fontWeight='600' color="#436AB2">
                                        Add Parents
                                    </Ptag>
                                    <AddImage src={plus} />
                                </MemberTag>
                                {values.parents && values.parents.length > 0 && (
                                    values.parents.map((parent, index) => (
                                        <div key={index}>
                                            {addingParent(index, formikProps, arrayHelpers)}
                                        </div>
                                    )))
                                }
                            </div>
                        )}
                    />}

                {employeeId && <ButtonWrapper>
                    <Button
                        id="employee_form_button"
                        label="Submit"
                        type="submit"
                    />
                </ButtonWrapper>}
            </Form>
        </>
        )
    }

    let Schema = Yup.object().shape({
        first_name: Yup.string().required("*Required"),
        dob: Yup.string().required("*Required"),
        last_name: Yup.string().required("*Required"),
        gender: Yup.string().required("*Required"),
        phone_number: Yup.string().required("*Required"),
        email: Yup.string().required("*Required"),
        children: Yup.array(Yup.object().shape({
            first_name: Yup.string(),
            last_name: Yup.string(),
            dob: Yup.string()
                .test('is-valid', 'Invalid date format', function (value) {
                    if (!value) return true;
                    return moment(value, 'DD/MM/YYYY', true).isValid();
                })
                .test('is-past', 'Please select a date that is in the past', function (value) {
                    if (!value) return true;
                    const date = moment(value, 'DD/MM/YYYY', true);
                    return date.isBefore(moment());
                }),
            relationship: Yup.string(),
            action: Yup.mixed().oneOf(Object.values(actions))
        })),
        parents: Yup.array(Yup.object().shape({
            first_name: Yup.string(),
            last_name: Yup.string(),
            dob: Yup.string()
                .test('is-valid', 'Invalid date format', function (value) {
                    if (!value) return true;
                    return moment(value, 'DD/MM/YYYY', true).isValid();
                })
                .test('is-past', 'Please select a date that is in the past', function (value) {
                    if (!value) return true;
                    const date = moment(value, 'DD/MM/YYYY', true);
                    return date.isBefore(moment());
                }),
            relationship: Yup.string(),
            action: Yup.mixed().oneOf(Object.values(actions))
        })),
        spouse: displaySection.spouse && Yup.object().shape({
            first_name: Yup.string(),
            last_name: Yup.string(),
            dob: Yup.string()
                .test('is-valid', 'Invalid date format', function (value) {
                    if (!value) return true;
                    return moment(value, 'DD/MM/YYYY', true).isValid();
                })
                .test('is-past', 'Please select a date that is in the past', function (value) {
                    if (!value) return true;
                    const date = moment(value, 'DD/MM/YYYY', true);
                    return date.isBefore(moment());
                }),
            action: Yup.mixed().oneOf(Object.values(actions))
        }),
    })

    const messageScreen = () => {
        return (
            <SuccessMessageWrapper>
                <div>
                    <Ptag
                        fontSize='32px'
                        fontWeight='600'
                        letterHeight='49px'
                        textAlign='center'
                        marginBottom="2rem"
                    >
                        Thank You
                    </Ptag>
                    <Ptag
                        fontSize='16px'
                        fontWeight='400'
                        letterHeight='30px'
                        textAlign='center'
                    >
                        The form has been successfully submitted and shared with your employer.
                    </Ptag>
                </div>

            </SuccessMessageWrapper>
        )
    }

    const validator = (values) => {
        let errors = {};
        if (values.spouse && displaySection.spouse) {
            let firstNameExist, lastNameExist, dobExist;
            if (values.spouse.first_name) firstNameExist = true;
            if (values.spouse.last_name) lastNameExist = true;
            if (values.spouse.dob) dobExist = true;

            if (firstNameExist || lastNameExist || dobExist) {
                const spouse = {};
                if (!firstNameExist) spouse.first_name = "*Required";
                if (!lastNameExist) spouse.last_name = "*Required";
                if (!dobExist) spouse.dob = "*Required";
                if (Object.keys(spouse).length > 0) {
                    errors = {
                        ...errors,
                        spouse,
                    }
                }
            }
        }
        if (values.children && values.children.length > 0 && displaySection.children) {
            const childErrors = [];
            values.children.forEach((child, index) => {
                let firstNameExist, lastNameExist, dobExist, relationshipExist;
                if (child.first_name) firstNameExist = true;
                if (child.last_name) lastNameExist = true;
                if (child.dob) dobExist = true;
                if (child.relationship) relationshipExist = true;

                if (firstNameExist || lastNameExist || dobExist || relationshipExist) {
                    const child = {};
                    if (!firstNameExist) child.first_name = "*Required";
                    if (!lastNameExist) child.last_name = "*Required";
                    if (!dobExist) child.dob = "*Required";
                    if (!relationshipExist) child.relationship = "*Required";
                    if (Object.keys(child).length > 0) {
                        childErrors[index] = child;
                    }
                } else if (child.dependent_id) {
                    if (!firstNameExist) child.first_name = "*Required";
                    if (!lastNameExist) child.last_name = "*Required";
                    if (!dobExist) child.dob = "*Required";
                    if (!relationshipExist) child.relationship = "*Required";
                    if (Object.keys(child).length > 0) {
                        childErrors[index] = child;
                    }
                }
            })
            if (childErrors.length > 0) {
                errors = {
                    ...errors,
                    children: childErrors
                }
            }
        }
        if (values.parents && values.parents.length > 0 && displaySection.parents) {
            const parentErrors = [];
            values.parents.forEach((parent, index) => {
                let firstNameExist, lastNameExist, dobExist, relationshipExist;
                if (parent.first_name) firstNameExist = true;
                if (parent.last_name) lastNameExist = true;
                if (parent.dob) dobExist = true;
                if (parent.relationship) relationshipExist = true;

                if (firstNameExist || lastNameExist || dobExist || relationshipExist) {
                    const parent = {};
                    if (!firstNameExist) parent.first_name = "*Required";
                    if (!lastNameExist) parent.last_name = "*Required";
                    if (!dobExist) parent.dob = "*Required";
                    if (!relationshipExist) parent.relationship = "*Required";
                    if (Object.keys(parent).length > 0) {
                        parentErrors[index] = parent;
                    }
                } else if (parent.dependent_id) {
                    if (!firstNameExist) parent.first_name = "*Required";
                    if (!lastNameExist) parent.last_name = "*Required";
                    if (!dobExist) parent.dob = "*Required";
                    if (!relationshipExist) parent.relationship = "*Required";
                    if (Object.keys(parent).length > 0) {
                        parentErrors[index] = parent;
                    }
                }
            })
            if (parentErrors.length > 0) {
                errors = {
                    ...errors,
                    parents: parentErrors
                }
            }
        }
        return errors;
    }

    return (
        <>
            {loading ? (<OverlayLoader />) : <Home>
                <HomeWrapperLeft>
                    <NeoLogo>
                        <img src={NeoInsuranceLogo} style={{ width: 145, height: 63 }} />
                    </NeoLogo>
                </HomeWrapperLeft>
                <HomeWrapperRight>
                    {displaySuccessMsg ? messageScreen() : <Formik
                        enableReinitialize={true}
                        initialValues={employeeData}
                        validationSchema={Schema}
                        validate={validator}
                        onSubmit={handleSubmit}
                        render={(formikProps) => renderForm(formikProps)}
                    />}
                </HomeWrapperRight>
            </Home>}
        </>
    )
}

const Home = styled.div`
    width: 100%;
    height:100%;
    margin:0px;
    padding:0px;
`
const AddImage = styled.img`
  display: flex;
  float: right;
  width: 25px;
  height: 25px;
  object-fit: cover;
`

const Button = styled(LargeButton)`
	cursor: ${props => props.disabled ? 'default' : 'pointer'};
	background-color: ${props => props.disabled ? 'rgba(67, 106, 178, 0.5) !important' : theme.primaryColor};
    text-transform: none;
`

const Dropdown = styled(Field)`
    color: #4a4a4a;
    width: 100%;
    font-size: 1.6rem;
    border: none;
    border-bottom: 1px solid #979797;
    padding-left: 5px;
    padding-bottom: 10px;
    margin-top: 4rem;
    outline: none;
`

const HomeWrapperLeft = styled.div`
    display:flex;
    flex-direction: column;
    background: ${theme.primaryColor};
    position: fixed;
    width: 15%;
    height: 100%;
    box-sizing: border-box;
    transition: width .2s ease-in;    
`

const MemberTag = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 6rem;
    margin-left: 5px;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    cursor: pointer;
    width:100%;
    position: relative;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
  margin-top:60px;
`;

const NeoLogo = styled.div`
    width:80%;
    padding:48px 0px 64px 0px;
    align-self:center;
`

const SuccessMessageWrapper = styled.section`
    display: grid;
    place-items: center;
    height: calc(100vh - 100px);
`

const HomeWrapperRight = styled.div`
	margin-left: 15%;
    padding: 50px 80px;
	width:85%;
	height:100%;
	overflow:scroll;
	display: flex;
	flex-direction: column;
	background-color: white;
`

const Line = styled.div`
  min-width: 100%;
  height: 1px;
  background-color: #979797;
  margin-top: 1rem;
  margin-bottom: 4.6rem;
`;

const Error = styled.p`
  color: red;
  font-size: 1.4rem;
  margin-top: 0.8rem;
  z-index: 9;
  position: absolute;
`

const DeleteButton = styled.button`
   background: #F37576;
   border-radius: 10px;
   color: white;
   padding: 1rem 3rem;
   text-transform: uppercase;
   outline: none;
   border: none;
   font-size: 12px;
   cursor: pointer;
`

const Footer = styled.footer`
    margin-top: 3rem;
    display: flex;
    justify-content: flex-end;
`

export default withRouter(EmployeeDetailForm)