import React, { Component,useState, useEffect } from 'react'
import styled from 'styled-components'
import { OverlayLoader } from '../../components'
import { useHistory } from 'react-router-dom'
import http from '../../services/httpService'
import { Ptag } from '../../components/fonts'
import { withRouter } from 'react-router'
// import download from '../../assets/images/download.png'
import download from '../../assets/svgs/downloadNew.svg'
import upload from '../../assets/images/upload.png'
import config from '../../config.json'
import axios from 'axios'


const AddDeleteEmployee = () =>{
    const [loading, setLoading] = useState(false)
    const [uploadErrorData, setUploadErrorData] = useState([])
    // const {type} = useParams()
    const history = useHistory()

    const userplan_id = localStorage.getItem('userplan_id')  || ''
     
    const onUpload = async (oEvent) => {
        setUploadErrorData([])
        let formData = new FormData()
        let validData = {}
        formData.append('file_details', oEvent.target.files[0])
        oEvent.target.value = null
        try {
            setLoading(true)
            formData.append('business_user_id', localStorage.getItem('id'))
            const {data} =  await axios({
                url: config.apiUrl + '/business/validate_employees/',
                method: 'POST',
                data: formData,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                }
            }) 
            validData = data && (data.GMC && data.GMC.input_file_data) || (data.GMC_M && data.GMC_M.input_file_data) || (data.GPA && data.GPA.input_file_data) || (data.GTL && data.GTL.input_file_data)
            localStorage.setItem("endorsment_data", JSON.stringify(data))
        } catch (ex) {
            if(ex.response && (typeof(ex.response.data[0]) === 'object') && Array.isArray(ex.response.data)){
                setUploadErrorData(ex.response && Array.isArray(ex.response.data) && ex.response.data || [])
            }else{
                alert(ex.response.data)
            }
            setLoading(false)
            return
        }
         try {
            
            const {} = await http.post("business/employee/",{
                business_user_id: localStorage.getItem('id'),
                input_file_data: validData
            })
         } finally {
            navigateToNextPage()
         }

    }
    const DownloadExcel = () => {
        let url = "https://getvitalimages.s3.ap-south-1.amazonaws.com/images/Addition%2BDeletion%2BFormat.xlsx"
        window.open(url, true)
    }

    const navigateToNextPage = () => {
       history.push('/business/thankYou') 
    }


    return (
        <>
            <Root>
                <PageBody>
                
                    {loading && <OverlayLoader />}

                    <Body>
                        <div style={{'display': 'flex', 'flexDirection': 'row', 'justifyContent': 'space-between', 'alignItems': 'center', 'marginTop': '20px', 'marginBottom' : '2rem'}}>
                            <Ptag color='#4A4A4A' fontSize='20px' fontWeight='600'>
                                {'Add/ Delete Employees'}
                            </Ptag>
                            <DownloadButton id="business_uploadexcel_download_button" onClick={() => DownloadExcel()}>
                                <img style={{ height:'17px', marginRight:'5px' }} src={download} />
                                <Ptag color='white' textAlign='center' fontSize='14px' fontWeight='600' letterSpacing='1px' letterHeight='19px'>
                                    {'Download Excel Format'}
                                </Ptag>
                            </DownloadButton>
                        </div>

                        <UploadButton id="business_uploadexcel_upload_button" onClick={() => document.getElementById('fileSelect').click()} >
                            <img style={{ 'marginRight': '10px' }} src={upload} height='20px' width='17px' />
                            <input
                                name='upload'
                                id="fileSelect"
                                type="file"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                onChange={onUpload}
                            />
                            <Ptag fontSize='14px' fontWeight='600' letterSpacing='0.44px' letterHeight='19px'>
                                {'Upload file'}
                            </Ptag>
                        </UploadButton>  

                    </Body>
                    {!!uploadErrorData.length &&
                        <ErrorTag>
                            <Ptag color='#F27576' style={{paddingBottom : '12px', height : '19px', fontSize : '14px', fontWeight : '700', marginBottom: '15px'}}>Errors!</Ptag>
                            <div>
                            <table border='0.4px' frame='void' rules='all' style={{width : '100%'}}>
                                <thead>
                                    <tr>
                                        <TableData><Ptag style={{height : '10px', fontWeight :'600', textAlign : 'center'}}>ROW</Ptag></TableData>
                                        <TableData><Ptag style={{height : '10px', fontWeight :'600',  textAlign : 'center'}}>COLUMN</Ptag></TableData>
                                        <TableData><Ptag style={{paddingLeft : '3px', width : '24px', height : '14px', fontWeight :'600'}}>MESSAGE</Ptag></TableData>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                uploadErrorData.map( (error, index) => 
                                    <tr>
                                        <TableData><Ptag textAlign = 'center' style={{fontSize : '12px', fontWeight :'500'}}>{(error && error.Employee_no) || ''}</Ptag></TableData>
                                        <TableData><Ptag textAlign = 'center' style={{fontSize : '12px', fontWeight :'500'}}>{(error && error.column) || ''}  {'  '}</Ptag></TableData>
                                        <TableData><Ptag style={{fontSize : '12px', fontWeight :'600'}}>{(error && error.error) || ''}</Ptag></TableData>
                                    </tr>
                                )}    
                                </tbody>
                            </table>
                            </div>
                        </ErrorTag>
                    }
                </PageBody>
            </Root>
        </>
    )
}
// }

const Root = styled.div`
	width: 100%;
	background-color: #ffffff;
    display: flex;
    flex-direction: column;
  
`
const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    flex: 1;
`
const PageBody = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    flex: 1;
`

const ErrorMessage = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    flex: 1;
    border: 1px solid #436AB2;
`

const AddMmanually = styled.div`
    display: flex;
    justify-content: center;
    align-items:center;
    border: 1px solid #436AB2;
    border-radius: 10px;
    margin-top: 24px;
    padding: 20px;
    cursor: pointer;
`

const DownloadButton = styled.div`
    display: flex;
    justify-content: center;
    align-items:center;
    background-color: #0054E3;
    border-radius: 10px;
    /* margin: 24px 0px 24px 0px; */
    padding: 20px;
    height: 36px;
    cursor: pointer;
    &:hover {
      transition: transform 0.2s ease-in-out;
      transform: scale(1.005);
    }
    max-width: 40%;
`

const UploadButton = styled.div`
    display: flex;
    border: 1px 1px solid #E4E4E4;
    box-shadow: 0px 0px 6px rgba(74, 74, 74, 0.19);
    border-radius: 10px;
    padding: 48px;
    justify-content: center;
    cursor: pointer;
    &:hover {
        transition: transform 0.2s ease-in-out;
        background-color: #F9FCFF;
        transform: scale(1.005);
    }

    & > input{
        display: none;
    }   
`

const ErrorTag = styled.div`
  padding: 24px;
  background-color: #F8F8F8;
  border-radius: 10px;
  margin-bottom: 10px;
`

const TableData = styled.td`
    padding: 10px;

`






export default withRouter(AddDeleteEmployee)