import React from 'react'
import { Field } from 'formik'
import DropDownSmartSearch from './dropDownSmartSearch'

const RenderDropDownSmartSearch = ({
  name,
  validate = null,
  selectedOption = { id: '0', label: '' },
  ...restProps
}) => (
  <Field
    name={name}
    validate={validate}
    render={({ field }) => <DropDownSmartSearch selectedOption={selectedOption} {...restProps} />}
  />
)

export default RenderDropDownSmartSearch
