import React, { Component } from 'react'
import styled from 'styled-components'
import {Header} from '../../components/business'
import {Ptag} from '../../components/fonts'
import Text from '../../utils/bhartiTnc'

const BhartiTnC = ({...props}) => {
    return(
        <Root>
            <Header onClickBack={() => props.history.push('/business/insureplansummary')}  />
            <Body>
                <Ptag fontSize='20px' letterHeight='2.7' letterSpacing='0.5px' fontWeight='600' fontFamily='Proxima Nova'>
                    {'Terms and Conditions'}
                </Ptag>
                {
                    Text['tnc'].map(item => (
                        <BulletPoints>
                            <Bullet/>
                            <Ptag color='#666666' fontSize='14px' letterSpacing='0.5px' >
                                {item}
                            </Ptag>
                        </BulletPoints>
                    ))
                }
                <Ptag style={{'margin': '18px 0 6px 0'}} fontSize='16px' letterSpacing='0.57px' fontWeight='600' color='#666666' >
                    {'Special Note: '}
                </Ptag>

                <BulletPoints>
                    <Bullet/>
                    <Ptag color='#666666' fontSize='14px' letterSpacing='0.5px' >
                        {Text['tnc']}
                    </Ptag>
                </BulletPoints>

                {/* <Ptag style={{'margin': '12px 0 6px 0'}} fontWeight='600' color='#666666' fontSize='14px' letterSpacing='0.5px' >
                    {'Addition and Deletion of employees and dependents'}
                </Ptag> */}

                {/* <Ptag style={{'marginBottom': '12px'}} color='#666666' fontSize='14px' letterSpacing='0.5px' >
                    {Text['addition']}
                </Ptag>
                {
                    Text['additionPoints'].map(item => (
                        <BulletPoints>
                            <Bullet/>
                            <Ptag color='#666666' fontSize='14px' letterSpacing='0.5px' >
                                {item}
                            </Ptag>
                        </BulletPoints>
                    ))
                } */}
            </Body>
            
        </Root>
    )
} 

export default BhartiTnC

const Root = styled.div`
    display: flex;
    flex-direction: column;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
`

const BulletPoints = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
`

const Bullet = styled.div`
    display: flex;
    min-width: 4px;
    min-height: 4px;
    max-height: 4px;
    max-width: 4px;
    background-color: #4a4a4a;
    border-radius: 4px;
    margin: 8px 6px 0 0;
`