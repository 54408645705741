import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import {Header} from '../../components/business'
import http from '../../services/httpService'
import PaymentAccordion from '../../components/memberApp/payment/BusinessPaymentAccordion.js'
import OverlayLoader from '../../components/overlayLoader'
import {LargeButton} from '../../components'
import { formatAmount } from '../../utils/utils'
import { Ptag } from '../../components/fonts'
import TagManager from 'react-gtm-module'
import {gtmCode} from '../../utils/utils'


export default class MakePayment extends Component {
  state = {
    netBanking: false,
    debitCard: true,
    method: 2,
    data: {},
    options: {},
    loading: true,
    paymentOption1: true,
    voucherError: '',
    companyName: ''
  }

  componentDidMount = async () => {
    const tagManagerArgs = {
      gtmId: gtmCode(),
    }
    window.scrollTo(0,0)
    const about = JSON.parse(localStorage.getItem('about'))
    const company = about['name']
    TagManager.initialize(tagManagerArgs)
    const userplan_id = localStorage.getItem('userplan_id')
    const {data} = await http.get(`/business/userplans/${userplan_id}/`)
    const Amount = (data['insurance_premium'] || 0 ) + (data['add_ons_premium'] || 0 ) + (data['add_ons_tax'] || 0) + (data['insurance_tax'] || 0)
    this.setState({ data, loading: false , Amount, company})
  }

  onClickNonRecurring = () => {
    this.handleSubmit({
      method: 0,
      plan: 0
    })
  }

  handleSubmit = async values => {

    this.setState({ loader: true })
    const plan = localStorage.getItem('userplan_id')
    await localStorage.setItem('premium', this.state.Amount)
    let body = {
      userplan: plan,
      payment: {
        method: values['method'],
        plan: values['plan']
      }
    }
    if (values.method === 1) {
      body['payment'].bank = values.bank_name
      //body['payment'].account_name = values.payment.branchname
      body['payment'].account_number = values.payment.account
      body['payment'].ifsc = values.payment.ifsc
    }
    const _this = this
    try {
      let response = await http.post('/business/subscription/', { ...body })
    //   await localStorage.setItem('vital_subscription_id', response.data.vital_subscription_id)

      const options = {
        async handler(response) {
          await http.post('/payment/verify/', {
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            razorpay_order_id: response.razorpay_order_id
          })
          _this.props.history.push('/business/paymentsuccess')
        },
        ...response.data
      }
      await this.setState({ loader: false })
      const rzp = new window.Razorpay(options)
      rzp.open()
    } catch (ex) {
      console.log('Error-Ex: ', ex)
      if (ex.status) console.log('Error-Ex-status: ', ex.status)
    }
  }

  render = () => (
    <Home>
      <Header  onClickBack={()=> this.props.history.push('/business/insureplansummary')}/>


      <Ptag style={{margin: '48px 0 4px 28px'}}  letterHeight='2.5' letterSpacing='0.86px'>
        {'NAME OF THE COMPANY'}
      </Ptag>
      <Ptag style={{ margin: '0 0 0 28px' }} textTransform='uppercase' fontSize="24px" fontWeight="600" letterSpacing='1.71px' letterHeight='1.71'>
          {this.state.company}
        </Ptag>

      <Ptag style={{margin: '24px 0 4px 28px'}}  letterHeight='2.5' letterSpacing='0.86px'>
        {'NOW PAYING'}
      </Ptag>
      <Ptag style={{ margin: '0 0 0 28px' }} fontSize="24px" fontWeight="600" letterSpacing='1.71px' letterHeight='1.71'>
          {`₹${formatAmount(this.state.Amount)}`}
        </Ptag>

      <PaymentMethodsWrapper>
        <Ptag style={{ marginBottom: '16px' }} fontSize="20px" fontWeight="600" color="#336188">
          {'Payment Method'}
        </Ptag>

        <PaymentAccordionWrapper>
          <AccordionHeader onClick={() => this.setState({paymentOption1: !this.state.paymentOption1})} >
            <Ptag fontSize='16px' letterSpacing='1px' fontWeight='600'>
              {'AUTO DEBIT'}
            </Ptag>
            <RecommendedTag>
              {'RECOMMENDED'}
            </RecommendedTag>
          </AccordionHeader>

          <PaymentAccordionBody view={this.state.paymentOption1 ? 'flex' : 'none'} >
            <Ptag style={{ 'marginBottom': '24px'}} >
            {`₹${this.state.Amount} will be charged now and up to ₹${this.state.Amount} will be auto-debited every month.`}
            </Ptag >
              
            <PaymentAccordion
              onPay={values => this.handleSubmit(values)}
              premium={this.state.Amount}
              discount={this.state.Amount}
            />
          </PaymentAccordionBody>
          
        </PaymentAccordionWrapper>

        {/* SELF DEBIT */}
        <PaymentAccordionWrapper>
          <AccordionHeader onClick={() => this.setState({paymentOption2: !this.state.paymentOption2})}>
            <Ptag fontSize='16px' letterSpacing='1px' fontWeight='600'>
              {'SELF DEBIT'}
            </Ptag>
          </AccordionHeader>

          <PaymentAccordionBody view={this.state.paymentOption2 ? 'flex' : 'none'} >
            <Ptag style={{'marginBottom': '24px'}}>
             {`₹${this.state.Amount}  will be charged now and up to ₹${this.state.Amount} has to be paid every subsequent month until policy expiration.`}
            </Ptag>
            <LargeButton
              onClick={this.onClickNonRecurring}
              label={'PAY NOW'}
            />
          </PaymentAccordionBody>
          
        </PaymentAccordionWrapper>
        
      </PaymentMethodsWrapper>

      {this.state.loading && <OverlayLoader />}
      {/* </BodyWrapper> */}
    </Home>
  )
}

const Home = styled.div`
  display: flex;
  flex-direction: column;
`


const PaymentMethodsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 24px;
`

const PaymentAccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: solid 1px #436ab2;
  margin-top: 24px;
`

const AccordionHeader = styled.div`
  display: flex;
  padding: 18px 15px;
  align-items: center; 
  cursor: pointer;
`


const RecommendedTag = styled.div`
  font-family: Avenir;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1px;
  color: #fff;
  padding: 6px 10px;
  border-radius: 5px;
  background-color: #009999;
  margin-left: 10px;
`

const PaymentAccordionBody = styled.div`
  display: ${props => props.view};
  flex-direction: column;
  padding: 24px 16px 24px 16px;
  border-top: solid 1px #9b9b9b;
`
