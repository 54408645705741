export const RelationShipOptions = [
  { id: '0', label: 'Self', value: 'SELF' },
  { id: '1', label: 'Father', value: 'FATHER' },
  { id: '2', label: 'Mother', value: 'MOTHER' },
  { id: '3', label: 'Spouse', value: 'SPOUSE' },
  { id: '4', label: 'Daughter', value: 'DAUGHTER' },
  { id: '5', label: 'Son', value: 'SON' },
  { id: '6', label: 'Father-In-Law', value: 'FATHERINLAW' },
  { id: '7', label: 'Mother-In-Law', value: 'MOTHERINLAW' }
]
