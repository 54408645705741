import React, { Component } from 'react'
import styled from 'styled-components'
import OtpInput from 'react-otp-input'
import http from '../../services/httpService'
import CountDown from '../../components/countDown'
import LargeButton from '../../components/LargeButton'
import { timeOut } from '../../constants/timeouts'
import { OverlayLoader } from '../../components'
import {throttle} from '../../utils/utils'
import TagManager from 'react-gtm-module'
import {gtmCode} from '../../utils/utils'
import {env} from '../../config.json'
import { Ptag } from '../../components/fonts'

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`

const OtpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 8rem 3rem 3rem 3rem;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.32);
  background-color: ${props => props.theme.primaryColor};
`

const H1 = styled.h1`
  font-size: 2.2rem;
  font-weight: bold;
  font-style: normal;
  font-family: "Avenir";
  font-stretch: normal;
  color: #ffffff;
  line-height: 1.25;
  letter-spacing: 0.3px;
  margin-bottom: 1.2rem;
`
const PhoneWrapper = styled.pre`
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  font-family: "Avenir";
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};
  font-style: normal;
  font-stretch: normal;
  text-align: ${props => (props.textAlign ? props.textAlign : 'none')};
  line-height: 1.71;
  letter-spacing: 0.3px;
  color: ${props => (props.color ? props.color : '#ffffff')};
  opacity: ${props => (props.opacity ? props.opacity : '1')};
`

const Disclaimer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-end;
`

const Font14 = styled.p`
  font-size: 1.4rem;
  font-family: "Avenir";
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 'normal')};
  font-style: normal;
  font-stretch: normal;
  text-align: ${props => (props.textAlign ? props.textAlign : 'none')};
  line-height: 1.71;
  letter-spacing: 0.3px;
  cursor: pointer;
  color: ${props => (props.color ? props.color : '#ffffff')};
  opacity: ${props => (props.opacity ? props.opacity : '1')};
`

const ReSendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;

  & > :first-child {
    margin-bottom: 3.5rem;
  }
  & > :nth-child(2) {
    margin-bottom: 2rem;
  }
  & > :nth-child(3) {
    margin-bottom: 2rem;
  }
`

const ConfirmButton = styled.div`
  display: flex;
  width: 100%;
  opacity: ${props => props.opacity};
  padding: 20px 0;
  justify-content: center;
  border-radius: 15px;
  background-color: #436ab2;
  color: #fff;
  font-family: "Avenir";
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.43px;
  text-align: center;
  color: #ffffff;
`

const PopUp = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 21px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.15);
  border-radius: 15px 15px 15px 15px;
  background-color: #ffffff;
`

const IconWrapper = styled.div`
  width: 100%;
  float: right;
`

const ErrorWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 2rem;
`

const EditNumberPopup = styled.div`
  width: 100%;
  display: block;
  padding: 0 1.2rem;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 9;
  transform: ${props => (props.showEditField === true ? 'translateY(0) scaleY(100%)' : 'translateY(102%) scaleY(0)')};
  transition: all 0.6s;
`

export default class Otp extends Component {
  state = {
    reSend: false,
    mounted: false,
    navigationContext: null
  }
  otp = ''
  e_category = this.props.location.pathname

  constructor(props){
    super(props)
    this.submitOtp = throttle(this.submitOtp.bind(this), 2000)
    this.reSendOtp = throttle(this.reSendOtp.bind(this), 2000)
  }

  componentDidMount = () => {
    const tagManagerArgs = {
      gtmId: gtmCode(),
    }
    TagManager.initialize(tagManagerArgs)
    this.setState({ navigationContext: localStorage.getItem('navigatingFrom') })
    setTimeout(() => this.setState({ reSend: true }), timeOut.resendOTP * 1000)
    let phoneCode = localStorage.getItem('phone')
    if (phoneCode) {
      this.setState({ phoneCode: '+91 ' + phoneCode.toUpperCase() })
    }
    window.document.querySelector('input').focus()
  }

  redirectToPage = data => {
    if (data.profile.stage === 0) {
      this.props.history.push('/family')
    } else {
      this.props.history.push('/dashboard')
    }
  }

  submitOtp = async () => {
    const _this = this
    if ( this.otp.length !== 6) return
    this.setState({loading:true})
    const otp = this.otp
    if (otp.length === 6) {
      try {
        let action
        if (localStorage.getItem('navigatingFrom') === 'login') {
          action = 'LOGIN'
        } else {
          action = 'REGISTER'
        }

        const { data } = await http.post('/phone/verify_otp/', {
          phone: localStorage.getItem('phone'),
          otp: otp,
          action: action,
          business: true
        })
        if (localStorage.getItem('navigatingFrom') === 'login') {
          const about ={
            name: data['company'] && data['company']['company_name'],
            pincode:  data['company'] && data['company']['pincode'],
            numberOfEmployees: data['company'] && data['company']['number_of_employees'],
            email: data['email'],
          }
          localStorage.setItem('uuid', data.id)
          const options = ["GMC", "GMC_M", "GPA", "GTL"];
          if(data.company.flex) {
            console.log("IN")
            localStorage.setItem("isFlex", true);
          } else if(options.includes(data.b_type)) {
            localStorage.setItem("flow", data.b_type);
          }
          localStorage.setItem('about',JSON.stringify(about))
          localStorage.removeItem('navigatingFrom')
          localStorage.setItem('marketplace_city',data['marketplace_city'])
          localStorage.setItem('businessAccount',true)
          localStorage.setItem('companyId',data['company'] && data['company']['id'])
          const loggingInFor = localStorage.getItem('logginginfor')
          if (data['company'] && data['company']['userplan_id']){
            localStorage.setItem('userplan_id',data['company']['userplan_id'])
            localStorage.setItem('token', data.token)
            if (loggingInFor === 'insurancepayment'){
              this.props.history.push('/business/payment')
              return
            }
            this.props.history.push('/business/dashboard')
          } else if(data['company'] && !data['company']['userplan_id'] && data['company']['employee_collection_required']) {
            localStorage.setItem('token', data.token)
            this.props.history.push('/business/employee-registration-dashboard')
          } else{
            this.setState({loading:false, error: 'User plan not found' })
          }
        } else {
          if(env === 'prod'){
            const tagManagerArgs1 = {
              gtmId: gtmCode(),
              dataLayer: {
                event: "CompleteRegistration_business",
              }
            }
            TagManager.initialize(tagManagerArgs1)
          }
          localStorage.setItem('marketplace_city',data['marketplace_city'])
          sessionStorage.setItem('token', data.token)
          localStorage.setItem('uuid', data.id)
          localStorage.removeItem('navigatingFrom')
          localStorage.setItem('businessAccount',true)
          localStorage.setItem('companyId',data['company'] && data['company']['id'])
          if (data['company'] && data['company']['offline_quote']){
            this.props.history.push('/business/offlinequotes')
            localStorage.setItem('offlineQuote',true)
          } else {
            this.props.history.push('/business/memberstype')
          }
        }
      } catch (ex) {
        await this.setState({loading:false })
        console.log(ex)
        if (ex.response &&  ex.response.status === 400) this.setState({ error: ex.response &&  ex.response.data })
      }
    }
    
  }

  handleSubmit = async otp => {
    this.otp = otp
    if(this.otp.length === 6) {
      this.submitOtp();
    }
  }

  reSendOtp = async () => {

    if (!this.state.reSend) 
    return

    let data = ['', '', '', '', '', '', '']

    this.inputTitle.state.otp = data
    this.inputTitle.state.activeInput = 0
    this.setState({ reSend: false })
    setTimeout(() => 
      this.setState({ reSend: true }),
    timeOut.resendOTP * 10000)
    let resp
    if (localStorage.getItem('navigatingFrom') === 'login') {
      resp = await http.post('/phone/send_otp/', {
        phone: localStorage.getItem('phone'),
        action: 'LOGIN',
        resend:true,
        business: true
      })
    } else {
      resp = await http.post('/phone/send_otp/', {
        phone: localStorage.getItem('phone'),
        action: 'REGISTER',
        resend:true,
        business: true
      })

    }
  }

  render() {
    return (
      <HomeWrapper>
        <OtpWrapper>
          <H1>enter OTP to create your account.</H1>
          <Font14>
            Don’t worry we will never spam you. <br />
          </Font14>
          <PhoneWrapper>
            SMS sent to <strong>{this.state.phoneCode}.{' '}</strong>
            {/* {this.state.navigationContext === 'login' && <u onClick={() => this.onClickEdit()}>EDIT</u>} */}
          </PhoneWrapper>
          <ReSendWrapper>
            <OtpInput
              ref={el => (this.inputTitle = el)}
              onChange={this.handleSubmit.bind(this)}
              numInputs={6}
              isInputNum={true}
              shouldAutoFocus={true}
              inputStyle={{
                width: '2.7rem',
                height: '4rem',
                margin: '0 1rem',
                fontSize: '2.5rem',
                outline: 'none',
                border: 'none',
                borderRadius: 0,
                borderBottom: '2px solid #ffffff',
                background: 'transparent',
                color: '#fff'
              }}
            />
            {/* <LargeButton
              id='business_otp_verify_button'
              style={{ width: '100%' }}
              label="VERIFY"
              bgColor="#436ab2"
              textColor={'#fff'}
              borderColor={'#fff'}
              borderRadius="1.5rem"
              padding="1rem"
              onClick={() => this.submitOtp()}
            /> */}
            <CountDown active={!this.state.reSend} />
            {
              this.state.reSend && 
              <div>
                <Font14 textAlign="center" color={'#fff'}>
                  Didn’t get Verification code?
                </Font14>
                <div style={{marginTop: "2rem"}}>
                  <LargeButton
                    style={{ width: '100%' }}
                    label="RESEND OTP"
                    bgColor="transparent"
                    textColor={'#fff'}
                    borderColor={'#fff'}
                    borderRadius="1.5rem"
                    padding="1rem"
                    onClick={this.reSendOtp}
                  />
                </div>
                {/* <Font14
                  opacity={this.state.reSend ? '1' : '0.4'}
                  fontWeight="bold"
                  textAlign="center"
                  color={'#fff'}
                  onClick={this.reSendOtp}>
                  Resend OTP
                </Font14> */}
              </div>
            }
            
            {this.state.error && (
              <ErrorWrapper>
                <Font14 color="red">{this.state.error}</Font14>
              </ErrorWrapper>
            )}
          </ReSendWrapper>
          {/* {
            localStorage.getItem('navigatingFrom') &&
            <Disclaimer>
              <Ptag fontSize="12px" color="#ffffffb5" letterHeight="18px">
                Disclaimer: I authorize Neo Insurance Broking to contact me for any important information regarding my plan or account.
              </Ptag>
            </Disclaimer>
          } */}
        </OtpWrapper>
        {this.state && this.state.loading && <OverlayLoader />}
      </HomeWrapper>
    )
  }
}
