import React, { useState, useEffect, useCallback, useMemo } from "react"
import styled from "styled-components";
import { Field, Form, Formik } from "formik";
import { theme } from "../../theme";
import { EmployeeTypeSelectionCard, LargeButton, EmployeeRegistrationDashboardEmployeeDetail, EmployeeRegistrationDashboardHeader, EmployeeRegistrationDashboardEmployeePreview, OverlayLoader } from "../../components";
import { Ptag } from "../../components/fonts";
import * as Yup from "yup";
import downloadIcon from "../../assets/svgs/download_white.svg";
import http from '../../services/httpService';
import { formatDate, getFirstLastName, openLinkInNewTab } from "../../utils/utils";
import moment from "moment";

const EXCEL_TEMPLATE = "https://getvitalimages.s3.ap-south-1.amazonaws.com/images/Intermediatedata+(5).xlsx";
const employeeTypes = ["E", "ESC", "ESCP"];

const EmployeeRegistrationDashboard = () => {
	const [selectedType, setSelectedType] = useState(null);
	const [showTypeError, setShowTypeError] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [employeeData, setEmployeeData] = useState({
		collection_percentage: 0,
		dependent_type: "",
		cutoff_date: "",
		hr_email: "",
		employees_data: []
	});
	const [error, setError] = useState(false);

	const doesEmployeeDataExist = useMemo(() => (
		employeeTypes.includes(employeeData.dependent_type)
	), [employeeData.dependent_type]);

	useEffect(() => {
		const phone = localStorage.getItem("phone") || "";
		(async function () {
			const config = {
				data: {
					phone
				}
			}
			try {
				const { data } = await http.get("business/get_business_employees_intermediate_data/", config);
				console.log(!data.total_employees, "EMP")
				if(!data.total_employees) {
					return;
				}
				setEmployeeData(data);
			} catch (_) {
				setError(true);
			} finally {
				setIsLoading(false)
			}
		})()
	}, [])

	const exportCsv = () => {
		let csvStr = '"Employee First Name*","Employee Last Name*","Email*","Gender*","Employee DOB","Mobile","Dependents","Dependent 1",,,,"Dependent 2",,,,"Dependent 3",,,,"Dependent 4",,,,"Dependent 5",,,,"Dependent 6"\n,,,,,,,"Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB"\n'
		employeeData.employees_data.forEach(item => {
			csvStr = csvStr + (getFirstLastName(item.name).firstName || '') + ','
			csvStr = csvStr + (getFirstLastName(item.name).lastName || '') + ','
			csvStr = csvStr + (item.email || '') + ','
			csvStr = csvStr + (item.gender || '') + ','
			csvStr = csvStr + (item.dob || '') + ','
			csvStr = csvStr + (item.phone || '') + ','
			csvStr = csvStr + (item.no_of_dependents || 0) + ','
			for (let i = 0; i < item.dependents.length; i++) {
				const role = item.dependents[i]
				csvStr = csvStr + (role.relation || '') + ','
				csvStr = csvStr + (role.first_name || '') + ','
				csvStr = csvStr + (role.last_name || '') + ','
				csvStr = csvStr + (role.dob || '') + ','
			}
			csvStr = csvStr + '\n'
		});

		const element = document.createElement("a");
		const fileOutput = csvStr
		const file = new Blob([fileOutput], { type: 'text/plain' });
		element.href = URL.createObjectURL(file);

		element.download = 'Employee_Details.csv';
		document.body.appendChild(element); // Required for this to work in FireFox
		element.click();
		element.parentNode.removeChild(element);
	}

	const sendReminderHandler = useCallback(async (isReminder) => {
		const payload = {
			is_reminder: isReminder,
		}
		const { data } = await http.post("business/send_employee_emails/", payload)
	}, [employeeData.dependent_type])

	const employeeOptionHandler = (type) => {
		setShowTypeError(false);
		setSelectedType(type)
	}

	const handleDateChange = (e, setFieldValue, fieldName, variable) => {
		const caretLocation = e.target.selectionStart;
        const value = e.target.value

        let newDate = value
        let prevDateLen = fieldName.length
        let newDateLen = newDate.length
        let newDob = newDate
        if (newDateLen < prevDateLen && caretLocation !== 2 && caretLocation !== 5) {
            newDob = fieldName.substr(0, caretLocation) + fieldName.substr(caretLocation + 1, newDateLen)
        }
        if (newDateLen <= 10) {
            if (caretLocation === 2 || caretLocation === 5) {
                newDob = newDate + '/'
                if (newDateLen < prevDateLen) {
                    newDob = fieldName.substr(0, caretLocation);
                }
            } 
        } else {
            newDob = newDate.slice(0, 10)
        }
		if(newDateLen === 10) {
			newDob = moment(newDob, "DD/MM/YYYY").format("DD/MM/YYYY");
		}
        setFieldValue(variable, newDob)
	};

	const updateEmployee = async (values) => {
		if (!selectedType) {
			setShowTypeError(true);
			return;
		}
		const payload = {
			...values,
			cutoff_date: formatDate(values.cutoff_date),
			dependent_type: selectedType
		}
		const options = {
			headers: {
				'Content-Type': 'multipart/form-data',
			}
		}
		const formData = new FormData();
		Object.entries(payload).forEach(([key, value]) => {
			formData.append(key, value)
		})
		try {
			setIsLoading(true);
			const { data } = await http.post('business/update_business_employees_by_hr/', formData, options)
			if (data) {
				await sendReminderHandler(false);
			}
		} catch(err) {
			alert(err.response.data[0].error);
		} finally {
			setIsLoading(false);
		}
		window.location.reload()
	}

	return <>
		{isLoading ? (<OverlayLoader />) : (
			(<>
				<Container>
					<EmployeeRegistrationDashboardHeader doesEmployeeDataExist={doesEmployeeDataExist} />
					<Line />
					{doesEmployeeDataExist ? (
						<EmployeeRegistrationDashboardEmployeePreview
							employeeData={employeeData}
							sendReminderHandler={() => sendReminderHandler(true)}
							doesEmployeeDataExist={doesEmployeeDataExist}
							exportCsv={exportCsv}
						/>
					) : <>
						<Ptag fontSize="20px" lineHeight="26x" fontWeight="500" marginBottom="2rem">Select Dependent Type</Ptag>
						<OptionContainer>
							<>
								{
									employeeTypes.map(type => (
										<EmployeeTypeSelectionCard
											key={type}
											isSelected={selectedType === type}
											type={type}
											employeeOptionHandler={() => employeeOptionHandler(type)}
										/>
									))
								}
								{showTypeError && <InputWarning style={{ position: 'absolute', bottom: '-2rem' }}>*Required</InputWarning>}
							</>
						</OptionContainer>
						<Formik
							initialValues={{ hr_email: '', cutoff_date: '', file_details: '' }}
							validateOnBlur
							onSubmit={updateEmployee}
							validationSchema={Yup.object({
								hr_email: Yup.string()
									.email('Invalid email address')
									.required('*Required'),
								cutoff_date: Yup.string()
									.test('is-valid', 'Invalid date format', function (value) {
										return moment(value, 'DD/MM/YYYY', true).isValid();
									})
									.test('is-future', 'Please select a date that is in future', function (value) {
										const date = moment(value, 'DD/MM/YYYY', true);
										return date.isAfter(moment());
									})
									.required('*Required'),
								file_details: Yup.mixed()
									.required("*Required")
							})}
							render={({ values, setFieldValue, errors, touched }) => (
								<Form>
									<FormWrapper>
										<InputWrapper flex="1 1 0px">
											<Label htmlFor="cutoff_date">Set Cut-off Date for Data Collection</Label>
											<Field
												name="cutoff_date"
												render={({ field }) => (
													<Input
														{...field}
														onChange={(event) => handleDateChange(event, setFieldValue, values['cutoff_date'], 'cutoff_date')}
														type="text"
														placeholder="DD/MM/YYYY"
														value={field.value}
													/>
												)}
											/>
											{touched.cutoff_date && errors.cutoff_date && <InputWarning>{errors.cutoff_date}</InputWarning>}
										</InputWrapper>
										<InputWrapper flex="2 1 0px">
											<Label htmlFor="email">Enter Email Id</Label>
											<Field
												name="hr_email"
												render={({ field }) => (
													<Input
														{...field}
														type="email"
														placeholder="Admin Email Id"
													/>
												)}
											/>
											{touched.hr_email && errors.hr_email && <InputWarning>{errors.hr_email}</InputWarning>}
										</InputWrapper>
									</FormWrapper>
									<FileDownloadContainer>
										<InputWrapper style={{ width: '50%' }}>
											<Label htmlFor="file_details">Upload Employee Information</Label>
											<Field
												name="file_details"
												render={({ field }) => (
													<FileInput
														{...field}
														type="file"
														value={undefined}
														accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
														placeholder="Select File"
														onChange={(event) => {
															setFieldValue("file_details", event.currentTarget.files[0]);
														}}
													/>
												)}
											/>
											{touched.file_details && errors.file_details && <InputWarning>{errors.file_details}</InputWarning>}
										</InputWrapper>
										<DownloadExcel onClick={() => openLinkInNewTab(EXCEL_TEMPLATE)}>
											<img src={downloadIcon} width='20px' height='20px' style={{ marginRight: 10 }} />
											<Ptag fontSize="14px" fontWeight="600" color="white" lineHeight="24px" letterSpacing="0.5px">Download Excel Format</Ptag>
										</DownloadExcel>
									</FileDownloadContainer>
									<SubmitButtonWrapper>
										<LargeButton label="Submit" color="#fff" type="submit" />
									</SubmitButtonWrapper>
								</Form>
							)}
						/>
					</>}
				</Container>

				{!doesEmployeeDataExist && <Container marginTop="2rem">
					<Ptag
						fontSize="24px"
						lineHeight="32px"
						fontWeight="700"
						marginBottom="2rem"
					>
						Employee Data
					</Ptag>
					<Line margin="0" />
					<EmployeeRegistrationDashboardEmployeeDetail
						employeeData={employeeData}
						relevant_employees={employeeData.relevant_employees}
						total_employees={employeeData.total_employees}
						sendReminderHandler={sendReminderHandler}
						doesEmployeeDataExist={doesEmployeeDataExist}
						exportCsv={exportCsv}
					/>
				</Container>}
			</>)
		)}
	</>
}

const Container = styled.section`
	transition: margin-left .2s ease-in;
	padding: 30px;
	margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)};
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	background-color: white;
	width: 100%;
`

const OptionContainer = styled.ul`
	position: relative;
	list-style-type: none;
	display: flex;
	flex-flow: row wrap;
	gap: 2rem;
	margin-bottom: 4rem;
`

const Line = styled.div`
	width: 100%;
	height: 1px;
	margin: ${props => props.margin ? props.margin : '1rem 0 3rem 0'};
	background-color: #436AB2;
`

const Input = styled.input`
	border: 1px solid #436AB2;
	padding: 14px 24px;
	border-radius: 8px;
	box-sizing: border-box;
	font-size: 16px;
	font-family: 'Avenir';
	color: #4A4A4A;
	vertical-align: baseline;
	min-height: 5rem;
	&::placeholder {
		color: #4A4A4A;
	}
`

const FileInput = styled(Input)`
	cursor: pointer;
	&::file-selector-button {
		display: none;
	}
`

const InputWrapper = styled.section`
    min-width: 50%;
	display: flex;
	flex-direction: column;
	flex: ${props => props.flex ? props.flex : null}
`

const InputWarning = styled.p`
	color: red;
    font-size: 1.4rem;
    margin-top: 0.8rem;
`

const FormWrapper = styled.section`
	display: flex;
	gap: 2rem;
	margin-bottom: 3rem;
	max-width: 80%;
`

const Label = styled.label`
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 2rem;
    font-family: 'Avenir';
	color: #4a4a4a;
`

const FileDownloadContainer = styled.section`
	display: flex;
	align-items: flex-end;
	margin-bottom: 4rem;
`

const SubmitButtonWrapper = styled.section`
	width: 80%;
`

const DownloadExcel = styled.button`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 12px 26px;
  background-color:${theme.accentColor};
  border-radius: 1.2rem;
  cursor: pointer;
  border: none;
  margin-left: 2rem;
`


export default EmployeeRegistrationDashboard;