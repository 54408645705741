import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Ptag } from './fonts'
import { Icons, LargeButton } from './'
import { inDesktopView } from './hoc'

const HealthQuestionCard = ({ sequence, question, submitResponses, initialResponses = {}, relationship = 'self' }) => {

    const [questionsShowed, setQuestionsShowed] = useState([])
    const [responses, setResponses] = useState({})
    const [error, setError] = useState({})


    const getGender = (relationShip) => {
        const selfGender =
            (localStorage.getItem("userGender") &&
                localStorage.getItem("userGender").toUpperCase()) ||
            "male";
        // console.log('selfGender: ', selfGender)
        // console.log('RelationShip: ', relationShip)

        if (
            relationShip === "son" ||
            relationShip === "father" ||
            relationShip === "fatherinlaw" ||
            (relationShip === "self" && selfGender === "male") ||
            (relationShip === "spouse" && selfGender === "female")
        ) {
            return 'male';
        } else if (
            relationShip === "daughter" ||
            relationShip === "mother" ||
            relationShip === "motherinlaw" ||
            (relationShip === "self" && selfGender === "female") ||
            (relationShip === "spouse" && selfGender === "male")
        ) {
            return 'female';
        } else {
            return 'male';
        }
    };
    useEffect(() => {

        if (Array.isArray(question) && question.length > 1) {
            let questions = [...question]
            let forGender = getGender(relationship)
            if (forGender === 'male') {
                questions = questions.filter(q => q['gender'] && q['gender'].toLowerCase() === 'male')
            }
            questions.sort((q1, q2) => q1['order'] - q2['order'])
            questions.splice(5)
            questions.push({
                id: 696969,
                question: 'Others'
            })
            setQuestionsShowed(questions)
            setResponses(initialResponses)

        } else if (Array.isArray(question) && question.length === 1 && question[0]['option_type'] === 'Multiple') {
            const questions = []

            const optionsTags = question[0]['option_keys'].split(',')
            const options = question[0]['options'].split(',')
            for (let index in optionsTags) {
                questions.push({
                    id: optionsTags[index],
                    option: options[index],
                    key: optionsTags[index],
                })
            }
            setQuestionsShowed(questions)
            setResponses(initialResponses)

        } else if (Array.isArray(question) && question.length === 1 && question[0]['option_type'] === 'Once') {
            const questions = []
            const optionsTags = question[0]['option_keys'].split(',')
            const options = question[0]['options'].split(',')
            const responses = {}
            for (let index in optionsTags) {
                questions.push({
                    id: optionsTags[index],
                    option: options[index],
                    key: optionsTags[index],
                })
                if (initialResponses[question[0]['key']] === optionsTags[index]) {
                    responses[optionsTags[index]] = true
                }
            }
            setQuestionsShowed(questions)
            setResponses(responses)
        }
    }, [question])
    // useEffect(() => {

    //     setResponses(initialResponses)
    // },[initialResponses])
    const onClickBullet = (item) => {

        if (item['id'] === 696969) {
            let questions = question
            questions.sort((q1, q2) => q1['order'] - q2['order'])
            let forGender = getGender(relationship)
            if (forGender === 'male') {
                questions = questions.filter(q => q['gender'] && q['gender'].toLowerCase() === 'male')
            }

            setQuestionsShowed(questions)
        } else {

            responses[item['key']] = !responses[item['key']]

            if (item['subquestions']) {
                for (let subQ of item['subquestions']) {
                    if (responses[item['key']]) {
                        delete responses[subQ['key']]
                    } else {
                        responses[subQ['key']] = false
                    }
                    error[subQ['key']] = false

                }
            }

            setResponses({ ...responses })
        }
    }

    const onClickTile = (item) => {
        setResponses({ [item['key']]: true })
        setTimeout(() => {
            submitResponses({ [question[0]['key']]: item['key'] })
        }, 300)

    }

    const onClickSubquestion = (item, value) => {
        if (value) {
            responses[item['key']] = true
        } else {
            responses[item['key']] = false
        }
        setResponses({ ...responses })
    }

    const onClickNoneHealthQuestions = () => {
        let responses = {}
        for (let q of question) {
            responses[q['key']] = false
            if (responses[q['key']] && q['subquestions'].length) {
                for (let subq of q['subquestions']) {
                    responses[subq['key']] = false
                }
            }
        }
        setResponses({ ...responses })
    }



    const sortQuestion = (subquestion1, subquestion2) => subquestion1['id'] - subquestion2['id']

    const formatQuestion = (text, i) => {
        let alphabet = ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"];
        return `${alphabet[i]}. ${text}`
    }

    const onClickSubmit = () => {
        let error = {}, scrollElement
        for (let q of question) {
            if (responses[q['key']] && q['subquestions'].length) {
                for (let subq of q['subquestions']) {
                    if (responses[subq['key']] === undefined) {
                        scrollElement = window.document.getElementById(q['key'].toString())
                        error[subq['key']] = true
                    }
                }
                if (scrollElement) {
                    break
                }
            }
        }
        if (Object.values(error).length) {

            if (scrollElement) {
                scrollElement.scrollIntoView({ behavior: 'smooth' })
            }
            setError(error)
        } else {
            submitResponses(responses)
        }
    }


    const renderInitialHealthQuestions = () => {

        return (
            <>
                <QuestionsWrapper >
                    <QuestionItem style={{ borderBottom: '2px solid #979797' }}>
                        <QuestionHeading>
                            <Ptag style={{ 'marginRight': '8px' }} letterSpacing="0.44px" fontSize="14px">
                                {'None'}
                            </Ptag>

                            <CheckboxWrapper onClick={() => onClickNoneHealthQuestions()}>
                                <Icons
                                    height='20'
                                    width='20'
                                    icon={Object.values(responses).indexOf(true) === -1 ? 'checkedCircle' : 'uncheckedCircle'}
                                />
                            </CheckboxWrapper>
                        </QuestionHeading>
                    </QuestionItem>
                    {

                        questionsShowed.map(item =>
                            <QuestionItem id={item['key']} key={item['id']}>
                                <QuestionHeading>
                                    <Ptag style={{ 'marginRight': '36px' }} letterSpacing="0.44px" fontSize="14px">
                                        {item['question']}
                                    </Ptag>

                                    <CheckboxWrapper
                                        onClick={() => onClickBullet(item)}
                                    >
                                        <Icons
                                            height='20'
                                            width='20'
                                            icon={responses[item['key']] ? 'checkedCircle' : 'uncheckedCircle'}
                                        />
                                    </CheckboxWrapper>
                                </QuestionHeading>

                                {
                                    item['subquestions'] && item['subquestions'].length > 0 && responses[item['key']] &&
                                    <SubquestionContainer>
                                        <Ptag letterSpacing='0.75px' fontWeight='600' fontSize='12px'>
                                            {item['subquestions_heading']}
                                        </Ptag>
                                        {item['subquestions'].sort(sortQuestion).map((subquestion, i) => (
                                            <SubQuestionRow key={`${item['key']} ${i}}`}>
                                                <Ptag fontWeight="500" letterSpacing="0.44px" fontSize="14px" letterHeight='1.71'>
                                                    {formatQuestion(subquestion['subquestion'], i)}
                                                </Ptag>
                                                <YesNoRow>
                                                    <Button onClick={() => onClickSubquestion(subquestion, true)} selected={responses[subquestion['key']] === true}>{'YES'}</Button>
                                                    <Button onClick={() => onClickSubquestion(subquestion, false)} selected={responses[subquestion['key']] === false}>{'NO'}</Button>
                                                </YesNoRow>
                                                {error[subquestion['key']] === true && responses[item['key']] && responses[subquestion['key']] === undefined &&
                                                    <Error>
                                                        {'*Required'}
                                                    </Error>
                                                }

                                            </SubQuestionRow>
                                        ))
                                        }
                                    </SubquestionContainer>
                                }

                            </QuestionItem>
                        )
                    }
                </QuestionsWrapper>
                <LargeButton id={`health_question_next_${sequence || ''}`} style={{ 'marginTop': '8px' }} label='NEXT' onClick={onClickSubmit} />
            </>
        )
    }

    const onClickSubmitMultiselect = () => {
        let result = []
        for (let [key, value] of Object.entries(responses)) {
            if (value) {
                result.push(key)
            }
        }
        submitResponses({ [question[0]['key']]: result.join(',') || 'None' })
    }

    const onClickNoneMultipleSelect = () => {
        setResponses({})
    }

    const renderMultiSelectQuestions = () => {

        return (
            <>
                <QuestionsWrapper>
                    <QuestionItem style={{ borderBottom: '2px solid #979797' }}>
                        <QuestionHeading>
                            <Ptag style={{ 'marginRight': '8px' }} letterSpacing="0.44px" fontSize="14px">
                                {'None'}
                            </Ptag>

                            <CheckboxWrapper onClick={() => onClickNoneMultipleSelect()}>
                                <Icons
                                    height='20'
                                    width='20'
                                    icon={Object.values(responses).indexOf(true) === -1 ? 'checkedCircle' : 'uncheckedCircle'}
                                />
                            </CheckboxWrapper>
                        </QuestionHeading>
                    </QuestionItem>

                    {
                        questionsShowed.map((item) => (
                            <QuestionItem key={item['id']}>
                                <QuestionHeading>
                                    <Ptag style={{ 'marginRight': '8px' }} textTransform='capitalize' letterSpacing="0.44px" fontSize="14px">
                                        {item['option']}
                                    </Ptag>

                                    <CheckboxWrapper onClick={() => onClickBullet(item)}>
                                        <Icons
                                            height='20'
                                            width='20'
                                            icon={responses[item['key']] ? 'checkedCircle' : 'uncheckedCircle'}
                                        />
                                    </CheckboxWrapper>
                                </QuestionHeading>
                            </QuestionItem>
                        ))
                    }

                </QuestionsWrapper>
                <LargeButton id={`health_question_next_${sequence || ''}`} style={{ 'marginTop': '8px' }} label='NEXT' onClick={onClickSubmitMultiselect} />
            </>
        )
    }

    const renderSingleSelect = () => {

        return (
            <TilesWrappper>
                {
                    questionsShowed.map((item, index) => (
                        <Tile id={`health_question_next_${sequence || ''}_${index}`} key={item['option']} selected={responses[item['key']]} onClick={() => onClickTile(item)}>
                            {item['option']}
                        </Tile>
                    ))
                }

            </TilesWrappper>
        )
    }

    const renderHeadings = (text, subText) => {

        return (
            <>
                <Ptag style={{ 'margin': '12px 0 6px' }} fontWeight='600' fontFamily='Avenir' fontSize='18px' letterHeight='1.67' letterSpacing='0.26px' >
                    {text}
                </Ptag>
                <Ptag letterSpacing='0.21px' letterHeight='1.71' style={{ 'margin': '0px 0 10px' }} fontSize='14px' color='#858688'>
                    {subText}
                </Ptag>
            </>
        )
    }
    const renderHeadingTextFunction = (relationship,ifaText, text) => {
        return localStorage.getItem('ifacode') && relationship && relationship.toLowerCase() === 'self' ? ifaText : text
    }
    const renderHeadingSubTextfunction = (relationship,ifaText, text,is_business_emp) => {
        // debugger
        return localStorage.getItem('ifacode') && relationship && relationship.toLowerCase() === 'self' ? ifaText : ((is_business_emp==="true")?null:text)

    }
    return (
        <Root id='question_root'>
            <Ptag fontFamily='Proxima Nova' fontSize='16px' letterSpacing='1px' color='#009999'>
                {sequence}
            </Ptag>


          {

                Array.isArray(question) && question.length > 1 &&
                <>

                    {renderHeadings(renderHeadingTextFunction(relationship,'Does your client have or had any of these health conditions?', `Do ${relationship && relationship.toLowerCase() === 'self' ? 'you' : 'they'} have or had any of these health conditions?`),
                       renderHeadingSubTextfunction(relationship,'To help us personalize their insurance cover', `To help us personalize ${relationship && relationship.toLowerCase() === 'self' ? 'your' : 'their'} insurance cover.`,localStorage.getItem('is_business_employee'))
                    )}
                    
                   {renderInitialHealthQuestions()}

                </>
            }

            {
                Array.isArray(question) && question.length === 1 && question[0]['option_type'] === 'Multiple' &&
                <>
                    {renderHeadings(renderHeadingTextFunction(relationship,`Does your client's parents or siblings have or had these conditions?`, `${relationship && relationship.toLowerCase() === 'self' ? question[0]['question'] : question[0]['family_member_question']} `),
                        renderHeadingSubTextfunction(relationship,'To help us understand their health risks.', `${question[0][relationship && relationship.toLowerCase() === 'self' ? 'proposer_reason' : 'family_member_reason']}`, localStorage.getItem('is_business_employee'))
                    )}
                    {renderMultiSelectQuestions()}
                </>
            }


            {
                Array.isArray(question) && question.length === 1 && question[0]['option_type'] === 'Once' &&
                <>
                    
                    {renderHeadings(localStorage.getItem('ifacode') && relationship && relationship.toLowerCase() === 'self' ? question[0]['ifa_flow_questions'] : relationship && relationship.toLowerCase() === 'self' ? question[0]['question'] : question[0]['family_member_question'],
                       (localStorage.getItem('is_business_employee') ==="true"?null :localStorage.getItem('ifacode') && relationship && relationship.toLowerCase() === 'self' ? question[0]['ifa_flow_reason']:question[0][relationship && relationship.toLowerCase() === 'self' ? 'proposer_reason' : 'family_member_reason'])
                    )
                    }
                    {renderSingleSelect()}
                </>

            }
        </Root>
    )
}

export default inDesktopView(HealthQuestionCard)

const Root = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 24px;
    background-color: #ffffff;
    min-height: 90vh;
`

const QuestionsWrapper = styled.ul`
    display: flex;
    flex-direction: column;
    flex: 0.6;
    & > :not(:last-child){
        border-bottom: 1px solid #979797;
    }
`

const QuestionItem = styled.li`
    display: flex;
    flex-direction: column;
    padding: 18px 0;
`

const QuestionHeading = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const CheckboxWrapper = styled.div`
  width: 20px;
  height: 20px;
`

const YesNoRow = styled.div`
  display: flex;
  margin: 16px 0 0 0;
`

const Button = styled.div`
  display: flex;
  align-items:center;
  justify-content: center;
  width: 98px;
  height: 30px;
  margin-right: 16px;
  border-radius: 5px;
  border: 1px solid #436ab2;
  background-color: ${props => props.selected ? '#436ab2' : 'transparent'};
  font-family: Avenir;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  text-align: center;
  color: ${props => props.selected ? '#ffffff' : '#4a4a4a'};
  cursor: pointer;
`

const SubquestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #f7f7f7;
  padding: 18px;
  margin-top: 20px;
`;

const SubQuestionRow = styled.div`
  display:flex;
  flex-direction: column;
  margin: 12px 0;
`

const Error = styled.p`
  font-family: Avenir;
  color: red;
  font-size: 1.4rem;
  margin-top: 0.8rem;
  z-index: 9;
`

const TilesWrappper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 18px;
`

const Tile = styled.button`
    display: flex;    
    margin-bottom: 18px;
    padding: 18px 16px;
    border-radius: 5px;
    border: solid 1px #436ab2;
    font-family: Avenir;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1px;
    text-align: center;
    color: ${props => props.selected ? 'white' : '#4a4a4a'};
    background-color: ${props => props.selected ? '#436ab2' : 'white'};
    outline: none;

`