import React from "react";
import { Ptag } from "../fonts";

import { BenefitCardWrapper, Header } from "./styles";

const BenefitCard = ({ detail }) => {
  return (
    <BenefitCardWrapper>
      <Header>
        <img src={detail.icon_url} width="36px" />
        <Ptag fontSize="16px" fontWeight="600" color="#436AB2" marginLeft="1rem">
          {detail.heading}
        </Ptag>
      </Header>
      <div style={{marginTop: '1rem'}}>
        <Ptag fontSize="14px" marginTop="5px">
          {detail.employer_sub_heading}
        </Ptag>
      </div>
    </BenefitCardWrapper>
  );
};

export default BenefitCard;
