import React, { Component } from 'react'
import styled from 'styled-components'
import jayanSir from '../assets/images/jayanSir.png'
import rahulSir from '../assets/images/rahulSir.png'

const Wrapper = styled.div`
  width: 100%;
  padding: 2.4rem 0 3.6rem 0;

  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const Header = styled.div`
  width: 32rem;
  padding-left: 2.4rem;
  // margin-bottom: rem;
`

const Font28 = styled.p`
    font-family: Proxima Nova;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: 0.5px;
    text-align: left;
    color: #4a4a4a;
`
const H2 = styled.h2`
  font-family: Proxima Nova;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.58;
  letter-spacing: 1px;
  color: #009999;
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : 'none')}

  @media (min-width: 1024px) {
    font-size: 34px;
    padding-top:  16px;
    text-align: center;
  }
`
const ImgDiv = styled.div`
  // width: 150px;
  // height: 150px;
  // border: solid 1px #979797;
  // background-color: #d8d8d8;
  margin-left: 24px;
  margin-bottom: 18px;
  margin-top: ${props => (props.marginTop ? props.marginTop : 'none')}

  @media (min-width: 1024px) {
    margin-top: 0px;
    margin-left: 158px;
  }
`
const Font20 = styled.div`
  font-family: Avenir;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.83px;
  color: #4a4a4a;
  padding-left: 24px;

  @media (min-width: 1024px) {
    margin-left: ${props => (props.marginLeft ? props.marginLeft : '122px')}
    font-size: 24px;
  }
`
const Font12 = styled.div`
  font-family: Avenir;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: #666666;
  padding-left: 24px;
  padding-top: 6px;

  @media (min-width: 1024px) {
    margin-left: 138px;
    font-size: 16px;
  }
`
const H3 = styled.h3`
  font-family: Avenir;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.47px;
  color: #4a4a4a;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 12px;

  @media (min-width: 1024px) {
    width: ${props => (props.width ? props.width : '460px')};
    font-size: 18px;
    padding-right: 0;
    margin-left: 18px;
    margin-top: 24px;
    // text-align: center; 
  }
`
const LineDiv = styled.div`
  border: solid 0.5px #979797;
  margin-top: 36px;
  margin-bottom: 24px;
  margin-left: 24px;
  margin-right: 24px;
`
const Link = styled.a`
`
const Font = styled.div`
  font-family: Avenir;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.38;
  letter-spacing: 0.67px;
  color: #4a90e2;
  padding-left: 24px;
  margin-bottom: 36px;

  @media (min-width: 1024px) {
    margin-bottom: 0;
    margin-top: 22px;
    padding-left: 8px;
  }
`
const Image = styled.img`
width: ${props => (props.width ? props.width : '95px')};
height: ${props => (props.height ? props.height : '104px')};

@media(min-width: 1024px) {
  width: 140px;
  height: 165px;
}
`
const LaptopDiv = styled.div`

@media (min-width: 1024px) {
  display: flex;
  align-items: center;
  justify-content: center;
}
`
 const LDiv = styled.div`
 @meida (min-width: 1024px) {
  display: flex;
  flex-direction: cloumn;
}
 `
 const LLDiv = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
  }
 `

 const MDiv = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    flex-direction: column;
    margin-top: -28px;
  }
 `
 const LMDiv = styled.div`
//  display: flex;
  @media (min-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
  }
 `
 

export default class Page47 extends Component {
  render = () => {
    return (
    <React.Fragment>
      <Wrapper>
        <Header>
          <H2> Leadership </H2>
        </Header>
      </Wrapper>
      <LaptopDiv>
        <LLDiv>
        <LDiv>
      <ImgDiv>
      <Image alt="rahul kumar get vital" src ={rahulSir} />
      </ImgDiv>
      <Font20> Rahul Kumar </Font20>
      <Font12> CEO & Founder </Font12>
      </LDiv>
      <H3> 
        Co-founder of India's first dating app Truly Madly. Rahul was also a 
        part of the senior management teams at Expedia and Make My Trip. 
        With extensive experience in Product Management, he has a passion for building robust Product and Tech teams.
      </H3>
      </LLDiv>
      

      <MDiv>
      <ImgDiv marginTop='36px'>
        <Image alt="jayan mathews get vital" src={jayanSir} />
      </ ImgDiv>
      <Font20 marginLeft='112px'> Jayan Mathews </Font20>
      <Font12> CBO & Founder </Font12>
      <H3> 
        With over 14 years of experience in Health Insurance in India. Jayan has led the Product and Digital divisions at Apollo Munich. 
        Prior to it he led the Health and traditional Product Development at 
        ICICI Prudential.
      </H3>
      </MDiv>
      </LaptopDiv>
      <LineDiv />
      <H2 paddingLeft = '24px'>
        Join Us
      </H2>
      <LMDiv>
      <H3 width='auto'>If we sound exciting, email us at: </H3>
      <Font><Link href="mailto:care@getvital.in" target="_top"> care@getvital.in</Link></Font>
      </LMDiv>
      </React.Fragment>
    )
  }
}
