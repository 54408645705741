import React, { useEffect, useState } from "react";
import http from "../../../services/httpService";
import ProductStatusCardList from "./ProductStatsCardList";
import { OverlayLoader } from "../../../components";
import { MainDiv } from "../GmcDashboard/styles";
import { Divider } from "@material-ui/core";
import { Ptag } from "../../../components/fonts";
import InsurancePlanCard from "../../../components/GmcDashbaord/InsurancePlanCard";
import { InsuranceCardsWrapper } from "../GmcDashboard/styles";
import FlexMisTable from "./FlexMisTable";
import { PRIORITY_ORDER } from "../../../constants/businessConstant";

const FlexDashboard = () => {
  const [getData, setGetData] = useState();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      // await getFlexCompanyData();
      await getFlexProductDetail();
    })();
  }, []);

  const getFlexProductDetail = async () => {
    const { data } = await http.get("/business/get_company_product_details/");
    let sortedArray = Object.entries(data.product_details).sort(([currentKey, currentValue],[nextKey, nextValue])=>{
        const currentBoolean = currentValue.userplan_data.mandatory
        const nextBoolean = nextValue.userplan_data.mandatory
        if(currentBoolean && !nextBoolean){
            return -1
        } else if(!currentBoolean && nextBoolean){
            return 1
        } else {
            const currentPriority = PRIORITY_ORDER.indexOf(currentKey);
            const nextPriority = PRIORITY_ORDER.indexOf(nextKey);
        
            if (currentPriority !== -1 && nextPriority !== -1) {
              return currentPriority - nextPriority; 
            } else if (currentPriority !== -1) {
              return -1;
            } else if (nextPriority !== -1) {
              return 1; 
            } else {
              return currentKey.localeCompare(nextKey); 
            }
        }
    }) 
    let gmcDetails = Object.keys(Object.fromEntries(sortedArray)).reduce((acc, key)=> {
        acc[key] = Object.fromEntries(sortedArray)[key]
        return acc
    }, {})
    setProducts(gmcDetails);
    let gmc_data = Object.keys(Object.fromEntries(sortedArray)).reduce((acc, key)=> {
      acc[key] = Object.fromEntries(sortedArray)[key]['userplan_data']
      return acc
  }, {})
    localStorage.setItem("flex_product_priority_list", JSON.stringify(Object.keys(gmcDetails)));
    localStorage.setItem("gmc_details", JSON.stringify(gmc_data));
    setLoading(false)
}

  const getFlexCompanyData = async () => {
    try {
      var { data } = await http.get(
        "business/get_employee_detail_for_group_products/"
      );
      const sortedArray = Object.entries(data[0]).sort(([currentKey, currentValue], [nextKey, nextValue])=>{
        const currentBoolan = currentValue.overallsummary.userplan_data.mandatory
        const nextBoolean = nextValue.overallsummary.userplan_data.mandatory
        if(currentBoolan && !nextBoolean) {
            return -1
        } else if(!currentBoolan && nextBoolean){
            return 1
        } else{
            const currentPriority = PRIORITY_ORDER.indexOf(currentKey);
            const nextPriority = PRIORITY_ORDER.indexOf(nextKey);
        
            if (currentPriority !== -1 && nextPriority !== -1) {
              return currentPriority - nextPriority; // sort based on priority order
            } else if (currentPriority !== -1) {
              return -1; // a should come before b (b is not in the priority order)
            } else if (nextPriority !== -1) {
              return 1; // b should come before a (a is not in the priority order)
            } else {
              return currentKey.localeCompare(nextKey); // sort by key names if both are not in the priority order
            }
          }
      })
      data[0] = Object.fromEntries(sortedArray)
      localStorage.setItem('flex_product_priority_list', Object.keys(data[0]))
      setGetData(data)
      setProducts(data);
      let gmcDetails = Object.keys(data[0]).reduce((acc, key)=> {
        acc[key] = data[0][key]['overallsummary']['userplan_data']
        return acc
    }, {})
    localStorage.setItem("flex_products_list", JSON.stringify(Object.keys(gmcDetails)));
    localStorage.setItem("gmc_details", JSON.stringify(gmcDetails));
    } catch (err) {
      alert("Some error has occured!");
    } finally {
      setLoading(false);
    }
  };
  const renderInsuranceProducts = () => {
    const generateJsx = (type, details, index) => {
      let flexCard = details.userplan_data
      let JSX = "";
      switch (type) {
        case 'health_insurance':
            JSX = <InsurancePlanCard type="GMC" plan_detail={{title: "Group Health Insurance", description: "Medical cover for employees, their spouse, kids"}} details={flexCard} />
            break;
        case 'personal_accident':
            JSX = <InsurancePlanCard type="GPA" plan_detail={{title: "Group Personal Accident", description: "Coverage for accidental death or permanent disablement"}} details={flexCard} />
            break;
        case 'term_life':
            JSX = <InsurancePlanCard type="GTL" plan_detail={{title: "Group Term Life", description: "Life insurance for employees"}} details={flexCard} />
            break;
        case 'mandatory_top_up_health':
            JSX = <InsurancePlanCard plan_detail={{title: "Mandatory Top Up Health", description: "For hospitalizations and day care treatments"}} details={flexCard} />
            break;
        case 'top_up_health':
            JSX = <InsurancePlanCard plan_detail={{title: "Top Up Health", description: "For hospitalizations and day care treatments"}} details={flexCard} />
            break;
        case 'opd_cover':
            JSX = <InsurancePlanCard type="OPD" plan_detail={{title: "OPD Cover", description: "Out patient cover for employees"}} details={flexCard} />
            break;
        case 'critical_illness':
            JSX = <InsurancePlanCard type="CI" plan_detail={{title: "Critical Illness", description: "For financial protection against loss of life"}} details={flexCard} />
            break;
        case 'health_checkup':
            JSX = <InsurancePlanCard type="HC" plan_detail={{title: "Health Checkup", description: "For financial protection against loss of life"}} details={flexCard} />
            break;
        case 'parental_cover':
          JSX = <InsurancePlanCard type="HC" plan_detail={{title: "Parental Cover", description: "Medical cover for parent of employees"}} details={flexCard} />
          break;
      }
      return JSX;
    };
    return (
      <InsuranceCardsWrapper>
        {Object.entries(products).map(([key, value])=> generateJsx(key, value))}
      </InsuranceCardsWrapper>
    );
  };
  return (
    <MainDiv>
      {loading ? (
        <OverlayLoader />
      ) : (
        <>
          <ProductStatusCardList products={products}/>
          <Divider/>
          <Ptag fontSize="24px" fontWeight="700" marginTop="3rem" marginBottom="3rem">
              Plan Summary
          </Ptag>
          {renderInsuranceProducts()}
          <div style={{marginTop: '36px', height: '16px', backgroundColor: '#dadada', marginLeft: '-4rem', width: '140%'}}></div>
          <FlexMisTable tablesData={products[0]} getData={getData}/>
        </>
      )}
    </MainDiv>
  );
};

export default FlexDashboard;
