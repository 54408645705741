import React, { Component } from 'react'
import styled from 'styled-components'
import {Ptag} from '../fonts'
import BenefitSection from './benefitOfferSection'
import config from "../../config.json";
import {LargeButton} from '../'
import {useHistory, useParams} from 'react-router-dom'
import {BenefitsRedemptionNavigationHelper} from '../../services/navigationService'

const OfferBody = ({bodyData, match}) => {
    const history = useHistory()
    const {id: offerId, vital_cover_id} = useParams()
    const WhatsIncluded = bodyData['offer_description_2'] && bodyData['offer_description_2'].split('$')
    const Instructions =  bodyData['offer_instructions'] && bodyData['offer_instructions'].split('$')
    const TermsNConditions = bodyData['terms'] && bodyData['terms'].split('$')
    const PreparationRequired = bodyData['preparation_required'] && bodyData['preparation_required'].split('$')
    const {brand, brand_description} = bodyData
    
    const onClickNext = () => {
        const navigator = new BenefitsRedemptionNavigationHelper(bodyData['offer_type'], match.path)
        localStorage.setItem('points_id', offerId)
        const nextPage = navigator.getNextPage()
        localStorage.setItem('flow', bodyData['offer_type'])
        localStorage.setItem('eventData', JSON.stringify({}))
        history.push(`${nextPage}/${vital_cover_id}/${offerId}`)
    }
    
    return(
        <Root>
            <HeaderContainer>
                <Ptag fontSize='20px' fontWeight='600' letterSpacing='0.5px' >
                    {bodyData['offer_description_1']}
                </Ptag>
                <Ptag fontSize='14px' letterSpacing='0.29px' >
                    {bodyData['sub_descriptor']}
                </Ptag>
            </HeaderContainer>
            <BenefitSection 
                heading={'What\'s included?'}
                bodyText={WhatsIncluded}
            />
            <BenefitSection 
                heading={'How to Redeem?'}
                bodyText={Instructions}
            />
            <BenefitSection 
                heading={'Terms and Conditions'}
                bodyText={TermsNConditions}
            />
            <BenefitSection 
                heading={'What preparation is required?'}
                bodyText={PreparationRequired}
            />
            <BenefitSection 
                heading={`About ${brand}`}
                bodyText={brand_description && [brand_description]}
                showAsPoints={false}
            />
            
            <Footer>
                <LargeButton onClick={onClickNext} label='NEXT' />
            </Footer>
            
            
        </Root>

    )
}


export default OfferBody

const Root = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px 24px 90px 24px;
`

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgb(216, 216, 216);
    padding-bottom: 24px;
    & > :first-child{
        margin-bottom: 10px;
    }
`

const Footer = styled.div`
    display: flex;
    width: 100%;
    margin-left: -24px;
    padding: 0 24px 24px 24px;
    position: fixed;
    max-width: ${config.maxScreenWidth};
    bottom: 0;
    background: #ffffff;
`
