function formatAmount(x) {
  // eslint-disable-next-line quotes
if (!x)
	return x
  x=x.toString();
  
  if (isNaN(x))
    return ''
  var afterPoint = '';
  if(x.indexOf('.') > 0)
	 afterPoint = x.substring(x.indexOf('.'),x.length);
  x = Math.floor(x);
  x=x.toString();
  var lastThree = x.substring(x.length-3);
  var otherNumbers = x.substring(0,x.length-3);
  if(otherNumbers !== '')
	  lastThree = ',' + lastThree;
  var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + afterPoint;
	return res || '0'
}

export default formatAmount
