//import {initGA,Event,PageView} from '../Tracking'
import config from '../config.json'
import formatAmountUtils from '../components/memberApp/common/utils'
import * as Sentry from '@sentry/browser'
import PolicyType from '../components/memberApp/constants/PolicyConstants'
import mother from "../assets/images/relationship/mother.png";
import self from "../assets/images/relationship/self.png";
import father from "../assets/images/relationship/father.png";
import son from "../assets/images/relationship/son.png";
import daughter from "../assets/images/relationship/daughter.png";
import spouse from "../assets/images/relationship/spouse.png";
import femaleDark from '../assets/images/relationship/femaleDark.png'
import maleDark from '../assets/images/relationship/maleDark.png'
import femaleLight from '../assets/images/relationship/femaleLight.png'
import maleLight from '../assets/images/relationship/maleLight.png'
import { PolicyMappings } from '../components/memberApp/constants/PolicyConstants'
import maleIcon from '../assets/images/relationship/maleIcon.png'
import femaleIcon from '../assets/images/relationship/femaleIcon.png'
import moment from 'moment'
import _, { values } from "lodash"
import http from '../services/httpService';

export function formatAmount(amount) {
  return formatAmountUtils(amount)
}

export const formatDateToDayMonthYear = (date) => {
  const datetime = date ? new Date(date) : new Date();

  // Use moment to format the datetime
  const formattedDate = moment(datetime).format("D MMMM YYYY");
  return formattedDate;
};

export function formatAmountWithDecimal(amountWithdecimal) {
  let amount = Math.trunc(amountWithdecimal)
  if (!amount)
    return 0;

  if (String(amount).length <= 3)
    return amount;

  let a = amount.toString();
  amount = Number(amount);
  let l = a.length;
  let res = ',' + a.substr(l - 3, l);
  amount = Math.floor(amount / 1000);
  while (amount.toString().length > 2) {
    a = amount.toString();
    l = a.length;
    res = ',' + a.substr(l - 2, l) + res;
    amount = Math.floor(amount / 100);
  }
  a = amount.toString();
  return a + res;
}

export const FormattingDateChange = (e, formik) => {
  const value = e.target.value
  const { values, setFieldValue} = formik
  let prevDate = values['dob']
  let newDate = value
  let prevDateLen = prevDate.length
  let newDateLen = newDate.length
  let newDob = newDate
  
  if (newDateLen < prevDateLen) {
      newDob = prevDate.substr(0, prevDateLen - 1)
  } else if (newDateLen <= 10) {
    if (newDateLen === 2 || newDateLen === 5) {
      console.log({prevDateLen}, {newDateLen})
      newDob =  newDate + '/'
    } 
  } else {
      newDob = newDate.slice(0,10)
  }
  console.log({newDob}, {prevDate})
  setFieldValue('dob', newDob)
}



export function customValidate(value) {
  let error
  if (value === '') {
    error = '*Required'
  }
  return error
}

export function validateAlphaOnly(value) {
  let error = ''
  let val = value && value.trim()
  if (!val) {
    error = '*Required'
  } else if (!/^([A-Za-z]?[\s]*)+$/.test(val)) {
    error = '*enter alphabets only.'
  }
  return error
}

export function validateEmail(email, required) {
  if (!email) return '*Required'

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email) ? '' : 'invalid email'
}

export function validateEmptyEmail(email) {
  if (!email) return ''

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email) ? '' : 'invalid email'
}

export function validateDate(value) {
  // pass value in 'mm/dd/yyyy' format
  try {
    let date = value.split('/')
    if (date[0].length === 1) {
      date[0] = '0' + date[0]
    }
    if (date[1].length === 1) {
      date[1] = '0' + date[1]
    }
    date = moment(`${date[2]}-${date[0]}-${date[1]}`);
    return date.isValid()
  } catch (ex) {
    return false
  }

}

export function validateAlphaNumeric(value) {
  let error = ''
  if (!value) {
    error = '*Required'
  } else if (!/^([A-Za-z0-9]?[\s]*)+$/.test(value)) {
    error = '*enter alphabets and no. only.'
  }
  return error
}

export function validatePincode(value) {
  let error
  if (!value) error = '*required'
  else if (value.toString().length !== 6) error = '*pincode should be 6-digit long.'
  return error
}
export function validatePincode1(value) {
  let error
  if (value.length === 6) return
  if (!value) error = '*required'
  else if (value.toString().length !== 6) error = '*pincode should be 6-digit long.'
  return error

}

export function validatePositiveNumber(value) {
  let error
  if (!value) {
    error = '*Required'
  } else if (!/^([0-9]?[\s]*)+$/.test(value)) {
    error = '*enter positive no. only.'
  }
  return error
}

export function formatDate(date) {
  let new_date
  new_date = date
    .split('/')
    .reverse()
    .join('-')
  return new_date
}

export function formatDateReverse(date) {
  let new_date
  new_date = date
    .split('-')
    .reverse()
    .join('/')
  return new_date
}

export function formatdob(date) {
  date = date.trimLeft('-')
  if (date.length !== 10) {
    Sentry.captureMessage('ERROR FORMATTED DOB:-> ' + date)
  }
  date = date.trimLeft('-')
  // let new_date = date.split('-').filter(function(i) {
  //   if (i !== '' && i !== '' && i !== ' ' && i !== ' ') {
  //     return i
  //   }
  //   return
  // })
  let new_date = date.split('-').filter(i => i !== '' || i !== ' ' || i !== 'null' || i !== '')
  let yyyy = new_date[0].toString()
  let mm = new_date[1].toString()
  let dd = new_date[2].toString()
  if (mm.length !== 2) {
    mm = '0' + mm
  }
  if (dd.length !== 2) {
    dd = '0' + dd
  }
  return yyyy + '-' + mm + '-' + dd
}

export function validatePhone(value) {
  let error
  if (!value) {
    error = '*Required'
  } else if (!/^\d{10}$/.test(value)) {
    error = '*enter 10 digit Phone Number.'
  }
  return error
}

export function gtag_report_conversion(url) {
  var callback = function () {
    if (typeof url != 'undefined') {
      window.location = url
    }
  }

  //	window.gtag('event', 'conversion', { 'send_to': 'AW-723118633/5wpsCKPWqqkBEKnU59gC', 'event_callback': callback });
  return false
}

export function getAllUrlParams(url, ignoreCaseSensitivity) {
  var queryString = url ? url.split('?')[1] || url.split('#')[1] : window.location.search.slice(1)
  var obj = {}
  //console.log("queryString",queryString);
  if (queryString) {
    queryString = queryString.split('#')[0]
    var arr = queryString.split('&')

    for (var i = 0; i < arr.length; i++) {
      var a = arr[i].split('=')
      var paramName = a[0]
      var paramValue = typeof a[1] === 'undefined' ? true : a[1]
      paramName = ignoreCaseSensitivity ? paramName : paramName.toLowerCase()

      if (typeof paramValue === 'string') paramValue = ignoreCaseSensitivity ? paramValue : paramValue.toLowerCase()
      if (paramName.match(/\[(\d+)?\]$/)) {
        var key = paramName.replace(/\[(\d+)?\]/, '')
        if (!obj[key]) obj[key] = []
        if (paramName.match(/\[\d+\]$/)) {
          var index = /\[(\d+)\]/.exec(paramName)[1]
          obj[key][index] = paramValue
        } else {
          obj[key].push(paramValue)
        }
      } else {
        if (!obj[paramName]) {
          obj[paramName] = paramValue
        } else if (obj[paramName] && typeof obj[paramName] === 'string') {
          obj[paramName] = [obj[paramName]]
          obj[paramName].push(paramValue)
        } else {
          obj[paramName].push(paramValue)
        }
      }
    }
  }
  //console.log("obj",obj);

  return obj
}
export function setUtmData(utm_source, utm_campaign, utm_medium, referralCode) {
  if (utm_source) localStorage.setItem('utm_source', utm_source)
  if (utm_medium) localStorage.setItem('utm_medium', utm_medium)
  if (utm_campaign) localStorage.setItem('utm_campaign', utm_campaign)
  if (referralCode) {
    referralCode = referralCode.toUpperCase()
    localStorage.setItem('referralCode', referralCode)
  }
}

export function findInValues(arr, value) {
  value = String(value).toLowerCase();
  return arr.filter(o =>
    Object.entries(o).some(entry =>
      String(entry[1]).toLowerCase().includes(value)
    )
  );
}


export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export function formatBackendDate(date) {
  let dateDay =
    date &&
    date
      .slice(0, date.indexOf('T'))
      .split('-')
      .reverse()
      .join('/')
  return dateDay
}

export function formatBigAmount(amount) {
  if (!amount)
    return 0;

  if (String(amount).length <= 3)
    return amount;

  let a = amount.toString();
  amount = Number(amount);
  let l = a.length;
  let res = ',' + a.substr(l - 3, l);
  amount = Math.floor(amount / 1000);
  while (amount.toString().length > 2) {
    a = amount.toString();
    l = a.length;
    res = ',' + a.substr(l - 2, l) + res;
    amount = Math.floor(amount / 100);
  }
  a = amount.toString();
  return a + res;
}

export function formatBackEndDateDDMMYY(date) {
  let originalDate = date
  try {

    let dateDay =
      date &&
      date
        .slice(0, date.indexOf('T'))
        .split('-')
        .reverse()
    dateDay[2] = dateDay[2].slice(2)
    return dateDay.join('/')
  } catch (ex) {
    console.error({ date: originalDate })
    return '-'
  }

}
export function generateRandomColor() {
  var letters = '0123456789ABCDEF'.split('')
  var color = '#'
  for (var i = 0; i < 6; i++) {
    color += letters[Math.round(Math.random() * 15)]
  }
  return color
}
export function convert_amount(x) {
  if (x === null || x == undefined) return 0

  x = x.toString()
  var lastThree = x.substring(x.length - 3)
  var otherNumbers = x.substring(0, x.length - 3)
  if (otherNumbers != '') lastThree = ',' + lastThree
  var res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree
  return res
}
export const appendScript = () => {
  let scriptToAppend = `
    (function(d,h,e,v,n) {
    if(d.dht)
    return;
    n=d.dht=function() {
    n.track?n.track.apply(n,arguments):n.queue.push(arguments)
  }
    n.queue=[];
    t=h.createElement(e);
    t.async=!0;
    t.src=v;
    t.id="dht";
    s=h.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s);
  }(window, document, 'script', 'https://assets-money.dailyhunt.in/dht.min.js'));
    dht('init', '')`

  console.log(scriptToAppend)
  const script = document.createElement('script')
  script.innerHTML = scriptToAppend
  script.async = true
  document.body.appendChild(script)
}

export function checkStringForNumbers(input) {
  let str = String(input)
  for (let i = 0; i < str.length; i++) {
    console.log(str.charAt(i))
    if (!isNaN(str.charAt(i))) {
      //if the string is a number, do the following
      return true
    }
  }
  return false
}

export function getPremium(type, policy, platform_fee = null) {
  if (type == PolicyType.WELLNESS) {
    return policy.platform_fee
  } else if (type == PolicyType.ACCIDENTAL) {
    return policy.premium
  } else {
    if (policy.platform_fee == null || policy.platform_fee == undefined)
      return policy.premium
    else {
      return parseInt(policy.premium) + parseInt(policy.platform_fee)
    }
  }
}

export function getPremiumWithTax(type, policy, platform_fee = null) {
  let prem = 0
  if (type == PolicyType.WELLNESS) {
    prem = policy.platform_fee
  } else if (type == PolicyType.ACCIDENTAL) {
    prem = policy.premium
  } else {
    if (policy.platform_fee == null || policy.platform_fee == undefined)
      prem = policy.premium
    else {
      prem = parseInt(policy.premium) + parseInt(policy.platform_fee)
    }
  }
  return prem + Math.ceil(0.18 * prem)
}


export function throttle(func, time) {
  let called = false

  return (...args) => {
    if (!called) {
      func(...args)
      called = true
      setTimeout(() => (called = false), time)
    }
  }
}

export function gtmCode() {
  if (config.env === 'prod') {
    return 'GTM-MDCMM7K'
  } else return 'GTM-5T95K8J'
}

// VERLOOP UTILITY FUNCTIONS
export function sentryLogging(name, email, phone, hf_id, department = null) {
  if (config.env === 'stg' || config.env === 'prod') {

    Sentry.captureMessage('Logging verloop data  ')
    Sentry.configureScope(scope => {
      scope.setExtra('name', name)
      scope.setExtra('email', email)
      scope.setExtra('phone', phone)
      scope.setExtra('department', department)
      scope.setUser({ id: localStorage.getItem('uuid') })
      // scope.clear();
    })

  }
}

export function formatRelation(text, spaced) {
  if (!text) {
    return ''
  }
  let indexInLaw = text.toLowerCase().indexOf("inlaw");
  if (indexInLaw > -1) {
    return text.toLowerCase().slice(0, indexInLaw) + (spaced ? ' in-law' : "-in-law");
  }
  return text.toLowerCase();
};

export function formatRelationship(relation) {
  if (relation === "MOTHERINLAW") {
    return "MOTHER-IN-LAW";
  }
  if (relation === "FATHERINLAW") {
    return "FATHER IN-LAW";
  }
  return relation;
}

export function getMemberIconURL(relation) {
  const selfGender = localStorage.getItem('userGender')
  if (relation && relation.toLowerCase().indexOf("mother") > -1)
    return mother;
  else if (relation && relation.toLowerCase().indexOf("father") > -1)
    return father;
  else if (relation && relation.toLowerCase().indexOf("son") > -1) return son;
  else if (relation && relation.toLowerCase().indexOf("daughter") > -1)
    return daughter;
  else if (relation && relation.toLowerCase().indexOf("self") > -1) {
    if (selfGender && selfGender.toLowerCase() === 'female') {
      return spouse;
    }
    return self;
  }
  else if (relation && relation.toLowerCase().indexOf("spouse") > -1) {
    if (selfGender && selfGender.toLowerCase() === 'female') {
      return self;
    }
    return spouse;
  } else {
    return self;
  }
};

export function getRelativesIcon(relation, light = false) {
  if (light) {
    const selfGender = localStorage.getItem('userGender')
    if (relation && (relation.toLowerCase().indexOf("mother") > -1 || relation.toLowerCase().indexOf("daughter") > -1))
      return femaleLight;
    else if (relation && (relation.toLowerCase().indexOf("father") > -1 || relation.toLowerCase().indexOf("son") > -1))
      return maleLight;
    else if (relation && relation.toLowerCase().indexOf("self") > -1) {
      if (selfGender && selfGender.toLowerCase() === 'female') {
        return femaleLight;
      }
      return maleLight;
    } else if (relation && relation.toLowerCase().indexOf("spouse") > -1) {
      if (selfGender && selfGender.toLowerCase() === 'female') {
        return maleLight;
      }
      return femaleLight;
    } else {
      return maleLight;
    }
  } else {
    const selfGender = localStorage.getItem('userGender')
    if (relation && (relation.toLowerCase().indexOf("mother") > -1 || relation.toLowerCase().indexOf("daughter") > -1))
      return femaleDark;
    else if (relation && (relation.toLowerCase().indexOf("father") > -1 || relation.toLowerCase().indexOf("son") > -1))
      return maleDark;
    else if (relation && relation.toLowerCase().indexOf("self") > -1) {
      if (selfGender && selfGender.toLowerCase() === 'female') {
        return femaleDark;
      }
      return maleDark;
    } else if (relation && relation.toLowerCase().indexOf("spouse") > -1) {
      if (selfGender && selfGender.toLowerCase() === 'female') {
        return maleDark;
      }
      return femaleDark;
    } else {
      return maleDark;
    }
  }

}
export function getRelativesDashboardIcon(relation, light = false) {
  if (light) {
    const selfGender = localStorage.getItem('userGender')
    if (relation && (relation.toLowerCase().indexOf("mother") > -1 || relation.toLowerCase().indexOf("daughter") > -1))
      return femaleIcon;
    else if (relation && (relation.toLowerCase().indexOf("father") > -1 || relation.toLowerCase().indexOf("son") > -1))
      return maleIcon;
    else if (relation && relation.toLowerCase().indexOf("self") > -1) {
      if (selfGender && selfGender.toLowerCase() === 'female') {
        return femaleIcon;
      }
      return maleIcon;
    } else if (relation && relation.toLowerCase().indexOf("spouse") > -1) {
      if (selfGender && selfGender.toLowerCase() === 'female') {
        return maleIcon;
      }
      return femaleIcon;
    } else {
      return maleIcon;
    }
  } else {
    const selfGender = localStorage.getItem('userGender')
    if (relation && (relation.toLowerCase().indexOf("mother") > -1 || relation.toLowerCase().indexOf("daughter") > -1))
      return femaleIcon;
    else if (relation && (relation.toLowerCase().indexOf("father") > -1 || relation.toLowerCase().indexOf("son") > -1))
      return maleIcon;
    else if (relation && relation.toLowerCase().indexOf("self") > -1) {
      if (selfGender && selfGender.toLowerCase() === 'female') {
        return femaleIcon;
      }
      return maleIcon;
    } else if (relation && relation.toLowerCase().indexOf("spouse") > -1) {
      if (selfGender && selfGender.toLowerCase() === 'female') {
        return maleIcon;
      }
      return femaleIcon;
    } else {
      return maleIcon;
    }
  }

}


export function getCardTitle(policy, item) {
  const policyName = policy && policy.toUpperCase()
  if (new Set([PolicyType.GHPA, PolicyType.GHPB, PolicyType.GHPC, PolicyType.GHPD]).has(policyName)) {
    return PolicyMappings['CORPORATE']['name'];
  }
  const name = PolicyMappings[policyName] && PolicyMappings[policyName]['name']
  if (name) {
    return name
  } else if(localStorage.getItem('employee_plan_type')==='FLEX'){
    return "Benefits"
  }else {
    if (policyName.indexOf('NIVA') > -1) {
      return 'Niva Bupa Group Plan';
    }
  }
}

export function familySorter(rel1, rel2) {
  let relPrio = {
    SELF: 0,
    SPOUSE: 1,
    SON: 2,
    DAUGHTER: 3,
    FATHER: 4,
    MOTHER: 5,
    FATHERINLAW: 6,
    MOTHERINLAW: 7,
  };
  const rel1formatted = rel1 && ((rel1['relationship'] && rel1['relationship'].toUpperCase()) || (rel1['relation'] && rel1['relation'].toUpperCase()))
  const rel2formatted = rel2 && ((rel2['relationship'] && rel2['relationship'].toUpperCase()) || (rel2['relation'] && rel2['relation'].toUpperCase()))
  if (relPrio[rel1formatted] > relPrio[rel2formatted])
    return 1;
  else if (relPrio[rel1formatted] < relPrio[rel2formatted])
    return -1;
  else return 0;
}

export function validateMobileNumber(value){
  let error
  let val=value.toString()
  if (!value) {
    error = '*Required.'
  } else if(val.length < 10 || val.length > 10){
    error = '*Please enter a valid number'
  } 
  return error
}

export function validateDOB(value){
  let error
  if (!value) {
    error = '*Required.'
  } else if (value.length < 10) {
    error = 'Date must be in DD/MM/YYYY format'
  } else if (value.length === 10) {
    const age = moment().diff(
      moment(
        value
          .split('/')
          .reverse()
          .join(),
        'YYYYMMDD'
      ),
      'years'
    )
    let daysInDiff = moment().diff(
      moment(
        value
          .split('/')
          .reverse()
          .join(),
        'YYYYMMDD'
      ),
      'days'
    )
    if (daysInDiff <= 0 || isNaN(age) || age < 0 || age > 120) error = 'DOB is invalid'
  }
  return error
}

export function getTags(responses, healthQuestions) {
  const AllTags = []
  const MedicalTags = []

  for (let q of healthQuestions) {

    if ((q['key'] in (responses && responses['new_health_questions'])) && responses['new_health_questions'][q['key']] && responses['new_health_questions'][q['key']].toLowerCase() !== 'none') {
      const values = responses['new_health_questions'][q['key']].split(',')
      const tagValues = q['option_tags'].split(',')
      const keyValues = q['option_keys'].split(',')
      for (let v of values) {
        let val = tagValues[keyValues.indexOf(v)]
        if (val) {
          AllTags.push(
            val
          )
        }
      }


    } else if ((q['key'] in responses) && responses[q['key']]) {
      MedicalTags.push(q['tag'])
    } else if (q['key'] && q['key'].toLowerCase().indexOf('hereditary') > -1 && (responses['new_health_questions'][q['key']] && responses['new_health_questions'][q['key']].toLowerCase() === 'none')) {
      AllTags.push(
        'No Hereditary Condition'
      )
    }
  }
  if (MedicalTags.length) {
    return [...MedicalTags, ...AllTags]
  } else {
    return ['No Medical Condition', ...AllTags]
  }
}

export function getAge(dob = '', splitter = '/') {
  return moment().diff(moment(dob.split(splitter).join(), 'YYYYMMDD'), 'years')
}

export function getBenefitText(benefit) {
  if (benefit && benefit['benefit_count'] && benefit['benefit_count'].toString().toLowerCase() != 'unlimited') {
    return benefit['details'] && `${benefit['benefit_count']}x ${benefit['details']['summary_heading'] || benefit['details']['heading']}`
  } else {
    return benefit['details'] && (benefit['details']['summary_heading'] || benefit['details']['heading'])
  }
}

export function isSafariBrowser() {
  // Source: https://stackoverflow.com/questions/7944460/detect-safari-browser
  let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  return isSafari
}


export function openVerloopChat() {
  if (window.Verloop) {
    window.Verloop(function () {
      this.openWidget()
    })
  } else {
    const initVerloop = function (w, d, s, u) {
      w.Verloop = function (c) { w.Verloop._.push(c) }; w.Verloop._ = []; w.Verloop.url = u;
      var h = d.getElementsByTagName(s)[0], j = d.createElement(s); j.async = true;
      j.src = 'https://getvital.verloop.io/livechat/script.min.js';
      h.parentNode.insertBefore(j, h);
      window.Verloop(function () {
        this.setUserParams({
          name: localStorage.getItem('first_name'),
          email: localStorage.getItem('email'),
          phone: localStorage.getItem('phone'),
          user_id: localStorage.getItem('healthfront_id')
        })
        const department = localStorage.getItem('department')
        if (department !== null || department !== undefined) {
          this.setCustomField('department', department, { scope: 'user' })
        }
        this.openWidget()

      })

    };
    initVerloop(window, document, 'script', 'https://getvital.verloop.io/livechat')
  }

}

export function getEmployeeTypeFromEnum(employeeEnum) {
  let text = "";
  switch (employeeEnum) {
    case "E":
      text = "Employee Only"
      break;
    case "ESC":
      text = "Employee, Spouse and Children"
      break;
    case "ESCP":
      text = "Employee, Spouse, Children and Parents"
      break;
  }
  return text;
}

export function openLinkInNewTab(link) {
  window.open(link, "_blank")
}

export function getFirstLastName(name) {
  let names = name.split(" ");
  let firstName = names[0];
  let lastName = names[names.length - 1];

  return { firstName, lastName };
}

export function setUserDataLocally(data) {
  localStorage.setItem('token', data.token)
  sessionStorage.setItem('token', data.token)
  let is_business_employee = data.is_business_employee
  localStorage.setItem('is_business_employee', is_business_employee)
  if (data.marketplace_city) {
    localStorage.setItem('marketplace_city', data.marketplace_city)
  }
  localStorage.setItem('healthfront_id', data.profile.HFId)
  localStorage.setItem('uuid', data.id)
  localStorage.setItem('full_name', data.profile.first_name + ' ' + data.profile.last_name)
  localStorage.setItem('first_name', data.profile.first_name || '')
  localStorage.setItem('last_name', data.profile.last_name || '')
  localStorage.setItem('dob', data.profile.dob)
  localStorage.setItem('current_city', data.city || '')
  localStorage.setItem('pinCode', data.pincode || '')
  localStorage.setItem('email', data.email || '')
  localStorage.setItem(
    'userGender',
    (data['profile'] && data['profile']['gender'] && data['profile']['gender'].toLowerCase()) || 'male'
  )
}

export function getDependentsRelation(dependents) {
  if(!dependents) return "";
  return dependents.map(({first_name}) => _.startCase(first_name)).join(', ');
}

// format number into indian number system 2000000 -> 20,00,000
export function formatNumberToIndianNationalSystem(number) {
  if (!number) return 0
  return number.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
}

export function downloadPDF(pdfData, filename) {
  // Create a Blob from the PDF data
  const blob = new Blob([pdfData], { type: 'application/pdf' });

  // Create a URL for the Blob
  const pdfURL = URL.createObjectURL(blob);

  // Create an anchor element
  const link = document.createElement('a');
  link.href = pdfURL;
  link.download = filename; // Set the desired filename for download

  // Append the anchor element to the document
  document.body.appendChild(link);

  // Trigger a click event on the anchor element to initiate download
  link.click();

  // Clean up: Remove the anchor element and revoke the Blob URL
  document.body.removeChild(link);
  URL.revokeObjectURL(pdfURL);
}


export const formatToRupees = (value) => {
  const enteredValue = value;
  if(!enteredValue) return
  const cleanedValue = enteredValue.replace(/[^\d.-]/g, "");
  if(!cleanedValue) return ''
  const parsedValue = parseFloat(cleanedValue);
  if (!isNaN(parsedValue)) {
    const formattedValue = parsedValue.toLocaleString("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
      useGrouping: true,
    });
    return formattedValue
  } else {
    return enteredValue
  }
}

export const getFileNameFromUrl = (url) => {
  if(!url) return "";
  const index = url.lastIndexOf("/");
  const fileName = url.substring(index + 1);
  return fileName;
}