import React from 'react'
import styled from 'styled-components'
import forward from '../assets/images/forward.png'
import insurePlanFooter from '../assets/svgs/insurePlanFooter.svg'

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  // background-color: #436ab2;
  box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.15);
  padding: 1.1rem 1.8rem 1.1rem 2.4rem;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;

  background: url(${insurePlanFooter});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
`

const MonthPayWrapper = styled.div`
  width: 44%;
  // float: left;
  & > :first-child {
    margin-bottom: 6px;
  }
`

const Font10 = styled.div`
  font-family: Avenir;
  font-size: 1rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  color: ${props => (props.color ? props.color : '#666666')};
`

const Font12 = styled.p`
  font-family: Avenir;
  font-size: 1.2rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: #666666;
`

const Font16 = styled.p`
  font-family: Avenir;
  font-size: 1.6rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1.14px;
  color: #fff;
`

const Font20 = styled.p`
  font-family: Avenir;
  font-size: 2rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 1px;
  color: #4a4a4a;
`

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  & img {
    margin-left: 1.8rem;
    width: 3.2rem;
    height: 3.2rem;
  }
`
const PayNowWrapper = styled.div`
  width: 100%;
`

const PolicyDoc = styled.div`
  background-color: #436ab2;
  border-radius: 10px;
  // float: right;
  padding: 1rem;
`

const PayWrapper = styled.span`
  width: 2.5rem;
  display: inline-block;
`

const FooterPayNow = props => {
  return (
    <Footer>
      <MonthPayWrapper>
        <Font12>Earn Points</Font12>
        <Font20>{props.payLabel}</Font20>
      </MonthPayWrapper>
      <PolicyDoc {...props}>
        <FlexContainer>
          <PayNowWrapper>
            <Font16>{props.title}</Font16>
            <Font10 color="#fff">
              Your total is <PayWrapper>{`₹${props.payLabel}`}</PayWrapper>
            </Font10>
          </PayNowWrapper>
          <img src={forward} alt="forward.png" />
        </FlexContainer>
      </PolicyDoc>
    </Footer>
  )
}

export default FooterPayNow
