import React, { useEffect, useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import auth from '../services/authService'
import http from '../services/httpService'

import { getAllUrlParams, setUserDataLocally } from "../utils/utils"

const URLParams = getAllUrlParams(window.location.href, true);
const token = URLParams['token'];
const vitalCoverId = URLParams['vital_cover_id'];
const allowedPaths = ["/dashboard", "/benefits", "/employeeform", "/about"];

// const vitalCoverId = "user_pol_83a1bc56483a17";    // For Testing


const PrivateRoute = ({ path, component: Component, render, ...rest }) => {
  const [shouldRender, setShouldRender] = useState(false);
  useEffect(() => {
    (async function () {
      if (allowedPaths.includes(path)) {
        if (token) {
          localStorage.setItem('token', token);
          const isExpired = auth.isTokenExpired();
          if(isExpired) {
            const newToken = await auth.renewToken(token);
            localStorage.setItem('token', newToken);
          }
          const { data } = await http.get("/users/get_user_data/")
          setUserDataLocally(data);
        }
      }
      window.history.replaceState(null, null, window.location.pathname);
      setShouldRender(true);
    })();
  }, [])

  return (
    <>
      {shouldRender && <Route
        {...rest}
        render={props => {
          if (localStorage.getItem('businessAccount')) {
            return (
              <Redirect
                to={{
                  pathname: '/business/dashboard',
                  state: { from: props.location }
                }}
              />
            )
          }
          if (!auth.getCurrentUser())
            return (
              <Redirect
                to={{
                  pathname: '/',
                  state: { from: props.location }
                }}
              />
            )

          return Component ? <Component {...props} {...(vitalCoverId ? { vitalCoverId: vitalCoverId } : {})} /> : render(props)
        }}
      />}
    </>
  )
}

export default PrivateRoute
