import React, { Component, Fragment } from 'react'
import styled from 'styled-components'
import ArrowBack from '../../../assets/images/arrowBack.png'
import chevron from '../../../assets/images/chevron.png'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

export default class BankRow extends Component {
  render() {
    return (
      <React.Fragment>
        <OuterDiv onClick={() => this.props.onSelectBank(this.props.idx)}>
          <BankName>{this.props.bank.bank_name}</BankName>
          <ArrowImage src={chevron} />
        </OuterDiv>
        <LineDiv />
      </React.Fragment>
    )
  }
}

const OuterDiv = styled.div`
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  // border-bottom: 1px solid #4a4a4a;
  padding: 0 24px 0 24px;
`
const BankName = styled.div`
  font-family: Avenir;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #436ab2;
`
const ArrowImage = styled.img`
  display: flex;
  float: right;
  width: 12px;
  height: 18px;
  object-fit: cover;
`

const LineDiv = styled.div`
  height: 1px;
  background-color: #d8d8d8;
  padding: 0 16px 0 16px;
`
