import React from 'react'
import styled from 'styled-components'
import BusyIndicator from '../components/busyIndicator'

const Overlay = styled.div`
  width:5px;
  height:5px;
  // background: rgba(0, 0, 0, 0.55);
  position:fixed;
  background: ${props => (props.bgColor ? props.bgColor : '#ffffff')};
  display: flex;
  bottom:50;
  margin-bottom:50px
  justify-content:center;
  align-items:center;
  z-index: 99;
  
`

const LaodMoreLoader = props => (
    <Overlay bgColor={props.bgColor}>
        <BusyIndicator />
    </Overlay>
)

export default LaodMoreLoader
