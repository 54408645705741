import React, { Component } from 'react'
import styled from 'styled-components'
import { Question } from '../components/question'

const QusnWrapper = styled.div`
  width: 100%;
  position: absolute;
  height: 100%;
  background-color: #4a4a4a42;
  bottom: 0;
  right: 0;
  z-index: 10;
`


export default class QuestionCard extends Component {
  state = {}

  componentDidMount = () => {
    for (let i = 0; i < this.props.questions.length; i++) {
      this.setState({ [i]: false })
    }

    if (this.props.questions.length > 0) {
      this.setState({ 0: true })
    }
  }

  handleQAns = (qid, value, key) => {
    this.setState({ [qid]: false, [qid + 1]: true })

    return this.props.onChange(value, key, this.props.questions.length === Number(qid) + 1)
    // return this.props.onChange(value, key, Number(qid) + 1)
  }

  render() {
    const { questions, onClose } = this.props

    return (
      <QusnWrapper>
        {questions.map((item, idx) => (
          <Question
            listSize={questions.length}
            key={item.id}
            active={this.state[idx]}
            id={idx + 1}
            title={item.ques}
            onClick={value => this.handleQAns(idx, value, item.key)}
            onClose={e => onClose(e)}
          />
        ))}
      </QusnWrapper>
    )
  }
}
