import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";

import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";
import config from "./config.json";

// import { composeWithDevTools } from 'redux-devtools-extension';
import { PaymentModeReducer } from "./redux/reducers/changePayModeBusiness";
import { theme } from "./theme";

const store = createStore(PaymentModeReducer, applyMiddleware(thunk));

if (config.env === "prod") {
  Sentry.init({
    dsn: "https://5309f8c710ae4dc1a3ec70e063fe2960@o323278.ingest.sentry.io/5390384",
  });
} else {
  Sentry.init({
    dsn: "https://d877e3b424db42d3806b7ac333f192b3@o323278.ingest.sentry.io/5390933",
  });
}

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>,
    rootElement
  );
} else {
  ReactDOM.render(
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Provider store={store}>
          <App />
        </Provider>
      </BrowserRouter>
    </ThemeProvider>,
    rootElement
  );
}

// ReactDOM.render ( <BrowserRouter>
// <App />
// </BrowserRouter>
// , rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister({
  // onUpdate: registration => {
  //   const waitingServiceWorker = registration.waiting
  //   if (waitingServiceWorker) {
  //     waitingServiceWorker.addEventListener('statechange', event => {
  //       console.log('%c Service Worker ',
  //           'color: white; background-color: #95B46A',
  //           'Service Worker updated');
  //       if (event.target.state === 'activated') {
  //       }
  //     });
  //     waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
  //   }
  // }
});
