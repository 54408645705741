export const colors = {
  primaryColor: "#436AB2",
  accentColor: "#0054E3",
  linkColor: "#4A90E2",
  whiteColor: "#ffffff",
  blackColor: "#000000",
  defaultFontColor: "#4a4a4a",
  baseBlue: "#436AB2",
  cardBackgroundColor: "#F1F3F6",
  neoPrimaryColor: "#002060",
};

export const theme = {
  ...colors
};
