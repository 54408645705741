import React from "react";
import styled from "styled-components";
import creditEligible from "../assets/svgs/creditEligible.svg";
import creditNotEligible from "../assets/svgs/creditNotEligible.svg";
import pendingCredit from "../assets/images/pendingCredit.png";
import Icons from "./icons";

const Home = styled.div`
  width: 100%;
  height: 100vh;
  padding: 0 2.4rem;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Modal = styled.div`
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.15),
    inset 0 1px 0 0 rgba(255, 255, 255, 0.9);
  border-radius: 1rem;
  padding: 2.4rem 2.4rem 2.4rem 2.8rem;
  & img {
    width: 14.4rem;
    margin-bottom: 2.4rem;
  }
`;

const NextBtn = styled.button`
  background: none;
  border: 0;
  outline: 0;
  &:active,
  &:focus {
    outline: none;
  }
`;

const ApprovedWrapper = styled.div`
  & h1 {
    margin-bottom: 1.8rem;
  }
  p:nth-of-type(3) {
    margin: 1.2rem 0 8px;
  }
  > div {
    margin-top: 1.2rem;
  }
`;

const NextBtnWrapper = styled.div`
  margin-top: 1.8rem;
  display: flex;
  flex-direction: row-reverse;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const Font24 = styled.p`
  font-family: Avenir;
  font-size: 2.4rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: 0.21px;
  color: #f27576;
`;

const Font20 = styled.h1`
  font-family: Avenir;
  font-size: 2rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 0.18px;
  color: #4a4a4a;
`;

const Font16 = styled.p`
  font-family: Avenir;
  font-size: 1.6rem;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  text-align: right;
  text-transform: uppercase;
  color: #436ab2;
`;

const Font14 = styled.p`
  font-family: Avenir;
  font-size: 1.4rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.71;
  letter-spacing: 0.18px;
  color: #4a4a4a;
`;
const Font10 = styled.p`
  font-family: Avenir;
  font-size: 1rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 2.4;
  letter-spacing: 0.5px;
  color: #4a4a4a;
`;
const CreditApprovedModal = props => {
  const img = props.rejected
    ? creditNotEligible
    : props.pending || props.preApproved
    ? pendingCredit
    : creditEligible;
  
  return (
    <Home>
      <Modal>
        <FlexWrapper>
          <img src={img} alt="creditStatus.svg" />
          {(props.pending || props.rejected || props.preApproved) && (
            <div onClick={() => props.onHandleClick("/dashboard")}>
              <Icons icon="crossSmall" />
            </div>
          )}
        </FlexWrapper>
        {props.pending ? (
          <ApprovedWrapper>
            <Font20>Oops</Font20>
            <Font14>We encountered some problem.</Font14>
            <Font14>Please contact us on care@getvital.in</Font14>
          </ApprovedWrapper>
        ) : props.rejected ? (
          <ApprovedWrapper>
            <Font20>Oops</Font20>
            <Font14>You are not eligible for Vital EMI.</Font14>
          </ApprovedWrapper>)
         : props.preApproved ? (
          <ApprovedWrapper>
            <Font20>Oops</Font20>
            <Font14>Could not assess eligibilty.</Font14>
            <Font14>Please contact us on <a href="mailto:care@getvital.in?Subject=Credit%20issue" target="_blank">care@getvital.in</a></Font14>
          </ApprovedWrapper>
        ) : (
          <ApprovedWrapper>
            <Font20>Congratulations</Font20>
            <Font14>You are eligible for Vital EMI. </Font14>
            <Font14> Now avail Vital EMI without any hassle.</Font14>
            <Font10>APPROVED AMOUNT</Font10>
            <Font24>₹{props.creditAmt}</Font24>
          </ApprovedWrapper>
        )}
        {!props.pending && !props.rejected && !props.preApproved && (
          <NextBtnWrapper>
            <NextBtn
              onClick={() => props.onHandleClick("/agreement")}
              disabled={!props.creditAmt}
            >
              <Font16>Next</Font16>
            </NextBtn>
          </NextBtnWrapper>)
        }
        {/* {(props.preApproved || props.rejected || props.pending) && (
          <NextBtnWrapper>
            <NextBtn onClick={() => props.onHandleClick("/dashboard")}>
              <Font16>OK</Font16>
            </NextBtn>
          </NextBtnWrapper>
        )} */}
      </Modal>
    </Home>
  );
};
export default CreditApprovedModal;
