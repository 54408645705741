import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {Ptag} from '../fonts'

const Button = ({text, onClickButton}) => {
    if (!text)
        return null
    return (
        <Root onClick={onClickButton} >
            <Ptag textTransform='uppercase' fontSize='10px' fontWeight='600' letterSpacing='1.67px' textAlign='center' color="#436ab2"  >
                {text}
            </Ptag>
        </Root>
    )
} 

export default Button


const Root = styled.button`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px 16px;
    border-radius: 18px;
    background-color: #fff;
    justify-content: center;
    outline: 0;
    border: 0;
    width: fit-content;
    &:active,
    &:focus {
        outline: none;
    }
`