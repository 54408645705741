import React from 'react'
import { Field } from 'formik'
import styled from 'styled-components'


const RenderSquareInput = ({ name, value, readOnly ,validate = null,  ...restProps }) => {
  let extraProps = {
    type: "number",
    pattern: "[0-9]*",
    inputmode: "numeric",
    value
  }
  if (readOnly){
    extraProps = {
      value: 'N/A'
    }
  }
  
  return(
    <Field
    name={name}
    validate={validate}
    render={({ field }) => (
      <Input
        {...field}
        {...restProps}
        {...extraProps}
      />
    )}
  />
  );
}


export default RenderSquareInput


const Input = styled.input`
  font-size: 1.6rem;
  font-family: Open Sans;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  color: #4a4a4a;
  border-radius: 5px;
  border: 1px solid #436ab2;
  background: white;
  padding: 7px;
  text-align: center;
  z-index: 1;
  &:focus {
    outline: none;
    background: none !important;
  };
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    transition: background-color 5000s 0s;
  };
  -webkit-opacity: 1;
  -webkit-appearance: none;

`