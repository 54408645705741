import styled from "styled-components";

export const Ptag = styled.p`

  text-decoration: ${(props) =>
    props.textDecoration ? props.textDecoration : "inherit"};
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : "Avenir"};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "left")};
  max-width: ${(props) => (props.maxwidth ? props.maxwidth : "100%")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "12px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "500")};
  color: ${(props) => (props.color ? props.color : " #4a4a4a")};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : "normal"};
  font-stretch: normal;
  font-style: normal;
  line-height: ${(props) =>
    props.letterHeight ? props.letterHeight : "normal"};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0")};
  margin-left:${(props) => (props.marginLeft ? props.marginLeft : "0")};
  margin-right:${(props) => (props.marginRight ? props.marginRight : "0")};
  margin-bottom:${(props) => (props.marginBottom ? props.marginBottom : "0")};
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : "none"};
  opacity: ${(props) => (props.opacity ? props.opacity : "1")};
`;
