import rel from "../../../assets/images/chiIcon.png";
import icicil from "../../../assets/images/iciciLombard.png";
import bhartilogo from "../../../assets/images/axalogo.png";
import kotakLogo from "../../../assets/images/kotakLogo.png"
import nivaLogo from "../../../assets/images/nivaLogo.jpeg"
import digitLogo from "../../../assets/images/digit.png"
import healthians from "../../../assets/images/healthians.png"
import redcliffe from "../../../assets/images/Redcliffe.png"
import tataaig from "../../../assets/images/tataAig.png"
import xyz from "../../../assets/images/xyz.png"
import universalSompo from "../../../assets/images/UniversalSompo.png"

export const insurers = {
    "ICICI LOMBARD": {
    logo: icicil,
    cashlessNetwork: "",
    claimProcess: "/claimprocess/3",
  },
  "BHARTI AXA": {
    logo: bhartilogo,
    cashlessNetwork: "",
    claimProcess: "/claimprocess/2",
  },
  "RELIGARE": {
    logo: rel,
    cashlessNetwork: "https://www.careinsurance.com/health-plan-network-hospitals.html",
    claimProcess: "/cashlessclaim"
  },
  "KOTAK": {
    logo: kotakLogo,
    cashlessNetwork: "https://www.kotakgeneral.com/Externalfiles/Mediassit-hospital-list-03-04-2023.xlsb",
    claimProcess: ""
  },
  "NIVA BUPA": {
    logo: nivaLogo,
    cashlessNetwork: "https://transaction.nivabupa.com/Pages/hospitalnetwork.aspx",
    claimProcess: ""
  },
  "TATA_AIG": {
    logo: tataaig,
    cashlessNetwork: "https://www.tataaig.com/locator/cashless-network-hospitals",
    claimProcess: ""
  },
  "GO_DIGIT": {
    logo: digitLogo,
    cashlessNetwork: "https://www.medibuddy.in/networkHospitals",
    claimProcess: ""
  },
  "HEALTHIANS": {
    logo: healthians,
    cashlessNetwork: "",
    claimProcess: ""
  },
  "REDCLIFFE": {
    logo: redcliffe,
    cashlessNetwork: "",
    claimProcess: ""
  },
  "UNIVERSAL_SOMPO": {
    logo: universalSompo,
    cashlessNetwork:  "https://www.universalsompo.com/cashless-hospitals/Hospital",
    claimProcess: ""
  },
  "XYZ": {
    logo: xyz,
    cashlessNetwork:  "https://www.careinsurance.com/health-plan-network-hospitals.html",
    claimProcess: ""
  }
}

const PolicyType = {
    COMPREHENSIVE: 'comprehensive',
    GROUP_COVER: 'group_cover',
    ACCIDENTAL: 'accidental',
    HEART: 'heart',
    PED_NON_INSULIN: 'ped_non_insulin',
    PED_INSULIN: 'ped_insulin',
    WELLNESS:'wellness',
    GHPA: 'GHP_A',
    GHPB: 'GHP_B',
    GHPC: 'GHP_C',
    GHPD: 'GHP_D',
    CORPORATE:'CORPORATE',
    OTHER: 'other',
    ANNUALLY:'annually',
    MONTHLY:'monthly',
    COMPREHENSIVE_SBO: 'comprehensive_sbo',
    COMPREHENSIVE_SBOM: 'comprehensive_sbom',
    KOTAK_ESC: 'kotak_esc',
    KOTAK_ESC_M: 'kotak_esc_m',
    KOTAK_PARENTAL: 'kotak_parental',
    KOTAK_UNLIMITED: 'kotak_unlimited',
    KOTAK_UNLIMITED_M: 'kotak_unlimited_m',
    GMC_NON_MATERNITY: 'gmc_non_maternity',
    GMC_MATERNITY: 'gmc_maternity',
    DIGIT_RR_SINGLE_AC : "digit_rr_single_ac",
    DIGIT_RR_1_2_PERCENT : "digit_rr_1_2_percent",
    DIGIT_RR_2_4_PERCENT : "digit_rr_2_4_percent",
    DIGIT_RR_SINGLE_AC_M : "digit_rr_single_ac_m",
    DIGIT_RR_1_2_PERCENT_M : "digit_rr_1_2_percent_m",
    DIGIT_RR_2_4_PERCENT_M : "digit_rr_2_4_percent_m"
  }  

export const PolicyMappings = {
  COMPREHENSIVE : {
    name: 'Total Care Plan',
    pdfUrl : "https://vitalimages.s3.ap-south-1.amazonaws.com/policyDocuments/comprehensive.pdf",
    type: PolicyType['COMPREHENSIVE']
  },
  GROUP_COVER : {
    name: 'Group Plan',
    // pdfUrl : "https://vitalimages.s3.ap-south-1.amazonaws.com/policyDocuments/comprehensive.pdf",
    type: PolicyType.GROUP_COVER
  },
  COMPREHENSIVE_SBO : {
    name: 'Group Care Plan',
    type: PolicyType['COMPREHENSIVE_SBO'],
    isCorporate: true,
  },
  HEART : {
    name: 'Total Care Cardiac ',
    pdfUrl: "https://vitalimages.s3.ap-south-1.amazonaws.com/policyDocuments/Cardiac+Cover+Policy+Document+(1).pdf",
    type: PolicyType['HEART']
  },
  PED_INSULIN : {
    name : 'Total Care Diabetes',
    pdfUrl:"https://vitalimages.s3.ap-south-1.amazonaws.com/policyDocuments/Diabetic+Cover+Policy+Document.pdf",
    type : PolicyType['PED_INSULIN']
  },
  PED_NON_INSULIN : {
    name : 'Total Care Diabetes/Hypertension',
    pdfUrl:"https://vitalimages.s3.ap-south-1.amazonaws.com/policyDocuments/Pre-Existing+Cover+Policy+Document.pdf",
    type : PolicyType['PED_NON_INSULIN']
  },
  ACCIDENTAL : {
    name : "Accidents and Infectious Illness",
    type : PolicyType['ACCIDENTAL']
  },
  CORPORATE : {
    name : "Group Care Plan ",
    type : PolicyType['CORPORATE']
  },
  WELLNESS : {
    name : "Care Lite Plan",
    type : PolicyType['WELLNESS']
  },
  GHP_A : {
    name : "Group Care Plan",
    type : PolicyType['GHPA'],
    isCorporate: true,
  },
  GHP_B : {
    name : "Group Care Plan",
    type : PolicyType['GHPB'],
    isCorporate: true,
  },
  GHP_C : {
    name : "Group Care Plan",
    type : PolicyType['GHPC'],
    isCorporate: true,
  },
  GHP_D : {
    name : "Group Care Plan",
    type : PolicyType['GHPD'],
    isCorporate: true,
  },
  COMPREHENSIVE_SBOM : {
    name: 'Group Care Plan',
    type: PolicyType['COMPREHENSIVE_SBOM'],
    isCorporate: true
  },
  KOTAK_ESC: {
    name: 'Kotak Group Plan',
    type: PolicyType['KOTAK_ESC'],
    isCorporate: true,
    pdfUrl: "https://vitalimages.s3.ap-south-1.amazonaws.com/policyDocuments/VITAL_Kotak_Group_Care_Policy+Document_without_Maternity.pdf"

  },
  KOTAK_ESC_M: {
    name: 'Kotak Group Plan',
    type: PolicyType['KOTAK_ESC_M'],
    isCorporate: true,
    pdfUrl: 'https://vitalimages.s3.ap-south-1.amazonaws.com/policyDocuments/VITAL_Kotak_Group_Care_Policy+Document_with_Maternity.pdf',
  },
  KOTAK_PARENTAL: {
    name: 'Kotak Group Plan',
    type: PolicyType['KOTAK_PARENTAL'],
    isCorporate: true,
    pdfUrl: 'https://vitalimages.s3.ap-south-1.amazonaws.com/Marketing/policy/Group+Care+Policy+Document_Kotak_Without_Maternity.pdf',
  },
  KOTAK_UNLIMITED: {
    name: 'Kotak Group Plan',
    type: PolicyType['KOTAK_UNLIMITED'],
    isCorporate: true,
    pdfUrl: 'https://vitalimages.s3.ap-south-1.amazonaws.com/Marketing/policy/Group+Care+Policy+Document_Kotak_Without_Maternity+-+no+capping.pdf',
  },
  KOTAK_UNLIMITED_M: {
    name: 'Kotak Group Plan',
    type: PolicyType['KOTAK_UNLIMITED_M'],
    isCorporate: true,
    pdfUrl: 'https://vitalimages.s3.ap-south-1.amazonaws.com/Marketing/policy/Group+Care+Policy+Document_Kotak_With_Maternity_no+capping.pdf',
  },
  GMC_NON_MATERNITY: {
    name: 'Group Mediclaim Cover',
    type: PolicyType['GMC_NON_MATERNITY'],
    isCorporate: true,
    // pdfUrl: 'https://vitalimages.s3.ap-south-1.amazonaws.com/policyDocuments/VITAL_Kotak_Group_Care_Policy+Document_with_Maternity.pdf',
  },
  GMC_MATERNITY: {
    name: 'Group Mediclaim Cover',
    type: PolicyType['GMC_MATERNITY'],
    isCorporate: true,
    // pdfUrl: 'https://vitalimages.s3.ap-south-1.amazonaws.com/policyDocuments/VITAL_Kotak_Group_Care_Policy+Document_with_Maternity.pdf',
  },
  DIGIT_RR_SINGLE_AC: {
    name: 'Group Care Plan',
    type: PolicyType['DIGIT_RR_SINGLE_AC'],
    isCorporate: true,
    pdfUrl: 'https://vitalimages.s3.ap-south-1.amazonaws.com/Marketing/policy/Group+Care+Policy+Document_Kotak_Without_Maternity.pdf',
  },
  DIGIT_RR_1_2_PERCENT: {
    name: 'Group Care Plan',
    type: PolicyType['DIGIT_RR_1_2_PERCENT'],
    isCorporate: true,
    pdfUrl: 'https://vitalimages.s3.ap-south-1.amazonaws.com/Marketing/policy/Group+Care+Policy+Document_Kotak_Without_Maternity.pdf',
  },
  DIGIT_RR_2_4_PERCENT: {
    name: 'Group Care Plan',
    type: PolicyType['DIGIT_RR_2_4_PERCENT'],
    isCorporate: true,
    pdfUrl: 'https://vitalimages.s3.ap-south-1.amazonaws.com/Marketing/policy/Group+Care+Policy+Document_Kotak_Without_Maternity.pdf',
  },
  DIGIT_RR_SINGLE_AC_M: {
    name: 'Group Care Plan',
    type: PolicyType['DIGIT_RR_SINGLE_AC_M'],
    isCorporate: true,
    pdfUrl: 'https://vitalimages.s3.ap-south-1.amazonaws.com/Marketing/policy/Group+Care+Policy+Document_Kotak_Without_Maternity.pdf',
  },
  DIGIT_RR_1_2_PERCENT_M: {
    name: 'Group Care Plan',
    type: PolicyType['DIGIT_RR_1_2_PERCENT_M'],
    isCorporate: true,
    pdfUrl: 'https://vitalimages.s3.ap-south-1.amazonaws.com/Marketing/policy/Group+Care+Policy+Document_Kotak_Without_Maternity.pdf',
  },
  DIGIT_RR_2_4_PERCENT_M: {
    name: 'Group Care Plan',
    type: PolicyType['DIGIT_RR_2_4_PERCENT_M'],
    isCorporate: true,
    pdfUrl: 'https://vitalimages.s3.ap-south-1.amazonaws.com/Marketing/policy/Group+Care+Policy+Document_Kotak_Without_Maternity.pdf',
  }
} 



export const PolicyWarningStatuses = {
  EMANDATE_PROCESSING: {
    text: 'emandate_processing',
    warning: 'Your payment is authorized. It will be debited within 72 hours.'
  },
  GRACE: {
    text: 'grace',
    warning: 'Your policy is currently in grace and all your benefits are inactive. Please pay immediately.'
  }
}

export const DiscountsText = {
  monthly:'Monthly ',
	bimonthly:'Bi-Monthly ',
	quarterly:'Quarterly ',
	halfyearly:'Half Yearly ',
	annually:'Yearly '

}

export const PaymentAlertText = {
  'UNDER REVIEW':'Your insurance policy is in review. Your plan and benefits will be activated once the policy is approved',
	'GRACE':'Please make payment to use all your benefits',
	'IN PROCESS':'Payment in progress.Your plan and benefits will be activated once payment is completed.',

}

export const MonthType = {
  monthly:'1',
	bimonthly:'2',
	quarterly:'3',
	halfyearly:'6',
	annually:'12'
}
  
export default PolicyType