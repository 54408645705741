import React, { Component } from 'react'
import styled from 'styled-components'
import {Header, EmployeeDetails, RenderSquareInput} from '../../components/business'
import {Ptag} from '../../components/fonts'
import {LargeButton} from '../../components'
import { Formik, FieldArray, Form } from 'formik'
import plus from '../../assets/images/addCircle.png'
import { OverlayLoader } from '../../components'
import http from '../../services/httpService'
import TagManager from 'react-gtm-module'
import {gtmCode} from '../../utils/utils'
import ageBands from '../../utils/ageBands'


export default class Employees extends Component{

    constructor(props){
        super(props)
        const membersIncluded = localStorage.getItem('membersType')
        // const employeeDetailsType = numberOfEmployees < 4 && membersIncluded > 0 ?  'detailsInput' : 'countInput'
        const employeeDetailsType = 'countInput'

        if (employeeDetailsType === 'countInput'){
            
            const employeeCounts = ageBands.map( item =>  ({
                    self: null,
                    spouse: null,
                    kids: null,
                    parents: null
                }))
            
            
            this.state = {
                loading: true,
                ageBands: membersIncluded == 2 ? ageBands : ageBands.slice(0,-4),
                employeeDetailsType,
                employeeCounts,
                membersIncluded
            }
        } else {
            this.state = {
                loading: true,
                employees: [{
                    members : [
                        {
                            relationship: 'self',
                            age: '',
                        }
                    ],
                    expanded: true,
                }],
                employeeDetailsType
            }
        }
    }

    async componentDidMount() {
        const tagManagerArgs = {
            gtmId: gtmCode(),
          }
        TagManager.initialize(tagManagerArgs)
        let data = JSON.parse(localStorage.getItem('employees')) 
        if (data && data['employees']){
            let employees = []
            for ( let employee of data['employees']){
                let memberStates = {
                    expanded: false,
                    members : [],
                }
                for ( let dependent of employee['members']){
                    let dependentState = {
                        relationship: dependent['relationship'].toLowerCase(),
                        age: dependent['age']
                    }
                    memberStates['members'].push(dependentState)
                }
                employees.push(memberStates)
            }
            this.setState({employees})
        }
        this.setState({loading: false})
    }

    onAddFamilyMembers = (id, relation, formikProps) => {
        let values = formikProps.values['employees']
        values[id]['members'].push({
            'relationship': relation,
            'age': ''
        })
        this.setState({employees : values})
    }

    onDeleteFamilyMember = (id, index, formikProps) => {
        let values = formikProps.values['employees']
        const touchedMembers = formikProps.touched['employees'] && formikProps.touched['employees'][id] && formikProps.touched['employees'][id]['members']
        const touched = formikProps.touched
        if (touchedMembers){
            touchedMembers.splice(index, 1)
            touched['employees'][id]['members'] = touchedMembers
            formikProps.setTouched(touched)
        }
        values[id]['members'].splice(index,1)
        this.setState({employees : values})
    }

    addEmployee = (formikProps) => {
        let values = formikProps.values['employees']
        let allSaved = true
        const initialValues = this.state.employees
        const {setFieldTouched} = formikProps
        if(values && values.length && values.length > 0){
            let isValid = true
            
            for(let i in values){
                let isEmployeeValid = true
                for(let j in values[i]['members']){
                    if (!values[i]['members'][j]['age']){
                        isEmployeeValid = false
                        setFieldTouched(`employees.${i}.members.${j}.age`, true)
                    }
                }
                
                if (isEmployeeValid){
                    if(initialValues[i]['expanded']){
                        allSaved = false
                    }
                    else {
                        values[i]['expanded'] = false
                    }
                } else {
                    values[i]['expanded'] = true
                    isValid = false
                }
            }
            if (!isValid)
                return
        }
        if (!allSaved){
            alert('Please save the existing employees before proceeding.')
            return
        }
        values.push({
            members : [
                {
                    relationship: 'self',
                    age: '',
                }
            ],
            expanded: true,
        })
        this.setState({employees : values})
        
    }

    validateForm = (values) => {
        const membersIncluded = localStorage.getItem('membersType')
        const mapping = ['E', 'ESC', 'ESCP']
        if (this.state.employeeDetailsType === 'countInput'){
            const employeesCount = values && values['employeesCounts'] && values['employeesCounts'].reduce( (accumalator, item) => accumalator + (item['self'] || 0), 0)
            if (!employeesCount){
                alert('Please input atleast 1 employee to proceed')
                return false
            }
            
            
            const member_count = ageBands.map( (item, index) => 
                ({
                    age_start: Number(item['age'].slice(0,item['age'].indexOf('-'))),
                    age_end: Number(item['age'].slice(item['age'].indexOf('-')+1)),
                    employee_count: values && values['employeesCounts'] && values['employeesCounts'][index] 
                        && typeof(values['employeesCounts'][index]['self']) === 'number' ? values['employeesCounts'][index]['self'] : 0,
                    child_count: values && values['employeesCounts'] && values['employeesCounts'][index] 
                        && typeof(values['employeesCounts'][index]['kids']) === 'number' ? values['employeesCounts'][index]['kids'] : 0,
                    spouse_count: values && values['employeesCounts'] && values['employeesCounts'][index] 
                        && typeof(values['employeesCounts'][index]['spouse']) === 'number' ? values['employeesCounts'][index]['spouse'] : 0,
                    parent_count: values && values['employeesCounts'] && values['employeesCounts'][index] 
                        && typeof(values['employeesCounts'][index]['parents']) === 'number' ? values['employeesCounts'][index]['parents'] : 0,
                })
            )
            const filtered = member_count.filter((item) => item['employee_count'] || item['child_count'] || item['spouse_count'] || item['parent_count'])

            const requestObject = {
                plan_type : 'Monthly',
                "model_type" : "Count",
                member_count : filtered
            }
            
            return requestObject
        } else {
            if(values.employees && values.employees.length && values.employees.length > 0){
                let isValid = true
                const employees = this.state.employees
                const requestObject = {
                    policy_type : mapping[membersIncluded],
                    model_type : "Detailed",
                    member_details: []
                }
                for(let i in values['employees']){
                    let dependents = {
                        'dependents' : []
                    }
                    let isEmployeeValid = true
                    for(let j in values['employees'][i]['members']){
                        if (values['employees'][i]['members'][j]['age']){
                            values['employees'][i]['members'][j]['age'] = parseInt(values['employees'][i]['members'][j]['age'])
                            values['employees'][i]['members'][j]['relationship'] = values['employees'][i]['members'][j]['relationship'].toUpperCase()
                            dependents['dependents'].push(values['employees'][i]['members'][j])
                        } else {
                            isEmployeeValid = false
                        }
                    }
                    if (isEmployeeValid && !employees[i]['expanded'] ){
                        requestObject['member_details'].push(dependents)
                        employees[i]['expanded'] = false
                    } else {
                        alert('Please save the existing employees before proceeding')
                        return false
                    }
                }
                this.setState({employees: employees})
                return isValid ? requestObject : false
            }
        }
        return false
        
    }

    onDeleteEmployee = (formikProps, index) => {
        let values = formikProps.values['employees']
        let touched = formikProps.touched && formikProps.touched['employees']
        if (touched){
            touched.splice(index, 1)
            formikProps.setTouched(touched)
        }
        values.splice(index, 1)
        this.setState({employees: values})
    }

    handleSubmit = async (values) => {
        this.setState({loading: true})
        const requestObject = this.validateForm(values);
        
        if(! requestObject){
            this.setState({loading: false})
            return
        }   
        try{
            if (this.state.employeeDetailsType === 'countInput'){
                localStorage.setItem('employeesCount',JSON.stringify(values))
            } else {
                localStorage.setItem('employees', JSON.stringify(values))
            }
            const {data} = await http.post('/business/plan/',{
                ...requestObject
                }
            )
            localStorage.setItem('plan_id',data['plan_id'])
            this.props.history.push('/business/plans')
            
        }catch(ex){
            console.error(ex)
            this.setState({loading: false})
        }    
    }

    onClickCardHeader = (cardId) => {
        const values = this.state.employees
        values[cardId]['expanded'] = !values[cardId]['expanded']
        this.setState({employees: values})
    }

    onSaveEmployee = (formikProps, cardId) => {
        const employeeMembers = formikProps.values && formikProps.values['employees'] && formikProps.values['employees'][cardId] && formikProps.values['employees'][cardId]['members']
        let valid = true
        for (let member in employeeMembers){
            if ( !employeeMembers[member]['age']){
                formikProps.setFieldTouched(`employees.${cardId}.members.${member}.${'age'}`, true)
                valid = false
            }
        }
        if (!valid)
            return
        else
            this.onClickCardHeader(cardId)
    }


    renderEmployeeFamilyDetails = (item, index, formikProps) => {
        return(
            <EmployeeDetails
                key={index}
                id={index}
                employee={item}
                formikProps={formikProps}
                onAddFamilyMembers={this.onAddFamilyMembers}
                onDeleteFamilyMember={this.onDeleteFamilyMember}
                onClickCardHeader={this.onClickCardHeader}
                onDeleteEmployee={this.onDeleteEmployee}
                onClickAddDependentButton={this.onClickAddDependentButton}
                onSaveEmployee={this.onSaveEmployee}
            />
        )
    }

    renderAddEmployee = (formikProps) => {
        const opacity = this.state.employees && this.state.employees.filter(item => item['expanded']).length > 0 ? '0.4' : '1'
        return (
            this.state.employeeDetailsType === 'detailsInput' &&
            <AddEmployeeWrapper id="business_employee_add_employee" opacity={opacity} onClick={() => this.addEmployee(formikProps)}>
                <img src={plus} height='24px' width='24px' style={{'marginRight': '10px'}}/>
                <Ptag fontWeight='600' fontSize='16px' letterSpace='0.25px' color="#00bfa5">{'Add Employee'}</Ptag>
            </AddEmployeeWrapper>
        )
    }

    renderEmployeesForm = (formikProps) => {
        return (
            this.state.employeeDetailsType === 'detailsInput' &&
            <FieldArray 
                name='employees'
                render={
                    (arrHelper) => (
                        this.state.employees && 
                        this.state.employees.map((item, index, arr) => 
                            this.renderEmployeeFamilyDetails(item, index, formikProps)
                        )
                    )
                }
            />
        )
    }

    renderAgeBand = (item, index, formikProps) => {
        const membersIncluded = this.state.membersIncluded
        // if (membersIncluded == 0 && index < 2){
        //     return null
        // }
        return(
            <AgeRow key={index}>
                <Ptag style={{'backgroundColor':'white', 'zIndex': 12, 'height': '40px', 'paddingTop':'5px'}} textAlign='left' color='#6D7278' fontSize='16px' fontWeight='600' letterSpace='1px' letterHeight='22px'>
                    {`${item['age']} years`}
                </Ptag>
                <RenderSquareInput readOnly={item['kid']} name={`employeesCounts.${index}.self`} />
                { membersIncluded > 0 && <RenderSquareInput readOnly={item['kid']} name={`employeesCounts.${index}.spouse`} />}
                { membersIncluded > 0 && <RenderSquareInput readOnly={item['kid']} name={`employeesCounts.${index}.kids`} /> }
                { membersIncluded > 1 && <RenderSquareInput readOnly={item['kid']} name={`employeesCounts.${index}.parents`} />}
            </AgeRow>
        )
    }

    renderLivesCovered = (formikProps) => {
        const countArray = formikProps && formikProps.values && formikProps.values['employeesCounts'] && Object.values(formikProps.values['employeesCounts']) && Object.values(formikProps.values['employeesCounts']).map(item => Object.values(item).reduce((acc, value) => acc + (typeof(value) === 'number' ?  value : 0) ,0))
        const count = countArray.reduce((acc,value) => acc + value, 0)
        return(
            <LivesCovered>
                <Ptag>
                    {`Lives Covered : ${count}`}
                </Ptag>
            </LivesCovered>
        )
    }

    renderEmployeeCounts = (formikProps) => {
        const membersIncluded = this.state.membersIncluded
        return(
            this.state.employeeDetailsType ===  'countInput'  &&
            <FormWrapper>
                <AgeRow>
                    <Ptag textTransform='uppercase' style={{'backgroundColor':'white', 'zIndex': 12,}} textAlign='left' letterSpacing='0.5px'>
                        {'Age Bands'}
                    </Ptag>
                    <Ptag textTransform='uppercase' textAlign='center' letterSpacing='0.5px'>
                        {'Employee'}
                    </Ptag>
                    { membersIncluded > 0 && <Ptag textTransform='uppercase' textAlign='center' letterSpacing='0.5px'>
                        {'Spouse'}
                    </Ptag>}
                    { membersIncluded > 0 && <Ptag textTransform='uppercase' textAlign='center' letterSpacing='0.5px'>
                        {'Kids'}
                    </Ptag>}
                    { membersIncluded > 1 && <Ptag textTransform='uppercase' textAlign='center' letterSpacing='0.5px'>
                        {'Parents'}
                    </Ptag>}
                </AgeRow>
                <FieldArray 
                    name='employeesCounts'
                    render={
                        (arrHelper) => (
                            this.state.ageBands && 
                            this.state.ageBands.map((item, index, arr) => 
                                this.renderAgeBand(item, index, formikProps)
                            )
                        )
                    }
                />
                <AgeRow>
                    {this.renderLivesCovered(formikProps)}
                </AgeRow>
            
            </FormWrapper>
            
        )
    }

    render(){
        const opacity = (this.state.employeeDetailsType === 'detailsInput') && this.state.employees && (this.state.employees.filter(item => item['expanded']).length > 0 || this.state.employees.length === 0 )? '0.4' : '1'
        const initialValues = this.state.employeeDetailsType === 'detailsInput' ? 
            {employees: this.state.employees} : 
            {employeesCounts : this.state.employeeCounts}
        return(
            <Root>
                <Header onClickBack={() => this.props.history.push('/business/memberstype')} progressPercentage={30}/>
                <Body>
                    <Ptag fontFamily='Proxima Nova' fontSize='24px' fontWeight='500' letterHeight='1.33' letterSpacing='0.35px'>
                        {'member details'}
                    </Ptag>
                    <Ptag color='#5e5e5e' letterSpacing='0.18px' >
                        {'add the number of members in each age band'}
                    </Ptag>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize={true}
                        onSubmit={this.handleSubmit}
                        render= {formikProps =>
                            <Form>
                                {this.renderEmployeeCounts(formikProps)}
                                {/* {this.renderEmployeesForm(formikProps)}
                                {this.renderAddEmployee(formikProps)} */}
                                <SubmitWrapper>
                                    <LargeButton id="business_employee_view_plans" opacity={opacity} label='VIEW PLANS' type='submit'/>
                                </SubmitWrapper>
                            </Form>
                        }
                    />
                </Body>
                {this.state.loading && <OverlayLoader/>}
            </Root>
        )
    }
}

const Root = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`

const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
    flex: 1;
    & > form {
        display: flex;
        flex-direction: column;
        flex : 1;
    }
`;

const AddEmployeeWrapper = styled.div`
    display: flex;
    border-radius: 15px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 26px 0;
    width: 100%;
    margin-top: 24px;
    justify-content: center;
    opacity: ${props => props.opacity};
`;

const SubmitWrapper = styled.div`
    display: flex;
    width: 100%;
    margin-top: 24px;
    flex: 1;
    align-items: flex-end;
`;

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
    overflow-y: auto;
    margin: 24px -24px 0 0;
    ::-webkit-scrollbar {
        display: none;
    }
`

const AgeRow = styled.div`
    display: flex;
    justify-content:space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: 24px;
    width: max-content;
    & > :first-child{
        position: sticky;
        position: -webkit-sticky;
        left:0;
        min-width: 114px;
        margin-right: 20px;
    };
    & > :not(:first-child){
        width: 86px;
        margin-right: 12px;
    }
`

const LivesCovered = styled.div`
    display: flex;

`