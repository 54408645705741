import React, { Component,useState, useEffect } from 'react'
import styled from 'styled-components'
import SplitDetailCard from '../../components/GmcDashbaord/SplitDetailCard'
import http from '../../services/httpService'
import { Ptag } from '../../components/fonts'
import { withRouter } from 'react-router'
import downloadcsv from '../../assets/images/downloadcsv.png'
import { useHistory } from 'react-router-dom'
import InsurancePremium from '../../components/business/Cards/InsurancePremiun'
import CdCardDetail from '../../components/business/Cards/CdCardDetails'
import { planName } from '../../constants/businessConstant'
import { toLower } from 'lodash'


const PrePurchasePlanRecommendation = () =>{
    const [loading, setLoading] = useState(false)
    const validate_emp_data = JSON.parse(localStorage.getItem('endorsment_data'))
    const company_name = localStorage.getItem('cname')
    const getTotalAmountLogo = () =>{
        if(((validate_emp_data && validate_emp_data.sum_premium_due).toFixed(2) || 0) > ((validate_emp_data && validate_emp_data.sum_refunds).toFixed(2) || 0)){
            return "+"
        } else {
            return "-"
        }
    }
    const history = useHistory()
    // const emp = validate_emp_data.filter(item => item.input_file_data)
    // console.log(validate_emp_data, emp)

    const handleSubmit = async() =>{
        try{
            setLoading(true)
            const userplan_id = ('userplan_id', localStorage.getItem('userplan_id') || sessionStorage.getItem('userplan_id'))
            
            const {data} = await http.post("business/employee/",{
                userplan_id: userplan_id,
                input_file_data: validate_emp_data.input_file_data
            })
            localStorage.removeItem('endorsment_data')
            history.push('/business/thankYou')
        } catch(ex){
            alert(ex)
        }
    }

    const renderCards = () => {
        let planKey = Object.entries(validate_emp_data).filter(([key, value]) => typeof value == 'object').map(key => key[0])
        planKey = (planKey).sort((a, b) => {
          if (a.slice(0, 3) < b.slice(0, 3)) {
            return -1;
          } else if (a.slice(0, 3) > b.slice(0, 3)) {
            return 1;
          } else {
            return a.length < b.length ? -1 : 1;
          }
        });
        return (
            planKey.map( key => 
                     <>
                         <Ptag fontSize='20px' fontweight='600' lineHeight="27px"marginTop="30px" >{planName[key]}</Ptag>
                         {endorsementFile(validate_emp_data[key])}
                         <CardDiv>
                             {premiumCalculation(validate_emp_data[key])}
                             {cdAccountDetails(validate_emp_data[key])}
                         </CardDiv>
                     </>
         ))
      }

    const cdAccountDetails = (value) =>{
        return (
            <CdCardDetail 
                latest_transaction_date={value.latest_transaction_date} 
                shortfall={value.shortfall}
                current_cd_balance={value.current_cd_balance}
                remaining_cd_balance={value.remaining_cd_balance}
            />
        )
    }

    const getCommonData = (key) => {
        const number = (validate_emp_data && validate_emp_data.GMC && validate_emp_data.GMC[key]) ||
                        (validate_emp_data && validate_emp_data.GMC_M && validate_emp_data.GMC_M[key]) ||
                        (validate_emp_data && validate_emp_data.GPA && validate_emp_data.GPA[key]) ||
                        (validate_emp_data && validate_emp_data.GTL && validate_emp_data.GTL[key])
        return number|| 0
    }

    const checkLength = (val, text) => {
        if(val > 1) {
            return text[0];
        } else  {
            return text[1]
        }
    }

    // const endorsementFile = (total_add_delete)=> {
    //     return(
    //         <EndorsementFile>
    //             {/* <Ptag fontSize="24px" fontWeight="600" color="#4A4A4A" lineHeight="27px" letterSpacing="0.5px" marginTop="56px" marginBottom='10px'>Endorsement File</Ptag> */}
    //             <InnerEndorsementFile>
    //                 <BoxTag>
    //                     <Ptag fontSize='16px' fontweight='500' textAlign="center">Total Addition</Ptag>
    //                     <FlexRowDiv>
    //                         <NumberDivLeft>
    //                             <Number fontSize='28px' >{total_add_delete['total_addition_employees']}</Number>
    //                             <Text>{checkLength(total_add_delete['total_addition_employees'], ["Employees", "Employee"])}</Text>
    //                         </NumberDivLeft>
    //                         <NumberDiv>
    //                             <Number fontSize='28px'>{total_add_delete['total_addition_lives']}</Number>
    //                             <Text marginLeft='18px'>{checkLength(total_add_delete['total_addition_lives'], ["Lives", "Life"])}</Text>
    //                         </NumberDiv>
    //                     </FlexRowDiv>
    //                 </BoxTag>
    //                 <BoxTag>
    //                     <Ptag fontSize='16px' fontweight='500' textAlign="center">Total Deletion</Ptag>
    //                     <FlexRowDiv>
    //                         <NumberDivLeft>
    //                             <Number >{total_add_delete['total_deletion_employees']}</Number>
    //                             <Text>{checkLength(total_add_delete['total_deletion_employees'], ["Employees", "Employee"])}</Text>
    //                         </NumberDivLeft>
    //                         <NumberDiv>
    //                             <Number >{total_add_delete['total_deletion_lives']}</Number>
    //                             <Text marginLeft='18px'>{checkLength(total_add_delete['total_deletion_lives'], ["Lives", "Life"])}</Text>
    //                         </NumberDiv>
    //                     </FlexRowDiv>
    //                 </BoxTag>
    //             </InnerEndorsementFile>
    //         </EndorsementFile>
            
    //     )
    // }

    const endorsementFile = (total_add_delete)=> {
        const additionDetails = []
        const deletionDetails = []
        additionDetails.push({
            title: checkLength(total_add_delete['total_addition_employees'], ["EMPLOYEES", "EMPLOYEE"]),
            number: total_add_delete['total_addition_employees'] || 0
        },{
            title: checkLength(total_add_delete['total_addition_lives'], ["LIVES", "LIFE"]),
            number: total_add_delete['total_addition_lives'] || 0
        })
        deletionDetails.push({
            title:checkLength(total_add_delete['total_deletion_employees'], ["EMPLOYEES", "EMPLOYEE"]),
            number: total_add_delete['total_deletion_employees'] || 0
        },{
            title: checkLength(total_add_delete['total_deletion_lives'], ["LIVES", "LIFE"]),
            number: total_add_delete['total_deletion_lives'] || 0
        })
        return(
            <TotalNumberDiv>
                <SplitDetailCard title="Toatal Addition" details={additionDetails}/>
                <SplitDetailCard title="Toatal Deletion" details={deletionDetails}/>
            </TotalNumberDiv>
        )
    }

    const premiumCalculation = (value) =>{
        return(
            <InsurancePremium 
                sum_premium_due={value.sum_premium_due} 
                sum_refunds={value.sum_refunds}
                net_premium_and_refunds={value.net_premium_and_refunds}
            />
        )
    }

    const wellnessTextField = () =>{
        return(
            <FlexRowDiv>
                 {
                    (validate_emp_data && !!getCommonData('wellness_fee_average'))?
                    <WellnessBox>
                        {/* <BorderBottom></BorderBottom> */}
                        <Ptag fontSize="24px" fontWeight="600" color="#4A4A4A" lineHeight="32px" letterSpacing="0.5px" marginTop='25px' marginBottom='15px'>Wellness Fees</Ptag>
                        <Ptag marginBottom='10px' fontSize="16px">
                            Your plan comes with <BoldTag>{validate_emp_data && getCommonData('wellness_fee_average')}</BoldTag>. <Text2><BoldTag>Rs.{(validate_emp_data && validate_emp_data.wellness_fee_average) || 0} per annum per employee,</BoldTag> calculated pro-data basis the policy start date.</Text2>
                        </Ptag>
                    </WellnessBox>:
                    <WellnessBox>
                        
                        <Ptag fontSize="24px" fontWeight="600" color="#4A4A4A" lineHeight="32px" letterSpacing="0.5px" marginBottom='15px' marginTop='30px'>Wellness Fees</Ptag>
                        <Ptag marginBottom='10px' fontSize="16px">
                            Your plan comes with a suite of wellness benefits that include <BoldTag>{validate_emp_data && getCommonData('wellness_sentence')}</BoldTag>. <Text2><BoldTag>This is complimentary with your plan.</BoldTag></Text2>
                        </Ptag>
                    </WellnessBox>
                }
            </FlexRowDiv>
        )
    }

    const exportCsv = () =>{
        var csvStr = '"Employee First Name","Employee Last Name","Gender","Employee Date of Birth","Mobile","Email","Dependent1",,,,"Dependent2",,,,"Dependent3",,,,"Dependent4",,,,"Dependent5",,,,"UHID","Endorsment Type(Add/Delete)","Date of Joining","Remarks","Start Date","Policy End Date","Date of Leaving","Sum Insured(Rs)","Premium Due(Rs)","Premium Paid(Rs)","Refund Due(Rs) *No refund will be processed if there has been a claim.",\n,,,,,,"Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB","Dependent Type","First Name","Last Name","DOB",\n'
        
        validate_emp_data && (validate_emp_data.input_file_data).forEach(element => { 
            csvStr = csvStr+ (element.employee_first_name || '') +','
            csvStr = csvStr+ (element.employee_last_name || '') +','
            csvStr = csvStr+ (element.employee_gender|| '')+','
            csvStr = csvStr+ (element.employee_dob || '') +','
            csvStr = csvStr+ (element.employee_phone|| '')+','
            csvStr = csvStr+ (element.employee_email|| '')+','
            for (let i = 0; i < 5; i++) {
                const role = element.employee_dependents[i]
                csvStr = csvStr+ ((role && role.dep_type) || '')+','
                csvStr = csvStr+ ((role && role.dep_first_name) || '')+','
                csvStr = csvStr+ ((role && role.dep_last_name) || '')+','
                csvStr = csvStr+ ((role && role.dep_dob) || '')+','     
            }
            csvStr = csvStr+ (element.employee_UHID|| '')+','
            csvStr = csvStr+ (element.employee_endorsement_type || '')+','
            csvStr = csvStr+ (element.employee_date_of_joining|| '')+',' 
            csvStr = csvStr+ (element.remarks|| '')+','
            csvStr = csvStr+ (element.start_date|| '')+','
            csvStr = csvStr+ (element.policy_end_date|| '')+','
            csvStr = csvStr+ (element.employee_date_of_leaving|| '')+','
            csvStr = csvStr+ (element.employee_sum_insured|| '')+','
            csvStr = csvStr+ (element.premium_due|| '')+','
            csvStr = csvStr+ (element.premium_paid|| '')+','
            csvStr = csvStr+ (element.refund_due|| '')+','
            
            csvStr = csvStr+'\n'
        });
        const element = document.createElement("a");
        const fileOutput = csvStr
        const file = new Blob([fileOutput], {type: 'text/plain'});
        element.href = URL.createObjectURL(file);
        element.download =  (company_name && company_name.split(/[\s.]+/)[0] + '_employee.csv') || 'Employee_Details.csv';
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        element.parentNode.removeChild(element);
    }

    const generateTableData = () => {
        const reducer = (acc, value, index) => {
            const empData = validate_emp_data[value].input_file_data;
            const newTableData = acc;
            var lowerCase = toLower(`employee_${value}_sum_insured`)
            if(!empData){
                return newTableData
            }
            empData.forEach(employee => {
                const empIdx = acc.findIndex((prevEmp) => prevEmp.phone === employee.employee_phone);
                if(empIdx > -1) {
                    newTableData[empIdx][value] = {
                            start_date : employee.start_date,
                            policy_end_date:employee.policy_end_date,
                            sum_insured : employee[lowerCase] || 0,
                            premium_due : employee.premium_due,
                            premium_paid : employee.premium_paid,
                            refund_due : employee.refund_due,
                            remarks: employee.remarks
                    }
                } else {
                    const obj = {
                        name: employee.employee_first_name,
                        last_name : employee.employee_last_name,
                        gender: employee.employee_gender,
                        dob: employee.employee_dob,
                        phone : employee.employee_phone,
                        mail : employee.employee_email,
                        dependent : employee.employee_dependents,
                        endorsement_type : employee.employee_endorsement_type,
                        uhid:employee.employee_UHID ,
                        [value]: {
                            start_date : employee.start_date,
                            policy_end_date:employee.policy_end_date,
                            sum_insured : employee[lowerCase],
                            premium_due : employee.premium_due,
                            premium_paid : employee.premium_paid,
                            refund_due : employee.refund_due,
                            refund_due : employee.refund_due
                        }
                    }
                    newTableData.push(obj);
                }
            })
            return newTableData;
        }
        return Object.keys(validate_emp_data).reduce(reducer, []);
    }


    const xport = React.useCallback(async () => {
        const { data } = await http.post("business/download_endorsement_data/", validate_emp_data, { responseType: 'blob' })
        
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([data]));
        link.download = 'endorsement_preview.xlsx';

        // Append link to the DOM and click it
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        window.URL.revokeObjectURL(link.href);
        // const XLSX = window.XLSX
        // const table = document.getElementById("Table2XLSX");
        // const wb = XLSX.utils.table_to_book(table);
        // XLSX.writeFile(wb, "Endorsment.xlsx");
      });

      function DownloadExcel(props) {
        const numbers = Array.from({ length: 5 }, (_, i) => i + 1);
        const newObj = generateTableData()
        const showGmcColumn = newObj[0].hasOwnProperty("GMC");
        const showGmcmColumn =  newObj[0].hasOwnProperty("GMC_M");
        const showGpaColumn = newObj[0].hasOwnProperty("GPA");
        const showGtlColumn = newObj[0].hasOwnProperty("GTL");
        const generateDependentData = (dependents) => {
            const totalDependents = dependents.length;
            const emptyIterations = Array(7 - totalDependents).fill(0);
           
            return (
                <>
                {
                    dependents.map((dependent) => (
                        <>
                            <td>{dependent && dependent.dep_type}</td>
                            <td>{dependent && dependent.dep_first_name}</td>
                            <td>{dependent && dependent.dep_last_name}</td>
                            <td>{dependent && dependent.dep_dob}</td>
                        </>
                    ))
                }
                {
                    emptyIterations.map((_) => (
                        <>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            <td> </td>
                        </>
                    ))
                }
                </>
            )
            
        }
        const generateDependentsHeaders = () => {
            return numbers.map(_ => (
                <>
                    <th>Dependent Type</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>DOB</th>
                </>
            ))
        }
        return (
        <div style={{display: "none"}}>
          <table  id="Table2XLSX">
            <tbody>
                <tr>
                    <td colSpan="8"/>
                    {numbers.map(num => (
                                <th colSpan="4">Dependent {num}</th>
                            ))}
                    {showGmcColumn && (
                        <td colSpan={7}>GMC</td>
                    )}
                     {showGmcmColumn && (
                        <td colSpan={7}>GMC</td>
                    )}
                    {showGpaColumn && (
                        <td colSpan={7}>GPA</td>
                    )}
                    {showGtlColumn && (
                        <td colSpan={7}>GTL</td>
                    )}
                </tr>
                <tr>
                        <td>First Name</td>
                        <td>Last Name</td>
                        <td>Gender</td>
                        <td>DOB</td>
                        <td>Phone</td>
                        <td>Mail</td>
                        <td>UHID</td>
                        <td>Endorsement Type</td>
                        {generateDependentsHeaders()}
                        {showGmcColumn && (
                            <>
                                <td>Start Date</td>
                                <td>End Date</td>
                                <td>Sum Insured</td>
                                <td>Premium Due</td>
                                <td>Premium Paid</td>
                                <td>Refund Due</td>
                                <td>Remarks</td>
                            </>
                        )}
                        {showGmcmColumn && (
                            <>
                                <td>Start Date</td>
                                <td>End Date</td>
                                <td>Sum Insured</td>
                                <td>Premium Due</td>
                                <td>Premium Paid</td>
                                <td>Refund Due</td>
                                <td>Remarks</td>
                            </>
                        )}
                        {showGpaColumn && (
                            <>
                                <td>Start Date</td>
                                <td>End Date</td>
                                <td>Sum Insured</td>
                                <td>Premium Due</td>
                                <td>Premium Paid</td>
                                <td>Refund Due</td>
                                <td>Remarks</td>
                            </>
                        )}
                        {showGtlColumn && (
                            <>
                                <td>Start Date</td>
                                <td>End Date</td>
                                <td>Sum Insured</td>
                                <td>Premium Due</td>
                                <td>Premium Paid</td>
                                <td>Refund Due</td>
                                <td>Remarks</td>
                            </>
                        )}
                </tr>
                {newObj.map((emp, i) => (
                    <tr>
                        <td>{emp.name}</td>
                        <td>{emp.last_name}</td>
                        <td>{emp.gender}</td>
                        <td>{emp.dob}</td>
                        <td>{emp.phone}</td>
                        <td>{emp.mail}</td>
                        <td>{emp.uhid}</td>
                        <td>{emp.endorsement_type}</td>
                        {generateDependentData(emp.dependent)}
                        {showGmcColumn && (
                            <>
                                <td>{emp.GMC.start_date}</td>
                                <td>{emp.GMC.policy_end_date}</td>
                                <td>{emp.GMC.sum_insured}</td>
                                <td>{emp.GMC.premium_due}</td>
                                <td>{emp.GMC.premium_paid}</td>
                                <td>{emp.GMC.refund_due}</td>
                                <td>{emp.GMC.remarks}</td>
                            </>
                        )}
                        {showGmcmColumn && (
                            <>
                                <td>{emp.GMC_M.start_date}</td>
                                <td>{emp.GMC_M.policy_end_date}</td>
                                <td>{emp.GMC_M.sum_insured}</td>
                                <td>{emp.GMC_M.premium_due}</td>
                                <td>{emp.GMC_M.premium_paid}</td>
                                <td>{emp.GMC_M.refund_due}</td>
                                <td>{emp.GMC_M.remarks}</td>
                            </>
                        )}
                        {showGpaColumn && (
                            <>
                                <td>{emp.GPA.start_date}</td>
                                <td>{emp.GPA.policy_end_date}</td>
                                <td>{emp.GPA.sum_insured}</td>
                                <td>{emp.GPA.premium_due}</td>
                                <td>{emp.GPA.premium_paid}</td>
                                <td>{emp.GPA.refund_due}</td>
                                <td>{emp.GPA.remarks}</td>
                            </>
                        )}
                        {showGtlColumn && (
                            <>
                                <td>{emp.GTL.start_date}</td>
                                <td>{emp.GTL.policy_end_date}</td>
                                <td>{emp.GTL.sum_insured}</td>
                                <td>{emp.GTL.premium_due}</td>
                                <td>{emp.GTL.premium_paid}</td>
                                <td>{emp.GTL.refund_due}</td>
                                <td>{emp.GTL.remarks}</td>
                            </>
                        )}
                    </tr>
                ))}
            </tbody></table>
          <button onClick={xport}><b>Export XLSX!</b></button>
        </div>
        );
      }

    const premiumEstimate = () => {
        return(
            <PremiumEstimateMainDiv>
                <FlexRowDiv2>
                    <InnerTextDiv>
                        <Ptag fontSize="24px" fontWeight="600" color="#4A4A4A" lineHeight="27px" letterSpacing="0.5px" marginBottom='10px'>Insurance Premium - Estimate</Ptag>
                        <Ptag fontSize='14px' fontweight="500">The figures shown below are estimated values, and may change upon the final quote shared by the insurer.</Ptag>
                    </InnerTextDiv>
                    <DownloadExcelTag>
                        <Ptag fontSize='14px' fontweight='550'>Employee-wise Breakup</Ptag>
                        <CommonBox>
                            <Download onClick={xport}>
                                <img src={downloadcsv} width='20px' height='20px' style={{ marginRight: 10 }} />
                                <Ptag fontSize="13px" fontWeight="600" color="#FFFFFF" lineHeight="24px" letterSpacing="0.5px">Download Excel</Ptag>
                            </Download>
                        </CommonBox>
                    </DownloadExcelTag>
                </FlexRowDiv2>
                <div style={{"padding":"0px 40px 40px 40px"}}>
                    {renderCards()}
                </div>
                <YellowBoxNote>
                    <YellowFlexBox>
                        <Ptag fontSize='18px' fontweight='600'>Note</Ptag>
                        <Ptag fontSize='14px'>Please ensure your CD Balance is adequate. That will ensure seamless endorsements and policy generation. We recommend you keep an additional 10% amount in the CD account to avoid last-minute hassles in additions/deletions.</Ptag>
                    </YellowFlexBox>
                </YellowBoxNote>
            </PremiumEstimateMainDiv>
        )
    }

    return (
        <>
            <Root>
                <Body>
                    <OuterDiv>
                        <TextUpper>Thank You!</TextUpper>
                        <ThankYouText>
                            We will share your endorsement file with the insurer(s). Your account manager will get back to you with the final quotes incase of net employee additions. In the scenario of net employee deletions, you will receive a refund in your CD Account(s). 
                        </ThankYouText>
                    </OuterDiv>
                    {premiumEstimate()}
                    {/* {validate_emp_data && !!getCommonData('wellness_sentence') && wellnessTextField()} */}
                    {DownloadExcel()}
              </Body>
            </Root>
        </>
    )
}
// }

const Root = styled.div`
	width: 100%;
	background-color: #ffffff;
    display: flex;
    flex-direction: column;
`
const TotalNumberDiv = styled.div`
    display: flex;
    flex-direction: row;
    gap:1rem;
    margin-top: 20px;
`

const ThankYouText = styled.p`
  margin: 0px 10% 7% 12%;
  padding: 0 40px 40px 40px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-align: center;
  font-family: 'Avenir';
  font-size: 15px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4a4a4a;
  background-color: #FDF6E9;
`
const OuterDiv = styled.div`
    position: relative;
    width: calc(100% + 45px);
    left: -24px;
    border-bottom: 20px solid ;
    border-color: #f2f0f0;
`

const CardDiv = styled.div`
    display: flex;
    gap: 2rem;
`

const TextUpper = styled.p`
  margin: 20px 10% 0px 12%;
  padding: 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #FDF6E9;
  text-align: center;
  font-family: 'Avenir';
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`
const Body = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    flex: 1;
`

const BoxTextTag = styled.div`
    display: flex;
    flex-direction: row;
    padding-left: 20px;
    padding-bottom: 10px;
    padding-top: 15px;
`
const BoldTag = styled.b`
    font-weight: 600;
`
const Text2 = styled.p`
    font-weight: 400;
    font-style:normal;
    font-family: "Avenir";
    font-size: 16px;
    color: #4a4a4a;
`

const Text = styled.p`
  text-align: center;
  font-family: Avenir;
  font-size: 14px;
  font-weight: 400;
  color: #4a4a4a;
`
const Number = styled.p`
  text-align: center;
  font-family: Avenir;
  font-size: 28px;
  font-weight: 600;
  color: #4a4a4a;
`

const ButtonWrapper = styled.div`
  display: flex;
  width: 96%;
  flex: 1;
  align-items: flex-end;
  margin-left:25px;
  margin-top: 23px;
  padding-bottom: 40px;
`
const SmallTextDiv2 = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 55%;
`

const SmallTextDiv = styled.div`
    display: flex;
    flex-direction: column;
    /* min-width: 53%; */
`
const SpaceBetween = styled.div`
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    min-width: 53%;
`
const SpaceBetween2 = styled.div`
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    min-width: 55%;
`

const YellowFlexBox = styled.div`
    display: flex;
    flex-direction: column;
`

const BorderBottom = styled.div`
    border-bottom: 1px solid #000000;
    padding-top: 10px;
    max-width: 70%;
`
const WellnessBox = styled.div`
    margin: 20px;
    margin-top: 0px;
    border-top: 1px solid #000000;
    min-width: 96%;
`

const PremiumBoxDiv = styled.div`
    min-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #F6F6F6;
    border-radius: 10px;
    padding: 15px;
`
const YellowBoxNote = styled.div`
    min-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #FDBD0C75;
;
    border-radius: 10px;
    padding: 20px;
`

const DownloadExcelTag = styled.div`
    display: flex;
    flex-direction:column;
    margin-left: 20px;
`

const CommonBox = styled.div`
    display:flex;
`

const Download = styled.div`
    margin-top: 7px;
    border: 0px;
    color: rgb(255, 255, 255);
    background: rgb(67, 106, 178);
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    letter-spacing: 0.5px;
    font-size: 13px;
    cursor: pointer;
    letter-spacing: 0.5px;
    border: 1px solid #F7F9FD;
    border-radius: 10px;
    /* margin-right:10px; */

`

const TatalAmountSpaceBetween= styled.div`
justify-content: space-between;
display: flex;
flex-direction: row;
min-width: 53%;
margin-left: 27px;
`

const PremiumEstimateMainDiv = styled.div`
    margin: 20px;
    display: flex;
    flex-direction: column;
`
const FlexRowDiv = styled.div`
    display: flex;
    flex-direction: row;
`
const FlexRowDiv2 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`
const InnerTextDiv = styled.div`
    max-width: 70%;
`

const BoxTag = styled.div`
    height: 136px;
    width: 100%;
    max-width: 280px;
    padding: 13px;
    margin-top: 20px;
    border: 1px solid #436AB2;
    border-radius: 10px;
    margin-right:20px;
`
const NumberDivLeft = styled.div`
    /* padding:25px; */
    margin-top: 11px;
    margin-bottom: 24px;
    width: 50%;
    border-right:1px solid #000000;
    align-items: center;
    justify-content: center;
`
const NumberDiv = styled.div`
    /* padding:25px; */
    margin-top: 11px;
    margin-bottom: 24px;
    min-width: 50%;
`
const EndorsementFile = styled.div`
    /* margin-left: 20px; */
    margin-right: 20px;
    /* padding-bottom: 30px;
    border-bottom: 1px solid #000000; */
`
const InnerEndorsementFile = styled.div`
    display: flex;
    flex-direction: row;
`

const DownloadButton = styled.div`
    display: flex;
    justify-content: center;
    align-items:center;
    border: 1px solid #436AB2;
    border-radius: 10px;
    margin-top: 24px;
    padding: 20px;
    cursor: pointer;
`

const HomeWrapper = styled.div`
  transition: margin-left .2s ease-in;
  padding: 50px 80px;
  box-sizing: border-box;
  background:#fff;
  height:100vh;
  margin-left: 200px;
`





export default withRouter(PrePurchasePlanRecommendation)