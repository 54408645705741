import React, { Component } from 'react'
import styled from 'styled-components'
import phonecoverpage3 from '../assets/images/phonecoverpage3.png'
import Screenshotpage3 from '../assets/images/Screenshotpage3.png'
import vitalInsuranceMobile2 from '../assets/images/vitalInsuranceMobile2.png'
// import vitalInsuranceMobile1 from '../assets/svgs/vitalInsuranceMobile1.svg'
// import vitalInsuranceMobile2 from '../assets/svgs/vitalInsuranceMobile2.svg'
import circlepage3 from '../assets/images/circlepage3.png'
import Groupdotspage3 from '../assets/images/Groupdotspage3.png'
import thumbpage3 from '../assets/images/thumbpage3.png'
import religare_desktop from '../assets/svgs/religare_desktop.svg'
import icon_navigation_arrow_back from '../assets/svgs/icon_navigation_arrow_back.svg'
// import religareLogo_1 from '../assets/svgs/religareLogo_1.svg'
// import religareLogo_2 from '../assets/svgs/religareLogo_2.svg'
import ReligareGroup from '../assets/images/ReligareGroup.png'
import screenshotdesktoppage3 from '../assets/images/assets1024/screenshotdesktoppage3.png'
import phonecoverdesktoppage3 from '../assets/images/assets1024/phonecoverdesktoppage3.png'
import religare1530 from '../assets/images/religare1530.png'
import DMI123 from '../assets/images/DMI1234.png'
import Religare from '../assets/images/updatedlanding1/Religare.png'
import religare1920 from '../assets/images/religare1920.jpg'
import page4 from '../assets/images/updatedlanding1/page4.png'
import page3small from '../assets/images/updatedlanding1/page3small.png'
import ImageC from '../assets/images/imageC.png'


import './page3.css'
const Wrapper = styled.div`
  width: 100%;
  position: relative;
  background-image: linear-gradient(#fafafa, #eaeaea);
`
const Header = styled.div`
  // top: 3.6rem;
  width: 100%;
  position: relative;
  // padding: 3.55rem 2.4rem 2.4rem 2.4rem;
  // padding: 3.55rem 2.4rem 3.6rem 2.4rem;
  padding: 3.6rem 0 3.6rem 0;

  @media (min-width: 1024px) {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    padding: 17rem 0 14rem 0;
  }

  @media (min-width: 1680px) {
    display: flex;
    align-items: center;
    // padding: 24.9rem 0;
    padding: 12.2rem 0;
  }
`
const Subheader = styled.div`
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  @media (min-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
    margin-left: 140px;
  }

  @media (min-width: 1680px) {
    margin-left: 14rem;
  }
`

const H2 = styled.h2`
  font-family: Proxima Nova;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.86px;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    width: 100%;
    font-size: 3.4rem;
    line-height: 1.59;
    letter-spacing: 0.02px;
    width: 450px;
  }

  @media (min-width: 1680px) {
    font-size: 5.2rem;
    width: 650px;
    line-height: 1.61;
    letter-spacing: 0.03px;
  }
`
const Header2 = styled.p`
  font-family: Proxima Nova;
  font-size: 2.8rem;
  font-weight: 600;
  letter-spacing: 0.28px;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    font-size: 4.4rem;
    font-weight: bold;
    letter-spacing: 0.02px;
  }

  @media (min-width: 1680px) {
    font-size: 72px;
    font-weight: 600;
    letter-spacing: 0.04px;
  }
`

const H3 = styled.h3`
  margin-top: 1.2rem;
  font-family: Avenir;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.88;
  letter-spacing: 0.75px;
  color: #4a4a4a;

  @media (min-width: 1024px) {
    width: 45rem;
    margin-top: 2.4rem;
    font-size: 1.8rem;
    line-height: 1.56;
    letter-spacing: 0px;
    text-align: left;
  }

  @media (min-width: 1680px) {
    margin-top: 4.5rem;
    width: 69.3rem;
    font-size: 3.4rem;
    line-height: 1.59;
    letter-spacing: 0.02px;
  }
`
const Content2 = styled.p`
  font-size: 1.4rem;
  font-family: Avenir;
  font-weight: bold;
  color: #436ab3;
  font-style: normal;
  font-stretch: normal;
  line-height: 2;
  text-align: left;
  text-transform: uppercase;

  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
  // position: relative;
  // bottom: 1.4rem;
  // z-index: 10;
  cursor: pointer;

  span {
    img {
      height: 1.2rem;
    }
  }

  @media (min-width: 1024px) {
    margin-top: 2.3rem;
    margin-bottom: 2.5rem;
    font-size: 1.6rem;
    line-height: 1.38;
    letter-spacing: 0.32px;
    span {
      img {
        height: 1rem;
      }
    }
  }

  @media (min-width: 1680px) {
    margin-top: 5.4rem;
    margin-bottom: 4.45rem;
    font-size: 3.4rem;
    line-height: 1.35;
    letter-spacing: 0.68px;
    span {
      img {
        height: 2.4rem;
      }
    }
  }
`

const Religarelogo = styled.div`
  margin-bottom: 2.4rem;
  background-image: url(${religare_desktop});
  background-size: contain;
  background-repeat: no-repeat;
  height: 4rem;
  width: 10rem;

  @media (min-width: 1024px) {
    background-image: url(${religare_desktop});
    background-size: contain;
    // height: 6.54rem;
    width: 20rem;
  }

  @media (min-width: 1680px) {
    background-image: url(${religare_desktop});
    background-size: contain;
    height: 8rem;
    width: 30rem;
  }
`

const Section = styled.div`
  width: 100%;
  height: 9.25rem;
  display: flex;
  flex-direction: row;
  display: -webkit-flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  position: absolute;
  margin-top: 29.9rem;
  z-index: 1;
`

const Imagethumb = styled.div`
  width: 4.32rem;
  height: 6.97rem;
  background-image: url(${thumbpage3});
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  bottom: 20%;
  // top: 19rem;

  @media (min-width: 1024px) {
    height: 10rem;
    width: 9.81rem;
    // top: 11rem;
  }
`
const Imagecircles = styled.div`
  width: 4.57rem;
  height: 9.24rem;
  background-image: url(${circlepage3});
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  bottom: 0;
  @media (min-width: 1024px) {
    background-image: url(${circlepage3});
    height: 5.81rem;
    width: 5.47rem;
  }
`

const Imagedots1024 = styled.div`
  // @media (min-width: 200px) and (max-width: 1023px) {
  //   display: none;
  // }

  background-image: url(${Groupdotspage3});
  background-repeat: no-repeat;
  position: absolute;
  right: 3%;
  bottom: 5%;
  // left: 0;
  height: 4.44rem;
  width: 4.45rem;
  // margin-left: 3rem;
  // top: 13rem;
  // display: none;
`

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  display: webkit-flex;
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  justify-content: center;
  position: absolute;
  top: 33.6rem;
  height: 24rem;
  content-align: center;

  @media (min-width: 1024px) {
    height: 28rem;
    top: 0rem;
  }
`

const Imagephone = styled.div`
  width: 29rem;
  height: 5.936rem;
  // height: 468.2px;
  // height: 38rem;
  margin: 0 auto;
  background-image: url("${props =>
    props.error ? props.errorSrc : props.loading ? props.loadingSrc : props.actualSrc}");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  top: ;

  @media (min-width: 1024px) {
    margin: 0;
  }

  @media (min-width: 1680px) {
    width: 58rem;
    height: 91.872rem;
    margin: 0;
     background-image: url("${props =>
       props.error ? props.errorSrc : props.loading ? props.loadingSrc : props.actualSrc}");
  }
`
const ScreenshotImage = styled.div`
  display: flex;
  background-image: url(${Screenshotpage3});
  width: 15.1rem;
  height: 22.8rem;
  position: absolute;
  margin-top: 4.4rem;
  margin-left: 1.8rem;
  display: none;

  @media (min-width: 1024px) {
    display: none;
    height: 41rem;
    width: 26.2rem;
    background-image: url(${screenshotdesktoppage3});
    position: absolute;
    left: 1rem;
  }
`
const Imagedots = styled.div`
width: 3.87rem ;
height: 3.97rem ;
background-image:url(${Groupdotspage3});
background-repeat: no-repeat;
position: relative;
top: 21rem;
right: 0rem ;
left: 12rem ;

@media (min-width: 1024px) {
    display: none ;

`
const ImageDv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Image = styled.img`
  display: flex;
  // width: 268px;
  height: 468.2px;

  @media (min-width: 1680px) {
    // width: 400px;
    height: 800px;
  }
`

const Font14 = styled.p`
  font-size: 1.2rem;
  font-family: Avenir;
  font-weight: 600;
  color: #4a4a4a;
  line-height: 1.7;
  letter-spacing: 0.5px;
  text-transform: uppercase;
`

const Font14a = styled.div`
  font-size: 14px;
  font-family: Avenir;
  color: #436AB2;
  font-weight: 600;
  letter-spacing: 2px;
  padding-bottom: 12px;
`

const LazyImage = ({ loadingSrc, actualSrc, errorSrc, ...props }) => {
  const [isImageLoaded, setImageLoaded] = React.useState(false)
  const [hasError, setHasError] = React.useState(false)
  React.useEffect(
    () => {
      const img = new Image()
    

      img.onload = () =>
        setImageLoaded(true)
      img.onerror = () => setHasError(true)

      img.src = actualSrc
    },
    [actualSrc]
  )

  return (
    <Imagephone
      loadingSrc={loadingSrc}
      actualSrc={actualSrc}
      errorSrc={actualSrc}
      loading={!isImageLoaded}
      error={hasError}
      {...props}
    />
  )
}
export default class Page3 extends Component {
  render = () => {
    return (
      <Wrapper id={this.props.id}>
        <Header>
          <Subheader>
          <Font14a>2. SMART HEALTH PLANS </Font14a>
            <H2>Affordable and comprehensive health plans</H2>
    
            {/* <Header2>Vital Insurance</Header2> */}
            <H3>
            {/* With our personalized and innovative insurance plans that come in a monthly subscription format. 
            Now insure your entire family against unforeseen hospitalization expenses at a fraction <br />
            of the cost. */}
              {/* Covering your medical expenses ,all in a monthly subscription format .
              These plans are developed by our insurance partners for Vital members.
              Now secure your entire family against unforeseen hospitalization expenses at a fraction of the cost. */}
            Including health insurance, for the first time, in monthly subscriptions. 
            These plans are designed exclusively for Vital members by leading insurance 
            providers and will help you secure your entire family against unforeseen
             hospitalization expenses - all at a fraction of the cost.
            </H3>
            <Content2 onClick={() => this.props.redirectToInsurancePage()}>
              Excited? Know how we do it!{' '}
              <span>
                <img src={icon_navigation_arrow_back} alt="icon_navigation_arrow_back.svg" />
              </span>
            </Content2>
            {/*<Font14>Issued By</Font14>*/}
            {/*<Religarelogo />*/}
          </Subheader>
          {/*<div className="imagePhone">
            <LazyImage unloadedSrc={vitalInsuranceMobile2} src={DummyUrl}/>
          </div> */}
          <ImageDv>
          {/* <Image src={ImageC}/> */}
          <Image alt="best health insurance" src="http://images.getvital.in/homelanding/imageC.png" />
          {/* <LazyImage
            loadingSrc={ImageC}
            actualSrc="http://images.getvital.in/images/imageC.png"
            errorSrc={ImageC}
          />  */}
          </ImageDv>
          
          <Imagethumb />
          {/* <Imagecircles /> */}
          {/* <Imagedots1024 /> */}
          {/* <img src={vitalInsuranceMobile2} alt="" /> */}
        </Header>
        {/* <Section>
          <Imagethumb />
          <Imagecircles />
          <Imagedots1024 />
        </Section> */}
        {/* <Footer>
          <Imagephone>
            <ScreenshotImage />
          </Imagephone>
          <Imagedots />
        </Footer> */}
      </Wrapper>
    )
  }
}
