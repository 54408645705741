import React, { Component } from 'react'
import styled from 'styled-components'
import Group from '../assets/images/Group.png'
import img1_long from '../assets/images/img1_long_low.png'
import img2_lat from '../assets/images/img2_lat_low.png'
import Group1024page1 from '../assets/images/Group1024page1.png'

// import background1920 from '../assets/images/background1920.png'

const Wrapper = styled.div`
  width: 100%;
`

const Section = styled.div`
  height: 57.1rem;
  background-image: url("${props =>
    props.errorM ? props.errorSrcM : props.loadingM ? props.loadingSrcM : props.actualSrcM}");
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  padding-top: 8.4rem;

  @media (min-width: 1024px) {
    background-image: url("${props =>
      props.error ? props.errorSrc : props.loading ? props.loadingSrc : props.actualSrc}");
    height: 98rem;
    background-color: ;
    background-size: 56% 100%;
    background-position-x: right;
    padding-top: 17.7rem;
  }
  @media (min-width: 1600px) {
    background-image: url("${props =>
      props.errorM ? props.errorSrcM : props.loadingM ? props.loadingSrcM : props.actualSrcM}");
    height: 98.9rem;
    background-color: ;
    background-size: cover;
    background-position-y: 40%;
    padding-top: 25rem;
  }
`
const Sectioncontent = styled.div`
  padding: 2.4rem 0 0 2.4rem;
  display: inline-block;

  @media (min-width: 1024px) {
    padding: 5rem 5.4rem 5rem 4.8rem;
    background-color: #eeeeee;
  }

  @media (min-width: 1680px) {
  }
`
const H1 = styled.h1`
  width: 29.1rem;
  text-align: left;
  font-family: Proxima Nova;
  font-weight: 600;
  font-size: 3.4rem;
  letter-spacing: 0.5px;
  color: #49a38e;

  @media (min-width: 1024px) {
    width: 60rem;
    // top: 8rem;
    // left: 9.4rem;
    line-height: 1.33;
    letter-spacing: normal;

    font-size: 6rem;
    line-height: 1.3;
    font-weight: bold;
  }

  @media (min-width: 1680px) {
    // left: 22rem;
    width: 73rem;
    font-size: 8rem;
    line-height: 1.38;
    letter-spacing: 2px;
  }
`
const H2 = styled.h2`
width: 26.3rem
color: #4A4A4A;
font-size: 1.6rem;
font-family: Avenir;
text-align: left;
line-height: 1.38;
letter-spacing: 0.5px;
margin-top: 1.2rem;

@media (min-width: 1024px) {
    width: 60rem;
    font-size: 3.4rem;
    line-height: 1.76;
    letter-spacing: 0.01px;
    margin-top: 2.4rem;
    // margin-top: 3.8rem;
}

@media (min-width:1680px) {
    // margin-top: 1.3rem;
    // margin-top: 3.85rem;
    line-height: 1.59;
    letter-spacing: 0.01px;
}
`
const ButtonStart = styled.button`
  margin-top: 2.4rem;
  width: 18rem;
  height: 4.8rem;
  background-color: #436ab3;
  border-radius: 1rem;
  font-size: 1.6rem;
  letter-spacing: 1px;
  color: #ffffff;
  cursor: pointer;
  font-family: Avenir;
  font-weight: 600;
  border: none;

  @media (min-width: 1024px) {
    // margin-top: 2.4rem;
    margin-top: 3.8rem;
    width: 30.93rem;
    height: 8.4rem;
    font-size: 2.8rem;
  }

  @media (min-width: 1680px) {
    // margin-top: 3.85rem;
    // margin-top: 1.3rem;
  }
`

const LazyImage = ({ loadingSrc, actualSrc, errorSrc, loadingSrcM, actualSrcM, errorSrcM, ...props }) => {
  const [isImageLoaded, setImageLoaded] = React.useState(false)
  const [isImageLoadedM, setImageLoadedM] = React.useState(false)

  const [hasError, setHasError] = React.useState(false)
  const [hasErrorM, setHasErrorM] = React.useState(false)

  React.useEffect(
    () => {
      const img = new Image()
      img.onload = () => setImageLoaded(true)
      img.onerror = () => setHasError(true)
      img.src = actualSrc
    },
    [actualSrc]
  )

  React.useEffect(
    () => {
      const img2 = new Image()
      img2.onload = () => setImageLoadedM(true)
      img2.onerror = () => setHasErrorM(true)
      img2.src = actualSrcM
    },
    [actualSrcM]
  )

  return (
    <Section
      loadingSrc={loadingSrc}
      actualSrc={actualSrc}
      errorSrc={loadingSrcM}
      loadingSrcM={loadingSrcM}
      actualSrcM={actualSrcM}
      errorSrcM={loadingSrcM}
      loading={!isImageLoaded}
      loadingM={!isImageLoadedM}
      error={hasError}
      errorM={hasErrorM}
      {...props}
    />
  )
}

export default class Page1 extends Component {
  render = () => {
    return (
      <Wrapper>
        <LazyImage
          loadingSrc={img1_long}
          actualSrc={'http://images.getvital.in/images/img_long.png'}
          errorSrc={img1_long}
          loadingSrcM={img2_lat}
          actualSrcM={'http://images.getvital.in/images/img2_lat_high.png'}
          errorSrcM={img2_lat}>
          <Sectioncontent>
            <H1>The next-gen health financing solution</H1>
            <H2>To cover your health and wellness expenses without burning a hole in your pocket.</H2>
            <ButtonStart id='getvital_home_joinForFree' onClick={() => this.props.redirectToSignUp()}>JOIN FOR FREE</ButtonStart>
          </Sectioncontent>

          {/* <GroupImage /> */}
        </LazyImage>
      </Wrapper>
    )
  }
}
