import React, { Component } from 'react'
import styled from 'styled-components'
import call from '../../assets/images/call.png'
import Support from '../../assets/images/support.png'
import $ from 'jquery'
import {Ptag} from '../fonts'

function withCallButton(ChildComponent, phoneNo='01141193909', hideCallButton=false){
    return class extends Component{
        
        componentDidMount(){
            const ele = $(`#getvitalincallbutton`)
            ele.hide()
        }

        onClickCall = async (event) => {
            event.preventDefault()
            let that = this
            const ele = $(`#getvitalincallbutton`)
            ele.show()
            ele.focus()
            ele.focusout(function(){
                ele.hide()
            })
        }


        render(){
            return(
                <React.Fragment>
                    <ChildComponent {...this.props} />{
                        !hideCallButton &&
                        <MobileView>
                            <CallWrapper id="getvital_callButton" href={`tel:${phoneNo}`}>
                                <img src={call} width="24px" height="24px" />
                            </CallWrapper>
                            <Ptag style={{'marginTop': '4px'}}>
                                {'Call'}
                            </Ptag>
                        </MobileView>
                    }{
                        !hideCallButton &&
                        <DesktopView>
                            <CallDiv id="getvital_callButton"  onClick={this.onClickCall} >
                                <img src={call} width="24px" height="24px" />
                            </CallDiv>
                            <Ptag style={{'marginTop': '4px'}}>
                                {'Call'}
                            </Ptag>
                        </DesktopView>
                        
                    }
                    
                    <CallPop id='getvitalincallbutton'>
                        <img src={Support} height='30px' width='30px'/>
                        <Wrapper>
                            <Ptag letterSpacing='0.3px' >
                                {'Call us at'}
                            </Ptag>
                            <Ptag fontSize='16px' letterSpacing='0.4px' color='#4a90e2'>
                                {phoneNo}
                            </Ptag>
                        </Wrapper>
                    </CallPop>
                    
                        
                </React.Fragment>
            )
        }
    }
}

export default withCallButton

const Home = styled.div`
    display: flex;
    flex-direction: column;
`

const MobileView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 160px;
    right: 20px;

    @media (min-width: 425px) {
        display: none;
    };

    z-index: 122;
`

const DesktopView = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 150px;
    right: 15%;

    @media (max-width: 426px) {
    display: none;
    };

    z-index: 122;
`

const CallWrapper = styled.a`
  display: flex;
  background-color: #436ab2;
  opacity: 1;
  border-radius: 45px;
  width: 45px;
  height: 45px;
  align-items: center;
  justify-content: center;
`

const CallDiv = styled.div`
    display: flex;
    background-color: #436ab2;
    opacity: 1;
    border-radius: 45px;
    width: 45px;
    height: 45px;
    margin-top: 10px;
    align-items: center;
    justify-content: center;

`

const CallPop = styled.button`
    display: flex;
    padding: 12px;
    border: 1px solid #436ab2;
    position: fixed;
    top: 232px;
    right: 10%;
    z-index: 100;
    background-color: white;
    height: 66px;
    width: 184px;
    border-radius: 6px;
    :focus {
        outline: none;
    }
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 4px;
`