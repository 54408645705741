import React from "react";
import SplitDetailCard from "../../../components/GmcDashbaord/SplitDetailCard";

export const renderSplitCards = (type, details, index) => {
    console.log(details, type)
    const splitDetails = [];
    const flex = localStorage.getItem('isFlex')
    if(details.number_of_employee >= 0) splitDetails.push({
        title: details.number_of_employee === 1 ? "EMPLOYEE" : "EMPLOYEES",
        number: details.number_of_employee || 0,
    })
    if(details.no_of_lives >= 0) splitDetails.push({
        title: details.no_of_lives === 1 ? "LIFE" : "LIVES",
        number: details.no_of_lives || 0,
    })
    const topHeading = flex && details.userplan_data && (details.userplan_data.mandatory?"Mandatory":"Voluntary")
    let JSX = "";
    switch(type) {
        case 'GMC_M':
        case 'GMC':
            JSX = <SplitDetailCard key={index.toString()} title="Group Health Insurance" details={splitDetails} />
            break;
        case 'GPA':
            JSX = <SplitDetailCard key={index.toString()} title="Group Personal Accident" details={splitDetails} />
            break;
        case 'GTL':
            JSX = <SplitDetailCard key={index.toString()} title="Group Term Life" details={splitDetails} />
            break;
        case 'health_insurance':
            JSX = <SplitDetailCard topHeading={topHeading === "Voluntary" && topHeading} title="Group Health Insurance" details={splitDetails} />
            break;
        case 'personal_accident':
            JSX = <SplitDetailCard topHeading={topHeading === "Voluntary" && topHeading} title="Group Personal Accident" details={splitDetails} />
            break;
        case 'term_life':
            JSX = <SplitDetailCard topHeading={topHeading === "Voluntary" && topHeading} title="Group Term Life" details={splitDetails} />
            break;
        case 'mandatory_top_up_health':
            JSX = <SplitDetailCard topHeading={topHeading === "Voluntary" && topHeading} title="Mandatory Top Up Health" details={splitDetails} />
            break;
        case 'top_up_health':
            JSX = <SplitDetailCard topHeading={topHeading === "Voluntary" && topHeading} title="Top Up Health" details={splitDetails} />
            break;
        case 'opd_cover':
            JSX = <SplitDetailCard topHeading={topHeading === "Voluntary" && topHeading} title="OPD Cover" details={splitDetails} />
            break;
        case 'critical_illness':
            JSX = <SplitDetailCard topHeading={topHeading === "Voluntary" && topHeading} title="Critical Illness" details={splitDetails} />
            break;
        case 'health_checkup':
            JSX = <SplitDetailCard topHeading={topHeading === "Voluntary" && topHeading} title="Health Checkup" details={splitDetails} />
            break;
        case 'parental_cover':
            JSX = <SplitDetailCard topHeading={topHeading === "Voluntary" && topHeading} title="Parental Cover" details={splitDetails} />
            break;
    }
    return JSX;
}